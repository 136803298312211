import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrProps } from '@bloomreach/react-sdk';
import './FloorPlanResultsCH.scss';
import { MediaModal } from 'components/MediaModal';

import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { Spinner } from 'components/shared';

import { buildValueListToObject, findRetailerBasepath, getPageContentData } from 'components/utils';
import {
  allFacets,
  allPageSort,
  filterConfiguration,
  resultsCountText,
  pageTypeDefaultSortConfigs,
  pageTypeActiveSortConfigs,
} from './FloorPlanResults.constants';

import {
  allFacetsMapper,
  checkIsDefaultValue,
  facetMapper,
  getFacets,
  getLocalizationData,
  mapResourceBundleData,
  mapResourceBundleToItem,
  sortFacetsByOrder,
} from './services/facets.service';

import {
  buildQueryString,
  getQueryStringValues,
  buildESRequest,
  buildLocationFacet,
  buildBaseFiltersArray,
  buildRetailerIdsFilter,
  buildUiLocationFilters,
  processLocationFiltersForEs,
} from './services/url.service';

import { FloorPlanFacetBar } from './components';
import { ProductResultCard } from '../shared';
import { FloorPlanSort } from './components/FloorPlanSort/FloorPlanSort';
import { FloorPlanPagination } from './components/FloorPlanPagination/FloorPlanPagination';
import useFetch from '../hooks/FetchElasticSearchResults.hook';
import { ResourceBundleOutputProps } from './FloorPlanResults.interface';

export interface MediaModalState {
  isOpen?: boolean;
  mediaSrc?: string;
  mediaTitle?: string;
  isVideo?: boolean;
}

export function FloorPlanResultsCH({
  componentData,
  configData,
  uiFacetConfig,
  esFacetConfig,
  myAccountConfig,
  allFilters,
  elasticSearch,
  filterConfig,
  retailer,
  isRetailerIdSearch,
  hasLocationFilter,
  initialQueryStringValues,
  retailerBasePath,
}: any) {
  const [primed, setPrimed] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isTablet, isMobile } = useWindowDimensions();
  const isMicroSite = window.location.pathname.includes('our-retailers');

  const { elasticSearchResponse, setElasticSearchRequest, elasticSearchLoading } = elasticSearch;

  const { localization } = componentData;
  const { listType, listSubType, baseLocationFacet, facetConfigInstance, facetBarConfig } =
    configData;

  const { hasSearch, primary_count } = filterConfig;

  const [queryStringItems, setQueryStringItems] = useState<any>(initialQueryStringValues);

  // builds object of values for facets and general from the query string:
  // the facets are based on [queryStringFacetItems] in the constants file
  // everything else will go into general where they will be available but not processed
  // STATIC CONFIGURATION
  const countText = resultsCountText[facetBarConfig.primaryType][facetBarConfig.secondaryType];

  // QUEUES
  // data retuned from a facet chance needs to be set manually by the user.
  // store it in its queue until the user confirms it. queue used useRef hook to avoid triggering a re-render
  const facetsQueue = useRef<any>(null);
  const resultsQueue = useRef<any>([]);
  const metaDataQueue = useRef<any>(null);

  // STATE
  // set manually by the user
  // will cause the UI to re-render

  const paginationState = useRef<any>(allPageSort.page.defaultValue);
  const [facetState, setFacetState] = useState<any>();
  const [currentMeta, setCurrentMeta] = useState<any>({});
  const [mediaModalState, setMediaModalState] = useState<MediaModalState>({
    isOpen: false,
    isVideo: false,
    mediaSrc: undefined,
    mediaTitle: undefined,
  });

  const currentFiltersRequestItems = useRef<any>({});
  const currentGeneralRequestItems = useRef<any>({});

  // {size: 12, current: 1}
  // {square_foot: 'desc'}
  const currentSortRequest = useRef<any>({});

  const currentElasticSearchRequest = useRef<any>([]);

  const initialSelected = useRef<any>({});

  const resetPagination = () => {
    paginationState.current = {
      ...paginationState.current,
      current: allPageSort.page.defaultValue.current,
      size: paginationState.current.size,
    };
  };

  const setResults = () => {
    setPrimed(false);
    setFacetState((state: any) => {
      return {
        ...state,
        results: resultsQueue.current,
        facets: sortFacetsByOrder(facetsQueue.current),
      };
    });

    setCurrentMeta((previous: any) => ({
      ...previous,
      ...metaDataQueue.current,
    }));
  };

  const setData = (response: any) => {
    const { rawResults, rawInfo } = response || {
      rawResults: [],
      rawInfo: { facets: [], meta: { page: { total_pages: 0, total_results: 0 } } },
    };

    if (response) {
      metaDataQueue.current = {
        count: rawInfo.meta?.page?.total_results,
        pages: rawInfo.meta?.page?.total_pages,
        current: rawInfo.meta?.page?.current,
      };
      resultsQueue.current = rawResults;

      // Create a deduplicated array of all the properties of the returned results...
      const allProperties: string[] = rawResults.reduce((memo: any, item: any) => {
        const keys = Object.keys(item);
        return [...new Set([...memo, ...keys])];
      }, []);

      facetsQueue.current = allFilters
        ? facetMapper(allFilters, rawInfo.facets, uiFacetConfig, primary_count, allProperties)
        : [];

      setCurrentMeta((previous: any) => ({
        ...previous,
        count: rawInfo.meta?.page?.total_results,
      }));

      setFacetState((state: any) => {
        return {
          ...state,
          facets: sortFacetsByOrder(facetsQueue.current),
        };
      });

      if (primed) {
        setResults();
      }
    }
  };

  useEffect(() => {
    if (elasticSearchResponse) {
      setData(elasticSearchResponse);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elasticSearchResponse]);

  const setQueryString = (url: any) => {
    window.history.replaceState({}, '', url);
  };

  const processCurrentRequestForElasticSearch = (currentPage?: number) => {
    const currentRequest = currentFiltersRequestItems.current;
    let filter3dTour = false;
    const mergedLocation = processLocationFiltersForEs(currentRequest);
    let retailerInStock: any = [];
    const forRequest = Object.entries(mergedLocation)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter((item: [string, any]) => {
        return Array.isArray(item[1]) ? item[1].length : true;
      })
      .map(([k, v]: [string, any]) => {
        if (isRetailerIdSearch && k === 'floorplan_availability') {
          retailerInStock = [{ floorplan_retailers_in_stock: retailer?.id }];
        }
        if (Array.isArray(v) && v.includes('3d Tours')) {
          filter3dTour = true;
          const updatedV = v.filter((filter) => filter !== '3d Tours');
          if (updatedV.length) {
            return { [k]: updatedV };
          }
          return null;
        }
        return { [k]: v };
      });

    const anyFilter: any = [];

    if (filter3dTour) {
      anyFilter.push({ media: ['3d Tours', 'Pana Tour'] });
    }

    setQueryString(
      buildQueryString(
        {
          ...currentFiltersRequestItems.current,
          ...currentGeneralRequestItems.current,
          page: currentPage ?? paginationState.current.current,
        },
        facetConfigInstance
      )
    );

    setQueryStringItems(getQueryStringValues(window.location.search, isRetailerIdSearch));

    const retailerids = isRetailerIdSearch ? [{ retailer_ids: [retailer.id] }] : [];
    const subTypeFilter = listSubType ? [{ inventory_type: listSubType }] : [];

    currentElasticSearchRequest.current = {
      all: [
        { type: listType },
        ...subTypeFilter,
        ...forRequest,
        ...retailerids,
        ...retailerInStock,
      ],
      any: anyFilter,
    };

    setElasticSearchRequest({
      facets: { ...esFacetConfig, ...baseLocationFacet },
      page: paginationState.current,
      sort: currentSortRequest.current,
      filters: currentElasticSearchRequest.current,
      query: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // HANDLE SEARCH CHANGES //
  const handleSearchChange = (data: any) => {
    const { lat, lng, address } = data;
    const current = currentFiltersRequestItems.current?.search;
    const value = { ...facetConfigInstance.search.mapper({ lat, lng, unit: localization.unit }) };

    const item = current
      ? {
          ...current,
          ...value,
        }
      : value;

    currentFiltersRequestItems.current = {
      ...currentFiltersRequestItems.current,
      search: item,
    };

    currentGeneralRequestItems.current = {
      ...currentGeneralRequestItems.current,
      name: address,
    };

    setPrimed(true);
    processCurrentRequestForElasticSearch();
  };

  const handleDisplayMediaModal = ({ isVideo, mediaSrc, mediaTitle }: MediaModalState) => {
    setMediaModalState({
      isOpen: true,
      isVideo,
      mediaSrc,
      mediaTitle,
    });
  };

  const handleCloseMediaModal = () => {
    setMediaModalState({
      isOpen: false,
      isVideo: false,
      mediaSrc: '',
      mediaTitle: '',
    });
  };

  const detectFacetChange = (facets: any) => {
    if (!facets) return false;
    const params = new URL(window.location.href).searchParams;
    let hasChanged = false;
    const check = (newFacets: any, queryFacets: any) => {
      newFacets.forEach((f: any) => {
        if (!queryFacets.get(f) || queryFacets.get(f) !== `[${facets[f]}]`) {
          hasChanged = true;
        }
      });
      return hasChanged;
    };
    return check(Object.keys(facets), params);
  };

  const handleSelectedFacetChange = (updatedFacets: any, apply = false) => {
    // pull out the current search filter...
    const { search } = currentFiltersRequestItems.current;
    const searchFilter = search ? { search } : {};
    if (detectFacetChange(updatedFacets)) resetPagination();

    // if no distance values are set, insert 3000..
    const adjustedForLocations =
      hasLocationFilter && !updatedFacets.hasOwnProperty('locations')
        ? {
            ...updatedFacets,
            locations: [facetConfigInstance.locations.ui.defaultValue],
          }
        : { ...updatedFacets };
    // update the current filter object...
    currentFiltersRequestItems.current = Object.entries(adjustedForLocations).reduce(
      (memo: any, [k, v]: [string, any]) => {
        // apply the mapper to the current value...
        const value: any = facetConfigInstance[k]?.mapper(v);
        const isDefault = k !== 'locations' && checkIsDefaultValue(k, value);
        const newItem = isDefault ? {} : { [k]: value };
        // return it with the update value and add the search back in...
        return {
          ...memo,
          ...newItem,
          ...searchFilter,
        };
      },
      {}
    );

    setPrimed(apply);
    processCurrentRequestForElasticSearch();
  };

  const processInitialData = () => {
    const { latitude, longitude, locations, page, sort, ...filters } = queryStringItems.facets;
    const newUrl = new URL(window.location.href);
    const sessionPage = newUrl.searchParams.get('page');
    const usePage = sessionPage != null ? +sessionPage : 1;

    paginationState.current = {
      ...paginationState.current,
      current: usePage || 1,
    };

    const defaultSortConfig =
      pageTypeDefaultSortConfigs[facetBarConfig.primaryType][facetBarConfig.secondaryType] || [];
    const defaultSort = defaultSortConfig.length
      ? defaultSortConfig
      : allPageSort.sort.defaultValue;

    const locationFilter = hasLocationFilter
      ? buildUiLocationFilters({
          type: listType,
          lat: latitude || retailer?.lat || null,
          lng: longitude || retailer?.lng || null,
          unit: localization.unit || 'mi',
          id: retailer?.id || null,
          distance: facetConfigInstance.locations.ui.defaultValue,
        })
      : {};

    currentFiltersRequestItems.current = isRetailerIdSearch
      ? buildRetailerIdsFilter(retailer?.id)
      : locationFilter;

    currentGeneralRequestItems.current = {
      ...queryStringItems.general,
    };

    currentSortRequest.current = sort ? [sort] : defaultSort;

    initialSelected.current = {
      ...filters,
      ...(hasLocationFilter
        ? {
            locations: locations || facetConfigInstance.locations.ui.defaultValue,
          }
        : {}),
    };

    handleSelectedFacetChange(initialSelected.current, true);
  };

  useEffect(() => {
    processInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveSortConfig = (sortData: any) => {
    const sortKey = Object.keys(sortData)?.[0];
    const sortVal = sortData[sortKey];
    const sortConfig =
      pageTypeActiveSortConfigs[facetBarConfig.primaryType][facetBarConfig.secondaryType] ||
      undefined;
    if (!sortConfig || !sortConfig?.[sortKey]?.[sortVal]) {
      return sortData;
    }

    return sortConfig?.[sortKey]?.[sortVal];
  };

  const handleSort = (data: any) => {
    currentSortRequest.current = getActiveSortConfig(data);
    resetPagination();
    setPrimed(true);
    processCurrentRequestForElasticSearch();
  };

  const scrollToAnchor = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const handleChangePage = (event: any, value: number) => {
    paginationState.current = {
      ...paginationState.current,
      current: value,
    };

    setPrimed(true);
    processCurrentRequestForElasticSearch();
    scrollToAnchor();
  };

  const handleChangeRowsPerPage = (value: number) => {
    paginationState.current = {
      ...paginationState.current,
      size: value,
    };

    setPrimed(true);
    processCurrentRequestForElasticSearch();
    scrollToAnchor();
  };

  return (
    <div className="cavcohomes-floorplan-search" id="floorPlanResultsTop">
      {!!facetState?.facets && (
        <FloorPlanFacetBar
          currentAddress={queryStringItems.general?.name}
          facets={facetState?.facets}
          selected={initialSelected.current}
          metaData={currentMeta}
          filterChange={(filters: any, apply = false) => handleSelectedFacetChange(filters, apply)}
          hasSearchZip={hasSearch}
          handleSearch={(search: any) => handleSearchChange(search)}
          handleSetFacets={() => setResults()}
          componentData={componentData}
          configData={configData}
          isMobile={isMobile || isTablet}
        />
      )}
      <div className="floorplan-sort-bar">
        <div className="sort-bar__count">
          {currentMeta?.count > 0 ? (
            <>
              <span className="sort-bar__count-number">{currentMeta?.count}</span>
              <span> {countText}</span>
            </>
          ) : (
            <span>0 results found. Please try again.</span>
          )}
        </div>
        <FloorPlanSort config={allPageSort.sort} sortBy={null} changeSort={handleSort} />
      </div>

      <section className="floorplan-results">
        {!elasticSearchLoading && facetState?.results?.length === 0 && (
          <div className="no-results-found">No Results Found.</div>
        )}

        <MediaModal
          show={mediaModalState.isOpen || false}
          isVideo={mediaModalState.isVideo}
          title={mediaModalState.mediaTitle || ''}
          mediaSrc={mediaModalState.mediaSrc}
          handleClose={handleCloseMediaModal}
        />

        <div className="cavcohomes-floorplan-list">
          {!primed &&
            facetState?.results?.length > 0 &&
            facetState?.results?.map((result: any, idx: number) => (
              <ProductResultCard
                result={result}
                handleMediaClick={handleDisplayMediaModal}
                myAccountConfig={myAccountConfig}
                type="floorplan"
                isMicroSite={isMicroSite}
                key={`item_${idx}`}
                basePath={retailerBasePath}
                isRetailer={isRetailerIdSearch}
                retailerId={retailer?.id}
              />
            ))}
        </div>
        <div>
          {!elasticSearchLoading && facetState?.results?.length > 0 && (
            <FloorPlanPagination
              config={allPageSort.page}
              totalPages={currentMeta?.pages || 0}
              currentPage={currentMeta?.current || 0}
              rowsPerPage={paginationState.current.size}
              changePage={handleChangePage}
              changeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </section>
    </div>
  );
}

export function FloorPlanResultsCMS({ component, page: brPage }: BrProps) {
  const resourceBundleData: ResourceBundleOutputProps = mapResourceBundleData({
    component,
    page: brPage,
  });

  const { myaccountconfiguration } = component.getModels();

  const myAccountValuelist =
    getPageContentData<ValueListData>(myaccountconfiguration, brPage)?.items || [];

  const myAccountConfig = buildValueListToObject(myAccountValuelist);

  const { esconfiguration, configuration, componentParameterMap, retailer, ...bundleData } =
    resourceBundleData;

  const listType = componentParameterMap.listType || 'floorplan';
  const listSubType = componentParameterMap.listSubType || null;

  const isRetailerIdSearch: any = !!retailer?.id;
  const initialQueryStringValues = getQueryStringValues(useLocation().search, isRetailerIdSearch);
  const { facets }: any = initialQueryStringValues;

  const hasCenter = !!(facets?.latitude || retailer?.lat);

  const primaryType = retailer?.id ? 'retailer' : 'main';
  const secondaryType = listSubType || 'floorplan';
  const isRetailerSearch: any = !!retailer?.id;
  const retailerBasePath = findRetailerBasepath(brPage);

  // we don't want a location filter if it is a retailer search of if no lat/lng info is provided
  const hasLocationFilter: boolean = !isRetailerSearch && hasCenter;

  const defaultValueOverrides: any = {
    locations: hasLocationFilter ? [100] : allFacets.locations.ui?.defaultValue,
  };

  const facetConfigInstance: any = Object.entries(allFacets).reduce((memo: any, [k, v]) => {
    const value = {
      ...v,
      ui: {
        ...v.ui,
        defaultValue: defaultValueOverrides[k] || v.ui?.defaultValue,
      },
    };
    return {
      ...memo,
      [k]: value,
    };
  }, {});

  const uiBaseFacetConfig = getFacets(
    primaryType,
    secondaryType,
    'ui',
    facetConfigInstance,
    !hasLocationFilter
  );

  // basic elastic search facet data based on the facets requested by this facet bar...
  // is sent with the API request to ES
  // tells ES what facets we need returned in the response
  // the last param is an override to hide the location filter. static config will display it for non-retailer searches
  // in the event that no lat/long info is available, however, we need to remove it.
  const esBaseFacetConfig = getFacets(
    primaryType,
    secondaryType,
    'elasticSearch',
    facetConfigInstance,
    !hasLocationFilter
  );

  const localization = getLocalizationData(useLocation().pathname, 'searchData');

  const coordinateFacets = {
    lat: facets?.latitude || retailer?.lat,
    lng: facets?.longitude || retailer?.lng,
  };

  const locationFacet = hasLocationFilter
    ? buildLocationFacet({
        ...coordinateFacets,
        base: esBaseFacetConfig?.locations[0],
        unit: localization.unit,
      })
    : {};

  // the last param is an override to hide the location filter. static config will display it for non-retailer searches
  // in the event that no lat/long info is available, however, we need to remove it.
  const allEsFiltersArray = buildBaseFiltersArray({
    ...coordinateFacets,
    type: listType,
    subtype: listSubType,
    unit: localization.unit,
    id: retailer?.id,
    facetConfig: facetConfigInstance,
  });

  const esInitialBaseFacets: any = buildESRequest({
    facets: {
      ...esBaseFacetConfig,
      ...locationFacet,
    },
    page: { size: 5, current: 1 },
    sort: allPageSort.sort.defaultValue,
    filters: allEsFiltersArray,
  });

  // elastic search object for the faceted search...
  const elasticSearch = useFetch(esconfiguration);

  const configData = {
    googleMapsConfig: configuration,
    uiBaseFacetConfig,
    esBaseFacetConfig,
    initialQueryStringValues,
    listType,
    listSubType,
    facetBarConfig: {
      primaryType,
      secondaryType,
    },
    retailer,
    baseLocationFacet: locationFacet,
    facetConfigInstance,
  };

  const componentData = {
    localization,
    ...mapResourceBundleToItem(bundleData),
  };

  // elastic search object for the initial request for all facets. This has to be separate from the main search
  const { elasticSearchResponse, setElasticSearchRequest, elasticSearchLoading } =
    useFetch(esconfiguration);

  // fetch all the initial facets and pass to the facet bar
  const allFilters = useMemo(() => {
    return elasticSearchResponse ? allFacetsMapper(elasticSearchResponse.rawInfo.facets) : {};
  }, [elasticSearchResponse]);

  useEffect(() => {
    setElasticSearchRequest(esInitialBaseFacets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterConfig = filterConfiguration[primaryType][secondaryType];

  return !elasticSearchLoading && Object.keys(allFilters).length ? (
    <FloorPlanResultsCH
      componentData={componentData}
      configData={configData}
      uiFacetConfig={uiBaseFacetConfig}
      esFacetConfig={esBaseFacetConfig}
      myAccountConfig={myAccountConfig}
      allFilters={allFilters}
      elasticSearch={elasticSearch}
      filterConfig={filterConfig}
      retailer={retailer}
      isRetailerIdSearch={isRetailerIdSearch}
      hasLocationFilter={hasLocationFilter}
      initialQueryStringValues={initialQueryStringValues}
      retailerBasePath={retailerBasePath}
    />
  ) : (
    <div className="cavcohomes-floorplan-list__spinner">
      <Spinner color="#ffc72c" />
    </div>
  );
}
