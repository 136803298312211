import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Element } from 'react-scroll';
import 'components/shared/ProductDetails/ProductFiles.scss';
import { ResolverLink } from 'components/shared';

function getFiles(pdfs: any) {
  return pdfs.map((pdf: any, indexPdf: number) => {
    const { id, uploadSize: fileSize, uploadFileName, fileName } = pdf;
    if (fileName === null || fileName.length === 0) return null;
    const fileSizeStr = fileSize < 1000 ? `${fileSize} KB` : `${(fileSize / 1024).toFixed(1)} MB`;

    return (
      <li key={`${id}-${indexPdf.toString()}`}>
        <ResolverLink target="_blank" href={uploadFileName}>
          {fileName}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function DownloadFiles(props: any) {
  return (
    <Element name="download" className="cvc-download-files">
      <h2>{props.title}</h2>
      <ul className="cvc-download-files-list">{getFiles(props.files)}</ul>
    </Element>
  );
}

export function FloorPlanFiles(props: BrProps) {
  const { series, floorplan, floorPlanFilesLabels } = props.component.getModels();
  const assets = series && series[0] && props.page.getContent(series[0]);
  const floorplanFiles = props.page.getContent(floorplan);
  const { title } = floorPlanFilesLabels;
  let pdfs;
  if (!assets && !floorplanFiles) {
    return null;
  }
  if (assets && !floorplanFiles) {
    pdfs = assets.getData().assets;
  } else if (!assets && floorplanFiles) {
    pdfs = floorplanFiles.getData().floorplanFiles;
  } else if (assets && floorplanFiles) {
    const seriesFiles = assets.getData().assets;
    const planFiles = floorplanFiles.getData().floorplanFiles;
    pdfs = seriesFiles.concat(planFiles);
  }
  // validate that at least one entry exists with a valid filename
  for (let i = 0; pdfs && i < pdfs.length; i += 1) {
    if (pdfs[i].fileName != null && pdfs[i].fileName.length > 0) {
      return <DownloadFiles title={title} files={pdfs} />;
    }
  }
  return null;
}
