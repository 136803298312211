import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import './FloorPlanLineDrawings.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';

function displayFloorPlanIMage(item: any) {
  return <img alt={item.imageAltText} src={item.ximage.medium.url} />;
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  index: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="presentation"
      key={`${imageGalleryName}-linedrawings-container-${index.toString()}`}
      className="cvc-floorplan-linedrawings-item-container"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
    >
      <div
        key={`${imageGalleryName}-linedrawing-${index}`}
        className="cvc-floorplan-linedrawings-media-item"
        tabIndex={-1}
      >
        {item.ximage ? displayFloorPlanIMage(item) : ''}
      </div>
      <div
        key={`${imageGalleryName}-floorplan-linedrawing-item-link-${item.id}`}
        className="cvc-floorplan-linedrawings-item-link"
        tabIndex={-1}
      />
    </div>
  );
}

function displayMediaAssets(
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.IMAGE) return '';

  return mediaAssetItems.map((item: any, index) => {
    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      index,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function FloorPlanLineDrawings(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { documents, floorPlanLineDrawingsLabels } = props.component.getModels();

  const imageGalleryName = props.component.getName();

  const ref = documents[0];
  const document = ref ? props.page.getContent(ref) : undefined;

  if (!document) {
    return null;
  }

  const { lineDrawings: lineDrawingsRef } = document.getData<FloorPlanData>();

  const lineDrawingsDocument = lineDrawingsRef && props.page.getContent(lineDrawingsRef);

  const floorPlanPhotosClasses = classNames({
    'cvc-floorplan-linedrawings px-3 pl-lg-5 pr-lg-5 pb-lg-5': true,
    container:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig,
  });

  if (!lineDrawingsDocument) {
    const floorplanMissinglinedrawingTextContainer = classNames({
      'cvc-floorplan-missinglinedrawing-text-container': true,
    });
    const missingDocumentText1 =
      floorPlanLineDrawingsLabels && floorPlanLineDrawingsLabels.lineDrawingsMissingText1
        ? floorPlanLineDrawingsLabels.lineDrawingsMissingText1
        : 'Please contact';

    const missingDocumentText2 =
      floorPlanLineDrawingsLabels && floorPlanLineDrawingsLabels.lineDrawingsMissingText2
        ? floorPlanLineDrawingsLabels.lineDrawingsMissingText2
        : 'your local retail location';

    const missingDocumentText3 =
      floorPlanLineDrawingsLabels && floorPlanLineDrawingsLabels.lineDrawingsMissingText3
        ? floorPlanLineDrawingsLabels.lineDrawingsMissingText3
        : 'for the floor plan';

    return (
      <div className={floorPlanPhotosClasses}>
        <div className={floorplanMissinglinedrawingTextContainer}>
          <div>
            {missingDocumentText1} <br />
            {floorPlanLineDrawingsLabels && floorPlanLineDrawingsLabels.lineDrawingsMissingText2
              ? missingDocumentText2
              : // <Link to="/find-a-location">{missingDocumentText2} </Link>
                { missingDocumentText2 }}{' '}
            {missingDocumentText3}
          </div>
        </div>
      </div>
    );
  }

  const { id, title, mediaType, mediaAsset } = lineDrawingsDocument.getData<AlbumData>();

  const mediaAssetItems = mediaAsset;
  const noLineDrawings = mediaAsset.find((item) => item.ximage) === undefined;

  if (noLineDrawings) {
    return null;
  }

  const floorItemsContainerClasses = classNames({
    'cvc-floorplan-linedrawings-items-container': true,
  });

  return (
    <div
      id={`${imageGalleryName}-${id}`}
      className={floorPlanPhotosClasses}
      key={`${imageGalleryName}-${id}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={ALBUM_MEDIA_TYPES.LINE_DRAWING}
        mediaAssetItems={mediaAssetItems}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
      />
      <div className="cvc-floorplan-linedrawings-details-container">
        <div className={floorItemsContainerClasses}>
          {displayMediaAssets(
            imageGalleryName,
            mediaType,
            mediaAssetItems,
            setIndexItemSelected,
            handleShowMediaViewer
          )}
        </div>
      </div>
    </div>
  );
}
