import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Card } from 'react-bootstrap';
import './ViewedHomeCards.scss';
import { ResolverLink } from 'components/shared';
import { CardBody } from './CardBody';
import { CardImage } from './CardImage';

interface FloorPlanCardProps {
  floorPlanDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  className?: string;
  hideHeart?: boolean;
}

export function FloorPlanCard(floorPlanCardProps: FloorPlanCardProps) {
  const {
    id,
    url,
    modelName,
    modelNumber,
    photoUrls,
    lineDrawingUrl: firstLineDrawingImage,
    videoAlbum: videosRef,
    tourAlbum: toursRef,
    numBedrooms,
    numBathrooms,
    squareFootage,
  } = floorPlanCardProps.floorPlanDocumentData;

  const floorPlanUrl = url;

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  if (!hasPhoto && firstLineDrawingImage) {
    imageDefault = firstLineDrawingImage;
  }

  const floorPlanTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;

  return (
    <Card
      id={id}
      className={`vh-recent-card ${!hasPhoto && imageDefault !== null && 'line-drawing'}`}
    >
      <CardImage className="vh-recent-image-link" image={imageDefault} url={floorPlanUrl} />
      <CardBody
        title={floorPlanTitle}
        url={floorPlanUrl}
        numBedrooms={numBedrooms}
        numBathrooms={numBathrooms}
        squareFootage={squareFootage}
        hasPhoto={hasPhoto}
        videosRef={videosRef}
        toursRef={toursRef}
        isMobile={floorPlanCardProps.windowDimensions.isMobile}
      />
    </Card>
  );
}

interface DisplayHomeCardProps {
  displayHomeDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  className?: string;
  hideHeart?: boolean;
}

export function DisplayHomeCard(displayHomeCardProps: DisplayHomeCardProps) {
  const {
    id,
    url,
    modelName,
    modelNumber,
    photoUrls,
    videoAlbum,
    tourAlbum,
    panatourAlbum,
    numBedrooms,
    numBathrooms,
    squareFootage,
    availableStatus,
    markAsClearance,
    nowPrice,
    wasPrice,
    sellingPrice,
  } = displayHomeCardProps.displayHomeDocumentData;

  // Create our currency formatter.
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const floorPlanUrl = url;

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  const isSold = availableStatus.toLowerCase() === 'sold';
  const soldHomeClassName = isSold ? ' is-sold' : '';

  const displayHomeTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;

  const handleCardPrice = () => {
    if (isSold) {
      // do all the different styling
      return (
        <span className="display-home-price-sold product-price-sold">
          Sold<span className="price-asterisk">**</span>
        </span>
      );
    }
    if (sellingPrice) {
      if (markAsClearance && nowPrice && wasPrice) {
        return (
          // do the styling for sale price
          <div className="display-home-price-clearance product-price-clearance">
            <span className="now-price">
              <span className="currency-price-tag">{currencyFormatter.format(nowPrice)}</span>
              <span className="price-asterisk">**</span>
            </span>
            <span className="was-price">
              Was <span className="currency-price-tag"> {currencyFormatter.format(wasPrice)}</span>
              <span className="price-asterisk">**</span>
            </span>
          </div>
        );
      }
      if (Number.isNaN(parseInt(sellingPrice, 10))) {
        // plain text
        return (
          <span className="textual-price">
            {sellingPrice}
            <span className="price-asterisk">**</span>
          </span>
        );
      }
      // format to money
      return (
        <span>
          <span className="currency-price-tag">{currencyFormatter.format(sellingPrice)}</span>
          <span className="price-asterisk">**</span>
        </span>
      );
    }
  };

  return (
    <Card
      id={id}
      className={`vh-recent-card ${!hasPhoto && imageDefault !== null && 'line-drawing'}`}
    >
      <ResolverLink href={floorPlanUrl}>
        <div className="display-home-price-container product-price-container">
          {handleCardPrice()}
        </div>
      </ResolverLink>
      <CardImage
        className={`vh-recent-image-link${soldHomeClassName}`}
        image={imageDefault}
        url={floorPlanUrl}
      />
      <CardBody
        title={displayHomeTitle}
        url={floorPlanUrl}
        numBedrooms={numBedrooms}
        numBathrooms={numBathrooms}
        squareFootage={squareFootage}
        hasPhoto={hasPhoto}
        videosRef={videoAlbum}
        toursRef={tourAlbum}
        panatourRef={panatourAlbum}
        isMobile={displayHomeCardProps.windowDimensions.isMobile}
        classes={soldHomeClassName}
      />
    </Card>
  );
}
