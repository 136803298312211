import { BrProps } from '@bloomreach/react-sdk';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { GetFirstImgUrl, getImageUrl } from '../FloorPlanBanner/FloorPlanBanner';
import './CavcoDetailImage.scss';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { GalleryViewer } from '../FloorPlanBanner/GalleryViewer';

function getPattern(n: number) {
  if (n === 1) {
    return `"item1 item1 item1 item1" "item1 item1 item1 item1"`;
  }
  if (n === 2) {
    return `"item1 item1 item2 item2" "item1 item1 item2 item2"`;
  }
  if (n === 3) {
    return `"item2 item2 item1 item1"
         "item2 item2 item3 item3"`;
  }
  if (n === 4) {
    return `"item2 item2 item1 item3"
        "item2 item2 item4 item4"`;
  }
  return `"item2 item2 item1 item3"
        "item2 item2 item4 item5"`;
}

const joinAllImages = (listPhotos: any, listLineDrawings: any) => {
  if (listPhotos && listLineDrawings) {
    const mergedImages = [...listPhotos];
    mergedImages.unshift(...listLineDrawings);
    return mergedImages;
  }
  if (listPhotos && !listLineDrawings) {
    return listPhotos;
  }

  if (!listPhotos && listLineDrawings) {
    return listLineDrawings;
  }
  return undefined;
};

export function CavcoDetailImageGrid(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = React.useState(false);
  const [indexItemSelected, setIndexItemSelected] = useState(0);

  const { documents } = props.component.getModels();

  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;
  if (!document) {
    return null;
  }
  const { photos, lineDrawings, photoAlbum } = document.getData<BannerData>();
  const usePhotos = photos || photoAlbum;
  const photosDocument = usePhotos ? props.page.getContent(usePhotos) : undefined;
  const lineDrawingsDocument = lineDrawings ? props.page.getContent(lineDrawings) : undefined;

  const imageMediaAsset = photosDocument?.getData<AlbumData>().mediaAsset;

  const lineDrawingsMediaAsset = lineDrawingsDocument?.getData<AlbumData>().mediaAsset;

  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);

  const getImagesToShow = (images: any) => {
    if (images) {
      if (images.length <= 5) {
        return images;
      }
      return images.slice(0, 5);
    }
  };

  const imagesCarousel = joinAllImages(imageMediaAsset, lineDrawingsMediaAsset);
  if (imagesCarousel) {
    [...imagesCarousel].forEach((item: any) => {
      item.mediaType = ALBUM_MEDIA_TYPES.IMAGE;
    });
  }
  const imagesToShow = getImagesToShow(imagesCarousel);
  const imagesToShowSize = imagesToShow ? imagesToShow.length : 0;
  const pattern = getPattern(imagesToShowSize);

  const handleMediaItemClick = (index: number) => {
    setIndexItemSelected(Number(index));
    handleShowMediaViewer();
  };

  return (
    <>
      {imagesCarousel && (
        <GalleryViewer
          show={showMediaViewer}
          onHide={handleCloseMediaViewer}
          mediaType={ALBUM_MEDIA_TYPES.IMAGE}
          mediaAssetItems={imagesCarousel}
          albumName=""
          albumLink=""
          hasParentPageFloorPlan={false}
          indexItemSelected={indexItemSelected}
          spinnerColorInDarkBackground="black"
          headerComponentType="album"
        />
      )}

      <div
        className={`image-grid-container ${imagesToShowSize <= 2 ? 'contained' : ''} ${
          imagesToShowSize < 1 ? 'empty' : ''
        }`}
        style={{ display: 'grid', gridTemplateAreas: pattern }}
      >
        {imagesToShow?.map((item: any, index: number) => {
          const imgContent: any = item && props.page.getContent(item.image);
          const imgLink = getImageUrl(item, imgContent, 'medium');
          const carouselImageId = `id-${index}`;
          return (
            <div
              className="carousel-image"
              key={carouselImageId}
              role="button"
              onClick={() => handleMediaItemClick(index)}
              tabIndex={index}
              onKeyDown={() => handleMediaItemClick(index)}
            >
              {index === imagesToShow.length - 1 &&
                imagesCarousel.length - imagesToShow.length > 0 && (
                  <div className="gallery-more-images">
                    <h3>+{imagesCarousel.length - imagesToShow.length} Photos</h3>
                  </div>
                )}
              <img src={imgLink} alt={item.alt ?? item.imageAltText} />
            </div>
          );
        })}
      </div>
    </>
  );
}

export function CavcoDetailImage(props: BrProps) {
  const { documents } = props.component.getModels();

  const [showMediaViewer, setShowMediaViewer] = React.useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);

  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;

  if (!document) {
    return null;
  }

  const { photos, lineDrawings, photoAlbum } = document?.getData<BannerData>();

  const usePhotos = photos || photoAlbum;
  const photosDocument = photos ? props.page.getContent(photos) : undefined;
  const photoAlbumDocument = photoAlbum ? props.page.getContent(photoAlbum) : undefined;
  const usePhotoDocument = photosDocument || photoAlbumDocument;
  const lineDrawingsDocument = lineDrawings ? props.page.getContent(lineDrawings) : undefined;
  const image = usePhotos && GetFirstImgUrl(props, usePhotos);
  function displayMediaAsset(mediaAssetItems: MediaAssetCompound[], handleMediaItemClick: any) {
    return mediaAssetItems.map((item: any, index: number) => {
      const imgContent: any = item && props.page.getContent(item.image);
      const imgLink = getImageUrl(item, imgContent, 'medium');
      return (
        <SwiperSlide key={`slide-image-${index.toString()}`}>
          <div
            onClick={() => {
              handleMediaItemClick(index);
            }}
            role="button"
            tabIndex={index}
            onKeyDown={() => handleMediaItemClick(index)}
          >
            <img className="carousel-image" src={imgLink} alt="" />
          </div>
        </SwiperSlide>
      );
    });
  }

  const handleMediaItemClick = (index: number) => {
    setIndexItemSelected(Number(index));
    handleShowMediaViewer();
  };
  if ((usePhotoDocument && image) || lineDrawingsDocument) {
    let photoAsset;
    if (usePhotoDocument) {
      const { mediaAsset } = usePhotoDocument.getData<AlbumData>();
      photoAsset = mediaAsset;
    }

    const lineDrawingsMediaAsset =
      lineDrawingsDocument && lineDrawingsDocument?.getData<AlbumData>().mediaAsset;
    const allImages = joinAllImages(photoAsset, lineDrawingsMediaAsset);
    return (
      <div className="floorplan-images-carousel">
        {allImages && (
          <GalleryViewer
            show={showMediaViewer}
            onHide={handleCloseMediaViewer}
            mediaType={ALBUM_MEDIA_TYPES.IMAGE}
            mediaAssetItems={allImages}
            albumName=""
            albumLink=""
            hasParentPageFloorPlan={false}
            indexItemSelected={indexItemSelected}
            spinnerColorInDarkBackground="black"
          />
        )}
        <Swiper
          effect="coverflow"
          grabCursor
          slidesPerView="auto"
          spaceBetween={10}
          className="floorplan-image-swiper"
          modules={[Pagination, Navigation]}
          pagination={{
            type: 'fraction',
          }}
        >
          {allImages && displayMediaAsset(allImages, handleMediaItemClick)}
        </Swiper>
      </div>
    );
  }
  return null;
}
