import React from 'react';

import { BrProps } from '@bloomreach/react-sdk';

// import { getRetailerData } from '../utils';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { FloorPlanCard } from './FloorPlanCard';
import './FeatureModels.scss';
import { InventoryCard } from './InventoryCard/InventoryCard';

function buildNoRetailersMessage(props: BrProps, noDocuments: any) {
  const document = props.page.getContent(noDocuments);
  if (document) {
    const contentVal = document.getData()!.content!.value;
    return (
      <div>
        <h2>{`${document.getData()!.title}`}</h2>

        <div dangerouslySetInnerHTML={{ __html: contentVal }} />
      </div>
    );
  }
  return <div>No Retailers found nearby</div>;
}

function formatRetailerFloorplanUrl(floorplan: any) {
  if (floorplan && !floorplan.linkedToCavcohomes) {
    const floorplanUrlSegment = floorplan.url.slice(floorplan.url.lastIndexOf('/'));

    const windowPathNameWithoutSlash = window.location.pathname.replace(/\/$/, '');
    const newFloorPlanUrl = `${windowPathNameWithoutSlash}/floorplans${floorplanUrlSegment}`;

    floorplan.url = newFloorPlanUrl;
  }
}

export function isWithinRange(startTime: number, endTime: number) {
  const nowTime: number = Date.now();
  return startTime <= nowTime && nowTime <= endTime;
}

/* function showFloorplanFeature(props: BrProps) {
  const retailerData = getRetailerData(props.page);

  const { documents, featureModelsLabel: lbl } = props.component.getModels();

  const thisFloorPlan = lbl && lbl.this_floor_plan ? lbl.this_floor_plan : 'This Floor Plan';
  const currencyLocale = lbl && lbl.currency_locale ? lbl.currency_locale : 'en-US';
  const currencyCode = lbl && lbl.currency_code ? lbl.currency_code : 'USD';
  const startingFrom = lbl && lbl.starting_from ? lbl.starting_from : 'Starts From';

  const ref = documents[0];
  const document = ref && props.page.getContent(ref);

  if (!document) {
    return null;
  }
  const { assetId: fpAssetId } = document.getData<FloorPlanData>();

  let sp = -1;
  if (
    fpAssetId &&
    retailerData &&
    isWithinRange(retailerData.featureModelsStartDate, retailerData.featureModelsEndDate)
  ) {
    if (fpAssetId === retailerData.featureModel1AssetId) {
      sp = retailerData.featureModel1StartingPrice;
    } else if (fpAssetId === retailerData.featureModel2AssetId) {
      sp = retailerData.featureModel2StartingPrice;
    }
  }

  if (sp < 0) {
    return null;
  }

  const startingPrice = new Intl.NumberFormat(currencyLocale, {
    style: 'currency',
    currency: currencyCode,
  }).format(sp);
  return (
    <div className="cvc-fp-feature-model">
      <h2 className="cvc-fp-feature-model-title">
        <span className="cvc-fp-feature-model-title-prefix">
          {thisFloorPlan} {startingFrom}
        </span>
        <strong className="cvc-fp-feature-model-title-amount">
          {startingPrice}
          <span className="price-asterisk">**</span>
        </strong>
      </h2>
    </div>
  );
} */

export function FeatureModels(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  // eslint-disable-next-line
  // @ts-ignore
  // const label = props.component.getType();
  // const isFloorplanPage = /Floorplan/i.test(label);

  // if (isFloorplanPage) {
  //   return showFloorplanFeature(props);
  // }

  const {
    featuredFloorPlanCards,
    featuredInventoryCards,
    featureModelsLabel: lbl,
    noDocuments,
  } = props.component.getModels();

  const floorPlanDocumentsModelVal =
    [featuredFloorPlanCards].find((list) => list && list.length) || null;
  const inventoryDocumentsModelVal =
    [featuredInventoryCards].find((list) => list && list.length) || null;

  const noDocumentsModelVal = noDocuments || null;

  if (!floorPlanDocumentsModelVal && !inventoryDocumentsModelVal) return null;
  const floorplanDocuments = floorPlanDocumentsModelVal || [];
  const inventoryDocuments = inventoryDocumentsModelVal || [];

  const mapFloorPlanToCard = (floorplan: any, index: number) => (
    <FloorPlanCard
      title="See Floor Plan Details"
      key={`floorplan-card-${index.toString()}`}
      floorPlanDocumentData={floorplan}
      windowDimensions={windowDimensions}
      props={props}
      label={lbl}
    />
  );

  const mapFloorPlanToCards = (floorplans = []) =>
    floorplans
      .map((floorplan: any, index) => {
        formatRetailerFloorplanUrl(floorplan);

        return mapFloorPlanToCard(floorplan, index);
      })
      .reverse();

  const mapInventoryToCard = (inventory: any, index: number) => {
    const inventoryType =
      inventory.inventoryType === 'Stock' ? 'Display Home' : 'Move-In Ready Home';
    return (
      <InventoryCard
        title={`See ${inventoryType} Details`}
        key={`floorplan-card-${index.toString()}`}
        inventoryDocumentData={inventory}
        windowDimensions={windowDimensions}
        props={props}
        label={lbl}
      />
    );
  };

  const mapInventoryToCards = (inventories = []) =>
    inventories
      .map((inventory: any, index) => {
        // formatRetailerFloorplanUrl(inventory);
        inventory.url = window.location.pathname.concat(inventory.url);
        return mapInventoryToCard(inventory, index);
      })
      .reverse();

  const mapFeatureModelsToCards = (floorplans = [], inventory = []) => {
    if (floorplans.length) {
      return mapFloorPlanToCards(floorplans);
    }
    return mapInventoryToCards(inventory);
  };

  return (
    <div className="cvc-fp-feature-models">
      <h2 className="cvc-fp-feature-models-title">
        <em>{`${lbl.feature} `}</em>
        {lbl.models}
      </h2>
      {!floorPlanDocumentsModelVal && !inventoryDocumentsModelVal ? (
        buildNoRetailersMessage(props, noDocumentsModelVal)
      ) : (
        <div className="feature-model-cards-container">
          {mapFeatureModelsToCards(floorplanDocuments, inventoryDocuments)}
        </div>
      )}
    </div>
  );
}
