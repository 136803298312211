import React from 'react';
import { Link } from '@material-ui/core';
import './CavcoLink.scss';
import { useHistory } from 'react-router-dom';

interface CavcoLinkProps {
  label: string;
  variant?: 'default' | 'yellow' | 'white';
  link: string;
  newWindow: boolean;
}

export function CavcoLink(props: CavcoLinkProps) {
  const history = useHistory();
  const { label, variant } = props;

  const handleClick = () => {
    history.push(props.link);
  };

  return (
    <div className="cavco-link-container" role="button" tabIndex={0}>
      <Link
        className={`link ${variant}`}
        {...(props.newWindow ? { href: props.link } : {})}
        {...(props.newWindow ? { target: '_blank' } : {})}
        {...(!props.newWindow ? { onClick: handleClick } : {})}
      >
        {label}
      </Link>
    </div>
  );
}
