import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import 'components/shared/ProductDetails/ProductFacts.scss';
import { getDisplayHomeDataModel } from 'components/utils';

export function DisplayHomeFacts(props: BrProps) {
  const { displayHomeFactsLabels } = props.component.getModels();
  const { displayHomeData: dhData, document: ref } = getDisplayHomeDataModel(props.page);
  const dhDoc = props.page.getContent(ref);

  if (!dhData || !dhDoc) {
    return null;
  }

  // Create our currency formatter.
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const { specialFeatures } = dhDoc.getData<InventoryData>();

  const {
    sellingPrice,
    availableStatus,
    squareFootage,
    builder,
    numBedrooms,
    numBathrooms,
    sections,
    buildingMethodValue,
    modelName,
    modelNumber,
    markAsClearance,
    wasPrice,
    nowPrice,
  } = dhData;

  let bedroomsLabel = 'Bedrooms:';
  let fafTitle = 'Facts and Features';
  let priceLabel = 'Price:';
  let statusLabel = 'Status:';
  let typeLabel = 'Type:';
  let squareFeetLabel = 'Square Feet:';
  let bathroomsLabel = 'Bathrooms:';
  let sectionsLabel = 'Sections:';
  let brandLabel = 'Brand:';
  let modelNameLabel = 'Model:';
  let modelNumberLabel = 'Model Number:';
  let buildingMethodLabel = 'Building Method:';

  if (displayHomeFactsLabels) {
    bedroomsLabel = displayHomeFactsLabels.bedrooms;
    fafTitle = displayHomeFactsLabels.facts_and_features;
    priceLabel = displayHomeFactsLabels.price;
    squareFeetLabel = displayHomeFactsLabels.square_feet;
    bathroomsLabel = displayHomeFactsLabels.bathrooms;
    sectionsLabel = displayHomeFactsLabels.sections;
    typeLabel = displayHomeFactsLabels.type;
    modelNameLabel = displayHomeFactsLabels.model;
    modelNumberLabel = displayHomeFactsLabels.modelNumber;
    statusLabel = displayHomeFactsLabels.status;
    brandLabel = displayHomeFactsLabels.brand;
    buildingMethodLabel = displayHomeFactsLabels.buildingMethod;
    sectionsLabel = displayHomeFactsLabels.sections;
  }

  let formattedSellingPrice = sellingPrice;
  if (availableStatus.toLowerCase() === 'sold') {
    formattedSellingPrice = 'SOLD';
  } else if (Number.isInteger(parseInt(sellingPrice, 10))) {
    formattedSellingPrice = currencyFormatter.format(sellingPrice);
  }
  const displayPrice = markAsClearance ? (
    <>
      <s className="was-price">Was {currencyFormatter.format(wasPrice)}</s>{' '}
      <mark className="now-price">
        Now {currencyFormatter.format(nowPrice)}
        <span className="price-asterisk">**</span>
      </mark>
    </>
  ) : (
    <>
      {formattedSellingPrice}
      <span className="price-asterisk">**</span>
    </>
  );

  const elements: { val: string; title: string }[] = [
    { val: displayPrice, title: priceLabel },
    { val: 'New Home, Ready for Land', title: typeLabel },
    { val: availableStatus, title: statusLabel },
    { val: builder, title: brandLabel },
    { val: modelName, title: modelNameLabel },
    { val: modelNumber, title: modelNumberLabel },
    { val: sections, title: sectionsLabel },
    { val: squareFootage, title: squareFeetLabel },
    { val: numBedrooms, title: bedroomsLabel },
    { val: numBathrooms, title: bathroomsLabel },
    { val: buildingMethodValue, title: buildingMethodLabel },
  ];

  const leftElements: { val: string; title: string }[] = [];
  const rightElements: { val: string; title: string }[] = [];
  for (let i = 0; i < elements.length; i += 1) {
    if (i < elements.length / 2) {
      leftElements.push(elements[i]);
    } else {
      rightElements.push(elements[i]);
    }
  }

  return (
    <div className="cvc-product-facts-content">
      <h2 className="cvc-product-facts-content-h2">{fafTitle}</h2>
      <ul className="cvc-product-facts-content-facts">
        {leftElements.length > 0 && (
          <li>
            <strong>{leftElements[0].title} </strong> {leftElements[0].val}
          </li>
        )}
        {leftElements.length > 1 && (
          <li>
            <strong>{leftElements[1].title} </strong> {leftElements[1].val}
          </li>
        )}
        {leftElements.length > 2 && (
          <li>
            <strong>{leftElements[2].title} </strong> {leftElements[2].val}
          </li>
        )}
        {leftElements.length > 3 && (
          <li>
            <strong>{leftElements[3].title} </strong> {leftElements[3].val}
          </li>
        )}
        {leftElements.length > 4 && (
          <li>
            <strong>{leftElements[4].title} </strong> {leftElements[4].val}
          </li>
        )}
        {leftElements.length > 5 && (
          <li>
            <strong>{leftElements[5].title} </strong> {leftElements[5].val}
          </li>
        )}
        {rightElements.length > 0 && (
          <li>
            <strong>{rightElements[0].title} </strong> {rightElements[0].val}
          </li>
        )}
        {rightElements.length > 1 && (
          <li>
            <strong>{rightElements[1].title} </strong> {rightElements[1].val}
          </li>
        )}
        {rightElements.length > 2 && (
          <li>
            <strong>{rightElements[2].title} </strong> {rightElements[2].val}
          </li>
        )}
        {rightElements.length > 3 && (
          <li>
            <strong>{rightElements[3].title} </strong> {rightElements[3].val}
          </li>
        )}
        {rightElements.length > 4 && (
          <li>
            <strong>{rightElements[4].title} </strong> {rightElements[4].val}
          </li>
        )}
      </ul>
      <ul className="cvc-product-facts-content-features">
        {specialFeatures?.feature.map((item) => {
          if (item !== '') return <li key={item}>{item}</li>;
        })}
      </ul>
    </div>
  );
}
