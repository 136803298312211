import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import './CardBody.scss';
import { ResolverLink } from 'components/shared';
import { ReactComponent as PictureIcon } from 'assets/images/picture-icon.svg';
import { ReactComponent as VirtualIcon } from 'assets/images/3d-no-circle-icon.svg';
import { ReactComponent as VideoIcon } from 'assets/images/video-no-circle-icon.svg';

export const CardBody = ({
  title = '',
  url = '',
  numBedrooms = 0,
  numBathrooms = 0,
  squareFootage = 0,
  hasPhoto = false,
  videosRef = null,
  toursRef = null,
  isMobile = false,
}) => {
  return (
    <Card.Body className="fp-recent-card-body">
      <Card.Title className="is-desktop-view-fc">
        <ResolverLink className="fp-recent-card-link-fc" href={url}>
          <span className="floorplan-card-title-fc">{title}</span>
        </ResolverLink>
      </Card.Title>
      <Card.Text className="is-desktop-view-fc">
        <span className="floorplan-card-bed-bath-sqft-fc">
          {numBedrooms && `${numBedrooms} bd`}
          {numBathrooms ? <span className="card-text-separator-fc">{' | '} </span> : ''}
          {numBathrooms && `${numBathrooms} ba`}
          {squareFootage ? <span className="card-text-separator-fc">{' | '} </span> : ''}
          {squareFootage && `${squareFootage} sqft`}
        </span>
        {!isMobile && (
          <span className="card-body-icons-container-fc">
            {hasPhoto ? <PictureIcon className="floorplan-picture-icon-fc" /> : ''}

            {toursRef ? <VirtualIcon className="floorplan-3d-virtual-icon-fc" /> : ''}

            {videosRef ? (
              <VideoIcon
                className={classNames(
                  'floorplan-video-icon-fc',
                  `${toursRef ? 'show-after-virtual-icon-fc' : ''}`
                )}
              />
            ) : (
              ''
            )}
          </span>
        )}
      </Card.Text>
    </Card.Body>
  );
};
