import * as ElasticAppSearch from '@elastic/app-search-javascript';
import { find } from 'lodash';

export interface ElasticOptions {
  filters?: {
    all: any[];
  };
  facets?: {};
  page?: {
    current?: number;
    total_pages?: number;
    total_results?: number;
    size?: number;
  };
  query: any;
  sort?: any;
}

export interface ElasticClientConfiguration {
  apiKey: string;
  engineName: string;
  endpointBase: string;
}

export interface ElasticSearchFacets {
  value: string;
  count: number;
}

export interface ElasticSearchPageInfo {
  current: number;
  size: number;
  total_pages: number;
  total_results: number;
}

export interface ElasticSearchResult {
  [key: string]: any;
}

export interface ElasticSearchResponse {
  facets?: ElasticSearchFacets[];
  pageInfo?: ElasticSearchPageInfo;
  results?: ElasticSearchResult[];
}

export function getElasticSearchClient(esConfig: ElasticClientConfiguration): any {
  return ElasticAppSearch.createClient({
    searchKey: esConfig.apiKey,
    endpointBase: esConfig.endpointBase,
    engineName: esConfig.engineName,
  });
}

const getFacets = (response: any, faceName?: string) => {
  if (!faceName) {
    return [];
  }
  if (response && response.rawInfo && response.rawInfo.facets) {
    return response.rawInfo?.facets[faceName][0].data;
  }
  return [];
};

export const esSearch = async (esClient: any, opts: ElasticOptions, facetName?: string) => {
  try {
    const response = await esClient.search('', opts);
    const facets: ElasticSearchFacets[] = getFacets(response, facetName);
    const page = response?.rawInfo?.meta?.page;
    const pageInfo: ElasticSearchPageInfo = {
      ...page,
    };

    const resultData: ElasticSearchResult[] = [];

    const manageData = (item: any) => {
      const r: ElasticSearchResult = {
        ...item.data,
      };
      resultData.push(r);
    };

    if (response.results && response.results.length > 0) {
      response.results.map((item: any) => manageData(item));
    }

    const result: ElasticSearchResponse = {
      facets,
      pageInfo,
      results: resultData,
    };
    return result;
  } catch (e) {
    throw new Error(`ES Search Document Error ${e}`);
  }
};

export const esSearchWithFacet = async (esClient: any, opts: ElasticOptions, facetName: string) => {
  const { facets, ...otherOptions } = opts;
  const data = await esSearch(esClient, otherOptions);
  const matchFacet = find(data.results, (o: any) => o[facetName] !== undefined);
  if (matchFacet) {
    const dataF = await esSearch(esClient, opts, facetName);
    return dataF;
  }
  return data;
};
