import React, { useEffect, useRef, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { checkForNessus } from 'components/utils';
import { RadioGroupFacet } from 'components/FloorPlanResultsCH/components/RadioFacet/RadioFacet';
import AutoCompleteComp from '../../../Autocomplete/Autocomplete';
import useGeocodeSearchDataHook from '../../../hooks/GeocodeSearchData.hook';
import { FloorPlanFacet } from '../FloorPlanFacet/FloorPlanFacet';
import { FloorPlanModalFacet } from '../FloorPlanModalFacet/FloorPlanModalFacet';
import './FloorPlanFacetBar.scss';
import { CheckboxFacet, CheckboxGroup } from '../CheckboxFacet/CheckboxFacet';
import { ButtonFacet } from '../ButtonFacet/ButtonFacet';
import { SliderFacet } from '../SliderFacet/SliderFacet';
import { AutosuggestResponse } from '../../FloorPlanResults.interface';

export const FloorPlanFacetBar = ({
  currentAddress = null,
  facets,
  selected = {},
  metaData,
  filterChange,
  hasSearchZip = true,
  handleSearch,
  handleSetFacets,
  componentData,
  configData,
  isMobile = false,
}: any) => {
  const anchorRef = useRef<any>();

  const { setGeocodeRequest, geocodeResponse } = useGeocodeSearchDataHook();

  const [currentFacets, setCurrentFacets] = useState<any>({
    primary: [],
    more: [],
  });
  const [selectedFacets, setSelectedFacets] = useState<any>(undefined);
  const [closeMenus, setCloseMenus] = useState<any>(false);
  const [isMoreFacetModalOpen, setIsMoreFacetModalOpen] = useState<boolean>(false);

  const { facetConfigInstance } = configData;

  const suggestionClassNames = {
    container: 'pac-item',
    suggestion: 'pac-container',
    suggestionActive: 'pac-container--active',
  };

  useEffect(() => {
    setSelectedFacets(selected);
  }, [selected]);

  useEffect(() => {
    if (geocodeResponse && !geocodeResponse.isError) {
      handleSearch(geocodeResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodeResponse]);

  const getResponsiveFilterList = useCallback(
    (facetList) => {
      const primary = facetList?.primary || [];
      const more = facetList?.more?.options || [];

      return isMobile
        ? {
            all: {
              key: 'all',
              label: 'Filters',
              options: [...primary, ...more],
            },
          }
        : facetList;
    },
    [isMobile]
  );

  useEffect(() => {
    const filters = getResponsiveFilterList(facets);
    setCurrentFacets(filters);
  }, [getResponsiveFilterList, facets]);

  const handleClearFilters = (isMore = false) => {
    const filters = isMore ? facets.more?.options : [...facets.primary, ...facets.more?.options];
    const newFacets = selectedFacets;

    filters?.forEach((facet: any) => {
      if (newFacets.hasOwnProperty(facet.key)) {
        delete newFacets[facet.key];
      }
    });

    setSelectedFacets(newFacets);
    filterChange(newFacets, true);

    if (isMore) {
      setIsMoreFacetModalOpen(false);
    }
  };

  const createSelectedFacetsLabel = (allSelectedFacets: any, facet: any) => {
    const key = facet.key || null;
    const selectedFacet = allSelectedFacets[key];
    const isLocationAnd3k =
      selectedFacet && selectedFacet.length === 1 && selectedFacet[0] === 3000;
    const match = key
      ? JSON.stringify(selectedFacet) === JSON.stringify(facetConfigInstance[key]?.ui?.defaultValue)
      : false;

    if (!allSelectedFacets.hasOwnProperty(key) || match || isLocationAnd3k) {
      return facet.label;
    }

    const delimiter = facet.type === 'range' ? '-' : ',';
    const selectedValuesStr = selectedFacet.join(delimiter);
    const truncated =
      selectedValuesStr.length > 11 ? `${selectedValuesStr.slice(0, 10)}...` : selectedValuesStr;
    const prefix = facet.prefix || '';
    const suffix = facet.suffix || '';
    return `${prefix}${truncated} ${suffix}`;
  };

  const createFacetGroupCountLabel = ({
    options,
    allSelectedFacets,
    label,
  }: {
    options: any;
    label: string;
    allSelectedFacets: any;
  }) => {
    const selectedFacetCount = options.reduce(
      (memo: number, facet: any) => memo + (allSelectedFacets[facet.key]?.length || 0),
      0
    );

    return selectedFacetCount && selectedFacetCount > 0
      ? `(${selectedFacetCount}) ${label}`
      : label;
  };

  const setFacets = () => {
    if (metaData.count > 0) {
      handleSetFacets();
      setCloseMenus(!closeMenus);
      setIsMoreFacetModalOpen(false);
    }
  };

  const handleShowMoreFacetModal = (show: boolean) => {
    setIsMoreFacetModalOpen(show);
  };

  // split selected facets into current facet and all others
  // get the currently selected value of the current facet
  // check if this is the default value
  // if it is, replace it with the selected value
  // if it isn't

  const handleSelectedFacet = (key: string, value: any, checked: boolean | null = null) => {
    const facetType = facetConfigInstance[key].ui?.type;
    // split the selected facets: current facet and all the rest...
    const { [key]: current, ...rest } = selectedFacets;
    // get the currently selected value for the current facet -- or an empty array
    const selectedValue = current || [];

    // get this facet's default value if it has one...
    const defaultValue = facetConfigInstance[key].ui?.defaultValue || null;
    // quick compare: is the current value the default value?
    const isDefaultValue = JSON.stringify(selectedValue) === JSON.stringify(defaultValue);
    // if this is the default value, we just need an empty array, otherwise, return the currently selected value
    const cleanedValue = isDefaultValue ? facetConfigInstance[key].ui?.defaultValue : selectedValue;
    // if the facet is checked(checkbox) or the cleaned array doesn't contain the currently selected value(button group):
    // > add the new value to the selected array...
    // > otherwise, remove it

    let updatedValue;

    if (facetType === 'radio') {
      updatedValue = [value];
    } else {
      updatedValue =
        checked || !cleanedValue.includes(value)
          ? [...cleanedValue, value]
          : cleanedValue.filter((v: any) => v !== value);
    }

    // range values just get updated with the current value
    updatedValue = facetType === 'range' ? value : updatedValue;

    // In addition to locations having a variable default value, we also need to remove 3000 if any other value is selected
    // 3000 is only a fallback
    const location3kCheck =
      key === 'locations' && updatedValue.length > 1
        ? updatedValue.filter((val: number) => val !== 3000)
        : updatedValue;

    // if the new facet array is empty but there is a default value, use it
    const defaultedValue =
      !location3kCheck.length && !!defaultValue ? defaultValue : location3kCheck;

    // if the updated facet is an empty array, just remove it from the facets,
    // otherwise, update the facets
    const updatedFacets =
      defaultedValue.length === 0
        ? { ...rest }
        : {
            ...selectedFacets,
            [key]: defaultedValue,
          };

    setSelectedFacets(updatedFacets);

    filterChange(updatedFacets);
  };

  return (
    <div className="facet-bar" ref={anchorRef}>
      <ul>
        {hasSearchZip && (
          <li className="facet-item facet-item--search-zip">
            <div className="cavcohomes-floorplan-list__filter-item">
              <SearchIcon fontSize="large" />
              {(currentFacets?.primary?.length > 0 || currentFacets?.all?.options.length > 0) && (
                <AutoCompleteComp
                  apiKey={checkForNessus(configData.googleMapsConfig.apiKey)}
                  placeholder={currentAddress || 'Search Zipcode'}
                  onClick={(suggestionSelected: AutosuggestResponse) =>
                    setGeocodeRequest(suggestionSelected?.description)
                  }
                  geocodeEndpoint={configData?.googleMapsConfig?.mapEndpoint}
                  suggestionsClassNames={suggestionClassNames}
                  inputClassName="cavcohomes-floorplan-list__filter-item--search-text"
                  executeOnClick
                />
              )}
            </div>
          </li>
        )}
        {currentFacets?.primary &&
          currentFacets?.primary?.map((facet: any, idx: number) => {
            return (
              <li key={`item_${idx}`} className="facet-item">
                <FloorPlanFacet
                  name={facet.key}
                  label={createSelectedFacetsLabel(selectedFacets, facet)}
                  panel={facet.panel}
                  closeMenu={closeMenus}
                  disabled={facet.disableFilter}
                >
                  {/* {createSelectedFacetsLabel(selectedFacets, facet)} */}
                  <div className={`facet-panel__options facet-panel__options--${facet.type}`}>
                    {facet.type === 'radio' && (
                      <RadioGroupFacet
                        facet={facet}
                        selectedFacets={selectedFacets}
                        handleSelectedFacet={handleSelectedFacet}
                        componentData={{
                          ...(componentData[facet.key] || {}),
                          ...componentData.localization,
                        }}
                      />
                    )}
                    {facet.type === 'checkbox' && (
                      <CheckboxGroup
                        facet={facet}
                        selectedFacets={selectedFacets}
                        handleSelectedFacet={handleSelectedFacet}
                        componentData={{
                          ...(componentData[facet.key] || {}),
                          ...componentData.localization,
                        }}
                      />
                    )}
                    {facet.type === 'button' && (
                      <ButtonFacet
                        facet={facet}
                        selectedFacets={selectedFacets}
                        handleSelectedFacet={(key: string, value: any, checked: boolean) =>
                          handleSelectedFacet(key, value, checked)
                        }
                        componentData={{
                          ...(componentData[facet.key] || {}),
                          ...componentData.localization,
                        }}
                      />
                    )}
                    {facet.type === 'range' && (
                      <SliderFacet
                        facet={facet}
                        selectedFacets={selectedFacets}
                        handleSelectedFacet={(key: string, value: any) =>
                          handleSelectedFacet(key, value, null)
                        }
                        componentData={{
                          ...(componentData[facet.key] || {}),
                          ...componentData.localization,
                        }}
                        configData={configData}
                      />
                    )}
                  </div>
                  <div className="facet-panel__apply">
                    <Button
                      role="button"
                      variant="contained"
                      onClick={setFacets}
                      disabled={metaData?.count === 0}
                    >
                      {metaData?.count > 0
                        ? `See ${metaData?.count} Results`
                        : `0 Results. Please try again`}
                    </Button>
                  </div>
                </FloorPlanFacet>
              </li>
            );
          })}
        {currentFacets?.more?.options?.length > 0 && (
          <li className="facet-item">
            <div className="facet-item--more">
              {currentFacets?.more?.options?.length > 0 && (
                <FloorPlanModalFacet
                  name={currentFacets.more.key}
                  label={createFacetGroupCountLabel({
                    options: currentFacets.more.options,
                    label: currentFacets.more.label,
                    allSelectedFacets: selectedFacets,
                  })}
                  show={isMoreFacetModalOpen}
                  handleShow={handleShowMoreFacetModal}
                >
                  <div className="floor-plan-facet-modal_body">
                    {facets?.more?.options?.map((option: any, idx: number) => {
                      let has3dFilter = false;

                      return (
                        <div
                          key={`${option.key}-${option.value}-${idx}`}
                          className="facet-panel__more"
                        >
                          <h4 className="facet-panel__label">{option.label}</h4>
                          <div className="facet-panel__more__options">
                            {option.type === 'radio' && (
                              <RadioGroupFacet
                                facet={option}
                                selectedFacets={selectedFacets}
                                handleSelectedFacet={handleSelectedFacet}
                                componentData={{
                                  ...(componentData[option.key] || {}),
                                  ...componentData.localization,
                                }}
                              />
                            )}
                            {option.type === 'range' && (
                              <SliderFacet
                                key={option.key}
                                facet={option}
                                selectedFacets={selectedFacets}
                                handleSelectedFacet={(key: string, value: any) =>
                                  handleSelectedFacet(key, value, null)
                                }
                                componentData={{
                                  ...(componentData[option.key] || {}),
                                  ...componentData.localization,
                                }}
                                configData={configData}
                              />
                            )}
                            {option.type === 'checkbox' &&
                              option.options.map((opt: any) => {
                                const updatedOpt = opt;

                                // Combine Pana Tour and 3d Tour into one filter option
                                if (
                                  updatedOpt.value === 'Pana Tour' ||
                                  updatedOpt.value === '3d Tours'
                                ) {
                                  if (has3dFilter) {
                                    return null;
                                  }
                                  updatedOpt.value = '3d Tours';
                                  has3dFilter = true;
                                }

                                const isSelected = selectedFacets[option.key]?.includes(
                                  updatedOpt.value
                                );

                                return (
                                  <div
                                    key={`${updatedOpt.key}-${updatedOpt.value}`}
                                    className={`facet-panel__more__item facet-panel__more__item--len-${Math.round(
                                      100 / option.options.length
                                    )} ${isSelected ? 'facet-selected' : ''}`}
                                  >
                                    <CheckboxFacet
                                      key={`${option.key}-${updatedOpt.value}`}
                                      name={option.key}
                                      option={updatedOpt}
                                      selectedFacets={selectedFacets}
                                      handleSelectedFacet={(
                                        key: string,
                                        value: any,
                                        checked: boolean
                                      ) => handleSelectedFacet(key, value, checked)}
                                      componentData={{
                                        ...(componentData[option.key] || {}),
                                        ...componentData.localization,
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="facet-panel__apply floor-plan-facet-modal_footer">
                    <Button
                      role="button"
                      className="cavcohomes-floorplan-search__clear"
                      variant="text"
                      onClick={() => handleClearFilters(true)}
                    >
                      Clear all filters
                    </Button>
                    <Button
                      role="button"
                      variant="contained"
                      onClick={setFacets}
                      disabled={metaData?.count === 0}
                    >
                      See {metaData?.count} Results
                    </Button>
                  </div>
                </FloorPlanModalFacet>
              )}
            </div>
          </li>
        )}
        {currentFacets?.all && (
          <li className="facet-item">
            <FloorPlanModalFacet
              name="all-filters"
              label="Filters"
              show={isMoreFacetModalOpen}
              handleShow={handleShowMoreFacetModal}
            >
              {currentFacets?.all?.options?.map((facet: any) => {
                return (
                  <div
                    key={`facet-bar-all-${facet.key}`}
                    className={`facet-panel facet-panel--${facet.key}`}
                  >
                    <h4 className="facet-panel__label">{facet.label}</h4>
                    <div className="facet-panel__options">
                      {facet.type === 'checkbox' && (
                        <CheckboxGroup
                          facet={facet}
                          selectedFacets={selectedFacets}
                          handleSelectedFacet={handleSelectedFacet}
                          componentData={{
                            ...(componentData[facet.key] || {}),
                            ...componentData.localization,
                          }}
                        />
                      )}
                      {facet.type === 'button' && (
                        <ButtonFacet
                          facet={facet}
                          selectedFacets={selectedFacets}
                          handleSelectedFacet={handleSelectedFacet}
                          componentData={{
                            ...(componentData[facet.key] || {}),
                            ...componentData.localization,
                          }}
                        />
                      )}
                      {facet.type === 'range' && (
                        <SliderFacet
                          facet={facet}
                          disabled={facet.disableFilter}
                          selectedFacets={selectedFacets}
                          handleSelectedFacet={(key: string, value: any) =>
                            handleSelectedFacet(key, value, null)
                          }
                          componentData={{
                            ...(componentData[facet.key] || {}),
                            ...componentData.localization,
                          }}
                          configData={configData}
                        />
                      )}
                      {facet.type === 'radio' && (
                        <RadioGroupFacet
                          facet={facet}
                          selectedFacets={selectedFacets}
                          handleSelectedFacet={handleSelectedFacet}
                          componentData={{
                            ...(componentData[facet.key] || {}),
                            ...componentData.localization,
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="facet-panel__apply">
                <Button
                  role="button"
                  variant="contained"
                  onClick={setFacets}
                  disabled={metaData?.count === 0}
                >
                  {metaData?.count > 0
                    ? `See ${metaData?.count} Results`
                    : `No results found. Please try again`}
                </Button>
              </div>
            </FloorPlanModalFacet>
          </li>
        )}
      </ul>
      <div>
        <Button
          onClick={() => handleClearFilters()}
          className="cavcohomes-floorplan-search__clear"
          type="button"
          variant="text"
        >
          Clear All Filters
        </Button>
      </div>
    </div>
  );
};
