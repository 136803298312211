import React, { FunctionComponent } from 'react';
import { BrComponent, BrPageContext } from '@bloomreach/react-sdk';
import './RetailerSingleColumn.scss';

const RetailerSingleColumn: FunctionComponent = () => {
  const page = React.useContext(BrPageContext);

  const displayHeaderComponentsWithoutRetailerData = () => {
    const headerContent =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'retailer-header-content');

    const validHeaderComponents =
      headerContent &&
      headerContent.getChildren().filter((comp: any) => comp.getName() !== 'retailer-data');

    if (!validHeaderComponents) {
      return null;
    }
    return validHeaderComponents.map((comp: any) => {
      if (comp.model.name === 'header-component') {
        return <BrComponent key={comp.model.name} path={comp.model.name} />;
      }
      return '';
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrComponent path="retailer-header-content">
        {displayHeaderComponentsWithoutRetailerData()}
      </BrComponent>

      <main
        className="cvc-main"
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-main" id="main">
          <BrComponent path="main" />
        </section>
      </main>
      <BrComponent path="retailer-footer-content" />
    </div>
  );
};

export default RetailerSingleColumn;
