import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import 'components/shared/ProductDetails/ProductDisclaimer.scss';
import { getDisplayHomeDataModel } from 'components/utils';

export function DisplayHomeDisclaimer(props: BrProps) {
  const { displayHomeDisclaimerLabels } = props.component.getModels();
  const { document: ref } = getDisplayHomeDataModel(props.page);
  const document = props.page.getContent(ref);

  const resourceBundle = displayHomeDisclaimerLabels;

  let disclaimer = 'The disclaimer resource bundle is not connected.';
  let pricingDisclaimer = '';

  if (resourceBundle) {
    disclaimer = resourceBundle.disclaimer;
    pricingDisclaimer = resourceBundle.pricingDisclaimer;
  }

  if (document) {
    const { overrideClearancePricing, invCLDisclaimer } = document.getData<InventoryData>();
    pricingDisclaimer =
      overrideClearancePricing && invCLDisclaimer ? invCLDisclaimer : pricingDisclaimer;
  }

  return (
    <div className="cvc-display-home-disclaimer cvc-product-disclaimer">
      <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(disclaimer) }} />
      <br />
      <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(pricingDisclaimer) }} />
    </div>
  );
}
