import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import './CavcoUserLoginForm.scss';
// eslint-disable-next-line
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';
import { setCookie } from '../hooks/cookie';
import { ReactComponent as FacebookSignIn } from '../../assets/images/facebook-sign-in.svg';
import { ReactComponent as GoogleSignIn } from '../../assets/images/google-sign-in.svg';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';
import { ReactComponent as NoEyeIcon } from '../../assets/images/no-eye.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/exclamation-point-red.svg';
import { initAllFavoritesForCurrentUser } from '../utils';
import { LoginStatus, loginSubject$ } from './LoginStatus';

export function CavcoUserLoginForm({
  cavcoUserLoginUrl,
  postLoginUrl,
  setSuccessfulSignIn,
  closeMethod,
  switchToOpenCreateLoginModal,
  switchToOpenForgetPasswordModal,
  cavcoFavoriteUrl,
}: any) {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [emailOrPasswordEmpty, setEmailOrPasswordEmpty] = useState(false);
  const [submittingUserLogin, setSubmittingUserLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSignInPwd, setShowSignInPwd] = useState(false);
  const [signInWithGoogle, setSignInWithGoogle] = useState('false');
  const [signInWithFacebook, setSignInWithFacebook] = useState('false');
  const [signInWithGoogleFaceBook, setSignInWithGoogleFaceBook] = useState(false);

  const [, setCavcoUserInfo] = useStateWithLocalStorage('cavcoUserInfo');

  const postMethod = (
    isThirdParty: boolean,
    values: {
      emailaddress: any;
      password: string;
      facebookId: string;
      googleId: string;
      userName?: any;
      rememberMe?: boolean;
      googleResponseBody?: any;
      facebookResponseBody?: any;
    }
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    const loginUrl = isThirdParty ? `${cavcoUserLoginUrl}3rdParty` : cavcoUserLoginUrl;
    fetch(loginUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('responseData:', data); // eslint-disable-line
        console.log('postLoginUrl:', postLoginUrl); // eslint-disable-line

        if (data.status === '400') {
          if (data.message.startsWith('User account for the emailAddress does not exist')) {
            data.message = 'Email address does not exist. Please verify the email.';
          }
          setErrorMessage(data.message);
          setSubmittingUserLogin(false);
          return;
        }
        setErrorMessage('');
        setCavcoUserInfo(data);
        if (data && data.uid && postLoginUrl && Number.isInteger(data.uid)) {
          setCookie('cavcouserid', data.uid, 30);
          initAllFavoritesForCurrentUser(cavcoFavoriteUrl);
          // initMyFavoritesForCurrentUser(loadMyFavoriteCardsUrl);
          // postLogin(data.uid);
        }
        setSuccessfulSignIn(true);
        closeMethod();
        setSubmittingUserLogin(false);
        loginSubject$.next(LoginStatus.loggedIn);
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
        setErrorOccurred(true);
        setSubmittingUserLogin(false);
      });
  };

  const responseFacebook = (response: any) => {
    // console.log('responseFacebook : ', response)
    setSignInWithGoogleFaceBook(true);
    if (response?.name) {
      setSignInWithFacebook('true');
      const values = {
        emailaddress: response?.email,
        password: '',
        facebookId: response?.userID,
        googleId: '',
        userName: response?.name,
        name: response?.name,
        googleResponseBody: null,
        facebookResponseBody: response,
      };
      postMethod(true, values);
    } else {
      setSignInWithFacebook('error');
    }
  };

  const responseGoogle = (response: any) => {
    // console.log('responseGoogle : ', response)
    setSignInWithGoogleFaceBook(true);
    if (response?.profileObj?.name) {
      setSignInWithGoogle('true');
      const values = {
        emailaddress: response?.profileObj?.email,
        password: '',
        facebookId: '',
        googleId: response?.profileObj?.googleId,
        userName: response?.profileObj?.name,
        name: response?.profileObj?.name,
        googleResponseBody: response,
        facebookResponseBody: null,
      };
      postMethod(true, values);
    } else {
      setSignInWithGoogle('error');
    }
  };

  return (
    <Formik
      initialValues={{
        emailaddress: '',
        password: '',
        rememberMe: false,
        facebookId: '',
        googleId: '',
        googleResponseBody: null,
        facebookResponseBody: null,
      }}
      onSubmit={(values) => {
        setSignInWithGoogleFaceBook(false);
        if (!values.emailaddress || !values.password) {
          setEmailOrPasswordEmpty(true);
          return;
        }
        setEmailOrPasswordEmpty(false);
        setSubmittingUserLogin(true);
        postMethod(false, values);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="cvc-form cvc-form-account">
          <div className="cvc-form-header">
            <h2>Sign In</h2>
            <p>
              <span>Don&apos;t have an account? </span>
              <strong
                className="cvc-text-link-alt"
                role="button"
                tabIndex={0}
                onClick={switchToOpenCreateLoginModal}
                onKeyDown={switchToOpenCreateLoginModal}
              >
                Create New Account
              </strong>
            </p>
          </div>

          {((emailOrPasswordEmpty && !signInWithGoogleFaceBook) ||
            !!errorMessage ||
            errorOccurred) && (
            <div className="cvc-form-error-message">
              <ErrorIcon className="cvc-form-error-message-icon" />
              <div className="cvc-form-error-message-text">
                {emailOrPasswordEmpty && !signInWithGoogleFaceBook && (
                  <span>Email and Password are required.</span>
                )}
                {!!errorMessage && <span>{errorMessage}</span>}
                {errorOccurred && <span>Error Occurred, Please Try Again.</span>}
                {signInWithGoogle === 'error' && values.googleId && (
                  <span>Error Occurred while signing in with Google, Please Try Again.</span>
                )}
                {signInWithFacebook === 'error' && values.facebookId && (
                  <span>Error Occurred while signing in with Facebook, Please Try Again.</span>
                )}
              </div>
            </div>
          )}

          <Form.Group className="mb-3 " controlId="emailaddress">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              name="emailaddress"
              value={values.emailaddress}
              onChange={handleChange}
              onBlur={handleBlur}
              // onMouseDown={(event)=>{event.preventDefault()}}
              className={
                (emailOrPasswordEmpty || !!errorMessage || errorOccurred) &&
                !signInWithGoogleFaceBook
                  ? 'cvc-form-error'
                  : ''
              }
            />
            <ErrorMessage name="emailaddress" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <div className="right-inner-addon">
              <Form.Control
                type={showSignInPwd ? 'text' : 'password'}
                name="password"
                value={values.password}
                onChange={handleChange}
                className={
                  (emailOrPasswordEmpty || !!errorMessage || errorOccurred) &&
                  !signInWithGoogleFaceBook
                    ? 'cvc-form-error'
                    : ''
                }
              />
              <div // eslint-disable-line
                className="right-inner-addon-btn"
                onClick={() => setShowSignInPwd((prevState) => !prevState)}
              >
                {showSignInPwd ? <NoEyeIcon /> : <EyeIcon />}
              </div>
            </div>
            <ErrorMessage name="password" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="rememberMe">
            <Form.Check
              type="checkbox"
              label="Remember Me"
              name="rememberMe"
              value={values.rememberMe ? 'true' : 'false'}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>

          <div className="text-center">
            <Button
              disabled={submittingUserLogin}
              type="submit"
              variant="primary"
              className="cvc-btn"
            >
              {submittingUserLogin && (
                <>
                  <Spinner animation="border" variant="light" className="cvc-btn-icon" />
                </>
              )}
              {submittingUserLogin ? 'Signing In' : 'Sign In'}
            </Button>

            <small // eslint-disable-line
              className="cvc-text-link-alt"
              onClick={switchToOpenForgetPasswordModal}
            >
              Forgot Your Password?
            </small>

            <GoogleLogin
              clientId="589760947690-gou15949t5qmra7hm9ki0b5ruuoicumb.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  variant="secondary"
                  className="cvc-btn-sign-in cvc-btn"
                  onClick={renderProps.onClick}
                  style={{ cursor: 'pointer' }}
                  onKeyDown={renderProps.onClick}
                  role="button"
                  tabIndex={0}
                >
                  <GoogleSignIn className="cvc-btn-icon" />
                  <span className="cvc-btn-label">Sign In with Google</span>
                </Button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />

            <FacebookLogin
              appId="846330196065901"
              autoLoad={false}
              callback={responseFacebook}
              fields="name,email,picture"
              // eslint-disable-next-line
              // @ts-ignore
              render={(renderProps) => (
                <Button
                  variant="secondary"
                  className="cvc-btn-sign-in cvc-btn"
                  style={{ cursor: 'pointer' }}
                  onClick={renderProps.onClick}
                  onKeyDown={renderProps.onClick}
                  role="button"
                  tabIndex={0}
                >
                  <FacebookSignIn className="cvc-btn-icon" />
                  <span className="cvc-btn-label">Sign In with Facebook</span>
                </Button>
              )}
            />
          </div>
          {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
}
