import React from 'react';
import { Col } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { FavoriteIcon, ResolverLink } from 'components/shared';
import { formatForHistory } from 'components/shared/ResolverLink';
import { formatPhoneNumber } from '../../utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './RetailerCards.scss';

function distanceSpan(distance: any, radiusUnit: any) {
  if (distance)
    return (
      <span className="cvc-retailer-card-details-distance">
        {Math.round(distance + Number.EPSILON)} {radiusUnit}
      </span>
    );
}

export const RetailerCards = ({
  cards,
  myAccountConfig,
  locationTypeDisplayOverrideMap,
}: {
  cards: any;
  myAccountConfig?: any;
  locationTypeDisplayOverrideMap?: any;
}) => {
  const history = useHistory();
  const { isMobile } = useWindowDimensions();

  const updateMarker = (title: string) => {
    const markerSelected = document.querySelector(`[title="${title}"][role="button"]`);

    if (markerSelected) {
      (markerSelected as HTMLDivElement).click();
    }
  };

  const handleDetailsClick = (title: string, url: string) => {
    if (isMobile) {
      history.push(formatForHistory(url));
    } else {
      updateMarker(title);
    }
  };
  return cards?.map((item: any) => {
    const {
      zip,
      state_or_province: state,
      dba,
      address,
      phone,
      city,
      distance,
      distanceUnit,
      url,
      directionsUrl,
      id,
      location_type: locationType,
      location_id: locationId,
    } = item;

    const theUrl = url?.raw;

    const name = dba?.raw;
    const retailerType = locationType?.raw.split(' ').join('_').toLowerCase();

    return (
      <Element
        key={id.raw}
        id={id.raw}
        name={`card-${id.raw}`}
        onClick={() => handleDetailsClick(dba.raw, theUrl)}
        className="cvc-retailer-card cvc-retailer-card"
      >
        <RetailerResultCard
          retailerType={retailerType}
          locationType={locationType?.raw}
          url={theUrl}
          name={name}
          distance={distance}
          distanceUnit={distanceUnit}
          address1={address?.raw?.[0]}
          address2={address?.raw?.[1]}
          city={city?.raw}
          state={state?.raw}
          zip={zip?.raw}
          phone={phone?.raw}
          directionsUrl={directionsUrl}
          myAccountConfig={myAccountConfig}
          locationId={locationId?.raw}
          locationTypeDisplayOverrideMap={locationTypeDisplayOverrideMap}
        />
      </Element>
    );
  });
};

export interface RetailerResultCardProps {
  retailerType?: string;
  locationType?: string;
  url?: string;
  name?: string;
  distance?: string;
  distanceUnit?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  directionsUrl?: string;
  myAccountConfig?: any;
  locationId?: string;
  handleFavoriteClick?: (params: any) => any;
  isExternal?: boolean;
  locationTypeDisplayOverrideMap?: any;
}

export const RetailerResultCard: React.FC<RetailerResultCardProps> = ({
  retailerType,
  locationType,
  url,
  name,
  distance,
  distanceUnit,
  address1,
  address2,
  city,
  state,
  zip,
  phone,
  directionsUrl,
  locationId = '',
  myAccountConfig,
  handleFavoriteClick,
  isExternal = false,
  locationTypeDisplayOverrideMap,
}) => {
  //  const isFavorite = isFavoriteForUser(locationId);
  //  const setFavoriteUrl = `${
  //    myAccountConfig?.favoriteBaseUrl
  //  }/our_retailers/${locationId}/${!isFavorite}?&websiteUrl=${url}`;

  const linkTarget = isExternal ? '_blank' : '';

  const locationTypeDisplay =
    locationType && locationTypeDisplayOverrideMap && locationTypeDisplayOverrideMap[locationType]
      ? locationTypeDisplayOverrideMap[locationType]
      : locationType;

  return (
    <>
      {retailerType && (
        <div className={`cvc-retailer-card-tag ${retailerType}`}>{locationTypeDisplay}</div>
      )}
      <Col className={`cvc-retailer-card-details ${retailerType ? 'has-tag' : ''}`}>
        <FavoriteIcon
          handleClick={handleFavoriteClick}
          classes="cvc-retailer-card-favorite-icon"
          //setFavoriteUrl={setFavoriteUrl}
          type="our_retailers"
          baseUrl={myAccountConfig?.favoriteBaseUrl}
          isCavcoHomesVariant
          loadFavoriteUrl={myAccountConfig?.cavcoFavoriteUrl}
          id={locationId}
          myAccountConfig={myAccountConfig}
          websiteUrl={url}
        />
        <p className="cvc-retailer-card-details-name">
          <ResolverLink target={linkTarget} href={url}>
            {name}
          </ResolverLink>
        </p>
        {distanceSpan(distance, `${distanceUnit} away`)}

        <div className="cvc-retailer-card-details-address d-flex">
          <p className="cvc-retailer-card-details-address-block">
            {address1}
            <br />
            {address2}
            {address2 && <br />}
            {city}, {state} {zip}
            {!!phone && (
              <>
                <br />
                {formatPhoneNumber(phone)}
              </>
            )}
          </p>
        </div>

        <div className="cvc-retailer-card-details-phone-button">
          {!isExternal && (
            <span className="cvc-retailer-card-button">
              <ResolverLink className="btn btn-link" href={`${url}`}>
                View Homes
              </ResolverLink>
            </span>
          )}
          {!!directionsUrl && (
            <span className="cvc-retailer-card-button">
              <ResolverLink target="_blank" className="btn btn-link" href={directionsUrl}>
                Get Directions
              </ResolverLink>
            </span>
          )}
        </div>
      </Col>
    </>
  );
};
