import { ProductResultRawProps } from './productResultCard.interface';

const processResultRaw = (result: any) => {
  try {
    return JSON.parse(result.raw);
  } catch (err) {
    return result.raw;
  }
};

const keyRemapper: { [key: string]: string } = {
  '3d_tour': 'virtual_tour',
};

export const resultMapper = (result: ProductResultRawProps): any => {
  return Object.entries(result).reduce((memo: any, [k, v]) => {
    return {
      ...memo,
      [keyRemapper[k] || k]: processResultRaw(v),
    };
  }, {});
};
