import { BrProps } from '@bloomreach/react-sdk';
import React from 'react';
import { Link } from 'react-scroll';
import './CavcoDetailFacts.scss';
import { ReactComponent as SquareFeet } from '../../assets/images/cavcohomes/square feet.svg';
import { ReactComponent as Bedrooms } from '../../assets/images/cavcohomes/bedrooms.svg';
import { ReactComponent as Bathrooms } from '../../assets/images/cavcohomes/bathrooms.svg';
import { ReactComponent as Acres } from '../../assets/images/cavcohomes/acres.svg';
import { floorPlanToInventoryMapper } from './floorplanToinventory.mapper';

export function CavcoDetailFacts(props: BrProps) {
  const pageComponents = props.page.getComponent('main', 'detailcontainer');
  const isRetailerPage = !!pageComponents?.getComponent('retailer-header-content');
  const isMIR = !!pageComponents?.getComponent('mir-banner');

  const titlePluralizer = (val: string | number, desc: string) =>
    val === '1' || val === 1 ? desc : `${desc}s`;

  const floorPlanFactProperty = (title: string, value: string, metaDesc?: string) => {
    return (
      <div className="floorplan-facts">
        <span className="feature-title">{title}</span>
        <span className="feature-value" data-elastic-name={metaDesc}>
          {value}
        </span>
      </div>
    );
  };

  const floorPlanFactPropertyDesktop = (title: string, value: string, metaDesc?: string) => {
    return (
      <>
        <div className="floorplan-facts fact-only-desktop">
          <span className="feature-title">{title}</span>
          <p className="feature-value" data-elastic-name={metaDesc}>
            {value}
          </p>
        </div>
        <hr className="fact-only-desktop" />
      </>
    );
  };

  const floorPlanFactWidget = (title: string, value: string, icon: JSX.Element) => {
    return (
      <>
        <div className="floorplan-facts">
          <div className="svg-container">{icon}</div>
          <p className="fact-label">
            {title === 'Square Feet'
              ? `${value} ${title}`
              : `${value} ${titlePluralizer(value, title)}`}
          </p>
        </div>
        <hr />
      </>
    );
  };

  const {
    squareFootage,
    dimensionalArea,
    content1,
    numBedrooms,
    numBathrooms,
    sections,
    buildingMethodValue,
    floorplanSellingFeatures,
    lotSize,
    community,
    displayName,
  } = floorPlanToInventoryMapper(props);

  const feature = floorplanSellingFeatures && floorplanSellingFeatures.feature;
  const featureBullets = (feat: string, i: number) =>
    feat !== '' && <li key={`${i}-${feat}`}>{feat}</li>;
  const show_description = content1?.value || (feature && feature[0] !== '');

  return (
    <div className="cavco-panel-container">
      <div className="cvc-floorplan-facts-container">
        <div className="floorplanfacts-title">
          <h2>Facts and Features</h2>
          {isRetailerPage && (
            <Link to="availability" smooth offset={-100}>
              Where to buy?
            </Link>
          )}
        </div>
        <div className={`floorplan-facts-features ${isMIR && 'mir-features'}`}>
          {squareFootage && floorPlanFactWidget('Square Feet', squareFootage, <SquareFeet />)}
          {numBedrooms && floorPlanFactWidget('Bedroom', numBedrooms, <Bedrooms />)}
          {numBathrooms && floorPlanFactWidget('Bathroom', numBathrooms, <Bathrooms />)}
          {lotSize && isMIR && floorPlanFactWidget('Acre', lotSize, <Acres />)}
          {sections && floorPlanFactPropertyDesktop('Sections', sections, 'sections')}
          {dimensionalArea && floorPlanFactPropertyDesktop('Dimensional Area', dimensionalArea)}
          {buildingMethodValue &&
            floorPlanFactPropertyDesktop('Building Method', buildingMethodValue, 'building_method')}
          {community && isMIR && floorPlanFactPropertyDesktop('Community', displayName)}
        </div>
        <div className="floorplan-facts-features-mobile">
          {sections && floorPlanFactProperty('Sections', sections, '')}
          {dimensionalArea && floorPlanFactProperty('Dimensional Area', dimensionalArea)}
          {buildingMethodValue && floorPlanFactProperty('Building Method', buildingMethodValue, '')}
          {community && isMIR && floorPlanFactPropertyDesktop('Community', displayName)}
        </div>
        {show_description && (
          <div className="floorplanfact-descriptor-container-desktop">
            {content1 && content1?.value && (
              <div
                className="floorplanfacts-description"
                dangerouslySetInnerHTML={{ __html: content1?.value }}
              />
            )}
            {feature && (
              <ul className="floorplanfacts-feature-list">{feature.map(featureBullets)}</ul>
            )}
          </div>
        )}
        {show_description && (
          <div className="floorplanfact-descriptor-container">
            {content1.value && (
              <div
                className="floorplanfacts-description"
                dangerouslySetInnerHTML={{ __html: content1.value }}
              />
            )}
            {feature && (
              <ul className="floorplanfacts-feature-list">{feature.map(featureBullets)}</ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
