import React, { useState } from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { getUrlFromImage } from 'components/utils';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import 'components/shared/ProductDetails/ProductPhotos.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';

function getImage(imageGalleryName: string, item: any, imgContent: any) {
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';
  // let originalImgPath = '';

  if (imgContent) {
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
    // originalImgPath = getUrlFromImage(imgContent);
  } else if (item.ximage) {
    mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
    // originalImgPath =
    //   item.ximage.original && item.ximage.original.url ? item.ximage.original.url : null;
  }

  return (
    <picture key={`${imageGalleryName}-${item.id}`}>
      <source
        media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
        srcSet={extraLargeImgPath}
      />
      <source
        media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
        srcSet={largeImgPath}
      />
      <img alt={item.imageAltText} src={mediumImgPath} />
    </picture>
  );
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  imgContent: any,
  index: any,
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="button"
      key={`${imageGalleryName}-image-${item.id}-${index}`}
      className="cvc-product-photos-media-item"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      {imgContent || item.ximage ? getImage(imageGalleryName, item, imgContent) : ''}
    </div>
  );
}

function displayPhotosAndRenderingsComingSoon() {
  return (
    <>
      <p>Photos and renderings</p>
      <p>coming soon</p>
    </>
  );
}

function displayMediaAssets(
  props: BrProps,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.IMAGE) return '';

  return mediaAssetItems.map((item: any, index) => {
    const imgContent: any = item && props.page.getContent(item.image);

    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      imgContent,
      index,
      windowDimensions,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function FloorPlanPhotos(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { documents } = props.component.getModels();

  const imageGalleryName = props.component.getName();

  const ref = documents[0];
  const document = ref ? props.page.getContent(ref) : undefined;

  if (!document) {
    return null;
  }

  const { photos: photosRef } = document.getData<FloorPlanData>();

  const photosDocument = photosRef && props.page.getContent(photosRef);

  if (!photosDocument) {
    return null;
  }

  const { id, title, mediaType, mediaAsset } = photosDocument.getData<AlbumData>();

  const floorPlanPhotosClasses = classNames({
    'cvc-floorplan-photos': true,
    'cvc-product-photos': true,
  });

  const mediaAssetItems = mediaAsset;
  const noPhotos = mediaAsset.find((item: any) => item.image || item.ximage) === undefined;

  const floorItemsContainerClasses = classNames({
    'cvc-product-photos-items-container': true,
    'no-photos': noPhotos,
  });

  return (
    <div
      id={`${imageGalleryName}-${id}`}
      className={floorPlanPhotosClasses}
      key={`${imageGalleryName}-${id}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={mediaAssetItems}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
      />
      <div className={floorItemsContainerClasses}>
        {noPhotos
          ? displayPhotosAndRenderingsComingSoon()
          : displayMediaAssets(
              props,
              imageGalleryName,
              mediaType,
              mediaAssetItems,
              windowDimensions,
              setIndexItemSelected,
              handleShowMediaViewer
            )}
      </div>
    </div>
  );
}
