import { useEffect, useState } from 'react';
import * as ElasticSearch from '@elastic/app-search-javascript';

export default function useFetch(config: any) {
  const [elasticSearchResponse, setElasticSearchResponse] = useState<any>(null);
  const [elasticSearchRequest, setElasticSearchRequest] = useState<any>(null);

  const [elasticSearchError, setElasticSearchError] = useState<any>(null);
  const [elasticSearchLoading, setElasticSearchLoading] = useState<boolean>(false);

  const esConfig = ElasticSearch?.createClient({
    searchKey: config.apiKey,
    endpointBase: config.endpointBase,
    engineName: config.engineName,
    cacheResponses: false,
  });

  useEffect(() => {
    // eslint-disable-next-line func-names
    (async function () {
      try {
        setElasticSearchLoading(true);
        const response = await esConfig?.search('', elasticSearchRequest);
        setElasticSearchResponse(response);
      } catch (err) {
        setElasticSearchError(err);
      } finally {
        setElasticSearchLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elasticSearchRequest]);

  return {
    elasticSearchResponse,
    elasticSearchError,
    elasticSearchLoading,
    setElasticSearchRequest,
  };
}
