/*
 *
 * This component creates the metadata for the Retailer Gallery page.
 * This is also the metadata for the Gallery sub-pages: Photo, Video and 3D Albums.
 * This is a Document-backed page.
 * ex url: https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/gallery
 * https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/gallery/photo-gallery
 * https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/gallery/video-gallery
 * https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/gallery/3d-virtual-tour-gallery
 *
 *
 *
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';
import { getRetailerData, buildCmsOgImage } from '../utils';

function getAlbumNames(props: BrProps, albums: any) {
  if (!albums) return null;

  return albums.map((doc: any) => {
    const album: any = doc && props.page.getContent(doc);
    return ` ${album.model.name}`;
  });
}

// {DBA} gallery includes [album names]
function buildMetaDescription(props: BrProps, data: RetailerData) {
  const photoAlbumNames = getAlbumNames(props, data.photos);
  const videoAlbumNames = getAlbumNames(props, data.videos);
  const tourAlbumNames = getAlbumNames(props, data.tours);

  return `${data.dba} gallery includes${photoAlbumNames},${videoAlbumNames},${tourAlbumNames}`;
}

//  {DBA}, {City}, {State}, Gallery, *photos, 3d tours, video tours, [{album names}]
function buildMetaKeywords(props: BrProps, data: RetailerData) {
  const photoAlbumNames = getAlbumNames(props, data.photos);
  const videoAlbumNames = getAlbumNames(props, data.videos);
  const tourAlbumNames = getAlbumNames(props, data.tours);

  const metaKeywords = `${data.dba}, ${data.city}, ${data.state}, Gallery${
    photoAlbumNames ? ',photos' : ''
  }${tourAlbumNames ? ',3d tours' : ''}${
    videoAlbumNames ? ',video tours' : ''
  },${photoAlbumNames},${tourAlbumNames},${tourAlbumNames}`;

  return metaKeywords;
}

function buildMetaImage(props: BrProps, photos: any) {
  let ogImage = '';
  const photosDocument = photos && photos[0] && props.page.getContent(photos[0]);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, photos[0]);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function buildTitle(data: RetailerData) {
  const albums = [
    data.photos ? 'photos' : '',
    data.tours ? '3d tours' : '',
    data.videos ? 'video tours' : '',
  ];
  const toReturn = albums.reduce((accum, album) => {
    return accum ? `${accum}, ${album}` : album;
  }, '');

  return `${data.dba} gallery includes ${toReturn}`;
}

function pagePropertiesForRtlData(props: BrProps, data: RetailerData, links: any) {
  const { metadata: cmsMetadata } = data;

  const pageTitle = cmsMetadata?.pageTitle || buildTitle(data);

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links, true);
  const metaDescription = cmsMetadata?.metaDescription || buildMetaDescription(props, data);
  const metaKeywords = cmsMetadata?.metaKeywords || buildMetaKeywords(props, data);
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage =
    buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data.photos);
  const imageAlt = cmsMetadata?.socialImage?.alt || '';
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata && !cmsMetadata.index ? 'noindex' : 'index';
  const followRobot = cmsMetadata && !cmsMetadata.follow ? 'nofollow' : 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
export function PagePropertiesRtlGallery(props: BrProps) {
  const dm = getRetailerData(props.page);

  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm.retailerPath);
}
