import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';

export const CavcoRetailerFloorPlanDisclaimer: React.FC<BrProps> = ({ component }) => {
  const { 'retailer-floor-plan-disclaimer-labels': disclaimers } = component.getModels();
  const {
    legal_text: legalText,
    disclaimer_1: disclaimer1,
    disclaimer_2: disclaimer2,
  } = disclaimers || {};

  if (!legalText && !disclaimer1 && !disclaimer2) {
    return null;
  }

  return (
    <div data-elastic-exclude className="cvc-cavco-retailer-fp-disclaimer">
      {legalText && <p className="cvc-cavco-retailer-fp-disclaimer__legal">{legalText}</p>}
      {disclaimer1 && <p className="cvc-cavco-retailer-fp-disclaimer__disclaimer">{disclaimer1}</p>}
      {disclaimer2 && <p className="cvc-cavco-retailer-fp-disclaimer__disclaimer">{disclaimer2}</p>}
    </div>
  );
};
