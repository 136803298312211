import React from 'react';

import { BrProps } from '@bloomreach/react-sdk';
import { Col, Container, Row } from 'react-bootstrap';

import classNames from 'classnames';

// Strangely the Image carousel breaks and throws an error when removing this line
// even though typescript is throwing an error
import SwiperCore, { Thumbs, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ResolverLink } from 'components/shared';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { FloorPlanCard } from './FloorPlanCard';
import './FloorPlanRecentlyViewed.scss';

import { MoreItemsCard } from './MoreItemsCard';

import 'swiper/swiper.scss';

// Strangely the Image carousel breaks and throws an error when removing this line
SwiperCore.use([Thumbs, Navigation]);

function buildNoRetailersMessage(props: BrProps, noDocuments: any) {
  const document = props.page.getContent(noDocuments);
  if (document) {
    const contentVal = document.getData()!.content!.value;
    return (
      <div>
        <h2>{`${document.getData()!.title}`}</h2>

        <div dangerouslySetInnerHTML={{ __html: contentVal }} />
      </div>
    );
  }
  return <div>No Retailers found nearby</div>;
}

function formatRetailerFloorplanUrl(floorplan: any) {
  const floorplanUrlSegment = floorplan.url.slice(floorplan.url.lastIndexOf('/'));
  // const retailerUrlSegment = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);

  const windowPathNameWithoutSlash = window.location.pathname.replace(/\/$/, '');
  const newFloorPlanUrl = `${windowPathNameWithoutSlash}/floorplans${floorplanUrlSegment}`;

  // // Makes sure the card's url works correctly if the page url doesn't have a trailing slash
  // floorplan.url =
  //   window.location.href.substr(-1) === '/'
  //     ? `floorplans${floorplanUrlSegment}`
  //     : `${retailerUrlSegment}/floorplans${floorplanUrlSegment}`;

  floorplan.url = newFloorPlanUrl;
}

export function FloorPlanRecentlyViewed(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  // eslint-disable-next-line
  // @ts-ignore
  const label = props.component.getType();

  const { visitedFloorPlans, cards, noDocuments } = props.component.getModels();

  // const loadFavoriteUrl = myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : null;
  // initAllFavoritesForCurrentUser(loadFavoriteUrl);

  const documentsModelVal = [visitedFloorPlans, cards].find((list) => list && list.length) || null;

  const noDocumentsModelVal = noDocuments || null;

  const retailerSlideLimit = 26;

  const gotoUrl =
    window.location.pathname.slice(-1) === '/'
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname;
  const retailerFloorPlanLink = `${gotoUrl}/floorplans`;
  const isRetailerPage = /retailer/i.test(label);

  if (!documentsModelVal) return null;
  const floorplanDocuments = documentsModelVal || [];
  const floorPlansFiltersContainerClasses = classNames({
    'show-container': true,
    'hide-container': false,
    'floorplans-filters-container': true,
    'no-horizontal-padding': true,
  });

  const mapFloorPlanToCard = (floorplan: any, index: number) => (
    <FloorPlanCard
      key={`floorplan-card-${index.toString()}`}
      floorPlanDocumentData={floorplan}
      windowDimensions={windowDimensions}
      props={props}
    />
  );

  const mapFloorPlanToCards = (floorplans = [], isRetailer = false) =>
    floorplans
      .map((floorplan: any, index) => {
        if (isRetailer) {
          formatRetailerFloorplanUrl(floorplan);
        }
        if (isRetailer && floorplans.length === retailerSlideLimit && index === 0) {
          return (
            <MoreItemsCard
              key={`floorplan-card-${index.toString()}`}
              image={
                floorplan.photoUrls.length > 0 ? floorplan.photoUrls[0] : floorplan.lineDrawingUrl
              }
              url={retailerFloorPlanLink}
            />
          );
        }

        return mapFloorPlanToCard(floorplan, index);
      })
      .reverse();

  const mapFloorPlanToSlides = (floorplans = [], isRetailer = false, isThumbs = true) =>
    floorplans
      .map((item: any, index: number) => {
        const slideContainerClasses = classNames({
          'cvc-media-viewer__item': true,
          'swiper-slide-container': false,
          'swiper-slide-container-thumbs': isThumbs,
        });
        if (isRetailer) {
          formatRetailerFloorplanUrl(item);
        }
        return (
          <SwiperSlide key={`slide-image-${item.url}`}>
            {isRetailer && floorplans.length === retailerSlideLimit && index === 0 ? (
              <div className={slideContainerClasses}>
                <MoreItemsCard
                  image={item.photoUrls.length > 0 ? item.photoUrls[0] : item.lineDrawingUrl}
                  url={retailerFloorPlanLink}
                />
              </div>
            ) : (
              <div className={slideContainerClasses}>{mapFloorPlanToCard(item, index)}</div>
            )}
          </SwiperSlide>
        );
      })
      .reverse();

  const displayFloorPlanCardsDeskTop = (floorplans = [], isRetailer = false) => (
    <div className={classNames('cvc-fp-swiper-scroll-bar-container')}>
      <Swiper
        className="fp-gallery-thumbs d-none d-md-flex"
        loop={false}
        spaceBetween={floorplans.length > 1 ? 19 : 0}
        slidesPerView="auto"
        navigation={{
          nextEl: '.thumbs-swiper-button-next-container',
          prevEl: '.thumbs-swiper-button-prev-container',
        }}
        slideToClickedSlide
        watchSlidesProgress
      >
        {mapFloorPlanToSlides(floorplans, isRetailer)}
      </Swiper>
      <button className="thumbs-swiper-button-next-container" type="button">
        <div className="swiper-button-next" />
      </button>
      <button className="thumbs-swiper-button-prev-container" type="button">
        <div className="swiper-button-prev" />
      </button>
    </div>
  );

  return (
    <div className="cvc-fp-recent">
      {isRetailerPage && (
        <div className="cvc-fp-available-header">
          <h3 className="cvc-fp-recent-title mb-2 ml-md-4 mr-3 d-block d-md-inline">
            Floor Plans Available to Build
          </h3>
          <ResolverLink className="cvc-fp-recent-view-all-link" href={retailerFloorPlanLink}>
            View All Floor Plans
          </ResolverLink>
        </div>
      )}
      {!isRetailerPage && <h2 className="cvc-fp-recent-title mb-4">Recently Viewed</h2>}
      <div className="cvc-floorplan-list-locations-container mt-3">
        {!documentsModelVal ? (
          buildNoRetailersMessage(props, noDocumentsModelVal)
        ) : (
          <>
            <Col className={floorPlansFiltersContainerClasses}>
              {windowDimensions.isMobile || windowDimensions.isTablet ? (
                <Container className="cvc-fp-recent-container">
                  <Row className="cvc-floorplan-photos-details-container no-margin">
                    <div className="floorplans-cards-container-mobile">
                      {mapFloorPlanToCards(floorplanDocuments, isRetailerPage)}
                    </div>
                  </Row>
                </Container>
              ) : (
                <Container className="cvc-fp-recent-container">
                  <div className="floorplans-cards-container">
                    {displayFloorPlanCardsDeskTop(floorplanDocuments, isRetailerPage)}
                  </div>
                </Container>
              )}
            </Col>
          </>
        )}
      </div>
    </div>
  );
}
