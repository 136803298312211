import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import SiteGlobalSwitches, { getRetailerData } from '../utils';
import { isWithinRange } from '../FeatureModels/FeatureModels';
import 'components/shared/ProductDetails/ProductDisclaimer.scss';

function showFeatureModelDisclaimer(
  props: BrProps,
  featureModelDisclaimer: string,
  fpAssetId: string
) {
  const retailerData = getRetailerData(props.page);

  if (
    retailerData &&
    featureModelDisclaimer &&
    isWithinRange(retailerData.featureModelsStartDate, retailerData.featureModelsEndDate)
  ) {
    if (
      fpAssetId === retailerData.featureModel1AssetId ||
      fpAssetId === retailerData.featureModel2AssetId
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(featureModelDisclaimer) }}
        />
      );
    }
  }
  return null;
}

export function FloorPlanDisclaimer(props: BrProps) {
  const { floorPlanDisclaimerLabels, retailerDisclaimerLabels } = props.component.getModels();

  const resourceBundle = floorPlanDisclaimerLabels || retailerDisclaimerLabels;

  let fpAssetId = '';
  let floorplanPrice = null;

  const { documents } = props.component.getModels();

  if (documents) {
    const ref = documents[0];
    const document = ref ? props.page.getContent(ref) : undefined;
    if (document) {
      const { assetId, startingPrice } = document.getData<FloorPlanData>();
      fpAssetId = assetId;
      floorplanPrice = startingPrice;
    }
  }

  const showPriceFlag = SiteGlobalSwitches.showFloorPlanPricing && floorplanPrice;

  let disclaimer = 'The disclaimer resource bundle is not connected.';
  let featureModelDisclaimer = null;
  let pricingDisclaimer = null;

  if (resourceBundle) {
    disclaimer = resourceBundle.disclaimer;
    featureModelDisclaimer = resourceBundle.featureModelDisclaimer;
    pricingDisclaimer = resourceBundle.pricingDisclaimer;
  }

  return (
    <div className="cvc-floorplan-disclaimer cvc-product-disclaimer">
      <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(disclaimer) }} />
      {showFeatureModelDisclaimer(props, featureModelDisclaimer, fpAssetId)}
      {showPriceFlag && (
        <>
          <br />
          <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(pricingDisclaimer) }} />
        </>
      )}
    </div>
  );
}
