import React from 'react';
import { styled, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import { ListItemText, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    padding: '10px 20px',
    marginTop: '1rem',
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 0,
  '&:hover': {
    'background-color': 'transparent',
  },
});

export const StyledSelect = styled(Select)({
  border: 0,
  background: 'rgba(244, 247, 248, 1)',
  outline: 'none',
  '& .MuiSelect-outlined': {
    padding: '10px',
  },
  '& svg': {
    display: 'none',
  },
});

export const StyledButtonGroup = styled(ButtonGroup)({
  '& button': {
    flex: 1,
    maxWidth: '125px',
  },
});

export const StyledButton = styled(Button)({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '32p',

  '&:disabled': {
    opacity: 0.3,
  },
});

export const StyledCheckboxListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '1em',
    fontWeight: 600,
    lineHeight: '24px',
  },
  '& p': {
    fontSize: '.875em',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

export const StyledSlider = styled(Slider)(({ styleprops }: any) => ({
  '&.Mui-disabled': {
    '& .MuiSlider-track': {
      opacity: 0.5,
    },
  },
  '& .MuiSlider-track': {
    height: `${styleprops.track}px`,
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(104, 144, 161, 1)',
  },
  '& .MuiSlider-rail': {
    height: `${styleprops.track}px`,
    transform: 'translateY(-50%)',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
  },
  '& .MuiSlider-markLabel': {
    marginTop: '10px',
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
  '& .MuiSlider-thumb': {
    width: `${styleprops.handle}px`,
    height: `${styleprops.handle}px`,
    marginTop: `-${styleprops.handle / 2}px`,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    margin: 0,
    transform: 'translateX(-50%)',
    '&::after': {
      width: `${styleprops.dot}px`,
      height: `${styleprops.dot}px`,
      borderRadius: '50%',
      position: 'absolute',
      top: `${(styleprops.handle - styleprops.dot) / 2}px`,
      left: `${(styleprops.handle - styleprops.dot) / 2}px`,
      backgroundColor: 'rgba(202, 216, 222, 1)',
    },
  },
}));
