import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoRetailerGallery.scss';
import {
  castImageDoc,
  CavcoAlbumGallery,
  mergeAllImages,
} from '../InspirationGallery/InspirationGallery';

export function CavcoRetailerGallery(props: BrProps) {
  const {
    documents: documentRef,
    retailer: retailerRef,
    retailerGalleryLabel,
  } = props.component.getModels();

  const retailer = props.page.getContent(retailerRef);

  if (!retailer || !documentRef) {
    return null;
  }

  const retailerData = retailer.getData<RetailerData>();

  const albums = documentRef
    .filter((album: any) => props.page.getContent(album))
    .map((album: any) => {
      const dataAlbum = props.page.getContent(album);
      if (dataAlbum) {
        return castImageDoc(dataAlbum);
      }
      return null;
    });

  const mergedAlbums = mergeAllImages(albums);
  const allAlbums = [mergedAlbums, ...albums];

  return (
    <div className="cavco-retailer-gallery-container">
      <CavcoAlbumGallery
        title={retailerData.dba}
        albums={allAlbums}
        description={retailerGalleryLabel?.description}
      />
    </div>
  );
}
