/*
 *
 * This component creates the Metadata BR component.
 * This is able to be added to any editorial page created.
 *
 *
 * ******** Will update metadata, but will get overwritten if a specific page's metadata is set elsewhere.
 * ex: The metadata is pulling from here for the search page, but from
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';

const firstKeyword = (keywords: string) => {
  if (keywords) {
    const array = keywords.split(',');
    return array[0];
  }
  return '';
};

export function PageProperties(props: BrProps) {
  const models = props.component.getModels();
  const params = models.componentParameterMap;

  if (!params) {
    return null;
  }

  const {
    pageTitle,
    metaDescription,
    metaKeywords,
    canonicalUrl,
    ogTitle,
    ogImage,
    ogDescription,
    noFollow,
    noIndex,
  } = params;

  const robots = (noIndex || noFollow) && [
    noIndex === 'on' ? 'noindex' : 'index',
    noFollow === 'on' ? 'nofollow' : 'follow',
  ];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;
  const elasticType = firstKeyword(metaKeywords);

  return (
    <div className={props.page.isPreview() ? 'has-edit-button' : ''}>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {elasticType && <meta className="elastic" name="type" content={elasticType} />}
        {ogImage && <meta className="elastic" name="search_image" content={`${ogImage}`} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`/binaries${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`/binaries${ogImage}`} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
