import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Modal } from 'react-bootstrap';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { GlobalScrollToTopButton } from 'components/shared';
import SiteGlobalSwitches, { isFavoriteForUser } from 'components/utils';
import { getCookie } from 'components/hooks/cookie';
import { SignInModals } from 'components/MyAccount/SignInModals';
import SnackBar, {
  MESSAGE_SEVERITY,
  initSnackBarMessage,
  SnackBarMessage,
} from 'components/shared/SnackBar/SnackBar';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { ImageCarousel } from '../shared/ImageCarousel/ImageCarousel';
import { DisplayHomeRequestInfoWithParams } from '../DisplayHomeRequestInfo/DisplayHomeRequestInfo';
import { BreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import { SharePage } from '../SharePage/SharePage';
import { getRetailerData, initAllFavoritesForCurrentUser } from '../utils';
import useWindowDimensions from '../hooks/useWindowDimensions';
import 'components/shared/ProductDetails/ProductBanner.scss';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-files-icon.svg';
import { ReactComponent as PrintIcon } from '../../assets/images/print-icon.svg';
import { ReactComponent as RequestIcon } from '../../assets/images/request-more-info.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/save-favorites-heart-icon.svg';

function DisplayHomeBreadcrumb(props: any) {
  return (
    <BreadcrumbStack
      page={props.page}
      component={props.component}
      maxLength={!props.retailer ? 3 : undefined}
      hideLast
      displayScrollToTop
      overrides={[{ index: 2, label: 'Display Homes' }]}
    />
  );
}

function DisplayHomeIcon(props: any) {
  const printClasses = props.icon === 'print' ? 'd-none d-md-block' : '';
  const requestSVG =
    props.icon === 'request' ? <RequestIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const printSVG =
    props.icon === 'print' ? <PrintIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const saveSVG =
    props.icon === 'save' ? <SaveIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const saveActiveSVG =
    props.icon === 'save-active' ? (
      <SaveIcon className="cvc-breadcrumb-page-cta-icon heart-active" />
    ) : (
      ''
    );
  const button = props.jump ? (
    <Link
      to={props.link ?? 'download'}
      smooth
      offset={-150}
      duration={1000}
      className={props.classes ?? ''}
      id="download-button"
    >
      <DownloadIcon className="cvc-breadcrumb-page-cta-icon" />
      <p className="text display-home-banner-icon__text">
        {props.title} <span className="label-secondary">{props.desc}</span>
      </p>
    </Link>
  ) : (
    <button
      type="button"
      className={`cvc-breadcrumb-page-cta-button ${printClasses}${` ${props.classes}` ?? ''}`}
      onClick={props.clickHandler}
      id={`${props.icon}-button`}
    >
      {requestSVG}
      {printSVG}
      {saveSVG}
      {saveActiveSVG}
      <p className="text display-home-banner-icon__text">
        {props.title} <span className="label-secondary">{props.desc}</span>
      </p>
    </button>
  );

  return <>{button}</>;
}

function SaveFavorite(props: any) {
  const fpFavorite = isFavoriteForUser(props.assetId);
  const [favorite, setFavorite] = useState(fpFavorite);
  const userId = getCookie('cavcouserid');
  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);
  const [snackBarMessage, setSnackBarMessage] = useState(initSnackBarMessage);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  // eslint-disable-next-line
  // @ts-ignore
  const saveFavorite = async () => {
    const favoriteUrl = props.subId
      ? `${props.favoriteBaseUrl}/display_homes/${props.assetId}/${!favorite}?subId=${props.subId}`
      : `${props.favoriteBaseUrl}/display_homes/${props.assetId}/${!favorite}`;

    setFavorite(!favorite);
    fetch(favoriteUrl, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 400) {
          setFavorite(favorite);
          const snackBarMessageTemp: SnackBarMessage = {
            message: data.message,
            severity: MESSAGE_SEVERITY.WARNING,
          };
          setSnackBarMessage(snackBarMessageTemp);
          handleOpenSnackBar();
        } else {
          initAllFavoritesForCurrentUser(props.loadFavoriteUrl);
        }
        console.log('responseData:', data); // eslint-disable-line
      })
      .catch((error) => {
        setFavorite(favorite);
        const snackBarMessageTemp: SnackBarMessage = {
          message:
            'There is no Location for this resourceId. Please pass a valid Location resourceId',
          severity: MESSAGE_SEVERITY.WARNING,
        };
        setSnackBarMessage(snackBarMessageTemp);
        handleOpenSnackBar();
        console.log('error from the server:', error.message); // eslint-disable-line
      });
  };

  const handleSetSuccessfulSignIn = () => {
    saveFavorite();
  };

  const icon = props.icon === 'solid' ? 'save' : 'save-outline';
  return (
    <>
      <SnackBar
        openSnackBar={openSnackBar}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <DisplayHomeIcon
        classes="display-home-banner-panel__button"
        title={favorite ? props.titleAdded : props.title}
        icon={favorite ? 'save-active' : icon}
        desc={favorite ? props.descriptionAdded : props.description}
        clickHandler={userId ? saveFavorite : openLoginModal}
      />
      {loginModal && (
        <SignInModals
          loginModalClosed={closeLoginModal}
          setSuccessfulSignIn={handleSetSuccessfulSignIn}
          myAccountConfig={props.myAccountConfig}
        />
      )}
    </>
  );
}

function RequestInfo(props: any) {
  const [request, setRequest] = useState(false);
  const closeModal = () => {
    setRequest(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  const openModal = () => {
    setRequest(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };
  const windowDimensions = useWindowDimensions();

  const button =
    props.type === 'icon' ? (
      <DisplayHomeIcon
        classes={`d-md-block ${props.classes ?? ''}`}
        icon="request"
        title="Request"
        desc="More Info"
        clickHandler={openModal}
      />
    ) : (
      <button
        type="button"
        className="btn btn-primary btn-primary-sm d-lg-none"
        onClick={openModal}
      >
        Request Info
      </button>
    );

  return (
    <>
      {button}
      <Modal
        className="display-home-banner-modal cvc-request-info-modal"
        show={request}
        onHide={closeModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="id-modal-body">{props.modal}</Modal.Body>
      </Modal>
    </>
  );
}

const handlePrice = (dhData: any) => {
  const { sellingPrice, nowPrice, wasPrice, availableStatus, markAsClearance } = dhData;

  // Create our currency formatter.
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  if (availableStatus.toLowerCase() === 'sold') {
    return (
      <div className="cvc-product-banner-price-section">
        <p className="cvc-product-banner-price-label">Price</p>
        <p className="cvc-product-banner-price cvc-product-banner-price-sold">
          Sold<span className="price-asterisk">**</span>
        </p>
      </div>
    );
  }
  if (sellingPrice) {
    if (markAsClearance && nowPrice && wasPrice) {
      return (
        // do the styling for sale price
        <>
          <div className="cvc-product-banner-price-section clearance">
            <p className="cvc-product-banner-price-label">Now</p>
            <p className="cvc-product-banner-price currency-price-tag cvc-product-banner-price-clearance">
              {currencyFormatter.format(nowPrice)}
              <span className="price-asterisk">**</span>
            </p>
          </div>
          <div className="cvc-product-banner-price-section">
            <p className="was-price">
              Was: <span className="currency-price-tag">{currencyFormatter.format(wasPrice)}</span>
              <span className="price-asterisk">**</span>
            </p>
          </div>
        </>
      );
    }
    if (Number.isNaN(parseInt(sellingPrice, 10))) {
      // plain text
      return (
        <div className="cvc-product-banner-price-section">
          <p className="cvc-product-banner-price-label">Price</p>
          <p className="cvc-product-banner-price">
            {sellingPrice}
            <span className="price-asterisk">**</span>
          </p>
        </div>
      );
    }
    // format to money
    return (
      <div className="cvc-product-banner-price-section">
        <p className="cvc-product-banner-price-label">Price</p>
        <p className="cvc-product-banner-price currency-price-tag">
          {currencyFormatter.format(sellingPrice)}
          <span className="price-asterisk">**</span>
        </p>
      </div>
    );
  }
};

function showPrice(dhData: any) {
  if (!dhData) return null;

  return (
    <div className="cvc-product-banner-price-container">
      <div className="cvc-product-banner-price-content d-flex flex-column justify-content-start ml-auto ">
        {handlePrice(dhData)}
      </div>
    </div>
  );
}

export function getDHImageUrl(item: any, imgContent: any, size: string) {
  let imgPath = '';
  if (imgContent) {
    imgPath =
      imgContent.model[size] && imgContent.model[size]._links.site.href
        ? imgContent.model[size]._links.site.href
        : null;
  } else if (item.ximage) {
    imgPath = item.ximage[size] && item.ximage[size].url ? item.ximage[size].url : null;
    if (!imgPath) {
      imgPath = item.ximage[size + 'Image'] && item.ximage[size + 'Image'].url;
    }
  }

  return imgPath;
}

export function GetFirstDHImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent: any = mediaAsset[0] && props.page.getContent(mediaAsset[0].image);
  return getDHImageUrl(mediaAsset[0], imgContent, 'large');
}

export function GetFirstDHExteriorImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  let imgContent;
  let mediaAssetExterior;
  for (let i = 0; !mediaAssetExterior && i < mediaAsset.length; i += 1) {
    if (mediaAsset[i].imageType === 'Exterior Photo') {
      mediaAssetExterior = mediaAsset[i];
      imgContent = mediaAssetExterior && props.page.getContent(mediaAssetExterior.image);
    }
  }
  if (!mediaAssetExterior) return null;
  return getDHImageUrl(mediaAssetExterior, imgContent, 'large');
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  imgContent: any,
  index: any,
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {},
  mediaAssetItems: any[]
) {
  const imgLink = getDHImageUrl(item, imgContent, 'large');
  let displayMediaViewer = true;
  if (windowDimensions.isMobile || windowDimensions.isTablet) {
    displayMediaViewer = false;
    if (mediaAssetItems.length > 1) {
      const photoUrls = mediaAssetItems.map((assetItem: any) => {
        return {
          url: getDHImageUrl(assetItem, imgContent, windowDimensions.isTablet ? 'large' : 'medium'),
          alt: assetItem.imageAltText,
        };
      });
      return <ImageCarousel key="" photoUrls={photoUrls} lineDrawingUrl="" centered />;
    }
    return (
      <div
        key={index}
        className="display-home-banner-image cvc-product-banner-image"
        role="img"
        style={{ backgroundImage: `url(${imgLink})` }}
      />
    );
  }

  const handleOnClick = () => {
    if (displayMediaViewer) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (displayMediaViewer) {
      if (e.keyCode === 32) {
        setIndexItemSelected(index);
        handleShowMediaViewer();
      }
    }
  };

  return (
    <div
      role="button"
      key={`${imageGalleryName}-image-${item.id}-${index}`}
      className="display-home-banner-image cvc-product-banner-image"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
      style={displayMediaViewer ? { cursor: 'pointer', backgroundImage: `url(${imgLink})` } : {}}
      tabIndex={displayMediaViewer ? 0 : -1}
    >
      <span className="sr-only">View image gallery</span>
    </div>
  );
}

function displayMediaAssets(
  props: BrProps,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.IMAGE) return '';

  return mediaAssetItems.map((item: any, index) => {
    const imgContent: any = item && props.page.getContent(item.image);

    return (
      index === 0 &&
      displayAlbumMediaItem(
        imageGalleryName,
        item,
        imgContent,
        index,
        windowDimensions,
        setIndexItemSelected,
        handleShowMediaViewer,
        mediaAssetItems
      )
    );
  });
}

function DisplayHomeBannerMain(
  props: BrProps,
  breadcrumb: any,
  modelName: any,
  modelNumber: any,
  bathrooms: any,
  bedrooms: any,
  squareFootage: any,
  requestForm: any,
  image: any,
  socialMediaIcons: any,
  isRetailerPage: boolean,
  config: any,
  hasFiles: any,
  assetId: any,
  isFavorite: any
) {
  const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
  const windowDimensions = useWindowDimensions();
  const displayBreadcrumbScrollToTop = windowDimensions.isTablet;

  const { myAccountConfig, displayHomeBannerLabel: lbl } = props.component.getModels();

  let locationID: any = null;
  if (isRetailerPage) {
    const retailerData = getRetailerData(props.page);

    if (retailerData && retailerData.locationID) {
      locationID = retailerData?.locationID;
    }
  }

  const showBrochureButton = config.showBrochureButton === 'true';

  const keyString = locationID
    ? `display-home-detail-favorite-${locationID}-${assetId}`
    : `display-home-detail-favorite-${assetId}`;

  let pdfLink: any = null;
  if (assetId) {
    pdfLink = `${config.pdfLinkUrl + assetId}/`;
    const currentLocation = window.location.href.split('#')[0].split('?')[0];
    pdfLink += `?${config.query}=${currentLocation}`;
  }

  const downloadPDF = () => {
    if (pdfLink) {
      window.location.href = pdfLink;
    }
  };

  return (
    <div className="display-home-banner-content cvc-product-banner-content">
      <div className="display-home-banner-content__container cvc-product-banner-content__container">
        <div className="cvc-product-banner-text">
          {breadcrumb && (
            <div className="cvc-product-banner-breadcrumb">
              <DisplayHomeBreadcrumb
                page={props.page}
                component={breadcrumb}
                retailer={isRetailerPage}
              />
              <span className="cvc-product-banner-breadcrumb--last">
                {`${modelName} ${modelNumber}`}
              </span>
            </div>
          )}

          <h1 className="cvc-product-banner-title">
            Display Home: <strong className="cvc-product-banner-title-lg">{modelName} </strong>
            <span className="cvc-product-banner-title-sm">{modelNumber}</span>
          </h1>

          <p className="display-home-banner-details cvc-product-banner-details">
            {bedrooms && `${bedrooms} bd | `}
            {bathrooms && `${bathrooms} ba | `}
            {squareFootage && `${squareFootage} sqft`}
          </p>

          <div className="cvc-product-banner-buttons">
            <RequestInfo type="button" modal={requestForm} />
          </div>
        </div>

        <div className="display-home-banner-panel product-banner-panel display-home-banner-panel__icons product-banner-panel__icons d-sm-flex flex-lg-row flex-md-column">
          {showFavorites && (
            <SaveFavorite
              key={keyString}
              icon="solid"
              classes="display-home-banner-panel__button"
              favoriteBaseUrl={myAccountConfig ? myAccountConfig.favoriteBaseUrl : ''}
              assetId={assetId}
              subId={locationID}
              isFavorite={isFavorite}
              title={lbl.favoriteTitle}
              description={lbl.favoriteDescription}
              titleAdded={lbl.favoriteTitleAdded}
              descriptionAdded={lbl.favoriteDescriptionAdded}
              loadFavoriteUrl={myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : ''}
              myAccountConfig={myAccountConfig}
            />
          )}
          {requestForm && (
            <RequestInfo
              classes="display-home-banner-panel__button"
              type="icon"
              modal={requestForm}
            />
          )}
          {hasFiles && (
            <DisplayHomeIcon
              title="Download"
              jump
              icon="download"
              desc="Files"
              classes="cvc-breadcrumb-page-cta-button display-home-banner-panel__button"
            />
          )}

          {showBrochureButton && (
            <DisplayHomeIcon
              classes=" display-home-banner-panel__button"
              title="Print"
              icon="print"
              desc="Brochure"
              clickHandler={downloadPDF}
            />
          )}

          <SharePage
            classes=" display-home-banner-panel__button"
            textClass=" display-home-banner-icon__text"
            ogImage={image?.url}
            icons={socialMediaIcons}
          />
          {displayBreadcrumbScrollToTop && (
            <GlobalScrollToTopButton className="scroll scroll-to-top-breadcrumb" />
          )}
        </div>
      </div>
    </div>
  );
}

// DisplayHomeBanner - show banner with first exterior photo if one exists, or gray if there is none
export function DisplayHomeBanner(props: BrProps) {
  const {
    socialMediaIcons,
    retailerDisplayHomeBannerConfig: config,
    componentParameterMap: paramMap,
  } = props.component.getModels();

  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('dh-request-form');

  const { hideBreadcrumb } = paramMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const models = details?.getComponent('dh-data')?.getModels();
  const dhData = models && models.displayHomeData;
  const document = models?.document ? props.page.getContent(models.document) : undefined;

  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const isRetailerPage = !!props.page.getComponent('retailer-header-content');

  if (!document || !dhData) {
    return null;
  }

  const image = dhData.photoUrls ? dhData.photoUrls[0] : null;
  // const { photoAlbum } = dhData;
  const hasFiles = dhData.hasDownloadableFiles;
  const {
    modelName,
    modelNumber,
    bathRoomsValue: bathrooms,
    bedRoomsValue: bedrooms,
    squareFeet: squareFootage,
    assetId,
    isFavorite,
    //photos,
  } = document.getData<BannerData>();

  const imageGalleryName = props.component.getName();
  //const photosDocument = image && props.page.getContent(photos);
  let mediaViewer;
  let mediaAssetDisplay;
  let imageGalleryId = imageGalleryName;
  // const image = GetFirstDHImgUrl(props, photos);
  // if there is a banner image we set up linking to the gallery

  if (dhData.photoAlbum && image) {
    const { title, mediaType, mediaAssets } = dhData.photoAlbum;
    let id = 0;
    imageGalleryId += `-${id++}`; // eslint-disable-line
    mediaViewer = (
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={mediaAssets}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
      />
    );
    mediaAssetDisplay = displayMediaAssets(
      props,
      imageGalleryName,
      mediaType,
      mediaAssets,
      windowDimensions,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  }

  const requestForm = form && (
    <DisplayHomeRequestInfoWithParams
      page={props.page}
      component={form}
      displayInsideModal
      formId="display-home-banner-cta-form"
    />
  );
  let productPageType = '';
  if (!isRetailerPage) {
    productPageType = 'cvc-product-on-main';
  }

  return (
    <div id={imageGalleryId} key={imageGalleryId}>
      {showPrice(dhData)}
      {mediaViewer && mediaViewer}
      <div
        className={`display-home-banner-container cvc-product-banner-container ${productPageType}`}
      >
        <div className={`display-home-banner cvc-product-banner ${!image ? ' no-image' : ''}`}>
          <BrManageContentButton content={document} />
          {!image || !mediaAssetDisplay ? '' : mediaAssetDisplay}
          {DisplayHomeBannerMain(
            props,
            breadcrumb,
            modelName,
            modelNumber,
            bathrooms,
            bedrooms,
            squareFootage,
            requestForm,
            image,
            socialMediaIcons,
            isRetailerPage,
            config,
            hasFiles,
            assetId,
            isFavorite
          )}
        </div>
      </div>
    </div>
  );
}
