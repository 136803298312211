import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import YouTube from 'react-youtube';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from 'components/shared';
import { getYoutubeId } from 'components/utils';
import { Spinner } from 'components/shared/Spinner';
import './MediaModal.scss';

interface MediaModalProps {
  show: boolean;
  mediaSrc?: string;
  isVideo?: boolean;
  title: string;
  handleClose: () => void;
}

export const MediaModal: React.FC<MediaModalProps> = ({
  show,
  mediaSrc,
  isVideo = false,
  title,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const videoId = isVideo ? getYoutubeId({ videoURL: mediaSrc }) : '';

  const toggleLoading = () => {
    setIsLoading(false);
  };

  const onClose = () => {
    setIsLoading(true);
    handleClose();
  };

  return (
    <Modal
      className="media-modal media-modal_overlay"
      show={show}
      onHide={onClose}
      dialogClassName="media-modal_dialog"
      backdropClassName="media-modal_overlay"
    >
      {isLoading && (
        <div className="media-modal_spinner">
          <Spinner inDarkBackground size="lg" />
        </div>
      )}
      <div className="media-modal_close">
        <IconButton onClick={onClose}>
          <CloseIcon width={40} height={40} className="media-modal_close-icon" />
        </IconButton>
      </div>
      {isVideo && (
        <>
          <YouTube
            videoId={videoId}
            containerClassName="media-modal_iframe-container"
            onReady={toggleLoading}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                controls: 1,
                start: 0,
                disablekb: 1,
                enablejsapi: 1,
                iv_load_policy: 3,
                modestbranding: 1,
              },
            }}
          />
        </>
      )}{' '}
      {!isVideo && !!mediaSrc && (
        <iframe
          onLoad={toggleLoading}
          title={title}
          ref={iframeRef}
          className="media-modal_iframe"
          src={mediaSrc}
          frameBorder="0"
        />
      )}
    </Modal>
  );
};
