import React, { useState } from 'react';

import classNames from 'classnames';

import { Card } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';

import { ResolverLink } from 'components/shared';

import { MediaViewer } from 'components/MediaViewer/MediaViewer';
import { ALBUM_MEDIA_TYPES } from 'components/enums';

import {
  FavoriteIcon,
  VirtualIcon,
  VideoIcon,
  USAFlagIcon,
  CANFlagIcon,
  ComingSoonCrossStitchPicture,
} from 'components/shared/Icons';

import SiteGlobalSwitches from 'components/utils';
import { resultMapper } from 'components/shared/ProductResultCard/procuctResultCard.mapper';
import { ImageCarousel } from '../../shared/ImageCarousel/ImageCarousel';

interface FloorPlanCardProps {
  key: string;
  floorPlanResult: any;
  windowDimensions: any;
  props: BrProps;
  isBuildingCenter: boolean;
}

export const FloorPlanCard = (floorPlanCardProps: FloorPlanCardProps) => {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const [mediaTypeVal, setMediaTypeVal] = useState('');
  const mediaAssetsArray: [any] = [
    {
      url: '',
      altText: '',
    },
  ];
  const [mediaAssetsVal, setMediaAssetsVal] = useState(mediaAssetsArray);

  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);

  const mapped: any = resultMapper(floorPlanCardProps.floorPlanResult.data);

  const {
    id,
    asset_id,
    name,
    model_number,
    photos,
    line_drawings,
    video_tour: videoUrl,
    virtual_tour: tourUrl,
    pana_tour: panatourUrl,
    number_of_bedrooms,
    number_of_bathrooms,
    square_foot,
    building_method,
    series,
    // isFavorite,
    starting_price,
    url,
    floorplan_availability,
    plant_location_id,
  } = mapped;

  const { isBuildingCenter } = floorPlanCardProps;

  const floorplanUrlName = url.split('/')[5];
  const basePath = window.location.pathname.replace(/floorplans\/search\/.+/, 'floorplans');

  const theFloorPlanUrl = `${basePath}/${floorplanUrlName}`;

  const inStock = floorplan_availability && floorplan_availability.includes('In Stock Now');
  const country = theFloorPlanUrl && theFloorPlanUrl.includes('/ca/') ? 'Canada' : 'United States';

  let url3dTour: any;
  let mediaType3dTour: any;
  if (tourUrl) {
    url3dTour = tourUrl;
    mediaType3dTour = ALBUM_MEDIA_TYPES.VIRTUAL_TOUR;
  } else if (!tourUrl && panatourUrl) {
    url3dTour = panatourUrl;
    mediaType3dTour = ALBUM_MEDIA_TYPES.PANATOUR;
  }

  // My Account
  const compModels = floorPlanCardProps.props.component.getModels();
  const plantLocationUid = isBuildingCenter ? plant_location_id : null;
  const favoriteBaseUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.favoriteBaseUrl
    : null;
  const loadFavoriteUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.cavcoFavoriteUrl
    : null;

  const showFlag = SiteGlobalSwitches.showFloorPlanCountry;

  const lineDrawing = line_drawings && line_drawings[0];

  let imageDefault = null;
  if (photos && photos[0]) {
    // first image should be shown
    imageDefault = photos[0]; // eslint-disable-line
  }

  if (lineDrawing) {
    if (!imageDefault || imageDefault.url === '') {
      imageDefault = lineDrawing;
    }
  }

  const floorPlanTitle = `${name ?? ''} ${model_number ?? ''}`;

  const displayBedroomsBathroomsAndSquareFootage = () => {
    return (
      <div className="product-card-bed-bath-sqft">
        <span>{number_of_bedrooms && `${number_of_bedrooms} bd`} </span>
        {number_of_bathrooms ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{number_of_bathrooms && `${number_of_bathrooms} ba`}</span>
        {square_foot ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{square_foot && `${square_foot} sqft`}</span>
        {inStock && <span className="in-stock-indicator">In Stock</span>}
      </div>
    );
  };

  const formatPrice = () => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    if (Number.isNaN(parseInt(starting_price, 10))) {
      // plain text
      return starting_price;
    }

    return currencyFormatter.format(starting_price);
  };

  const displayCardBody = () => {
    const buildingMethodText = building_method ? `${building_method} Home` : '';
    const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
    const showPricing = SiteGlobalSwitches.showFloorPlanPricing;
    const keyString = `floorplan-card-favorite-${asset_id}`;
    const subIdType = plantLocationUid ? 'subIdTypeBuildingCenter' : null;

    return floorPlanCardProps.windowDimensions.isMobile ? (
      <>
        <Card.Title>
          <ResolverLink className="card-link" href={theFloorPlanUrl}>
            <span className="floorplan-card-title product-card-title">{floorPlanTitle}</span>
          </ResolverLink>
        </Card.Title>
        <div className="card-text">
          {displayBedroomsBathroomsAndSquareFootage()}
          {showFavorites && name ? (
            <FavoriteIcon
              key={keyString}
              classes="floorplan-save-favorites-icon"
              type="our_homes"
              id={asset_id}
              subId={plantLocationUid}
              // isFavorite={isFavorite}
              baseUrl={favoriteBaseUrl}
              loadFavoriteUrl={loadFavoriteUrl}
              modelName={name}
              subIdType={subIdType}
              myAccountConfig={compModels.myAccountConfig}
            />
          ) : (
            ''
          )}
        </div>
      </>
    ) : (
      <>
        <Card.Title className="is-desktop-view">
          <ResolverLink className="card-link" href={theFloorPlanUrl}>
            <span className="floorplan-card-title product-card-title">{floorPlanTitle}</span>
          </ResolverLink>
          {displayBedroomsBathroomsAndSquareFootage()}
        </Card.Title>
        <div className="is-desktop-view card-text">
          <div className="floorplan-favorite-building-container">
            {showFavorites && name ? (
              <FavoriteIcon
                key={keyString}
                classes="floorplan-save-favorites-icon"
                type="our_homes"
                id={asset_id}
                subId={plantLocationUid}
                // isFavorite={isFavorite}
                baseUrl={favoriteBaseUrl}
                loadFavoriteUrl={loadFavoriteUrl}
                modelName={name}
                subIdType={subIdType}
                myAccountConfig={compModels.myAccountConfig}
              />
            ) : (
              ''
            )}
            {buildingMethodText ? (
              <span className="floorplan-building-method-text">{buildingMethodText}</span>
            ) : (
              ''
            )}
            {showPricing && starting_price ? (
              <span className="floorplan-series-price-text">
                Starts in the {formatPrice()}&apos;s<span className="price-asterisk">**</span>
              </span>
            ) : (
              <span className="floorplan-series-price-text">{series}</span>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleIconMediaIconClick = (mediaUrl: any, mediaType: string) => {
    setMediaTypeVal(mediaType);
    setMediaAssetsVal([mediaUrl]);
    handleShowMediaViewer();
  };

  return (
    <Card id={id} className={imageDefault === lineDrawing ? ' line-drawing' : ''}>
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaTypeVal}
        mediaAssetItems={mediaAssetsVal}
        albumName=""
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={0}
      />
      {!imageDefault ? (
        <ResolverLink className="card-link" href={theFloorPlanUrl}>
          <div className="card-no-photo">
            <ComingSoonCrossStitchPicture title="image of home coming soon" />
          </div>
        </ResolverLink>
      ) : (
        <ResolverLink className="card-link" href={theFloorPlanUrl}>
          <ImageCarousel photoUrls={photos} lineDrawingUrl={lineDrawing} />
        </ResolverLink>
      )}
      {!floorPlanCardProps.windowDimensions.isMobile ? (
        <>
          {showFlag && (
            <div className="floorplan-flag-icon cvc-product-list-card-icon">
              {country && country === 'Canada' ? (
                <CANFlagIcon title="Available in Canada" />
              ) : (
                <USAFlagIcon title="Available in the US" />
              )}
            </div>
          )}
          {url3dTour ? (
            <div
              className={classNames(
                'floorplan-3d-virtual-icon',
                'cvc-product-list-card-icon',
                showFlag ? 'show-after-flag-icon' : ''
              )}
            >
              <VirtualIcon
                onClick={() =>
                  handleIconMediaIconClick({ matterportURL: url3dTour }, mediaType3dTour)
                }
              />
            </div>
          ) : (
            ''
          )}
          {videoUrl ? (
            <div
              className={classNames(
                'floorplan-video-icon',
                'cvc-product-list-card-icon',
                showFlag && url3dTour ? 'show-after-two-icons' : '',
                (showFlag && !url3dTour) || (!showFlag && url3dTour) ? 'show-after-one-icon' : ''
              )}
            >
              <VideoIcon
                onClick={() =>
                  handleIconMediaIconClick({ videoURL: videoUrl }, ALBUM_MEDIA_TYPES.VIDEO)
                }
              />
            </div>
          ) : (
            ''
          )}
          {lineDrawing && photos && photos[0] ? (
            <div className="floorplan-line-drawings-container">
              <ResolverLink className="card-link" href={theFloorPlanUrl}>
                <img alt={lineDrawing.imageAltText} src={lineDrawing.url} />
              </ResolverLink>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <Card.Body>{displayCardBody()}</Card.Body>
    </Card>
  );
};
