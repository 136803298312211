import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { CaretDownIcon, DoneIcon } from 'components/shared';
import { ListGroup } from 'react-bootstrap';

export const FloorPlanSort = ({
  config,
  sortBy = null,
  changeSort,
  windowDimensions,
  count,
}: {
  config: any;
  sortBy: string | null;
  changeSort: any;
  windowDimensions: any;
  count: any;
}) => {
  const options = useRef<any>(
    config.values.reduce((memo: any, cur: any) => ({ ...memo, [cur.name]: cur }), {})
  );
  const defaultString = JSON.stringify(sortBy || config.defaultValue);
  const defaultValue = defaultString.replace(/:/g, '_').replace(/[^a-z0-9_]/gi, '');

  const [showListGroupSortByOptions, setShowListGroupSortByOptions] = useState(false);
  const [currentSortLabel] = useState(options.current[defaultValue].label);

  const handleChangeSort = (value: any) => {
    changeSort(options.current[value].value);
  };

  const displayListGroupSortByOptions = () => {
    const handleListGroupItemHover = (isHover: boolean) => {
      setShowListGroupSortByOptions(isHover);
    };

    const isNotDesktopScreen = !!(windowDimensions.isMobile || windowDimensions.isTablet);

    return (
      <ListGroup
        className={classNames('accordion-sort-by-list', isNotDesktopScreen ? 'is-mobile' : '')}
        onMouseOver={() => handleListGroupItemHover(true)}
        onMouseLeave={() => handleListGroupItemHover(false)}
        onFocus={() => handleListGroupItemHover(true)}
        onBlur={() => handleListGroupItemHover(false)}
      >
        {config.values?.map((sortOption: any, index: number) => {
          // const itemSortByTitleDisplayed = `${fpSort.sortBy} ${sortTitlesArray[index]}`;
          const itemSortByTitle = sortOption.label;
          const itemSortByField = sortOption.name;
          const itemSortByOrder = sortOption.order;

          return (
            <ListGroup.Item
              key={`${itemSortByField}-${itemSortByOrder}-${index}`}
              action
              onClick={() => handleChangeSort(sortOption.name)}
              active={isNotDesktopScreen && currentSortLabel === itemSortByTitle}
              disabled={count === 0}
            >
              {itemSortByTitle}
              {isNotDesktopScreen && currentSortLabel === itemSortByTitle ? (
                <DoneIcon className="sort-by-mobile-done-icon" />
              ) : (
                ''
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const handleAccordionSortByContainerHover = (isHover: boolean) => {
    setShowListGroupSortByOptions(isHover);
  };

  return windowDimensions.isMobile || windowDimensions.isTablet ? (
    <>{displayListGroupSortByOptions()}</>
  ) : (
    <div
      className="accordion-filter-sort-by-container"
      onMouseOver={() => handleAccordionSortByContainerHover(true)}
      onMouseLeave={() => handleAccordionSortByContainerHover(false)}
      onFocus={() => handleAccordionSortByContainerHover(true)}
      onBlur={() => handleAccordionSortByContainerHover(false)}
    >
      <div
        className={classNames(
          'accordion-filter-sort-by-title',
          !windowDimensions.isMobile && !windowDimensions.isTablet ? `is-desktop` : ''
        )}
      >
        <span
          className={showListGroupSortByOptions ? 'lighter-text-color' : ''}
        >{`Sort By: ${currentSortLabel}`}</span>
        <CaretDownIcon
          className={classNames(
            'accordion-filter-sort-by-icon',
            showListGroupSortByOptions ? 'rotate-icon' : ''
          )}
        />
      </div>
      {showListGroupSortByOptions ? displayListGroupSortByOptions() : ''}
    </div>
  );
};
