/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import classNames from 'classnames';
import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { BrProps, BrManageContentButton, BrPageContext } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import { ReactComponent as WhiteQuotes } from '../../assets/images/quotes-white.svg';
import { PAGE_VARIANT, SCREEN_SIZES } from '../enums';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { ReactComponent as RightArrowIconOnCircle } from '../../assets/images/right-arrow-on-transparent-circle-icon.svg';
import { ReactComponent as LeftArrowIconOnCircle } from '../../assets/images/left-arrow-on-transparent-circle-icon.svg';
import { getUrlFromImage } from '../utils';

import './CarouselBanner.scss';
import { CavcoControlledCarousel } from './CavcoCarouselBanner';

interface ControlledCarouselProps {
  componentId: string;
  componentParameterMap: any;
  scrollingInterval: number | null;
  documents: any[];
}

function getItemLink(linkRef: any) {
  if (!linkRef) return '';

  return (
    <ResolverLink
      className="cvc-carousel-banner__item-link text-left"
      href={linkRef.destinationUrl}
      title={linkRef.linkTitle}
      target={linkRef.newWindow ? '_blank' : ''}
    >
      {linkRef.displayText}
    </ResolverLink>
  );
}

enum CAROUSEL_BANNER_CONTENT1_SIZES {
  MOBILE = 14,
  TABLET = 18,
  DESKTOP_EXTRA_BIG = 24,
}

function getNewFontSize(windowsDimensions: any, contentLength: number) {
  let currentFontSize = 14;

  if (windowsDimensions.isMobile) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.MOBILE;
  } else if (
    windowsDimensions.isTablet ||
    windowsDimensions.isDesktop ||
    windowsDimensions.isDesktopBig
  ) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.TABLET;
  } else if (windowsDimensions.isDesktopExtraBig) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.DESKTOP_EXTRA_BIG;
  }
  const newFontSize = `${95 - Number((currentFontSize / contentLength).toFixed(4))}%`;
  return newFontSize;
}

function getContent1(content1Val: any, newFontSize = '', linkRef: any) {
  if (!content1Val) return '';
  const link = linkRef && getItemLink(linkRef);

  return (
    <div className="cvc-carousel-banner__item-content__container">
      <div
        className="cvc-carousel-banner__item-content1"
        dangerouslySetInnerHTML={{ __html: content1Val }}
        style={newFontSize ? { fontSize: `${newFontSize}` } : {}}
      />
      {link}
    </div>
  );
}

function getImage(page: any, imageRef: any, variantValue: string) {
  if (!imageRef) return null;

  const imgContent: any = imageRef && page.getContent(imageRef.imagelink);
  if (!imgContent) return null;

  let mediumImgPath = null;
  let smallImgPath = null;
  let largeImgPath = null;
  let extraLargeImgPath = null;

  if (imgContent) {
    smallImgPath = getUrlFromImage(imgContent, 'small');
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
  } else if (imageRef.ximage) {
    smallImgPath =
      imageRef.ximage.small && imageRef.ximage.small.url ? imageRef.ximage.small.url : null;
    mediumImgPath =
      imageRef.ximage.medium && imageRef.ximage.medium.url ? imageRef.ximage.medium.url : null;
    largeImgPath =
      imageRef.ximage.large && imageRef.ximage.large.url ? imageRef.ximage.large.url : null;
    extraLargeImgPath =
      imageRef.ximage.extraLarge && imageRef.ximage.extraLarge.url
        ? imageRef.ximage.extraLarge.url
        : null;
  }

  let imageVariantClasses = '';
  switch (variantValue) {
    case 'noImage':
      break;
    case 'imageLeft':
      imageVariantClasses = 'order-md-0';
      break;
    case 'imageRight':
      imageVariantClasses = 'order-md-1';
      break;
    default:
      break;
  }

  return (
    <div className={`cvc-carousel-banner__item-image order-0 ${imageVariantClasses}`}>
      <picture>
        {extraLargeImgPath && (
          <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={extraLargeImgPath} />
        )}
        {largeImgPath && (
          <source
            media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
            srcSet={largeImgPath}
          />
        )}
        {mediumImgPath && (
          <source
            media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
            srcSet={mediumImgPath}
          />
        )}
        <img alt={imageRef.alt} src={smallImgPath} />
      </picture>
    </div>
  );
}

function getCarouselItemHeight(id: string, prevId: any) {
  if (!id) return null;

  const carouselItem = document.getElementById(id);
  const prevCarouselItem = document.getElementById(prevId);
  if (carouselItem) {
    const carouselItemHeight = carouselItem.getBoundingClientRect().height;
    const prevCarouselItemHeight = prevCarouselItem
      ? prevCarouselItem.getBoundingClientRect().height
      : carouselItemHeight;

    return carouselItemHeight < prevCarouselItemHeight
      ? prevCarouselItemHeight
      : carouselItemHeight;
  }
}

function setCarouselBannerHeight(componentId: string, newHeight: number) {
  const carouselBanner: any = document.getElementById(componentId);
  if (carouselBanner) {
    carouselBanner.style.height = `${newHeight}px`;
  }
}

function ControlledCarousel({
  componentId,
  componentParameterMap,
  scrollingInterval,
  documents,
}: ControlledCarouselProps) {
  const windowDimensions = useWindowDimensions();
  const { isMobile, isTablet, isDesktop, isDesktopBig, isDesktopExtraBig } = windowDimensions;
  const page = React.useContext(BrPageContext)!;
  const [index, setIndex] = useState(0);
  const updatedFontSizeDocIds = [''];

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
    const ref = documents[selectedIndex];
    const previousRef = documents[selectedIndex - 1] || documents[documents.length - 1];
    const bannerDocument: any = ref && page.getContent(ref);
    const prevBannerDocument: any = previousRef && page.getContent(previousRef);
    const docId = bannerDocument.model.id;
    const prevDocId = prevBannerDocument.model.id;

    if (isDesktop || isDesktopBig || isDesktopExtraBig) {
      if (updatedFontSizeDocIds.length > 1) {
        const carouselItemHeight = getCarouselItemHeight(docId, prevDocId);
        if (carouselItemHeight) {
          setCarouselBannerHeight(componentId, carouselItemHeight);
        }
      }
    }
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={scrollingInterval}
      indicators={false}
      fade
      nextIcon={
        <div className="cvc-carousel-banner__next">
          <RightArrowIconOnCircle className="cvc-carousel-banner__next-icon" />
        </div>
      }
      prevIcon={
        <div className="cvc-carousel-banner__prev">
          <LeftArrowIconOnCircle className="cvc-carousel-banner__prev-icon" />
        </div>
      }
    >
      {documents.map((item: any, documentIndex: number) => {
        const document = page.getContent(documents[documentIndex]);

        if (!document) {
          return null;
        }

        const { id, content1, image: imageRef, link: linkRef } = document.getData<BannerData>();
        const content1Val = content1 && page.rewriteLinks(content1.value);
        const htmlTagRe = /<.+?>/g;
        const contentWithoutTags = content1Val.replace(htmlTagRe, '');
        const variant = `variant${documentIndex + 1}`;
        const variantValue = componentParameterMap[variant];
        let newFontSize = '';

        if (contentWithoutTags.length > 875 && isDesktopExtraBig) {
          newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

          const registeredDocId = updatedFontSizeDocIds.find(
            (updatedDocId: any) => updatedDocId === id
          );
          if (!registeredDocId) {
            updatedFontSizeDocIds.push(id);
          }
        }
        if (contentWithoutTags.length > 825 && (isTablet || isDesktop || isDesktopBig)) {
          newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

          const registeredDocId = updatedFontSizeDocIds.find(
            (updatedDocId: any) => updatedDocId === id
          );
          if (!registeredDocId) {
            updatedFontSizeDocIds.push(id);
          }
        }

        if (contentWithoutTags.length > 481 && isMobile) {
          newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

          const registeredDocId = updatedFontSizeDocIds.find(
            (updatedDocId: any) => updatedDocId === id
          );
          if (!registeredDocId) {
            updatedFontSizeDocIds.push(id);
          }
        }

        let itemContentVariantClasses = '';
        switch (variantValue) {
          case 'noImage':
            break;
          case 'imageLeft':
            itemContentVariantClasses = 'order-md-1';
            break;
          case 'imageRight':
            itemContentVariantClasses = 'order-md-0';
            break;
          default:
            break;
        }

        return (
          <Carousel.Item
            id={id}
            key={id}
            className={`cvc-carousel-banner__item d-flex flex-column flex-lg-row ${variantValue} ${
              page.isPreview() ? ' has-edit-button' : ''
            }`}
          >
            <BrManageContentButton content={document} />
            <div
              className={`cvc-carousel-banner__item-content order-1 ${itemContentVariantClasses} text-left col-md-7`}
            >
              <div className="cvc-carousel-banner__item-content-wrapper container">
                <div className="cvc-carousel-banner__item-quotes-content1-wrapper d-flex flex-row flex-lg-column">
                  <div className="cvc-carousel-banner__item-quotes">
                    <WhiteQuotes />
                  </div>
                  {content1Val && getContent1(content1Val, newFontSize, linkRef)}
                </div>
              </div>
            </div>
            {variantValue !== 'noImage' && imageRef && getImage(page, imageRef, variantValue)}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export function CarouselBanner(props: BrProps) {
  const { documents, componentParameterMap } = props.component.getModels();
  const componentId = props.component.getId();
  let variant = 'default';
  if (!documents) return null;

  const element = document.querySelector('.cavcohomes');

  if (element) {
    variant = PAGE_VARIANT.CAVCOHOMES;
  }

  const carouselBannerClasses = classNames({
    'cvc-carousel-banner': true,
  });

  const scrollingInterval =
    componentParameterMap && componentParameterMap.scrollingInterval
      ? Number(componentParameterMap.scrollingInterval * 1000)
      : null;

  if (variant === PAGE_VARIANT.CAVCOHOMES) {
    return (
      <div>
        <CavcoControlledCarousel
          componentId={componentId}
          componentParameterMap={componentParameterMap}
          scrollingInterval={scrollingInterval}
          documents={documents}
        />
      </div>
    );
  }

  return (
    <div id={componentId} className={carouselBannerClasses}>
      {documents && (
        <ControlledCarousel
          componentId={componentId}
          componentParameterMap={componentParameterMap}
          scrollingInterval={scrollingInterval}
          documents={documents}
        />
      )}
    </div>
  );
}
