import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import { Element } from 'react-scroll';
import 'components/shared/ProductDetails/ProductAvailability.scss';

function listRetailers(retailers: any, listState: any) {
  return retailers.map((retailer: any, indexRetailer: number) => {
    const { name, state, city, url, inStock } = retailer;
    let retailerInfo;
    if (state.localeCompare(listState) === 0) {
      retailerInfo = (
        <li key={`retailer-li-${indexRetailer.toString()}`}>
          {city} -{' '}
          <ResolverLink
            key={`retailer-a-${indexRetailer.toString()}`}
            href={url}
            target={url.startsWith('/') ? '' : '_blank'}
          >
            {name}
          </ResolverLink>
          {inStock && <span className="cvc-fp-availability-retailer-in-stock">In Stock</span>}
        </li>
      );
    }
    return retailerInfo;
  });
}

function ListStates(props: any) {
  const retailersInStock = props.retailers.filter((r: { inStock: boolean }) => r.inStock);
  const retailersNotInStock = props.retailers.filter((r: { inStock: boolean }) => !r.inStock);

  return (
    <ul key="cvc-state-list" className="cvc-state-list">
      {props.states.map((state: any, indexState: number) => (
        <div key={`cvc-state-detail-${indexState.toString()}`}>
          <li key={`state-${state}-li-${indexState.toString()}`}>{state}</li>
          <ul key={`state-${state}-ul-${indexState.toString()}`} className="cvc-retailer-list">
            {listRetailers(retailersInStock, state)}
            {listRetailers(retailersNotInStock, state)}
          </ul>
        </div>
      ))}
    </ul>
  );
}

export function FloorPlanAvailability(props: BrProps) {
  const { retailers, floorPlanAvailabilityLabels } = props.component.getModels();

  const { title } = floorPlanAvailabilityLabels;
  const { altDescription } = floorPlanAvailabilityLabels;
  const isPark = window.location.href.includes('park');
  const description = isPark
    ? floorPlanAvailabilityLabels.description_park
    : floorPlanAvailabilityLabels.description;

  // If a floorplan is not linked to a series the Availability component will not appear
  if (!retailers) {
    return <></>;
  }
  // If the retailer list is empty change the description text
  const descriptionText = !retailers.length ? altDescription : description;

  retailers.sort((a: { city: string }, b: { city: string }) => a.city.localeCompare(b.city));

  let states: any = [];

  retailers.forEach((retailer: any) => {
    states.push(retailer.state);
  });

  states = Array.from(new Set(states)).sort();

  return (
    <Element key="cvc-fp-availability-element" name="availability">
      <div
        key="cvc-fp-availability-container"
        className="cvc-fp-availability cvc-product-availability"
      >
        <h2 className="cvc-fp-availability-title">{title}</h2>
        <p>{descriptionText}</p>
        <ListStates key="cvc-fp-availability-list-states" states={states} retailers={retailers} />
      </div>
    </Element>
  );
}
