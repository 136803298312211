import React, { useState, useEffect, FunctionComponent } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { useLocation } from 'react-router-dom';
import { Container, Button, Form, ListGroup, Collapse } from 'react-bootstrap';
import SiteGlobalSwitches, {
  getBuildingCenterFromHeader,
  getTileGridDocumentsFromComponent,
} from 'components/utils';
import './FloorPlanList.scss';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  FiltersIcon,
  CloseIcon,
  RightArrowIcon,
  CaretDownIcon,
  AddIcon,
  RemoveIcon,
  DoneIcon,
} from 'components/shared/Icons';
import { GlobalScrollToTopButton, ResolverLink } from 'components/shared';
import { NoResultsTileGrid } from 'components/TileGrid/TileGrid';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { compareValues } from '../utils';
import { FloorPlanCard } from './FloorPlanCard';
import { NoFloorPlansMessage } from './NoFloorPlansMessage';
import { NoFloorPlansFoundForFilters } from './NoFloorPlansFoundForFilters';
import { FloorPlanSearch } from './FloorPlanSearch';
import { FloorPlanDocument } from './FloorPlanList.interface';

function displayFloorPlanCards(
  props: BrProps,
  floorplanDocumentsInput: any,
  windowDimensions: any,
  isSearchPage: boolean
) {
  if (!floorplanDocumentsInput || floorplanDocumentsInput === undefined) {
    return NoFloorPlansFoundForFilters();
  }

  if (
    floorplanDocumentsInput !== undefined &&
    floorplanDocumentsInput.length > 0 &&
    floorplanDocumentsInput[0].id !== ''
  ) {
    const displayFPCards = () => {
      return (
        <div
          className={`floorplans-cards-container cvc-product-list-cards${
            isSearchPage ? '' : ' cvc-floorplan-list-availability'
          }`}
        >
          {floorplanDocumentsInput.map((fpDocument: any, index: number) => {
            const keyString = `floorplan-card-${index.toString()}`;
            return (
              <FloorPlanCard
                key={keyString}
                floorPlanDocumentData={fpDocument}
                windowDimensions={windowDimensions}
                props={props}
              />
            );
          })}
        </div>
      );
    };
    return displayFPCards();
  }
}

enum PAGE_URL {
  RETAILER_PAGE_ROOT = 'our-retailers',
  FLOORPLAN_PAGE_ROOT = 'our-homes',
  PARK_RETAILER_PAGE_ROOT = 'our-park-retailers',
  BUILDING_CENTER = 'building-center',
  PARK_MODELS_PAGE_ROOT = 'our-park-models',
}

enum FLOORPLAN_FIELDS {
  DISTANCE = 'distance',
  NUM_BEDROOMS = 'numBedrooms',
  NUM_BATHROOMS = 'numBathrooms',
  SQUARE_FOOTAGE = 'squareFootage',
  SECTIONS = 'sections',
  BUILDING_METHOD = 'buildingMethodValue',
  MEDIA = 'media',
  AVAILABILITY = 'availability',
  SERIES = 'series',
}

export function FloorPlanList(props: BrProps) {
  const isRetailerPage =
    window.location.pathname.includes(PAGE_URL.RETAILER_PAGE_ROOT) ||
    window.location.pathname.includes(PAGE_URL.PARK_RETAILER_PAGE_ROOT);

  const isParkModel = window.location.pathname.includes(PAGE_URL.PARK_MODELS_PAGE_ROOT);

  const isBuildingCenterOnly =
    window.location.pathname.includes(PAGE_URL.BUILDING_CENTER) &&
    !window.location.pathname.includes('search');

  const {
    buildingCenterName,
    siteSearchLabels,
    componentParameterMap,
    isCommunity,
    titleText,
    subTitleText,
  } = props.component.getModels();

  const { page } = props;
  const [displayInMobile, setDisplayInMobile] = useState('cards');
  const [yourFilters, setYourFilters] = useState([
    {
      id: '',
      text: '',
      value: '',
      floorPlanField: '',
      allowsMultipleSelection: true,
      valueType: '',
    },
  ]);
  const [yourFiltersInitial, setYourFiltersInitial] = useState([
    {
      id: '',
      text: '',
      value: '',
      floorPlanField: '',
      allowsMultipleSelection: true,
      valueType: '',
    },
  ]);
  let idDistanceFilterOptionValid = '';
  const [idSmallestDistanceFilterOptionValid, setIdSmallestDistanceFilterOptionValid] =
    useState('');
  const smallDistanceFilterValid = {
    id: '',
    text: '',
    value: '',
    floorPlanField: '',
    allowsMultipleSelection: false,
    valueType: '',
  };
  const [sortByFilterTitle, setSortByFilterTitle] = useState('');
  const [sortByFilterSelected, setSortByFilterSelected] = useState('');
  const [sortByOrderSelected, setSortByOrderSelected] = useState('');
  const [showListGroupSortByOptions, setShowListGroupSortByOptions] = useState(false);
  const [sortOptionsMobileOpen, setSortOptionsMobileOpen] = useState(false);
  const [accordionFiltersMobileOpen, setAccordionFiltersMobileOpen] = useState(false);
  const [floorplanDocumentsOriginal, setFloorplanDocumentsOriginal] = useState([]);
  const mapByValue = new Map();
  const bcDocument = getBuildingCenterFromHeader(page);

  const [floorplanDocuments, setFloorplanDocuments] = useState<FloorPlanDocument[]>([]);
  // eslint-disable-next-line

  const windowDimensions = useWindowDimensions();
  const compModels = props.component.getModels();
  const details = props.page.getComponent('main', 'detailcontainer');
  const { hideBreadcrumb } = componentParameterMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const { isSearchPage, cardLimit } = props.component.getParameters();
  if (isSearchPage && SiteGlobalSwitches.useElasticSearchFloorplans) {
    return <></>;
  }

  const indexDefaultFilterOpen = compModels.cards && compModels.cards.length > 0 ? '0' : '';
  const [indexesAccordionGroupOpened, setIndexesAccordionGroupOpened] = useState([
    indexDefaultFilterOpen,
  ]);

  const googleMapConfigModelVal = compModels.googleMapConfig ? compModels.googleMapConfig : null;
  const zoomLevelModelVal = compModels.zoomLevel ? compModels.zoomLevel : null;

  const latitudeModelVal = compModels.latitude ? compModels.latitude : null;
  const longitudeModelVal = compModels.longitude ? compModels.longitude : null;
  const centerModelVal = compModels.center ? compModels.center : null;
  const noDocuments = compModels.noDocuments || (compModels.cards && compModels.cards.length === 0);
  const noDocumentsModelVal = compModels.noDocuments ? compModels.noDocuments : null;
  const maxRadiusStringModelVal = compModels.maxRadiusString ? compModels.maxRadiusString : null;
  const stdFPFiltersModelVal = compModels.stdFPFilters ? compModels.stdFPFilters : null;
  const retailerName = compModels.retailerName ? compModels.retailerName : '';
  const totalFloorPlanCount =
    !isSearchPage && compModels.floorPlanCount ? compModels.floorPlanCount : 0;

  const { fpSort } = compModels;

  const sortTitlesArray = fpSort ? fpSort.sortTitles.split(',') : [];
  const sortFieldsArray = fpSort ? fpSort.sortFields.split(',') : [];
  const searchParameters = useLocation().search;

  // *********
  const apiKey =
    googleMapConfigModelVal && googleMapConfigModelVal.apiKey ? googleMapConfigModelVal.apiKey : '';
  const internalLoc =
    googleMapConfigModelVal && googleMapConfigModelVal.mapEndpoint
      ? googleMapConfigModelVal.mapEndpoint
      : '';

  let locationNameSearched = '';
  let latitudeVal = 0;
  let longitudeVal = 0;
  let zoomLevelValue = 0;
  let numBedroomsVal = '';
  let numBathroomsVal = '';
  let homeUpperBound = '';
  let homeLowerBound = '';
  let homeSizeParam = '';

  let sectionsVal = '';
  let buildingMethodVal = '';
  let distanceMaxVal = '';
  let squareFootageMultipleValues = '';
  let mediaParam = '';
  let availabilityParam = '';
  let seriesParam = '';
  let sortByVal = '';
  if (searchParameters) {
    locationNameSearched = queryString.parse(searchParameters).name
      ? queryString.parse(searchParameters).name!.toString()
      : '';
    latitudeVal = queryString.parse(searchParameters).latitude
      ? parseFloat(queryString.parse(searchParameters).latitude!.toString())
      : latitudeModelVal;
    longitudeVal = queryString.parse(searchParameters).longitude
      ? parseFloat(queryString.parse(searchParameters).longitude!.toString())
      : longitudeModelVal;
    zoomLevelValue = queryString.parse(searchParameters).zoom
      ? parseInt(queryString.parse(searchParameters).zoom!.toString(), 10)
      : zoomLevelModelVal;

    numBedroomsVal = queryString.parse(searchParameters).numBedrooms
      ? queryString
          .parse(searchParameters)
          .numBedrooms!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';
    numBathroomsVal = queryString.parse(searchParameters).numBathrooms
      ? queryString
          .parse(searchParameters)
          .numBathrooms!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';
    homeUpperBound = queryString.parse(searchParameters).homeUpperBound
      ? queryString.parse(searchParameters).homeUpperBound!.toString()
      : '';
    homeLowerBound = queryString.parse(searchParameters).homeLowerBound
      ? queryString.parse(searchParameters).homeLowerBound!.toString()
      : '';

    sectionsVal = queryString.parse(searchParameters).sections
      ? queryString.parse(searchParameters).sections!.toString()
      : '';
    buildingMethodVal = queryString.parse(searchParameters).buildingMethod
      ? queryString.parse(searchParameters).buildingMethod!.toString()
      : '';

    distanceMaxVal = queryString.parse(searchParameters).distance
      ? queryString.parse(searchParameters).distance!.toString()
      : '';

    squareFootageMultipleValues = queryString.parse(searchParameters).squareFootage
      ? queryString
          .parse(searchParameters)
          .squareFootage!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';

    mediaParam = queryString.parse(searchParameters).media
      ? queryString.parse(searchParameters).media!.toString()
      : '';

    availabilityParam = queryString.parse(searchParameters).availability
      ? queryString.parse(searchParameters).availability!.toString()
      : '';

    seriesParam = queryString.parse(searchParameters).series
      ? queryString.parse(searchParameters).series!.toString()
      : '';

    sortByVal = queryString.parse(searchParameters).sortBy
      ? queryString.parse(searchParameters).sortBy!.toString()
      : '';
  }

  if (latitudeVal === 0 && centerModelVal) {
    latitudeVal = parseFloat(centerModelVal.split(',')[0]);
  }
  if (longitudeVal === 0 && centerModelVal) {
    longitudeVal = parseFloat(centerModelVal.split(',')[1]);
  }
  if (zoomLevelValue === 0 && zoomLevelModelVal) {
    zoomLevelValue = parseInt(zoomLevelModelVal, 10);
  }
  if (homeUpperBound !== '' && homeLowerBound !== '') {
    homeSizeParam = `${homeLowerBound} - ${homeUpperBound}`;
  } else if (homeUpperBound !== '') {
    homeSizeParam = `-${homeUpperBound}`;
  } else if (homeLowerBound !== '') {
    homeSizeParam = `${homeLowerBound}+`;
  }

  const tileGridDocumentData = getTileGridDocumentsFromComponent(page);
  const { document1, document2, document3, document4, tileGridTitle } = tileGridDocumentData || {};

  const matchesCriteria = (item: any, inputMatchesCriteriaArray: any) => {
    let count = 0;

    for (let n = 0; n < inputMatchesCriteriaArray.length; n += 1) {
      if (!inputMatchesCriteriaArray[n].allowsMultipleSelection) {
        if (
          Math.round(item[inputMatchesCriteriaArray[n].field] + Number.EPSILON) <=
          Number(inputMatchesCriteriaArray[n].values[0])
        ) {
          count += 1;
        }
      } else if (inputMatchesCriteriaArray[n].field === 'media') {
        let matchCount = 0;
        const criteriaItemValues = inputMatchesCriteriaArray[n].values;
        for (let i = 0; i < criteriaItemValues.length; i += 1) {
          switch (criteriaItemValues[i]) {
            case '3D Virtual Tours':
              matchCount =
                item.tourAlbum != null || item.panatourAlbum != null
                  ? (matchCount += 1)
                  : matchCount;
              break;
            case 'Videos':
              matchCount = item.videoAlbum != null ? matchCount + 1 : matchCount;
              break;
            case 'Photos':
              matchCount = item.photoUrls != null ? matchCount + 1 : matchCount;
              break;
            case 'File Downloads':
              matchCount = item.hasDownloadableFiles ? matchCount + 1 : matchCount;
              break;
            default:
          }
        }
        if (matchCount > 0) {
          count += 1;
        }
      } else if (inputMatchesCriteriaArray[n].field === 'availability') {
        let match = false;
        const criteriaItemValues = inputMatchesCriteriaArray[n].values;
        for (let i = 0; i < criteriaItemValues.length; i += 1) {
          const value = criteriaItemValues[i];
          if (value === 'In Stock') {
            match = item.inStock;
          } else {
            match = true;
          }
        }
        if (match) {
          count += 1;
        }
      } else if (
        inputMatchesCriteriaArray[n].allowsMultipleSelection &&
        (inputMatchesCriteriaArray[n].valueType === 'float' ||
          inputMatchesCriteriaArray[n].valueType === 'int')
      ) {
        const criteriaItemValues = inputMatchesCriteriaArray[n].values;
        let countValues = 0;

        for (let j = 0; j < criteriaItemValues.length; j += 1) {
          const value = criteriaItemValues[j];

          if (!value.startsWith('-') && !value.endsWith('+') && !value.toString().includes('-')) {
            if (parseFloat(value) === item[inputMatchesCriteriaArray[n].field]) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (value.startsWith('-') && !value.endsWith('+')) {
            if (item[inputMatchesCriteriaArray[n].field] <= parseFloat(value.split('-')[1])) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (!value.startsWith('-') && value.endsWith('+')) {
            if (item[inputMatchesCriteriaArray[n].field] >= parseFloat(value.split('+')[0])) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (
            !value.startsWith('-') &&
            !value.endsWith('+') &&
            value.toString().includes('-')
          ) {
            if (
              item[inputMatchesCriteriaArray[n].field] >= parseFloat(value.split('-')[0]) &&
              item[inputMatchesCriteriaArray[n].field] <= parseFloat(value.split('-')[1])
            ) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          }
        }

        if (countValues > 0) count += 1;
      } else if (
        inputMatchesCriteriaArray[n].allowsMultipleSelection &&
        inputMatchesCriteriaArray[n].valueType === 'string'
      ) {
        const criteriaItemValues = inputMatchesCriteriaArray[n].values
          ? inputMatchesCriteriaArray[n].values.toString().toLowerCase()
          : '';
        const itemType = inputMatchesCriteriaArray[n].field;
        const itemValue = item[itemType]
          ? item[inputMatchesCriteriaArray[n].field].toString().toLowerCase()
          : '';

        if (itemValue !== '') {
          if (itemType === FLOORPLAN_FIELDS.BUILDING_METHOD) {
            if (itemValue.indexOf(criteriaItemValues) > -1) {
              count += 1;
            }
          } else if (criteriaItemValues.indexOf(itemValue) > -1) {
            count += 1;
          }
        }
      }
    }

    return count === inputMatchesCriteriaArray.length;
  };

  const filterFloorPlanDocumentsByCriteriaArray = (
    inputArray: any,
    inputFilterFPDocsCriteriaArray: any
  ) => {
    const matches: any[] = [];

    if (inputArray) {
      const validInputArrayItems = inputArray.filter((item: any) => item.id !== '');

      for (let i = 0; i < validInputArrayItems.length; i += 1) {
        if (matchesCriteria(validInputArrayItems[i], inputFilterFPDocsCriteriaArray)) {
          matches.push(validInputArrayItems[i]);
        }
      }
    }

    return matches;
  };

  const getSortedFloorPlanDocumentsBy = (inputArray: any, key: string, order = 'asc') => {
    if (inputArray.length > 0) {
      const sortedFPDocs = inputArray.slice(0);
      sortedFPDocs.sort(compareValues(key, order));

      return sortedFPDocs;
    }
  };

  const updateSortByTitleAndReturnSortedFPDocs = (
    inputArray: any,
    title: string,
    key: string,
    order = 'asc'
  ) => {
    if (title !== sortByFilterTitle) {
      setSortByFilterTitle(title);
    }
    return getSortedFloorPlanDocumentsBy(inputArray, key, order);
  };

  const filterFloorPlanDocuments = () => {
    let updFPDocs: any[] = [];
    const filterFPDocumentsCriteriaArray: any[] = [];

    yourFiltersInitial.forEach((filter: any, indexFilter: number) => {
      if (indexFilter > 0) {
        const filterValues = [
          ...new Set(
            yourFiltersInitial
              .filter(
                (iniFilter) =>
                  iniFilter.id !== '' && iniFilter.floorPlanField === filter.floorPlanField
              )
              .map((fil) => fil.value)
          ),
        ];

        if (
          !filterFPDocumentsCriteriaArray.some(
            (criteriaItem: any) => criteriaItem.field === filter.floorPlanField
          )
        ) {
          filterFPDocumentsCriteriaArray.push({
            field: filter.floorPlanField,
            values: filterValues,
            valueType: filter.valueType,
            allowsMultipleSelection: filter.allowsMultipleSelection,
          });
        } else {
          const criteria = filterFPDocumentsCriteriaArray.find(
            (criItem) => criItem.field === filter.floorPlanField
          );
          criteria.values = filterValues;
        }
      }
    });

    const allFPDocs = floorplanDocumentsOriginal;

    updFPDocs = filterFloorPlanDocumentsByCriteriaArray(allFPDocs, filterFPDocumentsCriteriaArray);

    if (sortByFilterSelected !== '') {
      const sortUpdatedFPDocs = updateSortByTitleAndReturnSortedFPDocs(
        updFPDocs,
        sortByFilterTitle,
        sortByFilterSelected,
        sortByOrderSelected
      );
      setFloorplanDocuments(sortUpdatedFPDocs);
    }
  };

  const updateIndexFilterGroupOpened = (filterGroup: string) => {
    const accordionFilterGroup = document.querySelector(`.accordion-group-toggler.${filterGroup}`);

    if (accordionFilterGroup) {
      const { id } = accordionFilterGroup;
      const indexAccordionFilterGroup = id.split('-')[id.split('-').length - 1].toString();

      if (!indexesAccordionGroupOpened.some((idx: string) => idx === indexAccordionFilterGroup)) {
        setIndexesAccordionGroupOpened((prev) => [...prev, indexAccordionFilterGroup]);
      }
    }
  };

  const addNewUrlParameter = (field: string, value: string) => {
    let urlRefresh = window.location.href;
    let fieldToUse = field;
    let valueToUse = value;

    if (fieldToUse !== 'sortBy' && valueToUse.split(' ').length > 0) {
      valueToUse = valueToUse.split(' ').join('');
    }

    if (valueToUse.startsWith('-') && !valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('-', 'lt');
    }
    if (!valueToUse.startsWith('-') && valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('+', 'gt');
    }

    if (fieldToUse === FLOORPLAN_FIELDS.BUILDING_METHOD) {
      fieldToUse = 'buildingMethod';
    }
    const currentSearchParameters = window.location.search;

    if (currentSearchParameters) {
      if (queryString.parse(currentSearchParameters)[`${fieldToUse}`]) {
        const existingUrlParamWithValue = `${fieldToUse}=${queryString
          .parse(currentSearchParameters)
          [fieldToUse]!.toString()}`;

        const updatedParamWithValues =
          fieldToUse === FLOORPLAN_FIELDS.DISTANCE || fieldToUse === 'sortBy'
            ? `${fieldToUse}=${valueToUse}`
            : `${fieldToUse}=${queryString
                .parse(currentSearchParameters)
                [fieldToUse]!.toString()},${valueToUse}`;

        const urlRefreshUpdated = decodeURIComponent(urlRefresh).replace(
          `${existingUrlParamWithValue}`,
          `${updatedParamWithValues}`
        );

        urlRefresh = urlRefreshUpdated;
      } else {
        urlRefresh += `&${fieldToUse}=${valueToUse.trim()}`;
      }
    } else {
      urlRefresh += `?${fieldToUse}=${valueToUse.trim()}`;
    }

    window.history.pushState({ path: urlRefresh }, '', urlRefresh);
  };

  const updateOrRemoveUrlParameter = (field: string, value: string) => {
    let urlRefresh = window.location.href;
    let fieldToUse = field;
    let valueToUse = value;

    if (valueToUse.split(' ').length > 0) {
      valueToUse = valueToUse.split(' ').join('');
    }

    if (valueToUse.startsWith('-') && !valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('-', 'lt');
    }
    if (!valueToUse.startsWith('-') && valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('+', 'gt');
    }

    if (fieldToUse === FLOORPLAN_FIELDS.BUILDING_METHOD) {
      fieldToUse = 'buildingMethod';
    }

    valueToUse = valueToUse.toLocaleLowerCase();

    const currentSearchParameters = window.location.search;

    if (currentSearchParameters) {
      if (queryString.parse(currentSearchParameters)[`${fieldToUse}`]) {
        const existingUrlParamWithValue = `${fieldToUse}=${queryString
          .parse(currentSearchParameters)
          [fieldToUse]!.toString()}`;

        let updatedParamWithValues = '';
        if (existingUrlParamWithValue.includes(',')) {
          const updatedValue = queryString
            .parse(currentSearchParameters)
            [fieldToUse]!.toString()
            .split(',')
            .filter((val: string) => val !== valueToUse)
            .join(',');

          updatedParamWithValues = updatedValue !== '' ? `${fieldToUse}=${updatedValue}` : '';
        }
        const urlRefreshUpdated =
          updatedParamWithValues !== ''
            ? urlRefresh.replace(`${existingUrlParamWithValue}`, `${updatedParamWithValues}`)
            : urlRefresh.replace(`&${existingUrlParamWithValue}`, `${updatedParamWithValues}`);
        const urlRefreshUpdatedRetailer =
          updatedParamWithValues !== ''
            ? urlRefresh.replace(`${existingUrlParamWithValue}`, `${updatedParamWithValues}`)
            : urlRefresh.replace(
                new RegExp(`(\\?|&)${existingUrlParamWithValue}`),
                `${updatedParamWithValues}`
              );
        urlRefresh =
          isRetailerPage || isBuildingCenterOnly ? urlRefreshUpdatedRetailer : urlRefreshUpdated;
      }
    }
    if (urlRefresh.includes('&') && !urlRefresh.includes('?')) {
      urlRefresh = urlRefresh.replace('&', '?');
    }
    window.history.pushState({ path: urlRefresh }, '', urlRefresh);
  };

  const addNewYourFilter = (
    idFilter: string,
    textFilter: string,
    valueFilter: string,
    floorPlanFieldFilter: string,
    allowsMultipleSelectionFilter: boolean,
    valueTypeFilter: string,
    addNewSearchUrl = true
  ) => {
    if (addNewSearchUrl) {
      addNewUrlParameter(floorPlanFieldFilter, valueFilter.toLowerCase());
    }
    if (floorPlanFieldFilter) {
      setYourFilters((prevArr) => [
        ...prevArr,
        {
          id: idFilter,
          text: textFilter,
          value: valueFilter,
          floorPlanField: floorPlanFieldFilter,
          allowsMultipleSelection: allowsMultipleSelectionFilter,
          valueType: valueTypeFilter,
        },
      ]);
      setYourFiltersInitial((prevArr) => [
        ...prevArr,
        {
          id: idFilter,
          text: textFilter,
          value: valueFilter,
          floorPlanField: floorPlanFieldFilter,
          allowsMultipleSelection: allowsMultipleSelectionFilter,
          valueType: valueTypeFilter,
        },
      ]);
    }
  };

  const addAFilter = (inputAddAFilterValue: string, floorPlanField: string) => {
    const getFilterFromMapByValue = (
      inputFloorPlanFieldFromMap: string,
      inputValueFromMap: string
    ) => {
      let filterFoundFromMap = mapByValue.get(inputFloorPlanFieldFromMap + inputValueFromMap);

      if (!filterFoundFromMap) {
        if (floorPlanField === FLOORPLAN_FIELDS.SQUARE_FOOTAGE) {
          const range = (start: number, end: number, length = end - start + 1) =>
            [...Array(length).keys()].map((d) => d + start);

          mapByValue.forEach((value: any, key: any) => {
            if (key.toString().startsWith(floorPlanField)) {
              const filterOption = value;
              if (filterOption) {
                if (
                  !filterOption.value.toString().startsWith('-') &&
                  !filterOption.value.toString().endsWith('+') &&
                  filterOption.value.toString().includes('-')
                ) {
                  const rangeGenerated = range(
                    Number(filterOption.value.split('-')[0]),
                    Number(filterOption.value.split('-')[1])
                  );

                  if (rangeGenerated.indexOf(Number(inputValueFromMap)) > -1) {
                    filterFoundFromMap = value;
                  }
                }
              }
            }
          });
        } else {
          filterFoundFromMap = mapByValue.get(
            `${inputFloorPlanFieldFromMap + inputValueFromMap.trim()}+`
          );
        }
      }

      return filterFoundFromMap;
    };

    const addInternalFilter = (inputValue: string) => {
      const filterFromMap = getFilterFromMapByValue(floorPlanField, inputValue);

      if (filterFromMap) {
        if (!filterFromMap.allowsMultipleSelection) {
          filterFromMap.text = `< ${filterFromMap.text}`;
        }
        updateIndexFilterGroupOpened(floorPlanField);

        setYourFilters((prevArr) => [...prevArr, filterFromMap]);

        setYourFiltersInitial((prevArr) => [...prevArr, filterFromMap]);

        addNewYourFilter(
          filterFromMap.id,
          filterFromMap.text,
          filterFromMap.value,
          filterFromMap.floorPlanField,
          filterFromMap.allowsMultipleSelection,
          filterFromMap.valueType,
          false
        );
      } else {
        updateOrRemoveUrlParameter(floorPlanField, inputValue);
      }
    };

    if (inputAddAFilterValue.includes(',')) {
      // eslint-disable-next-line
      inputAddAFilterValue.split(',').map((val: string) => {
        addInternalFilter(val);
      });
    } else {
      addInternalFilter(inputAddAFilterValue);
    }
  };

  const filterFPDocsByAdditionalFilters = (
    sortedFPDocs: any,
    numBedrooms: any,
    numBathrooms: any,
    squareFootage: any,
    sections: any,
    buildingMethod: any,
    distanceMax: string,
    squareFootageValues: string,
    mediaType: string,
    availability: string,
    series: string,
    sortBy: string
  ) => {
    let resultFPDocs: any[] = [];

    if (distanceMax && distanceMax !== '') {
      let maxDistance = distanceMax;
      if (distanceMax.includes(',')) {
        const intDistancesSortedArray = distanceMax
          .split(',')
          .map((item: any) => Number(item))
          .sort((a: any, b: any) => a - b);

        maxDistance = intDistancesSortedArray[intDistancesSortedArray.length - 1].toString();
      }
      addAFilter(maxDistance, FLOORPLAN_FIELDS.DISTANCE);
    }

    if (numBathrooms && numBathrooms !== '') {
      addAFilter(numBathrooms, FLOORPLAN_FIELDS.NUM_BATHROOMS);
    }

    if (numBedrooms && numBedrooms !== '') {
      addAFilter(numBedrooms, FLOORPLAN_FIELDS.NUM_BEDROOMS);
    }

    if (squareFootage && squareFootage.length !== '') {
      addAFilter(squareFootage, FLOORPLAN_FIELDS.SQUARE_FOOTAGE);
    }

    if (squareFootageValues && squareFootageValues !== '') {
      addAFilter(squareFootageValues, FLOORPLAN_FIELDS.SQUARE_FOOTAGE);
    }

    if (sections && sections !== '') {
      addAFilter(sections, FLOORPLAN_FIELDS.SECTIONS);
    }

    if (buildingMethod && buildingMethod !== '') {
      addAFilter(buildingMethod, FLOORPLAN_FIELDS.BUILDING_METHOD);
    }

    if (mediaType && mediaType !== '') {
      addAFilter(mediaType, FLOORPLAN_FIELDS.MEDIA);
    }

    if (availability && availability !== '') {
      addAFilter(availability, FLOORPLAN_FIELDS.AVAILABILITY);
    }

    if (series && series !== '') {
      addAFilter(series, FLOORPLAN_FIELDS.SERIES);
    }

    resultFPDocs = sortedFPDocs;

    if (
      sortBy !== '' &&
      sortFieldsArray.some(
        (item: string) =>
          item.split(' ')[0].toLowerCase() === sortBy.split(' ')[0].toLowerCase() &&
          item.split(' ')[1].toLowerCase() === sortBy.split(' ')[1].toLowerCase()
      )
    ) {
      const sortByFieldIndex = sortFieldsArray.findIndex(
        (item: string) =>
          item.split(' ')[0].toLowerCase() === sortBy.split(' ')[0].toLowerCase() &&
          item.split(' ')[1].toLowerCase() === sortBy.split(' ')[1].toLowerCase()
      );

      const existingSortByTitle = sortTitlesArray[sortByFieldIndex];
      const titleDisplayed = `${fpSort.sortBy} ${existingSortByTitle}`;

      setSortByFilterTitle(titleDisplayed);
      setSortByFilterSelected(sortFieldsArray[sortByFieldIndex].split(' ')[0]);
      setSortByOrderSelected(sortFieldsArray[sortByFieldIndex].split(' ')[1]);

      const sortByUrlParamAndFilterFPDocs = updateSortByTitleAndReturnSortedFPDocs(
        resultFPDocs,
        titleDisplayed,
        sortFieldsArray[sortByFieldIndex].split(' ')[0],
        sortFieldsArray[sortByFieldIndex].split(' ')[1]
      );

      return sortByUrlParamAndFilterFPDocs;
    }
    if (sortByFilterSelected !== '' && isSearchPage) {
      const sortAndFilterUpdatedFPDocs = updateSortByTitleAndReturnSortedFPDocs(
        resultFPDocs,
        sortByFilterTitle,
        sortByFilterSelected,
        sortByOrderSelected
      );
      return sortAndFilterUpdatedFPDocs;
    }
    return resultFPDocs;
  };

  const defaultSortingLogic = (inputFlooplans: any) => {
    // the default should be square footage, so you should see the highest square footage first on that page
    // the next order is numBathrooms (only if the same sqtf occurs)
    // and then by model name (only if sqft and numBathrooms are the same)

    const results = inputFlooplans.sort((a: any, b: any) => {
      if (a.squareFootage === b.squareFootage) {
        if (a.numBathrooms === b.numBathrooms) {
          return a.modelName > b.modelName ? 1 : -1;
        }
        return b.numBedrooms - a.numBedrooms;
      }
      return a.squareFootage > b.squareFootage ? -1 : 1;
    });

    return results;
  };

  const setInitialSorting = (inputArray: any) => {
    setSortByFilterSelected('squareFootage');
    setSortByOrderSelected('desc');

    return defaultSortingLogic(inputArray);
  };

  const displayBottomLine = () => {
    return <hr className="cvc-product-list-your-filters-horizontal-bottom-line" />;
  };

  const displayYourFilters = () => {
    const filters = yourFilters;

    const handleFilterCloseIconClick = (id: string) => {
      if (yourFilters.some((filter) => filter.id === id)) {
        const filterToBeRemoved = yourFilters.find((filter) => filter.id === id);
        const updFilters = yourFilters.filter((filter) => filter.id !== id);

        setYourFilters(updFilters);
        setYourFiltersInitial(updFilters);

        updateOrRemoveUrlParameter(filterToBeRemoved!.floorPlanField, filterToBeRemoved!.value);

        if (updFilters.length === 1 && updFilters[0].id === '') {
          setFloorplanDocuments(floorplanDocumentsOriginal);
          setInitialSorting(floorplanDocumentsOriginal);
        }
      }
    };

    const handleClearAllClick = () => {
      if (isRetailerPage || isBuildingCenterOnly) {
        window.history.pushState({}, '', 'floorplans');

        setYourFilters([
          {
            id: '',
            text: '',
            value: '',
            floorPlanField: '',
            allowsMultipleSelection: true,
            valueType: '',
          },
        ]);
        setYourFiltersInitial([
          {
            id: '',
            text: '',
            value: '',
            floorPlanField: '',
            allowsMultipleSelection: true,
            valueType: '',
          },
        ]);

        setFloorplanDocuments(floorplanDocumentsOriginal);
      } else {
        const getFullUrlOnlyWithLatitudeAndLongitudeAndName = () => {
          const currentSearchParams =
            window.location.search && window.location.search.startsWith('?')
              ? window.location.search.substring(1, window.location.search.length)
              : window.location.search;

          const reducer = (mapResult: any, val: any, index: number) => {
            if (
              val.split('=')[0] === 'latitude' ||
              val.split('=')[0] === 'longitude' ||
              val.split('=')[0] === 'name'
            ) {
              mapResult[index] = val;
            }
            return mapResult;
          };
          const searchParamsWithoutAdditionalParams = currentSearchParams
            .split('&')
            .map((param: any) => param)
            .reduce(reducer, []);

          if (searchParamsWithoutAdditionalParams) {
            return (
              searchParamsWithoutAdditionalParams &&
              `?${searchParamsWithoutAdditionalParams.join('&')}`
            );
          }
        };

        const urlWithoutAdditionalParams = getFullUrlOnlyWithLatitudeAndLongitudeAndName();
        if (urlWithoutAdditionalParams) {
          window.history.pushState(
            { path: urlWithoutAdditionalParams },
            '',
            urlWithoutAdditionalParams
          );
        }

        setYourFilters([
          {
            id: '',
            text: '',
            value: '',
            floorPlanField: '',
            allowsMultipleSelection: true,
            valueType: '',
          },
        ]);
        setYourFiltersInitial([
          {
            id: '',
            text: '',
            value: '',
            floorPlanField: '',
            allowsMultipleSelection: true,
            valueType: '',
          },
        ]);

        setFloorplanDocuments(floorplanDocumentsOriginal);
        setInitialSorting(floorplanDocumentsOriginal);
      }
    };

    return filters && filters.length > 1 && isSearchPage ? (
      <>
        <div className="cvc-floorplan-list-your-filters-container cvc-product-list-your-filters">
          <div className="cvc-product-list-your-filters-title">
            Your Filters
            <RightArrowIcon className="cvc-product-list-your-filters-title-icon" />
          </div>
          <div className="cvc-product-list-your-filters-button-container">
            {
              // eslint-disable-next-line
              filters.map((filter: any, indexFil: number) => {
                if (filter.id) {
                  const buttonsDuplicated = document.querySelectorAll(
                    `.cvc-product-list-filter-button.${filter.id}`
                  );
                  if (buttonsDuplicated.length > 1) {
                    (
                      buttonsDuplicated.item(buttonsDuplicated.length - 1) as HTMLButtonElement
                    ).style.display = 'none';
                  }
                }

                if (filter.text.length > 0) {
                  return (
                    <button
                      key={`${filter.text}-${indexFil.toString()}`}
                      id={`btn-${filter.id}`}
                      type="button"
                      className={`cvc-product-list-filter-button ${filter.id}`}
                      tabIndex={-1}
                    >
                      <CloseIcon
                        className="filter-button-close-icon"
                        onClick={() => handleFilterCloseIconClick(filter.id)}
                      />
                      {filter.text}
                    </button>
                  );
                }
              })
            }
            {filters.length > 1 ? (
              <button
                key="clear-all-button"
                type="button"
                className="cvc-product-list-filter-button clear-all"
                onClick={handleClearAllClick}
              >
                <CloseIcon className="filter-button-close-icon" />
                Clear All
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {displayBottomLine()}
      </>
    ) : (
      ''
    );
  };

  const displayRetailerName = () => {
    return <div className="cvc-retailer-name">{retailerName}</div>;
  };

  const displayListGroupSortByOptions = () => {
    const handleSortByItemSelected = (title: string, key: string, order = 'asc') => {
      const sortedFPDocs = updateSortByTitleAndReturnSortedFPDocs(
        floorplanDocuments,
        title,
        key,
        order
      );

      setSortByFilterSelected(key);
      setSortByOrderSelected(order);
      setFloorplanDocuments(sortedFPDocs);

      setShowListGroupSortByOptions(false);

      if (sortOptionsMobileOpen) {
        setShowListGroupSortByOptions(false);
        setSortOptionsMobileOpen(false);
      }

      addNewUrlParameter('sortBy', `${key} ${order}`);
    };

    const handleListGroupItemHover = (isHover: boolean) => {
      setShowListGroupSortByOptions(isHover);
    };

    const isNotDesktopScreen = !!(windowDimensions.isMobile || windowDimensions.isTablet);

    return (
      <ListGroup
        className={classNames('accordion-sort-by-list', isNotDesktopScreen ? 'is-mobile' : '')}
        onMouseOver={() => handleListGroupItemHover(true)}
        onMouseLeave={() => handleListGroupItemHover(false)}
        onFocus={() => handleListGroupItemHover(true)}
        onBlur={() => handleListGroupItemHover(false)}
      >
        {sortFieldsArray.map((a: any, index: number) => {
          const itemSortByTitleDisplayed = `${fpSort.sortBy} ${sortTitlesArray[index]}`;
          const itemSortByTitle = sortTitlesArray[index];
          const itemSortByField = sortFieldsArray[index].split(' ')[0];
          const itemSortByOrder = sortFieldsArray[index].split(' ')[1];

          return (
            <ListGroup.Item
              key={`${itemSortByField}-${itemSortByOrder}`}
              action
              onClick={() =>
                handleSortByItemSelected(itemSortByTitleDisplayed, itemSortByField, itemSortByOrder)
              }
              active={
                isNotDesktopScreen &&
                sortByFilterSelected === itemSortByField &&
                sortByOrderSelected === itemSortByOrder
              }
              disabled={noDocuments}
            >
              {itemSortByTitle}
              {(windowDimensions.isMobile || windowDimensions.isTablet) &&
              sortByFilterSelected === itemSortByField &&
              sortByOrderSelected === itemSortByOrder ? (
                <DoneIcon className="sort-by-mobile-done-icon" />
              ) : (
                ''
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const displaySortByDesktopOptions = () => {
    const handleAccordionSortByContainerHover = (isHover: boolean) => {
      setShowListGroupSortByOptions(isHover);
    };

    return (
      <div
        className="accordion-filter-sort-by-container"
        onMouseOver={() => handleAccordionSortByContainerHover(true)}
        onMouseLeave={() => handleAccordionSortByContainerHover(false)}
        onFocus={() => handleAccordionSortByContainerHover(true)}
        onBlur={() => handleAccordionSortByContainerHover(false)}
      >
        <div
          className={classNames(
            'accordion-filter-sort-by-title',
            !windowDimensions.isMobile && !windowDimensions.isTablet ? `is-desktop` : ''
          )}
        >
          <span className={showListGroupSortByOptions ? 'lighter-text-color' : ''}>
            {sortByFilterTitle || 'Sort By'}
          </span>
          <CaretDownIcon
            className={classNames(
              'accordion-filter-sort-by-icon',
              showListGroupSortByOptions ? 'rotate-icon' : ''
            )}
          />
        </div>
        {showListGroupSortByOptions ? displayListGroupSortByOptions() : ''}
      </div>
    );
  };

  const displayMobileLinks = () => {
    const handleMobileLinkSelected = (linkSelected: string) => {
      setDisplayInMobile(linkSelected);

      if (linkSelected === 'cards') {
        setAccordionFiltersMobileOpen(false);

        if (!sortOptionsMobileOpen) {
          setSortOptionsMobileOpen(true);
          setShowListGroupSortByOptions(true);
        } else {
          setSortOptionsMobileOpen(false);
          setShowListGroupSortByOptions(false);
        }
      } else if (linkSelected === 'filters') {
        setSortOptionsMobileOpen(false);
        setShowListGroupSortByOptions(false);

        if (!accordionFiltersMobileOpen) {
          setAccordionFiltersMobileOpen(true);
        } else {
          setAccordionFiltersMobileOpen(false);
        }
      }
    };

    return (
      <div className="floorplan-mobile-links product-list-mobile-links">
        <Container>
          <Button
            variant="link"
            className={`${displayInMobile === 'cards' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('cards')}
          >
            Sort
            <CaretDownIcon
              className={classNames(
                'product-mobile-link-caret-down-icon',
                showListGroupSortByOptions ? 'rotate-icon' : ''
              )}
            />
          </Button>
          <span className="product-mobile-separator">{' | '}</span>
          <Button
            variant="link"
            className={`${displayInMobile === 'filters' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('filters')}
          >
            <FiltersIcon className="product-mobile-filters-icon" />
            Filter Results
          </Button>
        </Container>
        {showListGroupSortByOptions ? displayListGroupSortByOptions() : ''}
      </div>
    );
  };

  const numLocationsAvailable =
    floorplanDocuments && floorplanDocuments.length > 0 ? floorplanDocuments.length : 0;

  const CavcoFormCheck: FunctionComponent<any> = (formCheckProps) => {
    const {
      id,
      isDisabled,
      inputElementType,
      floorPlanField,
      label,
      labelFull,
      value,
      name,
      allowsMultipleSelection,
      valueType,
    } = formCheckProps;
    const handleFormCheckClick = (
      text: string,
      valueFormCheck: string,
      floorPlanFieldFormCheck: string,
      idFormCheck: string,
      allowsMultipleSelectionFormCheck: boolean,
      valueTypeFormCheck: string
    ) => {
      if (
        !allowsMultipleSelectionFormCheck &&
        yourFilters.some((filter) => filter.floorPlanField === floorPlanFieldFormCheck)
      ) {
        const indexFilters = yourFilters.filter(
          (filter) => filter.floorPlanField !== floorPlanFieldFormCheck
        );
        setYourFilters(indexFilters);
        setYourFiltersInitial(indexFilters);

        addNewYourFilter(
          idFormCheck,
          `< ${text}`,
          valueFormCheck,
          floorPlanFieldFormCheck,
          allowsMultipleSelectionFormCheck,
          valueTypeFormCheck
        );
      }
      if (
        allowsMultipleSelectionFormCheck &&
        yourFilters.some((filter) => filter.id === idFormCheck)
      ) {
        const filterToBeRemoved = yourFilters.find((filter) => filter.id === idFormCheck);

        const updFilters = yourFilters.filter((filter) => filter.id !== idFormCheck);
        setYourFilters(updFilters);
        setYourFiltersInitial(updFilters);

        updateOrRemoveUrlParameter(filterToBeRemoved!.floorPlanField, filterToBeRemoved!.value);

        if (updFilters.length === 1 && updFilters[0].id === '') {
          setFloorplanDocuments(floorplanDocumentsOriginal);
          setInitialSorting(floorplanDocumentsOriginal);
        }
      } else {
        addNewYourFilter(
          idFormCheck,
          !allowsMultipleSelectionFormCheck ? `< ${text}` : text,
          valueFormCheck,
          floorPlanFieldFormCheck,
          allowsMultipleSelectionFormCheck,
          valueTypeFormCheck
        );
      }
    };

    return (
      <Form.Check
        key={id}
        id={id}
        disabled={isDisabled}
        type={inputElementType}
        name={name}
        label={labelFull}
        value={value}
        onChange={() =>
          handleFormCheckClick(label, value, floorPlanField, id, allowsMultipleSelection, valueType)
        }
      />
    );
  };

  const getNumberFPDocsByFilterNameAndValue = (
    inputNumberFPDocsFilter: any,
    value: any,
    usefloorplanDocumentsOriginal = false
  ) => {
    let results = 0;

    const allFPDocs = !usefloorplanDocumentsOriginal
      ? floorplanDocuments
      : floorplanDocumentsOriginal;
    const fpDocs = filterFloorPlanDocumentsByCriteriaArray(allFPDocs, [
      {
        field: inputNumberFPDocsFilter.floorPlanField,
        values: [value],
        valueType: inputNumberFPDocsFilter.valueType,
        allowsMultipleSelection: inputNumberFPDocsFilter.allowsMultipleSelection,
      },
    ]);

    if (fpDocs !== undefined) {
      results = fpDocs.length > 0 ? fpDocs.length : 0;
    }

    return results;
  };

  const AccordionFilter: FunctionComponent<any> = (accordionFilterProps) => {
    const { filter, indexFilter } = accordionFilterProps;
    const [isAccordionItemOpen, setIsAccordionItemOpen] = useState(false);

    const contentId = `${filter.floorPlanField}-${indexFilter.toString()}-content`;

    const handleAccordionGroupToglerClick = (index: string) => {
      setIsAccordionItemOpen(!isAccordionItemOpen);
      if (indexesAccordionGroupOpened.some((idx: string) => idx === index)) {
        const indexAccordionGroup = indexesAccordionGroupOpened.findIndex(
          (idx: string) => idx !== index
        );
        const newIndexAccordionGroup = indexesAccordionGroupOpened.slice(0, indexAccordionGroup);
        setIndexesAccordionGroupOpened(newIndexAccordionGroup);
      } else {
        setIndexesAccordionGroupOpened((prev) => [...prev, index]);
      }
    };
    const handlekeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        setIsAccordionItemOpen(!isAccordionItemOpen);
      }
    };

    const useFloorPlanDocumentsOriginal = yourFilters.some(
      (yourFilter: any) => yourFilter.floorPlanField === filter.floorPlanField
    );

    return (
      <div className="accordion-filter-container">
        <div
          id={`accordion-group-header-${filter.floorPlanField}-${indexFilter.toString()}`}
          className={`accordion-group-toggler ${filter.floorPlanField}`}
          role="button"
          onClick={() => handleAccordionGroupToglerClick(indexFilter.toString())}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handlekeyDown(e)}
          aria-controls={contentId}
          aria-expanded={indexesAccordionGroupOpened.some(
            (index: any) => index === indexFilter.toString()
          )}
          tabIndex={0}
        >
          {filter.title}
          {indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString()) ? (
            <RemoveIcon className="card-header-icon" />
          ) : (
            <AddIcon className="card-header-icon" />
          )}
        </div>
        <Collapse
          in={indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())}
        >
          <div
            id={contentId}
            className={classNames(
              'card-body-container',
              indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())
                ? 'is-open'
                : ''
            )}
          >
            {filter.values.split(',').map((value: any, indexValue: number) => {
              const inputElementType = filter.allowsMultipleSelection ? 'checkbox' : 'radio';
              const numberFPDocsByFilterNameAndValue = getNumberFPDocsByFilterNameAndValue(
                filter,
                value,
                useFloorPlanDocumentsOriginal
              );
              const label = `${value} ${filter.unit}`;
              const labelFull = `${label} (${numberFPDocsByFilterNameAndValue})`;
              const id = `formCheck-${filter.floorPlanField}-${indexValue.toString()}`;
              const name = !filter.allowsMultipleSelection
                ? `filter-${filter.floorPlanField}-radios`
                : '';

              mapByValue.set(
                filter.floorPlanField + value.toString().split(' ').join('').toLowerCase(),
                {
                  id,
                  text: label,
                  value,
                  floorPlanField: filter.floorPlanField,
                  allowsMultipleSelection: filter.allowsMultipleSelection,
                  valueType: filter.valueType,
                }
              );

              if (
                distanceMaxVal === '' &&
                !filter.allowsMultipleSelection &&
                numberFPDocsByFilterNameAndValue > 0 &&
                idDistanceFilterOptionValid === ''
              ) {
                idDistanceFilterOptionValid = id;
                // eslint-disable-next-line
                setIdSmallestDistanceFilterOptionValid(id);

                smallDistanceFilterValid.id = id;
                smallDistanceFilterValid.text = `< ${label}`;
                smallDistanceFilterValid.value = value;
                smallDistanceFilterValid.floorPlanField = filter.floorPlanField;
                smallDistanceFilterValid.allowsMultipleSelection = filter.allowsMultipleSelection;
                smallDistanceFilterValid.valueType = filter.valueType;
              }

              return (
                <CavcoFormCheck
                  key={`card-body-${id}`}
                  isDisabled={numberFPDocsByFilterNameAndValue === 0}
                  id={id}
                  name={name}
                  inputElementType={inputElementType}
                  floorPlanField={filter.floorPlanField}
                  label={label}
                  labelFull={labelFull}
                  value={value}
                  allowsMultipleSelection={filter.allowsMultipleSelection}
                  valueType={filter.valueType}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    );
  };

  const displayDynamicFilters = (isDisplayInMobile = false) => {
    const country =
      window.location.pathname.includes('us') || window.location.pathname.includes('ca')
        ? window.location.pathname
            .split('/')
            [window.location.pathname.split('/').length - 1].toUpperCase()
        : '';
    const filters: any[] = [];

    if (stdFPFiltersModelVal && stdFPFiltersModelVal.filter_names) {
      const filterResourceBundles = stdFPFiltersModelVal.filter_names;

      filterResourceBundles.split(',').forEach((filterResourceBundle: string) => {
        const filterDetails = compModels[filterResourceBundle]
          ? compModels[filterResourceBundle]
          : null;

        if (filterDetails) {
          const floorPlanFieldVal = filterDetails.floorPlanField;

          let unitVal = filterDetails.unit || '';
          if (filterDetails[`unit${country}`]) {
            unitVal = filterDetails[`unit${country}`];
          }

          const sortedValuesAsc = filterDetails.values
            ? filterDetails.values
                .split(',')
                .sort((a: any, b: any) => {
                  if (filterDetails.valueType === 'string') {
                    return a < b ? -1 : 1;
                  }
                  return a - b;
                })
                .join(',')
            : '';
          const valueTypeVal = filterDetails.valueType;

          filters.push({
            floorPlanField: floorPlanFieldVal,
            title: filterDetails.title,
            unit: unitVal,
            values: sortedValuesAsc,
            allowsMultipleSelection: filterDetails.allowsMultipleSelection === 'true',
            valueType: valueTypeVal,
          });
        }
      });
    }

    const handleDarkBackgroundKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        setAccordionFiltersMobileOpen(!accordionFiltersMobileOpen);
      }
    };

    return isDisplayInMobile ? (
      <div className="product-list-accordion-mobile-wrapper">
        <div
          className={classNames(
            `product-list-accordion ${accordionFiltersMobileOpen ? 'show-in-mobile' : ''}`
          )}
        >
          {filters.map((filter: any, indexFilter: number) => (
            <AccordionFilter
              key={`accordion-filter-${indexFilter.toString()}`}
              filter={filter}
              indexFilter={indexFilter}
              windowDimensions={windowDimensions}
            />
          ))}
        </div>
        <div
          aria-label="product-list-accordion-mobile-link"
          role="button"
          className={classNames(
            `product-list-accordion-mobile-link ${
              accordionFiltersMobileOpen ? 'show-in-mobile' : ''
            }`
          )}
          tabIndex={0}
          onClick={() => setAccordionFiltersMobileOpen(!accordionFiltersMobileOpen)}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleDarkBackgroundKeyDown(e)}
        />
      </div>
    ) : (
      <div className="product-list-accordion">
        {displaySortByDesktopOptions()}
        {filters.map((filter: any, indexFilter: number) => (
          <AccordionFilter
            key={`accordion-filter-${indexFilter.toString()}`}
            filter={filter}
            indexFilter={indexFilter}
            windowDimensions={windowDimensions}
          />
        ))}
      </div>
    );
  };

  const displayBreadcrumb = (
    breadcrumbInput: any,
    displayScrollToTop: boolean,
    isSearch: boolean
  ) => {
    return breadcrumbInput && isSearch ? (
      <div className="cvc-floorplan-list-breadcrumb cvc-product-list-breadcrumb d-sm-block">
        {breadcrumb && (
          <CustomBreadcrumbStack
            props={{ page: props.page, component: breadcrumb }}
            customProps={{ displayScrollToTop }}
          />
        )}
      </div>
    ) : (
      ''
    );
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    setIndexesAccordionGroupOpened([indexDefaultFilterOpen]);

    setYourFilters([
      {
        id: '',
        text: '',
        value: '',
        floorPlanField: '',
        allowsMultipleSelection: true,
        valueType: '',
      },
    ]);
    setYourFiltersInitial([
      {
        id: '',
        text: '',
        value: '',
        floorPlanField: '',
        allowsMultipleSelection: true,
        valueType: '',
      },
    ]);

    if (compModels) {
      const { cards = [] } = compModels;
      const sortedFPDocs =
        sortFieldsArray.length > 0 && sortByVal === '' ? setInitialSorting(cards) : cards;

      const sortedWithFiltersFPDocs = filterFPDocsByAdditionalFilters(
        sortedFPDocs,
        numBedroomsVal,
        numBathroomsVal,
        homeSizeParam,
        sectionsVal,
        buildingMethodVal,
        distanceMaxVal,
        squareFootageMultipleValues,
        mediaParam,
        availabilityParam,
        seriesParam,
        sortByVal
      );

      setFloorplanDocuments(sortedWithFiltersFPDocs);
      setFloorplanDocumentsOriginal(sortedWithFiltersFPDocs);
    }

    const generalBreadcrumb = document.body.querySelector(
      '.cvc-breadcrumb-container:not(.is-custom)'
    );
    if (generalBreadcrumb) {
      (generalBreadcrumb as HTMLDivElement).style.display = 'none';
    }
    // eslint-disable-next-line
  }, [compModels]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    if (distanceMaxVal === '' && idSmallestDistanceFilterOptionValid !== '') {
      addNewYourFilter(
        smallDistanceFilterValid.id,
        smallDistanceFilterValid.text,
        smallDistanceFilterValid.value,
        smallDistanceFilterValid.floorPlanField,
        smallDistanceFilterValid.allowsMultipleSelection,
        smallDistanceFilterValid.valueType
      );
    }
    // eslint-disable-next-line
  }, [idSmallestDistanceFilterOptionValid]);

  useEffect(() => {
    if (yourFilters.length > 1) {
      // eslint-disable-next-line
      yourFilters.map((filter: any) => {
        const inputFilter = document.getElementById(filter.id);
        if (inputFilter) {
          (inputFilter as HTMLInputElement).checked = true;
          inputFilter.classList.toggle('is-selected', true);
        }
      });
    }
  });

  useEffect(() => {
    if (yourFiltersInitial.length > 1) {
      filterFloorPlanDocuments();
    }
    // eslint-disable-next-line
  }, [yourFiltersInitial]);

  const floorPlansSortContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'cards'),
    'floorplans-sort-container': true,
    'cvc-product-list-sort': true,
  });

  const floorPlansFiltersContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'filters'),
    'floorplans-filters-container': true,
    'cvc-product-list-main': true,
  });

  const noFloorplansDocument = props.page.getContent(noDocumentsModelVal);
  const noFloorplansTitle = noFloorplansDocument?.getData()?.title;
  const noFloorplansContent = noFloorplansDocument?.getData()?.content?.value;
  const displayBreadcrumbScrollToTop = false;
  const spinnerColorInLightBackground =
    compModels &&
    compModels.spinnerConfig &&
    compModels.spinnerConfig.spinner_color_light_background;
  const searchButtonText =
    siteSearchLabels && siteSearchLabels.searchButtonText
      ? siteSearchLabels.searchButtonText
      : 'Search Again';
  let searchedNameDisplay = locationNameSearched;
  if (searchedNameDisplay === '' && isBuildingCenterOnly) {
    searchedNameDisplay = siteSearchLabels.companyName
      ? `${siteSearchLabels.companyName} ${buildingCenterName}`
      : buildingCenterName;
  }
  const floorPlanListClasses = classNames({
    'cvc-floorplan-list': true,
    'cvc-product-list': true,
    'cvc-location-section': !isSearchPage,
    'cvc-bc-floorplans': !isSearchPage && !isRetailerPage,
  });

  const footerButtonClasses = classNames({
    'cvc-location-section-foot': true,
    hidden: !isSearchPage && floorplanDocuments.length < cardLimit,
  });
  if (!isSearchPage && totalFloorPlanCount === 0) {
    return <></>;
  }

  // function getBuildingCenterFromHeader(page: any): any | null {
  //   const bcContainer = page.getComponent('header-content', 'buildingcenter-data');
  //   const bcDocuments = bcContainer && bcContainer.getModels() && bcContainer.getModels().documents;
  //   return bcDocuments && page.getContent(bcDocuments[0])?.getData();
  // }
  // const bcDocument = getBuildingCenterFromHeader(page);

  const displayTileGrids = () => {
    if (!isParkModel) {
      return (
        <>
          {document1 && (
            <NoResultsTileGrid
              props={props}
              documentRef={document1}
              gridTitleText={tileGridTitle}
            />
          )}
          {document2 && (
            <NoResultsTileGrid props={props} documentRef={document2} gridTitleText={null} />
          )}
        </>
      );
    }
    return (
      <>
        {document3 && (
          <NoResultsTileGrid props={props} documentRef={document3} gridTitleText={tileGridTitle} />
        )}
        {document4 && (
          <NoResultsTileGrid props={props} documentRef={document4} gridTitleText={null} />
        )}
      </>
    );
  };

  const displayButton = (searchPage: boolean, retailerPage: boolean, isCommunityPage: boolean) => {
    if (!searchPage && !isCommunityPage) {
      if (retailerPage) {
        return (
          <div className={footerButtonClasses}>
            <ResolverLink className="cvc-product-view-all-button" href="./floorplans">
              <Button>View All Floor Plans</Button>
            </ResolverLink>
          </div>
        );
      }
      if (bcDocument) {
        return (
          <ResolverLink
            className="cvc-product-view-all-button"
            href={`/building-center${bcDocument.buildingCenterPath}/floorplans`}
          >
            <Button>View All {totalFloorPlanCount} Available Floor Plans</Button>
          </ResolverLink>
        );
      }
    }
  };

  const displayCommunityTitles = (isCommunityPage: boolean, title: string, subTitle: string) => {
    return (
      isCommunityPage && (
        <div className="cvc-floorplan-bc-title">
          <h2 className="cvc-heading-featured">
            <span className="cvc-heading-featured-inner">{title}</span>
          </h2>
          <h3>
            <span>{subTitle}</span>
          </h3>
        </div>
      )
    );
  };

  return (
    <div className={floorPlanListClasses}>
      {displayCommunityTitles(isCommunity, titleText, subTitleText)}

      {!isSearchPage && !isCommunity && (
        <>
          {isRetailerPage && (
            <div className="cvc-retailer-floorplan-head cvc-location-section-head">
              <h2 className="cvc-location-section-head-title">
                Showing 1-{floorplanDocuments.length} of {totalFloorPlanCount} Floor Plans
              </h2>
            </div>
          )}
          {!isRetailerPage && (
            <h2 className="cvc-heading-featured">
              <span className="cvc-heading-featured-inner">Floor Plans For Order</span>
            </h2>
          )}
        </>
      )}
      {(windowDimensions.isMobile || windowDimensions.isTablet) && (
        <>
          {breadcrumb && displayBreadcrumb(breadcrumb, displayBreadcrumbScrollToTop, isSearchPage)}
          <FloorPlanSearch
            {...{
              locationNameSearched,
              numLocationsAvailable,
              isRetailerPage,
              apiKey,
              internalLoc,
              spinnerColorInLightBackground,
              searchButtonText,
              isBuildingCenterOnly,
              searchedNameDisplay,
              isSearchPage,
              bcDocument,
            }}
          />
        </>
      )}
      <div className="cvc-floorplan-list-locations-container cvc-product-list-container">
        {(windowDimensions.isMobile || windowDimensions.isTablet) &&
          isSearchPage &&
          displayMobileLinks()}
        <>
          {(isSearchPage || windowDimensions.isMobile || windowDimensions.isTablet) && (
            <div className={floorPlansSortContainerClasses}>
              {windowDimensions.isMobile || windowDimensions.isTablet ? (
                <>
                  {isRetailerPage && isSearchPage && displayRetailerName()}
                  {isSearchPage && displayYourFilters()}
                  {noDocuments ? (
                    <>
                      <NoFloorPlansMessage
                        {...{
                          title: noFloorplansTitle,
                          contentVal: noFloorplansContent,
                          maxRadiusString: maxRadiusStringModelVal,
                        }}
                      />
                      {displayTileGrids()}
                    </>
                  ) : (
                    displayFloorPlanCards(props, floorplanDocuments, windowDimensions, isSearchPage)
                  )}
                  {isSearchPage && displayDynamicFilters(true)}
                </>
              ) : (
                <>{isSearchPage && displayDynamicFilters()}</>
              )}
            </div>
          )}
          <div className={floorPlansFiltersContainerClasses}>
            {!windowDimensions.isMobile && !windowDimensions.isTablet && (
              <>
                <>
                  {breadcrumb &&
                    isSearchPage &&
                    displayBreadcrumb(breadcrumb, displayBreadcrumbScrollToTop, isSearchPage)}
                  <FloorPlanSearch
                    {...{
                      locationNameSearched,
                      numLocationsAvailable,
                      isRetailerPage,
                      apiKey,
                      internalLoc,
                      spinnerColorInLightBackground,
                      searchButtonText,
                      isBuildingCenterOnly,
                      searchedNameDisplay,
                      isSearchPage,
                      bcDocument,
                    }}
                  />
                </>
                {isRetailerPage && isSearchPage && displayRetailerName()}
                {isSearchPage && displayYourFilters()}
                {noDocuments && (
                  <NoFloorPlansMessage
                    {...{
                      title: noFloorplansTitle,
                      contentVal: noFloorplansContent,
                      maxRadiusString: maxRadiusStringModelVal,
                    }}
                  />
                )}
                {!noDocuments &&
                  displayFloorPlanCards(props, floorplanDocuments, windowDimensions, isSearchPage)}
                {noDocuments && displayTileGrids()}
              </>
            )}
          </div>
        </>
      </div>
      {/* {!isSearchPage && (
        <div className={footerButtonClasses}>
            <ResolverLink className="cvc-product-view-all-button" href="./floorplans">
            <Button>View All Floor Plans</Button>
          </ResolverLink>
        </div>
      )} */}
      {displayButton(isSearchPage, isRetailerPage, isCommunity)}
      <GlobalScrollToTopButton />
    </div>
  );
}
