import React, { FunctionComponent } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import PanelAds from './PanelAds';

const withBrProps = (Component: FunctionComponent<any>) => (brProps: BrProps) => {
  const { documents, componentParameterMap, groupTitle } = brProps.component.getModels();
  const variant = componentParameterMap?.variant;
  if (documents.length !== 3 || !variant) {
    return null;
  }

  const props = {
    groupTitle,
    variant,
    panels: documents.map((doc: any) => {
      const document = brProps.page.getContent(doc);

      if (!document) {
        return null;
      }

      const {
        name,
        displayName,
        content1: { value: content },
        link,
        image: imageRef,
        title,
      } = document.getData() as unknown as Promo;
      const image: any = imageRef && brProps.page.getContent(imageRef.imagelink);
      // From << Br Props
      // To >> Panel Ad Props

      if (link && link.documentPicker) {
        const linkDocument = brProps.page.getContent(link.documentPicker)?.getData();
        link.documentLink = linkDocument?._links?.site?.href;
      }

      return {
        documentRef: document,
        name,
        displayName,
        content,
        link,
        image: {
          ...imageRef,
          imageRef: image?.model?.data,
        },
        title,
      };
    }),
  };

  return (
    <Component {...props} isPreview={brProps.page.isPreview()}>
      {/* <BrManageContentButton content={document} /> */}
    </Component>
  );
};

export default withBrProps(PanelAds);
