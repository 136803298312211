/*
 *
 * This component creates the Metadata BR component.
 * This is able to be added to any editorial page created.
 *
 *
 * ******** Will update metadata, but will get overwritten if a specific page's metadata is set elsewhere.
 * ex: The metadata is pulling from here for the search page, but from
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { getRetailerData, buildCmsOgImage } from 'components/utils';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';

function buildMetaImage(props: BrProps, photos: any) {
  let ogImage = '';
  const photosDocument = photos && photos[0] && props.page.getContent(photos[0]);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, photos[0]);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function pagePropertiesForRtlData(props: BrProps, data: RetailerData, links: any) {
  const { metadata: cmsMetadata } = data;
  const { componentParameterMap } = props.component.getModels();
  const { companyFull, inventoryType, description, keywords } = componentParameterMap;

  //  const titleLabel = inventoryType === 'DisplayHome' ? 'Modular Homes in' : 'Manufactured Homes in';
  //  const title2Label =
  //  inventoryType === 'DisplayHome' ? 'Display Homes for Sale' : 'Move-In Ready Homes for Sale';
  //  const pageTitle = `${titleLabel} ${data.city}, ${data.state} | ${title2Label} | ${data.dba}`;
  let pageTitle = '';
  let metaDescription = '';
  if (inventoryType === 'DisplayHome') {
    pageTitle = `Manufactured, Modular and Mobile Homes Available in ${data.city}, ${data.state} | ${data.dba}, a Cavco Company`;
    metaDescription = `Visit cavcohomes.com to view the selection of manufactured, modular and mobile homes at ${data.dba} in ${data.city}, ${data.state}.`;
  } else {
    pageTitle = `Move-in Ready Manufactured, Modular and Mobile Homes in ${data.city}, ${data.state} | ${data.dba}, a Cavco Company`;
    //`Manufactured Homes in ${data.city}, ${data.state} | Move-In Ready Homes for Sale | ${data.dba}`;
    metaDescription =
      description ||
      cmsMetadata?.metaDescription ||
      `Looking for a move-in ready home? Visit cavcohomes.com to view the selection of manufactured, modular and mobile homes at ${data.dba} in ${data.city}, ${data.state}.`;
    //`Are you looking for a modular home in ${data.city}, ${data.state}? You have found the right place! Take a look at our modular and mobile homes available for sale for folks in ${data.city}, ${data.state}. View more here!`;
  }

  const canonicalUrl = BuildCannonicalURL(links);
  const metaKeywords =
    keywords ||
    cmsMetadata?.metaKeywords ||
    `manufactured home dealer, modular homes, manufactured homes, mobile home builder, system built homes, double wide homes, singlewide homes, ${companyFull}, manufactured home builder, modular home builder, display homes for sale`;
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage =
    buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data.photos);

  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = componentParameterMap.indexRobot || 'index';
  const followRobot = componentParameterMap.followRobot || 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}

export function CavcoPagePropertiesRtlInventoryList(props: BrProps) {
  const { page } = props;
  const dm = getRetailerData(page);

  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm.retailerPath);
}
