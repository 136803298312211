import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Card } from 'react-bootstrap';

import { CardBody } from './CardBody';
import { CardImage } from './CardImage';

import { getRetailerData } from '../../utils';

interface FloorPlanCardProps {
  floorPlanDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  title: string;
  className?: string;
  label: any;
}

export function FloorPlanCard(floorPlanCardProps: FloorPlanCardProps) {
  const {
    id,
    url,
    photoUrls,
    lineDrawingUrl: firstLineDrawingImage,
    startingPrice,
  } = floorPlanCardProps.floorPlanDocumentData;

  const lbl = floorPlanCardProps.label;
  const currencyLocale = lbl.currencyLocale ? lbl.currencyLocale : 'en-US';
  const currencyCode = lbl.currencyCode ? lbl.currencyCode : 'USD';

  const floorPlanUrl = url;

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  if (!hasPhoto && firstLineDrawingImage) {
    imageDefault = firstLineDrawingImage;
  }

  const retailerData: RetailerData | null = getRetailerData(floorPlanCardProps.props.page);

  const floorPlanTitle = floorPlanCardProps.title;
  const startingPriceString = new Intl.NumberFormat(currencyLocale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(startingPrice);

  const dba = retailerData ? retailerData.dba : '';
  const city = retailerData ? retailerData.city : '';
  const state = retailerData ? retailerData.state : '';

  return (
    <Card
      id={id}
      className={`fp-feature-model-card ${!hasPhoto && imageDefault !== null && 'line-drawing'}`}
    >
      <CardImage className="fp-feature-model-image-link" image={imageDefault} url={floorPlanUrl} />
      <CardBody
        title={floorPlanTitle}
        url={floorPlanUrl}
        startingPrice={startingPriceString}
        specialFloorPlan={lbl.specialFloorPlanPricing}
        offeredBy={lbl.offerBy}
        dba={dba}
        city={city}
        state={state}
      />
    </Card>
  );
}
