import React, { useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { CavcoUserLoginForm } from './CavcoUserLoginForm';
import { CavcoUserCreateForm } from './CavcoUserCreateForm';
import { CavcoUserForgotPassword } from './CavcoUserForgotPassword';

const myAccountConfigConst = {
  favoriteBaseUrl: 'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/setfavorite',
  cavcoUserLoginUrl: 'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/login',
  postLoginUrl: 'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/login',
  toReloadAfterPersist: 'true',
  cavcoUserCreateUrl: 'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/create',
  cavcoChangePasswordUrl:
    'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/changepassword',
  cavcoForgetPassword: 'https://dev.cavco.bloomreach.cloud/site/rest/cavcouserinfo/forgetpassword',
  disableCache: 'true',
};

export function SignInModals({
  isMobile = true,
  isTablet = false,
  myAccountConfig = myAccountConfigConst,
  loginModalClosed,
  setSuccessfulSignIn,
}: any) {
  const [loginModal, setLoginModal] = useState(true);
  const closeLoginModal = () => {
    loginModalClosed();
    setLoginModal(false);
  };

  const [createLoginModal, setCreateLoginModal] = useState(false);
  const closeCreateLoginModal = () => {
    loginModalClosed();
    setCreateLoginModal(false);
  };

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const closeForgotPasswordModal = () => {
    loginModalClosed();
    setForgotPasswordModal(false);
  };
  const openForgotPasswordModal = () => setForgotPasswordModal(true);

  const switchToOpenLoginModal = () => {
    setCreateLoginModal(false);
    setLoginModal(true);
  };

  const switchToOpenCreateLoginModal = () => {
    setLoginModal(false);
    setCreateLoginModal(true);
  };

  const switchToOpenForgetPasswordModal = () => {
    // closeLoginModal();
    setLoginModal(false);
    setCreateLoginModal(false);
    openForgotPasswordModal();
  };

  return (
    <>
      <Modal
        className="cvc-request-info-modal-retailer"
        show={loginModal}
        onHide={closeLoginModal}
        backdrop={isMobile || isTablet ? 'static' : true}
        keyboard={!(isMobile || isTablet)}
        top
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col>
              <CavcoUserLoginForm
                cavcoUserLoginUrl={myAccountConfig.cavcoUserLoginUrl}
                postLoginUrl={myAccountConfig.postLoginUrl}
                closeMethod={closeLoginModal}
                switchToOpenCreateLoginModal={switchToOpenCreateLoginModal}
                switchToOpenForgetPasswordModal={switchToOpenForgetPasswordModal}
                isMobile={isMobile}
                setSuccessfulSignIn={setSuccessfulSignIn}
                cavcoFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
                loadMyFavoriteCardsUrl={myAccountConfig.loadMyFavoriteCardsUrl}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        className="cvc-request-info-modal-retailer"
        show={createLoginModal}
        onHide={closeCreateLoginModal}
        backdrop={isMobile || isTablet ? 'static' : true}
        keyboard={!(isMobile || isTablet)}
        top
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col>
              <CavcoUserCreateForm
                cavcoUserLoginUrl={myAccountConfig.cavcoUserCreateUrl}
                postLoginUrl={myAccountConfig.postLoginUrl}
                closeMethod={closeCreateLoginModal}
                switchToOpenLoginModal={switchToOpenLoginModal}
                switchToOpenForgetPasswordModal={switchToOpenForgetPasswordModal}
                isMobile={isMobile}
                setSuccessfulSignIn={setSuccessfulSignIn}
                cavcoFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
                loadMyFavoriteCardsUrl={myAccountConfig.loadMyFavoriteCardsUrl}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        className="cvc-request-info-modal-retailer"
        show={forgotPasswordModal}
        onHide={closeForgotPasswordModal}
        backdrop={isMobile || isTablet ? 'static' : true}
        keyboard={!(isMobile || isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col>
              <CavcoUserForgotPassword
                cavcoUserLoginUrl={myAccountConfig.cavcoForgetPassword}
                postLoginUrl={myAccountConfig.postLoginUrl}
                closeMethod={closeForgotPasswordModal}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
