import { BrProps } from '@bloomreach/react-sdk';

import { allFacets, filterConfiguration, resourceBundleMapper } from '../FloorPlanListES.constants';
import { buildResourceBundleToObject, getPageContentData } from '../../utils';
import { AllFacetsProps, ResourceBundleOutputProps } from '../FloorPlanListES.interface';

const mergeArrays = (base: any[], update: any[]) => {
  if (update.length === base.length) {
    return update;
  }

  const keys = update.reduce((m, c: any) => ({ ...m, [c.value]: c.count }), {});

  return base.map((item) => {
    return keys[item.value] ? { ...item, count: keys[item.value] } : item;
  });
};

export const checkIsDefaultValue = (
  key: string,
  value: any,
  facetConfig: AllFacetsProps = allFacets
) => {
  const config = facetConfig[key];
  const val = Array.isArray(value) ? value : config.urlMapper(key, value)[key];
  return JSON.stringify(config.ui?.defaultValue) === JSON.stringify(val);
};

export const allFacetsMapper = (facets: any) => {
  const newFacets = Object.entries(facets);

  if (!newFacets.length) {
    return null;
  }
  return newFacets.reduce((memo: any, [k, v]: [string, any]) => {
    const values = v[0]?.data;
    // if the facet doesn't exist or have any values or, if it does exist but has no key, ignore it.
    if (!values.length) {
      return memo;
    }
    return {
      ...memo,
      [k]: values.map((value: any) => {
        return { ...value, count: 0 };
      }),
    };
  }, {});
};

export const facetMapper = (
  baseFacets: any,
  updatedFacets: any,
  config: any,
  primaryCount = 6,
  allProperties: string[] = []
) => {
  const newFacets = Object.entries(updatedFacets);
  const facetCount = Object.keys(baseFacets).length;

  if (!newFacets.length) {
    return null;
  }

  return newFacets.reduce(
    (memo: any, [k, v]: [string, any]) => {
      const updated = { ...memo };
      const baseFacetInfo = config[k][0];

      const allValues = baseFacets[k] ? Object.values(baseFacets[k]) : [];

      const values = v[0]?.data;
      const updatedValues =
        allValues.length !== values.length ? mergeArrays(allValues, values) : values;

      // if the facet doesn't exist or have any values or, if it does exist but has no key, ignore it.
      if (!baseFacetInfo || !updatedValues.length || !baseFacetInfo?.key) {
        return updated;
      }
      const isPrimary = baseFacetInfo.order < primaryCount || facetCount < primaryCount;
      // add non-static items to the facet...

      // filter out any options with no results if filterEmpty option is true
      const filteredUpdatedValues = baseFacetInfo?.filterEmpty
        ? updatedValues.filter((facet: any) => facet.count > 0)
        : updatedValues;

      // if the results do not contain a product with this key
      // and this property is set to be disabled if no products contain it,
      // disable it...
      const facet = {
        ...baseFacetInfo,
        isPrimary,
        key: k,
        options: filteredUpdatedValues,
        disableFilter: baseFacetInfo.disableWhenEmpty && !allProperties.includes(k),
      };

      updated.primary = [...memo.primary, facet];

      return updated;
    },
    {
      primary: [],
    }
  );
};

export const sortFacetsByOrder = (facets: any = {}) => {
  if (!facets) {
    return facets;
  }
  const primary = facets.primary?.sort((a: any, b: any) => a.order - b.order) || [];
  return { primary };
};

// get facet data based on configuration
export const getFacets = (
  listType: string,
  listSubType: string,
  type: 'ui' | 'elasticSearch' | 'mapper',
  facetConfig = allFacets,
  hasLocationOverride = false
) => {
  const { facet_bar } = filterConfiguration[listType][listSubType];
  // remove locations from the facets if there is an override: no lat info
  const facetBarFinal = facet_bar.filter((facet: any) => {
    const exclude = hasLocationOverride && facet === 'locations';
    return !exclude;
  });

  const filteredList = facetBarFinal.map((key: string, index: number) => {
    const item = facetConfig[key][type];
    return [[key], type === 'ui' ? [{ ...item, order: index }] : item];
  });

  return Object.fromEntries(filteredList);
};

// get country data from url or storage -- if provided
export const getLocalizationData = (
  path: string,
  storageKey: string,
  isBuildingCenterOnly: boolean
): { countryCode: string; unit: string; postalCode: string } => {
  let countryCode = '';
  const storage: string | null = storageKey ? window.sessionStorage.getItem(storageKey) : null;
  const storageJSON = storage ? JSON.parse(storage) : storage;
  if (!isBuildingCenterOnly) {
    const regexCountry = path.match(/\/(\w{2})$/);
    const countryFromUrl = regexCountry ? regexCountry[1] : 'us';
    countryCode = !countryFromUrl && storage ? storageJSON?.country : countryFromUrl;
  }

  return {
    countryCode,
    unit: countryCode === 'us' ? 'mi' : 'km',
    postalCode: storage ? storageJSON.zipcode : null,
  };
};

// build an array from integer...
export const buildArrayFromNumber = (number: number) =>
  [...Array(number).keys()].map((_, i) => i + 1);

// map data from bloomreach...
export const mapResourceBundleData = ({ component, page }: BrProps): ResourceBundleOutputProps => {
  return Object.entries(component.getModels()).reduce((memo: any, [k, v]: [string, any]) => {
    // some data will come back as a reference ($ref), some as an object
    // we only need to process the refs
    const data = v.hasOwnProperty('$ref')
      ? buildResourceBundleToObject(getPageContentData<ResourceBundleData>(v, page))
      : v;
    return {
      ...memo,
      [k]: data,
    };
  }, {});
};

export const mapResourceBundleToItem = (bundleData: any) => {
  const items = Object.entries(resourceBundleMapper);
  return items.reduce((memo: any, [k, v]: any) => {
    return {
      ...memo,
      [k]: bundleData[v],
    };
  }, {});
};
