export const floorPlanToInventoryMapper = (data: any) => {
  let componentData;
  // if nodeType == floorplan
  const { floorplan: floorplanRef, nodeTypes } = data.component.getModels();
  const fpDocument = floorplanRef && data.page.getContent(floorplanRef);

  // if nodeType == inventory
  const details = data.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('inventory-data');
  const docRef = form && form.getModels().document;
  const inventoryDocument = data.page.getContent(docRef);

  if (nodeTypes === 'cavco:Inventory') {
    const {
      squareFeet,
      // content1,
      bedRoomsValue,
      bathRoomsValue,
      sectionsValue,
      buildingMethodValue,
      specialFeatures,
      lotSize,
      floorplan,
      retailerLocation,
    } = inventoryDocument.getData();
    const dArea = floorplan && data.page.getContent(floorplan).dimensionalArea;

    const { community, displayName } = data.page.getContent(retailerLocation).getData();

    componentData = {
      squareFootage: squareFeet,
      dimensionalArea: dArea,
      content1: { value: '' },
      numBedrooms: bedRoomsValue,
      numBathrooms: bathRoomsValue,
      sections: sectionsValue,
      buildingMethodValue,
      floorplanSellingFeatures: specialFeatures,
      lotSize,
      community,
      displayName,
    };
  } else {
    const {
      squareFootage,
      dimensionalArea,
      content1,
      numBedrooms,
      numBathrooms,
      sections,
      buildingMethodValue: fpBuildingMethod,
      floorplanSellingFeatures,
    } = fpDocument.getData();
    componentData = {
      squareFootage,
      dimensionalArea,
      content1,
      numBedrooms,
      numBathrooms,
      sections,
      buildingMethodValue: fpBuildingMethod,
      floorplanSellingFeatures,
    };
  }
  return componentData;
};
