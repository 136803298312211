import React from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import './ContentCustomClick.scss';
import { getUrlFromImage } from 'components/utils';

export function ContentCustomClick(props: BrProps) {
  const { document: documentRef, componentParameterMap } = props.component.getModels();
  const document = documentRef ? props.page.getContent(documentRef) : undefined;

  if (!document) {
    return null;
  }

  const {
    author,
    content,
    content1,
    date,
    image: imageRef,
    title,
    link,
  } = document.getData<DocumentData>();
  const image = imageRef && props.page.getContent(imageRef);
  const theContent = content || content1;
  function newWindow() {
    window.open(link.destinationUrl, '_blank', componentParameterMap.features);
  }

  return (
    <div
      className={`cvc-simple-content--new-window${
        props.page.isPreview() ? ' has-edit-button' : ''
      }`}
    >
      <div className="cvc-simple-content--new-window__container">
        <BrManageContentButton content={document} />
        {image && <img className="img-fluid mb-3" src={getUrlFromImage(image)} alt={title} />}
        {title && <h2>{title}</h2>}
        {author && <p className="mb-3 text-muted">{author}</p>}
        {date && <p className="mb-3 small text-muted">{new Date(date).toDateString()}</p>}
        {theContent && (
          <button type="button" onClick={link && newWindow}>
            <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(theContent.value) }} />
          </button>
        )}
      </div>
    </div>
  );
}
