import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { BrPageContext } from '@bloomreach/react-sdk';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube from 'react-youtube';
import { ResolverLink } from 'components/shared';
import { Spinner } from 'components/shared/Spinner';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { ReactComponent as CloseIcon } from '../../assets/images/cavcohomes/x-white.svg';
import { ReactComponent as ExportIcon } from '../../assets/images/cavcohomes/export.svg';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES, PAGE_VARIANT } from '../enums';

import 'swiper/swiper.scss';
import './MediaViewer.scss';
import { getYoutubeId, getUrlFromImage } from '../utils';

interface MediaViewerProps {
  show: boolean;
  onHide: () => void;
  mediaType: string;
  mediaAssetItems: [MediaAssetCompound];
  albumName: string;
  albumLink: string;
  hasParentPageFloorPlan: boolean;
  indexItemSelected: number;
  defaultImageSize?: string;
  spinnerColorInDarkBackground?: string;
}

function getImage(item: any, imgContent: any, mediaType: string, defaultImageSize = '') {
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';
  let originalImgPath = '';

  let defaultImageSizeUrl = '';
  if (defaultImageSize !== '') {
    if (imgContent) {
      defaultImageSizeUrl = getUrlFromImage(imgContent, defaultImageSize);
    } else if (item.ximage) {
      defaultImageSizeUrl =
        item.ximage[`${defaultImageSize}`] && item.ximage[`${defaultImageSize}`].url
          ? item.ximage[`${defaultImageSize}`].url
          : '';
    }
  } else if (imgContent) {
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
    originalImgPath = getUrlFromImage(imgContent);
  } else if (item.ximage) {
    mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
    originalImgPath = extraLargeImgPath;
  }

  return defaultImageSize === '' ? (
    <picture key={`${item.id}`} className="cvc-media-viewer__item-image">
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={originalImgPath} />
      {extraLargeImgPath && (
        <source
          media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
          srcSet={extraLargeImgPath}
        />
      )}
      {largeImgPath && (
        <source
          media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
          srcSet={largeImgPath}
        />
      )}
      <img
        alt={item.imageAltText}
        src={mediumImgPath}
        className={classNames(
          `${mediaType === ALBUM_MEDIA_TYPES.LINE_DRAWING ? 'is-linedrawing' : ''}`
        )}
      />
    </picture>
  ) : (
    <picture key={`${item.id}`} className="cvc-media-viewer__item-image">
      <img
        alt={item.imageAltText}
        src={defaultImageSizeUrl}
        className={classNames(
          `${mediaType === ALBUM_MEDIA_TYPES.LINE_DRAWING ? 'is-linedrawing' : ''}`
        )}
      />
    </picture>
  );
}

const getMainNextButton = () => {
  const mainNextButton: any = document.querySelector('.main-swiper-button-next-container');
  if (mainNextButton) {
    return mainNextButton;
  }
};
const getMainPrevioustButton = () => {
  const mainPrevButton: any = document.querySelector('.main-swiper-button-prev-container');
  if (mainPrevButton) {
    return mainPrevButton;
  }
};

function getCustomHeightInPercentage(heighToReduce: number) {
  const currentScreenHeight = window.innerHeight;
  const customHeight = currentScreenHeight - heighToReduce;
  const customHeightInPercentage = Math.ceil((customHeight * 100) / currentScreenHeight);
  return `${customHeightInPercentage}%`;
}

function Display3DTour({ item, windowDimensions, spinnerColorInDarkBackground }: any) {
  const [isMediaReady, setIsMediaReady] = useState(false);

  const itemDescription = item.description;
  const iframeSrc = item.matterportURL.includes('matterport')
    ? `${item.matterportURL}&help=0&brand=0&nt=1`
    : item.matterportURL;
  let customIframeHeight = '';
  if (itemDescription) {
    const scrollBarDescAndLinksContainer: any = document.querySelector(
      '.cvc-media-viewer__scroll-bar-description-and-links-container'
    );
    if (scrollBarDescAndLinksContainer) {
      const scrollBarDescAndLinksContainerStyle = window.getComputedStyle(
        scrollBarDescAndLinksContainer
      );
      const scrollBarDescAndLinksContainerHeight =
        scrollBarDescAndLinksContainerStyle.height.replace('px', '');
      const scrollBarDescAndLinksContainerPaddingTop =
        scrollBarDescAndLinksContainerStyle.paddingTop.replace('px', '');

      const heightToReduce =
        Number(scrollBarDescAndLinksContainerHeight) +
        Number(scrollBarDescAndLinksContainerPaddingTop);
      if (scrollBarDescAndLinksContainerHeight) {
        if (windowDimensions.isMobile) {
          customIframeHeight = `calc(100vh - ${heightToReduce}px)`;
        } else if (windowDimensions.isTablet) {
          customIframeHeight = getCustomHeightInPercentage(
            Number(scrollBarDescAndLinksContainerHeight) + 6
          );
        } else if (windowDimensions.isDesktopExtraBig) {
          customIframeHeight = getCustomHeightInPercentage(
            Number(scrollBarDescAndLinksContainerHeight) * 2 +
              Number(scrollBarDescAndLinksContainerPaddingTop)
          );
        } else {
          customIframeHeight = getCustomHeightInPercentage(
            Number(scrollBarDescAndLinksContainerHeight) * 2 -
              Number(scrollBarDescAndLinksContainerPaddingTop)
          );
        }
      }

      const halfHeightToReduce = heightToReduce / 2;

      let swiperButtonContainerHeight = '81%';
      if (windowDimensions.isTablet) {
        swiperButtonContainerHeight = '83%';
      }
      if (!windowDimensions.isMobile && !windowDimensions.isTablet) {
        swiperButtonContainerHeight = '100%';
      }

      const mainSwiperButtonPrevContainer: any = getMainPrevioustButton();
      if (mainSwiperButtonPrevContainer) {
        mainSwiperButtonPrevContainer.style.height = `calc(${swiperButtonContainerHeight} - ${halfHeightToReduce}px)`;
      }
      const mainSwiperButtonNexContainer: any = getMainNextButton();
      if (mainSwiperButtonNexContainer) {
        mainSwiperButtonNexContainer.style.height = `calc(${swiperButtonContainerHeight} - ${halfHeightToReduce}px)`;
      }
    }
  } else if (!windowDimensions.isMobile && !windowDimensions.isTablet) {
    customIframeHeight = '97%';
  }

  setTimeout(() => {
    setIsMediaReady(true);
  }, 1500);

  return (
    <>
      {!isMediaReady && (
        <div key="spinner-tour" className="spinner-container">
          <Spinner
            inDarkBackground
            color={spinnerColorInDarkBackground ? `${spinnerColorInDarkBackground}` : ''}
          />
        </div>
      )}
      <iframe
        title={item.id}
        className="cvc-media-viewer__item-iframe"
        src={iframeSrc}
        width="100%"
        frameBorder="0"
        style={
          customIframeHeight.length > 0 ? { height: `${customIframeHeight}` } : { height: '100%' }
        }
      />
    </>
  );
}

export function MediaViewer(props: MediaViewerProps) {
  const page = React.useContext(BrPageContext)!;
  const [thumbsSwiper, setThumbsSwiper] = useState(undefined);
  const [currentItem, setCurrentItem] = useState(props.indexItemSelected);
  let currentVideoPlaying: any = null;
  const windowDimensions = useWindowDimensions();

  const mediaGalleryClasses = classNames({
    'cvc-media-viewer': true,
  });

  const handleSwiper = (swiper: any) => {
    setThumbsSwiper(swiper);
  };

  const handleCloseModal = () => {
    setThumbsSwiper(undefined);
    props.onHide();
  };

  const setFocusOnModal = () => {
    const modal: any = document.querySelector('.modal');
    if (modal) {
      modal.focus();
    }
  };

  const pauseCurrentVideoPlaying = () => {
    if (currentVideoPlaying !== null) {
      currentVideoPlaying.pauseVideo();
      currentVideoPlaying = null;
    }
  };

  const mainPreviousSlide = () => {
    const mainPrevButton = getMainPrevioustButton();
    if (mainPrevButton) {
      mainPrevButton.click();
    }
  };

  const mainNextSlide = () => {
    const mainNextButton = getMainNextButton();
    if (mainNextButton) {
      mainNextButton.click();
    }
  };

  useEffect(() => {
    const modal: any = document.querySelector('.modal')!;
    if (modal) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 37) {
          mainPreviousSlide();
        }
        if (e.keyCode === 39) {
          mainNextSlide();
        }
      };

      modal.addEventListener('keydown', handleKeyDown);
      return () => modal.removeEventListener('keydown', handleKeyDown);
    }
  });

  const displayLink = (link: LinkCompound) => {
    return (
      <>
        {' '}
        <ResolverLink
          href={link.destinationUrl !== '' ? link.destinationUrl : undefined}
          className="cvc-media-viewer__scroll-bar-optional-link"
        >
          {link.displayText !== '' ? link.displayText : 'Optional Link'}
        </ResolverLink>
      </>
    );
  };

  const displayFloorplanHeaderLink = () => {
    const selectedItem = props.mediaAssetItems[currentItem];
    if (!selectedItem) {
      return '';
    }
    const floorPlanDocument: any =
      selectedItem.documentPicker && page.getContent(selectedItem.documentPicker);
    if (!floorPlanDocument) {
      return '';
    }
    let floorPlanURL = '/';
    if (floorPlanDocument && floorPlanDocument.getUrl()) {
      const floorPlanUrlSplitted = floorPlanDocument.getUrl().split('/');
      floorPlanURL = floorPlanUrlSplitted.splice(0, floorPlanUrlSplitted.length - 1).join('/');
    }

    return (
      <>
        <a style={{ float: 'right' }} href={floorPlanURL}>
          <ExportIcon className="export-icon" />
          View Floor Plan
        </a>
      </>
    );
  };

  const displayFloorPlanLink = (floorPlanDocument: any) => {
    if (!floorPlanDocument) return '';

    const { modelName, modelNumber } = floorPlanDocument.model;

    let floorPlanURL = '/';
    if (floorPlanDocument.getUrl()) {
      const floorPlanUrlSplitted = floorPlanDocument.getUrl().split('/');
      floorPlanURL = floorPlanUrlSplitted.splice(0, floorPlanUrlSplitted.length - 1).join('/');
    }

    const floorPlanText =
      modelName && modelNumber ? `${modelName} ${modelNumber}` : 'Floor Plan Detail';

    return (
      <>
        {' '}
        <span className="cvc-media-viewer__scroll-bar-floor-plan-label">Related Floor Plan: </span>
        <ResolverLink href={floorPlanURL} className="cvc-media-viewer__scroll-bar-optional-link">
          {floorPlanText}
        </ResolverLink>
      </>
    );
  };

  const displayDescriptionAndLinks = (albumLink: string, item: MediaAssetCompound) => {
    const floorPlanDocument = item.documentPicker && page.getContent(item.documentPicker);
    return (
      <div className="cvc-media-viewer__scroll-bar-description-and-links-container">
        {item && item.description && (
          <span className="cvc-media-viewer__scroll-bar-description">{item.description}</span>
        )}
        {item.link &&
          (item.link.displayText !== '' || item.link.destinationUrl !== '') &&
          !floorPlanDocument &&
          displayLink(item.link)}
        {item.link &&
          item.link.displayText === '' &&
          item.link.destinationUrl === '' &&
          floorPlanDocument &&
          displayFloorPlanLink(floorPlanDocument)}
      </div>
    );
  };

  const getMatterportThumbImage = (matterportId: string, width = 0) => {
    return width > 0
      ? `https://my.matterport.com/api/v1/player/models/${matterportId}/thumb?width=${width}`
      : `https://my.matterport.com/api/v1/player/models/${matterportId}/thumb`;
  };

  const display3DTourThumb = (item: any, index: number) => {
    let thumbImageSrc = 'https://cdn1.cavco.com/public/cavcoweb/images/bloomreach/panatour.jpg';

    if (item.matterportURL.includes('matterport')) {
      const matterportArrayValues = item.matterportURL.split('/');
      const matterportID = matterportArrayValues[matterportArrayValues.length - 1]
        .replace('?m=', '')
        .replace(/&\S*/, '')
        .trim();
      thumbImageSrc = getMatterportThumbImage(matterportID);
    }

    return (
      <div
        key={`cvc-media-viewer-tours-container-${item.id}`}
        className="cvc-media-viewer-item-container"
        style={{ cursor: 'pointer' }}
        tabIndex={0}
        role="button"
      >
        <div
          key={`cvc-media-viewer-tour-${index}`}
          className="cvc-media-viewer-media-item"
          tabIndex={-1}
        >
          <img
            className="cvc-media-viewer__item-image"
            alt={item.imageAltText}
            src={thumbImageSrc}
          />
        </div>
        <div
          key={`cvc-media-viewer-floorplan-tours-item-link-${item.id}`}
          className="cvc-media-viewer-item-link"
          tabIndex={-1}
        >
          <VideoIcon
            key={`cvc-media-viewer-floorplan-tours-item-icon-${item.id}`}
            className="cvc-media-viewer-item-icon"
          />
        </div>
      </div>
    );
  };

  const onPlayHandler = (event: any) => {
    try {
      if (currentVideoPlaying !== null && currentVideoPlaying.f.id !== event.target.f.id) {
        pauseCurrentVideoPlaying();
      }

      currentVideoPlaying = event.target;
      setFocusOnModal();
    } catch (e) {
      return null;
    }
  };

  const DisplayVideo = ({ item }: any) => {
    const [isMediaReady, setIsMediaReady] = useState(false);

    const videoId = getYoutubeId(item);

    let customIframeHeight = '';
    const itemDescription = item.description;

    if (itemDescription) {
      const scrollBarDescAndLinksContainer: any = document.querySelector(
        '.cvc-media-viewer__scroll-bar-description-and-links-container'
      );
      if (scrollBarDescAndLinksContainer) {
        const scrollBarDescAndLinksContainerStyle = window.getComputedStyle(
          scrollBarDescAndLinksContainer
        );
        const scrollBarDescAndLinksContainerHeight =
          scrollBarDescAndLinksContainerStyle.height.replace('px', '');
        const scrollBarDescAndLinksContainerPaddingTop =
          scrollBarDescAndLinksContainerStyle.paddingTop.replace('px', '');

        const heightToReduce =
          Number(scrollBarDescAndLinksContainerHeight) +
          Number(scrollBarDescAndLinksContainerPaddingTop);

        if (scrollBarDescAndLinksContainerHeight) {
          if (windowDimensions.isMobile) {
            customIframeHeight = getCustomHeightInPercentage(heightToReduce);
          } else if (windowDimensions.isTablet) {
            customIframeHeight = getCustomHeightInPercentage(
              Number(scrollBarDescAndLinksContainerHeight) + 6
            );
          } else if (windowDimensions.isDesktopExtraBig) {
            customIframeHeight = getCustomHeightInPercentage(
              Number(scrollBarDescAndLinksContainerHeight) * 2 +
                Number(scrollBarDescAndLinksContainerPaddingTop)
            );
          } else {
            customIframeHeight = getCustomHeightInPercentage(
              Number(scrollBarDescAndLinksContainerHeight) * 2
            );
          }
        }

        const halfHeightToReduce = heightToReduce / 2;

        let swiperButtonContainerHeight = '81%';
        if (windowDimensions.isTablet) {
          swiperButtonContainerHeight = '85%';
        }
        if (!windowDimensions.isMobile && !windowDimensions.isTablet) {
          swiperButtonContainerHeight = '100%';
        }

        const mainSwiperButtonPrevContainer: any = getMainPrevioustButton();
        if (mainSwiperButtonPrevContainer) {
          mainSwiperButtonPrevContainer.style.height = `calc(${swiperButtonContainerHeight} - ${halfHeightToReduce}px)`;
        }
        const mainSwiperButtonNexContainer: any = getMainNextButton();
        if (mainSwiperButtonNexContainer) {
          mainSwiperButtonNexContainer.style.height = `calc(${swiperButtonContainerHeight} - ${halfHeightToReduce}px)`;
        }
      }
    } else if (!windowDimensions.isMobile && !windowDimensions.isTablet) {
      customIframeHeight = '97%';
    }

    const handleOnReady = () => {
      try {
        setIsMediaReady(true);
      } catch (e) {
        return null;
      }
    };

    return (
      <>
        {!isMediaReady && (
          <div key="spinner-video" className="spinner-container">
            <Spinner
              inDarkBackground
              color={
                props.spinnerColorInDarkBackground ? `${props.spinnerColorInDarkBackground}` : ''
              }
            />
          </div>
        )}
        <YouTube
          id={item.id}
          videoId={videoId}
          onPlay={onPlayHandler}
          onReady={handleOnReady}
          containerClassName="cvc-media-viewer__item-iframe-container"
          opts={{
            width: '100%',
            height: customIframeHeight.length > 0 ? `${customIframeHeight}` : '100%',
            playerVars: {
              controls: 1,
              start: 0,
              disablekb: 1,
              enablejsapi: 1,
              iv_load_policy: 3,
              modestbranding: 1,
            },
          }}
        />
      </>
    );
  };

  function getYoutubeThumbImage(videoId: string, size = 'hq') {
    const imageQal = !size || size.length === 0 ? 'hqdefault.jpg' : `${size}default.jpg`;
    return `https://img.youtube.com/vi/${videoId}/${imageQal}`;
  }

  const displayVideoThumb = (item: any, index: number) => {
    const videoId = getYoutubeId(item);

    return (
      <div
        key={`cvc-media-viewer-videos-container-${item.id}`}
        className="cvc-media-viewer-item-container"
        style={{ cursor: 'pointer' }}
        tabIndex={0}
        role="button"
      >
        <div
          key={`cvc-media-viewer-video-${index}`}
          className="cvc-media-viewer-media-item"
          tabIndex={-1}
        >
          <img
            className="cvc-media-viewer__item-image"
            alt={item.imageAltText}
            src={getYoutubeThumbImage(videoId)}
          />
        </div>
        <div
          role="button"
          key={`cvc-media-viewer-floorplan-videos-item-link-${item.id}`}
          className="cvc-media-viewer-item-link"
          tabIndex={0}
        >
          <VideoIcon
            key={`cvc-media-viewer-floorplan-videos-item-icon-${item.id}`}
            className="cvc-media-viewer-item-icon"
          />
        </div>
      </div>
    );
  };

  const floorplanHeader = () => {
    return (
      <div className="cvc-floorplan-modal-header">
        <Button onClick={handleCloseModal}>
          <CloseIcon className="close-icon" />
          Close
        </Button>
        {displayFloorplanHeaderLink()}
      </div>
    );
  };

  const displaySliders = (
    albumLink: string,
    mediaType: string,
    mediaAssetItems: [MediaAssetCompound],
    isThumbs = false
  ) => {
    if (!mediaAssetItems) return '';

    return mediaAssetItems.map((item: any, index: number) => {
      const imgContent: any = item && page.getContent(item.image);

      const slideContainerClasses = classNames({
        'cvc-media-viewer__item': true,
        'swiper-slide-container': true,
        'swiper-slide-container-thumbs': isThumbs,
      });

      switch (mediaType) {
        case ALBUM_MEDIA_TYPES.IMAGE:
        case ALBUM_MEDIA_TYPES.LINE_DRAWING:
          return (
            <SwiperSlide key={`slide-image-${index.toString()}`}>
              <div className={slideContainerClasses}>
                {imgContent || item.ximage
                  ? getImage(
                      item,
                      imgContent,
                      mediaType,
                      props.defaultImageSize ? props.defaultImageSize : ''
                    )
                  : ''}
                {!isThumbs && item.description && displayDescriptionAndLinks(albumLink, item)}
              </div>
            </SwiperSlide>
          );
        case ALBUM_MEDIA_TYPES.VIRTUAL_TOUR:
        case ALBUM_MEDIA_TYPES.PANATOUR:
          return (
            <SwiperSlide key={`slide-tour-${index.toString()}`}>
              <div className={slideContainerClasses}>
                {!isThumbs && item.matterportURL && (
                  <Display3DTour
                    item={item}
                    windowDimensions={windowDimensions}
                    spinnerColorInDarkBackground={props.spinnerColorInDarkBackground}
                  />
                )}
                {isThumbs && item.matterportURL ? display3DTourThumb(item, index) : ''}
                {!isThumbs && item.description && displayDescriptionAndLinks(albumLink, item)}
              </div>
            </SwiperSlide>
          );
        case ALBUM_MEDIA_TYPES.VIDEO:
          return (
            <SwiperSlide key={`slide-video-${index.toString()}`}>
              <div className={slideContainerClasses}>
                {!isThumbs && item.videoURL ? <DisplayVideo item={item} /> : ''}
                {isThumbs && item.videoURL && displayVideoThumb(item, index)}
                {!isThumbs && item.description && displayDescriptionAndLinks(albumLink, item)}
              </div>
            </SwiperSlide>
          );
        default:
          return '';
      }
    });
  };

  const displayAlbumName = (hasParentPageFloorPlan: boolean, albumName: string) => {
    if (!albumName) return '';

    const parentPagelabel = !hasParentPageFloorPlan ? 'Album' : 'Floor Plan';

    return (
      <div className="cvc-media-viewer__scroll-bar-parent-page d-none d-md-block">
        <span className="cvc-media-viewer__scroll-bar-parent-page-label">
          {parentPagelabel}: {albumName}
        </span>
      </div>
    );
  };
  const allowTouchMove = !!(windowDimensions.isMobile || windowDimensions.isTablet);

  const handleSliceChange = (swiperCore: any) => {
    if (props.mediaAssetItems.length === 1) {
      setCurrentItem(0);
    }
    const { activeIndex } = swiperCore;
    const offset = props.mediaAssetItems.length > 6 ? 7 : props.mediaAssetItems.length;
    if (activeIndex - offset < 0) {
      setCurrentItem(props.mediaAssetItems.length - 1);
    } else if (activeIndex - offset >= props.mediaAssetItems.length) {
      setCurrentItem(0);
    } else {
      setCurrentItem(activeIndex - offset);
    }
  };

  return (
    <div className={mediaGalleryClasses}>
      <Modal className="cvc-media-viewer-modal" show={props.show} onHide={handleCloseModal}>
        <Modal.Header closeButton>{floorplanHeader()}</Modal.Header>
        <Modal.Body>
          <Swiper
            className="gallery-top"
            allowTouchMove={allowTouchMove}
            loop
            initialSlide={props.indexItemSelected}
            loopedSlides={props.mediaAssetItems.length > 6 ? 7 : props.mediaAssetItems.length}
            slidesPerView={1}
            onTransitionEnd={handleSliceChange}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            navigation={{
              nextEl: '.main-swiper-button-next-container',
              prevEl: '.main-swiper-button-prev-container',
            }}
            pagination={{ type: 'fraction' }}
            thumbs={{ swiper: thumbsSwiper }}
          >
            {displaySliders(props.albumLink, props.mediaType, props.mediaAssetItems)}
            <button className="main-swiper-button-next-container" type="button">
              <div className="swiper-button-next" />
            </button>
            <button className="main-swiper-button-prev-container" type="button">
              <div className="swiper-button-prev" />
            </button>
          </Swiper>

          <div
            className={classNames(
              'cvc-media-viewer__scroll-bar',
              `${props.mediaType === ALBUM_MEDIA_TYPES.VIDEO ? 'show-videos' : ''}`
            )}
          >
            <Swiper
              className="gallery-thumbs d-none d-md-flex"
              loop={props.mediaAssetItems.length > 6}
              loopedSlides={
                props.mediaAssetItems.length > 6 ? props.mediaAssetItems.length + 1 : undefined
              }
              spaceBetween={props.mediaAssetItems.length > 1 ? 19 : 0}
              slidesPerView={props.mediaAssetItems.length >= 6 ? 6 : props.mediaAssetItems.length}
              navigation={{
                nextEl: '.thumbs-swiper-button-next-container',
                prevEl: '.thumbs-swiper-button-prev-container',
              }}
              slideToClickedSlide
              watchSlidesProgress
              breakpoints={{
                1920: {
                  slidesPerView:
                    props.mediaAssetItems.length >= 8 ? 8 : props.mediaAssetItems.length,
                },
              }}
              onSwiper={(swiper) => handleSwiper(swiper)}
            >
              {PAGE_VARIANT.CAVCOHOMES &&
                displaySliders(props.albumLink, props.mediaType, props.mediaAssetItems, true)}
              <button
                className="thumbs-swiper-button-next-container"
                type="button"
                disabled={props.mediaAssetItems.length <= 6}
                onClick={() => mainNextSlide()}
              >
                <div className="swiper-button-next" />
              </button>
              <button
                className="thumbs-swiper-button-prev-container"
                type="button"
                disabled={props.mediaAssetItems.length <= 6}
                onClick={() => mainPreviousSlide()}
              >
                <div className="swiper-button-prev" />
              </button>
            </Swiper>
            {props.albumName && displayAlbumName(props.hasParentPageFloorPlan, props.albumName)}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
