import React from 'react';
import { TextField } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/images/cavcohomes/magnifying-glass.svg';
import { ReactComponent as SearchIconMobile } from '../../assets/images/cavcohomes/magnifying-glass-mobile.svg';
import './SearchTextField.scss';

interface SearchTextFieldProps {
  value: string;
  onchange?: any;
  onEnter?: any;
}

export function SearchTextField(props: SearchTextFieldProps) {
  const onChange = (event: any) => {
    if (props.onchange) {
      props.onchange(event);
    }
  };

  return (
    <div className="search-component-container">
      <div className="icon-container">
        <SearchIcon className="search-icon" />
        <SearchIconMobile className="search-icon-mobile" />
      </div>
      <TextField
        className="search-textfield"
        fullWidth
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.onEnter();
          }
        }}
        value={props.value}
        variant="outlined"
      />
    </div>
  );
}
