import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { IMaskInput } from 'react-imask';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import { Container, Col, Row } from 'react-bootstrap';
import { scroller } from 'react-scroll';
import { CloseIcon } from 'components/shared';
import { SITE_NAMES } from 'components/enums';
import {
  buildResourceBundleToObject,
  buildValueListToObject,
  buildResourceBundleToDropdown,
  getPageContentData,
  getCurrentSiteName,
  buildDocumentToDropdown,
} from '../utils';
import { ReactComponent as ArrowDropdown } from '../../assets/images/arrow-drop-down.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon-20.svg';
import './CustomerSupportForm.scss';

const currentSite = getCurrentSiteName();
const isCavcoHomes = currentSite === SITE_NAMES.CAVCO_HOMES;

interface CustomerSupportModel {
  formConfig: import('@bloomreach/spa-sdk').Reference;
  labels: import('@bloomreach/spa-sdk').Reference;
  modalLabels: import('@bloomreach/spa-sdk').Reference;
  states: import('@bloomreach/spa-sdk').Reference;
  caProvinces: import('@bloomreach/spa-sdk').Reference;
  manufacturers: import('@bloomreach/spa-sdk').Reference;
  manufacturerNumberDoc: import('@bloomreach/spa-sdk').Reference;
  [key: string]: import('@bloomreach/spa-sdk').Reference;
}

interface CustomerModal {
  open: boolean;
  title: string | number;
  description: string | number;
  textButton: string | number;
}

interface NeedHelpModalProps {
  title: string;
  content: string;
  open: boolean;
  textButton: string | number;
  handleClose?: () => void;
}

interface CustomerModalProps extends CustomerModal {
  handleClose: () => void;
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  emailAddress: '',
  mainPhoneNo: '',
  cellPhoneNo: '',
  workPhoneNo: '',
  purchasedDate: '',
  comments: '',
  serialNumber: '',
  manufacturer: '',
  buildingCenter: '',
  webType: '',
  plantUid: '',
};

const formValueMapping: { [key: string]: string } = {
  firstName: 'firstName',
  lastName: 'lastName',
  streetAddress: 'streetAddress',
  city: 'city',
  state: 'state',
  zipCode: 'zip',
  emailAddress: 'email',
  mainPhoneNo: 'mainPhoneNumber',
  purchasedDate: 'dateOfPurchase',
  serialNumber: 'serialNumber',
  manufacturer: 'manufacturer',
  buildingCenter: 'buildingCenter',
  comments: 'comments',
};

const getValidationSchema = (formConfig: any) => {
  const schema: { [key: string]: any } = {};
  const excludedFields = ['cellPhoneNo', 'workPhoneNo', 'zipCode', 'emailAddress', 'purchasedDate'];
  Object.keys(initialFormValues).forEach((key: string) => {
    const errorMsg = formConfig[`${formValueMapping[key]}_errormsg`];
    if (!excludedFields.includes(key)) {
      schema[key] = yup.string().required(errorMsg);
    }
    if (key === 'zipCode') {
      schema[key] = yup
        .string()
        .required(errorMsg)
        .matches(/^[0-9]+$/, errorMsg);
    }

    if (key === 'emailAddress') {
      schema[key] = yup.string().required(errorMsg).email(errorMsg);
    }

    if (key === 'purchasedDate') {
      schema[key] = yup
        .string()
        .required(errorMsg)
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, errorMsg);
    }
  });
  return yup.object(schema);
};

const PhoneNumberMask = React.forwardRef<HTMLElement, any>(function PhoneNumberMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      placeholder="###-###-####"
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const DateMask = React.forwardRef<HTMLElement, any>(function DateMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Date}
      placeholder="mm/dd/yyyy"
      pattern="m/`d/`Y"
      format={(date: Date) => {
        let day = date.getDate().toString();
        let month = (date.getMonth() + 1).toString();
        const year = date.getFullYear().toString();

        if (parseInt(day, 10) < 10) {
          day = `0${day}`;
        }

        if (parseInt(month, 10) < 10) {
          month = `0${month}`;
        }

        return [month, day, year].join('/');
      }}
      parse={(str: string) => {
        const monthDayYear = str.split('/').map((v) => parseInt(v, 10));
        return new Date(monthDayYear[2], monthDayYear[0] - 1, monthDayYear[1]);
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      lazy={!props.value || Number.isNaN(parseInt(props.value, 10))}
      overwrite
      autofix
    />
  );
});

const NeedHelpModal: React.FC<NeedHelpModalProps> = ({
  title,
  content,
  handleClose,
  open,
  textButton,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-need-help-dialog-title"
      aria-describedby="alert-need-help-dialog-description"
      className={`cvc-customer-support__need-help-modal ${isCavcoHomes ? 'is-cavcohomes' : ''}`}
    >
      <DialogTitle disableTypography id="need-help-dialog-title">
        <h4>{title}</h4>
        <Button onClick={handleClose} className="close-button">
          &times;
        </Button>
      </DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: content }} />
      {isCavcoHomes && (
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {textButton}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const CustomerFormModal: React.FC<CustomerModalProps> = ({
  open,
  handleClose,
  title,
  description,
  textButton,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`cvc-customer-support__modal ${isCavcoHomes ? 'is-cavcohomes' : ''}`}
    >
      {isCavcoHomes && (
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon width={26} height={26} />
        </IconButton>
      )}
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getLabelTextByInputname = (name: string) => {
  const input = document.querySelector(`.cvc-customer-support__form input[name="${name}"]`);
  if (input) {
    return input
      ?.closest('.MuiFormControl-root')
      ?.querySelector('label')
      ?.textContent?.replaceAll('*', '')
      .trim();
  }
  return name;
};

const defaultErrorMessage = 'Something went wrong...';

export const CustomerSupportForm: React.FC<BrProps> = ({ component, page }: BrProps) => {
  const {
    labels,
    modalLabels,
    formConfig,
    states: statesConfig,
    caProvinces: caProvincesConfig,
    manufacturers: manufacturersRef,
    'cavco-homes-bc': cavcoHomesBcRef,
    'chariot-eagle-bc': chariotEagleBcRef,
    'destiny-homes-bc': destinyHomesBcRef,
    'fairmont-homes-bc': fairmontHomesBcRef,
    'fleetwood-homes-bc': fleetwoodHomesBcRef,
    'friendship-homes-bc': friendshipHomesBcRef,
    'nationwide-homes-bc': nationwideHomesBcRef,
    'palm-harbor-bc': palmHarborBcRef,
    manufacturerNumberDoc,
  } = component.getModels<CustomerSupportModel>();

  const [modal, setModal] = React.useState<CustomerModal>({
    open: false,
    title: '',
    description: '',
    textButton: '',
  });
  const [openHelpModal, setOpenHelpModal] = React.useState<boolean>(false);
  const [buildingCenters, setBuildingCenters] = React.useState<Dropdown[]>([]);
  const formConfigData = getPageContentData<ValueListData>(formConfig, page)?.items || [];
  const {
    title: manufacturerTitle,
    content: { value: manufacturerHtml },
  } = manufacturerNumberDoc
    ? getPageContentData<any>(manufacturerNumberDoc, page)
    : { title: '', content: { value: '' } };

  const labelsObject = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(labels, page)
  );
  const modalLabelsObject = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(modalLabels, page)
  );
  const { apiRoot, requestServiceUrl, webType, plantUid } = buildValueListToObject(formConfigData);

  const states = [
    ...buildResourceBundleToDropdown(getPageContentData<ResourceBundleData>(statesConfig, page)),
    ...buildResourceBundleToDropdown(
      getPageContentData<ResourceBundleData>(caProvincesConfig, page)
    ),
  ].sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

  const manufacturers = buildResourceBundleToDropdown(
    getPageContentData<ResourceBundleData>(manufacturersRef, page)
  );

  const handleError = (payload?: any) => {
    if (!payload) {
      setModal({
        open: true,
        title: modalLabelsObject.modalErrorTitle,
        description: modalLabelsObject.modalErrorText,
        textButton: modalLabelsObject.modalButtonText,
      });
      return;
    }
    const payloadErrorString = payload;
    const json = JSON.parse(
      payloadErrorString.slice(payloadErrorString.indexOf('{'), payloadErrorString.length)
    );
    const label = getLabelTextByInputname(json.name);
    const errorMessage = json.message
      ? json.message.replace(json.name, label)
      : defaultErrorMessage;
    setModal({
      open: true,
      title: modalLabelsObject.modalErrorTitle,
      description: errorMessage || modalLabelsObject.modalErrorText,
      textButton: modalLabelsObject.modalButtonText,
    });
  };

  const handleCLoseModal = () => {
    setModal({
      open: false,
      title: '',
      description: '',
      textButton: 'Ok',
    });
  };

  const getBuildingCenterValue = (currentVal: string) => {
    const currentBC = buildingCenters.filter((bc) => bc.displayName === currentVal);
    return currentBC[0]?.value;
  };
  const handleSubmit = (data: any, { resetForm, setSubmitting }: any) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    const formUrl = `${apiRoot}${requestServiceUrl}`;
    fetch(formUrl, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status !== 200) {
          handleError(response.payload);
          setSubmitting(false);
        } else {
          setModal({
            open: true,
            title: modalLabelsObject.modalTitle?.toString(),
            description: modalLabelsObject.modalText?.toString(),
            textButton: modalLabelsObject.modalButtonText?.toString(),
          });
          resetForm();
          setSubmitting(false);
        }
      })
      .catch(() => {
        handleError();
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
      webType,
      plantUid,
    },
    validationSchema: getValidationSchema(labelsObject),
    validateOnChange: false,
    onSubmit: (values, actions) => {
      const payload = { ...values };

      const parsedDate: Date | null = payload.purchasedDate
        ? new Date(payload.purchasedDate)
        : null;
      if (parsedDate?.toString() !== 'Invalid Date') {
        payload.purchasedDate = parsedDate?.toLocaleDateString('en-US') || '';
      }

      if (payload.mainPhoneNo) {
        payload.mainPhoneNo = payload.mainPhoneNo.split('-').join('');
      }

      if (payload.workPhoneNo) {
        payload.workPhoneNo = payload.workPhoneNo.split('-').join('');
      }

      if (payload.cellPhoneNo) {
        payload.cellPhoneNo = payload.cellPhoneNo.split('-').join('');
      }
      if (payload.buildingCenter) {
        payload.plantUid = getBuildingCenterValue(payload.buildingCenter);
      }

      handleSubmit(payload, actions);
    },
  });

  const onChangeManufacturers = (e: any, newValue: string | null) => {
    const value = newValue?.toString()?.trim()?.toLowerCase();
    let buildingCenterRef: any;
    switch (value) {
      case 'cavco homes':
        buildingCenterRef = cavcoHomesBcRef;
        break;
      case 'chariot eagle':
        buildingCenterRef = chariotEagleBcRef;
        break;
      case 'destiny homes':
        buildingCenterRef = destinyHomesBcRef;
        break;
      case 'fairmont homes':
        buildingCenterRef = fairmontHomesBcRef;
        break;
      case 'fleetwood homes':
        buildingCenterRef = fleetwoodHomesBcRef;
        break;
      case 'friendship homes':
        buildingCenterRef = friendshipHomesBcRef;
        break;
      case 'nationwide homes':
        buildingCenterRef = nationwideHomesBcRef;
        break;
      case 'palm harbor homes':
        buildingCenterRef = palmHarborBcRef;
        break;
      default:
        buildingCenterRef = null;
    }
    if (!buildingCenterRef) {
      setBuildingCenters([]);
    }
    setBuildingCenters(
      buildDocumentToDropdown(getPageContentData<ResourceBundleData>(buildingCenterRef, page))
    );
    formik.setFieldValue('manufacturer', newValue);
  };

  const openHelpSerialNumberModal = () => {
    setOpenHelpModal(true);
  };

  const handleCLoseHelpSerialNumberModal = () => {
    setOpenHelpModal(false);
  };

  React.useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    if (isSubmitting && !isValidating && Object.keys(errors).length) {
      const keys = Object.keys(initialFormValues);
      const errorKeys = Object.keys(errors);
      for (let i = 0; i < keys.length; i += 1) {
        if (errorKeys.includes(keys[i])) {
          scroller.scrollTo('CustomerSupportForm', {
            duration: 1000,
            smooth: true,
            offset: -150,
          });
          break;
        }
      }
    }
  }, [formik, formik.isSubmitting, formik.isValidating]);

  return (
    <div className="cvc-customer-support">
      <Container>
        <header className="cvc-customer-support__header">
          <h2>{labelsObject.form_title}</h2>
          <p className="body1">{labelsObject.form_subheading}</p>
          <p className="copy">{labelsObject.required_text}</p>
        </header>
        <form
          name="CustomerSupportForm"
          className="cvc-customer-support__form"
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <h5 className="contact-information">{labelsObject.contact_information_text}</h5>
          <Row>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="firstName"
                label={labelsObject.firstName}
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              />
              {formik.errors.firstName && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.firstName}
                </p>
              )}
            </Col>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="lastName"
                label={labelsObject.lastName}
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              />
              {formik.errors.lastName && (
                <p className="cvc-customer-support__textfield-errormsg">{formik.errors.lastName}</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                className="cvc-customer-support__input"
                required
                name="streetAddress"
                label={labelsObject.streetAddress}
                fullWidth
                value={formik.values.streetAddress}
                onChange={formik.handleChange}
                error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
              />
              {formik.errors.streetAddress && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.streetAddress}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="city"
                label={labelsObject.city}
                fullWidth
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
              />
              {formik.errors.city && (
                <p className="cvc-customer-support__textfield-errormsg">{formik.errors.city}</p>
              )}
            </Col>
            <Col md={2} xs={5}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="state"
                label={labelsObject.state}
                fullWidth
                value={formik.values.state}
                onChange={formik.handleChange}
                select
                SelectProps={{
                  IconComponent: ArrowDropdown,
                }}
                error={formik.touched.state && Boolean(formik.errors.state)}
              >
                {states.map(({ value: displayName, displayName: value }) => (
                  <MenuItem key={`option_${value}`} value={value}>
                    {displayName}
                  </MenuItem>
                ))}
              </TextField>
              {formik.errors.state && (
                <p className="cvc-customer-support__textfield-errormsg">{formik.errors.state}</p>
              )}
            </Col>
            <Col md={4} xs={7}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="zipCode"
                label={labelsObject.zip}
                fullWidth
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              />
              {formik.errors.zipCode && (
                <p className="cvc-customer-support__textfield-errormsg">{formik.errors.zipCode}</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="emailAddress"
                label={labelsObject.email}
                fullWidth
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
              />
              {formik.errors.emailAddress && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.emailAddress}
                </p>
              )}
            </Col>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="mainPhoneNo"
                label={labelsObject.mainPhoneNumber}
                fullWidth
                value={formik.values.mainPhoneNo}
                onChange={formik.handleChange}
                error={formik.touched.mainPhoneNo && Boolean(formik.errors.mainPhoneNo)}
                InputProps={{ inputComponent: PhoneNumberMask }}
              />
              {formik.errors.mainPhoneNo && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.mainPhoneNo}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                name="cellPhoneNo"
                label={labelsObject.cellPhone}
                fullWidth
                value={formik.values.cellPhoneNo}
                onChange={formik.handleChange}
                InputProps={{ inputComponent: PhoneNumberMask }}
              />
            </Col>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                name="workPhoneNo"
                label={labelsObject.workPhone}
                fullWidth
                value={formik.values.workPhoneNo}
                onChange={formik.handleChange}
                InputProps={{ inputComponent: PhoneNumberMask }}
              />
            </Col>
          </Row>
          <h5 className="home-information">{labelsObject.home_information_text}</h5>
          <p className="home-information-copy">{labelsObject.home_information_text_sh}</p>
          <Row>
            <Col md={6} xs={12}>
              <Autocomplete
                className="cvc-customer-support__input autocomplete"
                fullWidth
                id="city"
                options={manufacturers.map((v) => v.displayName)}
                popupIcon={<SearchIcon />}
                onChange={(e, newValue) => onChangeManufacturers(e, newValue)}
                value={formik.values.manufacturer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="manufacturer"
                    label={labelsObject.manufacturer}
                    error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
                  />
                )}
              />
              {formik.errors.manufacturer && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.manufacturer}
                </p>
              )}
            </Col>
            <Col md={6} xs={12}>
              <Autocomplete
                className="cvc-customer-support__input autocomplete"
                fullWidth
                id="buildingCenter"
                options={buildingCenters.map((v) => v.displayName)}
                popupIcon={<SearchIcon />}
                onChange={(e, v) => formik.setFieldValue('buildingCenter', v)}
                value={formik.values.buildingCenter}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="buildingCenter"
                    label={labelsObject.buildingCenter}
                    error={formik.touched.buildingCenter && Boolean(formik.errors.buildingCenter)}
                  />
                )}
              />
              {formik.errors.buildingCenter && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.buildingCenter}
                </p>
              )}
            </Col>
          </Row>
          <Row className="margin-bottom-0">
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="serialNumber"
                label={labelsObject.serialNumber}
                fullWidth
                value={formik.values.serialNumber}
                onChange={formik.handleChange}
                FormHelperTextProps={{ className: 'help-serial-number' }}
                error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
              />
              {formik.errors.serialNumber && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.serialNumber}
                </p>
              )}
              <Button onClick={openHelpSerialNumberModal} className="help-serial-number">
                {labelsObject.serial_number_helper_text}
              </Button>
            </Col>
            <Col md={6} xs={12}>
              <TextField
                className="cvc-customer-support__input"
                required
                name="purchasedDate"
                label={labelsObject.dateOfPurchase}
                fullWidth
                value={formik.values.purchasedDate}
                onChange={formik.handleChange}
                error={formik.touched.purchasedDate && Boolean(formik.errors.purchasedDate)}
                InputProps={{ inputComponent: DateMask }}
              />
              {formik.errors.purchasedDate && (
                <p className="cvc-customer-support__textfield-errormsg">
                  {formik.errors.purchasedDate}
                </p>
              )}
            </Col>
          </Row>
          <h5 className="comments">{labelsObject.comments}</h5>
          <Row>
            <Col>
              <TextField
                className="cvc-customer-support__input no-floating-label"
                name="comments"
                label={labelsObject.comments_ph?.toString()}
                fullWidth
                multiline
                minRows={4}
                value={formik.values.comments}
                onChange={formik.handleChange}
                required
                error={formik.touched.comments && Boolean(formik.errors.comments)}
              />
              {formik.errors.comments && (
                <p className="cvc-customer-support__textfield-errormsg">{formik.errors.comments}</p>
              )}
            </Col>
          </Row>
          <Button
            type="submit"
            className="cvc-customer-support__button"
            disabled={formik.isSubmitting}
          >
            {labelsObject.submit}
          </Button>
        </form>
      </Container>
      {modal.open && <CustomerFormModal {...modal} handleClose={handleCLoseModal} />}
      {openHelpModal && (
        <NeedHelpModal
          open={openHelpModal}
          handleClose={handleCLoseHelpSerialNumberModal}
          title={manufacturerTitle}
          content={manufacturerHtml}
          textButton={modalLabelsObject.modalButtonText}
        />
      )}
    </div>
  );
};
