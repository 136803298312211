import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { Content } from '@bloomreach/spa-sdk';
import { ResolverLink } from 'components/shared';
import { ReactComponent as RightArrowIconOnCircle } from '../../assets/images/right-arrow-on-transparent-circle-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right-arrow-icon.svg';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import './Album.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { displayYoutubeVideo, display3DTour, getUrlFromImage } from '../utils';
import { CavcoAlbum } from './CavcoAlbum';

function displayTitle(title: string, albumBackPageUrl: string, isBackedPage: boolean) {
  if (!title) return '';

  const showLink = !window.location.href.endsWith(albumBackPageUrl);

  if (!isBackedPage && showLink) {
    return (
      <>
        <span className="cvc-album-pre-title">Album: </span>
        <ResolverLink href={albumBackPageUrl} className="cvc-album-title">
          {title}
        </ResolverLink>
      </>
    );
  }

  return (
    <>
      <span className="cvc-album-pre-title">Album: </span>
      <span className="cvc-album-title-span">{title}</span>
    </>
  );
}

function displayDescription(props: BrProps, description: any) {
  if (!description) return '';

  return (
    <div className="cvc-album-description">
      <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(description.value) }} />
    </div>
  );
}

function displayLink(albumBackPageUrl: string) {
  const showLink = !window.location.href.endsWith(albumBackPageUrl);
  if (showLink) {
    return (
      <div className="cvc-album-view-album-link-container">
        <ResolverLink className="cvc-album-view-album-link" href={albumBackPageUrl}>
          View Album
          <RightArrowIconOnCircle className="cvc-album-view-album-link-icon" />
        </ResolverLink>
      </div>
    );
  }
}

export function getImageSource(imageGalleryName: string, item: any, imgContent: any) {
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';
  let originalImgPath = '';

  if (imgContent) {
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
    originalImgPath = getUrlFromImage(imgContent);
  } else if (item.ximage) {
    mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
    originalImgPath = extraLargeImgPath;
  }

  return (
    <picture key={`${imageGalleryName}-${item.id}`}>
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={originalImgPath} />
      {extraLargeImgPath && (
        <source
          media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
          srcSet={extraLargeImgPath}
        />
      )}
      {largeImgPath && (
        <source
          media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
          srcSet={largeImgPath}
        />
      )}
      <img alt={item.imageAltText} src={mediumImgPath} />
    </picture>
  );
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  mediaType: string,
  item: any,
  imgContent: any,
  index: any,
  handleShowMediaViewer = () => {},
  setIndexItemSelected: any
) {
  const handleMediaItemClick = () => {
    setIndexItemSelected(Number(index));
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(Number(index));
      handleShowMediaViewer();
    }
  };

  switch (mediaType) {
    case ALBUM_MEDIA_TYPES.IMAGE:
      return (
        <div
          role="presentation"
          key={`${imageGalleryName}-image-${item.id}-${index}`}
          className="cvc-album-item cvc-album-media-item"
          onClick={() => handleMediaItemClick()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
        >
          {imgContent || item.ximage ? getImageSource(imageGalleryName, item, imgContent) : ''}
        </div>
      );
    case ALBUM_MEDIA_TYPES.VIDEO:
      return item.videoURL !== '' ? (
        <div
          role="presentation"
          key={`${imageGalleryName}-video-container-${item.id}`}
          className="cvc-album-item cvc-album-media-item-video-container"
          onClick={() => handleMediaItemClick()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
        >
          <div
            key={`${imageGalleryName}-album-media-item-video-${item.id}`}
            className="cvc-album-media-item"
          >
            {item.videoURL ? displayYoutubeVideo(item) : ''}
          </div>
          <div
            key={`${imageGalleryName}-album-media-item-video-link-${item.id}`}
            className="cvc-album-media-item-video-link"
            tabIndex={-1}
          >
            <VideoIcon
              key={`${imageGalleryName}-album-media-item-video-icon-${item.id}`}
              className="cvc-album-media-item-video-icon"
            />
          </div>
        </div>
      ) : (
        ''
      );
    case ALBUM_MEDIA_TYPES.VIRTUAL_TOUR:
    case ALBUM_MEDIA_TYPES.PANATOUR:
      return item.matterportURL !== '' ? (
        <div
          role="presentation"
          key={`${imageGalleryName}-virtual-${item.id}`}
          className="cvc-album-item cvc-album-media-item-virtual-container"
          onClick={() => handleMediaItemClick()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
        >
          <div className="cvc-album-media-item">
            {item.matterportURL ? display3DTour(item) : ''}
          </div>
          <div
            key={`${imageGalleryName}-album-media-item-virtual-link-${item.id}`}
            className="cvc-album-media-item-virtual-link"
            tabIndex={-1}
          >
            <div className="cvc-album-media-item-virtual-text">3D</div>
          </div>
        </div>
      ) : (
        ''
      );
    default:
      break;
  }
}

function displayMediaAssets(
  props: BrProps,
  isBackedPage: boolean,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  maxItems: number,
  imageGalleryLabels: any,
  windowDimensions: any,
  albumBackPageUrl: string,
  handleShowMediaViewer = () => {},
  setIndexItemSelected: any
) {
  // if (mediaAssetItems.find((item) => item.image || item.ximage) === undefined) return '';
  let moreTextValue = 'more';
  if (windowDimensions.isMobile) {
    moreTextValue = imageGalleryLabels && imageGalleryLabels.more_mobile;
  }
  if (windowDimensions.isTablet) {
    moreTextValue = imageGalleryLabels && imageGalleryLabels.more_tablet;
  }
  if (
    windowDimensions.isDesktop ||
    windowDimensions.isDesktopBig ||
    windowDimensions.isDesktopExtraBig
  ) {
    moreTextValue = imageGalleryLabels && imageGalleryLabels.more_desktop;
  }

  const items = [...new Array(maxItems)].map(
    (item, index) =>
      mediaAssetItems[index] || {
        id: `${imageGalleryName}-empty-${index}`,
        key: `${imageGalleryName}-empty-${index}`,
      }
  );

  return (
    <>
      {items.map((item: any, index) => {
        const imgContent: any = item && props.page.getContent(item.image);
        const showLink = !window.location.href.endsWith(albumBackPageUrl);

        if (item && !item.name) return null;

        let lastClass = '';
        if (!isBackedPage && showLink) {
          switch (index) {
            case 5:
              if (
                (windowDimensions.isMobile || windowDimensions.isTablet) &&
                mediaAssetItems.length > 6
              ) {
                lastClass = 'cvc-album-last-media-item-container--mobile';
              }
              break;
            case 11:
              if (
                (windowDimensions.isDesktop ||
                  windowDimensions.isDesktopBig ||
                  windowDimensions.isDesktopExtraBig) &&
                mediaAssetItems.length > 12
              ) {
                lastClass = 'cvc-album-last-media-item-container--desktop';
              }
              break;
            default:
              break;
          }
        }

        return lastClass === '' ? (
          <React.Fragment
            key={`${imageGalleryName}-album-media-item-container-${index.toString()}`}
          >
            {displayAlbumMediaItem(
              imageGalleryName,
              mediaType,
              item,
              imgContent,
              index,
              handleShowMediaViewer,
              setIndexItemSelected
            )}
          </React.Fragment>
        ) : (
          <div
            key={`${imageGalleryName}-album-last-media-item-container-${item.id}`}
            style={{ zIndex: items.length - index }}
            className={`cvc-album-last-media-item-container ${lastClass}`}
          >
            <div
              className="cvc-album-media-item"
              key={`${imageGalleryName}-album-last-media-item-${item.id}`}
            >
              {imgContent || item.ximage ? getImageSource(imageGalleryName, item, imgContent) : ''}
            </div>
            <ResolverLink
              className="cvc-album-last-media-item-text-container"
              href={albumBackPageUrl}
              key={`${imageGalleryName}-album-last-media-item-text-container-${item.id}`}
            >
              <div
                key={`${imageGalleryName}-album-last-media-item-text-${item.id}`}
                className="cvc-album-last-media-item-text text-capitalize"
              >
                {moreTextValue}
                <RightArrowIcon
                  className="cvc-album-last-media-item-text-icon"
                  key={`${imageGalleryName}-album-last-media-item-text-icon-${item.id}`}
                />
              </div>
            </ResolverLink>
          </div>
        );
      })}
    </>
  );
}

function showAlbumDoc(
  props: BrProps,
  document: Content,
  componentParameterMap: any,
  imageGalleryConfig: any,
  imageGalleryLabels: any,
  handleCloseMediaViewer: any,
  handleShowMediaViewer: any,
  showMediaViewer: any,
  windowDimensions: any,
  indexItemSelected: any,
  setIndexItemSelected: any,
  spinnerConfig: any
) {
  const { id, title, thisAlbumUrl, description, mediaType, mediaAsset } =
    document.getData<AlbumData>();

  const documentValue = componentParameterMap && componentParameterMap.document;
  const documentValueSplitted = documentValue && documentValue.split('/');

  const backedPagePath =
    documentValueSplitted &&
    `/${documentValueSplitted.slice(2, documentValueSplitted.length).join('/')}`;
  const imageGalleryName = props.component.getName();
  const isBackedPage = imageGalleryName === 'content';
  let albumBackPageUrl = documentValueSplitted && window.location.href + backedPagePath;
  if (documentValueSplitted && document.getUrl()) {
    albumBackPageUrl = document.getUrl()!.toString();
  }
  if (albumBackPageUrl === undefined && window.location.href.includes('gallery')) {
    albumBackPageUrl = document.getUrl() ? document.getUrl()!.toString() : thisAlbumUrl;
  }

  const showLink = !window.location.href.endsWith(albumBackPageUrl);

  const albumClasses = classNames({
    'cvc-album': true,
    'is-backed-page': isBackedPage,
    'show-link': showLink,
    container: true,
  });

  let defaultMaxItems = 6;
  if (
    windowDimensions.isDesktop ||
    windowDimensions.isDesktopBig ||
    windowDimensions.isDesktopExtraBig
  ) {
    defaultMaxItems = 12;
  }

  const maxItems =
    (isBackedPage && mediaAsset && mediaAsset.length > 0) || !showLink
      ? mediaAsset.length + 6
      : defaultMaxItems;

  const spinnerColorInDarkBackground = spinnerConfig && spinnerConfig.spinner_color_dark_background;

  return (
    <div
      id={`${imageGalleryName}-${id}`}
      className={albumClasses}
      key={`${imageGalleryName}-${id}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={mediaAsset}
        albumName={title}
        albumLink={albumBackPageUrl}
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerColorInDarkBackground}
      />
      <h2 className="cvc-album-title-container">
        {displayTitle(title, albumBackPageUrl, isBackedPage)}
      </h2>
      <Row className="cvc-album-details-container no-margin">
        <Col className="cvc-album-content-container-column no-padding">
          <div className="cvc-album-content-container">
            {displayDescription(props, description)}
            {displayLink(albumBackPageUrl)}
          </div>
        </Col>
        <Col className="cvc-album-items-container-column no-padding">
          <div className="cvc-album-items-container">
            {displayMediaAssets(
              props,
              isBackedPage,
              imageGalleryName,
              mediaType,
              mediaAsset,
              maxItems,
              imageGalleryLabels,
              windowDimensions,
              albumBackPageUrl,
              handleShowMediaViewer,
              setIndexItemSelected
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export function AlbumFiltered({ props, index }: { props: BrProps; index: any }) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();
  const {
    documents: documentRef,
    componentParameterMap,
    imageGalleryConfig,
    imageGalleryLabels,
    spinnerConfig,
  } = props.component.getModels();

  let document;
  if (index === undefined || Number.isNaN(index)) {
    document = documentRef && props.page.getContent(documentRef);
  } else {
    document = documentRef[index] && props.page.getContent(documentRef[index]);
  }
  if (!document) {
    return null;
  }
  return showAlbumDoc(
    props,
    document,
    componentParameterMap,
    imageGalleryConfig,
    imageGalleryLabels,
    handleCloseMediaViewer,
    handleShowMediaViewer,
    showMediaViewer,
    windowDimensions,
    indexItemSelected,
    setIndexItemSelected,
    spinnerConfig
  );
}

export function Album(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { name: pageVariant } = props.page.getChannelParameters();
  const isCavcoHomesVariant = pageVariant === 'cavcohomes';

  const {
    documents,
    document: documentRef,
    componentParameterMap,
    imageGalleryConfig,
    imageGalleryLabels,
    spinnerConfig,
  } = props.component.getModels();

  let document = documentRef && props.page.getContent(documentRef);
  if (documents && !document) {
    document = props.page.getContent(documents[0]);
  }

  if (!document) {
    return null;
  }

  return isCavcoHomesVariant
    ? CavcoAlbum(props)
    : showAlbumDoc(
        props,
        document,
        componentParameterMap,
        imageGalleryConfig,
        imageGalleryLabels,
        handleCloseMediaViewer,
        handleShowMediaViewer,
        showMediaViewer,
        windowDimensions,
        indexItemSelected,
        setIndexItemSelected,
        spinnerConfig
      );
}
