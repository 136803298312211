import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrProps } from '@bloomreach/react-sdk';
import {
  Button,
  Slider,
  Step,
  StepButton,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { checkForNessus } from 'components/utils';
import { ReactComponent as DoneCheck } from '../../assets/images/cavcohomes/done-check.svg';
import { ReactComponent as LeftArrow } from '../../assets/images/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../assets/images/arrow-right.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon-30.svg';

import './Quiz.scss';
import AutocompleteComp from '../Autocomplete/Autocomplete';
import useGeocodeSearchDataHook from '../hooks/GeocodeSearchData.hook';
import { AutosuggestResponse } from '../FloorPlanResultsCH/FloorPlanResults.interface';

export const Quiz = ({ component, page }: BrProps) => {
  const history = useHistory();
  const ref = useRef<any>();
  const { document: documentRef, configuration } = component.getModels();
  const googleMapConfig: any = page?.getContent(configuration)?.getData();
  const configMap: any = { apiKey: '', mapEndpoint: '' };
  const STARTING_PRICE = '0.00';

  const { setGeocodeRequest, geocodeResponse } = useGeocodeSearchDataHook();

  googleMapConfig.keys.forEach((element: any, index: number) => {
    configMap[element] = googleMapConfig.messages[index];
  });
  const quizDocument = page.getContent(documentRef);
  const quizDocumentData = quizDocument?.getData<any>();
  const questionList = quizDocumentData?.quizCompound || [];
  const getSteps = (data: any) => {
    const stepLabels: any = [];
    const stepContent: any = [];
    const stepKey: any = [];

    data?.forEach((item: any) => {
      stepKey.push(item.tabName.replace(' ', '-').toLowerCase());
      stepLabels.push(item.tabName);
      stepContent.push(item);
    });
    return {
      keys: stepKey,
      labels: stepLabels,
      content: stepContent,
    };
  };
  const steps = getSteps(questionList);
  const [activeStep, setActiveStep] = useState(0);
  const [active, setActive] = useState<any>(
    Number(steps?.content[activeStep]?.response?.responseMinValue)
  );
  const [min, setMin] = useState<any>({
    value: Number(steps?.content[0]?.response?.responseMinValue),
    label: steps?.content[0]?.response?.responseMinLabel,
  });
  const [max, setMax] = useState<any>({
    value: Number(steps?.content[0]?.response?.responseMaxValue),
    label: steps?.content[0]?.response?.responseMaxLabel,
  });
  const [completedSteps, setCompletedSteps] = useState<Record<number, boolean>>({});

  const handleNext = (scrollOffset: any) => {
    const value = active > 0 ? active : min.value;
    sessionStorage.setItem(steps.keys[activeStep], value);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const prevValue = sessionStorage.getItem(steps.keys[activeStep + 1]);

    setActive(prevValue || '');
    ref.current.scrollLeft += scrollOffset;
  };

  const handleBack = (scrollOffset: any) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const prevValue = sessionStorage.getItem(steps.keys[activeStep - 1]);
    setActive(prevValue);
    ref.current.scrollLeft += scrollOffset;
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const getSetValue = (step: any) => {
    const storedVal: any = sessionStorage.getItem(steps.keys[step]);
    return storedVal;
  };
  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const newVal = newValue as number;
    setActive(newVal);
  };

  const handleComplete = () => {
    const newCompleted: any = completedSteps;
    newCompleted[activeStep] = true;
    setCompletedSteps(newCompleted);
    handleNext(100);
  };

  useEffect(() => {
    if (geocodeResponse && !geocodeResponse.isError) {
      setActive(geocodeResponse.address);
    }
  }, [geocodeResponse]);

  const handleSubmit = () => {
    sessionStorage.setItem(steps.keys[activeStep], active);

    const { address, country, lat, lng } = geocodeResponse;
    const beds = sessionStorage.getItem('bedrooms');
    const max_price = `${STARTING_PRICE},${sessionStorage.getItem('price')}000`;
    const sqft = [
      sessionStorage.getItem('square-feet'),
      steps?.content[0]?.response?.responseMaxValue,
    ];

    if (active && beds && sqft && address && max_price) {
      if (lat && lng) {
        history.push(
          `our-homes/${country}?latitude=${lat}&longitude=${lng}&name=${address}&number_of_bedrooms=%5B${beds}%5D&square_foot=%5B${sqft}%5D&starting_price=%5B${max_price}%5D`
        );
      }
    }
  };
  const StepIcon = (props: StepIconProps) => {
    const { completed, icon } = props;

    return completed ? (
      <DoneCheck className="StepIcon-completedIcon" />
    ) : (
      <div className="StepIcon-circle">
        <span>{icon}</span>
      </div>
    );
  };

  useEffect(() => {
    const searchInput: any = document.querySelector('.cavcohomes-quiz-component__zipcode-input')!;
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
        handleSubmit();
      }
    };
    if (searchInput) {
      if (searchInput.value && !geocodeResponse) {
        setGeocodeRequest(searchInput.value);
      }
      searchInput.addEventListener('keydown', handleKeyDown);
    }
    return () => searchInput?.removeEventListener('keydown', handleKeyDown);
  });

  useEffect(() => {
    if (documentRef && page) {
      setMin({
        value: Number(steps?.content[activeStep]?.response?.responseMinValue),
        label: steps?.content[activeStep]?.response?.responseMinLabel,
      });
      setMax({
        value: Number(steps?.content[activeStep]?.response?.responseMaxValue),
        label: steps?.content[activeStep]?.response?.responseMaxLabel,
      });
      const prevValue = sessionStorage.getItem(steps.keys[activeStep]);
      setActive(Number(prevValue) || prevValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, documentRef, page]);

  if (!documentRef) {
    return null;
  }

  return (
    <div className="cavcohomes-quiz-component">
      <Typography align="center" component="h3" className="cavcohomes-quiz-component__title">
        {quizDocumentData.groupTitle}
      </Typography>
      <div className="cavcohomes-quiz-component__stepper">
        <div className="cavcohomes-quiz-component__stepper--overlay" />

        <Stepper nonLinear activeStep={activeStep} alternativeLabel ref={ref}>
          {steps.labels.map((label: any, index: any) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: ReactNode } = {};
            const stepClassName =
              activeStep === index || completedSteps[index]
                ? 'cavcohomes-quiz-component__stepper--step active'
                : 'cavcohomes-quiz-component__stepper--step';
            return (
              <Step key={label} {...stepProps} className={stepClassName}>
                <StepButton
                  disabled={!completedSteps[index] && activeStep !== index}
                  onClick={() => handleStep(index)}
                  completed={completedSteps[index]}
                >
                  <StepLabel StepIconComponent={StepIcon} {...labelProps}>
                    {label}
                    <p className="step-answer">
                      {label === 'Price' && index < activeStep && (
                        <span>${getSetValue(index)}K</span>
                      )}
                      {label !== 'Price' && index < activeStep && (
                        <span>{getSetValue(index) || min.label}</span>
                      )}
                    </p>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div className="cavcohomes-quiz-component__stepper--overlay--left" />

        {activeStep === steps.labels.length - 1 ? (
          <div className="cavcohomes-quiz-component__stepper--wrapper">
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              component="h4"
              className="cavcohomes-quiz-component__stepper--question"
            >
              {steps?.content[activeStep]?.tabQuestionText}
              <span>
                <b> {active || '(enter location)'}</b>
              </span>
            </Typography>
            <div className="cavcohomes-quiz-component__zipcode">
              <SearchIcon className="cvc-search-tab-hero-input-icon" onClick={handleSubmit} />
              <AutocompleteComp
                initialValue={getSetValue(activeStep) || ''}
                apiKey={checkForNessus(configMap.apiKey)}
                placeholder="Enter ZIP code"
                geocodeEndpoint={configMap.mapEndpoint}
                onClick={(data: AutosuggestResponse) => setGeocodeRequest(data.description)}
                inputClassName="cavcohomes-quiz-component__zipcode-input"
              />
            </div>
          </div>
        ) : (
          <div className="cavcohomes-quiz-component__stepper--slider-wrapper">
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              component="h4"
              className="cavcohomes-quiz-component__stepper--question"
            >
              {steps?.content[activeStep].tabQuestionText}
              {activeStep === 0 && (
                <span>
                  <b> {active || min.label}</b> square feet
                </span>
              )}
              {activeStep === 1 && (
                <span>
                  <b> {active || min.label}</b> bedrooms
                </span>
              )}
              {activeStep === 2 && (
                <span>
                  <b>{` $${active || min.value},000 `}</b>
                  max
                </span>
              )}
            </Typography>
            <div
              className={`cavcohomes-quiz-component__stepper--slider step-${steps.keys[activeStep]}`}
            >
              <div className="cavcohomes-quiz-component__stepper--slider-markLabels">
                <span>{min.label}</span>
                <span>{max.label}</span>
              </div>
              <Slider
                // eslint-disable-next-line no-nested-ternary
                step={max.value > 10000 ? 10000 : max.value < 10 ? 1 : 50}
                name={steps.labels[activeStep]}
                value={Number(active)}
                onChange={handleSliderChange}
                min={min.value}
                max={max.value}
                marks
              />
            </div>
          </div>
        )}
        <div className="cavcohomes-quiz-component__stepper--navigation">
          <Button
            startIcon={<LeftArrow />}
            disabled={activeStep === 0}
            onClick={() => handleBack(-100)}
            className="cavcohomes-quiz-component__stepper--navigation-back"
          >
            Previous
          </Button>

          <Button
            endIcon={activeStep !== steps.labels.length - 1 && <RightArrow />}
            variant={activeStep === steps.labels.length - 1 ? 'contained' : 'outlined'}
            color="primary"
            onClick={() =>
              activeStep !== steps.labels.length - 1 ? handleComplete() : handleSubmit()
            }
            className={
              activeStep === steps.labels.length - 1
                ? 'results cavcohomes-quiz-component__stepper--navigation-next btn btn-cta'
                : 'cavcohomes-quiz-component__stepper--navigation-next btn btn-punchout-large'
            }
          >
            {activeStep !== steps.labels.length - 1 ? 'Next' : 'See Results'}
          </Button>
        </div>
      </div>
    </div>
  );
};
