import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { Formik, Form, Field } from 'formik';
import { scroller, Element } from 'react-scroll';
import classNames from 'classnames';
import { ResolverLink } from 'components/shared';
import { Spinner } from 'components/shared/Spinner';
import { RETAILER_TYPE } from '../enums';
import { getBuildingCenterFromHeader, TagManager } from '../utils';
import './RetailerContact.scss';

let isSearching = false;

function RetailerConfirmationModal(props: any) {
  const classnames = props.isError
    ? classNames('modal-error-title', 'mb-3')
    : classNames('modal-title', 'mb-3');

  return (
    <Modal className="cvc-fp-request-info-modal text-center" show={props.show} onHide={props.close}>
      <Modal.Header closeButton />
      <Modal.Body className="mt-4 px-5">
        <h2 className={classnames}>{props.title}</h2>
        <p>{props.content}</p>
        <button
          type="button"
          className="cvc-form-button btn btn-primary px-4"
          onClick={props.close}
        >
          {props.buttonText}
        </button>
      </Modal.Body>
    </Modal>
  );
}

const setIsSearching = (value: any) => {
  isSearching = value;
};

export function RetailerContact({ brProps }: { brProps: BrProps }) {
  const pageLocation = useLocation();
  const {
    formModalLabel: modalLbl,
    retailerFormConfig: config,
    usStates,
    caProvinces,
    nameTitles,
    componentParameterMap,
    spinnerConfig,
    buildingcenterlist,
    GoogleReCaptchaWebConfig: reCaptchaConfig,
  } = brProps.component.getModels();

  const isReCaptchaEnabled =
    reCaptchaConfig &&
    reCaptchaConfig.retailerForm &&
    (reCaptchaConfig.retailerForm.toUpperCase() === 'ON' ||
      reCaptchaConfig.retailerForm.toUpperCase() === 'ENABLED' ||
      reCaptchaConfig.retailerForm.toUpperCase() === 'TRUE');

  // captcha
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState<any>('');

  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);
  const activeDealership = {
    yes: '1',
    no: '0',
  };
  // Validation
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [title, setTitle] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [location, setLocation] = useState(false);
  const [plantUidValidation, setPlantUidValidation] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '', isError: false });
  const [activeDealershipValue, setActiveDealershipValue] = useState(activeDealership.yes);
  const [dealerLocationClassNameCol, setDealerLocationClassNameCol] = useState(
    'cvc-form-group cvc-form-required'
  );
  const [dealerLocationRequired, setDealerLocationRequired] = useState(true);

  // Input values
  const [titleVal, setTitleVal] = useState('');
  const [stateVal, setStateVal] = useState('');
  const [websiteVal, setWebsiteVal] = useState('');

  const { page } = brProps;
  const bcDocument = getBuildingCenterFromHeader(page);
  const bcName = [];
  if (bcDocument) {
    bcName[0] = bcDocument?.name;
  }
  const retailerName = bcName.length
    ? bcName
    : pageLocation.pathname.split('/').filter((x: any) => Boolean(x));

  const plantUidOptions = buildingcenterlist ? Object.entries(buildingcenterlist) : [];
  const valueOption = retailerName
    ? plantUidOptions.filter((x: any) =>
        retailerName.find((y: string) =>
          x[1].toLowerCase().includes(y.toLowerCase().replace('-', ' '))
        )
      )
    : false;
  const [plantUidVal, setPlantUidVal] = useState(
    valueOption && valueOption.length > 0 ? valueOption[0][0] : ''
  );

  function handleTitleChange(event: any) {
    setTitleVal(event.target.value);
  }

  function handleStateChange(event: any) {
    setStateVal(event.target.value);
  }

  function handlePlantUidChange(event: any) {
    setPlantUidVal(event.target.value);
  }

  function handleWebsiteChange(event: any) {
    setWebsiteVal(event.target.value);
  }

  function updateActiveDealership(value: string) {
    setActiveDealershipValue(value);
    if (value === activeDealership.no) {
      setDealerLocationClassNameCol('cvc-form-group');
      setDealerLocationRequired(false);
    } else {
      setDealerLocationClassNameCol('cvc-form-group cvc-form-required');
      setDealerLocationRequired(true);
    }
  }

  function validateFields() {
    setIsSearching(true);

    setTitle(false);
    setFirstName(false);
    setLastName(false);
    setEmail(false);
    setPhone(false);
    setLocation(false);
    setPlantUidValidation(false);

    scroller.scrollTo('RetailerForm', {
      duration: 1000,
      smooth: true,
      offset: -150,
    });

    setIsSearching(false);
  }

  let { retailerFormLabels: lbl } = brProps.component.getModels();
  if (!lbl) {
    lbl = {};
  }

  // const {
  //   formModalLabel: modalLbl,
  //   retailerFormConfig: config,
  //   usStates,
  //   caProvinces,
  //   nameTitles,
  //   componentParameterMap,
  //   spinnerConfig,
  //   buildingcenterlist,
  // } = brProps.component.getModels();

  const getDefaultComments = (label: any, isParkVal: boolean) => {
    if (label) {
      if (isParkVal) {
        return label.commentsPH_park;
      }
      return label.commentsPH;
    }
    return '';
  };

  const { webType } = config;
  const formId = 'retailer-contact-form';
  const { variant2: retailerType } = componentParameterMap;
  const isParkVal = retailerType && retailerType === RETAILER_TYPE.PARKMODEL;
  const defaultComments = getDefaultComments(lbl, isParkVal);

  const titleOptions = nameTitles ? Object.entries(nameTitles) : [];
  titleOptions.sort();

  // const plantUidOptions = buildingcenterlist ? Object.entries(buildingcenterlist) : [];
  let stateOptions = { ...usStates, ...caProvinces };
  stateOptions = Object.entries(stateOptions);
  stateOptions.sort();

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  return (
    <div className="cvc-retailer-contact cvc-full-page-form">
      <Container>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            title: '',
            emailAddress: '',
            phoneNo: '',
            phone1: '',
            phone2: '',
            phone3: '',
            comments: defaultComments,
            locationCity: '',
            locationState: '',
            dealershipLocations: '',
            activeDealership: '1',
            websiteURL: '',
            webType: '',
            plantUid: '',
            captchaToken: '',
          }}
          onSubmit={async (values, { resetForm }) => {
            await setIsSearching(true);

            values.phoneNo = values.phone1 + values.phone2 + values.phone3;
            values.title = titleVal;
            values.locationState = stateVal;
            values.websiteURL = websiteVal;
            values.activeDealership = activeDealershipValue;
            values.webType = webType;
            values.plantUid = plantUidVal as string;
            values.captchaToken = captchaToken;

            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
            };
            const formUrl =
              config.apiRoot +
              (isParkVal ? config.requestServiceParkUrl : config.requestServiceUrl);
            //          console.log("formUrl", formUrl);
            fetch(formUrl, requestOptions)
              .then((response) => response.json())
              .then(async (data) => {
                console.log('responseData:', data); // eslint-disable-line

                // check for error
                if (data.status !== 200) {
                  const textLength = modalLbl.modalTextLength
                    ? parseInt(modalLbl.modalTextLength, 10)
                    : 50;
                  const errorMessage =
                    data.payload.length > textLength
                      ? `${data.payload.substr(0, textLength)}...`
                      : data.payload;
                  setModalContent({
                    title: modalLbl.modalErrorTitle,
                    text: errorMessage,
                    isError: true,
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'formError',
                      errorMessage,
                      formId,
                      pageHref: window.location.href,
                    },
                  });

                  await setIsSearching(false);
                  await openModal();
                } else {
                  setModalContent({
                    title: modalLbl.modalTitle,
                    text: modalLbl.modalText,
                    isError: false,
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'formSubmission',
                      formId,
                      pageHref: pageLocation.pathname,
                    },
                  });

                  await setIsSearching(false);
                  await openModal();

                  setStateVal('');
                  setPlantUidVal('');
                  setTitleVal('');
                  setWebsiteVal('');
                  resetForm();
                  const captchaRefCurrent: any = captchaRef.current;
                  if (captchaRefCurrent) captchaRefCurrent.reset();
                  setCaptchaToken('');
                }
              })
              .catch(async (error) => {
                console.log('error from the server:', error.message); // eslint-disable-line
                const textLength = modalLbl.modalTextLength
                  ? parseInt(modalLbl.modalTextLength, 10)
                  : 50;
                const errorMessage =
                  error.message.length > textLength
                    ? `${error.message.substr(0, textLength)}...`
                    : error.message;
                setModalContent({
                  title: modalLbl.modalErrorTitle,
                  text: errorMessage,
                  isError: true,
                });
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'formError',
                    errorMessage,
                    formId,
                    pageHref: pageLocation.pathname,
                  },
                });

                await setIsSearching(false);
                await openModal();
              });

            console.log(values); // eslint-disable-line
          }}
        >
          {({ handleSubmit }) => (
            <Element name="RetailerForm">
              <Form
                className="cvc-retailer-contact-form cvc-form"
                id={formId}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Row className="justify-content-between">
                  <Col className="cvc-retailer-contact-info" md={5}>
                    <h2 className="cvc-form-section-title">{lbl.titleInfo}</h2>

                    <div className="form-row">
                      <Col className="cvc-form-group align-self-end" xs={3}>
                        <label htmlFor="title" className={title ? 'cvc-form-error' : ''}>
                          {lbl.title}
                        </label>
                        <Field
                          id="title"
                          as="select"
                          name="title"
                          aria-label="Location state"
                          type="text"
                          inputMode="text"
                          autoComplete="honorific-prefix"
                          value={titleVal}
                          onChange={handleTitleChange}
                          onInvalid={() => setTitle(true)}
                        >
                          <option value="">{lbl.title}</option>
                          {titleOptions.map((option: any) => {
                            return (
                              <option key={option[1]} value={option[1]}>
                                {option[0]}
                              </option>
                            );
                          })}
                        </Field>
                      </Col>

                      <Col className="cvc-form-group cvc-form-required">
                        <label htmlFor="firstName" className={firstName ? 'cvc-form-error' : ''}>
                          {lbl.firstName}
                        </label>
                        <Field
                          id="firstName"
                          name="firstName"
                          type="text"
                          inputMode="text"
                          autoComplete="given-name"
                          pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                          required
                          onInvalid={() => setFirstName(true)}
                        />
                      </Col>

                      <Col className="cvc-form-group cvc-form-required">
                        <label htmlFor="lasttName" className={lastName ? 'cvc-form-error' : ''}>
                          {lbl.lastName}
                        </label>
                        <Field
                          id="lastName"
                          name="lastName"
                          type="text"
                          inputMode="text"
                          autoComplete="family-name"
                          pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                          required
                          onInvalid={() => setLastName(true)}
                        />
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className="cvc-form-group cvc-form-required">
                        <label htmlFor="emailAddress" className={email ? 'cvc-form-error' : ''}>
                          {lbl.email}
                        </label>
                        <Field
                          id="emailAddress"
                          name="emailAddress"
                          inputMode="email"
                          autoComplete="email"
                          required
                          type="email"
                          onInvalid={() => setEmail(true)}
                        />
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className="cvc-form-group cvc-form-required">
                        <label htmlFor="phone1" className={phone ? 'cvc-form-error' : ''}>
                          {lbl.phone}
                        </label>
                        <div className="d-flex align-items-center">
                          <Field
                            className="col-2"
                            name="phone1"
                            type="tel"
                            inputMode="tel"
                            autoComplete="tel-area-code"
                            maxLength="3"
                            pattern="[0-9]{3}"
                            aria-label="Phone area code"
                            required
                            onInvalid={() => setPhone(true)}
                          />
                          &nbsp;-&nbsp;
                          <Field
                            className="col-2"
                            name="phone2"
                            type="tel"
                            inputMode="tel"
                            autoComplete="tel-local-prefix"
                            maxLength="3"
                            pattern="[0-9]{3}"
                            aria-label="Phone number"
                            required
                            onInvalid={() => setPhone(true)}
                          />
                          &nbsp;-&nbsp;
                          <Field
                            className="col-3"
                            name="phone3"
                            type="tel"
                            inputMode="tel"
                            autoComplete="tel-local-suffix"
                            maxLength="4"
                            pattern="[0-9]{4}"
                            aria-label="Phone number"
                            required
                            onInvalid={() => setPhone(true)}
                          />
                        </div>
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className="cvc-form-group">
                        <label htmlFor="comments">{lbl.comments}</label>
                        <Field
                          name="comments"
                          className="p-2"
                          component="textarea"
                          type="text"
                          aria-label="Comments"
                          rows={6}
                        />
                      </Col>
                    </div>
                  </Col>
                  {/* end your info */}

                  <Col className="cvc-retailer-contact-location" md={6}>
                    <h2 className="cvc-form-section-title">{lbl.titleLocation}</h2>

                    {buildingcenterlist ? (
                      <div className="form-row flex-wrap">
                        <Col className="cvc-form-group cvc-form-required align-self-end">
                          <label
                            htmlFor="plantUid"
                            className={` ${plantUidValidation ? 'cvc-form-error' : ''}`}
                          >
                            {lbl.selectBuildingCenterPartner}
                          </label>
                          <Field
                            id="plantUid"
                            as="select"
                            name="plantUid"
                            aria-label="Plant ID"
                            required
                            value={plantUidVal}
                            onChange={handlePlantUidChange}
                            onInvalid={() => setPlantUidValidation(true)}
                          >
                            <option value="" disabled>
                              {lbl.selectBuildingCenter}
                            </option>
                            {plantUidOptions.map((option: any) => {
                              return (
                                <option key={option[0]} value={option[0]}>
                                  {option[1]}
                                </option>
                              );
                            })}
                          </Field>
                        </Col>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="form-row flex-wrap">
                      <Col className="cvc-form-group cvc-form-required" xs={8}>
                        <label
                          htmlFor="locationCity"
                          className={` ${location ? 'cvc-form-error' : ''}`}
                        >
                          {lbl.location}
                        </label>
                        <Field
                          name="locationCity"
                          inputMode="text"
                          autoComplete="address-level2"
                          type="text"
                          placeholder="City"
                          pattern="[A-Za-z\-\s\.]+"
                          aria-label="Location city"
                          required
                          onInvalid={() => setLocation(true)}
                        />
                      </Col>

                      <Col className="cvc-form-group cvc-form-required align-self-end" xs={4}>
                        <Field
                          id="state"
                          as="select"
                          name="state"
                          aria-label="Location state"
                          required
                          value={stateVal}
                          onChange={handleStateChange}
                          onInvalid={() => setLocation(true)}
                        >
                          <option value="" disabled>
                            {lbl.state}
                          </option>
                          {stateOptions.map((option: any) => {
                            return (
                              <option key={option[1]} value={option[1]}>
                                {option[0]}
                              </option>
                            );
                          })}
                        </Field>
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className="cvc-form-group">
                        <div id="active-radio-group" className="cvc-form-label">
                          {lbl.active}
                        </div>
                        <div role="radiogroup" aria-labelledby="active-radio-group">
                          <div>
                            <label
                              className={`lblRadio ${
                                activeDealershipValue === activeDealership.yes ? ' checked' : ''
                              }`}
                              htmlFor="yes"
                            >
                              <Field
                                id="yes"
                                className="activeDealership"
                                type="radio"
                                name="activeDealership"
                                value={activeDealership.yes}
                                required
                                checked={activeDealershipValue === activeDealership.yes}
                                onChange={(event: any) =>
                                  updateActiveDealership(event.target.value)
                                }
                              />
                              {lbl.activeYes}
                            </label>
                          </div>
                          <div>
                            <label
                              className={`lblRadio ${
                                activeDealershipValue === activeDealership.no ? ' checked' : ''
                              }`}
                              htmlFor="no"
                            >
                              <Field
                                id="no"
                                className="activeDealership"
                                type="radio"
                                name="activeDealership"
                                value={activeDealership.no}
                                required
                                checked={activeDealershipValue === activeDealership.no}
                                onChange={(event: any) =>
                                  updateActiveDealership(event.target.value)
                                }
                              />
                              {lbl.activeNo}
                            </label>
                          </div>
                        </div>
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className={dealerLocationClassNameCol}>
                        <label htmlFor="dealershipLocations">{lbl.dealership}</label>
                        <Field
                          name="dealershipLocations"
                          className="p-2"
                          component="textarea"
                          type="text"
                          aria-label="Dealership Location(s)"
                          rows={6}
                          required={dealerLocationRequired}
                        />
                      </Col>
                    </div>

                    <div className="form-row">
                      <Col className="cvc-form-group">
                        <label htmlFor="websiteURL">{lbl.website}</label>
                        <Field
                          id="websiteURL"
                          name="websiteURL"
                          type="text"
                          placeholder="http://"
                          value={websiteVal}
                          onChange={handleWebsiteChange}
                        />
                      </Col>
                    </div>
                  </Col>
                  {/* end your location */}
                </Row>

                <Row className="mt-4">
                  <Col className="text-center">
                    {isReCaptchaEnabled ? (
                      <div className="cvc-form-captcha">
                        <ReCAPTCHA
                          sitekey={reCaptchaConfig.siteKey}
                          ref={captchaRef}
                          onChange={(token) => setCaptchaToken(token)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <p className="cvc-form-text disclaimer">{lbl.required}</p>
                    <p className="cvc-form-text disclaimer">
                      {lbl.privacy}{' '}
                      <ResolverLink href={lbl.privacyUrl}>{lbl.privacyLinkText}</ResolverLink>.
                    </p>
                    <button
                      className="cvc-form-button mt-3 btn btn-primary"
                      type="submit"
                      onClick={validateFields}
                      disabled={isSearching === true || (isReCaptchaEnabled && !captchaToken)}
                      tabIndex={isSearching ? -1 : 0}
                    >
                      {lbl.submit}
                    </button>
                    {isSearching ? (
                      <Spinner
                        color={
                          spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''
                        }
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Form>

              <RetailerConfirmationModal
                title={modalContent.title}
                content={modalContent.text}
                buttonText={modalLbl && modalLbl.modalButtonText}
                show={modal}
                close={closeModal}
                isError={modalContent.isError}
              />
            </Element>
          )}
        </Formik>
      </Container>
    </div>
  );
}
