import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Card } from 'react-bootstrap';

import { CardBody } from './CardBody';
import { CardImage } from './CardImage';

import { getRetailerData } from '../../utils';

interface InventoryCardProps {
  inventoryDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  title: string;
  className?: string;
  label: any;
}

function formatInventoryPrice(inventory: any, currencyLocale: string, currencyCode: string) {
  const { sellingPrice, availableStatus, markAsClearance, nowPrice, wasPrice } = inventory;
  const isSold = availableStatus.toLowerCase() === 'sold';

  // Create our currency formatter.
  const currencyFormatter = new Intl.NumberFormat(currencyLocale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 0,
  });

  if (isSold) {
    // do all the different styling
    return 'Sold';
  }
  if (sellingPrice) {
    if (markAsClearance && nowPrice && wasPrice) {
      return currencyFormatter.format(nowPrice);
    }
    if (Number.isNaN(parseInt(sellingPrice, 10))) {
      // plain text
      return sellingPrice;
    }
    // format to money
    return currencyFormatter.format(sellingPrice);
  }
}

export function InventoryCard(inventoryCardProps: InventoryCardProps) {
  const { id, url, photoUrls, lineDrawingUrl, floorPlanLineDrawings, inventoryType } =
    inventoryCardProps.inventoryDocumentData;

  let floorPlanLineDrawingsUrl = null;

  if (floorPlanLineDrawings) {
    floorPlanLineDrawingsUrl = floorPlanLineDrawings.mediaAssets[0]?.ximage?.mediumImage;
  }

  const firstLineDrawingImage = lineDrawingUrl || floorPlanLineDrawingsUrl;

  const lbl = inventoryCardProps.label;
  const currencyLocale = lbl.currencyLocale ? lbl.currencyLocale : 'en-US';
  const currencyCode = lbl.currencyCode ? lbl.currencyCode : 'USD';

  const inventoryUrl = url;
  const inventoryTypeName = inventoryType === 'MIR' ? 'Move-in Ready Home' : 'Display Home';

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  if (!hasPhoto && firstLineDrawingImage) {
    imageDefault = firstLineDrawingImage;
  }

  const retailerData: RetailerData | null = getRetailerData(inventoryCardProps.props.page);

  const inventoryTitle = inventoryCardProps.title;

  const dba = retailerData ? retailerData.dba : '';
  const city = retailerData ? retailerData.city : '';
  const state = retailerData ? retailerData.state : '';

  return (
    <Card
      id={id}
      className={`fp-feature-model-card ${!hasPhoto && imageDefault !== null && 'line-drawing'}`}
    >
      <CardImage className="fp-feature-model-image-link" image={imageDefault} url={inventoryUrl} />
      <CardBody
        title={inventoryTitle}
        url={inventoryUrl}
        displayPrice={formatInventoryPrice(
          inventoryCardProps.inventoryDocumentData,
          currencyLocale,
          currencyCode
        )}
        specialPricingLabel={`Special ${inventoryTypeName} Pricing`}
        offeredBy={lbl.offerBy}
        dba={dba}
        city={city}
        state={state}
      />
    </Card>
  );
}
