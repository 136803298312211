import { Button } from '@material-ui/core';
import { ProductResultCard, RetailerResultCard, Spinner } from 'components/shared';
import type { RetailerResultCardProps } from 'components/shared';
import { MediaModal } from 'components/MediaModal';
import React, { useEffect, useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';

import './CHMyFavorites.scss';
import { MediaModalState } from 'components/FloorPlanResultsCH/FloorPlanResultsCH';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-icon.svg';

const cmsBaseUrl = window.cmsBaseUrl || process.env.REACT_APP_CMS_BASE_URL;
const siteOriginUrl = new URL(cmsBaseUrl || '');

const mapMediaAssetFromAlbum = (album: any) => {
  switch (album?.mediaType) {
    case '3D':
      return album?.mediaAssets?.[0]?.matterportURL;
    case 'Video':
      return album?.mediaAssets?.[0]?.videoURL;
    default:
      return null;
  }
};

const isResultExternal = (result: any) => {
  const resultBase = result?.baseUrl;
  const resultBaseUrl = new URL(resultBase || '');

  return siteOriginUrl.origin !== resultBaseUrl.origin;
};

const normalizeResult = (result: any) => {
  const isExternal = isResultExternal(result);
  const resultUrl = isExternal ? result.absoluteUrl : result.url;
  const lineDrawings = result.lineDrawingUrl ? [result.lineDrawingUrl] : null;

  return {
    name: result.modelName,
    photos: result.photoUrls,
    number_of_bathrooms: result.numBathrooms,
    number_of_bedrooms: result.numBedrooms,
    model_number: result.modelNumber,
    square_foot: result.squareFootage,
    line_drawings: lineDrawings,
    series: result.series,
    building_method: result.buildingMethodValue,
    sections: result.sections,
    virtual_tour: mapMediaAssetFromAlbum(result.tourAlbum),
    video_tour: mapMediaAssetFromAlbum(result.videoAlbum),
    url: resultUrl,
    isExternal,
  };
};

interface ExtendedRetailerResultCard extends RetailerResultCardProps {
  locationID?: string;
  favoriteType?: string;
  assetID?: string;
  isFavorite?: boolean;
  favoriteSubId?: string;
}

const normalizeRetailerResult = (result: any): ExtendedRetailerResultCard => {
  const locationType = result.parkModelRetailer ? 'Park Model Retailer' : 'retailer';
  const retailerType = result.locationTypeOverride
    ? result.locationTypeOverride
    : locationType.split(' ').join('_').toLowerCase();

  const extUrl = result.linkedToCavcohomes ? result.cavcohomesRetailerLink : result.absoluteUrl;

  const isExternal = isResultExternal(result);
  const resultUrl = isExternal ? extUrl : result.url;

  return {
    city: result.city,
    state: result.state,
    zip: result.zip,
    name: result.locationName,
    address1: result.address1,
    address2: result.address2,
    favoriteType: result.favoriteType,
    locationID: result.locationID,
    assetID: result.assetID,
    isFavorite: result.isFavorite,
    favoriteSubId: result.favoriteId,
    url: resultUrl,
    locationType,
    retailerType,
    isExternal,
  };
};

interface CHMyFavoritesProps {
  floorplans?: any[];
  displayHomes?: any[];
  mir?: any[];
  retailers?: any[];
  showRemoveItemModal: boolean;
  isLoading?: boolean;
  handleHideRemoveItemModal: () => void;
  handleRemoveItem: () => void;
  handleRemoveFloorplanClick: (floorplan: any) => void;
  handleRemoveRetailerClick: (retailer: any) => void;
  handleRemoveDisplayHomeClick: (displayHome: any) => void;
  handleRemoveMirClick: (mir: any) => void;
}

const DEFAULT_RESULTS_COUNT = 3;

export const CHMyFavorites: React.FC<CHMyFavoritesProps> = ({
  floorplans = [],
  displayHomes = [],
  mir = [],
  retailers = [],
  showRemoveItemModal = false,
  isLoading = false,
  handleHideRemoveItemModal,
  handleRemoveItem,
  handleRemoveFloorplanClick,
  handleRemoveRetailerClick,
  handleRemoveDisplayHomeClick,
  handleRemoveMirClick,
}) => {
  const hasFloorplans = floorplans && floorplans.length > 0;
  const hasRetailers = retailers && retailers.length > 0;
  const hasDisplayHomes = displayHomes && displayHomes.length > 0;
  const hasMir = mir && mir.length > 0;

  const setInitialResults = (results: any[]): any[] => {
    const maxIndex =
      results.length >= DEFAULT_RESULTS_COUNT ? DEFAULT_RESULTS_COUNT : results.length;
    return results.slice(0, maxIndex);
  };

  interface RenderedFavoritesState {
    renderedFloorplans: any[];
    renderedRetailers: any[];
    renderedDisplayHomes: any[];
    renderedMir: any[];
  }
  const [renderedFavorites, setRenderedFavorites] = useState<RenderedFavoritesState>({
    renderedFloorplans: [],
    renderedDisplayHomes: [],
    renderedMir: [],
    renderedRetailers: [],
  });

  const [mediaModalState, setMediaModalState] = useState<MediaModalState>({
    isOpen: false,
    isVideo: false,
    mediaSrc: undefined,
    mediaTitle: undefined,
  });

  const handleShowFloorplansClick = () => {
    const renderedResults =
      renderedFavorites.renderedFloorplans.length > DEFAULT_RESULTS_COUNT
        ? setInitialResults(floorplans)
        : floorplans;

    setRenderedFavorites({
      ...renderedFavorites,
      renderedFloorplans: renderedResults,
    });
  };

  const handleShowDisplayHomesClick = () => {
    const renderedResults =
      renderedFavorites.renderedDisplayHomes.length > DEFAULT_RESULTS_COUNT
        ? setInitialResults(displayHomes)
        : displayHomes;

    setRenderedFavorites({
      ...renderedFavorites,
      renderedDisplayHomes: renderedResults,
    });
  };

  const handleShowMirClick = () => {
    const renderedResults =
      renderedFavorites.renderedMir.length > DEFAULT_RESULTS_COUNT ? setInitialResults(mir) : mir;

    setRenderedFavorites({
      ...renderedFavorites,
      renderedMir: renderedResults,
    });
  };

  const handleShowRetailersClick = () => {
    const renderedResults =
      renderedFavorites.renderedRetailers.length > DEFAULT_RESULTS_COUNT
        ? setInitialResults(retailers)
        : retailers;

    setRenderedFavorites({
      ...renderedFavorites,
      renderedRetailers: renderedResults,
    });
  };

  const handleDisplayMediaModal = ({ isVideo, mediaSrc, mediaTitle }: MediaModalState) => {
    setMediaModalState({
      isOpen: true,
      isVideo,
      mediaSrc,
      mediaTitle,
    });
  };

  const handleCloseMediaModal = () => {
    setMediaModalState({
      isOpen: false,
      isVideo: false,
      mediaSrc: '',
      mediaTitle: '',
    });
  };

  useEffect(() => {
    setRenderedFavorites({
      renderedFloorplans: setInitialResults(floorplans),
      renderedDisplayHomes: setInitialResults(displayHomes),
      renderedRetailers: setInitialResults(retailers),
      renderedMir: setInitialResults(mir),
    });
  }, [displayHomes, floorplans, retailers, mir]);

  const hasResults = hasFloorplans || hasDisplayHomes || hasRetailers || hasMir;
  return (
    <div className="ch-account-favorites">
      <h2 className="cvc-my-account-fav-title">My Favorites</h2>
      {!hasResults && !isLoading ? (
        <>
          <p>When you favorite homes or retailers, they will appear here.</p>
        </>
      ) : (
        <div className="ch-account-favorites__results">
          {isLoading && (
            <div className="ch-account-favorites__loading-spinner">
              <Spinner size="lg" color="#ffc72c" />
            </div>
          )}
          {hasDisplayHomes && (
            <>
              <Row className="justify-content-between align-content-center no-gutters">
                <h3>Display Homes ({displayHomes.length})</h3>
                {displayHomes.length > DEFAULT_RESULTS_COUNT && (
                  <button
                    className="cvh-link d-none d-lg-block"
                    type="button"
                    onClick={handleShowDisplayHomesClick}
                  >
                    {renderedFavorites.renderedDisplayHomes.length < displayHomes.length
                      ? 'See All'
                      : 'See Less'}
                  </button>
                )}
              </Row>
              <Row className="ch-account-favorites__results-set">
                {renderedFavorites.renderedDisplayHomes.map((displayHome) => (
                  <Col
                    className="ch-account-favorites__results-set__result"
                    xs={12}
                    lg={4}
                    key={displayHome.assetID}
                  >
                    <ProductResultCard
                      result={normalizeResult(displayHome)}
                      type="floorplan"
                      isMicroSite={false}
                      isRetailer={false}
                      isRaw={false}
                      handleMediaClick={handleDisplayMediaModal}
                      handleFavoriteClick={() => handleRemoveDisplayHomeClick(displayHome)}
                    />
                  </Col>
                ))}

                {displayHomes.length > DEFAULT_RESULTS_COUNT && (
                  <Button
                    className="d-block d-lg-none btn btn-punchout-large"
                    type="button"
                    onClick={handleShowDisplayHomesClick}
                  >
                    {renderedFavorites.renderedDisplayHomes.length < displayHomes.length
                      ? 'See All'
                      : 'See Less'}
                  </Button>
                )}
              </Row>
            </>
          )}
          {hasMir && (
            <>
              <Row className="justify-content-between align-content-center no-gutters">
                <h3>Move-In-Ready Homes ({mir.length})</h3>
                {mir.length > DEFAULT_RESULTS_COUNT && (
                  <button
                    className="cvh-link d-none d-lg-block"
                    type="button"
                    onClick={handleShowMirClick}
                  >
                    {renderedFavorites.renderedMir.length < mir.length ? 'See All' : 'See Less'}
                  </button>
                )}
              </Row>
              <Row className="ch-account-favorites__results-set">
                {renderedFavorites.renderedMir.map((mirItem) => (
                  <Col
                    className="ch-account-favorites__results-set__result"
                    xs={12}
                    lg={4}
                    key={mirItem.assetID}
                  >
                    <ProductResultCard
                      result={normalizeResult(mirItem)}
                      type="move-in-ready"
                      isMicroSite={false}
                      isRetailer={false}
                      isRaw={false}
                      handleMediaClick={handleDisplayMediaModal}
                      handleFavoriteClick={() => handleRemoveMirClick(mirItem)}
                    />
                  </Col>
                ))}

                {mir.length > DEFAULT_RESULTS_COUNT && (
                  <Button
                    className="d-block d-lg-none btn btn-punchout-large"
                    type="button"
                    onClick={handleShowMirClick}
                  >
                    {renderedFavorites.renderedMir.length < mir.length ? 'See All' : 'See Less'}
                  </Button>
                )}
              </Row>
            </>
          )}
          {hasFloorplans && (
            <>
              <Row className="justify-content-between align-content-center no-gutters">
                <h3>Floor Plans ({floorplans.length})</h3>
                {floorplans.length > DEFAULT_RESULTS_COUNT && (
                  <button
                    className="cvh-link d-none d-lg-block"
                    type="button"
                    onClick={handleShowFloorplansClick}
                  >
                    {renderedFavorites.renderedFloorplans.length < floorplans.length
                      ? 'See All'
                      : 'See Less'}
                  </button>
                )}
              </Row>
              <Row className="ch-account-favorites__results-set">
                {renderedFavorites.renderedFloorplans.map((floorplan) => (
                  <Col
                    className="ch-account-favorites__results-set__result"
                    xs={12}
                    lg={4}
                    key={floorplan.assetID}
                  >
                    <ProductResultCard
                      result={normalizeResult(floorplan)}
                      type="floorplan"
                      isMicroSite={false}
                      isRetailer={false}
                      isRaw={false}
                      handleMediaClick={handleDisplayMediaModal}
                      handleFavoriteClick={() => handleRemoveFloorplanClick(floorplan)}
                    />
                  </Col>
                ))}

                {floorplans.length > DEFAULT_RESULTS_COUNT && (
                  <Button
                    className="d-block d-lg-none btn btn-punchout-large"
                    type="button"
                    onClick={handleShowFloorplansClick}
                  >
                    {renderedFavorites.renderedFloorplans.length < floorplans.length
                      ? 'See All'
                      : 'See Less'}
                  </Button>
                )}
              </Row>
            </>
          )}
          {hasRetailers && (
            <>
              <Row className="justify-content-between align-content-center no-gutters">
                <h3>Retailers ({retailers.length})</h3>
                {retailers.length > DEFAULT_RESULTS_COUNT && (
                  <button
                    className="cvh-link d-none d-lg-block"
                    type="button"
                    onClick={handleShowRetailersClick}
                  >
                    {renderedFavorites.renderedRetailers.length < retailers.length
                      ? 'See All'
                      : 'See Less'}
                  </button>
                )}
              </Row>
              <Row className="ch-account-favorites__results-set">
                {renderedFavorites.renderedRetailers.map((retailer) => (
                  <Col
                    className="ch-account-favorites__results-set__result cvc-retailer-card cvc-retailer-card"
                    xs={12}
                    lg={3}
                    key={`retailer-result-${retailer.locationID}`}
                  >
                    <RetailerResultCard
                      {...normalizeRetailerResult(retailer)}
                      handleFavoriteClick={() =>
                        handleRemoveRetailerClick(normalizeRetailerResult(retailer))
                      }
                    />
                  </Col>
                ))}

                {retailers.length > DEFAULT_RESULTS_COUNT && (
                  <Button
                    className="d-block d-lg-none btn btn-punchout-large"
                    type="button"
                    onClick={handleShowRetailersClick}
                  >
                    {renderedFavorites.renderedRetailers.length < retailers.length
                      ? 'See All'
                      : 'See Less'}
                  </Button>
                )}
              </Row>
            </>
          )}
        </div>
      )}
      <MediaModal
        show={mediaModalState.isOpen || false}
        isVideo={mediaModalState.isVideo}
        title={mediaModalState.mediaTitle || ''}
        mediaSrc={mediaModalState.mediaSrc}
        handleClose={handleCloseMediaModal}
      />
      <Modal
        show={showRemoveItemModal}
        onHide={handleHideRemoveItemModal}
        className="ch-account-favorites__modal"
        centered
      >
        <button
          type="button"
          onClick={handleHideRemoveItemModal}
          className="ch-account-favorites__modal-close"
        >
          <CloseIcon />
        </button>
        <div className="ch-account-favorites__modal-body">
          <p className="ch-account-favorites__modal-bold">
            Are you sure you want to remove this favorited item?
          </p>
        </div>
        <Row className="justify-content-end no-gutters ch-account-favorites__modal-actions">
          <button onClick={handleHideRemoveItemModal} type="button" className="cvh-link">
            Cancel
          </button>
          <button type="button" className="btn btn-cta" onClick={handleRemoveItem}>
            Remove
          </button>
        </Row>
      </Modal>
    </div>
  );
};
