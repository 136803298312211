import { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMapPositions } from 'components/enums';

declare global {
  interface Window {
    spaBaseUrl: '';
    cmsBaseUrl: '';
    cavco: {
      mapMarkers?: any;
      mapConfig?: any;
    };
    google: any;
  }
}

export const useGoogleMap = ({
  center,
  zoom = 7,
}: {
  center?: { lat: number; lng: number };
  zoom?: number;
  retailers?: Array<any>;
  onMarkerClick?: any;
  closeInfoWindow?: any;
}) => {
  const mapContainerEl = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<any>();

  useEffect(() => {
    if (mapContainerEl.current && !map && window.google) {
      setMap(
        new window.google.maps.Map(mapContainerEl.current, {
          center,
          zoom,
          disableDefaultUI: false,
          mapTypeControl: true,
          streetViewControl: true,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
          zoomControlOptions: { position: GoogleMapPositions.RIGHT_TOP },
        })
      );
    }
  }, [mapContainerEl, map, center, zoom]);

  const regenerateMap = useCallback(
    (newCenter) => {
      setMap(
        new window.google.maps.Map(mapContainerEl.current, {
          center: newCenter,
          zoom,
          disableDefaultUI: false,
          mapTypeControl: true,
          streetViewControl: true,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
          zoomControlOptions: { position: GoogleMapPositions.RIGHT_TOP },
        })
      );
    },
    [zoom]
  );

  return {
    mapContainerEl,
    map,
    regenerateMap,
  };
};
