import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { Reference } from '@bloomreach/spa-sdk';
import { Col, Container, Row } from 'react-bootstrap';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core';
import { LocalStorageContext } from 'components/Header/LocalStorageContext';
import AutocompleteComp from '../Autocomplete/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon-30.svg';
import { buildStatesDropdown, checkForNessus, getCountryByGeocode } from '../utils';

import './SearchBanner.scss';

type SearchBannerType =
  | 'floorPlan'
  | 'community'
  | 'moveInReady'
  | 'retailerState'
  | 'retailerZipcode';

interface SearchBannerParameters {
  backgroundColor?: 'lightBlue' | 'pewter';
  headline?: string;
  searchType?: SearchBannerType;
  subheadline?: string;
  textAlignment?: 'centered' | 'leftAligned';
}

interface SearchBannerModel {
  componentParameterMap: SearchBannerParameters;
  googlemapConfig: Reference;
  states: Reference;
  stateCenters: Reference;
}

export const SearchBanner: React.FC<BrProps> = ({ component, page }: BrProps) => {
  const history = useHistory();
  // local storage hook
  const [floorPlanSearch, setFloorPlanSearch] = useContext(LocalStorageContext);

  const [state, setState] = React.useState<string | unknown>('');
  const [search, setSearch] = React.useState<SearchLatLong>({
    lat: null,
    lng: null,
    country: '',
    searchTerm: '',
  });
  const {
    componentParameterMap: {
      backgroundColor = 'pewter',
      headline,
      searchType = 'floorPlan',
      subheadline,
      textAlignment = 'centered',
    },
    googlemapConfig,
    states: statesConfig,
    stateCenters,
  } = component.getModels<SearchBannerModel>();

  const googlemapConfigList = page?.getContent(googlemapConfig)?.getData();
  const apiKey = googlemapConfigList?.items?.find((i: any) => i.key === 'apiKey')?.label || '';
  const internalLoc =
    googlemapConfigList?.items?.find((i: any) => i.key === 'mapEndpoint')?.label || '';

  const { keys: nameKeys, messages: nameMessages } = page
    ?.getContent(statesConfig)
    ?.getData<StateConfigData>() || {
    keys: [],
    messages: [],
  };
  const { keys: coordinateKeys, messages: coordinateMessages } = page
    ?.getContent(stateCenters)
    ?.getData() || {
    keys: [],
    messages: [],
  };

  const namesResource = {
    keys: nameKeys,
    messages: nameMessages,
  };

  const coordinatesResource = {
    keys: coordinateKeys,
    messages: coordinateMessages,
  };
  const states = buildStatesDropdown({ namesResource, coordinatesResource });

  const searchBannerClassnames = classNames(
    'cvc-search-banner',
    backgroundColor === 'lightBlue'
      ? 'cvc-search-banner--lightblue-bg'
      : 'cvc-search-banner--pewter-bg',
    textAlignment === 'leftAligned' ? 'cvc-search-banner--left' : 'cvc-search-banner--center'
  );

  const getUrlBySearchType = (type: SearchBannerType) => {
    const searchBannerType = type?.toString().trim();
    const basePath = window.spaBaseUrl || '';

    switch (searchBannerType) {
      case 'retailerState':
      case 'community':
      case 'retailerZipcode':
        return `${basePath}/our-retailers`;
      case 'moveInReady':
        return `${basePath}/move-in-ready-homes`;
      default:
        return `${basePath}/our-homes`;
    }
  };

  const searchInputFocus = () => {
    const searchInput = document.querySelector('.cvc-search-banner__input');
    if (searchInput) {
      (searchInput as HTMLInputElement).focus();
    }
  };

  function handleSubmit() {
    const nextPage = getUrlBySearchType(searchType);
    const { lat, lng, country, searchTerm } = search;
    const communityQueryParam = '&community=true';

    if (!lat || !lng) {
      return;
    }

    let params = '';
    let nameString = '';
    if (lat && lng) {
      if (searchTerm) {
        nameString = `&name=${searchTerm}`;
      }
      params += `/${country}?latitude=${lat}&longitude=${lng}${nameString}`;
    }
    params = searchType === 'community' ? `${params}${communityQueryParam}` : params;
    const path = `${nextPage}${params}`;
    // set the search item in localstorage
    setFloorPlanSearch({ ...floorPlanSearch, [searchType]: path });

    history.push(path);
  }

  const suggestionSelected = (data: any) => {
    geocodeByAddress(data.description)
      .then(async (results) => {
        const result = results[0];
        const latLong = await getLatLng(result);
        return {
          ...latLong,
          country: getCountryByGeocode(results[0]),
          searchTerm: result?.formatted_address,
        };
      })
      .then((latLong: SearchLatLong) => setSearch(latLong));
    searchInputFocus();
  };

  React.useEffect(() => {
    const searchInput: any = document.querySelector('.cvc-search-banner__input')!;
    if (searchInput) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
          handleSubmit();
        }
      };

      searchInput.addEventListener('keydown', handleKeyDown);
      return () => searchInput.removeEventListener('keydown', handleKeyDown);
    }
  });

  const parseLocationString = (locationStr: string | unknown) => {
    const locationArr = String(locationStr).split(',');

    const lat = locationArr?.[0] || '';
    const lng = locationArr?.[1] || '';
    const dist = locationArr?.[2] || '';

    return {
      lat,
      lng,
      dist,
    };
  };

  const onChangeState = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: any
  ) => {
    const {
      target: { value },
    } = event;
    const stateName = String(child?.props?.children);
    setState(value);
    if (value) {
      const { lat, lng, dist } = parseLocationString(value);
      const nextPage = getUrlBySearchType(searchType);
      history.push(
        `${nextPage}/us/?latitude=${lat}&longitude=${lng}&locations=${dist}&name=${stateName}`
      );
    }
  };

  const lefCol = textAlignment === 'leftAligned' ? '8' : '12';
  const rightCol = textAlignment === 'leftAligned' ? '4' : '12';

  return (
    <div className={searchBannerClassnames}>
      <Container>
        <Row>
          <Col xs={12} md={lefCol}>
            {headline && <h1>{headline}</h1>}
            {subheadline && <h3>{subheadline}</h3>}
          </Col>
          <Col xs={12} md={rightCol}>
            {searchType !== 'retailerState' ? (
              <div className="cvc-search-banner__input-wrapper">
                <IconButton className="cvc-search-banner__input-icon-button" onClick={handleSubmit}>
                  <SearchIcon className="cvc-search-banner__input-icon" />
                </IconButton>
                <AutocompleteComp
                  apiKey={checkForNessus(apiKey)}
                  placeholder="Enter ZIP code"
                  geocodeEndpoint={internalLoc}
                  onClick={suggestionSelected}
                  inputClassName="cvc-search-banner__input"
                />
              </div>
            ) : (
              <FormControl className="cvc-search-banner__select-wrapper">
                {!state && <InputLabel id="state-dropdown">State</InputLabel>}
                <Select
                  id="state"
                  className="cvc-search-banner__select"
                  labelId="state-dropdown"
                  value={state}
                  onChange={onChangeState}
                >
                  {states.map(({ value, displayName }) => (
                    <MenuItem key={`option_${value}`} value={value}>
                      {displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
