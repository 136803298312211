import React, { useState } from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import 'components/shared/ProductDetails/Product3DTours.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { display3DTour } from '../utils';

function displayTitle(title: string) {
  if (!title) return '';

  return <h2 className="cvc-product-tours-title">{title}</h2>;
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  index: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="presentation"
      key={`${imageGalleryName}-tours-container-${index}`}
      className="cvc-product-tours-item-container"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
    >
      <div
        key={`${imageGalleryName}-tour-${index}`}
        className="cvc-product-tours-media-item"
        tabIndex={-1}
      >
        {item.matterportURL ? display3DTour(item) : ''}
      </div>
      <div
        key={`${imageGalleryName}-floorplan-tours-item-link-${item.id}`}
        className="cvc-product-tours-item-link"
        tabIndex={-1}
      >
        <VideoIcon
          key={`${imageGalleryName}-floorplan-tours-item-icon-${item.id}`}
          className="cvc-product-tours-item-icon"
        />
      </div>
    </div>
  );
}

function displayMediaAssets(
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.VIRTUAL_TOUR && mediaType !== ALBUM_MEDIA_TYPES.PANATOUR)
    return '';

  return mediaAssetItems.map((item: any, index) => {
    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      index,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function FloorPlan3DTours(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { documents, floorPlanToursLabels, spinnerConfig } = props.component.getModels();

  const imageGalleryName = props.component.getName();

  const ref = documents[0];
  const document = ref ? props.page.getContent(ref) : undefined;

  if (!document) {
    return null;
  }

  const { tours: toursRef, panatours: panatoursRef } = document.getData<FloorPlanData>();

  const toursDocument = toursRef && props.page.getContent(toursRef);
  const panatourDocument = panatoursRef && props.page.getContent(panatoursRef);

  let albumId = '';
  let albumMediaType = '';
  let albumTitle = '';
  let mediaItems = null;

  if (toursDocument) {
    const { id, title, mediaType, mediaAsset } = toursDocument.getData<AlbumData>();
    albumId = id;
    albumTitle = title;
    albumMediaType = mediaType;
    mediaItems = mediaAsset.slice(0, 3) as [MediaAssetCompound];
    if (panatourDocument) {
      const { title: panaTitle, mediaAsset: panaMediaAsset } =
        panatourDocument.getData<AlbumData>();
      albumTitle = albumTitle.concat(' & ', panaTitle);
      mediaItems = mediaItems.concat(panaMediaAsset.slice(0, 1)) as [MediaAssetCompound];
    }
  } else if (!toursDocument && panatourDocument) {
    const {
      id: panaId,
      title: panaTitle,
      mediaType: panaMediaType,
      mediaAsset: panaMediaAsset,
    } = panatourDocument.getData<AlbumData>();
    albumId = panaId;
    albumTitle = panaTitle;
    albumMediaType = panaMediaType;
    mediaItems = panaMediaAsset.slice(0, 1) as [MediaAssetCompound];
  } else {
    return null;
  }

  const toursCompTitle =
    floorPlanToursLabels && floorPlanToursLabels.title
      ? floorPlanToursLabels.title
      : '3D Virtual Tours';

  const floorPlanPhotosClasses = classNames({
    'cvc-floorplan-tours cvc-product-tours': true,
    container:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig,
  });

  const mediaAssetItems = mediaItems;
  const noTours = mediaItems.find((item) => item.matterportURL !== '') === undefined;

  if (noTours) {
    return null;
  }

  const oneItem = mediaAssetItems.length === 1;

  const floorItemsContainerClasses = classNames({
    'cvc-product-tours-items-container': true,
    'one-item': oneItem,
  });

  const spinnerColorInDarkBackground = spinnerConfig && spinnerConfig.spinner_color_dark_background;

  return (
    <div
      id={`${imageGalleryName}-${albumId}`}
      className={floorPlanPhotosClasses}
      key={`${imageGalleryName}-${albumId}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={albumMediaType}
        mediaAssetItems={mediaAssetItems}
        albumName={albumTitle}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerColorInDarkBackground}
      />
      {displayTitle(toursCompTitle)}
      <div className={floorItemsContainerClasses}>
        {displayMediaAssets(
          imageGalleryName,
          albumMediaType,
          mediaAssetItems,
          setIndexItemSelected,
          handleShowMediaViewer
        )}
      </div>
    </div>
  );
}
