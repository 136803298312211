import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoRetailerFloorPlanTitle.scss';
import { Typography } from '@material-ui/core';
import { getRetailerData } from '../utils';

//import RetailerHeaderInfo from '../Header/CavcoRetailerHeader/RetailerHeaderInfo';
//james trying to get the retailer name from RetailerHeaderInfo to append to the title

export const CavcoRetailerFloorPlanTitle: React.FC<BrProps> = ({ component, page }) => {
  const { title, container, name } = component.getModels();
  const titleComp = container === 'cavco-retailer-fp-title' ? 'h1' : 'h1';
  const titleAlign = container === 'cavco-retailer-fp-title' ? 'left' : 'center';
  const details = page.getComponent('main', 'detailcontainer');
  const isFloorplan = !!details && details.getComponent('floorplan-title');
  const showThisTitle = isFloorplan ? `${getRetailerData(page)?.dba} ${title}` : title;

  return (
    <div className={`cavcohomes-title-component ${container}`}>
      <Typography component={titleComp} align={titleAlign}>
        {showThisTitle} {name}
      </Typography>
    </div>
  );
};
