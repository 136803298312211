import React, { useState } from 'react';

import classNames from 'classnames';

import { Card } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { Content } from '@bloomreach/spa-sdk';

import { ResolverLink } from 'components/shared';

import { MediaViewer } from 'components/MediaViewer/MediaViewer';
import { ALBUM_MEDIA_TYPES } from 'components/enums';

import {
  FavoriteIcon,
  VirtualIcon,
  VideoIcon,
  USAFlagIcon,
  CANFlagIcon,
  ComingSoonCrossStitchPicture,
} from 'components/shared/Icons';

import SiteGlobalSwitches from 'components/utils';
import { ImageCarousel } from '../../shared/ImageCarousel/ImageCarousel';

interface FloorPlanCardProps {
  key: string;
  floorPlanDocumentData: any;
  windowDimensions: any;
  props: BrProps;
}

function getAlbumPropertiesFromDocumentRef(documentRef: any, props: any, mediaAssetsArray: any) {
  const document: Content = documentRef && props.page.getContent(documentRef);
  if (!document)
    return {
      title: '',
      mediaAsset: documentRef?.mediaAssets || mediaAssetsArray,
      albumLink: '',
    };

  const { title, mediaAsset } = document.getData<AlbumData>();
  const albumLink = document.getUrl()!.toString();

  return {
    title,
    mediaAsset,
    albumLink,
  };
}

export const FloorPlanCard = (floorPlanCardProps: FloorPlanCardProps) => {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const [mediaTypeVal, setMediaTypeVal] = useState('');
  const mediaAssetsArray: [MediaAssetCompound] = [
    {
      id: '',
      image: { $ref: '' },
      ximage: {
        id: '',
        xid: '',
        name: '',
        displayName: '',
        original: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        cavcoThumbnail: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        small: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        medium: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        large: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        extraLarge: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
      },
      link: {
        destinationUrl: '',
        displayName: '',
        displayText: '',
        id: '',
        linkTitle: '',
        name: '',
        newWindow: false,
        alt: '',
      },
      imageAltText: '',
      matterportURL: '',
      videoURL: '',
      description: '',
      documentPicker: { $ref: '' },
      imageType: '',
    },
  ];
  const [mediaAssetsVal, setMediaAssetsVal] = useState(mediaAssetsArray);
  const [titleVal, setTitleVal] = useState('');
  const [albumLink, setAlbumLink] = useState('');

  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);

  const {
    id,
    assetID,
    modelName,
    modelNumber,
    photoUrls,
    lineDrawingUrl,
    videoAlbum: videosRef,
    tourAlbum: toursRef,
    panatourAlbum: panatourRef,
    numBedrooms,
    numBathrooms,
    squareFootage,
    buildingMethodValue,
    series,
    country,
    // isFavorite,
    startingPrice,
    inStock,
    isCommunity,
    flrPlanWidthFeet,
    flrPlanWidthInches,
    flrPlanLengthFeet,
    flrPlanLengthInches,
  } = floorPlanCardProps.floorPlanDocumentData;

  const theFloorPlanUrl = floorPlanCardProps.floorPlanDocumentData.url;

  let ref3dTour: any;
  let mediaType3dTour: any;
  if (toursRef !== null) {
    ref3dTour = toursRef;
    mediaType3dTour = ALBUM_MEDIA_TYPES.VIRTUAL_TOUR;
  } else if (toursRef === null && panatourRef !== null) {
    ref3dTour = panatourRef;
    mediaType3dTour = ALBUM_MEDIA_TYPES.PANATOUR;
  }

  // My Account
  const compModels = floorPlanCardProps.props.component.getModels();
  const { retailerId, plantLocationUid } = compModels;
  const favoriteBaseUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.favoriteBaseUrl
    : null;
  const loadFavoriteUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.cavcoFavoriteUrl
    : null;

  const showFlag = SiteGlobalSwitches.showFloorPlanCountry;

  let imageDefault = null;
  if (photoUrls && photoUrls[0]) {
    // first image should be shown
    imageDefault = photoUrls[0]; // eslint-disable-line
  }

  if (lineDrawingUrl) {
    if (!imageDefault || imageDefault.url === '') {
      imageDefault = lineDrawingUrl;
    }
  }

  const floorPlanTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;

  const widthFeet = flrPlanWidthFeet || '0';
  const widthInches = flrPlanWidthInches || '0';
  const lengthFeet = flrPlanLengthFeet || '0';
  const lengthInches = flrPlanLengthInches || '0';

  const displayBedroomsBathroomsAndSquareFootage = () => {
    return (
      <div className="product-card-bed-bath-sqft">
        {isCommunity ? (
          <>
            <span>{`${widthFeet}' ${widthInches}" x ${lengthFeet}' ${lengthInches}" `}</span>
            <span className="card-text-separator">{' | '} </span>
          </>
        ) : (
          ''
        )}
        <span>{numBedrooms && `${numBedrooms} bd`} </span>
        {numBathrooms ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{numBathrooms && `${numBathrooms} ba`}</span>
        {squareFootage ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{squareFootage && `${squareFootage} sqft`}</span>
        {inStock && <span className="in-stock-indicator">In Stock</span>}
      </div>
    );
  };

  const formatPrice = () => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    if (Number.isNaN(parseInt(startingPrice, 10))) {
      // plain text
      return startingPrice;
    }

    return currencyFormatter.format(startingPrice);
  };

  const displayCardBody = () => {
    const buildingMethodText = buildingMethodValue ? `${buildingMethodValue} Home` : '';
    const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
    const showPricing = SiteGlobalSwitches.showFloorPlanPricing;
    const keyString = `floorplan-card-favorite-${assetID}`;
    const retailerSubIdType = retailerId ? 'subIdTypeRetailer' : null;
    const subIdType = plantLocationUid ? 'subIdTypeBuildingCenter' : retailerSubIdType;

    return floorPlanCardProps.windowDimensions.isMobile ? (
      <>
        <Card.Title>
          <ResolverLink className="card-link" href={theFloorPlanUrl}>
            <span className="floorplan-card-title product-card-title">{floorPlanTitle}</span>
          </ResolverLink>
        </Card.Title>
        <div className="card-text">
          {displayBedroomsBathroomsAndSquareFootage()}
          {showFavorites && modelName ? (
            <FavoriteIcon
              key={keyString}
              classes="floorplan-save-favorites-icon"
              type="our_homes"
              id={assetID}
              subId={retailerId || plantLocationUid}
              // isFavorite={isFavorite}
              baseUrl={favoriteBaseUrl}
              loadFavoriteUrl={loadFavoriteUrl}
              modelName={modelName}
              subIdType={subIdType}
              myAccountConfig={compModels.myAccountConfig}
            />
          ) : (
            ''
          )}
        </div>
      </>
    ) : (
      <>
        <Card.Title className="is-desktop-view">
          <ResolverLink className="card-link" href={theFloorPlanUrl}>
            <span className="floorplan-card-title product-card-title">{floorPlanTitle}</span>
          </ResolverLink>
          {displayBedroomsBathroomsAndSquareFootage()}
        </Card.Title>
        <div className="is-desktop-view card-text">
          <div className="floorplan-favorite-building-container">
            {showFavorites && modelName ? (
              <FavoriteIcon
                key={keyString}
                classes="floorplan-save-favorites-icon"
                type="our_homes"
                id={assetID}
                subId={retailerId || plantLocationUid}
                // isFavorite={isFavorite}
                baseUrl={favoriteBaseUrl}
                loadFavoriteUrl={loadFavoriteUrl}
                modelName={modelName}
                subIdType={subIdType}
                myAccountConfig={compModels.myAccountConfig}
              />
            ) : (
              ''
            )}
            {buildingMethodText ? (
              <span className="floorplan-building-method-text">{buildingMethodText}</span>
            ) : (
              ''
            )}
            {showPricing && startingPrice ? (
              <span className="floorplan-series-price-text">
                Starts in the {formatPrice()}&apos;s<span className="price-asterisk">**</span>
              </span>
            ) : (
              <span className="floorplan-series-price-text">{series}</span>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleIconMediaIconClick = (documentRef: any, mediaType: string) => {
    setMediaTypeVal(mediaType);

    const albumData = getAlbumPropertiesFromDocumentRef(
      documentRef,
      floorPlanCardProps.props,
      documentRef.mediaAssets
    );
    // const data = albumData.mediaAsset.map((x:any) =>({...x, matterportURL: "https://cdn2.cavco.com/tour/220/22CL24443X/22CL24443X.html",}))
    if (albumData) {
      // setMediaAssetsVal(data)
      setMediaAssetsVal(albumData!.mediaAsset);
      setTitleVal(albumData!.title);
      setAlbumLink(albumData!.albumLink);
    }
    handleShowMediaViewer();
  };

  return (
    <Card id={id} className={imageDefault === lineDrawingUrl ? ' line-drawing' : ''}>
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaTypeVal}
        mediaAssetItems={mediaAssetsVal}
        albumName={titleVal}
        albumLink={albumLink}
        hasParentPageFloorPlan
        indexItemSelected={0}
      />
      {!imageDefault ? (
        <ResolverLink className="card-link" href={theFloorPlanUrl}>
          <div className="card-no-photo">
            <ComingSoonCrossStitchPicture title="image of home coming soon" />
          </div>
        </ResolverLink>
      ) : (
        <ResolverLink className="card-link" href={theFloorPlanUrl}>
          <ImageCarousel photoUrls={photoUrls} lineDrawingUrl={lineDrawingUrl} />
        </ResolverLink>
      )}
      {!floorPlanCardProps.windowDimensions.isMobile ? (
        <>
          {showFlag && (
            <div className="floorplan-flag-icon cvc-product-list-card-icon">
              {country && country === 'Canada' ? (
                <CANFlagIcon title="Available in Canada" />
              ) : (
                <USAFlagIcon title="Available in the US" />
              )}
            </div>
          )}
          {ref3dTour ? (
            <div
              className={classNames(
                'floorplan-3d-virtual-icon',
                'cvc-product-list-card-icon',
                showFlag ? 'show-after-flag-icon' : ''
              )}
            >
              <VirtualIcon onClick={() => handleIconMediaIconClick(ref3dTour, mediaType3dTour)} />
            </div>
          ) : (
            ''
          )}
          {videosRef ? (
            <div
              className={classNames(
                'floorplan-video-icon',
                'cvc-product-list-card-icon',
                showFlag && ref3dTour ? 'show-after-two-icons' : '',
                (showFlag && !ref3dTour) || (!showFlag && ref3dTour) ? 'show-after-one-icon' : ''
              )}
            >
              <VideoIcon
                onClick={() => handleIconMediaIconClick(videosRef, ALBUM_MEDIA_TYPES.VIDEO)}
              />
            </div>
          ) : (
            ''
          )}
          {lineDrawingUrl && photoUrls && photoUrls[0] ? (
            <div className="floorplan-line-drawings-container">
              <ResolverLink className="card-link" href={theFloorPlanUrl}>
                <img alt={lineDrawingUrl.alt} src={lineDrawingUrl.url} />
              </ResolverLink>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <Card.Body>{displayCardBody()}</Card.Body>
    </Card>
  );
};
