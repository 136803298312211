import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { StyledCheckboxListItemText } from '../../FloorPlanResultsStyledComponents';
import 'components/FloorPlanResultsCH/components/RadioFacet/radioFacet.scss';

export const RadioFacet = ({ option, componentData }: any) => {
  const primary = option.value ? option.value : `${option.to} ${componentData?.unit}`;

  // secondary data: displays as verbiage for checkbox...
  const componentDataKey = option.value ? option.value.replaceAll(' ', '-').toLowerCase() : null;
  const secondary = componentDataKey ? componentData?.[componentDataKey] : '';
  return (
    <div className="facet-panel__checkbox-item">
      <Radio color="primary" value={option.to} />
      <StyledCheckboxListItemText primary={primary} secondary={secondary} />
    </div>
  );
};

export const RadioGroupFacet = ({
  facet,
  selectedFacets,
  handleSelectedFacet,
  componentData,
}: any) => {
  return (
    <RadioGroup
      name={facet.key}
      row
      aria-label={facet.label}
      value={selectedFacets[facet?.key]?.[0]}
      onChange={(event, val) => handleSelectedFacet(facet.key, Number(val), true)}
    >
      {facet?.options?.map((option: any, index: number) => (
        <RadioFacet
          key={`${facet.key}-${option.value}-${index}`}
          option={option}
          componentData={componentData}
        />
      ))}
    </RadioGroup>
  );
};
