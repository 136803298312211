import { Checkbox } from '@material-ui/core';
import React from 'react';
import { StyledCheckboxListItemText } from '../../FloorPlanResultsStyledComponents';
import './checkboxFacet.scss';

export const CheckboxFacet = ({
  name,
  option,
  selectedFacets,
  handleSelectedFacet,
  componentData,
}: any) => {
  const label = option.value ? option.value : option.to;
  const primary = option.value ? option.value : `${option.to} ${componentData?.unit}`;
  const isChecked =
    !!selectedFacets && !!selectedFacets[name] && !!selectedFacets[name]?.includes(label);
  // secondary data: displays as verbiage for checkbox...
  const componentDataKey = option.value ? option.value.replaceAll(' ', '-').toLowerCase() : null;
  const secondary = componentDataKey ? componentData?.[componentDataKey] : '';
  const primaryUpdated = primary.replace('3d ', '3D ').replace('Video Tour', 'Video Tours');

  return (
    <div className="facet-panel__checkbox-item">
      <Checkbox
        checked={isChecked || false}
        value={primary}
        name={name}
        onChange={(e, checked) => handleSelectedFacet(name, label, checked)}
      />
      <StyledCheckboxListItemText primary={primaryUpdated} secondary={secondary} />
    </div>
  );
};

export const CheckboxGroup = ({
  facet,
  selectedFacets,
  handleSelectedFacet,
  componentData,
}: any) => {
  return facet?.options?.map((option: any, index: number) => (
    <CheckboxFacet
      key={`${facet.key}-${option.value}-${index}`}
      name={facet.key}
      option={option}
      selectedFacets={selectedFacets}
      handleSelectedFacet={(key: string, value: any, checked: boolean) =>
        handleSelectedFacet(key, value, checked)
      }
      componentData={componentData}
    />
  ));
};
