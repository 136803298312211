import React, { FunctionComponent } from 'react';
import { BrComponent, BrPageContext } from '@bloomreach/react-sdk';
import './SingleColumn.scss';
import classNames from 'classnames';

const SingleColumn: FunctionComponent = () => {
  const page = React.useContext(BrPageContext);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrComponent path="header-content" />

      <BrComponent path="parkmodel-header-content" />
      <main
        className={classNames('cvc-main', page?.getComponent().getName())}
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-main" id="main">
          <div className="hero-drop-zone">
            <BrComponent path="hero" />
          </div>
          <div className="main-drop-zone">
            <BrComponent path="main" />
          </div>
        </section>
      </main>
      <BrComponent path="footer-content" />

      <BrComponent path="parkmodel-footer-content" />
    </div>
  );
};

export default SingleColumn;
