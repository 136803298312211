import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as ElasticSearch from '@elastic/app-search-javascript';
import { BrProps } from '@bloomreach/react-sdk';
import { IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { Col, Container, Row, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { useGoogleMap } from 'components/hooks/useGoogleMap';
// import { Wrapper as GoogleMapsProvider } from '@googlemaps/react-wrapper';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Spinner } from 'components/shared/Spinner';
import { scroller } from 'react-scroll';
import { SEARCH_TYPES } from 'components/enums';
import { LocalStorageContext } from 'components/Header/LocalStorageContext';
import { GoogleMapsProvider } from '../hooks/GoogleMapsProvider.context';
import { RetailerCards } from '../shared/CavcoRetailerCards/RetailerCards';
import {
  buildResourceBundleToObject,
  buildValueListToObject,
  checkForNessus,
  formatPhoneNumber,
  getCountryByGeocode,
  getDistanceFromLatLonInKm,
  getDistanceUnitByCountry,
  getPageContentData,
  oneKmInMiles,
} from '../utils';
import AutoCompleteComp from '../Autocomplete/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon-30.svg';
import { ReactComponent as RetailerIcon } from '../../assets/images/cavcohomes/location.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';

import './RetailerListCH.scss';

interface ElasticOptions {
  facets?: {};
  filters: {
    all: any[];
  };
  query: any;
  page: { size: any };
}

const markerSize = 1.3;
const markerSizeSelected = 1.6;
const markerLabelX = 12;
const markerLabelY = 10;
const markerDefaultConfig = {
  fillOpacity: 1,
  scale: markerSize,
  strokeColor: '#FFFFFF',
  strokeWeight: 0,
  path: 'M11.3412 22C11.1408 21.9063 10.9728 21.7553 10.8586 21.566C8.9983 18.936 7.12399 16.316 5.27371 13.678C4.56902 12.6656 4.14233 11.4864 4.03618 10.258C3.7238 7.05803 5.45593 3.98003 8.39355 2.71003C11.2451 1.47604 13.9384 1.85204 16.3494 3.82603C17.0764 4.41636 17.6795 5.14426 18.1242 5.96797C18.5688 6.79167 18.8463 7.69497 18.9406 8.62602C19.1802 10.4964 18.6897 12.3872 17.5709 13.906C15.77 16.4393 13.9678 18.9773 12.1642 21.52C12.047 21.7282 11.8688 21.8957 11.6536 22H11.3412ZM13.9945 9.52802C13.9964 9.03574 13.8525 8.55389 13.5809 8.14308C13.3093 7.73227 12.9222 7.41084 12.4681 7.21922C12.0141 7.02761 11.5135 6.97436 11.0293 7.06618C10.545 7.158 10.0988 7.39079 9.74666 7.73527C9.39455 8.07974 9.15228 8.52052 9.05034 9.00216C8.94841 9.4838 8.99134 9.98479 9.17376 10.4421C9.35617 10.8994 9.66992 11.2927 10.0755 11.5724C10.4811 11.8521 10.9605 12.0057 11.4533 12.014C11.7838 12.018 12.1117 11.9568 12.4184 11.8339C12.7251 11.711 13.0045 11.5288 13.2406 11.2979C13.4767 11.0669 13.6648 10.7917 13.7941 10.488C13.9235 10.1843 13.9916 9.85806 13.9945 9.52802Z',
};
const spinnerColorInLightBackground = '#000000';
const markerColors: any = {
  community: '#215732',
  retirement_community: '#67823A',
  retailer: '#33687e',
  park_model_retailer: '#323e48',
};

const cardActiveClass = 'active';
const cardContainerId = 'retailer-cards-container';

const setMarkerIcon = (marker: any, config: object = {}) => {
  const fillColor = marker?.getIcon()?.fillColor;
  const labelOrigin = marker?.getIcon()?.labelOrigin;

  marker?.setIcon({
    ...markerDefaultConfig,
    fillColor,
    labelOrigin,
    ...config,
  });
};

const RetailerList = ({ component, page: brPage }: BrProps) => {
  const [displayInMobile, setDisplayInMobile] = useState<string>('cards');
  const [search, setSearch] = useState<SearchLatLong>({
    lat: null,
    lng: null,
    country: '',
    postalCode: '',
  });
  const [elasticOptions, setElasticOptions] = useState<any>({
    type: 'retailer',
    locations: [],
    page: { size: 20, current: 1 },
  });
  const [filteredResultsCount, setfilteredResultsCount] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  const [retailerList, setRetailerList] = useState<any>([]);
  const [retailerCardsList, setRetailerCardsList] = useState<any>([]);
  const [locationType, setLocationType] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const windowDimensions: any = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();

  const {
    googlemapsconfiguration,
    esconfiguration,
    configuration,
    myaccountconfiguration,
    locationtypeoverridemapping,
    statecentersmapping,
  } = component.getModels();
  const configMap = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(googlemapsconfiguration, brPage)
  );

  const esConfigMap = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(esconfiguration, brPage)
  );
  const myAccountConfig = buildValueListToObject(
    getPageContentData<ValueListData>(myaccountconfiguration, brPage)?.items || []
  );
  const bannerConfig = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(configuration, brPage)
  );
  const locationTypeOverrideMapping = buildValueListToObject(
    getPageContentData<ValueListData>(locationtypeoverridemapping, brPage)?.items || []
  );
  const stateCenters = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(statecentersmapping, brPage)
  );

  // local storage hook
  const [floorPlanSearch, setFloorPlanSearch] = useContext(LocalStorageContext);

  const searchRadius = useMemo(() => Number(configMap?.searchRadius), [configMap]);
  const client = useMemo(
    () =>
      ElasticSearch.createClient({
        searchKey: esConfigMap.apiKey,
        endpointBase: esConfigMap.endpointBase,
        engineName: esConfigMap.engineName,
        // cacheResponses: false,
      }),
    [esConfigMap.apiKey, esConfigMap.endpointBase, esConfigMap.engineName]
  );

  const queryParams = new URLSearchParams(location.search);
  const countryCode = location.pathname.split('/')[2];
  const stateCode = location.pathname.split('/')[3];
  let stateCenter;

  if (stateCode) {
    stateCenter = stateCenters[stateCode]?.toString().split(',');
  }

  const stateLat = stateCenter ? stateCenter[0] : null;
  const stateLng = stateCenter ? stateCenter[1] : null;

  const currentAddress = queryParams.get('name');
  const stateName = currentAddress && currentAddress.replace(/,.*/, '');
  const queryLat = queryParams.get('latitude');
  const queryLng = queryParams.get('longitude');
  const locationLat = queryLat || stateLat;
  const locationLng = queryLng || stateLng;
  const selectedTab = location.pathname.includes('our-communities');

  // const [tabValue, setTabValue] = React.useState(selectedTab ? 1 : 0);
  const tabValue = selectedTab ? 1 : 0;
  const isCommunity = selectedTab;
  const [markers, setMarkers] = useState<any[] | null>(null);
  const zoomLevelValue = Number(queryParams.get('zoom') || 7);
  const distanceUnit = getDistanceUnitByCountry(countryCode);
  const center = { lat: Number(locationLat), lng: Number(locationLng) };

  const cardContainerEl = useRef<HTMLDivElement>(null);

  const { mapContainerEl, map, regenerateMap } = useGoogleMap({
    zoom: zoomLevelValue,
    center,
  });

  const closeInfoWindow = useCallback(() => {
    const prevCardSelected = cardContainerEl.current?.querySelector(`.${cardActiveClass}`);

    if (prevCardSelected) {
      const prevId = prevCardSelected.getAttribute('id');
      const item = markers?.find((mapMarker: any) => mapMarker?.marker?.id === prevId);
      prevCardSelected.classList.remove(cardActiveClass);
      item?.infoWindow?.close();
      item?.marker?.setZIndex(1);
      setMarkerIcon(item?.marker, {
        scale: markerSize,
        anchor: new window.google.maps.Point(0, 0),
      });
    }
  }, [markers]);

  const updateMapMarkers = useCallback(
    (e: any) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      let index = 0;
      let targetId = '';

      closeInfoWindow();

      markers?.forEach((markerSet: any, i: number) => {
        const { marker } = markerSet;
        if (lat === marker?.position?.lat() && lng === marker?.position?.lng()) {
          index = i;
          targetId = markers[index]?.marker?.id;
        }
      });

      const cardId = `${targetId}`;
      const cardSelected = document.getElementById(cardId);
      const item = markers?.[index];

      if (cardSelected) {
        cardSelected.classList.add(cardActiveClass);
        cardSelected.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      item?.infoWindow?.open(map, item?.marker);
      item?.marker?.setZIndex(1075);
      setMarkerIcon(item?.marker, {
        scale: markerSizeSelected,
        anchor: new window.google.maps.Point(2, 2),
      });
      map.panTo({ lat, lng });
    },
    [closeInfoWindow, map, markers]
  );

  const removeCurrentMarkers = useCallback(() => {
    markers?.forEach((markerObj) => {
      const { marker } = markerObj;
      marker?.setMap(null);
    });
  }, [markers]);

  const onMarkerClick = useCallback(
    (e: any) => {
      if (!retailerList) return;
      updateMapMarkers(e);
    },
    [updateMapMarkers, retailerList]
  );

  const createMarkersFromRetailers = useCallback(() => {
    if (map && retailerList?.length > 0) {
      const markersList = retailerList.map((retailer: any) => {
        const {
          locations,
          dba: name,
          location_type: locType,
          id,
          url,
          address,
          phone,
          city,
          state_or_province,
          zip,
        } = retailer;
        const locationsFormatted: any = locations.raw.split(',').map((loc: any) => Number(loc));
        const locationTypeFormatted: any = locType?.raw.split(' ').join('_').toLowerCase();
        const marker = new window.google.maps.Marker({
          title: `${name?.raw}`,
          icon: {
            ...markerDefaultConfig,
            fillColor: markerColors[locationTypeFormatted] || '#33687e',
            labelOrigin: new window.google.maps.Point(markerLabelX, markerLabelY),
          },
          label: {
            text: ' ',
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
          map,
          position: { lat: locationsFormatted[0], lng: locationsFormatted[1] },
        });

        marker.setZIndex(1);
        marker.set('type', 'point');
        marker.set('id', `${id.raw.toString()}`);
        const cmsBase = window?.spaBaseUrl || '';
        const markerHref = `${cmsBase}${url?.raw}`;
        const address1 = address?.raw?.[0];
        const address2 = address?.raw?.[1];
        const phoneNumber = phone?.raw ? formatPhoneNumber(phone?.raw) : '';

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
              <div class="cvc-info-window" style="z-index:1080;">
                <a href=${markerHref} class="cvc-info-window-title cvh-link">${name?.raw}</a>
                <div>
                  <p>
                    ${address1}
                    ${address2 || ''}
                    <br />
                    ${city?.raw}, ${state_or_province?.raw} ${zip?.raw?.[0]}
                    <br />
                    ${phoneNumber}
                  </p>
                </div>
                <div class="retailer-location-icon-label">
                  <div>${locType?.raw}</div>
                </div>
              </div>`,
          anchor: marker,
        });

        infoWindow?.close();

        return { infoWindow, marker };
      });

      setMarkers(markersList);
    }
  }, [map, retailerList]);

  const defaultOptions: ElasticOptions = useMemo(
    () => ({
      facets: {
        location_type: { type: 'value', sort: { value: 'desc' } },
      },
      query: '',
      filters: {
        all: [
          { type: 'retailer' },
          {
            ...(!stateCode && {
              locations: {
                center: `${locationLat},${locationLng}`,
                unit: countryCode === 'us' ? 'mi' : 'km',
                from: 0,
                to: searchRadius,
              },
            }),
          },
          {
            location_type: ['Retailer', 'Park Model Retailer', 'Community', 'Retirement Community'],
          },
          { community: tabValue },
          { ...(stateCode && { state_or_province: stateCode.toUpperCase() }) },
        ],
      },
      sort: {
        ...(stateCode
          ? { city: 'asc' }
          : {
              locations: {
                center: `${locationLat},${locationLng}`,
              },
            }),
      },
      page: {
        size: 20,
      },
    }),
    [stateCode, countryCode, locationLat, locationLng, searchRadius, tabValue]
  );

  const getRetailers = useCallback(
    async (config: any = null) => {
      setIsLoading(true);
      let esOptions: any = {};
      esOptions = {
        ...defaultOptions,
        page: {
          size: config?.page?.size,
        },
      };

      if (locationLat && locationLng) {
        const options = config ? esOptions : defaultOptions;
        const data = await client.search('', options);
        if (data.rawResults) {
          const augmentedResults = data.rawResults.map((item: any) => {
            const coordinates: number[] = item.locations?.raw.split(',');
            const distanceRetailer = getDistanceFromLatLonInKm(
              +locationLat,
              +locationLng,
              coordinates[0],
              coordinates[1]
            );
            item.directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${[
              locationLat,
              locationLng,
            ]}&destination=${[coordinates[0], coordinates[1]]}`;
            item.distanceUnit = distanceUnit;
            if (distanceUnit === 'miles') {
              item.distance = (Math.round(+distanceRetailer * 100) / 100) * oneKmInMiles;
            } else {
              item.distance = Math.round(+distanceRetailer * 100) / 100;
            }
            return item;
          });

          setRetailerList(augmentedResults);
          setRetailerCardsList(augmentedResults);
          setLocationType(data.rawInfo.facets.location_type[0].data);
          setfilteredResultsCount(data.rawInfo.meta.page.total_results);
        }
      }
      setIsLoading(false);
    },
    [client, defaultOptions, distanceUnit, locationLat, locationLng]
  );

  useEffect(() => {
    history.listen(() => {
      setRetailerList([]);
    });
  }, [history]);

  useEffect(() => {
    if (locationLat && locationLng) {
      getRetailers(elasticOptions);
    }
  }, [elasticOptions, getRetailers, locationLat, locationLng]);

  useEffect(() => {
    if (!markers && !!retailerList) {
      createMarkersFromRetailers();
    }

    if (!!markers && markers.length > 0) {
      markers.forEach((markerObj) => {
        const { marker, infoWindow } = markerObj;
        infoWindow?.addListener('closeclick', closeInfoWindow);
        marker.addListener('click', onMarkerClick);
      });
    }

    return () => {
      if (!!markers && markers.length > 0) {
        removeCurrentMarkers();
      }
    };
  }, [
    closeInfoWindow,
    createMarkersFromRetailers,
    markers,
    onMarkerClick,
    retailerList,
    removeCurrentMarkers,
  ]);

  useEffect(() => {
    if (!!retailerList && retailerList.length > 0) {
      createMarkersFromRetailers();
    }
  }, [retailerList, createMarkersFromRetailers]);

  const handleSearch = useCallback(() => {
    const { lat, lng, searchTerm, country } = search;
    if (lat && lng) {
      if (mapContainerEl.current) {
        regenerateMap({ lat: Number(lat), lng: Number(lng) });
      }
      const searchType = isCommunity ? SEARCH_TYPES.COMMUNITY : SEARCH_TYPES.RETAILER;
      const retailerOrCommunitySegment = isCommunity ? '/our-communities/' : '/our-retailers/';
      const pathname = location.pathname.replace(
        /\/(our-retailers|our-communities).*/,
        retailerOrCommunitySegment
      );
      const path = `${pathname}${country}?latitude=${lat}&longitude=${lng}&name=${searchTerm}`;
      // set the search item in localstorage
      setFloorPlanSearch({ ...floorPlanSearch, [searchType]: path });

      history.push(path);
    }
  }, [
    history,
    mapContainerEl,
    location.pathname,
    regenerateMap,
    search,
    setFloorPlanSearch,
    floorPlanSearch,
    isCommunity,
  ]);

  useEffect(() => {
    const searchInput: any = document.querySelector('.search-filter-autocomplete-input')!;
    if (searchInput) {
      const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>
      ) => {
        if (e.key === 'Enter') {
          if (!e?.target?.value) {
            setSearch({
              lat: null,
              lng: null,
              country: '',
              postalCode: '',
            });
            return;
          }
          handleSearch();
        }
      };

      searchInput.addEventListener('keydown', handleKeyDown);
      return () => searchInput.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleSearch]);

  const displayStateLevelMetaTags = () => {
    const metaTitle = isCommunity
      ? `Manufactured Home Communities in ${stateName}`
      : `Manufactured Home Dealers in ${stateName}`;
    const metaDescription = isCommunity
      ? `Find the right manufactured, modular or mobile home community for you in ${stateName}. View floor plans at cavcohomes.com.`
      : `Find the right manufactured, modular or mobile home dealer for you in ${stateName}. View floor plans at cavcohomes.com.`;

    return (
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
    );
  };

  const handleTabClick = () => {
    let newUrl;
    if (tabValue === 0) {
      newUrl = window.location.href.replace('our-retailers', 'our-communities');
    } else {
      newUrl = window.location.href.replace('our-communities', 'our-retailers');
    }
    window.location.href = newUrl;
  };

  const handleDisplayMobileMap = () => {
    setDisplayInMobile('map');
    scroller.scrollTo('tabContainer', {
      smooth: false,
      duration: 0,
      offset: 0,
    });
    // scroll to map
  };

  const displayMobileLinks = () => {
    return (
      <div className="retailer-mobile-links">
        <Button
          variant="link"
          className={`${displayInMobile === 'cards' ? 'active' : ''}`}
          onClick={() => setDisplayInMobile('cards')}
        >
          View List
        </Button>
        <Button
          variant="link"
          className={`${displayInMobile === 'map' ? 'active' : ''}`}
          onClick={() => handleDisplayMobileMap()}
        >
          View Map
        </Button>
      </div>
    );
  };

  const searchInputFocus = () => {
    const searchInput = document.querySelector('.search-filter-autocomplete-input');
    if (searchInput) {
      (searchInput as HTMLInputElement).focus();
    }
  };

  const retailersMapContainerClasses = classNames({
    show:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'map'),
    hide: (windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile !== 'map',
    'retailers-map-container': true,
  });

  const retailersCardsContainerClasses = classNames({
    show:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'cards'),
    hide: (windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile !== 'cards',
    'retailers-cards-container': true,
  });

  const handleSuggestionSelected = React.useCallback((data: any) => {
    geocodeByAddress(data.description)
      .then(async (results) => {
        const result = results[0];
        const postalCode: any = result.address_components.find(
          (el: any) => el.types[0] === 'postal_code'
        );
        const latLong = await getLatLng(result);
        return {
          ...latLong,
          country: getCountryByGeocode(results[0]),
          postalCode: postalCode?.short_name,
          searchTerm: result?.formatted_address,
        };
      })
      .then((latLong: SearchLatLong) => setSearch(latLong))
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Geocoding failed:', error));
    searchInputFocus();
  }, []);

  const handleLoadMore = () => {
    regenerateMap({ lat: Number(locationLat), lng: Number(locationLng) });
    setResultsPerPage(resultsPerPage + 12);
    setElasticOptions({
      ...elasticOptions,
      page: {
        size: resultsPerPage + 12,
      },
    });
  };

  if (!locationLat && !locationLng && retailerList.length === 0) {
    return (
      <Container className="cvc-retailer-list landing">
        <div className="cavcohomes-retailers-banner">
          <Typography
            className="cavcohomes-retailers-banner--title"
            gutterBottom
            align="center"
            component="h1"
            variant="h2"
          >
            {isCommunity ? bannerConfig?.searchCommunityTitle : bannerConfig?.searchRetailerTitle}
            {stateCode && stateName && ` in ${stateName}`}
          </Typography>
          <div className="search-filter-autocomplete">
            <IconButton className="search-filter-autocomplete-icon-button" onClick={handleSearch}>
              <SearchIcon className="search-filter-autocomplete-icon-button-icon" />
            </IconButton>
            <AutoCompleteComp
              apiKey={checkForNessus(configMap.apiKey)}
              placeholder={currentAddress || 'Search Zipcode'}
              onClick={handleSuggestionSelected}
              inputClassName="search-filter-autocomplete-input"
              initialValue=""
              geocodeEndpoint={configMap.mapEndpoint}
            />
          </div>
          <img
            className="cavcohomes-retailers-banner-img"
            alt="cavcohomes retailers search banner"
            src={`${bannerConfig?.bannerImage}`}
          />
        </div>
        <div className="cavcohomes-retailers-banner-body">
          <span>
            {isCommunity
              ? bannerConfig?.searchCommunitySubTitle
              : bannerConfig?.searchRetailerSubTitle}
          </span>
        </div>
      </Container>
    );
  }

  return (
    <Container className="cvc-retailer-list">
      {stateCode && stateName && displayStateLevelMetaTags()}
      <Typography
        className="cavcohomes-retailers-banner--title"
        gutterBottom
        align="center"
        component="h1"
        variant="h2"
      >
        {isCommunity ? bannerConfig?.searchCommunityTitle : bannerConfig?.searchRetailerTitle}
        {stateCode && stateName && ` in ${stateName}`}
      </Typography>
      <Row xs={12} className="cvc-retailer-list-search-filters-container no-gutters">
        <Col xs={12} lg={5} className="search-filter-autocomplete">
          {isLoading ? (
            <Spinner
              color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
            />
          ) : (
            <>
              <IconButton className="search-filter-autocomplete-icon-button" onClick={handleSearch}>
                <SearchIcon className="search-filter-autocomplete-icon-button-icon" />
              </IconButton>
              <AutoCompleteComp
                apiKey={checkForNessus(configMap.apiKey)}
                placeholder={currentAddress || 'Search Zipcode'}
                onClick={handleSuggestionSelected}
                inputClassName="search-filter-autocomplete-input"
                initialValue={currentAddress}
                geocodeEndpoint={configMap.mapEndpoint}
              />
            </>
          )}
        </Col>
        <Col id="tabContainer" className="retailers-tabs-container" xs={12} lg={5}>
          <Tabs
            className="retailers-tabs"
            value={tabValue}
            onChange={() => handleTabClick()}
            aria-label="search tab hero dropdown"
          >
            <Tab label="Dealers" />
            <Tab label="Communities" />
          </Tabs>
        </Col>
      </Row>
      <Row className="border-bottom mb-5 no-gutters">
        <div className="search-filter-locations-text-container mb-2">
          <span className="search-filter-locations-available">{filteredResultsCount}</span>{' '}
          {tabValue > 0 ? 'Communities' : 'Dealers'} Found
        </div>
      </Row>

      {!retailerList.length && <div>NO {tabValue > 0 ? 'COMMUNITY' : 'DEALERS'} RESULTS</div>}
      <Row className="no-horizontal-margin cvc-retailer-list-locations-container no-gutters">
        {windowDimensions.isMobile || windowDimensions.isTablet ? displayMobileLinks() : ''}
        <Col xs={12} lg={5} className={retailersCardsContainerClasses}>
          <div id={cardContainerId} className="retailer-list-wrapper" ref={cardContainerEl}>
            <div className="retailer-list-content">
              <div className="cvc-retailer-cards">
                <PlaceCards
                  myAccountConfig={myAccountConfig}
                  cards={retailerCardsList}
                  locationTypeDisplayOverrideMap={locationTypeOverrideMapping}
                />
              </div>
              {filteredResultsCount > resultsPerPage && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadMore}
                  className="btn btn-cta load-more"
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} lg={7} className={retailersMapContainerClasses}>
          <div id="map" ref={mapContainerEl} />
          <Container className="cvc-retailer-list-location-icons-container">
            {locationType?.map((type: any, idx: any) => {
              const locType = type.value.toLowerCase().split(' ').join('-');
              const legendDisplay = locationTypeOverrideMapping[type.value]
                ? locationTypeOverrideMapping[type.value]
                : type.value;
              return (
                <div key={idx} className="retailer-location-legend">
                  <RetailerIcon className={`retailer-location-icon is-${locType}`} />
                  <span className="retailer-location-icon-label">{legendDisplay}</span>
                </div>
              );
            })}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

interface PlaceCardsProps {
  cards: any;
  myAccountConfig?: any;
  locationTypeDisplayOverrideMap?: any;
}

const PlaceCards: React.FC<PlaceCardsProps> = ({
  cards,
  myAccountConfig,
  locationTypeDisplayOverrideMap,
}: PlaceCardsProps) => {
  return (
    <>
      {cards.length > 0 && (
        <RetailerCards
          myAccountConfig={myAccountConfig}
          cards={cards}
          locationTypeDisplayOverrideMap={locationTypeDisplayOverrideMap}
        />
      )}
    </>
  );
};

export const RetailerListMapper = ({ component, page: brPage }: BrProps) => {
  const { googlemapsconfiguration } = component.getModels();

  const googleConfig = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(googlemapsconfiguration, brPage)
  );

  return (
    <GoogleMapsProvider apiKey={String(checkForNessus(googleConfig.apiKey))}>
      <RetailerList component={component} page={brPage} />
    </GoogleMapsProvider>
  );
};
