import React, { FunctionComponent, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Col, Modal, Row } from 'react-bootstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Element, scroller } from 'react-scroll';

import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { styled } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField as FormikTextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { BrProps } from '@bloomreach/react-sdk';

import 'components/shared/ProductDetails/ProductRequestInfo.scss';
import './CavcoFloorPlanRequestInfo.scss';
import { Spinner } from 'components/shared/Spinner';

import { getRetailerData, getTrafficSource, TagManager } from '../utils';

import useLasso from '../../lasso';

import * as CONSTANTS from './RequestInfoForm.constants';
import { ModalLaunchContext } from '../hooks/ModalLaunch.context';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import {
  formConfigurationMapper,
  formSubmit,
  handleErrorMessage,
} from './RequestInforForm.services';
import { FormConfigurationProps } from './requestInfoForm.interface';
import { StyledSnackbar } from './RequestInfoFormStyledComponents';

const StyledAlert = styled(MuiAlert)({
  display: 'flex',
  alignItems: 'center',
  color: '#FFF',
  '& .MuiSvgIcon-root': {
    fill: '#FFF !important',
  },
});

function Alert(props: AlertProps) {
  return (
    <StyledAlert className="info-form-snackbar__alert" elevation={6} variant="filled" {...props} />
  );
}

export function CavcoFloorPlanRequestInfo(
  props: BrProps,
  displayInsideModal = false,
  formId = 'floor-plan-request-info-form'
) {
  const {
    formLabels,
    isRetailer,
    displayAsModal,
    formModalLabel,
    version,
    statesProvinceList,
    apiUrl,
    spinnerConfig,
    webType,
    assetId,
  }: FormConfigurationProps = formConfigurationMapper(props, displayInsideModal);

  const { lasso } = useLasso();

  const pageLocation = useLocation();
  const retailerData = getRetailerData(props.page);
  const locationID = retailerData && retailerData.locationID ? retailerData.locationID : '';

  const { globalModal, setGlobalModal } = useContext<any>(ModalLaunchContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [responseContent, setResponseContent] = useState<any | null>(undefined);
  const [submittedData, setSubmittedData] = useState<any>(null);

  const scrollToTop = (touch: any, err: any) => {
    if (Object.entries(touch)?.length <= 0 || Object.entries(err).length > 0) {
      scroller.scrollTo(formId, {
        duration: 1000,
        smooth: true,
        offset: -150,
      });
    }
  };

  const closeResetForm = () => {
    setSubmittedData(null);
    setGlobalModal(false);
  };

  const closeSnackbarHandler = (isError: boolean) => {
    setResponseContent(null);
    if (!isError) {
      closeResetForm();
    }
  };

  const openSnackbarHandler = (isError = false) => {
    const data = isError
      ? {
          title: formModalLabel.modalErrorTitle,
          text: formModalLabel.modalText,
          isError,
        }
      : { title: formModalLabel.modalTitle, text: formModalLabel.modalText, isError };

    setResponseContent(data);
  };

  const tagManagerHandler = (tmData: any) => {
    TagManager.dataLayer({
      dataLayer: { ...tmData },
    });
  };
  const statesProvinceList_sorted = statesProvinceList?.sort(
    (a: { key: string; value: string }, b: { key: string; value: string }) =>
      a.key < b.key ? -1 : 1
  );
  const validstatesProvinceValues: string[] = statesProvinceList_sorted.map(
    (item: any) => item.value
  );

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const future = new Date('12/31/9999');

  const RequestInfoFormSchema = Yup.object().shape({
    firstName: Yup.string().required(formLabels.firstName_errormsg),
    lastName: Yup.string().required(formLabels.lastName_errormsg),
    locationToBuildCity: Yup.string().required(formLabels.city_errormsg),
    locationToBuildState: Yup.string()
      .required(formLabels.state_errormsg)
      .oneOf(validstatesProvinceValues, formLabels.state_errormsg),
    locationToBuildZip: Yup.string()
      .required(formLabels.zip_errormsg)
      .matches(CONSTANTS.ZIPCODE_REGEX, formLabels.zip_errormsg),
    emailAddress: Yup.string()
      .required(formLabels.email_errormsg)
      .matches(CONSTANTS.EMAIL_REGEX, formLabels.email_errormsg),
    phoneNo: Yup.string()
      .required(formLabels.phone_errormsg)
      .matches(CONSTANTS.PHONE_REGEX, formLabels.phone_errormsg),
    ownLand: Yup.string()
      .required(formLabels.ownLand_errormsg)
      .oneOf([formLabels?.ownNo, formLabels?.ownYes]),
    appointmentDate: Yup.date()
      .min(yesterday, formLabels?.date_errormsg)
      .max(future, formLabels?.date_errormsg),
    comments: Yup.string(),
  });

  const customError = (message: string) => {
    return <p className="MuiFormHelperText-root Mui-error Mui-required">{message}</p>;
  };

  const FormElement = () => {
    const getDefaultInitialValues = () => ({
      ...CONSTANTS.REQUEST_INFO_FORM_DEFAULT,
      version: version || CONSTANTS.FORM_TYPE_VALUES.SIDEBAR,
      comments: formLabels.commentPlaceholderText,
      currentPageUrl: window.location.href,
      locationUid: locationID,
      webType,
      trafficSource: getTrafficSource(lasso.guid),
      currentPageAssetId: assetId,
    });

    return (
      <Element key="cvc-fp-availability-element" name="contact-info" id="fp-request-info-section">
        <div
          id={`fp-request-info${displayInsideModal ? '-inside-modal' : ''}`}
          className={`cavco-fp-request-info cvc-product-request-info ${
            !isRetailer && retailerData ? ' retailer-form' : ''
          }`}
        >
          <div className="cvc-request-form-header">
            <h2 className="cvc-fp-request-form-title">{formLabels.title}</h2>
          </div>

          <Formik
            initialValues={submittedData ? { ...submittedData } : getDefaultInitialValues()}
            validationSchema={RequestInfoFormSchema}
            onSubmit={async (values: any) => {
              setIsSubmitting(true);
              values.phoneNo = values.phoneNo.replace(/-/g, '');
              setSubmittedData({
                ...values,
                webType,
                currentPageAssetId: assetId,
              });
              await formSubmit(values, apiUrl)
                .then((response: any) => {
                  setIsSubmitting(false);
                  if (response.status < 300) {
                    openSnackbarHandler(false);
                    tagManagerHandler({
                      event: 'formSubmission',
                      formId,
                      pageHref: pageLocation.pathname,
                    });
                  } else {
                    openSnackbarHandler(true);
                    tagManagerHandler({
                      event: 'formError',
                      errorMessage: handleErrorMessage(response, formModalLabel),
                      formId,
                      pageHref: pageLocation.pathname,
                    });
                  }
                })
                .catch((error: any) => {
                  openSnackbarHandler(true);
                  tagManagerHandler({
                    event: 'formError',
                    errorMessage: handleErrorMessage(error, formModalLabel),
                    formId,
                    pageHref: pageLocation.pathname,
                  });
                });
            }}
          >
            {({ setFieldValue, values, touched, errors }) => {
              return (
                <Form id={formId} className="cvc-form " name={formId}>
                  <h5 className="form-titles contact-info">
                    {formLabels?.formHeading_contactInfo}
                  </h5>
                  <Row>
                    <Col md={6} xs={12}>
                      <Field
                        id="firstName"
                        className="cvc-customer-support__input"
                        label={`${formLabels.firstName}*`}
                        fullWidth
                        name="firstName"
                        value={values.firstName}
                        component={FormikTextField}
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <Field
                        id="lastName"
                        className="cvc-customer-support__input"
                        name="lastName"
                        label={`${formLabels.lastName}*`}
                        fullWidth
                        value={values?.lastName}
                        component={FormikTextField}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Field
                        id="emailAddress"
                        className="cvc-customer-support__input"
                        name="emailAddress"
                        label={`${formLabels.email}*`}
                        fullWidth
                        value={values?.emailAddress}
                        component={FormikTextField}
                      />
                    </Col>
                    <Col sm={6}>
                      <Field
                        id="phoneNo"
                        className="cvc-customer-support__input"
                        name="phoneNo"
                        type="tel"
                        label={`${formLabels.phone}*`}
                        fullWidth
                        value={values?.phoneNo}
                        component={FormikTextField}
                      />
                    </Col>
                  </Row>
                  <h5 className="form-titles location">{formLabels?.formHeading_location}</h5>
                  <Row>
                    <Col sm={6}>
                      <Field
                        id="locationToBuildCity"
                        className="cvc-customer-support__input"
                        name="locationToBuildCity"
                        label="City*"
                        fullWidth
                        value={values?.locationToBuildCity}
                        component={FormikTextField}
                      />
                    </Col>
                    {statesProvinceList_sorted && (
                      <Col md={2} sm={3} xs={6}>
                        <FormControl
                          fullWidth
                          error={!!touched.locationToBuildState && !!errors.locationToBuildState}
                          className={`cvc-customer-support__select ${
                            touched?.locationToBuildState && errors.locationToBuildState
                              ? 'Mui-error'
                              : ''
                          }`}
                        >
                          <Field
                            id="locationToBuildState"
                            //labelId="locationToBuildState-label"
                            name="locationToBuildState"
                            value={values?.locationToBuildState}
                            component="select"
                          >
                            <option label="--" disabled />
                            {statesProvinceList_sorted.map(({ key, value }: any) => {
                              return (
                                <option
                                  key={`option_${value}`}
                                  value={value as string}
                                  className="option-state"
                                >
                                  {key}
                                </option>
                              );
                            })}
                          </Field>
                          <InputLabel
                            id="locationToBuildState-label"
                            htmlFor="locationToBuildState"
                          >
                            State*
                          </InputLabel>
                        </FormControl>
                        <ErrorMessage
                          name="locationToBuildState"
                          render={() => customError(formLabels.state_errormsg)}
                        />
                      </Col>
                    )}
                    <Col md={4} sm={3} xs={6}>
                      <Field
                        id="locationToBuildZip"
                        className="cvc-customer-support__input"
                        name="locationToBuildZip"
                        label="Zip*"
                        type="decimal"
                        fullWidth
                        value={values?.locationToBuildZip}
                        component={FormikTextField}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="cvc-form-group ownland-container">
                      <h5
                        id="ownLand-group-label"
                        className="form-titles own-land"
                        style={{ paddingBottom: '40px' }}
                      >
                        {formLabels.ownLand}*
                      </h5>
                      <div
                        id="ownLandRadioGroup"
                        role="radiogroup"
                        aria-labelledby="ownLand"
                        className="land-radios"
                      >
                        <FormControl required error={!!errors.ownLand}>
                          <RadioGroup
                            id="ownLand"
                            role="radio"
                            className="cvc-radio-group"
                            aria-labelledby="ownLand-group-label"
                            name="ownLand"
                            value={values.ownLand}
                            onChange={(event) => {
                              setFieldValue('ownLand', event.currentTarget.value);
                            }}
                          >
                            <FormControlLabel
                              value={formLabels.ownYes}
                              control={<Radio />}
                              label={formLabels.ownYes}
                            />
                            <FormControlLabel
                              value={formLabels.ownNo}
                              control={<Radio />}
                              label={formLabels.ownNo}
                            />
                          </RadioGroup>
                          <ErrorMessage
                            name="ownLand"
                            render={() => customError(formLabels?.ownLand_errormsg)}
                          />
                        </FormControl>
                      </div>
                    </Col>
                  </Row>
                  <h5 className="form-titles date" style={{ paddingBottom: '40px' }}>
                    {formLabels.aptDate}
                  </h5>
                  <Row>
                    <Col sm={6} xs={12} className="position-relative">
                      {/* eslint-disable-next-line */}
                      <label htmlFor="appointmentDate">
                        <span className="label-aria-hidden">{formLabels?.date_label}</span>
                        <Field
                          id="appointmentDate"
                          className="cvc-customer-support__input cvc-customer-support__input--date"
                          name="appointmentDate"
                          type="date"
                          fullWidth
                          value={values.appointmentDate}
                          component={FormikTextField}
                          aria-labelledby="appointmentDate"
                          label={formLabels?.date_label}
                        />
                      </label>
                    </Col>
                    <Col sm={6} xs={12} className="position-relative">
                      {/* eslint-disable-next-line */}
                      <label htmlFor="appointmentTime">
                        <span className="label-aria-hidden">{formLabels?.time_label}</span>
                        <Field
                          id="appointmentTime"
                          className="cvc-customer-support__input cvc-customer-support__input--time"
                          name="appointmentTime"
                          min="9:00"
                          max="18:00"
                          type="time"
                          fullWidth
                          value={values.appointmentTime}
                          component={FormikTextField}
                          label={formLabels?.time_label}
                        />
                      </label>
                    </Col>
                  </Row>

                  <h5 className="form-titles comments">{formLabels?.formHeading_comments}</h5>
                  <div className="form-row mb-2 comments-container">
                    <Col>
                      <Field
                        id="comments"
                        className="cvc-customer-support__input"
                        name="comments"
                        label={formLabels.comments}
                        placeholder={formLabels.commentPlaceholderText}
                        fullWidth
                        multiline
                        component={FormikTextField}
                      />
                    </Col>
                  </div>

                  <section>
                    <div className="cvc-customer-support__privacy">
                      <p>{formLabels.required}</p>
                      <p>
                        {formLabels.privacy} <a href={formLabels.privacyUrl}>Privacy Policy</a>
                      </p>
                    </div>
                    <div className="cvc-customer-support__submission">
                      <button
                        className="btn btn-cta"
                        type="submit"
                        onClick={() => scrollToTop(touched, errors)}
                      >
                        {formLabels?.btn_submit}
                      </button>
                    </div>
                  </section>

                  {isSubmitting ? (
                    <Spinner
                      color={
                        spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''
                      }
                    />
                  ) : (
                    ''
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
        <StyledSnackbar
          open={!!responseContent}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={
            responseContent?.isError
              ? CONSTANTS.SNACKBAR_DURATIONS.error
              : CONSTANTS.SNACKBAR_DURATIONS.success
          }
          onClose={() => closeSnackbarHandler(responseContent?.isError)}
        >
          <Alert
            className="info-form-snackbar"
            severity={responseContent?.isError ? 'error' : 'success'}
          >
            <span className="info-form-snackbar__title">{responseContent?.title}</span>
            <span className="info-form-snackbar__text">{responseContent?.text}</span>
          </Alert>
        </StyledSnackbar>
      </Element>
    );
  };

  return displayAsModal ? (
    <Modal
      show={globalModal === 'retailerRequestInfoForm'}
      onHide={() => setGlobalModal(null)}
      className="retailer-request-info-modal"
    >
      <button
        type="button"
        onClick={() => setGlobalModal(null)}
        className="retailer-request-info-modal__closer"
      >
        <CloseIcon />
      </button>
      <FormElement />
    </Modal>
  ) : (
    <FormElement />
  );
}

export const FloorPlanRequestInfoWithParams: FunctionComponent<any> = (fpRequestInfoProps) => {
  const { displayInsideModal, formId, ...props } = fpRequestInfoProps;
  return CavcoFloorPlanRequestInfo(props, displayInsideModal, formId);
};
