import React, { useState } from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { getDisplayHomeDataModel } from 'components/utils';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import 'components/shared/ProductDetails/ProductPhotos.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';

function getImage(imageGalleryName: string, item: any, imgContent: any) {
  let cavcoThumbnailImgPath = '';
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';

  if (imgContent) {
    cavcoThumbnailImgPath =
      imgContent.model.cavcoThumbnail && imgContent.model.cavcoThumbnail._links.site.href
        ? imgContent.model.cavcoThumbnail._links.site.href
        : null;
    mediumImgPath =
      imgContent.model.medium && imgContent.model.medium._links.site.href
        ? imgContent.model.medium._links.site.href
        : null;
    largeImgPath =
      imgContent.model.large && imgContent.model.large._links.site.href
        ? imgContent.model.large._links.site.href
        : null;
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.ximage) {
    cavcoThumbnailImgPath =
      item.ximage.cavcoThumbnail && item.ximage.cavcoThumbnail.url
        ? item.ximage.cavcoThumbnail.url
        : null;
    mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
  }

  return (
    <picture key={`${imageGalleryName}-${item.id}`}>
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={extraLargeImgPath} />
      <source
        media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
        srcSet={largeImgPath}
      />
      <source
        media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
        srcSet={mediumImgPath}
      />
      <img alt={item.imageAltText} src={cavcoThumbnailImgPath} />
    </picture>
  );
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  imgContent: any,
  index: any,
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="button"
      key={`${imageGalleryName}-image-${item.id}-${index}`}
      className="cvc-product-photos-media-item"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      {imgContent || item.ximage ? getImage(imageGalleryName, item, imgContent) : ''}
    </div>
  );
}

function displayMediaAssets(
  props: BrProps,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.IMAGE) return '';

  return mediaAssetItems.map((item: any, index) => {
    const imgContent: any = item && props.page.getContent(item.image);

    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      imgContent,
      index,
      windowDimensions,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function DisplayHomePhotos(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const imageGalleryName = props.component.getName();

  const { document: ref, inventoryFloorplan: floorPlanRef } = getDisplayHomeDataModel(props.page);
  const document = props.page.getContent(ref);

  if (!document) {
    return null;
  }

  const { photoAlbum: photosRef } = document.getData<InventoryData>();

  let photosDocument = null;
  let lineDrawingsDocument = null;

  let albumId = '';
  let albumMediaType = '';
  let albumTitle = '';
  let mediaItems = null;

  const inventoryPhotoAlbum = photosRef && props.page.getContent(photosRef);

  // If there's an inventory album, use that.
  // If not get the floorplan photo and/or line drawing album
  if (!inventoryPhotoAlbum) {
    const floorPlanDocument = floorPlanRef ? props.page.getContent(floorPlanRef) : undefined;
    if (!floorPlanDocument) {
      return null;
    }
    const { photos: fpPhotoRef, lineDrawings: fpLineDrawingRef } =
      floorPlanDocument && floorPlanDocument.getData<FloorPlanData>();
    const fpPhotoDocument = fpPhotoRef && props.page.getContent(fpPhotoRef);
    const fpLineDrawingDocument = fpLineDrawingRef && props.page.getContent(fpLineDrawingRef);

    photosDocument = fpPhotoDocument || fpLineDrawingDocument;
    lineDrawingsDocument = fpPhotoDocument && fpLineDrawingDocument ? fpLineDrawingDocument : null;
  } else {
    photosDocument = inventoryPhotoAlbum;
  }

  if (photosDocument) {
    const { id, title, mediaType, mediaAsset } = photosDocument.getData<AlbumData>();
    albumId = id;
    albumTitle = title;
    albumMediaType = mediaType;
    mediaItems = mediaAsset;
    if (lineDrawingsDocument) {
      const {
        title: ldAlbumTitle,
        mediaType: ldMediaType,
        mediaAsset: ldMediaAsset,
      } = lineDrawingsDocument.getData<AlbumData>();
      if (albumMediaType === ldMediaType) {
        albumTitle = albumTitle.concat(' & ', ldAlbumTitle);
        mediaItems = mediaItems.concat(ldMediaAsset) as [MediaAssetCompound];
      }
    }
  } else {
    return null;
  }

  const displayHomePhotosClasses = classNames({
    'cvc-display-home-photos': true,
    'cvc-product-photos': true,
  });

  const mediaAssetItems = mediaItems;
  const noPhotos = mediaItems.find((item) => item.image || item.ximage) === undefined;

  if (noPhotos) {
    return null;
  }

  const floorItemsContainerClasses = classNames({
    'cvc-product-photos-items-container': true,
  });

  return (
    <div
      id={`${imageGalleryName}-${albumId}`}
      className={displayHomePhotosClasses}
      key={`${imageGalleryName}-${albumId}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={albumMediaType}
        mediaAssetItems={mediaAssetItems}
        albumName={albumTitle}
        albumLink=""
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
      />
      <div className={floorItemsContainerClasses}>
        {displayMediaAssets(
          props,
          imageGalleryName,
          albumMediaType,
          mediaAssetItems,
          windowDimensions,
          setIndexItemSelected,
          handleShowMediaViewer
        )}
      </div>
    </div>
  );
}
