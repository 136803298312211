import classNames from 'classnames';
import React from 'react';
import { Spinner as ReactBoostrapSpinner } from 'react-bootstrap';
import './Spinner.scss';

export const Spinner = ({ isShow = true, inDarkBackground = false, color, size }: any) => {
  return isShow ? (
    <ReactBoostrapSpinner
      animation="border"
      role="status"
      size={size || ''}
      style={color ? { color: `${color}` } : {}}
      className={classNames(inDarkBackground ? ' in-dark-background' : '')}
    >
      <span className="sr-only">Loading...</span>
    </ReactBoostrapSpinner>
  ) : (
    <></>
  );
};
