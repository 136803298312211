import React from 'react';

export const useStateWithLocalStorage = (localStorageKey: string, initialVal: any = null) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey)!)
      : initialVal
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};
