import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, Button, Card, Col, Row } from 'react-bootstrap';
import { Content } from '@bloomreach/spa-sdk';
import { ReactComponent as CaretIcon } from '../../assets/images/caret-down.svg';
import SiteGlobalSwitches, {
  formatBytes,
  getBuildingCenterFromHeader,
  validateFileCompounds,
} from '../utils';

import './BuildingCenterSeriesList.scss';

function AccordionIcon() {
  const currentEventKey = useContext(AccordionContext);
  return (
    <>
      {currentEventKey ? (
        <CaretIcon className="accordion-toggle-icon caret-turned-up" />
      ) : (
        <CaretIcon className="accordion-toggle-icon" />
      )}
    </>
  );
}

function listSeriesAssetDownloads(assets: any) {
  return assets.map((asset: any, indexPdf: number) => {
    const { id, uploadSize: fileSize, uploadFileName, fileName } = asset;
    if (
      fileName === null ||
      fileName.length === 0 ||
      uploadFileName === null ||
      uploadFileName.length === 0
    )
      return null;
    const fileSizeStr = formatBytes(fileSize);

    return (
      <li key={`asset-${id}-${indexPdf.toString()}`}>
        <ResolverLink target="_blank" href={uploadFileName}>
          {fileName}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function listSeriesDecorImages(albumImages: any) {
  return albumImages.map((mediaAsset: any, index: number) => {
    const { description, ximage } = mediaAsset;
    const image = ximage && ximage.extraLarge;
    const url = image && image.url;
    const fileSize = image && image.size;

    if (url === null || url.length === 0 || description === null || description.length === 0) {
      return null;
    }
    const fileSizeStr = formatBytes(fileSize);
    return (
      <li key={`image-${index.toString()}`}>
        <ResolverLink className="decor-image" target="_blank" href={url}>
          {description}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function displaySeriesInfo(
  page: any,
  document: any,
  index: string,
  resourceBundleLabels: any,
  isCommunity: boolean
) {
  const seriesDoc = page.getContent(document);
  const {
    name: seriesName,
    standardFeatures,
    assets,
    descriptionNotUsed: description,
    decorPhotoAlbum: decorAlbumRef,
  } = seriesDoc.getData();

  const seriesParam = SiteGlobalSwitches.useElasticSearchFloorplans
    ? `%5B"${seriesName.split(' ').join('+')}"%5D`
    : seriesName.replace(/\s+/g, '').toLowerCase();

  const seriesLink = `./floorplans?series=${seriesParam}`;

  const comingSoonText =
    resourceBundleLabels && resourceBundleLabels.infoComingLabel
      ? resourceBundleLabels.infoComingLabel
      : 'Information Coming Soon';
  const descriptionHeader =
    resourceBundleLabels && resourceBundleLabels.descriptionLabel
      ? resourceBundleLabels.descriptionLabel
      : 'Description';
  const downloadsHeader =
    resourceBundleLabels && resourceBundleLabels.downloadsLabel
      ? resourceBundleLabels.downloadsLabel
      : 'Download Files';
  const featuresHeader =
    resourceBundleLabels && resourceBundleLabels.featuresLabel
      ? resourceBundleLabels.featuresLabel
      : 'Standard Features';

  const decorAlbum: Content = decorAlbumRef && page.getContent(decorAlbumRef);
  let albumImages: any;

  if (decorAlbum) {
    const { mediaAsset } = decorAlbum.getData<AlbumData>();
    albumImages = mediaAsset;
  }

  const hasDescription = description && description.value;
  const hasStandardFeatures = standardFeatures && standardFeatures.value;

  const hasAssets = assets && validateFileCompounds(assets);
  const hasFiles = hasAssets || (albumImages && albumImages.length > 0);

  const displayDescription = () => {
    return (
      <>
        {hasDescription ? (
          <div
            className="cvc-bc-series-accordion-column-content description"
            dangerouslySetInnerHTML={{ __html: page.rewriteLinks(description.value) }}
          />
        ) : (
          <div>{comingSoonText}</div>
        )}
      </>
    );
  };

  const displaySeriesDownloads = () => {
    return (
      <div className="cvc-bc-series-accordion-column-content downloads">
        <ul className="cvc-download-files-list">
          {hasAssets && <>{listSeriesAssetDownloads(assets)}</>}
          {decorAlbum && <>{listSeriesDecorImages(albumImages)}</>}
        </ul>
      </div>
    );
  };

  const displayStandardFeatures = () => {
    return (
      <>
        {hasStandardFeatures ? (
          <div
            className="cvc-bc-series-accordion-column-content features"
            dangerouslySetInnerHTML={{ __html: page.rewriteLinks(standardFeatures.value) }}
          />
        ) : (
          <div>{comingSoonText}</div>
        )}
      </>
    );
  };

  return (
    <Accordion className="cvc-bc-series-accordion">
      <Card>
        <Card.Header className="cvc-bc-series-accordion-header">
          <Row>
            <Accordion.Toggle as={Col} className="left-column" eventKey={index}>
              <AccordionIcon />
              <h2 className="series-name">
                Series: <strong>{seriesName}</strong>
              </h2>
            </Accordion.Toggle>
            <Col className="right-column">
              <span className="view-floorplans-button">
                <Accordion.Toggle className="btn btn-primary toggle-button" eventKey={index}>
                  Details
                </Accordion.Toggle>
                {!isCommunity && <Button href={seriesLink}>Floor Plans</Button>}
              </span>
            </Col>
          </Row>
        </Card.Header>
        <Accordion.Collapse className="cvc-bc-series-accordion-collapse" eventKey={index}>
          <Card.Body className="cvc-bc-series-accordion-body">
            <Row>
              <div className="left-column">
                <h4 className="cvc-bc-series-accordion-section-title">{descriptionHeader}</h4>
                <div className="description-section">{displayDescription()}</div>
                <h4 className="cvc-bc-series-accordion-section-title">{downloadsHeader}</h4>
                <div className="downloads-section">
                  {hasFiles ? displaySeriesDownloads() : <span>{comingSoonText}</span>}
                </div>
              </div>
              <div className="right-column">
                <h4 className="cvc-bc-series-accordion-section-title">{featuresHeader}</h4>
                <div className="features-section">{displayStandardFeatures()}</div>
              </div>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export function BuildingCenterSeriesList(props: BrProps) {
  const { documents, buildingCenterSeriesListLabels, isCommunity } = props.component.getModels();
  if (!documents) {
    return null;
  }
  let communitySeriesMarketingText;
  if (isCommunity) {
    const buildingCenter = getBuildingCenterFromHeader(props.page);
    communitySeriesMarketingText = buildingCenter && buildingCenter.communitySeriesMarketingText;
  }

  return (
    <>
      <div className="cvc-bc-series-list">
        {documents.map((document: any, index: number) => (
          <div
            className="cvc-bc-series-list-item"
            id={`content-${index.toString()}`}
            key={`content-${index.toString()}`}
          >
            {displaySeriesInfo(
              props.page,
              document,
              index.toString(),
              buildingCenterSeriesListLabels,
              isCommunity
            )}
          </div>
        ))}
      </div>
      {communitySeriesMarketingText && communitySeriesMarketingText.value && (
        <div className="cvc-bc-community-series-marketing-text">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: props.page.rewriteLinks(communitySeriesMarketingText.value),
            }}
          />
        </div>
      )}
    </>
  );
}
