import React, { useState, useEffect, FunctionComponent } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { useLocation } from 'react-router-dom';
import { Container, Button, Form, ListGroup, Collapse } from 'react-bootstrap';
import './DisplayHomeList.scss';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  FiltersIcon,
  CloseIcon,
  RightArrowIcon,
  CaretDownIcon,
  AddIcon,
  RemoveIcon,
  DoneIcon,
} from 'components/shared/Icons';
import { GlobalScrollToTopButton, ResolverLink } from 'components/shared';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { compareValues } from '../utils';
import { DisplayHomeCard } from './DisplayHomeCard';
import { NoDisplayHomesFoundForFilters } from './NoDisplayHomesFoundForFilters';
import { DisplayHomeSearch } from './DisplayHomeSearch';
import { DisplayHomeDocument } from './DisplayHomeList.interface';

function displayDisplayHomeCards(
  props: BrProps,
  displayHomeDocumentsInput: any,
  windowDimensions: any
) {
  if (!displayHomeDocumentsInput || displayHomeDocumentsInput === undefined) {
    return NoDisplayHomesFoundForFilters();
  }

  if (
    displayHomeDocumentsInput !== undefined &&
    displayHomeDocumentsInput.length > 0 &&
    displayHomeDocumentsInput[0].id !== ''
  ) {
    const displayDHCards = () => {
      return (
        <div className="display-homes-cards-container cvc-product-list-cards">
          {displayHomeDocumentsInput.map((dhDocument: any, index: number) => {
            const keyString = `display-home-card-${index.toString()}`;
            return (
              <DisplayHomeCard
                key={keyString}
                displayHomeDocumentData={dhDocument}
                windowDimensions={windowDimensions}
                props={props}
              />
            );
          })}
        </div>
      );
    };
    return displayDHCards();
  }
}

enum DISPLAY_HOME_FIELDS {
  NUM_BEDROOMS = 'numBedrooms',
  NUM_BATHROOMS = 'numBathrooms',
  SQUARE_FOOTAGE = 'squareFootage',
  SECTIONS = 'sections',
  BUILDING_METHOD = 'buildingMethodValue',
  MEDIA = 'media',
}

export function DisplayHomeList(props: BrProps) {
  const [displayInMobile, setDisplayInMobile] = useState('cards');
  const [yourFilters, setYourFilters] = useState([
    {
      id: '',
      text: '',
      value: '',
      displayHomeField: '',
      allowsMultipleSelection: true,
      valueType: '',
    },
  ]);
  const [yourFiltersInitial, setYourFiltersInitial] = useState([
    {
      id: '',
      text: '',
      value: '',
      displayHomeField: '',
      allowsMultipleSelection: true,
      valueType: '',
    },
  ]);

  const [sortByFilterTitle, setSortByFilterTitle] = useState('');
  const [sortByFilterSelected, setSortByFilterSelected] = useState('');
  const [sortByOrderSelected, setSortByOrderSelected] = useState('');
  const [showListGroupSortByOptions, setShowListGroupSortByOptions] = useState(false);
  const [sortOptionsMobileOpen, setSortOptionsMobileOpen] = useState(false);
  const [accordionFiltersMobileOpen, setAccordionFiltersMobileOpen] = useState(false);
  const [displayHomeDocumentsOriginal, setDisplayHomeDocumentsOriginal] = useState([]);
  const mapByValue = new Map();

  const [displayHomeDocuments, setDisplayHomeDocuments] = useState<DisplayHomeDocument[]>([]);
  // eslint-disable-next-line

  const windowDimensions = useWindowDimensions();
  const compModels = props.component.getModels();

  // const loadFavoriteUrl = compModels.myAccountConfig
  //  ? compModels.myAccountConfig.cavcoFavoriteUrl
  //  : null;
  // initAllFavoritesForCurrentUser(loadFavoriteUrl);

  const breadcrumb = undefined;
  const { isSearchPage, cardLimit } = props.component.getParameters();

  const indexDefaultFilterOpen = compModels.cards && compModels.cards.length > 0 ? '0' : '';
  const [indexesAccordionGroupOpened, setIndexesAccordionGroupOpened] = useState([
    indexDefaultFilterOpen,
  ]);

  const noDocuments = compModels.noDocuments || (compModels.cards && compModels.cards.length === 0);
  const stdDHFiltersModelVal = compModels.stdDHFilters ? compModels.stdDHFilters : null;
  const totalDisplayHomeCount =
    !isSearchPage && compModels.displayHomeCount ? compModels.displayHomeCount : 0;

  const { dhSort } = compModels;

  const sortTitlesArray = dhSort ? dhSort.sortTitles.split(',') : [];
  const sortFieldsArray = dhSort ? dhSort.sortFields.split(',') : [];

  const searchParameters = useLocation().search;
  let numBedroomsVal = '';
  let numBathroomsVal = '';
  let homeUpperBound = '';
  let homeLowerBound = '';
  let homeSizeParam = '';

  let sectionsVal = '';
  let buildingMethodVal = '';
  let squareFootageMultipleValues = '';
  let mediaParam = '';
  let sortByVal = '';

  if (searchParameters) {
    numBedroomsVal = queryString.parse(searchParameters).numBedrooms
      ? queryString
          .parse(searchParameters)
          .numBedrooms!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';
    numBathroomsVal = queryString.parse(searchParameters).numBathrooms
      ? queryString
          .parse(searchParameters)
          .numBathrooms!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';
    homeUpperBound = queryString.parse(searchParameters).homeUpperBound
      ? queryString.parse(searchParameters).homeUpperBound!.toString()
      : '';
    homeLowerBound = queryString.parse(searchParameters).homeLowerBound
      ? queryString.parse(searchParameters).homeLowerBound!.toString()
      : '';

    sectionsVal = queryString.parse(searchParameters).sections
      ? queryString.parse(searchParameters).sections!.toString()
      : '';
    buildingMethodVal = queryString.parse(searchParameters).buildingMethodValue
      ? queryString.parse(searchParameters).buildingMethodValue!.toString()
      : '';

    squareFootageMultipleValues = queryString.parse(searchParameters).squareFootage
      ? queryString
          .parse(searchParameters)
          .squareFootage!.toString()
          .replace('lt', '-')
          .replace('gt', '+')
      : '';

    mediaParam = queryString.parse(searchParameters).media
      ? queryString.parse(searchParameters).media!.toString()
      : '';

    sortByVal = queryString.parse(searchParameters).sortBy
      ? queryString.parse(searchParameters).sortBy!.toString()
      : '';
  }

  if (homeUpperBound !== '' && homeLowerBound !== '') {
    homeSizeParam = `${homeLowerBound} - ${homeUpperBound}`;
  } else if (homeUpperBound !== '') {
    homeSizeParam = `-${homeUpperBound}`;
  } else if (homeLowerBound !== '') {
    homeSizeParam = `${homeLowerBound}+`;
  }

  const matchesCriteria = (item: any, inputMatchesCriteriaArray: any) => {
    let count = 0;

    for (let n = 0; n < inputMatchesCriteriaArray.length; n += 1) {
      if (!inputMatchesCriteriaArray[n].allowsMultipleSelection) {
        if (
          Math.round(item[inputMatchesCriteriaArray[n].field] + Number.EPSILON) <=
          Number(inputMatchesCriteriaArray[n].values[0])
        ) {
          count += 1;
        }
      } else if (inputMatchesCriteriaArray[n].field === 'media') {
        let matchCount = 0;
        const criteriaItemValues = inputMatchesCriteriaArray[n].values;
        for (let i = 0; i < criteriaItemValues.length; i += 1) {
          switch (criteriaItemValues[i]) {
            case '3D Virtual Tours':
              matchCount = item.has3dTourAlbum != null ? (matchCount += 1) : matchCount;
              break;
            case 'Videos':
              matchCount = item.videoAlbum != null ? matchCount + 1 : matchCount;
              break;
            case 'Photos':
              matchCount = item.photoUrls != null ? matchCount + 1 : matchCount;
              break;
            case 'File Downloads':
              matchCount = item.hasDownloadableFiles ? matchCount + 1 : matchCount;
              break;
            default:
          }
        }
        if (matchCount > 0) {
          count += 1;
        }
      } else if (
        inputMatchesCriteriaArray[n].allowsMultipleSelection &&
        (inputMatchesCriteriaArray[n].valueType === 'float' ||
          inputMatchesCriteriaArray[n].valueType === 'int')
      ) {
        const criteriaItemValues = inputMatchesCriteriaArray[n].values;
        let countValues = 0;

        for (let j = 0; j < criteriaItemValues.length; j += 1) {
          const value = criteriaItemValues[j];

          if (!value.startsWith('-') && !value.endsWith('+') && !value.toString().includes('-')) {
            if (parseFloat(value) === item[inputMatchesCriteriaArray[n].field]) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (value.startsWith('-') && !value.endsWith('+')) {
            if (item[inputMatchesCriteriaArray[n].field] <= parseFloat(value.split('-')[1])) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (!value.startsWith('-') && value.endsWith('+')) {
            if (item[inputMatchesCriteriaArray[n].field] >= parseFloat(value.split('+')[0])) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          } else if (
            !value.startsWith('-') &&
            !value.endsWith('+') &&
            value.toString().includes('-')
          ) {
            if (
              item[inputMatchesCriteriaArray[n].field] >= parseFloat(value.split('-')[0]) &&
              item[inputMatchesCriteriaArray[n].field] <= parseFloat(value.split('-')[1])
            ) {
              countValues += 1;
              // eslint-disable-next-line
              continue;
            }
          }
        }

        if (countValues > 0) count += 1;
      } else if (
        inputMatchesCriteriaArray[n].allowsMultipleSelection &&
        inputMatchesCriteriaArray[n].valueType === 'string'
      ) {
        const criteriaItemValues = inputMatchesCriteriaArray[n].values
          ? inputMatchesCriteriaArray[n].values.toString().toLowerCase()
          : '';
        const itemType = inputMatchesCriteriaArray[n].field;
        const itemValue = item[itemType]
          ? item[inputMatchesCriteriaArray[n].field].toString().toLowerCase()
          : '';

        if (itemValue !== '') {
          if (itemType === DISPLAY_HOME_FIELDS.BUILDING_METHOD) {
            if (itemValue.indexOf(criteriaItemValues) > -1) {
              count += 1;
            }
          } else if (criteriaItemValues.indexOf(itemValue) > -1) {
            count += 1;
          }
        }
      }
    }

    return count === inputMatchesCriteriaArray.length;
  };

  const filterDisplayHomeDocumentsByCriteriaArray = (
    inputArray: any,
    inputFilterDHDocsCriteriaArray: any
  ) => {
    const matches: any[] = [];

    if (inputArray) {
      const validInputArrayItems = inputArray.filter((item: any) => item.id !== '');

      for (let i = 0; i < validInputArrayItems.length; i += 1) {
        if (matchesCriteria(validInputArrayItems[i], inputFilterDHDocsCriteriaArray)) {
          matches.push(validInputArrayItems[i]);
        }
      }
    }
    return matches;
  };

  const getSortedDisplayHomeDocumentsBy = (inputArray: any, key: string, order = 'asc') => {
    if (inputArray.length > 0) {
      const sortedDHDocs = inputArray.slice(0);
      sortedDHDocs
        .sort(compareValues('shipDate', 'asc'))
        .sort(compareValues('markAsClearance', 'desc'))
        .sort(compareValues(key, order));

      return sortedDHDocs;
    }
  };

  const updateSortByTitleAndReturnSortedDHDocs = (
    inputArray: any,
    title: string,
    key: string,
    order = 'asc'
  ) => {
    if (title !== sortByFilterTitle) {
      setSortByFilterTitle(title);
    }
    return getSortedDisplayHomeDocumentsBy(inputArray, key, order);
  };

  const filterDisplayHomeDocuments = () => {
    let updDHDocs: any[] = [];
    const filterDHDocumentsCriteriaArray: any[] = [];

    yourFiltersInitial.forEach((filter: any, indexFilter: number) => {
      if (indexFilter > 0) {
        const filterValues = [
          ...new Set(
            yourFiltersInitial
              .filter(
                (iniFilter) =>
                  iniFilter.id !== '' && iniFilter.displayHomeField === filter.displayHomeField
              )
              .map((fil) => fil.value)
          ),
        ];

        if (
          !filterDHDocumentsCriteriaArray.some(
            (criteriaItem: any) => criteriaItem.field === filter.displayHomeField
          )
        ) {
          filterDHDocumentsCriteriaArray.push({
            field: filter.displayHomeField,
            values: filterValues,
            valueType: filter.valueType,
            allowsMultipleSelection: filter.allowsMultipleSelection,
          });
        } else {
          const criteria = filterDHDocumentsCriteriaArray.find(
            (criItem) => criItem.field === filter.displayHomeField
          );
          criteria.values = filterValues;
        }
      }
    });

    const allDHDocs = displayHomeDocumentsOriginal;

    updDHDocs = filterDisplayHomeDocumentsByCriteriaArray(
      allDHDocs,
      filterDHDocumentsCriteriaArray
    );

    const sortUpdatedDHDocs = updateSortByTitleAndReturnSortedDHDocs(
      updDHDocs,
      sortByFilterTitle,
      sortByFilterSelected,
      sortByOrderSelected
    );
    setDisplayHomeDocuments(sortUpdatedDHDocs);
  };

  const updateIndexFilterGroupOpened = (filterGroup: string) => {
    const accordionFilterGroup = document.querySelector(`.accordion-group-toggler.${filterGroup}`);

    if (accordionFilterGroup) {
      const { id } = accordionFilterGroup;
      const indexAccordionFilterGroup = id.split('-')[id.split('-').length - 1].toString();

      if (!indexesAccordionGroupOpened.some((idx: string) => idx === indexAccordionFilterGroup)) {
        setIndexesAccordionGroupOpened((prev) => [...prev, indexAccordionFilterGroup]);
      }
    }
  };

  const addNewUrlParameter = (field: string, value: string) => {
    let urlRefresh = window.location.href;
    let fieldToUse = field;
    let valueToUse = value;

    if (fieldToUse !== 'sortBy' && valueToUse.split(' ').length > 0) {
      valueToUse = valueToUse.split(' ').join('');
    }

    if (valueToUse.startsWith('-') && !valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('-', 'lt');
    }
    if (!valueToUse.startsWith('-') && valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('+', 'gt');
    }

    if (fieldToUse === DISPLAY_HOME_FIELDS.BUILDING_METHOD) {
      fieldToUse = 'buildingMethodValue';
    }
    const currentSearchParameters = window.location.search;

    if (currentSearchParameters) {
      if (queryString.parse(currentSearchParameters)[`${fieldToUse}`]) {
        const existingUrlParamWithValue = `${fieldToUse}=${queryString
          .parse(currentSearchParameters)
          [fieldToUse]!.toString()}`;

        const updatedParamWithValues =
          fieldToUse === 'sortBy'
            ? `${fieldToUse}=${valueToUse}`
            : `${fieldToUse}=${queryString
                .parse(currentSearchParameters)
                [fieldToUse]!.toString()},${valueToUse}`;

        const urlRefreshUpdated = decodeURIComponent(urlRefresh).replace(
          `${existingUrlParamWithValue}`,
          `${updatedParamWithValues}`
        );

        urlRefresh = urlRefreshUpdated;
      } else {
        urlRefresh += `&${fieldToUse}=${valueToUse.trim()}`;
      }
    } else {
      urlRefresh += `?${fieldToUse}=${valueToUse.trim()}`;
    }

    window.history.pushState({ path: urlRefresh }, '', urlRefresh);
  };

  const updateOrRemoveUrlParameter = (field: string, value: string) => {
    let urlRefresh = window.location.href;
    let fieldToUse = field;
    let valueToUse = value;

    if (valueToUse.split(' ').length > 0) {
      valueToUse = valueToUse.split(' ').join('');
    }

    if (valueToUse.startsWith('-') && !valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('-', 'lt');
    }
    if (!valueToUse.startsWith('-') && valueToUse.endsWith('+')) {
      valueToUse = valueToUse.replace('+', 'gt');
    }

    if (fieldToUse === DISPLAY_HOME_FIELDS.BUILDING_METHOD) {
      fieldToUse = 'buildingMethodValue';
    }

    valueToUse = valueToUse.toLocaleLowerCase();

    const currentSearchParameters = window.location.search;

    if (currentSearchParameters) {
      if (queryString.parse(currentSearchParameters)[`${fieldToUse}`]) {
        const existingUrlParamWithValue = `${fieldToUse}=${queryString
          .parse(currentSearchParameters)
          [fieldToUse]!.toString()}`;
        let updatedParamWithValues = '';
        if (!existingUrlParamWithValue.includes(valueToUse)) {
          return;
        }
        if (existingUrlParamWithValue.includes(',')) {
          const updatedValue = queryString
            .parse(currentSearchParameters)
            [fieldToUse]!.toString()
            .split(',')
            .filter((val: string) => val !== valueToUse)
            .join(',');
          updatedParamWithValues = updatedValue !== '' ? `${fieldToUse}=${updatedValue}` : '';
        }
        const urlRefreshUpdated =
          updatedParamWithValues !== ''
            ? urlRefresh.replace(`${existingUrlParamWithValue}`, `${updatedParamWithValues}`)
            : urlRefresh.replace(
                new RegExp(`(\\?|&)${existingUrlParamWithValue}`),
                `${updatedParamWithValues}`
              );
        urlRefresh = urlRefreshUpdated;
      }
    }
    if (urlRefresh.includes('&') && !urlRefresh.includes('?')) {
      urlRefresh = urlRefresh.replace('&', '?');
    }
    window.history.pushState({ path: urlRefresh }, '', urlRefresh);
  };

  const addNewYourFilter = (
    idFilter: string,
    textFilter: string,
    valueFilter: string,
    displayHomeFieldFilter: string,
    allowsMultipleSelectionFilter: boolean,
    valueTypeFilter: string,
    addNewSearchUrl = true
  ) => {
    if (addNewSearchUrl) {
      addNewUrlParameter(displayHomeFieldFilter, valueFilter.toLowerCase());
    }

    setYourFilters((prevArr) => [
      ...prevArr,
      {
        id: idFilter,
        text: textFilter,
        value: valueFilter,
        displayHomeField: displayHomeFieldFilter,
        allowsMultipleSelection: allowsMultipleSelectionFilter,
        valueType: valueTypeFilter,
      },
    ]);

    setYourFiltersInitial((prevArr) => [
      ...prevArr,
      {
        id: idFilter,
        text: textFilter,
        value: valueFilter,
        displayHomeField: displayHomeFieldFilter,
        allowsMultipleSelection: allowsMultipleSelectionFilter,
        valueType: valueTypeFilter,
      },
    ]);
  };

  const addAFilter = (inputAddAFilterValue: string, displayHomeField: string) => {
    const getFilterFromMapByValue = (
      inputdisplayHomeFieldFromMap: string,
      inputValueFromMap: string
    ) => {
      let filterFoundFromMap = mapByValue.get(inputdisplayHomeFieldFromMap + inputValueFromMap);

      if (!filterFoundFromMap) {
        if (displayHomeField === DISPLAY_HOME_FIELDS.SQUARE_FOOTAGE) {
          const range = (start: number, end: number, length = end - start + 1) =>
            [...Array(length).keys()].map((d) => d + start);

          mapByValue.forEach((value: any, key: any) => {
            if (key.toString().startsWith(displayHomeField)) {
              const filterOption = value;
              if (filterOption) {
                if (
                  !filterOption.value.toString().startsWith('-') &&
                  !filterOption.value.toString().endsWith('+') &&
                  filterOption.value.toString().includes('-')
                ) {
                  const rangeGenerated = range(
                    Number(filterOption.value.split('-')[0]),
                    Number(filterOption.value.split('-')[1])
                  );

                  if (rangeGenerated.indexOf(Number(inputValueFromMap)) > -1) {
                    filterFoundFromMap = value;
                  }
                }
              }
            }
          });
        } else {
          filterFoundFromMap = mapByValue.get(
            `${inputdisplayHomeFieldFromMap + inputValueFromMap.trim()}+`
          );
        }
      }

      return filterFoundFromMap;
    };

    const addInternalFilter = (inputValue: string) => {
      const filterFromMap = getFilterFromMapByValue(displayHomeField, inputValue);

      if (filterFromMap) {
        if (!filterFromMap.allowsMultipleSelection) {
          filterFromMap.text = `< ${filterFromMap.text}`;
        }
        updateIndexFilterGroupOpened(displayHomeField);

        setYourFilters((prevArr) => [...prevArr, filterFromMap]);

        setYourFiltersInitial((prevArr) => [...prevArr, filterFromMap]);

        addNewYourFilter(
          filterFromMap.id,
          filterFromMap.text,
          filterFromMap.value,
          filterFromMap.displayHomeField,
          filterFromMap.allowsMultipleSelection,
          filterFromMap.valueType,
          false
        );
      } else {
        updateOrRemoveUrlParameter(displayHomeField, inputValue);
      }
    };

    if (inputAddAFilterValue.includes(',')) {
      // eslint-disable-next-line
      inputAddAFilterValue.split(',').map((val: string) => {
        addInternalFilter(val);
      });
    } else {
      addInternalFilter(inputAddAFilterValue);
    }
  };

  const filterDHDocsByAdditionalFilters = (
    sortedDHDocs: any,
    numBedrooms: any,
    numBathrooms: any,
    squareFootage: any,
    sections: any,
    buildingMethod: any,
    squareFootageValues: string,
    mediaType: string,
    sortBy: string
  ) => {
    let resultDHDocs: any[] = [];

    if (numBathrooms && numBathrooms !== '') {
      addAFilter(numBathrooms, DISPLAY_HOME_FIELDS.NUM_BATHROOMS);
    }

    if (numBedrooms && numBedrooms !== '') {
      addAFilter(numBedrooms, DISPLAY_HOME_FIELDS.NUM_BEDROOMS);
    }

    if (squareFootage && squareFootage.length !== '') {
      addAFilter(squareFootage, DISPLAY_HOME_FIELDS.SQUARE_FOOTAGE);
    }

    if (squareFootageValues && squareFootageValues !== '') {
      addAFilter(squareFootageValues, DISPLAY_HOME_FIELDS.SQUARE_FOOTAGE);
    }

    if (sections && sections !== '') {
      addAFilter(sections, DISPLAY_HOME_FIELDS.SECTIONS);
    }

    if (buildingMethod && buildingMethod !== '') {
      addAFilter(buildingMethod, DISPLAY_HOME_FIELDS.BUILDING_METHOD);
    }

    if (mediaType && mediaType !== '') {
      addAFilter(mediaType, DISPLAY_HOME_FIELDS.MEDIA);
    }

    resultDHDocs = sortedDHDocs;

    if (
      sortBy !== '' &&
      sortFieldsArray.some(
        (item: string) =>
          item.split(' ')[0].toLowerCase() === sortBy.split(' ')[0].toLowerCase() &&
          item.split(' ')[1].toLowerCase() === sortBy.split(' ')[1].toLowerCase()
      )
    ) {
      const sortByFieldIndex = sortFieldsArray.findIndex(
        (item: string) =>
          item.split(' ')[0].toLowerCase() === sortBy.split(' ')[0].toLowerCase() &&
          item.split(' ')[1].toLowerCase() === sortBy.split(' ')[1].toLowerCase()
      );

      const existingSortByTitle = sortTitlesArray[sortByFieldIndex];
      const titleDisplayed = `${dhSort.sortBy} ${existingSortByTitle}`;

      setSortByFilterTitle(titleDisplayed);
      setSortByFilterSelected(sortFieldsArray[sortByFieldIndex].split(' ')[0]);
      setSortByOrderSelected(sortFieldsArray[sortByFieldIndex].split(' ')[1]);

      const sortByUrlParamAndFilterDHDocs = updateSortByTitleAndReturnSortedDHDocs(
        resultDHDocs,
        titleDisplayed,
        sortFieldsArray[sortByFieldIndex].split(' ')[0],
        sortFieldsArray[sortByFieldIndex].split(' ')[1]
      );

      return sortByUrlParamAndFilterDHDocs;
    }
    if (sortByFilterSelected !== '') {
      const sortAndFilterUpdatedDHDocs = updateSortByTitleAndReturnSortedDHDocs(
        resultDHDocs,
        sortByFilterTitle,
        sortByFilterSelected,
        sortByOrderSelected
      );
      return sortAndFilterUpdatedDHDocs;
    }
    return resultDHDocs;
  };

  const displayBottomLine = () => {
    return <hr className="cvc-product-list-your-filters-horizontal-bottom-line" />;
  };

  const displayYourFilters = () => {
    const filters = yourFilters;

    const handleFilterCloseIconClick = (id: string) => {
      if (yourFilters.some((filter) => filter.id === id)) {
        const filterToBeRemoved = yourFilters.find((filter) => filter.id === id);
        const updFilters = yourFilters.filter((filter) => filter.id !== id);

        setYourFilters(updFilters);
        setYourFiltersInitial(updFilters);

        updateOrRemoveUrlParameter(filterToBeRemoved!.displayHomeField, filterToBeRemoved!.value);

        if (updFilters.length === 1 && updFilters[0].id === '') {
          setDisplayHomeDocuments(displayHomeDocumentsOriginal);
        }
      }
    };

    const handleClearAllClick = () => {
      window.history.pushState({}, '', 'display-homes');

      setYourFilters([
        {
          id: '',
          text: '',
          value: '',
          displayHomeField: '',
          allowsMultipleSelection: true,
          valueType: '',
        },
      ]);
      setYourFiltersInitial([
        {
          id: '',
          text: '',
          value: '',
          displayHomeField: '',
          allowsMultipleSelection: true,
          valueType: '',
        },
      ]);

      setDisplayHomeDocuments(displayHomeDocumentsOriginal);
    };

    return filters && filters.length > 1 && isSearchPage ? (
      <>
        <div className="cvc-display-home-list-your-filters-container cvc-product-list-your-filters">
          <div className="cvc-product-list-your-filters-title">
            Your Filters
            <RightArrowIcon className="cvc-product-list-your-filters-title-icon" />
          </div>
          <div className="cvc-product-list-your-filters-button-container">
            {
              // eslint-disable-next-line
              filters.map((filter: any, indexFil: number) => {
                if (filter.id) {
                  const buttonsDuplicated = document.querySelectorAll(
                    `.cvc-product-list-filter-button.${filter.id}`
                  );
                  if (buttonsDuplicated.length > 1) {
                    (
                      buttonsDuplicated.item(buttonsDuplicated.length - 1) as HTMLButtonElement
                    ).style.display = 'none';
                  }
                }

                if (filter.text.length > 0) {
                  return (
                    <button
                      key={`${filter.text}-${indexFil.toString()}`}
                      id={`btn-${filter.id}`}
                      type="button"
                      className={`cvc-product-list-filter-button ${filter.id}`}
                      tabIndex={-1}
                    >
                      <CloseIcon
                        className="filter-button-close-icon"
                        onClick={() => handleFilterCloseIconClick(filter.id)}
                      />
                      {filter.text}
                    </button>
                  );
                }
              })
            }
            {filters.length > 1 ? (
              <button
                key="clear-all-button"
                type="button"
                className="cvc-product-list-filter-button clear-all"
                onClick={handleClearAllClick}
              >
                <CloseIcon className="filter-button-close-icon" />
                Clear All
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {displayBottomLine()}
      </>
    ) : (
      ''
    );
  };

  const displayListGroupSortByOptions = () => {
    const handleSortByItemSelected = (title: string, key: string, order = 'asc') => {
      const sortedDHDocs = updateSortByTitleAndReturnSortedDHDocs(
        displayHomeDocuments,
        title,
        key,
        order
      );

      setSortByFilterSelected(key);
      setSortByOrderSelected(order);
      setDisplayHomeDocuments(sortedDHDocs);

      setShowListGroupSortByOptions(false);

      if (sortOptionsMobileOpen) {
        setShowListGroupSortByOptions(false);
        setSortOptionsMobileOpen(false);
      }

      addNewUrlParameter('sortBy', `${key} ${order}`);
    };

    const handleListGroupItemHover = (isHover: boolean) => {
      setShowListGroupSortByOptions(isHover);
    };

    const isNotDesktopScreen = !!(windowDimensions.isMobile || windowDimensions.isTablet);

    return (
      <ListGroup
        className={classNames('accordion-sort-by-list', isNotDesktopScreen ? 'is-mobile' : '')}
        onMouseOver={() => handleListGroupItemHover(true)}
        onMouseLeave={() => handleListGroupItemHover(false)}
        onFocus={() => handleListGroupItemHover(true)}
        onBlur={() => handleListGroupItemHover(false)}
      >
        {sortFieldsArray.map((a: any, index: number) => {
          const itemSortByTitleDisplayed = `${dhSort.sortBy} ${sortTitlesArray[index]}`;
          const itemSortByTitle = sortTitlesArray[index];
          const itemSortByField = sortFieldsArray[index].split(' ')[0];
          const itemSortByOrder = sortFieldsArray[index].split(' ')[1];

          return (
            <ListGroup.Item
              key={`${itemSortByField}-${itemSortByOrder}`}
              action
              onClick={() =>
                handleSortByItemSelected(itemSortByTitleDisplayed, itemSortByField, itemSortByOrder)
              }
              active={
                isNotDesktopScreen &&
                sortByFilterSelected === itemSortByField &&
                sortByOrderSelected === itemSortByOrder
              }
              disabled={noDocuments}
            >
              {itemSortByTitle}
              {(windowDimensions.isMobile || windowDimensions.isTablet) &&
              sortByFilterSelected === itemSortByField &&
              sortByOrderSelected === itemSortByOrder ? (
                <DoneIcon className="sort-by-mobile-done-icon" />
              ) : (
                ''
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const displaySortByDesktopOptions = () => {
    const handleAccordionSortByContainerHover = (isHover: boolean) => {
      setShowListGroupSortByOptions(isHover);
    };

    return (
      <div
        className="accordion-filter-sort-by-container"
        onMouseOver={() => handleAccordionSortByContainerHover(true)}
        onMouseLeave={() => handleAccordionSortByContainerHover(false)}
        onFocus={() => handleAccordionSortByContainerHover(true)}
        onBlur={() => handleAccordionSortByContainerHover(false)}
      >
        <div
          className={classNames(
            'accordion-filter-sort-by-title',
            !windowDimensions.isMobile && !windowDimensions.isTablet ? `is-desktop` : ''
          )}
        >
          <span className={showListGroupSortByOptions ? 'lighter-text-color' : ''}>
            {sortByFilterTitle || 'Sort By'}
          </span>
          <CaretDownIcon
            className={classNames(
              'accordion-filter-sort-by-icon',
              showListGroupSortByOptions ? 'rotate-icon' : ''
            )}
          />
        </div>
        {showListGroupSortByOptions ? displayListGroupSortByOptions() : ''}
      </div>
    );
  };

  const displayMobileLinks = () => {
    const handleMobileLinkSelected = (linkSelected: string) => {
      setDisplayInMobile(linkSelected);

      if (linkSelected === 'cards') {
        setAccordionFiltersMobileOpen(false);

        if (!sortOptionsMobileOpen) {
          setSortOptionsMobileOpen(true);
          setShowListGroupSortByOptions(true);
        } else {
          setSortOptionsMobileOpen(false);
          setShowListGroupSortByOptions(false);
        }
      } else if (linkSelected === 'filters') {
        setSortOptionsMobileOpen(false);
        setShowListGroupSortByOptions(false);

        if (!accordionFiltersMobileOpen) {
          setAccordionFiltersMobileOpen(true);
        } else {
          setAccordionFiltersMobileOpen(false);
        }
      }
    };

    return (
      <div className="display-home-mobile-links product-list-mobile-links">
        <Container>
          <Button
            variant="link"
            className={`${displayInMobile === 'cards' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('cards')}
          >
            Sort
            <CaretDownIcon
              className={classNames(
                'product-mobile-link-caret-down-icon',
                showListGroupSortByOptions ? 'rotate-icon' : ''
              )}
            />
          </Button>
          <span className="product-mobile-separator">{' | '}</span>
          <Button
            variant="link"
            className={`${displayInMobile === 'filters' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('filters')}
          >
            <FiltersIcon className="product-mobile-filters-icon" />
            Filter Results
          </Button>
        </Container>
        {showListGroupSortByOptions ? displayListGroupSortByOptions() : ''}
      </div>
    );
  };

  const numLocationsAvailable =
    displayHomeDocuments && displayHomeDocuments.length > 0 ? displayHomeDocuments.length : 0;

  const CavcoFormCheck: FunctionComponent<any> = (formCheckProps) => {
    const {
      id,
      isDisabled,
      inputElementType,
      displayHomeField,
      label,
      labelFull,
      value,
      name,
      allowsMultipleSelection,
      valueType,
    } = formCheckProps;

    const handleFormCheckClick = (
      text: string,
      valueFormCheck: string,
      displayHomeFieldFormCheck: string,
      idFormCheck: string,
      allowsMultipleSelectionFormCheck: boolean,
      valueTypeFormCheck: string
    ) => {
      if (
        !allowsMultipleSelectionFormCheck &&
        yourFilters.some((filter) => filter.displayHomeField === displayHomeFieldFormCheck)
      ) {
        const indexFilters = yourFilters.filter(
          (filter) => filter.displayHomeField !== displayHomeFieldFormCheck
        );
        setYourFilters(indexFilters);
        setYourFiltersInitial(indexFilters);

        addNewYourFilter(
          idFormCheck,
          `< ${text}`,
          valueFormCheck,
          displayHomeFieldFormCheck,
          allowsMultipleSelectionFormCheck,
          valueTypeFormCheck
        );
      }
      if (
        allowsMultipleSelectionFormCheck &&
        yourFilters.some((filter) => filter.id === idFormCheck)
      ) {
        const filterToBeRemoved = yourFilters.find((filter) => filter.id === idFormCheck);

        const updFilters = yourFilters.filter((filter) => filter.id !== idFormCheck);
        setYourFilters(updFilters);
        setYourFiltersInitial(updFilters);

        updateOrRemoveUrlParameter(filterToBeRemoved!.displayHomeField, filterToBeRemoved!.value);

        if (updFilters.length === 1 && updFilters[0].id === '') {
          setDisplayHomeDocuments(displayHomeDocumentsOriginal);
        }
      } else {
        addNewYourFilter(
          idFormCheck,
          !allowsMultipleSelectionFormCheck ? `< ${text}` : text,
          valueFormCheck,
          displayHomeFieldFormCheck,
          allowsMultipleSelectionFormCheck,
          valueTypeFormCheck
        );
      }
    };

    return (
      <Form.Check
        key={id}
        id={id}
        disabled={isDisabled}
        type={inputElementType}
        name={name}
        label={labelFull}
        value={value}
        onChange={() =>
          handleFormCheckClick(
            label,
            value,
            displayHomeField,
            id,
            allowsMultipleSelection,
            valueType
          )
        }
      />
    );
  };

  const getNumberDHDocsByFilterNameAndValue = (
    inputNumberDHDocsFilter: any,
    value: any,
    usedisplayHomeDocumentsOriginal = false
  ) => {
    let results = 0;
    const allDHDocs = !usedisplayHomeDocumentsOriginal
      ? displayHomeDocuments
      : displayHomeDocumentsOriginal;
    const dhDocs = filterDisplayHomeDocumentsByCriteriaArray(allDHDocs, [
      {
        field: inputNumberDHDocsFilter.displayHomeField,
        values: [value],
        valueType: inputNumberDHDocsFilter.valueType,
        allowsMultipleSelection: inputNumberDHDocsFilter.allowsMultipleSelection,
      },
    ]);

    if (dhDocs !== undefined) {
      results = dhDocs.length > 0 ? dhDocs.length : 0;
    }

    return results;
  };

  const AccordionFilter: FunctionComponent<any> = (accordionFilterProps) => {
    const { filter, indexFilter } = accordionFilterProps;
    const [isAccordionItemOpen, setIsAccordionItemOpen] = useState(false);

    const contentId = `${filter.displayHomeField}-${indexFilter.toString()}-content`;

    const handleAccordionGroupToglerClick = (index: string) => {
      setIsAccordionItemOpen(!isAccordionItemOpen);
      if (indexesAccordionGroupOpened.some((idx: string) => idx === index)) {
        const indexAccordionGroup = indexesAccordionGroupOpened.findIndex(
          (idx: string) => idx !== index
        );
        const newIndexAccordionGroup = indexesAccordionGroupOpened.slice(0, indexAccordionGroup);
        setIndexesAccordionGroupOpened(newIndexAccordionGroup);
      } else {
        setIndexesAccordionGroupOpened((prev) => [...prev, index]);
      }
    };
    const handlekeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        setIsAccordionItemOpen(!isAccordionItemOpen);
      }
    };

    const useDisplayHomeDocumentsOriginal = yourFilters.some(
      (yourFilter: any) => yourFilter.displayHomeField === filter.displayHomeField
    );

    return (
      <div className="accordion-filter-container">
        <div
          id={`accordion-group-header-${filter.displayHomeField}-${indexFilter.toString()}`}
          className={`accordion-group-toggler ${filter.displayHomeField}`}
          role="button"
          onClick={() => handleAccordionGroupToglerClick(indexFilter.toString())}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handlekeyDown(e)}
          aria-controls={contentId}
          aria-expanded={indexesAccordionGroupOpened.some(
            (index: any) => index === indexFilter.toString()
          )}
          tabIndex={0}
        >
          {filter.title}
          {indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString()) ? (
            <RemoveIcon className="card-header-icon" />
          ) : (
            <AddIcon className="card-header-icon" />
          )}
        </div>
        <Collapse
          in={indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())}
        >
          <div
            id={contentId}
            className={classNames(
              'card-body-container',
              indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())
                ? 'is-open'
                : ''
            )}
          >
            {filter.values.split(',').map((value: any, indexValue: number) => {
              const inputElementType = filter.allowsMultipleSelection ? 'checkbox' : 'radio';
              const numberDHDocsByFilterNameAndValue = getNumberDHDocsByFilterNameAndValue(
                filter,
                value,
                useDisplayHomeDocumentsOriginal
              );
              const label = `${value} ${filter.unit}`;
              const labelFull = `${label} (${numberDHDocsByFilterNameAndValue})`;
              const id = `formCheck-${filter.displayHomeField}-${indexValue.toString()}`;
              const name = !filter.allowsMultipleSelection
                ? `filter-${filter.displayHomeField}-radios`
                : '';

              mapByValue.set(
                filter.displayHomeField + value.toString().split(' ').join('').toLowerCase(),
                {
                  id,
                  text: label,
                  value,
                  displayHomeField: filter.displayHomeField,
                  allowsMultipleSelection: filter.allowsMultipleSelection,
                  valueType: filter.valueType,
                }
              );

              return (
                <CavcoFormCheck
                  key={`card-body-${id}`}
                  isDisabled={numberDHDocsByFilterNameAndValue === 0}
                  id={id}
                  name={name}
                  inputElementType={inputElementType}
                  displayHomeField={filter.displayHomeField}
                  label={label}
                  labelFull={labelFull}
                  value={value}
                  allowsMultipleSelection={filter.allowsMultipleSelection}
                  valueType={filter.valueType}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    );
  };

  const displayDynamicFilters = (isDisplayInMobile = false) => {
    const country =
      window.location.pathname.includes('us') || window.location.pathname.includes('ca')
        ? window.location.pathname
            .split('/')
            [window.location.pathname.split('/').length - 1].toUpperCase()
        : '';
    const filters: any[] = [];

    if (stdDHFiltersModelVal && stdDHFiltersModelVal.filter_names) {
      const filterResourceBundles = stdDHFiltersModelVal.filter_names;

      filterResourceBundles.split(',').forEach((filterResourceBundle: string) => {
        const filterDetails = compModels[filterResourceBundle]
          ? compModels[filterResourceBundle]
          : null;

        if (filterDetails) {
          const displayHomeFieldVal = filterDetails.displayHomeField;
          const isSorted = filterDetails.sortValues ? filterDetails.sortValues === 'true' : true;

          let unitVal = filterDetails.unit || '';
          if (filterDetails[`unit${country}`]) {
            unitVal = filterDetails[`unit${country}`];
          }

          const sortedValuesAsc = filterDetails.values
            ? filterDetails.values
                .split(',')
                .sort((a: any, b: any) => {
                  if (filterDetails.valueType === 'string') {
                    return a < b ? -1 : 1;
                  }
                  return a - b;
                })
                .join(',')
            : '';
          const valueTypeVal = filterDetails.valueType;

          const displayValues = isSorted ? sortedValuesAsc : filterDetails.values;

          filters.push({
            displayHomeField: displayHomeFieldVal,
            title: filterDetails.title,
            unit: unitVal,
            values: displayValues,
            allowsMultipleSelection: filterDetails.allowsMultipleSelection === 'true',
            valueType: valueTypeVal,
          });
        }
      });
    }

    const handleDarkBackgroundKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        setAccordionFiltersMobileOpen(!accordionFiltersMobileOpen);
      }
    };

    return isDisplayInMobile ? (
      <div className="product-list-accordion-mobile-wrapper">
        <div
          className={classNames(
            `product-list-accordion ${accordionFiltersMobileOpen ? 'show-in-mobile' : ''}`
          )}
        >
          {filters.map((filter: any, indexFilter: number) => (
            <AccordionFilter
              key={`accordion-filter-${indexFilter.toString()}`}
              filter={filter}
              indexFilter={indexFilter}
              windowDimensions={windowDimensions}
            />
          ))}
        </div>
        <div
          aria-label="product-list-accordion-mobile-link"
          role="button"
          className={classNames(
            `product-list-accordion-mobile-link ${
              accordionFiltersMobileOpen ? 'show-in-mobile' : ''
            }`
          )}
          tabIndex={0}
          onClick={() => setAccordionFiltersMobileOpen(!accordionFiltersMobileOpen)}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleDarkBackgroundKeyDown(e)}
        />
      </div>
    ) : (
      <div className="product-list-accordion">
        {displaySortByDesktopOptions()}
        {filters.map((filter: any, indexFilter: number) => (
          <AccordionFilter
            key={`accordion-filter-${indexFilter.toString()}`}
            filter={filter}
            indexFilter={indexFilter}
            windowDimensions={windowDimensions}
          />
        ))}
      </div>
    );
  };

  const displayBreadcrumb = (breadcrumbInput: any, displayScrollToTop: boolean) => {
    return breadcrumbInput ? (
      <div className="cvc-display-home-list-breadcrumb cvc-product-list-breadcrumb d-sm-block">
        {breadcrumbInput && (
          <CustomBreadcrumbStack
            props={{ page: props.page, component: breadcrumbInput }}
            customProps={{ displayScrollToTop }}
          />
        )}
      </div>
    ) : (
      ''
    );
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    setIndexesAccordionGroupOpened([indexDefaultFilterOpen]);

    setYourFilters([
      {
        id: '',
        text: '',
        value: '',
        displayHomeField: '',
        allowsMultipleSelection: true,
        valueType: '',
      },
    ]);
    setYourFiltersInitial([
      {
        id: '',
        text: '',
        value: '',
        displayHomeField: '',
        allowsMultipleSelection: true,
        valueType: '',
      },
    ]);

    if (compModels) {
      const { cards = [] } = compModels;
      const sortedDHDocs = cards;

      const sortedWithFiltersDHDocs = filterDHDocsByAdditionalFilters(
        sortedDHDocs,
        numBedroomsVal,
        numBathroomsVal,
        homeSizeParam,
        sectionsVal,
        buildingMethodVal,
        squareFootageMultipleValues,
        mediaParam,
        sortByVal
      );

      setDisplayHomeDocuments(sortedWithFiltersDHDocs);
      setDisplayHomeDocumentsOriginal(sortedWithFiltersDHDocs);
    }

    const generalBreadcrumb = document.body.querySelector(
      '.cvc-breadcrumb-container:not(.is-custom)'
    );
    if (generalBreadcrumb) {
      (generalBreadcrumb as HTMLDivElement).style.display = 'none';
    }
    // eslint-disable-next-line
  }, [compModels]);

  useEffect(() => {
    if (yourFilters.length > 1) {
      // eslint-disable-next-line
      yourFilters.map((filter: any) => {
        const inputFilter = document.getElementById(filter.id);
        if (inputFilter) {
          (inputFilter as HTMLInputElement).checked = true;
          inputFilter.classList.toggle('is-selected', true);
        }
      });
    }
  });

  useEffect(() => {
    if (yourFiltersInitial.length > 1) {
      filterDisplayHomeDocuments();
    }
    // eslint-disable-next-line
  }, [yourFiltersInitial]);

  const displayHomesSortContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'cards'),
    'display-homes-sort-container': true,
    'cvc-product-list-sort': true,
  });

  const displayHomesFiltersContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'filters'),
    'display-homes-filters-container': true,
    'cvc-product-list-main': true,
  });

  const displayHomesListClasses = classNames({
    'cvc-display-home-list': true,
    'cvc-product-list': true,
    'cvc-location-section': !isSearchPage,
  });

  const footerButtonClasses = classNames({
    'cvc-location-section-foot': true,
    hidden: displayDisplayHomeCards.length < cardLimit,
  });

  const displayBreadcrumbScrollToTop = false;

  if (!isSearchPage && totalDisplayHomeCount === 0) {
    return <></>;
  }

  return (
    <div className={displayHomesListClasses}>
      {!isSearchPage && (
        <div className="cvc-retailer-display-homes-head cvc-location-section-head">
          <h2 className="cvc-location-section-head-title">Display Homes</h2>
          <ResolverLink className="cvc-location-section-head-view-all-link" href="./display-homes">
            View all display homes currently available
          </ResolverLink>
        </div>
      )}
      {(windowDimensions.isMobile || windowDimensions.isTablet) && (
        <>
          {breadcrumb &&
            isSearchPage &&
            displayBreadcrumb(breadcrumb, displayBreadcrumbScrollToTop)}
          {isSearchPage && (
            <DisplayHomeSearch
              {...{
                numLocationsAvailable,
              }}
            />
          )}
        </>
      )}
      <div className="cvc-display-home-list-locations-container cvc-product-list-container">
        {(windowDimensions.isMobile || windowDimensions.isTablet) &&
          isSearchPage &&
          displayMobileLinks()}
        <>
          {(isSearchPage || windowDimensions.isMobile || windowDimensions.isTablet) && (
            <div className={displayHomesSortContainerClasses}>
              {windowDimensions.isMobile || windowDimensions.isTablet ? (
                <>
                  {isSearchPage && displayYourFilters()}
                  {noDocuments ? (
                    <Container>
                      <div>No Display Homes available at this Retailer</div>
                    </Container>
                  ) : (
                    displayDisplayHomeCards(props, displayHomeDocuments, windowDimensions)
                  )}
                  {displayDynamicFilters(true)}
                </>
              ) : (
                <>{isSearchPage && displayDynamicFilters()}</>
              )}
            </div>
          )}
          <div className={displayHomesFiltersContainerClasses}>
            {!windowDimensions.isMobile && !windowDimensions.isTablet && (
              <>
                {breadcrumb &&
                  isSearchPage &&
                  displayBreadcrumb(breadcrumb, displayBreadcrumbScrollToTop)}
                {isSearchPage ? (
                  <DisplayHomeSearch
                    {...{
                      numLocationsAvailable,
                    }}
                  />
                ) : (
                  ''
                )}
                {isSearchPage && displayYourFilters()}
                {noDocuments && (
                  <Container>
                    <div>No Display Homes available at this Retailer</div>
                  </Container>
                )}
                {!noDocuments &&
                  displayDisplayHomeCards(props, displayHomeDocuments, windowDimensions)}
              </>
            )}
          </div>
        </>
      </div>
      {!isSearchPage && (
        <div className={footerButtonClasses}>
          <ResolverLink className="cvc-product-view-all-button" href="./display-homes">
            <Button>View All {totalDisplayHomeCount} Display Homes</Button>
          </ResolverLink>
        </div>
      )}
      <GlobalScrollToTopButton />
    </div>
  );
}
