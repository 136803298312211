import React, { useState } from 'react';
import { BrManageContentButton, BrPageContext } from '@bloomreach/react-sdk';
import './CavcoCarouselBanner.scss';
import { LinearProgress } from '@material-ui/core';
import { Carousel } from 'react-bootstrap';
import { getUrlFromImage } from 'components/utils';
import { ReactComponent as NextIcon } from '../../assets/images/cavcohomes/arrow-next.svg';
import { ReactComponent as PrevIcon } from '../../assets/images/cavcohomes/arrow-prev.svg';
import { ReactComponent as QuotesIcon } from '../../assets/images/cavcohomes/quotes.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { SCREEN_SIZES } from '../enums';

interface CavcoControlledCarouselProps {
  componentId: string;
  componentParameterMap: any;
  scrollingInterval: number | null;
  documents: any[];
}

function setCarouselBannerHeight(componentId: string, newHeight: number) {
  const carouselBanner: any = document.getElementById(componentId);
  if (carouselBanner) {
    carouselBanner.style.height = `${newHeight}px`;
  }
}

function getCarouselItemHeight(id: string, prevId: any) {
  if (!id) return null;

  const carouselItem = document.getElementById(id);
  const prevCarouselItem = document.getElementById(prevId);
  if (carouselItem) {
    const carouselItemHeight = carouselItem.getBoundingClientRect().height;
    const prevCarouselItemHeight = prevCarouselItem
      ? prevCarouselItem.getBoundingClientRect().height
      : carouselItemHeight;

    return carouselItemHeight < prevCarouselItemHeight
      ? prevCarouselItemHeight
      : carouselItemHeight;
  }
}

enum CAROUSEL_BANNER_CONTENT1_SIZES {
  MOBILE = 14,
  TABLET = 18,
  DESKTOP_EXTRA_BIG = 24,
}

export function getImage(page: any, imageRef: any, variantValue: string) {
  if (!imageRef) return null;

  const imgContent: any = imageRef && page.getContent(imageRef.imagelink);
  if (!imgContent) return null;

  let mediumImgPath = null;
  let largeImgPath = null;
  let extraLargeImgPath = null;
  let originalImgPath = null;

  if (imgContent) {
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
    originalImgPath = getUrlFromImage(imgContent);
  } else if (imageRef.ximage) {
    mediumImgPath =
      imageRef.ximage.medium && imageRef.ximage.medium.url ? imageRef.ximage.medium.url : null;
    largeImgPath =
      imageRef.ximage.large && imageRef.ximage.large.url ? imageRef.ximage.large.url : null;
    extraLargeImgPath =
      imageRef.ximage.extraLarge && imageRef.ximage.extraLarge.url
        ? imageRef.ximage.extraLarge.url
        : null;
    originalImgPath = extraLargeImgPath;
  }

  let imageVariantClasses = '';
  switch (variantValue) {
    case 'noImage':
      break;
    case 'imageLeft':
      imageVariantClasses = 'order-md-0';
      break;
    case 'imageRight':
      imageVariantClasses = 'order-md-1';
      break;
    default:
      break;
  }

  return (
    <div className={`cvc-carousel-image-container ${imageVariantClasses}`}>
      <div className="quotes">
        <QuotesIcon />
      </div>
      <picture>
        {originalImgPath && (
          <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={originalImgPath} />
        )}
        {extraLargeImgPath && (
          <source
            media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
            srcSet={extraLargeImgPath}
          />
        )}
        {largeImgPath && (
          <source
            media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
            srcSet={largeImgPath}
          />
        )}
        <img alt={imageRef.alt} src={mediumImgPath} />
      </picture>
    </div>
  );
}

function getNewFontSize(windowsDimensions: any, contentLength: number) {
  let currentFontSize = 14;

  if (windowsDimensions.isMobile) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.MOBILE;
  } else if (
    windowsDimensions.isTablet ||
    windowsDimensions.isDesktop ||
    windowsDimensions.isDesktopBig
  ) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.TABLET;
  } else if (windowsDimensions.isDesktopExtraBig) {
    currentFontSize = CAROUSEL_BANNER_CONTENT1_SIZES.DESKTOP_EXTRA_BIG;
  }
  const newFontSize = `${95 - Number((currentFontSize / contentLength).toFixed(4))}%`;
  return newFontSize;
}

export function CavcoControlledCarousel({
  componentId,
  componentParameterMap,
  scrollingInterval,
  documents,
}: CavcoControlledCarouselProps) {
  const windowDimensions = useWindowDimensions();
  const { isMobile, isTablet, isDesktop, isDesktopBig, isDesktopExtraBig } = windowDimensions;
  const page = React.useContext(BrPageContext)!;
  const [index, setIndex] = useState(0);
  const updatedFontSizeDocIds = [''];

  const totalDocuments = documents.length;

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
    const ref = documents[selectedIndex];
    const previousRef = documents[selectedIndex - 1] || documents[documents.length - 1];
    const bannerDocument: any = ref && page.getContent(ref);
    const prevBannerDocument: any = previousRef && page.getContent(previousRef);
    const docId = bannerDocument?.model.id;
    const prevDocId = prevBannerDocument?.model.id;

    if (isDesktop || isDesktopBig || isDesktopExtraBig) {
      if (updatedFontSizeDocIds.length > 1) {
        const carouselItemHeight = getCarouselItemHeight(docId, prevDocId);
        if (carouselItemHeight) {
          setCarouselBannerHeight(componentId, carouselItemHeight);
        }
      }
    }
  };

  function sliderNext() {
    if (index + 1 === totalDocuments) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }

  function sliderBack() {
    if (index === 0) {
      setIndex(totalDocuments - 1);
    } else {
      setIndex(index - 1);
    }
  }

  function getContent1(content1Val: any, newFontSize = '') {
    if (!content1Val) return '';
    return (
      <div className="cvc-carousel-content1-container">
        <div
          className="cvc-carousel-content1"
          dangerouslySetInnerHTML={{ __html: content1Val }}
          style={newFontSize ? { fontSize: `${newFontSize}` } : {}}
        />
      </div>
    );
  }

  return (
    <div className="cavco-carousel-banner-container">
      <div className="carousel-container">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={scrollingInterval}
          indicators={false}
        >
          {documents.map((item: any, documentIndex: number) => {
            const document = page.getContent(documents[documentIndex]);

            if (!document) {
              return null;
            }

            const { id, content1, image: imageRef } = document.getData<BannerData>();
            const content1Val = content1 && page.rewriteLinks(content1.value);
            const htmlTagRe = /<.+?>/g;
            const contentWithoutTags = content1Val.replace(htmlTagRe, '');
            const variant = `variant${documentIndex + 1}`;
            const variantValue = componentParameterMap[variant] || 'imageRight';
            let newFontSize = '';

            if (contentWithoutTags.length > 875 && isDesktopExtraBig) {
              newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

              const registeredDocId = updatedFontSizeDocIds.find(
                (updatedDocId: any) => updatedDocId === id
              );
              if (!registeredDocId) {
                updatedFontSizeDocIds.push(id);
              }
            }
            if (contentWithoutTags.length > 825 && (isTablet || isDesktop || isDesktopBig)) {
              newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

              const registeredDocId = updatedFontSizeDocIds.find(
                (updatedDocId: any) => updatedDocId === id
              );
              if (!registeredDocId) {
                updatedFontSizeDocIds.push(id);
              }
            }

            if (contentWithoutTags.length > 481 && isMobile) {
              newFontSize = getNewFontSize(windowDimensions, contentWithoutTags.length);

              const registeredDocId = updatedFontSizeDocIds.find(
                (updatedDocId: any) => updatedDocId === id
              );
              if (!registeredDocId) {
                updatedFontSizeDocIds.push(id);
              }
            }
            const carouselKey = `${id}${documentIndex}`;
            return (
              <Carousel.Item id={carouselKey} key={carouselKey}>
                <BrManageContentButton content={document} />
                <div className={`item-wrapper-${variantValue}`}>
                  <div className="cvc-carousel-text-container">
                    <div>{content1Val && getContent1(content1Val, newFontSize)}</div>
                  </div>
                  {variantValue !== 'noImage' && imageRef && getImage(page, imageRef, variantValue)}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className="carousel-control">
        <div className="navigation-button">
          <button type="button" onClick={sliderBack} aria-label="slider previous button">
            <PrevIcon />
          </button>
          <button type="button" onClick={sliderNext} aria-label="slider next button">
            <NextIcon />
          </button>
        </div>
        <LinearProgress
          className="progressbar"
          variant="determinate"
          value={((index + 1) * 100) / totalDocuments}
        />
        <span>
          {index + 1}/{totalDocuments}
        </span>
      </div>
    </div>
  );
}
