import React from 'react';

import { Card } from 'react-bootstrap';

import { ResolverLink } from 'components/shared';

export const CardImage = ({ image, url = '', className = '' }: any) => {
  return (
    <>
      {!image ? (
        <ResolverLink className={`fp-feature-model-card-link-fc ${className}`} href={url}>
          <div className="card-no-photo-fc" />
        </ResolverLink>
      ) : (
        <ResolverLink className={`fp-feature-model-card-link-fc ${className}`} href={url}>
          <Card.Img
            alt={image && image.alt ? image.alt : ''}
            variant="top"
            src={image && image.url ? image.url : ''}
          />
        </ResolverLink>
      )}
    </>
  );
};
