import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import './CavcoUserLoginForm.scss';
import { ReactComponent as ErrorIcon } from '../../assets/images/exclamation-point-red.svg';

export function CavcoUserForgotPassword({ cavcoUserLoginUrl, postLoginUrl, closeMethod }: any) {
  const [submittingForgotPasswordEmail, setSubmittingForgotPasswordEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);

  return (
    <Formik
      initialValues={{ emailaddress: '' }}
      onSubmit={(values) => {
        if (!values.emailaddress) {
          setErrorMessage('Email Address is required.');
          return;
        }
        setErrorMessage('');

        setSubmittingForgotPasswordEmail(true);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        };
        fetch(cavcoUserLoginUrl, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log('responseData:', data); // eslint-disable-line
            console.log('postLoginUrl:', postLoginUrl); // eslint-disable-line

            if (data.status === 400) {
              if (data.message.startsWith('User account for the emailAddress does not exist')) {
                data.message = 'Email address does not exist. Please verify the email.';
              } else if (data.message.startsWith('emailAddress is invalid')) {
                data.message = 'Email address does not exist. Please verify the email.';
              }
              setErrorMessage(data.message);
              setSubmittingForgotPasswordEmail(false);
              return;
            }
            setErrorMessage('');

            if (data) {
              setEmailSentSuccessfully(true);
              return;
            }

            closeMethod();
            // top.window.location.reload(true);
            setSubmittingForgotPasswordEmail(false);
          })
          .catch((error) => {
            console.log('error from the server:', error.message); // eslint-disable-line
            setErrorMessage('Error Occurred, Please Try Again.');
            setSubmittingForgotPasswordEmail(false);
          });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <>
          {!emailSentSuccessfully && (
            <Form onSubmit={handleSubmit} className="cvc-form cvc-form-account">
              <div className="cvc-form-header">
                <h2>Forgot your password?</h2>
              </div>

              {!!errorMessage && (
                <div className="cvc-form-error-message">
                  <ErrorIcon className="cvc-form-error-message-icon" />
                  <div className="cvc-form-error-message-text">
                    <span>{errorMessage}</span>
                  </div>
                </div>
              )}

              <p>Enter your email address below and we will send your new password to you.</p>
              <Form.Group className="mb-3" controlId="emailaddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="emailaddress"
                  value={values.emailaddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errorMessage ? 'cvc-form-error' : ''}
                />
                <ErrorMessage name="emailaddress" />
              </Form.Group>

              <div className="text-center">
                <Button
                  disabled={submittingForgotPasswordEmail}
                  type="submit"
                  variant="primary"
                  className="cvc-btn"
                >
                  {submittingForgotPasswordEmail && (
                    <Spinner animation="border" variant="light" className="cvc-btn-icon" />
                  )}
                  {submittingForgotPasswordEmail ? 'Submitting' : 'Submit'}
                </Button>
              </div>

              {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
            </Form>
          )}

          {emailSentSuccessfully && (
            <div className="cvc-form cvc-form-account">
              <div className="cvc-form-header">
                <h2>Thank You</h2>
              </div>
              <p>Your password has been sent to the email address you provided.</p>
            </div>
          )}
        </>
      )}
    </Formik>
  );
}
