import React, { useState, useRef } from 'react';
import GooglePlacesAutocomplete, {
  suggestionType,
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
// import Geocode from 'react-geocode';

import { checkForNessus } from 'components/utils';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { ReactComponent as PoweredByGoogle } from '../../assets/images/powered-by-google.svg';
import './Autocomplete.scss';

export function GoToPageUrl(data: any) {
  // console.log('>>> goToPage:', data);
  let urlVal;
  const terms = data.terms.length;

  // > 2 terms in US or CA -> city, state, country
  if (terms > 2) {
    const city = data.terms[0];
    const state = data.terms[1];
    const country = terms === 3 ? data.terms[2] : data.terms[terms - 1];
    const countryAbbrev =
      country.value === 'Canada' || country.value === 'Canadá' || country.value === 'CA'
        ? 'ca'
        : 'us';
    urlVal = data.appendParamOnly
      ? `${data.url}?latitude=${data.lat}&longitude=${data.lng}&name=${encodeURIComponent(
          city.value
        )}%2C+${encodeURIComponent(state.value)}%2C+${encodeURIComponent(country.value)}`
      : `${data.url}/${countryAbbrev}?latitude=${data.lat}&longitude=${
          data.lng
        }&name=${encodeURIComponent(city.value)}%2C+${encodeURIComponent(
          state.value
        )}%2C+${encodeURIComponent(country.value)}`;
  } else {
    // 2 terms in US or CA -> state, country
    // state, country search
    const state = data.terms[0];
    const country = data.terms[1];
    const countryAbbrev =
      country &&
      (country.value === 'Canada' || country.value === 'Canadá' || country.value === 'CA')
        ? 'ca'
        : 'us';
    urlVal = data.appendParamOnly
      ? `${data.url}?latitude=${data.lat}&longitude=${data.lng}&name=${encodeURIComponent(
          state.value
        )}%2C+${encodeURIComponent(countryAbbrev)}`
      : `${data.url}/${countryAbbrev}?latitude=${data.lat}&longitude=${
          data.lng
        }&name=${encodeURIComponent(state.value)}%2C+${encodeURIComponent(countryAbbrev)}`;
  }
  return urlVal;
}

export function GeocodeByAddress(description: string) {
  return (
    geocodeByAddress(description)
      .then((results) => getLatLng(results[0]))
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Geocoding failed:', error))
  );
}

interface AutocompleteCompState {
  inputStyle?: object;
  suggestionsClassNames?: any;
  suggestions?: Array<suggestionType>;
  highlightSuggestion?: suggestionType;
  currentSuggestion?: suggestionType;
  geocodeEndpoint: any;
  onClick?: any;
  placeholder?: any;
  initialValue?: any;
  inputClassName: string;
  showCloseIcon?: boolean;
  apiKey?: string;
  elementId?: string;
  executeOnClick?: boolean;
}

const AutocompleteComp = (props: AutocompleteCompState) => {
  const [highlightSuggestion, setHighlightSuggestion] = useState(null);
  const [currentSuggestion, setCurrentSuggestion] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDownCurrentLocation = async (e: any, onSelectSuggestion: any) => {
    if (e.keyCode === 13) {
      // const mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent> = React.MouseEvent;
      return getMyCurrentLocation(e, onSelectSuggestion);
    }
  };

  const handleCurrentLocationFocus = (
    event: React.FocusEvent<HTMLDivElement>,
    currentState: any
  ) => {
    const currentLocation: any = document.getElementById('use-current-location');
    if (currentLocation) {
      if (event.type === 'focus') {
        cleanCurrentHighlightSuggestion(currentState);
        currentLocation.classList.add('highlighted');
      }
      if (event.type === 'blur') {
        cleanCurrentHighlightSuggestion(currentState);
        currentLocation.classList.remove('highlighted');
      }
    }
  };

  const handleKeyDown = (e: any, suggestion: any, onSelectSuggestion: any) => {
    // console.log('>>> index suggestion selected: ', indexSuggestion);
    if (e.keyCode === 13) {
      e.preventDefault();
      setHighlightSuggestion(suggestion);
      return onSelectSuggestion(
        { description: suggestion.description, terms: suggestion.terms },
        e
      );
    }
  };

  const setHover = (suggestion: any) => {
    setHighlightSuggestion(suggestion);
  };

  const setCurrentLocationStyle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    currentState: any
  ) => {
    const currentLocation: any = document.getElementById('use-current-location');
    if (currentLocation) {
      if (event.type === 'mouseover') {
        cleanCurrentHighlightSuggestion(currentState);
        currentLocation.classList.add('highlighted');
      }
      if (event.type === 'mouseleave') {
        cleanCurrentHighlightSuggestion(currentState);
        currentLocation.classList.remove('highlighted');
      }
    }
  };

  const setCurrentSuggestionHandler = (suggestion: any) => {
    if (!currentSuggestion || (currentSuggestion as any).description !== suggestion.description) {
      setCurrentSuggestion(suggestion);
      props.onClick(suggestion);
    }
  };
  const getMyCurrentLocation = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    onSelectSuggestion: any
  ) => {
    const locationOptions = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 10000,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log("getCurrentPosition", position);

        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const formUrl = `${
          props.geocodeEndpoint
        }?latlng=${position.coords.latitude.toString()},${position.coords.longitude.toString()}`;
        // console.log("formUrl", formUrl);
        fetch(formUrl, requestOptions)
          .then((res) => res.json())

          /* Geocode.fromLatLng(
                position.coords.latitude.toString(),
                position.coords.longitude.toString()
              ) */ .then(
            (response: any) => {
              // console.log('>>> response.results: ', response);

              let filteredResult: any = null;
              let resultDescription: any = null;
              response.results.some((item: any) => {
                const addressComponents = item.address_components;
                // console.log('>>> addressComponents: ', addressComponents);

                if (addressComponents) {
                  return addressComponents.some((ac: any) => {
                    const itemTypes = ac.types;
                    // console.log('>>> itemTypes: ', itemTypes);

                    if (
                      itemTypes.some(
                        (ad: any) =>
                          ad === 'administrative_area_level_3' ||
                          ad === 'administrative_area_level_2' ||
                          ad === 'administrative_area_level_1'
                      )
                    ) {
                      // console.log("filteredResult administrative_area_level_2 or administrative_area_level_3:", item)
                      filteredResult = [];
                      item.address_components.forEach((iac: any) => {
                        // console.log ("checking ", iac);
                        if (
                          (iac.types.includes('locality') && filteredResult.length === 0) ||
                          (iac.types.includes('administrative_area_level_3') &&
                            filteredResult.length === 0) ||
                          (iac.types.includes('administrative_area_level_2') &&
                            filteredResult.length === 0) ||
                          iac.types.includes('administrative_area_level_1') ||
                          iac.types.includes('country')
                        ) {
                          // console.log('adding', iac);
                          filteredResult.push(iac);
                        }
                      });
                      resultDescription = item.formatted_address;
                      return true;
                    }
                    return false;
                  });
                }
                return false;
              });

              if (filteredResult) {
                // console.log('>>> filteredResult: ', filteredResult);
                filteredResult.forEach((ac: any) => {
                  ac.value = ac.short_name;
                  return ac.value;
                });
                // console.log('>>> filteredResult: ', filteredResult);

                const selectedSuggestion = {
                  description: resultDescription,
                  terms: filteredResult,
                };
                return onSelectSuggestion(selectedSuggestion, e);
              }
            },
            (/* error */) => {
              // console.error(error);
            }
          );
      },
      (/* err */) => {
        // console.log(err)
      },
      locationOptions
    );
  };

  const clearSearchBox = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const cleanCurrentHighlightSuggestion = (currentState: any) => {
    if (currentState) {
      if (currentState.highlightSuggestion) {
        setHighlightSuggestion({ ...currentState.highlightSuggestion, place_id: '' });
      }
    }
  };

  return (
    <div className="cvc-autocomplete-container">
      <GooglePlacesAutocomplete
        apiKey={checkForNessus(props.apiKey)}
        initialValue={props.initialValue}
        placeholder={props.placeholder}
        onSelect={props.onClick}
        inputStyle={props.inputStyle}
        minLengthAutocomplete={2}
        inputClassName={props.inputClassName}
        idPrefix={props.elementId}
        renderInput={(
          inputProps: JSX.IntrinsicAttributes &
            React.ClassAttributes<HTMLInputElement> &
            React.InputHTMLAttributes<HTMLInputElement>
        ) => (
          <div className="cvc-autocomplete-input-and-close-container">
            <form className="cvc-autocomplete-form" autoComplete="off">
              <input
                aria-label={props.placeholder ?? 'Search: '}
                ref={inputRef}
                {...inputProps}
                className={props.inputClassName}
              />
              {props.showCloseIcon ? (
                <CloseIcon className="cvc-autocomplete__close-icon" onClick={clearSearchBox} />
              ) : (
                ''
              )}
            </form>
          </div>
        )}
        renderSuggestions={(
          active: any,
          suggestions: any[],
          onSelectSuggestion: (
            arg0: any,
            arg1: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => void
        ) => {
          if (suggestions.length > 0 && !props.executeOnClick) {
            setCurrentSuggestionHandler(suggestions[0]);
          }

          return (
            <div className="ac-container">
              {suggestions.map((suggestion) => (
                <div
                  role="menuitem"
                  tabIndex={0}
                  key={suggestion.place_id}
                  className={
                    suggestion.types.includes('postal_code') ||
                    suggestion.types.includes('locality') ||
                    suggestion.types.includes('administrative_area_level_1') ||
                    suggestion.types.includes('administrative_area_level_2')
                      ? (highlightSuggestion as any)?.place_id === suggestion.place_id
                        ? 'ac-item highlighted cvc-autocomplete-suggestion'
                        : 'ac-item cvc-autocomplete-suggestion'
                      : 'd-none'
                  }
                  onClick={(event) => onSelectSuggestion(suggestion, event)}
                  onKeyDown={(event) => handleKeyDown(event, suggestion, onSelectSuggestion)}
                  onMouseEnter={() => setHover(suggestion)}
                  onFocus={() => setCurrentSuggestionHandler(suggestion)}
                >
                  {suggestion.description}
                </div>
              ))}
              {suggestions.length > 0 && (
                <div
                  id="use-current-location"
                  key="use-current-location"
                  role="button"
                  tabIndex={0}
                  className="ac-item cvc-autocomplete-current-location cvc-autocomplete-suggestion "
                  onMouseOver={(e) => setCurrentLocationStyle(e, highlightSuggestion)}
                  onMouseLeave={(e) => setCurrentLocationStyle(e, highlightSuggestion)}
                  onFocus={(e) => handleCurrentLocationFocus(e, highlightSuggestion)}
                  onBlur={(e) => handleCurrentLocationFocus(e, highlightSuggestion)}
                  onClick={async (e) => getMyCurrentLocation(e, onSelectSuggestion)}
                  onKeyDown={async (e) => handleKeyDownCurrentLocation(e, onSelectSuggestion)}
                >
                  <LocationIcon className="cvc-autocomplete-current-location-icon" />
                  Use Current Location
                </div>
              )}
              {suggestions.length > 0 && (
                <div className="powered-by-google-item" tabIndex={-1}>
                  <PoweredByGoogle />
                </div>
              )}
            </div>
          );
        }}
        suggestionsClassNames={props.suggestionsClassNames}
        autocompletionRequest={{
          types: ['(regions)'],
          componentRestrictions: {
            country: ['us', 'ca', 'pr'],
          },
        }}
      />
    </div>
  );
};

export default AutocompleteComp;
