import React from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import './RetailerPromoComponent.scss';

import { ResolverLink } from 'components/shared';
import { isRetailerHomePage } from 'components/utils';
import { RetailerPromoComponent2 } from './RetailerPromoComponent2';

export function RetailerPromoComponent(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef ? props.page.getContent(documentRef) : undefined;

  if (!document) {
    return null;
  }

  if (isRetailerHomePage(props.page)) {
    return RetailerPromoComponent2(props);
  }

  const { content, content1, link } = document.getData<DocumentData>();
  const theContent = content || content1;
  const destination = link.destinationUrl;
  // if (!destination.toLowerCase().includes('http')) {
  //   let theUrl = window.location.pathname.replace(/#.*\/?/, '');
  //   if (!theUrl.endsWith('/')) theUrl += '/';
  //   destination = theUrl + link.destinationUrl;
  // }

  return (
    <div className={`cvc-retailer-promo${props.page.isPreview() ? ' has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      {theContent && (
        <ResolverLink href={destination}>
          <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(theContent.value) }} />
        </ResolverLink>
      )}
    </div>
  );
}
