import React from 'react';
import { Image } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoRetailerPromo.scss';
import { getUrlFromImage } from 'components/utils';

export function CavcoRetailerPromoList(props: BrProps) {
  const { promos } = props.component.getModels();

  if (!promos) return null;

  const PromoBanners = (id: string) => {
    const document: any = promos && props.page.getContent(id);
    if (!document) return <></>;

    const { image, title, subTitle, content1, content2, link } = document?.model;
    const bannerImage = image && props.page.getContent(image.imagelink);
    const { displayText, destinationUrl } = link;

    return (
      <div key={title} className={`cvc-retailer-promo-container ${title}`}>
        <section>
          {bannerImage && (
            <div className="image-container--left">
              <Image src={getUrlFromImage(bannerImage)} alt={image?.alt} />
            </div>
          )}
          <div className="banner-content--right">
            {title && <h3>{title}</h3>}
            {subTitle && <p>{subTitle}</p>}
            {content1 && <div dangerouslySetInnerHTML={{ __html: content1.value }} />}
            {content2 && content2.value}

            <a className="banner-link btn btn-punchout-large" href={destinationUrl}>
              {displayText}
            </a>
          </div>
        </section>
      </div>
    );
  };

  return <div className="cvc-promo-list-Component">{promos?.items?.map(PromoBanners)}</div>;
}
