import { BrProps } from '@bloomreach/react-sdk';
import React from 'react';
import { Image } from 'react-bootstrap';
import { isStringOnOrTrue, getUrlFromImage } from 'components/utils';

export function BuildingCenterBanner(props: BrProps) {
  const { documents, componentParameterMap, contactSalesUrl } = props.component.getModels();
  const { titleText: titleProp, showContactSales, isCommunity } = componentParameterMap;

  const document = documents && documents[0] && props.page.getContent(documents[0]);
  if (!document) {
    return null;
  }
  const {
    featuredImage,
    plantLocationUid,
    buildingCenterAlias,
    displayName: displayNameTemp,
    displayNameInCommunities,
  } = document.getData();
  const bannerImage =
    featuredImage && featuredImage.imagelink && props.page.getContent(featuredImage.imagelink);
  const titleText = isStringOnOrTrue(isCommunity)
    ? titleProp
    : titleProp || 'Our Floor Plan Series';

  const city = (document as any)?.model?.city ?? [];
  const state = (document as any)?.model?.state ?? [];
  const cityState = `${city}, ${state}`;

  const {
    buildingCenterBrandLogos,
    communityBuildingCenterToBrandsMapping: communityBCToBrandsMapping,
  } = props.component.getModels();

  let communityBCToBrands;
  if (communityBCToBrandsMapping && communityBCToBrandsMapping.hasOwnProperty(plantLocationUid)) {
    communityBCToBrands = communityBCToBrandsMapping[plantLocationUid];
  } else {
    communityBCToBrands = '';
  }

  let brandLogoUrlsArray: any[] = [];
  if (communityBCToBrands) {
    const brandMappingArray = communityBCToBrands.split(',');
    brandLogoUrlsArray = brandMappingArray
      .filter((brand: string) => brand.trim())
      .map((brand: string) => buildingCenterBrandLogos[brand]);
  }

  let displayName = displayNameInCommunities || displayNameTemp;
  displayName = displayName || buildingCenterAlias;
  const isBuildingCenter = (props as any)?.page?.model?.page?.name?.includes(
    'buildingcenter-content'
  );

  return (
    <>
      <div className="cvc-banner cvc-banner-bc">
        <div className="cvc-banner-hero cvc-banner-hero-txt">
          {bannerImage && (
            <div className="cvc-banner-image">
              <Image src={getUrlFromImage(bannerImage)} alt={featuredImage.alt} />
            </div>
          )}
          {isBuildingCenter && (
            <div className="cvc-banner-sidebar">
              <div className="cvc-banner-sidebar-brands">
                {brandLogoUrlsArray.map((url) => (
                  <img
                    key={url}
                    className="cvc-banner-sidebar-brands-logo"
                    src={url}
                    alt={displayName}
                  />
                ))}
              </div>
              <h1 className="cvc-banner-sidebar-title">
                {displayName && (
                  <strong className="cvc-banner-sidebar-title-name">
                    {displayName}
                    <span className="cvc-banner-sidebar-title-divider">, </span>
                  </strong>
                )}
                <span className="cvc-banner-sidebar-title-location">{cityState}</span>
              </h1>
            </div>
          )}
          {titleText && (
            <div className="cvc-banner-content">
              <h1>{titleText}</h1>
            </div>
          )}
        </div>
      </div>

      {contactSalesUrl && showContactSales && isStringOnOrTrue(showContactSales) && (
        <div className="cvc-builtin-contact">
          <div className="cvc-content-sticky">
            <div className="cvc-content-sticky__inner">
              <div className="cvc-content-sticky__rotated">
                <a href={contactSalesUrl} className="btn btn-primary btn-sticky">
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
