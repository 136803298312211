import React, { useRef } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import './floorplanSort.scss';
import { StyledSelect } from '../../FloorPlanResultsStyledComponents';

export const FloorPlanSort = ({
  config,
  sortBy = null,
  changeSort,
}: {
  config: any;
  sortBy: string | null;
  changeSort: any;
}) => {
  const options = useRef<any>(
    config.values.reduce((memo: any, cur: any) => ({ ...memo, [cur.name]: cur }), {})
  );
  const defaultString = JSON.stringify(sortBy || config.defaultValue);
  const defaultValue = defaultString.replace(/:/g, '_').replace(/[^a-z0-9_]/gi, '');

  const handleChangeSort = (value: any) => {
    changeSort(options.current[value].value);
  };

  // Offset Material-ui scroll lock
  // adds/removes 15px padding to fixed-position header
  const openSelect = (open: boolean) => {
    const addRemove = open ? 'add' : 'remove';
    const el = document.querySelector('.cvc-header.is-fixed');
    if (el) {
      el.classList[addRemove]('scroll-lock-offset');
    }
  };

  return (
    <section className="sort-bar">
      <div className="sort-bar__label">
        <span id="sort-bar-label">Sort By:</span>
      </div>
      <div className="sort-bar__select">
        <StyledSelect
          variant="outlined"
          defaultValue={defaultValue}
          onChange={(e) => handleChangeSort(e.target.value)}
          onOpen={() => openSelect(true)}
          onClose={() => openSelect(false)}
          labelId="sort-bar-label"
          label="sort-bar-label"
        >
          {config.values?.map((sort: any) => {
            return (
              <MenuItem key={`${sort.field}-${sort.order}`} value={sort.name}>
                {sort.label}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </div>
    </section>
  );
};
