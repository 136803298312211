import { BrProps } from '@bloomreach/react-sdk';
import { getRetailerData } from 'components/utils';
import React from 'react';
import { Helmet } from 'react-helmet-async';

function pagePropertiesForRtlData(data: RetailerData) {
  const { metadata: cmsMetadata } = data;

  const pageTitle = `Digital Home Design Center of Manufactured Homes in ${data.city}, ${data.state} | ${data.dba}, a Cavco Dealer`;

  const metaDescription =
    cmsMetadata?.metaDescription ||
    `Explore design options of the interior and exterior of your manufactured home at ${data.dba} of ${data.city}, ${data.state}.`;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} data-rh="true" />}
      </Helmet>
    </div>
  );
}

export function PagePropertiesDesignCenter(props: BrProps) {
  const retailerData = getRetailerData(props.page);

  if (!retailerData) {
    return null;
  }
  return pagePropertiesForRtlData(retailerData);
}
