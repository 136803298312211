import React, { useEffect, useState } from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import './AlertBar.scss';

export function AlertBar(props: BrProps) {
  const { document: documentRef, alert, backgroundColor, textColor } = props?.component.getModels();

  const document = documentRef && props?.page.getContent(documentRef);
  const [alertState, setAlertState] = useState<any>(true);

  useEffect(() => {
    if (alertState) {
      window.document.body.classList.remove('alertbar-closed');
    } else {
      window.document.body.classList.add('alertbar-closed');
    }
  }, [alertState]);

  if (!document) {
    return null;
  }

  const handleClose = () => {
    setAlertState(false);
  };

  const { title, content1 } = document.getData();
  const contentVal = content1 && props.page.rewriteLinks(content1.value);
  return (
    <>
      {!!alertState && (
        <div className={`cvc-alert-bar${props.page.isPreview() ? ' has-edit-button' : ''}`}>
          <BrManageContentButton content={document} />
          <div className="cvc-alert-bar-content" style={{ backgroundColor, color: textColor }}>
            {title && <span className="cvc-alert-bar__title">{title} </span>}
            {contentVal && (
              <div
                className="cvc-alert-bar__content"
                dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(contentVal) }}
              />
            )}
            {!!alert && <CloseIcon style={{ fill: textColor }} onClick={handleClose} />}
          </div>
        </div>
      )}
    </>
  );
}
