import { BrProps } from '@bloomreach/react-sdk';
import React, { useEffect, useState } from 'react';
import './CavcoDetailFiles.scss';
import { Collapse } from '@material-ui/core';
import { formatBytes, getSeriesDecorImages } from 'components/utils';
import { CaretDownIcon, ResolverLink } from '../shared';
import { ReactComponent as DownArrowIcon } from '../../assets/images/cavcohomes/down-arrow.svg';

export function CavcoDetailFiles(props: BrProps) {
  const { series } = props.component.getModels();
  const [checked, setChecked] = React.useState(false);
  const [gridCol, setGridCol] = React.useState('120px');
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (windowDimenion.winWidth < 1024) {
      setGridCol('120px');
    } else {
      setGridCol('50px');
    }
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  if (!series) {
    return null;
  }
  const document = props.page.getContent(series[0]);

  const seriesDisplayName = document?.getData().displayName;
  const seriesName = document?.getData().name;
  const descriptionNotUsed = document?.getData().descriptionNotUsed;
  const standardFeatures = document?.getData().standardFeatures;

  function listSeriesDecorImages(albumImages: any) {
    return albumImages.map((mediaAsset: any, index: number) => {
      const { description, ximage } = mediaAsset;
      const image = ximage && ximage.extraLarge;
      const url = image && image.url;
      const fileSize = image && image.size;

      if (url === null || url.length === 0 || description === null || description.length === 0) {
        return null;
      }
      const fileSizeStr = formatBytes(fileSize);
      return (
        <li key={`image-${index.toString()}`}>
          <DownArrowIcon />
          <ResolverLink
            className="decor-image"
            target="_blank"
            rel="noopener noreferrer"
            href={url}
          >
            {description}
          </ResolverLink>{' '}
          (JPG/{fileSizeStr})
        </li>
      );
    });
  }

  function getFiles(pdfs: any) {
    return pdfs.map((pdf: any, indexPdf: number) => {
      const { id, uploadSize: fileSize, uploadFileName, fileName } = pdf;
      if (fileName === null || fileName.length === 0) return null;
      const fileSizeStr = fileSize < 1000 ? `${fileSize} KB` : `${(fileSize / 1024).toFixed(1)} MB`;

      return (
        <li key={`${id}-${indexPdf.toString()}`}>
          <DownArrowIcon />
          <ResolverLink target="_blank" href={uploadFileName}>
            {fileName}
          </ResolverLink>{' '}
          (PDF/{fileSizeStr})
        </li>
      );
    });
  }

  function DownloadFiles(downloadProps: any) {
    const hasFiles = downloadProps.hasAssets || downloadProps.hasImages;
    if (hasFiles) {
      return (
        <>
          <h3 className="series-subtitle">Download Files</h3>
          <ul className="cvc-download-files-list pl-4">
            {downloadProps.hasAssets && getFiles(downloadProps.pdfs)}
            {downloadProps.hasImages && listSeriesDecorImages(downloadProps.decorImages)}
          </ul>
        </>
      );
    }
    return null;
  }

  const getSeriesFiles = (propsFiles: BrProps, isMobile: boolean | null) => {
    const assets = series && series[0] && propsFiles.page.getContent(series[0]);
    if (!assets) {
      return null;
    }
    const pdfs = assets.getData().assets;
    const decorImages = getSeriesDecorImages(props, assets);

    const hasImages = decorImages && decorImages.length > 0;
    let hasAssets = false;
    // validate that at least one entry exists with a valid filename
    for (let i = 0; pdfs && i < pdfs.length; i += 1) {
      if (pdfs[i].fileName != null && pdfs[i].fileName.length > 0) {
        hasAssets = true;
        break;
      }
    }

    if (hasAssets || hasImages) {
      return (
        <DownloadFiles
          pdfs={pdfs}
          decorImages={decorImages}
          isMobile={isMobile}
          hasAssets={hasAssets}
          hasImages={hasImages}
        />
      );
    }
    return null;
  };

  const getBuildingCenterInfo = (propsFiles: BrProps) => {
    const assets = series && series[0] && propsFiles.page.getContent(series[0]);
    if (!assets) {
      return null;
    }

    const { buildingCenter } = assets.getData();
    const bcContent = buildingCenter && propsFiles.page.getContent(buildingCenter);
    if (!bcContent) {
      return null;
    }

    const bcModel = bcContent.getData();
    return `Built by ${bcModel.dba}, ${bcModel.city}, ${bcModel.state}`;
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div className="cavco-panel-container-files">
      <div className="cavco-floorplan-series-container">
        <Collapse in={checked} collapsedSize={gridCol}>
          <div className="floorplanseries-title">
            <div className="title-files">
              <h2 data-elastic-name="series">
                <span className="title-files-prefix">Series: </span>
                {seriesName || seriesDisplayName}
              </h2>
              <span>{getBuildingCenterInfo(props)}</span>
            </div>
          </div>
          <div className="floorplanseries-info">
            <div className="files-container">{getSeriesFiles(props, false)}</div>
            <div className="specs-container">
              {descriptionNotUsed?.value?.length > 0 && (
                <>
                  <h3 className="series-subtitle">Description</h3>
                  <div
                    className="inner-data"
                    dangerouslySetInnerHTML={{
                      __html: descriptionNotUsed?.value ? descriptionNotUsed.value : '',
                    }}
                  />
                </>
              )}
              {standardFeatures?.value?.length > 0 && (
                <>
                  <h3 className="series-subtitle">Standard Features</h3>
                  <div
                    className="inner-data"
                    dangerouslySetInnerHTML={{
                      __html: standardFeatures?.value ? standardFeatures.value : '',
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Collapse>
        {(descriptionNotUsed?.value?.length ||
          standardFeatures?.value?.length ||
          getSeriesFiles(props, false)) && (
          <div className="collapse-control">
            <button type="button" onClick={handleChange} className={`${checked ? 'more' : 'less'}`}>
              {checked ? 'Show Less' : 'Show More'}
              <CaretDownIcon fill="#33687E" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
