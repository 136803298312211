import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './BuildingCenterDisclaimers.scss';

export function BuildingCenterDisclaimers(props: BrProps) {
  const { floorPlanDisclaimerLabels } = props.component.getModels();

  const resourceBundle = floorPlanDisclaimerLabels;

  let disclaimer = 'The disclaimer resource bundle is not connected.';
  let pricingDisclaimer = disclaimer;

  if (resourceBundle) {
    disclaimer = resourceBundle.disclaimer;
    pricingDisclaimer = resourceBundle.pricingDisclaimer;
  }

  return (
    <div className="cvc-bc-disclaimer cvc-disclaimer">
      <p dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(disclaimer) }} />
      <p dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(pricingDisclaimer) }} />
    </div>
  );
}
