import React from 'react';
import Button from '@material-ui/core/Button';

import { StyledButtonGroup } from '../../FloorPlanResultsStyledComponents';

export const ButtonFacet = ({ facet, selectedFacets, handleSelectedFacet }: any) => {
  return (
    <StyledButtonGroup
      color="primary"
      variant="outlined"
      aria-label="outlined primary button group"
    >
      {facet?.options?.map((option: any) => {
        const selectedClass = selectedFacets[facet.key]?.includes(option.value)
          ? 'facet-selected'
          : '';

        return (
          <Button
            key={`${facet.key}-${option.value}`}
            className={selectedClass}
            disableElevation
            disableRipple
            fullWidth
            onClick={() => handleSelectedFacet(facet.key, option.value, null)}
          >
            {option.value}
          </Button>
        );
      })}
    </StyledButtonGroup>
  );
};
