import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import { scroller } from 'react-scroll';

import { ReactComponent as HeartIcon } from '../../assets/images/cavcohomes/heart-icon.svg';
import './CavcoRetailerStickyBanner.scss';

export function CavcoRetailerStickyBanner(props: BrProps) {
  const { anchorTo } = props.component.getModels().componentParameterMap;
  const scrollToForm = () =>
    scroller.scrollTo(anchorTo, {
      duration: 1000,
      smooth: true,
      offset: -150,
    });

  return (
    <div className="cvc-sticky-banner">
      <ResolverLink className="favorite-link">
        <HeartIcon className="favorite-link__icon" />
      </ResolverLink>
      <button type="button" className="anchor-btn" onClick={scrollToForm}>
        Request Info
      </button>
    </div>
  );
}
