/*
 *
 * This component creates the metatdata for the banner search results page, the floor plan and retailer searches.
 * ex: https://qa.cavco.bloomreach.cloud/site/search
 *
 */

import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function getSearchBy(searchParameters: any) {
  if (searchParameters) {
    const locationNameSearched = queryString.parse(searchParameters).name
      ? queryString.parse(searchParameters).name!.toString()
      : '';
    const latitudeVal = queryString.parse(searchParameters).latitude
      ? parseFloat(queryString.parse(searchParameters).latitude!.toString())
      : '';
    const longitudeVal = queryString.parse(searchParameters).longitude
      ? parseFloat(queryString.parse(searchParameters).longitude!.toString())
      : '';

    let countryAbbrev = 'US';
    let isUS = true;
    let state = '';
    if (locationNameSearched.split(',').length >= 2) {
      const locs: any = locationNameSearched.split(',');
      if (locs[locs.length - 1].includes('Canada') || locs[locs.length - 1].includes('CA')) {
        countryAbbrev = 'CA';
        isUS = false;
      }
      state = locs[locs.length - 2].trim();
    }

    if (latitudeVal && longitudeVal) {
      return `by ${countryAbbrev} location`;
    }
    return `by ${isUS ? 'state' : 'province'} ${state}`;
  }
}

function pagePropertiesForRtlData(searchParameters: any, props: BrProps) {
  const models = props.component.getModels();
  const params = models.componentParameterMap;

  if (!params) {
    return null;
  }

  let { pageTitle, metaKeywords } = params;
  const metaDescription = params.metaKeywords;

  const paths = window.location.pathname.split('/');
  if (paths[paths.length - 1] !== 'search') {
    const searchBy = getSearchBy(searchParameters);
    pageTitle = `${pageTitle} ${searchBy}`;
    metaKeywords = `${metaKeywords}, ${searchBy}`;
  }

  const ogTitle = pageTitle;
  const ogDescription = metaDescription;
  const robots = ['index', 'follow'];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}

export function PagePropertiesSearch(props: BrProps) {
  const searchParameters = useLocation().search;

  return pagePropertiesForRtlData(searchParameters, props);
}
