import React, { memo } from 'react';
import './CavcoRetailerEvent.scss';

const parseDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
};

const metaDate = (timestamp: number) => {
  const date = new Date(timestamp);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();

  if (parseInt(day, 10) < 10) {
    day = `0${day}`;
  }

  if (parseInt(month, 10) < 10) {
    month = `0${month}`;
  }

  return [year, month, day].join('-');
};

export interface CavcoRetailerEventProps {
  title: string;
  introduction: string;
  date: number;
  endDate: number;
}

const CavcoRetailerEvent: React.FC<
  CavcoRetailerEventProps & { retailerEventLabels: { upcoming: string } }
> = ({ title, introduction, date, endDate, retailerEventLabels }) => {
  const upcoming = retailerEventLabels?.upcoming || 'UPCOMING EVENT';

  return (
    <div className="cvc-cavco-retailer-event" itemScope itemType="https://schema.org/Event">
      <div className="cvc-cavco-retailer-event__upcoming">
        <h5>{upcoming}</h5>
        <h3 itemProp="name">{title}</h3>
        <h6>
          {parseDate(date)} - {parseDate(endDate)}
          <meta itemProp="startDate" content={metaDate(date)} />
          <meta itemProp="endDate" content={metaDate(endDate)} />
        </h6>
      </div>
      <div className="cvc-cavco-retailer-event__description">
        <p className="cvc-cavco-retailer-event__content" itemProp="description">
          {introduction}
        </p>
      </div>
    </div>
  );
};

export default memo(CavcoRetailerEvent);
