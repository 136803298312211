import React, { FunctionComponent } from 'react';
import { BrPageContext, BrComponent } from '@bloomreach/react-sdk';
import '../../shared/ProductDetails/ProductDetails.scss';
import './CavcoFloorPlanDetails.scss';

const CavcoFloorPlanDetails: FunctionComponent = () => {
  const page = React.useContext(BrPageContext)!;

  const displayBrComponentsByContainer = (containerName: string) => {
    const main =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'main');
    const floorPlanDetailContainer =
      main && main.getChildren().find((comp: any) => comp.getName() === 'detailcontainer');

    const components =
      floorPlanDetailContainer &&
      floorPlanDetailContainer
        .getChildren()
        .filter((comp: any) => comp.getModels().componentParameterMap.container === containerName);

    if (!components) {
      return null;
    }

    return components.map((item: any) => {
      return (
        <div className="d-block" key={`div-${item.model.name}`}>
          <BrComponent key={item.model.name} path={item.model.name} />
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrComponent path="header-content" />
      <BrComponent path="retailer-header-content" />
      <main
        className="cvc-main"
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-floorplan">
          <BrComponent path="main">
            <BrComponent path="detailcontainer">
              <div className="cvc-floorplan-detail-container cvc-product-detail-container">
                {displayBrComponentsByContainer('top-container')}
                <div className="cavco-floorplandetail-component">
                  {displayBrComponentsByContainer('panel1')}
                  {displayBrComponentsByContainer('panel2')}
                  {displayBrComponentsByContainer('panel3')}
                  {displayBrComponentsByContainer('panel4')}
                  {displayBrComponentsByContainer('panel5')}
                  {displayBrComponentsByContainer('panel6')}
                  {displayBrComponentsByContainer('panel7')}
                  {displayBrComponentsByContainer('panel8')}
                  {displayBrComponentsByContainer('panel9')}
                </div>
              </div>
            </BrComponent>
          </BrComponent>
        </section>
      </main>
      <BrComponent path="footer-content" />
      <BrComponent path="retailer-footer-content" />
    </div>
  );
};

export default CavcoFloorPlanDetails;
