import { ReactComponent as CavcoHomesSearchIcon } from 'assets/images/cavcohomes/search-icon.svg';
import React, { useEffect, useState } from 'react';
import { Navbar, Container, Modal, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { BrProps, BrPageContext } from '@bloomreach/react-sdk';
import { useHistory, useLocation } from 'react-router';
import { ResolverLink } from 'components/shared';
import { formatForHistory } from 'components/shared/ResolverLink';
import { Spinner } from 'components/shared/Spinner';
import { RetailerFavoriteIcon } from 'components/shared/Icons';
import {
  getCurrentSiteNameDisplay,
  getBasePath,
  getBuildingCenterFromHeader,
  getMyAccountPath,
  getCurrentSiteName,
  isStringOnOrTrue,
  getUrlFromImage,
} from 'components/utils';
import { Drawer, IconButton, List, ListItem } from '@material-ui/core';
import { getCookie, deleteAllCookie } from 'components/hooks/cookie';
import { SignInModals } from 'components/MyAccount/SignInModals';
import { Link } from 'react-router-dom';
import { SITE_NAMES } from 'components/enums';
import { LoginStatus, loginSubject$ } from 'components/MyAccount/LoginStatus';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';
import { AccountMobileMenu } from './components/CavcoHomes/AccountMobileMenu';
import { ReactComponent as NavBarTogglerIcon } from '../../assets/images/nav-search-icon.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/images/nav-icon.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left-arrow-icon.svg';
import { ReactComponent as UserMyFavoritesIcon } from '../../assets/images/user-icon.svg';
import { ReactComponent as UserAccountIcon } from '../../assets/images/cavcohomes/account-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { ReactComponent as BcLogoDurango } from '../../assets/images/buildingcenter-logo-durango.svg';
import { ReactComponent as BcLogoNC } from '../../assets/images/buildingcenter-logo-northcarolina.svg';
import { ReactComponent as BcLogoTexas } from '../../assets/images/buildingcenter-logo-texas.svg';
import { ReactComponent as BcLogoWest } from '../../assets/images/buildingcenter-logo-west.svg';
import { ReactComponent as BcLogoDestiny } from '../../assets/images/buildingcenter-logo-destiny.svg';
import { ReactComponent as BcLogoNationwide } from '../../assets/images/buildingcenter-logo-nationwide.svg';
import {
  HEADER_COMPONENTPARAMETERMAP_VARIANT,
  PAGE_VARIANT,
  SCREEN_SIZES,
  HEADER_CONTENT_TYPE,
} from '../enums';
import './Header.scss';
import './CavcoHomesHeader.scss';
import './CavcoChariotEagleHeader.scss';
import './CavcoDotComHeader.scss';
import './CavComHeader.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { FloorPlanRequestInfoWithParams } from '../FloorPlanRequestInfo/FloorPlanRequestInfo';
import { DisplayHomeRequestInfoWithParams } from '../DisplayHomeRequestInfo/DisplayHomeRequestInfo';
import { Menu } from '../Menu/Menu';
import SiteGlobalSwitches, { formatPhoneNumber, getRetailerData } from '../utils';
import { RetailerHeader2 } from './RetailerHeader2';
import CavcoRetailerHeader from './CavcoRetailerHeader';

let HeaderVariant = '';
declare let window: any;
let isSearching = false;

const setIsSearching = (value: any) => {
  isSearching = value;
};

function DisplaySearchIcon({
  showSearch,
  searchUrl,
  headerVariant,
  windowDimensions,
  spinnerColorInLightBackground,
  spinnerColorInDarkBackground,
  onSearch,
}: {
  showSearch: string;
  searchUrl: string;
  headerVariant: string;
  windowDimensions: any;
  spinnerColorInLightBackground: string;
  spinnerColorInDarkBackground: string;
  onSearch: () => void;
}) {
  const history = useHistory();
  const page = React.useContext(BrPageContext);
  const bcPath = getBuildingCenterFromHeader(page);
  const [showSearchInputBox, setShowSearchInputBox] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInputEl = React.useRef<HTMLInputElement>(null);
  const searchButtonEl = React.useRef<HTMLButtonElement>(null);
  const isCavcoHomesVariant =
    HeaderVariant === PAGE_VARIANT.CAVCOHOMES || HeaderVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER;

  useEffect(() => {
    if (showSearchInputBox) {
      searchInputEl?.current?.focus();
    }
  }, [showSearchInputBox]);

  if (showSearch !== 'on') return <></>;
  const IconVariant = headerVariant === PAGE_VARIANT.CAVCOHOMES ? CavcoHomesSearchIcon : SearchIcon;

  const redirectToSearchPage = async () => {
    await setIsSearching(true);

    if (searchText) {
      const newSearchUrl = page!.getUrl(searchUrl);
      if (!bcPath) {
        await history.push(formatForHistory(newSearchUrl + encodeURIComponent(searchText)));
      } else {
        const url = formatForHistory(newSearchUrl + encodeURIComponent(searchText));
        const win = window.open(url, '_self');
        win.focus();
      }
      // await history.push(formatForHistory(newSearchUrl + encodeURIComponent(searchText)));
      await setIsSearching(false);
    } else {
      await setIsSearching(false);
      const headerSearchInput = document.getElementById('cvc-header-search-input-box');
      if (headerSearchInput) {
        (headerSearchInput as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      redirectToSearchPage();
      onSearch();
    }
  };
  // const handleHideSearchInput = () => {
  //   setSearchText('');
  //   setShowSearchInputBox(false);
  // };

  const displaySearchInputBox = () => {
    const handleHideSearchInput = () => {
      setSearchText('');
      setShowSearchInputBox(false);
    };

    const handleBlur = (e: React.FocusEvent) => {
      const { relatedTarget } = e;
      if (searchButtonEl.current && relatedTarget !== searchButtonEl.current) {
        handleHideSearchInput();
      }
    };

    const handleSearch = () => {
      redirectToSearchPage();
      handleHideSearchInput();
      onSearch();
    };

    const blurProps = isCavcoHomesVariant
      ? {
          tabIndex: 1,
          onBlur: handleBlur,
        }
      : {};
    return (
      <div
        className={classNames(
          'cvc-header-search-input-container',
          `${headerVariant}`,
          windowDimensions.isMobile || windowDimensions.isTablet ? 'is-mobile' : ''
        )}
        {...blurProps}
      >
        <div className="cvc-header-search-input-div">
          {HeaderVariant === 'is-cavcohomes-retailer' ? null : (
            <>
              <input
                aria-label="Search the Site"
                id="cvc-header-search-input-box"
                type="text"
                ref={searchInputEl}
                placeholder="Search the Site"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(event.target.value)
                }
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
                className="cvc-header-search-input"
              />
              {isCavcoHomesVariant ? (
                <IconButton
                  ref={searchButtonEl}
                  onClick={handleSearch}
                  className="cvc-header-search-input-search-button"
                  aria-label="Search"
                >
                  <IconVariant className="cvc-header-search-input-search-button-icon" />
                </IconButton>
              ) : (
                <CloseIcon
                  className="cvc-header-search-input-close-icon"
                  onClick={() => handleHideSearchInput()}
                />
              )}
            </>
          )}

          {
            // eslint-disable-next-line no-nested-ternary
            windowDimensions.isMobile || windowDimensions.isTablet ? (
              !isSearching ? (
                <IconVariant
                  className="cvc-header-search-input-search-icon"
                  onClick={redirectToSearchPage}
                />
              ) : (
                <Spinner
                  color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
                />
              )
            ) : null
          }
        </div>
      </div>
    );
  };

  return windowDimensions.isMobile || windowDimensions.isTablet ? (
    displaySearchInputBox()
  ) : (
    <>
      {showSearchInputBox && displaySearchInputBox()}
      {!isSearching ? (
        (!isCavcoHomesVariant || (isCavcoHomesVariant && !showSearchInputBox)) && (
          <IconVariant
            className="cvc-header-comp-search-icon"
            onClick={
              showSearchInputBox
                ? redirectToSearchPage
                : () => setShowSearchInputBox(!showSearchInputBox)
            }
          />
        )
      ) : (
        <Spinner
          inDarkBackground={headerVariant === PAGE_VARIANT.FAIRMONT}
          color={
            headerVariant === PAGE_VARIANT.FAIRMONT && spinnerColorInDarkBackground
              ? `${spinnerColorInDarkBackground}`
              : `${spinnerColorInLightBackground}`
          }
        />
      )}
    </>
  );
}

function displayHomeTextLink(homeTextLink: LinkCompound) {
  if (HeaderVariant !== PAGE_VARIANT.PARK_MODEL) return '';

  if (HeaderVariant === PAGE_VARIANT.PARK_MODEL && homeTextLink.displayText.length > 0) {
    // let path = new URL(homeTextLink.destinationUrl).pathname;
    // let path = new URL(window.location.origin + process.env.REACT_APP_SPA_BASE_URL).pathname;
    // if (homeTextLink.destinationUrl) {
    //   if (!homeTextLink.destinationUrl.includes(window.location.origin)) {
    //     path = new URL(window.location.origin + homeTextLink.destinationUrl).pathname;
    //   } else {
    //     path = new URL(homeTextLink.destinationUrl).pathname;
    //   }
    // }

    return (
      <ResolverLink
        id={homeTextLink.id}
        href={homeTextLink.destinationUrl || '/'}
        className="cvc-header-comp-home-text"
        target={homeTextLink.newWindow ? '_blank' : '_self'}
      >
        {homeTextLink.displayText}
      </ResolverLink>
    );
  }
}
function displayLogo(image: any, imageAltText: string, additionalLogoText: string) {
  if (!image) return '';

  return (
    <div
      className={`cvc-header-comp-navbar-brand-image-container ${
        additionalLogoText ? 'has-tag' : ''
      }`}
    >
      <img
        id="logo"
        className="cvc-header-comp-navbar-brand-image"
        src={getUrlFromImage(image)}
        alt={imageAltText}
      />
      {additionalLogoText ? <span className="navbar-brand-tag">{additionalLogoText}</span> : ''}
    </div>
  );
}

export function getImage(containerName: string, item: any, imgContent: any) {
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';
  let originalImgPath = '';

  if (!imgContent && !item) return '';

  if (imgContent) {
    mediumImgPath = getUrlFromImage(imgContent, 'medium');
    largeImgPath = getUrlFromImage(imgContent, 'large');
    extraLargeImgPath = getUrlFromImage(imgContent, 'extraLarge');
    originalImgPath = getUrlFromImage(imgContent);
  } else if (item.hasOwnProperty('ximage')) {
    if (item.ximage) {
      mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
      largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
      extraLargeImgPath =
        item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
      originalImgPath = extraLargeImgPath;
    }
  }

  return (
    <picture key={`${containerName}-${item.id}`}>
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={originalImgPath} />
      <source
        media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
        srcSet={extraLargeImgPath}
      />
      <source
        media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
        srcSet={largeImgPath}
      />
      <img className="cvc-header-comp-navbar-brand-image" alt={item.alt} src={mediumImgPath} />
    </picture>
  );
}

function getVariant(variant: string): string {
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.PARK_MODEL) {
    return PAGE_VARIANT.PARK_MODEL;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.RETAILER) {
    return PAGE_VARIANT.RETAILER;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.BUILDINGCENTER) {
    return PAGE_VARIANT.BUILDINGCENTER;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES) {
    return PAGE_VARIANT.CAVCOHOMES;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES_RETAILER) {
    return PAGE_VARIANT.CAVCOHOMES_RETAILER;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.CAVCO_DOT_COM_HEADER) {
    return PAGE_VARIANT.CAVCO_DOT_COM;
  }
  return PAGE_VARIANT.FAIRMONT;
}

function displayNavLinks(headerVariant: string, page: any, windowDimensions: any, history: any) {
  if (headerVariant === PAGE_VARIANT.PARK_MODEL) {
    return Menu(HEADER_CONTENT_TYPE.PARK_MODEL, page, windowDimensions, history);
  }
  if (headerVariant === PAGE_VARIANT.RETAILER) {
    return Menu(HEADER_CONTENT_TYPE.RETAILER, page, windowDimensions, history);
  }

  if (headerVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER) {
    return Menu(HEADER_CONTENT_TYPE.CAVCOHOMES_RETAILER, page, windowDimensions, history);
  }

  if (headerVariant === PAGE_VARIANT.CAVCO_DOT_COM) {
    return Menu(HEADER_CONTENT_TYPE.CAVCO_DOT_COM, page, windowDimensions, history);
  }

  return Menu(HEADER_CONTENT_TYPE.FAIRMONT, page, windowDimensions, history);
}

function displayMobileLink(mobileLink: any) {
  const currentSite = getCurrentSiteName();
  const tollFreeNbr = () => {
    if (currentSite === SITE_NAMES.PALM_HARBOR) {
      return (
        <span className="cvc-header-mobile-tollfree">
          Call A Model Center: <a href="tel:1-888-466-3719">1-888-466-3719</a>
        </span>
      );
    }
  };
  return (
    <div className="cvc-header-comp-navbar-mobile-link-container">
      {tollFreeNbr()}
      <span className="cvc-header-comp-navbar-mobile-link-text">{mobileLink.displayText}</span>
      <ResolverLink
        className="cvc-header-comp-navbar-mobile-link nav-link text-capitalize"
        href={mobileLink.destinationUrl}
        target={mobileLink.newWindow ? '_blank' : '_self'}
      >
        <LeftArrowIcon className="cvc-header-comp-navbar-mobile-link-icon" />
        <span>{mobileLink.linkTitle}</span>
      </ResolverLink>
    </div>
  );
}

function displayRetailerLogo(containerName: string, retailerImage: any, retailerLogoImg: any) {
  // const path = window.location.pathname.includes('/site') ? '/site' : '';
  const path = window.location.pathname;

  return (
    <div className="cvc-header-comp-navbar-mobile-link-retailer-icon-container">
      <ResolverLink href={path}>
        {getImage(containerName, retailerImage, retailerLogoImg)}
      </ResolverLink>
    </div>
  );
}

function getRetailerStateAndCity(retailerData: any) {
  if (!retailerData.city && !retailerData.state) return '';

  return `${retailerData.city}, ${retailerData.state}`;
}

function getRetailerLogoImageKeyBy(parkModelRetailer: boolean, community: boolean): string {
  const imagesArray = [
    { key: 'false-false', value: 'std-retailer' },
    { key: 'false-true', value: 'std-community' },
    { key: 'true-false', value: 'park-retailer' },
    { key: 'true-true', value: 'park-community' },
  ];
  const keyCompound = `${parkModelRetailer.toString()}-${community.toString()}`;

  const imageFound = imagesArray.find((item) => item.key === keyCompound);

  if (!imageFound) {
    return 'std-community';
  }

  return imageFound.value;
}

function displayFullAddress(retailerData: any) {
  return `${retailerData?.address1}, ${retailerData?.city}, ${retailerData?.state} ${retailerData?.zip}`;
}

function scrollToElementById(elementId: any, url: any) {
  // offset to account for header
  const yOffset = -200;
  const element = window.document.getElementById(elementId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  } else {
    window.location.href = `${url}#${elementId}`;
  }
}

export function Header(props: BrProps) {
  const location = useLocation();
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [requestInfoModal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);
  const windowDimensions = useWindowDimensions();
  const [showRightSlide, setRightSlide] = useState(false);
  const [cavcoUserInfo] = useStateWithLocalStorage('cavcoUserInfo');
  const { page } = props as any;
  const bcDocument = getBuildingCenterFromHeader(page);
  let additionalLogoText = '';
  if (bcDocument) {
    additionalLogoText = bcDocument.buildingCenterAlias
      ? bcDocument.buildingCenterAlias
      : `${bcDocument.city}, ${bcDocument.state}`;
  }

  const { buildingCenters } = props.component.getModels();

  const headerContent = (page as any)
    ?.getComponent()
    .getChildren()
    .find((item: any) => item.model.name === 'header-content');
  const headerComp = headerContent
    ?.getChildren()
    .find((item: any) => item.model.name === 'header-component');
  const headerInfo = headerComp?.model?.models?.communityHeaderLabels?.communityHeaderInfo;
  const checkHeaderComp = Boolean(headerComp?.model.label === 'Community Header Component');

  const buildingCentersMapSort: any = buildingCenters
    ?.map((item: any) => (
      <li key={item.name}>
        <Dropdown.Item href={`/${item.name}`}>
          <LeftArrowIcon className="dropdown-item-icon" />
          {item.displayName}
        </Dropdown.Item>
      </li>
    ))
    .sort();

  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);

  const [userId, setUserId] = useState(getCookie('cavcouserid'));

  const handleSetSuccessfulSignIn = () => {};

  useEffect(() => {
    const subscription = loginSubject$.subscribe((loginStatus: any) => {
      if (loginStatus) {
        setUserId(getCookie('cavcouserid'));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const { componentParameterMap } = props.component.getModels();
  const isProd =
    componentParameterMap &&
    componentParameterMap.environment &&
    componentParameterMap.environment === 'prod';
  const basePath: string = getBasePath(isProd);

  const [showDropdown, setShowDropdown] = useState(false);
  const logOut = () => {
    setShowDropdown(false);
    localStorage.clear();
    deleteAllCookie();
    loginSubject$.next(LoginStatus.loggedOut);
  };

  const isCavcoHomesVariant =
    HeaderVariant === PAGE_VARIANT.CAVCOHOMES || HeaderVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER;

  const handleCloseMenu = () => {
    setMenuOpen(false);
    if (isCavcoHomesVariant && showRightSlide) {
      setRightSlide(false);
    }
  };

  const accountMenuClasses = classNames({
    'cvc-header-comp-navbar-navigation': isCavcoHomesVariant,
    'account-mobile-menu': isCavcoHomesVariant,
    'is-open': isCavcoHomesVariant && showRightSlide,
  });

  const UserAccountRightSlide = () => {
    // const {showRightSlide,setRightSlide,logOut} = props;
    if (isCavcoHomesVariant) {
      const accountLinks = [
        {
          name: 'My Favorites',
          url: `${basePath}${getMyAccountPath('my-account')}`,
        },
        {
          name: 'My Settings',
          url: `${basePath}${getMyAccountPath('my-account?settings')}`,
        },
      ];
      const mainAccountLink = {
        name: 'My Cavco Account',
        url: `${basePath}${getMyAccountPath('my-account')}`,
      };
      return (
        <AccountMobileMenu accountLinks={accountLinks} mainLink={mainAccountLink}>
          <ListItem
            onClick={() => {
              logOut();
              setRightSlide(false);
            }}
          >
            <Nav.Link className="account-mobile-menu_link cvc-header-comp-navbar-nav-link nav-link">
              Log Out
            </Nav.Link>
          </ListItem>
        </AccountMobileMenu>
      );
    }
    return (
      <Drawer
        className="cvc-header-comp-navbar-user"
        anchor="right"
        open={showRightSlide}
        onClose={() => setRightSlide(false)}
      >
        <div className="cvc-header-comp-navbar-sidebar-header">
          <h4 className="cvc-header-comp-navbar-sidebar-header-title">
            My {getCurrentSiteNameDisplay()} Account
          </h4>
          <button
            type="button"
            className="cvc-header-comp-navbar-sidebar-closer"
            aria-controls="responsive-navbar-nav"
            aria-label="side bar toggle"
            onClick={() => setRightSlide(false)}
          >
            <LeftArrowIcon
              style={{ transform: 'scaleX(-1)' }}
              className="cvc-header-comp-navbar-close-icon"
              alt="sidebar nav toggle"
            />
          </button>
        </div>

        <div className="cvc-header-comp-navbar-sidebar-content">
          <List>
            <ListItem button>
              <Link
                key={`${basePath}${getMyAccountPath('my-account')}`}
                to={`${basePath}${getMyAccountPath('my-account')}`}
              >
                My Favorites
              </Link>
            </ListItem>
            <List>
              <ListItem button>
                <Link
                  key={`${basePath}${getMyAccountPath('my-account?floor-plans')}`}
                  to={`${basePath}${getMyAccountPath('my-account?floor-plans')}`}
                >
                  My Floor Plans
                </Link>
              </ListItem>
              <ListItem button>
                <Link
                  key={`${basePath}${getMyAccountPath('my-account?locations')}`}
                  to={`${basePath}${getMyAccountPath('my-account?locations')}`}
                >
                  My Locations
                </Link>
              </ListItem>
            </List>
            <ListItem button>
              <Link
                key={`${basePath}${getMyAccountPath('my-account?settings')}`}
                to={`${basePath}${getMyAccountPath('my-account?settings')}`}
              >
                My Settings
              </Link>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                logOut();
                setRightSlide(false);
              }}
            >
              Log Out
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  };

  const IconDisplayMobileTablet = () => {
    // const{setRightSlide,openLoginModal} = props;
    return (
      <span>
        <span
          onClick={() => setRightSlide(true)}
          onKeyDown={() => setRightSlide(true)}
          role="button"
          aria-label="display mobile button"
          tabIndex={0}
        >
          {isCavcoHomesVariant ? (
            <UserAccountIcon
              className="cvc-header-comp-navbar-user-icon logged-in"
              hidden={!userId}
            />
          ) : (
            <UserMyFavoritesIcon
              className="cvc-header-comp-navbar-user-icon logged-in"
              hidden={!userId}
            />
          )}
        </span>
        {isCavcoHomesVariant ? (
          <UserAccountIcon
            className="cvc-header-comp-navbar-user-icon"
            hidden={!!userId}
            onClick={openLoginModal}
          />
        ) : (
          <UserMyFavoritesIcon
            className="cvc-header-comp-navbar-user-icon"
            hidden={!!userId}
            onClick={openLoginModal}
          />
        )}
      </span>
    );
  };

  function displaySignIn(showSignIn: string) {
    const pressEscOrSpacebar = (event: { key: string }) => {
      if (event.key === 'Enter' || event.key === '32') {
        openLoginModal();
      }
    };
    return showSignIn === 'on' && !!!userId ? ( // eslint-disable-line
      <button
        className="cvc-header-comp-favorites"
        onClick={openLoginModal}
        onKeyDown={pressEscOrSpacebar}
        style={{ cursor: 'pointer' }}
        // role="button"
        aria-label="display login button"
        // tabIndex={0}
        type="button"
      >
        {isCavcoHomesVariant ? (
          <UserAccountIcon className="cvc-header-comp-favorites-user-icon" />
        ) : (
          <UserMyFavoritesIcon className="cvc-header-comp-favorites-user-icon" />
        )}
        <span className="cvc-header-comp-favorites-title">Sign In</span>
      </button>
    ) : showSignIn === 'on' && !!userId ? (
      <Nav className="user-logged-in">
        <NavDropdown
          role="button"
          tabIndex={0}
          onKeyDown={() => history.push(`${basePath}${getMyAccountPath('my-account')}`)}
          onClick={() => history.push(`${basePath}${getMyAccountPath('my-account')}`)}
          title={
            <>
              {isCavcoHomesVariant ? (
                <>
                  <IconButton className={`${HeaderVariant}_user-icon-button`}>
                    <UserAccountIcon className={`${HeaderVariant}_user-icon`} />
                  </IconButton>
                  {cavcoUserInfo && !!cavcoUserInfo.firstName && (
                    <span>Hi, {cavcoUserInfo.firstName}</span>
                  )}
                </>
              ) : (
                <UserMyFavoritesIcon className="cvc-header-comp-favorites-user-icon logged-in" />
              )}
              {!isCavcoHomesVariant ? (
                <span
                  aria-label="My Favorites button"
                  className="cvc-header-comp-favorites-title caret-off"
                >
                  My Favorites
                </span>
              ) : (
                ''
              )}
            </>
          }
          id="my-favorites-dropdown"
          className={`cvc-header-comp-favorites ${HeaderVariant}_my-account_dropdown`}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
          show={showDropdown}
        >
          <NavDropdown.Item
            className="cvc-header-comp-favorites-dropdown"
            href={`${basePath}my-account?favorites`}
          >
            {isCavcoHomesVariant ? (
              <span
                className={`cvc-header-comp-favorites-title caret-off ${HeaderVariant}_my-account_title`}
              >
                My Favorites
              </span>
            ) : (
              ''
            )}
          </NavDropdown.Item>
          <NavDropdown.Item
            className="cvc-header-comp-favorites-dropdown"
            href={`${basePath}${getMyAccountPath('my-account?settings')}`}
          >
            <span className={`cvc-header-comp-favorites-title ${HeaderVariant}_my-account_title`}>
              My Settings
            </span>
          </NavDropdown.Item>
          {isCavcoHomesVariant && <hr className={`${HeaderVariant}_my-account_divider`} />}
          <NavDropdown.Item onClick={logOut} className="cvc-header-comp-favorites-dropdown">
            <span className={`cvc-header-comp-favorites-title ${HeaderVariant}_my-account_title`}>
              Log Out
            </span>
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    ) : (
      ''
    );
  }
  const displayBuildingCenterLogo = (containerName: string, bcName: string) => {
    switch (bcName) {
      case 'Durango':
        return <BcLogoDurango className="bc-logo durango-logo" />;
      case 'NorthCarolina':
        return <BcLogoNC className="bc-logo northcarolina-logo" />;
      case 'Texas':
        return <BcLogoTexas className="bc-logo texas-logo" />;
      case 'West':
        return <BcLogoWest className="bc-logo west-logo" />;
      case 'Destiny':
        return <BcLogoDestiny className="bc-logo destiny-logo" />;
      case 'Nationwide':
        return <BcLogoNationwide className="bc-logo nationwide-logo" />;
      default:
        break;
    }
  };

  const {
    header: documentRef,
    spinnerConfig,
    myAccountConfig,
    siteGlobalSwitches,
  } = props.component.getModels();
  HeaderVariant = getVariant(componentParameterMap.variant);

  const document = documentRef ? props.page.getContent(documentRef) : undefined;

  SiteGlobalSwitches.myAccountSwitchOn = !!(
    siteGlobalSwitches && siteGlobalSwitches.myAccountSwitch.toLowerCase() === 'on'
  );

  SiteGlobalSwitches.showFloorPlanPricing = !!(
    siteGlobalSwitches && siteGlobalSwitches.showFloorPlanPricing.toLowerCase() === 'on'
  );

  SiteGlobalSwitches.showFloorPlanCountry = !!(
    siteGlobalSwitches && siteGlobalSwitches.showFloorPlanCountry.toLowerCase() === 'on'
  );

  SiteGlobalSwitches.useElasticSearchFloorplans = !!(
    siteGlobalSwitches && siteGlobalSwitches.useElasticSearchFloorplans?.toLowerCase() === 'on'
  );

  const showSignIn = SiteGlobalSwitches.myAccountSwitchOn
    ? componentParameterMap.showSignIn
    : 'off';

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;
  const spinnerColorInDarkBackground = spinnerConfig && spinnerConfig.spinner_color_dark_background;

  const isDesktop = !windowDimensions.isMobile && !windowDimensions.isTablet;

  useEffect(() => {
    const { hash } = window.location;
    const element = hash && window.document.getElementById(hash.substr(1));
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 200;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  // this closes sidebar menu when you click off of it
  useEffect(() => {
    const sidebar = window.document.querySelector('#responsive-navbar-nav');
    if (
      !isDesktop &&
      sidebar &&
      sidebar.classList.contains('is-open') &&
      HeaderVariant !== PAGE_VARIANT.CAVCOHOMES
    ) {
      window.document.body.addEventListener('click', (e: any) => {
        // Makes it so you can't click on the sidebar or modal to close it.
        if (
          !e.target.closest('#id-modal-body') &&
          e.target.closest('#responsive-navbar-nav') === null
        ) {
          setMenuOpen(false);
        }
      });
    }
    return window.document.body.removeEventListener('click', () => setMenuOpen(false));
  });

  if (!document) {
    return null;
  }

  const details = props.page.getComponent('main', 'detailcontainer');
  const form =
    details && (details.getComponent('request-info') || details.getComponent('fp-request-form'));

  const displayHomeForm = details && details.getComponent('dh-request-form');

  const navigationClasses = classNames({
    'cvc-header-comp-navbar-navigation': true,
    'is-open': menuOpen,
  });

  const { backgroundImage, homeTextLink, logoImage, mobileLink, logoImages } =
    document.getData<HeaderData>();
  // if (homeTextLink && process.env.REACT_APP_SPA_BASE_URL) {
  //   homeTextLink.destinationUrl = process.env.REACT_APP_SPA_BASE_URL + homeTextLink.destinationUrl;
  // }

  if (HeaderVariant === PAGE_VARIANT.RETAILER) {
    return <RetailerHeader2 {...props} />;
  }

  let retailerData = null;
  let retailerImage: any = null;
  let retailerLogoImg = null;
  let phoneFormatted: string | undefined = '';
  let phoneLink = '';
  let retailerUrl = '';
  let logoImageUrl = logoImage.destinationUrl;

  if (HeaderVariant === PAGE_VARIANT.RETAILER) {
    retailerData = getRetailerData(props.page);

    const imageKey = getRetailerLogoImageKeyBy(
      retailerData!.parkModelRetailer,
      retailerData!.community
    );
    if (imageKey) {
      retailerImage = logoImages.imageKeyed.find((img) => img.key === imageKey);
      retailerLogoImg = retailerImage && props.page.getContent(retailerImage.imagelink);
    }

    phoneFormatted = formatPhoneNumber(retailerData?.phone);
    phoneLink = `tel:${retailerData?.phone}`;
    retailerUrl = retailerData!.retailerPath;
    logoImageUrl = logoImageUrl && logoImageUrl.replace('$retailer', retailerUrl);
  }

  if (bcDocument) {
    logoImageUrl =
      logoImageUrl && logoImageUrl.replace('$buildingcenter', bcDocument.buildingCenterPath);
  }

  logoImageUrl = logoImage && window.spaBaseUrl + logoImageUrl;

  const retailerHomeUrl = window.spaBaseUrl + retailerUrl;
  const bgImage: any = backgroundImage && props.page.getContent(backgroundImage.imagelink);
  const logoImg: any = logoImage && props.page.getContent(logoImage.image);
  const showMenuClose = menuOpen || (isCavcoHomesVariant && showRightSlide);
  const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;

  const currentSite = getCurrentSiteName();
  const tollFreeMsg = () => {
    if (currentSite === SITE_NAMES.PALM_HARBOR) {
      return (
        <div className="cvc-header-tollfree">
          Call A Model Center: <a href="tel:1-888-466-3719">1-888-466-3719</a>
        </div>
      );
    }
  };

  return (
    <>
      <div data-elastic-exclude className={classNames('cvc-header-wrapper', `${HeaderVariant}`)}>
        <div
          className={classNames(
            'cvc-header',
            `${HeaderVariant}`,
            !props.page.isPreview() && 'is-fixed'
          )}
        >
          {HeaderVariant === PAGE_VARIANT.PARK_MODEL ? (
            <div className="cvc-header-bg-wrapper">
              <div
                className={classNames(
                  'cvc-header-bg',
                  `${HeaderVariant.length === 0 || !bgImage ? 'cvc-header-bg--default-color' : ''}`
                )}
                style={
                  HeaderVariant.length > 0 && bgImage
                    ? { backgroundImage: `url(${getUrlFromImage(bgImage)})` }
                    : {}
                }
                aria-label={
                  HeaderVariant.length > 0 && backgroundImage
                    ? backgroundImage.alt
                    : 'header-background'
                }
              />
              <div className="cvc-header-bg-md" />
            </div>
          ) : (
            ''
          )}
          <header className="cvc-header-comp">
            {HeaderVariant === PAGE_VARIANT.PARK_MODEL
              ? homeTextLink && displayHomeTextLink(homeTextLink)
              : ''}
            <Container className="cvc-header-comp-container">
              {isStringOnOrTrue(componentParameterMap.isCommunity) && (
                <ResolverLink href="/" className="cvc-header-dropdown-to-root">
                  <HomeIcon />
                  <span>Cavco Communities</span>
                </ResolverLink>
              )}
              <div
                className={classNames(
                  componentParameterMap.showSearch === 'on' || showSignIn === 'on'
                    ? `cvc-header-comp-search-favorites-container${
                        HeaderVariant === PAGE_VARIANT.PARK_MODEL ? ' park' : ''
                      }`
                    : 'hide-search-fav'
                )}
              >
                {HeaderVariant === 'is-cavcohomes-retailer' ? null : (
                  <>
                    <div className="cvc-header-comp-search-icon-container">
                      {isDesktop && HeaderVariant !== PAGE_VARIANT.RETAILER ? (
                        <>
                          <DisplaySearchIcon
                            showSearch={componentParameterMap.showSearch}
                            searchUrl={componentParameterMap.searchUrl}
                            headerVariant={HeaderVariant}
                            windowDimensions={windowDimensions}
                            spinnerColorInLightBackground={spinnerColorInLightBackground}
                            spinnerColorInDarkBackground={spinnerColorInDarkBackground}
                            onSearch={() => setMenuOpen(false)}
                          />
                          {tollFreeMsg()}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )}
                <div className="cvc-header-comp-favorites-container">
                  {displaySignIn(showSignIn)}
                </div>
              </div>
              {checkHeaderComp && (
                <Dropdown
                  className={`cvc-header-dropdown ${
                    bcDocument?.displayName ? 'cvc-header-dropdown-bc' : ''
                  }`}
                >
                  <Dropdown.Toggle id="dropdown-basic" className="cvc-header-dropdown-toggle ">
                    <LocationIcon className="cvc-header-dropdown-toggle-locator" />
                    <LeftArrowIcon className="cvc-header-dropdown-toggle-retract" />
                    <span className="cvc-header-dropdown-toggle-label">Manufacturing</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="cvc-header-dropdown-content">
                    <p className="cvc-header-dropdown-content-intro">{headerInfo}</p>
                    <ul className="cvc-header-dropdown-content-list">{buildingCentersMapSort}</ul>
                    <ResolverLink href="/" className="cvc-header-dropdown-home">
                      <Dropdown.Toggle>
                        <HomeIcon className="cvc-header-dropdown-home-icon" />
                        <span className="cvc-header-dropdown-home-label">Home</span>
                      </Dropdown.Toggle>
                    </ResolverLink>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <div className="cvc-header-comp-navbar-container">
                <Navbar className="cvc-header-comp-navbar">
                  <button
                    type="button"
                    className="cvc-header-comp-navbar-toggler"
                    aria-controls="responsive-navbar-nav"
                    aria-label="navigation menu toggle"
                    onClick={() => {
                      return !menuOpen && !showMenuClose ? setMenuOpen(true) : handleCloseMenu();
                    }}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {HeaderVariant === PAGE_VARIANT.CAVCOHOMES ||
                    HeaderVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER ? (
                      !showMenuClose ? (
                        <div className="cvc-icon--nav-mobile" />
                      ) : (
                        <CloseIcon className="cvc-header-search-input-close-icon" />
                      )
                    ) : buildingCenters ? (
                      <HamburgerIcon className="cvc-header-comp-navbar-toggler-icon" />
                    ) : (
                      <NavBarTogglerIcon className="cvc-header-comp-navbar-toggler-icon" />
                    )}
                  </button>
                  <Navbar.Brand
                    as={ResolverLink}
                    className={`cvc-header-comp-navbar-brand navbar-brand ${
                      bcDocument?.displayName ? 'building-center-logo' : ''
                    }`}
                    href={
                      logoImage && logoImageUrl && logoImageUrl.length > 0
                        ? logoImageUrl
                        : window.location.origin
                    }
                  >
                    {bcDocument && HeaderVariant === PAGE_VARIANT.CAVCO_DOT_COM && (
                      <div className="cvc-header-comp-navbar-brand-image-container">
                        {displayBuildingCenterLogo('', bcDocument.displayName)}
                      </div>
                    )}
                    {HeaderVariant !== PAGE_VARIANT.RETAILER ? (
                      displayLogo(logoImg, logoImage.imageAltText, additionalLogoText)
                    ) : (
                      <div className="navbar-brand-flag">
                        {getImage('retailer-logo', retailerImage, retailerLogoImg)}
                      </div>
                    )}
                  </Navbar.Brand>
                  <div id="responsive-navbar-nav" className={navigationClasses}>
                    <div className="cvc-header-comp-navbar-sidebar-header">
                      <button
                        type="button"
                        className="cvc-header-comp-navbar-sidebar-closer"
                        aria-controls="responsive-navbar-nav"
                        aria-label="menu close button"
                        onClick={() => setMenuOpen(false)}
                      >
                        <LeftArrowIcon className="cvc-header-comp-navbar-close-icon" />
                      </button>
                      {HeaderVariant === PAGE_VARIANT.RETAILER ? (
                        <div className="cvc-header-comp-navbar-sidebar-retailer-info-container">
                          <div className="cvc-header-comp-navbar-sidebar-retailer-name">
                            {retailerData && retailerData.name}
                          </div>
                          <div className="cvc-header-comp-navbar-sidebar-retailer-city-state">
                            {retailerData && getRetailerStateAndCity(retailerData)}
                          </div>
                        </div>
                      ) : (
                        <DisplaySearchIcon
                          showSearch={componentParameterMap.showSearch}
                          searchUrl={componentParameterMap.searchUrl}
                          headerVariant={HeaderVariant}
                          windowDimensions={windowDimensions}
                          spinnerColorInLightBackground={spinnerColorInLightBackground}
                          spinnerColorInDarkBackground={spinnerColorInDarkBackground}
                          onSearch={() => setMenuOpen(false)}
                        />
                      )}
                    </div>
                    <div className="cvc-header-comp-navbar-sidebar-content">
                      {displayNavLinks(HeaderVariant, props.page, windowDimensions, history)}
                      {HeaderVariant !== PAGE_VARIANT.RETAILER && !isDesktop
                        ? mobileLink && !isCavcoHomesVariant && displayMobileLink(mobileLink)
                        : displayRetailerLogo('retailer-logo', retailerImage, retailerLogoImg)}
                    </div>
                  </div>

                  {showSignIn === 'on' ? (
                    <>
                      <Navbar.Collapse className="justify-content-end">
                        <IconDisplayMobileTablet />
                      </Navbar.Collapse>
                      <div className={accountMenuClasses}>
                        <UserAccountRightSlide />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </Navbar>
                {HeaderVariant === PAGE_VARIANT.RETAILER ? (
                  <>
                    <div className="cvc-header-comp-navbar-retailer-details-container">
                      <div className="retailer-details-name-favorites">
                        <ResolverLink className="retailer-details-name" href={logoImageUrl ?? '/'}>
                          {retailerData?.name}
                        </ResolverLink>
                        {showFavorites && (
                          <RetailerFavoriteIcon
                            key={`retailer-header-favorite${retailerData?.locationID}`}
                            // label={
                            //   lbl && lbl.headerFavoriteText
                            //     ? lbl.headerFavoriteText
                            //     : 'ADD TO MY FAVORITE'
                            // }
                            // labelAdded={
                            //   lbl && lbl.headerFavoriteTextAdded
                            //     ? lbl.headerFavoriteTextAdded
                            //     : 'ADDED TO MY FAVORITE'
                            // }
                            id={retailerData?.locationID}
                            // isFavorite={retailerData?.isFavorite}
                            baseUrl={myAccountConfig.favoriteBaseUrl}
                            loadFavoriteUrl={myAccountConfig.loadFavoriteUrl}
                            myAccountConfig={myAccountConfig}
                          />
                        )}
                      </div>
                      <div className="retailer-details-address-phone-links">
                        <div className="retailer-details-address">
                          <LocationIcon className="retailer-details-address-icon" />
                          {displayFullAddress(retailerData)}
                        </div>
                        <div className="retailer-details-phone">
                          <PhoneIcon className="retailer-details-phone-icon" />
                          {'  '}
                          <a href={phoneLink} className="retailer-details-phone-link">
                            {phoneFormatted}
                          </a>
                        </div>
                        <div className="retailer-details-links">
                          <button
                            type="button"
                            className="retailer-details-menu-link cvc-text-link-alt"
                            onClick={() => scrollToElementById('location-details', retailerHomeUrl)}
                          >
                            Hours
                          </button>
                          {' | '}
                          <button
                            type="button"
                            className="retailer-details-menu-link cvc-text-link-alt"
                            onClick={() =>
                              scrollToElementById('location-details-map', retailerHomeUrl)
                            }
                          >
                            Map
                          </button>
                          {' | '}
                          <button
                            type="button"
                            className="retailer-details-menu-link cvc-text-link-alt"
                            onClick={openModal}
                          >
                            Request Info
                          </button>
                        </div>
                      </div>
                    </div>
                    <Modal
                      className="cvc-request-info-modal-retailer"
                      show={requestInfoModal}
                      onHide={closeModal}
                    >
                      <Modal.Header closeButton />
                      <Modal.Body id="id-modal-body" className="px-0">
                        {form && (
                          <FloorPlanRequestInfoWithParams
                            page={props.page}
                            component={form}
                            displayInsideModal
                            formId="retailer-home-header-link-modal-form"
                          />
                        )}
                        {displayHomeForm && (
                          <DisplayHomeRequestInfoWithParams
                            page={props.page}
                            component={displayHomeForm}
                            displayInsideModal
                            formId="retailer-home-header-link-modal-form"
                          />
                        )}
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  ''
                )}
              </div>
              {HeaderVariant === PAGE_VARIANT.RETAILER && !isDesktop ? (
                <>
                  <div className="cvc-header-comp-retailer-name-address-container">
                    <div className="cvc-header-comp-retailer-name-address-container-inner">
                      <span className="retailer-name">{retailerData?.name}</span>
                      <span className="retailer-address">
                        {retailerData && getRetailerStateAndCity(retailerData)}
                      </span>
                    </div>
                  </div>
                  <div className="cvc-header-comp-retailer-icons-container">
                    <button
                      type="button"
                      aria-label="location details"
                      className="retailer-details-menu-link mobile-location"
                      onClick={() => scrollToElementById('location-details-map', retailerUrl)}
                    >
                      <LocationIcon />
                    </button>
                    <a href={phoneLink} className="retailer-details-menu-link mobile-phone">
                      <PhoneIcon />
                    </a>
                    {showFavorites && (
                      <RetailerFavoriteIcon
                        key={`retailer-header-favorite${retailerData?.locationID}`}
                        // label={
                        //   lbl && lbl.headerFavoriteText
                        //     ? lbl.headerFavoriteText
                        //     : 'ADD TO MY FAVORITE'
                        // }
                        // labelAdded={
                        //   lbl && lbl.headerFavoriteTextAdded
                        //     ? lbl.headerFavoriteTextAdded
                        //     : 'ADDED TO MY FAVORITE'
                        // }
                        id={retailerData?.locationID}
                        // isFavorite={retailerData?.isFavorite}
                        baseUrl={myAccountConfig.favoriteBaseUrl}
                        loadFavoriteUrl={myAccountConfig.loadFavoriteUrl}
                        myAccountConfig={myAccountConfig}
                      />
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
              {loginModal && (
                <SignInModals
                  isMobile={windowDimensions.isMobile}
                  isTablet={windowDimensions.isTablet}
                  myAccountConfig={myAccountConfig}
                  loginModalClosed={closeLoginModal}
                  setSuccessfulSignIn={handleSetSuccessfulSignIn}
                />
              )}
              {/* { loginModal &&  <SignInModals loginModalClosed={closeLoginModal}  />   } */}
            </Container>
          </header>
        </div>
      </div>
      {HeaderVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER && <CavcoRetailerHeader {...props} />}
    </>
  );
}
