import { BrProps } from '@bloomreach/react-sdk';
import React from 'react';
import './BuildingCenterMarketingText.scss';

export function BuildingCenterMarketingText(props: BrProps) {
  const { documents } = props.component.getModels();
  const document = documents[0] ? props.page.getContent(documents[0]) : undefined;
  if (!document) {
    return null;
  }
  const { seriesMarketingText } = document.getData<DocumentData>();
  return (
    <div className="cvc-bc-series-marketing-text">
      {seriesMarketingText && (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(seriesMarketingText.value) }}
        />
      )}
    </div>
  );
}
