import { BrProps } from '@bloomreach/react-sdk';
import React, { useEffect, useState } from 'react';
import './CavcoSearchComponent.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { FormControl } from '@material-ui/core';
import { Pagination } from 'react-bootstrap';
import { CavcoChips } from 'components/FormComponents/CavcoChips';
import {
  ElasticOptions,
  ElasticSearchResponse,
  ElasticSearchResult,
  esSearchWithFacet,
  getElasticSearchClient,
} from '../elasticSeachUtils';
import { CavcoMultiSelectField } from '../FormComponents/CavcoMultiSelectField';
import { SiteSearchCard } from './SiteSearchCard';
import { SearchTextField } from '../FormComponents/SearchTextField';

export function CavcoSearchComponent(props: BrProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredResultsCount, setfilteredResultsCount] = useState<any>('');
  const useQuery = () => new URLSearchParams(useLocation().search);
  const q = useQuery().get('query');
  const history = useHistory();
  const [esResult, setEsResult] = useState<any>(null);
  const [facet, setFacet] = useState<any>();
  const [page, setPage] = useState(1);
  const [esRequest, setEsRequest] = useState<ElasticOptions>({
    query: '',
    facets: {
      type: [
        {
          type: 'value',
        },
      ],
    },
    sort: [
      {
        sort_group: 'asc',
      },
    ],
  });
  const [elasticOptions, setElasticOptions] = useState<any[]>([]);
  const [elasticOptionsTags, setElasticOptionsTags] = useState<any[]>([]);
  const { resultperpage, includeImages, esconfiguration, siteTypes } = props.component.getModels();

  const [searchText, setSearchText] = useState('');
  const esConfig: any = props?.page?.getContent(esconfiguration)?.getData();
  const siteTypesData: any = props?.page?.getContent(siteTypes)?.getData();
  const esConfigMap: any = { apiKey: '', engineName: '', endpointBase: '' };
  const siteTypesMap: any = {};

  const fillConfigData = (dest: any, source: any) => {
    source.keys.forEach((element: any, index: number) => {
      dest[element] = source.messages[index];
    });
  };

  fillConfigData(siteTypesMap, siteTypesData);
  fillConfigData(esConfigMap, esConfig);

  const client = getElasticSearchClient(esConfigMap);

  const getSearchData = async (options: ElasticOptions): Promise<ElasticSearchResponse> => {
    const search = await esSearchWithFacet(client, options, 'type');
    return search;
  };

  const newSearch = () => {
    const newLoc = window.location.pathname;
    history.push(`${newLoc}?query=${searchText}`);
  };

  useEffect(() => {
    const buildData = () => {
      setSearchText(q || '');
      if (q && esRequest) {
        esRequest.query = q;
        const requestPage = {
          size: +resultperpage,
        };
        esRequest.page = requestPage;
        setEsRequest(esRequest);
        getSearchData(esRequest).then((data: ElasticSearchResponse) => {
          setEsResult(data);
          setFacet(data.facets);
          setfilteredResultsCount(data.pageInfo?.total_results);
        });
      }
    };
    buildData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  const updateTags = (tags: any) => {
    const newTags = tags.filter((tag: any) => tag !== 'see-results');
    setElasticOptionsTags(newTags);
  };

  const updateData = (filters: any) => {
    const typeFilters = {
      type: filters,
    };
    esRequest.filters = {
      all: [typeFilters],
    };
    esRequest.page = {
      ...esRequest.page,
      current: 1,
    };
    updateTags(filters);
    setEsRequest(esRequest);
    if (filters.length === 0 || (filters.length === 1 && filters[0] === 'see-results')) {
      delete esRequest.filters;
    }
    getSearchData(esRequest).then((data: ElasticSearchResponse) => {
      setEsResult(data);
      setfilteredResultsCount(data.pageInfo?.total_results);
    });
  };

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    if ((Array.isArray(value) && value.includes('see-results')) || value === 'see-results') {
      setIsOpen(false);
      updateData(value);
    } else {
      setElasticOptions(value);
      if (value.length === 0) {
        delete esRequest.filters;
      } else {
        const typeFilters = {
          type: value,
        };
        esRequest.filters = {
          all: [typeFilters],
        };
      }
      setEsRequest(esRequest);
      getSearchData(esRequest).then((data: ElasticSearchResponse) => {
        setfilteredResultsCount(`${data.pageInfo?.total_results}`);
      });
    }
  };

  const handleSelectClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!event?.target?.value?.includes('see-results')) {
      if (!event.target.value) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(true);
      }
    }
  };

  const SelectCheckboxFilter = () => {
    return (
      <FormControl
        fullWidth
        onClick={(e) => {
          handleSelectClick(e);
        }}
      >
        <CavcoMultiSelectField
          label="all"
          handleSelectChange={handleSelectChange}
          isOpen={isOpen}
          data={facet}
          values={elasticOptions}
          totalResults={filteredResultsCount}
          selectLabels={siteTypesMap}
        />
      </FormControl>
    );
  };

  const updateSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    if (newPage > 0 && newPage <= esResult?.pageInfo.total_pages) {
      setPage(newPage);
      esRequest.page = {
        ...esRequest.page,
        current: newPage,
      };
      setEsRequest(esRequest);
      getSearchData(esRequest).then((data: ElasticSearchResponse) => {
        setEsResult(data);
      });
      window.scrollTo(0, 0);
    }
  };

  const removeChipTags = (value: string) => {
    const newChipsTags = elasticOptions.filter((item: any) => item !== value);
    setElasticOptions(newChipsTags);
    setElasticOptionsTags(newChipsTags);
    updateData(newChipsTags);
  };

  const removeAllChipsTags = () => {
    setElasticOptions([]);
    setElasticOptionsTags([]);
    updateData([]);
  };

  return (
    <div className="cavco-search-container">
      <div className="search-filter-container">
        <div className="search-input-wrapper">
          <SearchTextField onchange={updateSearch} onEnter={newSearch} value={searchText} />
        </div>
        <div className="result-filter-container">
          <SelectCheckboxFilter />
        </div>
        {elasticOptionsTags.length > 0 && (
          <div className="remove-tags">
            <button type="button" onClick={removeAllChipsTags}>
              <u>Clear all filters</u>
            </button>
          </div>
        )}
        <div className="result-chips-tags-container">
          {elasticOptionsTags.map((item: any, index: number) => {
            const key = `${item}-${index}`;
            return (
              <CavcoChips
                label={siteTypesMap[item] ? siteTypesMap[item] : item}
                key={key}
                value={item}
                handleCloseEvent={removeChipTags}
              />
            );
          })}
        </div>
        <div className="search-result-wrapper">
          <h2>{`Search results for ‘${q}’`}</h2>
        </div>
        <div className="search-result-total-wrapper">
          <span>
            <b>{esResult?.pageInfo.total_results}</b> Results
          </span>
        </div>
        <div className="search-result-desktop-wrapper">
          <h2>{`Search results for ‘${q}’`}</h2>
        </div>
        <div className="search-result-total-desktop-wrapper">
          <span>
            <b>{esResult?.pageInfo.total_results}</b> Results
          </span>
        </div>
      </div>
      <div className="search-result-container">
        <div className="site-search-result-container">
          {esResult?.results?.map((item: ElasticSearchResult, index: number) => {
            const key = `item-${index}`;
            return (
              <SiteSearchCard
                key={key}
                data={item}
                includeImages={includeImages}
                titleLabels={siteTypesMap}
              />
            );
          })}
        </div>
        <div className="site-search-pagination-containers">
          {esResult?.pageInfo.total_pages > 0 && (
            <Pagination>
              <Pagination.Prev onClick={(e: any) => handleChangePage(e, page - 1)} />
              <>
                {[...Array(esResult?.pageInfo.total_pages)].map((pageItem: any, index: any) => {
                  return (
                    index + 1 <= page + 3 &&
                    index + 1 >= page - 3 && (
                      <Pagination.Item
                        active={page === index + 1}
                        key={`page-${index + 1}`}
                        onClick={(e: any) => handleChangePage(e, index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  );
                })}
              </>
              <Pagination.Next onClick={(e: any) => handleChangePage(e, page + 1)} />
            </Pagination>
          )}
        </div>
      </div>
    </div>
  );
}
