import React, { useState } from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import './Content.scss';
import { getYoutubeId, getUrlFromImage } from 'components/utils';
import YouTube from 'react-youtube';
// import useWindowDimensions from 'components/hooks/useWindowDimensions';

export function Content(props: BrProps) {
  const { document: documentRef, componentParameterMap } = props.component.getModels();
  const document = documentRef ? props.page.getContent(documentRef) : undefined;
  // const windowDimensions = useWindowDimensions();
  const [clicked, setClicked] = useState(false);
  if (!document) {
    return null;
  }
  const {
    introduction,
    content,
    subtitle,
    content1,
    content2,
    image: imageRef,
    title,
    date,
    blocks,
    contentType,
  } = document.getData<DocumentData>();

  const image = imageRef && props.page.getContent(imageRef);
  let theContent: any[] = [];
  if (content1 && content2) {
    theContent = [content1, content2];
  } else if (content1 && !content2) {
    theContent = [content1];
  } else if (!content1 && content2) {
    theContent = [content2];
  } else if (content) {
    theContent = [content];
  }
  if (contentType === 'cavco:RichContent') {
    /*
    let videoHeight = '';
    if (windowDimensions.isMobile) {
      videoHeight = '230px';
    } else if (windowDimensions.isTablet) {
      videoHeight = '430px';
    } else if (windowDimensions.isDesktop) {
      videoHeight = '550px';
    } else {
      videoHeight = '650px';
    }
    */
    const {
      background: bgColor,
      columns: colNumber,
      blockBackground: blockBgColor,
      verticalAlign,
      paddingTopBottom,
    } = componentParameterMap;
    return (
      <div className={`cvh-rich-content bg-${bgColor} padding-${paddingTopBottom}`}>
        {blocks?.map((block: any, idx: number) => {
          const { value, image: blockImage, videoURL } = block;
          const blockImageRef = blockImage && props.page.getContent(blockImage);
          const videoId = videoURL ? getYoutubeId(block) : null;
          block.size = 'maxres';
          return (
            <div
              key={`item_${idx}`}
              className={`cvh-rich-content-item rich-col-${colNumber} block-bg-${blockBgColor}`}
            >
              {!!blockImageRef && (
                <div className={`cvh-rich-content__img align-${verticalAlign}`}>
                  <a href={block.destinationUrl}>
                    <img src={getUrlFromImage(blockImageRef)} alt={block.imageAltText} />
                  </a>
                </div>
              )}
              {!!videoId && (
                <div className={`cvh-rich-content__video align-${verticalAlign}`}>
                  <div>
                    <div
                      className={`cvh-rich-content__video--overlay ${clicked ? 'is-clicked' : ''}`}
                    />
                    <YouTube
                      onPlay={() => setClicked(true)}
                      id={block.id}
                      videoId={videoId}
                      containerClassName="cvh-rich-content__video-iframe-container"
                      opts={{
                        width: '100%',
                        height: '100%',
                        playerVars: {
                          controls: 1,
                          start: 0,
                          disablekb: 1,
                          enablejsapi: 1,
                          iv_load_policy: 3,
                          modestbranding: 1,
                          rel: 0,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {!!value && (
                <div className={`cvh-rich-content__richText align-${verticalAlign}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.page.rewriteLinks(value),
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className={`cvc-simple-content${props.page.isPreview() ? ' has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      <div className="cvc-simple-content__inner">
        {image && (
          <img
            className="cvc-simple-content__img img-fluid mb-3"
            src={getUrlFromImage(image)}
            alt={title}
          />
        )}
        {title && <h1 className="cvc-simple-content__title">{title}</h1>}
        {subtitle && <h2 className="cvc-simple-content__subtitle mb-3">{subtitle}</h2>}
        {introduction && <p className="cvc-simple-content__intro">{introduction}</p>}
        {date && (
          <p className="cvc-simple-content__date mb-3 small">{new Date(date).toDateString()}</p>
        )}

        {theContent && theContent.length > 0 && (
          <div className="cvc-simple-content__content">
            {theContent?.map((contentChild: any, idx) => (
              <div
                key={`item_${idx}`}
                dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(contentChild.value) }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
