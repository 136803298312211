import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoRetailerLocationDetails.scss';
import { formatPhoneNumber } from '../utils';

export function CavcoRetailerLocationDetails(props: BrProps) {
  const { documents, retailerLocationDetailsLabels, googleMapConfig } = props.component.getModels();
  const document = documents && documents[0] && props.page.getContent(documents[0]);

  if (!document) {
    return null;
  }

  const {
    hoursOpMonday,
    hoursOpTuesday,
    hoursOpWednesday,
    hoursOpThursday,
    hoursOpFriday,
    hoursOpSaturday,
    hoursOpSunday,
    name,
    address1,
    address2,
    city,
    state,
    zip,
    phone,
  } = document.getData();
  const phoneFormatted = formatPhoneNumber(phone);
  const phoneLink = `tel:${phone}`;
  const address1Plus = address1.replace(/\s/g, '+');
  const address2Plus = address2 ? `+${address2.replace(/\s/g, '+')}` : '';
  const cityPlus = city.replace(/\s/g, '+');
  const statePlus = state.replace(/\s/g, '+');
  const addressQuery = `${address1Plus}${address2Plus},${cityPlus}+${statePlus}+${zip}`;

  let titleKey = 'Location Details';
  let monKey = 'Mon';
  let tueKey = 'Tue';
  let wedKey = 'Wed';
  let thuKey = 'Thu';
  let friKey = 'Fri';
  let satKey = 'Sat';
  let sunKey = 'Sun';

  if (retailerLocationDetailsLabels) {
    titleKey = retailerLocationDetailsLabels.title;
    monKey = retailerLocationDetailsLabels.mon;
    tueKey = retailerLocationDetailsLabels.tue;
    wedKey = retailerLocationDetailsLabels.wed;
    thuKey = retailerLocationDetailsLabels.thu;
    friKey = retailerLocationDetailsLabels.fri;
    satKey = retailerLocationDetailsLabels.sat;
    sunKey = retailerLocationDetailsLabels.sun;
  }

  return (
    <>
      <div className="cavcohomes-retailer-details-content">
        <Row className="cavcohomes-retailer-details-content__location">
          <Col className="cavcohomes-retailer-details-content__location__info" xs={12} md={4}>
            <h3 className="cavcohomes-container-location-details" id="location-details">
              {titleKey}
            </h3>

            <div className="cavcohomes-container-location-details">
              <div className="cavcohomes-container-content">
                <address>
                  <div id="name">{name}</div>
                  <div>{address1}</div>
                  <div>{address2}</div>
                  <div>
                    {city}, {state} {zip}
                  </div>
                </address>
              </div>
            </div>

            <div className="cavcohomes-container-location-details">
              <div className="cavcohomes-container-content phone-content">
                <a href={phoneLink}>{phoneFormatted}</a>
              </div>
            </div>

            <div className="cavcohomes-container-location-details">
              <div className="cavcohomes-container-content cavcohomes-container-hours-content">
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{monKey}</span>
                  <span>{hoursOpMonday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{tueKey}</span>
                  <span>{hoursOpTuesday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{wedKey}</span>
                  <span>{hoursOpWednesday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{thuKey}</span>
                  <span>{hoursOpThursday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{friKey}</span>
                  <span>{hoursOpFriday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{satKey}</span>
                  <span>{hoursOpSaturday}</span>
                </div>
                <div className="cavcohomes-container-hours-content-unit">
                  <span className="cavcohomes-container-hours-content-day">{sunKey}</span>
                  <span>{hoursOpSunday}</span>
                </div>
              </div>
            </div>
          </Col>

          <Col className="cavcohomes-retailer-details-content__location__map" xs={12} md={8}>
            <div className="cavcohomes-container-location-details">
              <div className="cavcohomes-map-container" id="location-details-map">
                <iframe
                  title={name}
                  width="100%"
                  height="100%"
                  frameBorder="1px"
                  style={{ border: `1px solid #CAD8DE`, padding: `1px` }}
                  src={`https://www.google.com/maps/embed/v1/place?key=${googleMapConfig.apiKey}
                &q=${addressQuery}`}
                  allowFullScreen
                  aria-hidden="true"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
