import React, { useEffect, useState } from 'react';

import AutoCompleteComp from 'components/Autocomplete/Autocomplete';
import useGeocodeSearchDataHook from 'components/hooks/GeocodeSearchData.hook';
import { useStateWithLocalStorage } from 'components/hooks/useStateWithLocalStorage';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { checkForNessus } from 'components/utils';

export const SearchAgainComp = ({
  apiKey,
  locationNameSearched,
  internalLoc,
  searchButtonText = 'Search Again',
  handleSearch,
}: any) => {
  const { setGeocodeRequest, geocodeResponse } = useGeocodeSearchDataHook();
  const [, setCurrentFloorPlanSearch] = useStateWithLocalStorage('currentFloorPlanSearch');
  const [tempFloorPlanSearch, setTempFloorPlanSearch] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });

  useEffect(() => {
    if (geocodeResponse && !geocodeResponse.isError) {
      handleSearch(geocodeResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodeResponse]);

  const suggestionSelected = (data: any) => {
    geocodeByAddress(data.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const updatedData = {
          ...data,
          lat,
          lng,
        };
        setTempFloorPlanSearch(updatedData);
      });
  };

  const searchAgain = async () => {
    if (
      tempFloorPlanSearch.description !== '' &&
      tempFloorPlanSearch.lat &&
      tempFloorPlanSearch.lng &&
      tempFloorPlanSearch.terms
    ) {
      await setCurrentFloorPlanSearch({
        latitude: tempFloorPlanSearch.lat,
        longitude: tempFloorPlanSearch.lng,
        dataTerms: tempFloorPlanSearch.terms,
      });
      await setGeocodeRequest(tempFloorPlanSearch.description);
    }
  };

  useEffect(() => {
    const searchInputBox: any = document.querySelector('.search-filter-autocomplete-input')!;
    if (searchInputBox) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
          searchAgain();
        }
      };

      searchInputBox.addEventListener('keydown', handleKeyDown);
      return () => searchInputBox.removeEventListener('keydown', handleKeyDown);
    }
  });

  return (
    <>
      <AutoCompleteComp
        apiKey={checkForNessus(apiKey)}
        placeholder="Enter City/State or Province"
        onClick={suggestionSelected}
        inputClassName="search-filter-autocomplete-input"
        initialValue={locationNameSearched}
        geocodeEndpoint={internalLoc}
      />
      <button
        type="button"
        className="btn btn-primary d-inline-block"
        onClick={searchAgain}
        tabIndex={0}
      >
        {searchButtonText}
      </button>
    </>
  );
};
