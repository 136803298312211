import classNames from 'classnames';
import { AddIcon, RemoveIcon } from 'components/shared';
import React, { useState, FunctionComponent } from 'react';
import { Collapse, Form } from 'react-bootstrap';
import { FloorPlanSort } from '../FloorPlanSort/FloorPlanSort';

export const DynamicFiltersPanel = (
  isDisplayInMobile = false,
  facetState: { facets: { primary: any[] } },
  selected: any,
  windowDimensions: {
    width: number | null;
    height: number | null;
    isMobile: boolean | null;
    isTablet: boolean | null;
    isDesktop: boolean | null;
    isDesktopBig: boolean | null;
    isDesktopExtraBig: boolean | null;
  },
  allPageSort: any[],
  handleSort: { (data: any): void; (data: any): void },
  totalFloorPlanCount: any,
  handleSelectedFacetChange: {
    (updatedFacets: any): void;
    (updatedFacets: any): void;
  },
  selectedSortType: any,
  accordionFiltersMobileOpen: boolean
) => {
  const filters: any[] = facetState?.facets?.primary;

  const showFacetCount = false;

  if (!filters) {
    return;
  }

  const openFilters: string[] = [];
  if (totalFloorPlanCount && totalFloorPlanCount > 0) {
    const selectedFilterKeys = Object.keys(selected);
    filters.forEach((filter) => {
      if (selectedFilterKeys.includes(filter.key)) {
        openFilters.push(`${filter.order}`);
      }
    });
  }

  const CavcoFormCheck: FunctionComponent<any> = (formCheckProps) => {
    const { id, isDisabled, inputElementType, labelFull, value, name, handleFilterSelection } =
      formCheckProps;
    const labelFullUpdated = labelFull.replace('3d ', '3D ').replace('Video Tour', 'Video Tours');

    return (
      <Form.Check
        key={id}
        id={id}
        disabled={isDisabled}
        type={inputElementType}
        name={name}
        label={labelFullUpdated}
        value={value}
        onChange={() => handleFilterSelection(value)}
      />
    );
  };

  const AccordionFilter: FunctionComponent<any> = (accordionFilterProps) => {
    const { filter, indexFilter, handleFilterSelection } = accordionFilterProps;
    // const indexDefaultFilterOpen = totalFloorPlanCount && totalFloorPlanCount > 0 ? '0' : '';
    const [indexesAccordionGroupOpened, setIndexesAccordionGroupOpened] = useState(openFilters);
    const [isAccordionItemOpen, setIsAccordionItemOpen] = useState(false);

    const contentId = `${filter.floorPlanField}-${indexFilter.toString()}-content`;

    const handleAccordionGroupToglerClick = (index: string) => {
      setIsAccordionItemOpen(!isAccordionItemOpen);
      if (indexesAccordionGroupOpened.some((idx: string) => idx === index)) {
        const indexAccordionGroup = indexesAccordionGroupOpened.findIndex(
          (idx: string) => idx !== index
        );
        const newIndexAccordionGroup = indexesAccordionGroupOpened.slice(0, indexAccordionGroup);
        setIndexesAccordionGroupOpened(newIndexAccordionGroup);
      } else {
        setIndexesAccordionGroupOpened((prev: any) => [...prev, index]);
      }
    };
    const handlekeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        setIsAccordionItemOpen(!isAccordionItemOpen);
      }
    };

    const updateFilterSelection = (value: any) => {
      const updated = selected;

      if (selected.hasOwnProperty(filter.key) && filter.type !== 'radio') {
        const index = updated[filter.key].indexOf(value);
        if (index > -1) {
          updated[filter.key].splice(index, 1);
          if (updated[filter.key].length === 0) {
            delete updated[filter.key];
          }
        } else {
          updated[filter.key].push(value);
        }
      } else {
        updated[filter.key] = [value];
      }
      handleFilterSelection(updated, true);
    };

    let has3dFilter = false;

    return (
      <div className="accordion-filter-container">
        <div
          id={`accordion-group-header-${filter.floorPlanField}-${indexFilter.toString()}`}
          className={`accordion-group-toggler ${filter.floorPlanField}`}
          role="button"
          onClick={() => handleAccordionGroupToglerClick(indexFilter.toString())}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handlekeyDown(e)}
          aria-controls={contentId}
          tabIndex={0}
        >
          {filter.panel}
          {indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString()) ? (
            <RemoveIcon className="card-header-icon" />
          ) : (
            <AddIcon className="card-header-icon" />
          )}
        </div>
        <Collapse
          in={indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())}
        >
          <div
            id={contentId}
            className={classNames(
              'card-body-container',
              indexesAccordionGroupOpened.some((index: any) => index === indexFilter.toString())
                ? 'is-open'
                : ''
            )}
          >
            {filter.options.map((option: any) => {
              const inputElementType = filter.type;

              let labelName = option.value ? option.value : option.name;

              let value = option.value ? option.value : option.to;

              // Combine Pana Tour and 3d Tour into one filter option
              if (labelName === 'Pana Tour' || labelName === '3d Tours') {
                if (has3dFilter) {
                  return null;
                }
                labelName = '3d Tours';
                value = labelName;
                has3dFilter = true;
              }

              const suffix = filter.suffix ? ` ${filter.suffix}` : '';

              const label = `${labelName}${suffix}`;
              const labelFull = `${label} (${option.count})`;
              const displayLabel = showFacetCount ? labelFull : label;

              const isDisabled = showFacetCount ? option.count === 0 : false;

              const id = `formCheck-${filter.key}-${value}`;
              const name =
                inputElementType !== 'checkbox' ? `filter-${filter.floorPlanField}-radios` : '';

              return (
                <CavcoFormCheck
                  key={`card-body-${labelName}`}
                  isDisabled={isDisabled}
                  id={id}
                  name={name}
                  inputElementType={inputElementType}
                  floorPlanField={filter.floorPlanField}
                  label={label}
                  labelFull={displayLabel}
                  value={value}
                  allowsMultipleSelection={filter.allowsMultipleSelection}
                  valueType={filter.valueType}
                  handleFilterSelection={updateFilterSelection}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    );
  };

  return isDisplayInMobile ? (
    <div className="product-list-accordion-mobile-wrapper">
      <div
        className={classNames(
          `product-list-accordion ${accordionFiltersMobileOpen ? 'show-in-mobile' : ''}`
        )}
      >
        {filters.map((filter: any, indexFilter: number) => (
          <AccordionFilter
            key={`accordion-filter-${indexFilter.toString()}`}
            filter={filter}
            indexFilter={indexFilter}
            handleFilterSelection={handleSelectedFacetChange}
          />
        ))}
      </div>
      <div
        aria-label="product-list-accordion-mobile-link"
        role="button"
        className={classNames(
          `product-list-accordion-mobile-link ${accordionFiltersMobileOpen ? 'show-in-mobile' : ''}`
        )}
        tabIndex={0}
        // onClick={() => setAccordionFiltersMobileOpen(!accordionFiltersMobileOpen)}
        // onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleDarkBackgroundKeyDown(e)}
      />
    </div>
  ) : (
    <div className="product-list-accordion">
      <FloorPlanSort
        config={allPageSort.sort}
        sortBy={selectedSortType}
        changeSort={handleSort}
        windowDimensions={windowDimensions}
        count={totalFloorPlanCount}
      />
      {filters.map((filter: any, indexFilter: number) => (
        <AccordionFilter
          key={`accordion-filter-${indexFilter.toString()}`}
          filter={filter}
          indexFilter={indexFilter}
          handleFilterSelection={handleSelectedFacetChange}
        />
      ))}
    </div>
  );
};
