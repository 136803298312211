import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { getRetailerData } from '../utils';
import 'components/shared/ProductDetails/ProductPresentedBy.scss';

function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

export function DisplayHomePresentedBy(props: BrProps) {
  const retailerData: RetailerData | null = getRetailerData(props.page);

  if (!retailerData) {
    return null;
  }

  const { dba, address1, address2, city, state, zip, phone } = retailerData;
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <div className="cvc-product-presented-by-content">
      <h2>Presented By</h2>
      <div className="retailer-info">
        <a href="../">{dba}</a>
        <span>{address1}</span>
        <span>{address2}</span>
        <span>
          {city}, {state} {zip}
        </span>
        <span>{formattedPhone}</span>
      </div>
    </div>
  );
}
