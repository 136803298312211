import React, { FunctionComponent } from 'react';
import { BrPageContext, BrComponent } from '@bloomreach/react-sdk';
// import { Modal, Button } from 'react-bootstrap';
import './PageNotFound.scss';
import { Helmet } from 'react-helmet-async';
import { getCurrentSiteName } from 'components/utils';
import { SITE_NAMES } from 'components/enums';
import { PageNotFoundIllustration } from 'components/shared/Icons';
// import useWindowDimensions from '../../hooks/useWindowDimensions';
// import { FloorPlanRequestInfoWithParams } from '../../FloorPlanRequestInfo/FloorPlanRequestInfo';

const PageNotFound: FunctionComponent = () => {
  const page = React.useContext(BrPageContext)!;
  const currentSite = getCurrentSiteName();
  const isCavcoHomes = currentSite === SITE_NAMES.CAVCO_HOMES;
  const pageUrl = window.location.href;
  // const windowDimensions = useWindowDimensions();

  // const [requestInfoModal, setModal] = useState(false);

  /*
  const closeModal = () => {
    setModal(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  
  const openModal = () => {
    setModal(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };
  */

  // const details = page.getComponent('main', 'detailcontainer');
  // const form = details && details.getComponent('request-info');

  const displayBrComponentsByContainer = (containerName: string) => {
    const main =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'main');
    const PageNotFoundDetailContainer =
      main && main.getChildren().find((comp: any) => comp.getName() === 'detailcontainer');

    const components =
      PageNotFoundDetailContainer &&
      PageNotFoundDetailContainer.getChildren().filter(
        (comp: any) => comp.getModels().componentParameterMap.container === containerName
      );

    if (!components) {
      return null;
    }

    return components.map((item: any) => {
      return (
        <div className="d-block" key={`div-${item.model.name}`}>
          <BrComponent key={item.model.name} path={item.model.name} />
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <title>Page Not Found - {pageUrl}</title>
      </Helmet>
      <BrComponent path="header-content" />
      <BrComponent path="parkmodel-header-content" />
      <BrComponent path="retailer-header-content" />
      <main
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-main" id="main">
          <BrComponent path="hero" />
          <BrComponent path="main">
            <BrComponent path="detailcontainer">
              <div
                className={`cvc-pagenotfound-detail-container ${
                  isCavcoHomes ? 'is-cavcohomes' : ''
                }`}
              >
                <div className="cvc-pagenotfound-top-container">
                  {displayBrComponentsByContainer('top-container')}
                  <PageNotFoundIllustration title="404 Page Not Found illustration | family moving houses" />
                  {/*
                  {windowDimensions.isMobile && (
                    <div className="cvc-location-details-request-info">
                      <Button variant="primary" onClick={openModal}>
                        Request Info
                      </Button>
                      <Modal
                        className="cvc-request-info-modal-retailer"
                        show={requestInfoModal}
                        onHide={closeModal}
                        backdrop={
                          windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true
                        }
                        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
                        centered
                      >
                        <Modal.Header closeButton />
                        <Modal.Body className="px-0">
                          {form && (
                            <FloorPlanRequestInfoWithParams
                              page={page}
                              component={form}
                              formId="page-not-found-form"
                            />
                          )}
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                */}
                </div>
                {/*
                <div className="cvc-request-info-form-container">
                  {displayBrComponentsByContainer('form-container')}
                </div>
                */}
              </div>
            </BrComponent>
          </BrComponent>
        </section>
      </main>
      <BrComponent path="footer-content" />
      <BrComponent path="parkmodel-footer-content" />
      <BrComponent path="retailer-footer-content" />
    </div>
  );
};

export default PageNotFound;
