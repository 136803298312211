import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { getPageContentData } from 'components/utils';
import CavcoRetailerEventComp, { CavcoRetailerEventProps } from './CavcoRetailerEvent';

export const CavcoRetailerEvent: React.FC<BrProps> = ({ component, page }) => {
  const { documents = [], retailerEventLabels } = component.getModels();
  const retailerData = getPageContentData<any>(documents[0], page);
  const eventData =
    retailerData && getPageContentData<CavcoRetailerEventProps>(retailerData?.event, page);
  if (!eventData) {
    return null;
  }

  return <CavcoRetailerEventComp {...eventData} retailerEventLabels={retailerEventLabels} />;
};
