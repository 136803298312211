import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './RetailerEvent.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';

export function RetailerEvent(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  const { documents, retailerEventLabels } = props.component.getModels();
  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;

  if (!document) {
    return null;
  }

  const { event: eventRef } = document.getData<RetailerData>();
  const eventDocument = eventRef && props.page.getContent(eventRef);

  if (!eventDocument) {
    return null;
  }

  const event = eventDocument.getData<RetailerEventData>();

  let upcoming = 'UPCOMING:';
  let from = 'FROM';
  let to = 'TO';

  if (retailerEventLabels) {
    upcoming = retailerEventLabels.upcoming;
    from = retailerEventLabels.from;
    to = retailerEventLabels.to;
  }

  const retailerEvent = () => {
    return (
      <div className="cvc-retailer-event-box">
        <div className="cvc-retailer-event-title-row">
          <span className="cvc-retailer-event-upcoming">{upcoming} </span>
          <span className="cvc-retailer-event-title">{event.title} </span>
        </div>
        <div className="cvc-boxes-and-description-row">
          <div className="cvc-day-boxes-table">
            <div className="cvc-day-box-table">
              <div className="cvc-day-box-table-title-row">
                <div className="cvc-day-box-table-title-cell">{from}</div>
              </div>
              <div className="cvc-day-box-table-date">
                <div className="cvc-day-box-week-day">{event.startDateDayOfWeekString}</div>
                <div className="cvc-day-box-row-month">{event.startDateMonthString}</div>
                <div className="cvc-day-box-row-date">{event.startDateDateString}</div>
              </div>
            </div>

            <div className="cvc-day-box-table">
              <div className="cvc-day-box-table-title-row">
                <div className="cvc-day-box-table-title-cell">{to}</div>
              </div>
              <div className="cvc-day-box-table-date">
                <div className="cvc-day-box-week-day">{event.endDateDayOfWeekString}</div>
                <div className="cvc-day-box-row-month">{event.endDateMonthString}</div>
                <div className="cvc-day-box-row-date">{event.endDateDateString}</div>
              </div>
            </div>
          </div>
          <div className="cvc-description">{event.introduction}</div>
        </div>
      </div>
    );
  };
  return windowDimensions.isMobile || windowDimensions.isTablet ? (
    retailerEvent()
  ) : (
    <div className="cvc-retailer-event-row-desktop-wrapper">{retailerEvent()}</div>
  );
}
