import React from 'react';
import { Image } from 'react-bootstrap';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { Content, ImageSet } from '@bloomreach/spa-sdk';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right-arrow-icon.svg';
import './Banner.scss';
import { PAGE_VARIANT, SCREEN_SIZES } from '../enums';
import { CavcoBanner } from './CavcoBanner';
import { FullWidthVideoBanner } from './FullWidthVideoBanner';
import { getUrlFromImage } from '../utils';

function BannerHalf(
  title: any,
  subtitle: any,
  image: any,
  imageRef: ImageCompound,
  contentVal: any,
  content2Val: any,
  linkRef: any,
  bgColor: any,
  objectfit: any,
  imgPosition: any,
  imgShape: any
) {
  const divClass = `cvc-banner-half cvc-banner-img-${imgPosition} cvc-banner-bg-${bgColor} cvc-fit-${objectfit} cvc-banner-${imgShape}`;
  return (
    <div className={divClass}>
      <div className="cvc-banner-image">
        {linkRef && linkRef.destinationUrl !== '' && image && (
          <a
            href={linkRef.destinationUrl}
            target={linkRef.newWindow ? '_blank' : ''}
            rel={linkRef.newWindow ? 'noreferrer' : ''}
          >
            <Image src={getUrlFromImage(image)} alt={imageRef.alt} />
          </a>
        )}
        {(!linkRef || linkRef.destinationUrl === '') && image && (
          <Image src={getUrlFromImage(image)} alt={imageRef.alt} />
        )}
      </div>

      <div className="cvc-banner-content">
        <div className="cvc-banner-content__inner">
          {linkRef && linkRef.destinationUrl !== '' && title && (
            <h2>
              <a
                href={linkRef.destinationUrl}
                target={linkRef.newWindow ? '_blank' : ''}
                rel={linkRef.newWindow ? 'noreferrer' : ''}
              >
                {title}
              </a>
            </h2>
          )}
          {(!linkRef || linkRef.destinationUrl === '') && title && <h2>{title}</h2>}
          {subtitle && (
            <h3>
              <span>{subtitle} </span>
              <RightArrowIcon className="cvc-banner-content-arrow" />
            </h3>
          )}
          {contentVal && (
            <div
              className="cvc-banner-content-desc"
              dangerouslySetInnerHTML={{ __html: contentVal }}
            />
          )}
          {linkRef && (
            <a
              className="cvc-banner-link"
              href={linkRef.destinationUrl}
              title={linkRef.linkTitle}
              target={linkRef.newWindow ? '_blank' : ''}
              rel={linkRef.newWindow ? 'noreferrer' : ''}
            >
              {linkRef.displayText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function BannerFull(
  title: any,
  subtitle: any,
  image: any,
  imageRef: ImageCompound,
  contentVal: any,
  linkRef: any,
  bgColor: any,
  objectfit: any,
  txtPosition: any,
  imgShape: any
) {
  const divClass = `cvc-banner-full cvc-banner-txt-${txtPosition} cvc-banner-bg-${bgColor} cvc-fit-${objectfit} cvc-banner-${imgShape}`;
  return (
    <div className={divClass}>
      <div className="cvc-banner-image">
        {linkRef && linkRef.destinationUrl !== '' && image && (
          <a
            href={linkRef.destinationUrl}
            target={linkRef.newWindow ? '_blank' : ''}
            rel={linkRef.newWindow ? 'noreferrer' : ''}
          >
            <Image src={getUrlFromImage(image)} alt={imageRef.alt} />
          </a>
        )}
        {(!linkRef || linkRef.destinationUrl === '') && image && (
          <Image src={getUrlFromImage(image)} alt={imageRef.alt} />
        )}
      </div>

      <div className="cvc-banner-content">
        <div className="cvc-banner-content__inner">
          {title && (
            <h2>
              <a href={linkRef.destinationUrl}>{title}</a>
            </h2>
          )}
          {subtitle && (
            <h3>
              <a href={linkRef.destinationUrl}>
                <span>{subtitle} </span>
                <RightArrowIcon className="cvc-banner-content-arrow" />
              </a>
            </h3>
          )}
          {contentVal && (
            <div
              className="cvc-banner-content-desc"
              dangerouslySetInnerHTML={{ __html: contentVal }}
            />
          )}
          {linkRef && (
            <a
              className="cvc-banner-link"
              href={linkRef.destinationUrl}
              title={linkRef.linkTitle}
              target={linkRef.newWindow ? '_blank' : ''}
              rel={linkRef.newWindow ? 'noreferrer' : ''}
            >
              {linkRef.displayText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function Hero(
  title: any,
  subtitle: any,
  image: any,
  imageRef: ImageCompound,
  bgColor: any,
  objectfit: any,
  heroType: any
) {
  const divClass = `cvc-banner-hero cvc-banner-hero-${heroType} cvc-banner-bg-${bgColor} cvc-fit-${objectfit}`;

  if (!image) return '';

  const mediumImgPath = getUrlFromImage(image, 'medium');
  const largeImgPath = getUrlFromImage(image, 'large');
  const extraLargeImgPath = getUrlFromImage(image, 'extraLarge');
  const originalImgPath = getUrlFromImage(image);

  // This piece of code would never get executed and can probably throw an exception
  // else if (image.hasOwnProperty('ximage')) {
  //   if (image.ximage) {
  //     mediumImgPath =
  //       image.ximage.medium && image.ximage.medium.url ? image.ximage.medium.url : null;
  //     largeImgPath = image.ximage.large && image.ximage.large.url ? image.ximage.large.url : null;
  //     extraLargeImgPath =
  //       image.ximage.extraLarge && image.ximage.extraLarge.url ? image.ximage.extraLarge.url : null;
  //   }
  // }

  return (
    <div className={divClass}>
      <div className="cvc-banner-image">
        {image && (
          <picture>
            <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={originalImgPath} />
            {extraLargeImgPath && (
              <source
                media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
                srcSet={extraLargeImgPath}
              />
            )}
            {largeImgPath && (
              <source
                media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
                srcSet={largeImgPath}
              />
            )}
            <img alt={imageRef.alt} src={mediumImgPath} />
          </picture>
        )}
      </div>
      <div className="cvc-banner-content">
        {title && (
          <h1>
            <span className="cvc-banner-content-title">{title} </span>
            <span className="cvc-banner-content-subtitle">{subtitle} </span>
          </h1>
        )}
      </div>
    </div>
  );
}

export function Banner(props: BrProps) {
  const { document: documentRef, componentParameterMap } = props.component.getModels();
  const document: Content | undefined = documentRef && props.page.getContent(documentRef);

  if (!document) {
    return null;
  }

  const {
    title,
    subtitle,
    content1,
    content2,
    image: imageRef,
    link: linkRef,
  } = document.getData<BannerData>();
  const image = imageRef && props.page.getContent(imageRef.imagelink);
  const contentVal = content1 && props.page.rewriteLinks(content1.value);
  const content2Val = content2 && props.page.rewriteLinks(content2.value);
  const { theme, overlay, imagefit } = componentParameterMap;
  const variant = props.page.toJSON();
  const { videoCompound } = document.getData();
  const page = (variant as any).page.name;
  const pageVariant =
    page &&
    (page.includes('parkmodel') || page.includes('park-models')) &&
    !theme.toLowerCase().includes('full')
      ? ' is-park-model-std'
      : '';
  let imgPos = '';
  if (
    theme.toLowerCase().includes('imageleft') ||
    theme.toLowerCase().includes('roundimagelongtextright')
  ) {
    imgPos = 'left';
  } else if (
    theme.toLowerCase().includes('imageright') ||
    theme.toLowerCase().includes('roundimagelongtextleft')
  ) {
    imgPos = 'right';
  }
  const imgPosition = imgPos;
  let txtPos = '';
  if (theme.toLowerCase().includes('imagefullleft')) {
    txtPos = 'left';
  } else if (theme.toLowerCase().includes('imagefullright')) {
    txtPos = 'right';
  }
  const txtPosition = txtPos;

  let heroTxt = '';
  if (theme === 'heroNoTitle') {
    heroTxt = 'no-txt';
  } else {
    heroTxt = 'txt';
  }
  const heroType = heroTxt;

  let vidClass = '';
  if (theme === 'video') {
    vidClass = 'banner-video';
  } else if (theme === 'videoLarge') {
    vidClass = 'large-video';
  }
  const videoSizeClass = vidClass;

  let variantPage = 'default';
  const element = window.document.querySelector('.cavcohomes');

  if (element) {
    variantPage = PAGE_VARIANT.CAVCOHOMES;
  }

  if (variantPage === PAGE_VARIANT.CAVCOHOMES) {
    return CavcoBanner(props);
  }

  function mapFullWidthVideoBannerProps() {
    const { fallbackImage, videoId, mobileVideoId } = videoCompound;
    const posterImage = fallbackImage?.imagelink
      ? getUrlFromImage(props.page.getContent<ImageSet>(fallbackImage.imagelink))
      : '';

    return {
      posterImage,
      videoId,
      mobileVideoId,
      content2Val,
      videoSizeClass,
    };
  }

  function Video() {
    return <FullWidthVideoBanner {...mapFullWidthVideoBannerProps()} />;
  }

  return (
    <div className={`cvc-banner${pageVariant}${props.page.isPreview() ? ' has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      {(theme === 'imageleft' || theme === 'imageright') &&
        BannerHalf(
          title,
          subtitle,
          image,
          imageRef,
          contentVal,
          content2Val,
          linkRef,
          overlay,
          imagefit,
          imgPosition,
          'default'
        )}
      {(theme === 'imagefullLeft' || theme === 'imageFullRight') &&
        BannerFull(
          title,
          subtitle,
          image,
          imageRef,
          contentVal,
          linkRef,
          overlay,
          imagefit,
          txtPosition,
          'default'
        )}
      {(theme === 'roundImageLongTextRight' || theme === 'roundImageLongTextLeft') &&
        BannerHalf(
          title,
          subtitle,
          image,
          imageRef,
          contentVal,
          content2Val,
          linkRef,
          overlay,
          imagefit,
          imgPosition,
          'round'
        )}
      {theme === 'hero' && Hero(title, subtitle, image, imageRef, overlay, imagefit, heroType)}
      {theme === 'heroNoTitle' && Hero('', '', image, imageRef, overlay, imagefit, heroType)}
      {(theme === 'video' || theme === 'videoLarge') && Video()}
    </div>
  );
}
