import { CloseIcon, RightArrowIcon } from 'components/shared';
import React, { useEffect, useState, useCallback } from 'react';

export const MyFiltersBar = ({ facets, selected = {}, filterChange, isMobile = false }: any) => {
  const [, setCurrentFacets] = useState<any>({
    primary: [],
    more: [],
  });
  const [selectedFacets, setSelectedFacets] = useState<any>(undefined);

  useEffect(() => {
    setSelectedFacets(selected);
  }, [selected]);

  const getResponsiveFilterList = useCallback(
    (facetList) => {
      const primary = facetList?.primary || [];
      const more = facetList?.more?.options || [];

      return isMobile
        ? {
            all: {
              key: 'all',
              label: 'Filters',
              options: [...primary, ...more],
            },
          }
        : facetList;
    },
    [isMobile]
  );

  useEffect(() => {
    const filters = getResponsiveFilterList(facets);
    setCurrentFacets(filters);
  }, [getResponsiveFilterList, facets]);

  const handleClearFilters = () => {
    const filters = [...facets.primary];
    const newFacets = selectedFacets;

    filters?.forEach((facet: any) => {
      if (newFacets.hasOwnProperty(facet.key)) {
        delete newFacets[facet.key];
      }
    });

    setSelectedFacets(newFacets);
    filterChange(newFacets, true);
  };

  const handleFilterCloseIconClick = (key: string, value: string) => {
    const filters = [...facets.primary];
    const newFacets = selectedFacets;
    let filterValue = value;

    if (key === 'square_foot') {
      filterValue = filters
        .find((filter) => filter?.key === key)
        .options.find((option: any) => option?.name === value).to;
    }

    filters?.forEach(() => {
      if (newFacets.hasOwnProperty(key)) {
        const index = newFacets[key].indexOf(filterValue);
        if (index > -1) {
          newFacets[key].splice(index, 1);
        }
        if (newFacets[key].length === 0) {
          delete newFacets[key];
        }
      }
    });

    setSelectedFacets(newFacets);
    filterChange(newFacets, true);
  };

  const displayBottomLine = () => {
    return <hr className="cvc-product-list-your-filters-horizontal-bottom-line" />;
  };

  const facetData = facets?.primary;

  const arr: { key: string; value: unknown; suffix: any; prefix: any }[] = [];

  function locationFirst(a: [string, unknown], b: [string, unknown]) {
    if (a[0] === 'locations') {
      return -1;
    }
    if (b[0] === 'locations') {
      return 1;
    }
    return 0;
  }

  if (facetData) {
    Object.entries(selected)
      .sort(locationFirst)
      .forEach((entry) => {
        const key = entry[0];
        const values: any = entry[1];
        const facet = facetData.find((item: { key: string }) => item.key === key);
        if (facet) {
          values.forEach((value: any) => {
            let arrayValue = value;
            if (key === 'square_foot') {
              arrayValue = facet.options.find((option: any) => option?.to === value)?.name;
            }
            arr.push({
              key,
              value: arrayValue,
              suffix: facet.suffix,
              prefix: facet.prefix,
            });
          });
        }
      });
  }

  return arr && arr.length > 0 ? (
    <>
      <div className="cvc-floorplan-list-your-filters-container cvc-product-list-your-filters">
        <div className="cvc-product-list-your-filters-title">
          Your Filters
          <RightArrowIcon className="cvc-product-list-your-filters-title-icon" />
        </div>
        <div className="cvc-product-list-your-filters-button-container">
          {
            // eslint-disable-next-line
            arr.map((filter: any, index: any) => {
              const prefix = filter.prefix ? `${filter.prefix} ` : '';
              const suffix = filter.suffix ? ` ${filter.suffix}` : '';
              const buttonLabel = `${prefix}${filter.value}${suffix}`;
              const filterId = `${filter.key}-${index}`;
              if (filter.key) {
                const buttonsDuplicated = document.querySelectorAll(
                  `.cvc-product-list-filter-button.${filterId}`
                );
                if (buttonsDuplicated.length > 1) {
                  (
                    buttonsDuplicated.item(buttonsDuplicated.length - 1) as HTMLButtonElement
                  ).style.display = 'none';
                }
              }

              if (filter.value) {
                return (
                  <button
                    key={`${filter.value}-${filter.key.toString()}`}
                    id={`btn-${filterId}`}
                    type="button"
                    className={`cvc-product-list-filter-button ${filterId}`}
                    tabIndex={-1}
                  >
                    <CloseIcon
                      className="filter-button-close-icon"
                      onClick={() => handleFilterCloseIconClick(filter.key, filter.value)}
                    />
                    {buttonLabel}
                  </button>
                );
              }
            })
          }
          {arr.length > 1 ? (
            <button
              key="clear-all-button"
              type="button"
              className="cvc-product-list-filter-button clear-all"
              onClick={handleClearFilters}
            >
              <CloseIcon className="filter-button-close-icon" />
              Clear All
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      {displayBottomLine()}
    </>
  ) : (
    <></>
  );
};
