import React from 'react';

import { BrProps } from '@bloomreach/react-sdk';
import { Col, Container, Row } from 'react-bootstrap';

import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';

import useWindowDimensions from '../hooks/useWindowDimensions';

import { FloorPlanCard, DisplayHomeCard } from './ViewedHomeCards';
import './RecentlyViewedHomes.scss';

import 'swiper/swiper.scss';

function isDocumentDisplayHome(document: any) {
  return document.availableStatus !== undefined;
}

export function RecentlyViewedHomes(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  const { visitedHomes } = props.component.getModels();

  if (!visitedHomes) return null;
  const floorPlansFiltersContainerClasses = classNames({
    'show-container': true,
    'hide-container': false,
    'viewed-homes-filters-container': true,
    'no-horizontal-padding': true,
  });

  const mapFloorPlanToCard = (floorplan: any, index: number) => (
    <FloorPlanCard
      key={`viewed-home-card-${index.toString()}`}
      floorPlanDocumentData={floorplan}
      windowDimensions={windowDimensions}
      props={props}
    />
  );

  const mapDisplayHomeToCard = (displayHome: any, index: number) => (
    <DisplayHomeCard
      key={`viewed-home-card-${index.toString()}`}
      displayHomeDocumentData={displayHome}
      windowDimensions={windowDimensions}
      props={props}
    />
  );

  const mapFloorPlanToCards = (documents = []) =>
    documents
      .map((document: any, index) => {
        const isDisplayHome = isDocumentDisplayHome(document);

        if (isDisplayHome) {
          return mapDisplayHomeToCard(document, index);
        }
        return mapFloorPlanToCard(document, index);
      })
      .reverse();

  const mapFloorPlanToSlides = (floorplans = [], isThumbs = true) =>
    floorplans
      .map((item: any, index: number) => {
        const slideContainerClasses = classNames({
          'cvc-media-viewer__item': true,
          'swiper-slide-container': false,
          'swiper-slide-container-thumbs': isThumbs,
        });
        const isDisplayHome = isDocumentDisplayHome(item);

        if (isDisplayHome) {
          return (
            <SwiperSlide key={`slide-image-${item.url}`}>
              <div className={slideContainerClasses}>{mapDisplayHomeToCard(item, index)}</div>
            </SwiperSlide>
          );
        }
        return (
          <SwiperSlide key={`slide-image-${item.url}`}>
            <div className={slideContainerClasses}>{mapFloorPlanToCard(item, index)}</div>
          </SwiperSlide>
        );
      })
      .reverse();

  const displayFloorPlanCardsDeskTop = (floorplans = []) => (
    <div className={classNames('cvc-vh-swiper-scroll-bar-container')}>
      <Swiper
        className="vh-gallery-thumbs d-none d-md-flex"
        loop={false}
        spaceBetween={floorplans.length > 1 ? 19 : 0}
        slidesPerView="auto"
        navigation={{
          nextEl: '.thumbs-swiper-button-next-container',
          prevEl: '.thumbs-swiper-button-prev-container',
        }}
        slideToClickedSlide
        watchSlidesProgress
      >
        {mapFloorPlanToSlides(floorplans)}
      </Swiper>
      <button className="thumbs-swiper-button-next-container" type="button">
        <div className="swiper-button-next" />
      </button>
      <button className="thumbs-swiper-button-prev-container" type="button">
        <div className="swiper-button-prev" />
      </button>
    </div>
  );

  return (
    <div className="cvc-vh-recent">
      <h2 className="cvc-vh-recent-title mb-4">Recently Viewed</h2>
      <div className="cvc-viewed-home-list-locations-container mt-3">
        <Col className={floorPlansFiltersContainerClasses}>
          {windowDimensions.isMobile || windowDimensions.isTablet ? (
            <Container className="cvc-vh-recent-container">
              <Row className="cvc-viewed-home-photos-details-container no-margin">
                <div className="viewed-homes-cards-container-mobile">
                  {mapFloorPlanToCards(visitedHomes)}
                </div>
              </Row>
            </Container>
          ) : (
            <Container className="cvc-vh-recent-container">
              <div className="viewed-homes-cards-container">
                {displayFloorPlanCardsDeskTop(visitedHomes)}
              </div>
            </Container>
          )}
        </Col>
      </div>
    </div>
  );
}
