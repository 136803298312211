import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import './CavcoUserLoginForm.scss';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';
import { setCookie } from '../hooks/cookie';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';
import { ReactComponent as NoEyeIcon } from '../../assets/images/no-eye.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/exclamation-point-red.svg';
import { initAllFavoritesForCurrentUser } from '../utils';
import { LoginStatus, loginSubject$ } from './LoginStatus';

export function CavcoUserCreateForm({
  cavcoUserLoginUrl,
  postLoginUrl,
  setSuccessfulSignIn,
  closeMethod,
  switchToOpenLoginModal,
  switchToOpenForgetPasswordModal,
  cavcoFavoriteUrl,
}: any) {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [emailOrPasswordEmpty, setEmailOrPasswordEmpty] = useState(false);
  const [passwordAndConfirmPasswordMatch, setPasswordAndConfirmPasswordMatch] = useState(true);
  const [passwordRequirementMatch, setPasswordRequirementMatch] = useState(true);
  const [submittingCreateUser, setSubmittingCreateUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [isShowPwd, setIsShowPwd] = useState(false);

  const [, setCavcoUserInfo] = useStateWithLocalStorage('cavcoUserInfo');

  return (
    <Formik
      initialValues={{ emailaddress: '', password: '', confirmPassword: '' }}
      onSubmit={async (values) => {
        if (!values.emailaddress || !values.password) {
          setEmailOrPasswordEmpty(true);
          return;
        }
        setEmailOrPasswordEmpty(false);

        if (!values.password.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,25}$/)) {
          setPasswordRequirementMatch(false);
          return;
        }
        setPasswordRequirementMatch(true);

        if (values.password !== values.confirmPassword) {
          setPasswordAndConfirmPasswordMatch(false);
          return;
        }
        setPasswordAndConfirmPasswordMatch(true);

        setSubmittingCreateUser(true);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        };
        fetch(cavcoUserLoginUrl, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log('responseData:', data); // eslint-disable-line

            if (data.status === '400') {
              if (data.message.startsWith('emailAddress already exists')) {
                setEmailAlreadyExist(true);
              } else {
                setErrorMessage(data.message);
                setEmailAlreadyExist(false);
              }
              setSubmittingCreateUser(false);
              return;
            }
            setErrorMessage('');

            setCavcoUserInfo(data);
            if (data && data.userId && postLoginUrl) {
              setCookie('cavcouserid', data.userId, 30);
              initAllFavoritesForCurrentUser(cavcoFavoriteUrl);
              // initMyFavoritesForCurrentUser(loadMyFavoriteCardsUrl);
            }
            setSuccessfulSignIn(true);
            closeMethod();
            setSubmittingCreateUser(false);
            loginSubject$.next(LoginStatus.loggedIn);
          })
          .catch((error) => {
            console.log('error from the server:', error.message); // eslint-disable-line
            setErrorOccurred(true);
            setSubmittingCreateUser(false);
          });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit} className="cvc-form cvc-form-account">
          <div className="cvc-form-header">
            <h2>Create New Account</h2>
            <p>
              <span>Already have an account? </span>
              <strong
                className="cvc-text-link-alt"
                onClick={switchToOpenLoginModal}
                aria-hidden="true"
              >
                Sign In
              </strong>
            </p>
          </div>

          {emailAlreadyExist && (
            <div className="cvc-form-error-message">
              <ErrorIcon className="cvc-form-error-message-icon" />
              <div className="cvc-form-error-message-text">
                <span>An account with that email address already exists. </span>
                <span
                  className="cvc-text-link-alt"
                  role="button"
                  tabIndex={0}
                  onClick={switchToOpenForgetPasswordModal}
                  onKeyDown={switchToOpenForgetPasswordModal}
                >
                  Forgot Your Password?
                </span>
                <span> ... Or you can create a new account with a different email.</span>
              </div>
            </div>
          )}

          {(!passwordAndConfirmPasswordMatch ||
            emailOrPasswordEmpty ||
            !!errorMessage ||
            errorOccurred) && (
            <div className="cvc-form-error-message">
              <ErrorIcon className="cvc-form-error-message-icon" />
              <div className="cvc-form-error-message-text">
                {!passwordAndConfirmPasswordMatch && <span>Password does not match.</span>}
                {emailOrPasswordEmpty && <span>Email and Password are required.</span>}
                {!!errorMessage && <span>{errorMessage}</span>}
                {errorOccurred && <span>Error Occurred, Please Try Again.</span>}
              </div>
            </div>
          )}

          <Form.Group className="mb-3 mt-3" controlId="emailaddress1">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              name="emailaddress"
              value={values.emailaddress}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                emailAlreadyExist || emailOrPasswordEmpty || !!errorMessage || errorOccurred
                  ? 'cvc-form-error'
                  : ''
              }
            />
            <ErrorMessage name="emailaddress" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password1">
            <Form.Label>Password</Form.Label>
            <div className="right-inner-addon">
              <Form.Control
                type={isShowPwd ? 'text' : 'password'}
                name="password"
                value={values.password}
                onChange={handleChange}
                className={
                  !passwordRequirementMatch ||
                  !passwordAndConfirmPasswordMatch ||
                  emailOrPasswordEmpty ||
                  !!errorMessage ||
                  errorOccurred
                    ? 'cvc-form-error'
                    : ''
                }
              />
              <div // eslint-disable-line
                className="right-inner-addon-btn"
                onClick={() => setIsShowPwd((prevState) => !prevState)}
              >
                {isShowPwd ? <NoEyeIcon /> : <EyeIcon />}
              </div>
            </div>
            <ErrorMessage name="password" />
          </Form.Group>
          <div className="password-reqs">
            <strong
              className={
                !passwordRequirementMatch &&
                !values.password.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,25}$/)
                  ? 'cvc-form-error'
                  : ''
              }
            >
              Your password must include:
            </strong>
            <div>
              <ul>
                <li
                  className={
                    !passwordRequirementMatch && !values.password.match(/(^.{8,25}$)/)
                      ? 'cvc-form-error'
                      : ''
                  }
                >
                  8-25 characters
                </li>
                <li
                  className={
                    !passwordRequirementMatch && !values.password.match(/(?=.*[A-Z])(?=.*[a-z])/)
                      ? 'cvc-form-error'
                      : ''
                  }
                >
                  Upper and lowercase letters
                </li>
                <li
                  className={
                    !passwordRequirementMatch && !values.password.match(/\d/)
                      ? 'cvc-form-error'
                      : ''
                  }
                >
                  At least a number
                </li>
                <li
                  className={
                    !passwordRequirementMatch && !values.password.match(/\W/)
                      ? 'cvc-form-error'
                      : ''
                  }
                >
                  At least a special character
                </li>
              </ul>
            </div>
          </div>

          <Form.Group className="mb-5" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <div className="right-inner-addon">
              <Form.Control
                type={isShowPwd ? 'text' : 'password'}
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className={!passwordAndConfirmPasswordMatch ? 'cvc-form-error' : ''}
              />
              <div // eslint-disable-line
                className="right-inner-addon-btn"
                onClick={() => setIsShowPwd((prevState) => !prevState)}
              >
                {isShowPwd ? <NoEyeIcon /> : <EyeIcon />}
              </div>
              <ErrorMessage name="confirmPassword" />
            </div>
          </Form.Group>

          <div className="text-center mt-5 terms">
            <small>
              <span>By clicking &quot;Create New Account&quot; you agree to the </span>
              <a href="https://www.cavco.com/privacy/">Privacy Policy</a>.
            </small>
          </div>

          <div className="text-center">
            <Button
              disabled={submittingCreateUser}
              type="submit"
              variant="primary"
              className="cvc-btn"
            >
              {submittingCreateUser && (
                <>
                  <Spinner animation="border" variant="light" className="cvc-btn-icon" />
                </>
              )}
              Create New Account
            </Button>
          </div>
          {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
}
