import { styled } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const StyledSnackbar = styled(Snackbar)({
  '& .MuiAlert-filledSuccess': {
    backgroundColor: 'rgba(39, 122, 26, 1)',
  },
  '& .MuiAlert-filledError': {
    backgroundColor: 'rgba(199, 35, 31, 1)',
  },
  '& .info-form-snackbar__title': {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 700,
  },
  '& .info-form-snackbar__text': {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
  },
});

export const StyledAlert = styled(MuiAlert)({
  display: 'flex',
  alignItems: 'center',
  color: '#FFF',
  '& .MuiSvgIcon-root': {
    fill: '#FFF !important',
  },
});
