import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Card } from 'react-bootstrap';
import './FloorPlanCard.scss';
import SiteGlobalSwitches from 'components/utils';
import { FavoriteIcon } from 'components/shared/Icons';
import { CardBody } from './CardBody';
import { CardImage } from './CardImage';

interface FloorPlanCardProps {
  floorPlanDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  className?: string;
  hideHeart?: boolean;
}

export function FloorPlanCard(floorPlanCardProps: FloorPlanCardProps) {
  const {
    id,
    assetID,
    url,
    modelName,
    modelNumber,
    photoUrls,
    lineDrawingUrl: firstLineDrawingImage,
    videoAlbum: videosRef,
    tourAlbum: toursRef,
    numBedrooms,
    numBathrooms,
    squareFootage,
    // isFavorite,
  } = floorPlanCardProps.floorPlanDocumentData;

  const floorPlanUrl = url;

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  if (!hasPhoto && firstLineDrawingImage) {
    imageDefault = firstLineDrawingImage;
  }

  const floorPlanTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;

  const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
  // My Account
  const compModels = floorPlanCardProps.props.component.getModels();
  const { retailerId } = compModels;
  const favoriteBaseUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.favoriteBaseUrl
    : null;
  const loadFavoriteUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.cavcoFavoriteUrl
    : null;

  return (
    <Card
      id={id}
      className={`fp-recent-card ${!hasPhoto && imageDefault !== null && 'line-drawing'}`}
    >
      <CardImage className="fp-recent-image-link" image={imageDefault} url={floorPlanUrl} />
      {showFavorites && modelName && !floorPlanCardProps?.hideHeart ? (
        <FavoriteIcon
          classes="fp-recent-favorites-icon"
          type="our_homes"
          id={assetID}
          subId={retailerId}
          // isFavorite={isFavorite}
          baseUrl={favoriteBaseUrl}
          loadFavoriteUrl={loadFavoriteUrl}
          myAccountConfig={compModels.myAccountConfig}
        />
      ) : (
        ''
      )}
      <CardBody
        title={floorPlanTitle}
        url={floorPlanUrl}
        numBedrooms={numBedrooms}
        numBathrooms={numBathrooms}
        squareFootage={squareFootage}
        hasPhoto={hasPhoto}
        videosRef={videosRef}
        toursRef={toursRef}
        isMobile={floorPlanCardProps.windowDimensions.isMobile}
      />
    </Card>
  );
}
