import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface GoogleMapsAPIContextType {
  isLoaded: boolean;
}

const GoogleMapsAPIContext = createContext<GoogleMapsAPIContextType>({ isLoaded: false });

interface GoogleMapsProviderProps {
  children: ReactNode;
  apiKey: string;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({ children, apiKey }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const scriptId = 'google-maps-script';
    if (document.getElementById(scriptId)) {
      // Script is already present
      setIsLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      setIsLoaded(true); // Set to true only after the script is fully loaded
    };
    script.onerror = () => {
      // eslint-disable-next-line no-console
      console.error('Failed to load the Google Maps script.');
    };
  }, [apiKey]); // Dependency on apiKey ensures this runs only when apiKey changes

  return (
    <GoogleMapsAPIContext.Provider value={{ isLoaded }}>
      {isLoaded ? children : <div>Loading Google Maps...</div>}
    </GoogleMapsAPIContext.Provider>
  );
};

export const useGoogleMaps = (): GoogleMapsAPIContextType => useContext(GoogleMapsAPIContext);
