import { allFacets, allPageSort, queryStringFacetItems } from '../FloorPlanResults.constants';
import { EsLocationFilterProps } from '../FloorPlanResults.interface';

export const buildQueryString = (data: any, facetConfig = allFacets) => {
  const url: any = new URL('', window.location.origin + window.location.pathname);

  const currentQsObject = Object.entries(data).reduce((memo: any, [k, v]: [string, any]) => {
    const facetData = facetConfig[k] || null;
    const newItem = facetData ? facetData.urlMapper(k, v) : { [k]: v };
    return {
      ...memo,
      ...newItem,
    };
  }, {});

  Object.entries(currentQsObject).forEach(([k, v]: [string, any]) => {
    url.searchParams.set(k, JSON.stringify(v));
  });

  return url;
};

// Parse query string.
// facet items are based on queryStringFacetItems
// everything else goes into "general"
export const getQueryStringValues = (query: string, isRetailer: boolean) => {
  const qs = new URLSearchParams(query);

  const pattern = new RegExp(isRetailer ? '(locations|latitude|longitude)' : 'retailer_ids', 'i');
  const filtered = Array.from(qs).filter((item) => !pattern.test(item[0]));
  return filtered.reduce(
    (memo: any, [k, v]: [string, any]) => {
      const section = queryStringFacetItems.includes(k) ? 'facets' : 'general';
      let newVal;
      try {
        newVal = JSON.parse(v);
      } catch (err) {
        newVal = v;
      }

      return {
        ...memo,
        [section]: {
          ...memo[section],
          [k]: newVal,
        },
      };
    },
    {
      facets: {},
      general: {},
    }
  );
};

export const buildLocationFacet = ({ base, lat, lng, unit }: any) => {
  return {
    locations: [
      {
        ...base,
        center: `${lat},${lng}`,
        unit,
      },
    ],
  };
};

export const processLocationFiltersForEs = (currentRequest: any) => {
  const { locations = null, search = {}, ...rest } = currentRequest;
  const loc = locations
    ? {
        locations: {
          ...locations,
          ...search,
        },
      }
    : {};
  return {
    ...rest,
    ...loc,
  };
};

export const buildUiLocationFilters = ({
  distance = null,
  lat = null,
  lng = null,
  unit = 'mi',
  facetConfig = allFacets,
}: any): any => {
  const dist = distance || { from: 0, to: facetConfig.locations.ui?.defaultValue[0] };
  const search =
    lat && lng
      ? {
          search: facetConfig.search.mapper({
            lat,
            lng,
            unit,
          }),
        }
      : {};
  return {
    locations: dist,
    ...search,
  };
};

export const buildLocationFilter = ({
  distance = null,
  lat = null,
  lng = null,
  unit = 'mi',
  facetConfig = allFacets,
}: any): { locations: EsLocationFilterProps } => {
  const geo = lat && lng ? facetConfig.search.mapper({ lat, lng, unit }) : {};
  const dist = distance || { from: 0, to: facetConfig.locations.ui?.defaultValue[0] };
  return {
    locations: {
      ...dist,
      ...geo,
    },
  };
};

export const buildRetailerIdsFilter = (id: string) => {
  return { retailer_ids: [id] };
};

export const buildBaseFiltersArray = ({
  type,
  subtype,
  lat,
  lng,
  unit,
  id = null,
  facetConfig = allFacets,
}: any) => {
  const typeFilter = { type };
  const subTypeFilter = subtype ? [{ inventory_type: subtype }] : [];
  const retailerIdsFilter = id ? [buildRetailerIdsFilter(id)] : [];
  const locationFilter =
    !!lat && !!lng && !!unit && !id
      ? [buildLocationFilter({ distance: { from: 0, to: 3000 }, lat, lng, unit, facetConfig })]
      : [];
  return [typeFilter, ...subTypeFilter, ...retailerIdsFilter, ...locationFilter];
};

export const buildESRequest = ({ facets, page, sort, filters }: any) => {
  return {
    query: '',
    facets: { ...facets },
    page: { ...page },
    sort: [sort || allPageSort.sort.defaultValue],
    filters: {
      all: [...filters],
    },
  };
};
