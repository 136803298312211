import React from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { StyledSnackbar, StyledAlert } from './SnackbarStyledComponents';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface SnackBarMessage {
  message: string;
  severity: string;
}

export const initSnackBarMessage: SnackBarMessage = {
  message: '',
  severity: '',
};

export const MESSAGE_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

function SnackBar(props: {
  openSnackBar: any;
  handleCloseSnackBar: any;
  snackBarMessage: SnackBarMessage;
}) {
  const { openSnackBar, handleCloseSnackBar, snackBarMessage } = props;
  return (
    <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
      <Alert onClose={handleCloseSnackBar} severity={snackBarMessage.severity}>
        {snackBarMessage.message}
      </Alert>
    </Snackbar>
  );
}

function CavcoAlert(props: AlertProps) {
  return (
    <StyledAlert className="info-form-snackbar__alert" elevation={6} variant="filled" {...props} />
  );
}

interface CavcoSnackbarProps {
  snackbarProps: SnackbarProps;
  alertProps: AlertProps;
}

export const CavcoSnackbar: React.FC<CavcoSnackbarProps> = ({
  snackbarProps,
  alertProps,
  children,
}) => {
  return (
    <StyledSnackbar {...snackbarProps}>
      <CavcoAlert {...alertProps}>{children}</CavcoAlert>
    </StyledSnackbar>
  );
};

export default SnackBar;
