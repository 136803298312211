import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { AlertIcon } from 'components/shared/Icons';

export const NoFloorPlansMessage = ({ title, contentVal, maxRadiusString }: any) => {
  if (document) {
    return (
      <Container>
        <Row className="cvc-product-list-alert-banner-container no-horizontal-margin">
          <Col className="cvc-product-list-alert-icon-container no-horizontal-padding">
            <AlertIcon className="cvc-product-list-alert-icon" />
          </Col>
          <Col className="cvc-product-list-alert-content-container no-horizontal-padding">
            <div className="cvc-product-list-alert-title">{`${title} ${maxRadiusString}`}</div>
            <div className="cvc-product-list-alert-description">
              <div dangerouslySetInnerHTML={{ __html: contentVal }} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <div>No Floor Plans found nearby</div>
    </Container>
  );
};
