import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { currencyFormatter } from 'components/utils';
import { FavoriteIcon, ResolverLink } from '../index';
import { ImageCarousel } from '../ImageCarousel/ImageCarousel';
import { ReactComponent as VirtualIcon } from '../../../assets/images/cavcohomes/360-tour.svg';
import { ReactComponent as VideoIcon } from '../../../assets/images/cavcohomes/video.svg';
import { ReactComponent as ComingSoonCrossStitchPicture } from '../../../assets/images/coming-soon-cross-stitch-picture.svg';
import { resultMapper } from './procuctResultCard.mapper';

import './productResultCard.scss';
import { ProductResultBaseProps, ProductResultMirProps } from './productResultCard.interface';

export const ProductResultCard = ({
  result,
  type = 'floorplan',
  isMicroSite,
  handleMediaClick,
  basePath,
  isRetailer,
  isRaw = true,
  handleFavoriteClick,
  retailerId,
  myAccountConfig,
}: {
  result: any;
  type: string;
  isMicroSite: boolean;
  basePath?: string | null;
  handleMediaClick?: (params: any) => void;
  isRetailer: boolean;
  isRaw?: boolean;
  handleFavoriteClick?: (params: any) => any;
  retailerId?: string;
  myAccountConfig?: any;
}) => {
  const mapped: any = isRaw ? resultMapper(result) : result;

  const usePath = basePath || window.location.pathname.replace(/\/$/, '');

  const pageHref = usePath;
  const linkTarget = result.isExternal ? '_blank' : '';

  const mappedUrl = isMicroSite
    ? pageHref + mapped.retailer_microsite_url + window.location.search
    : mapped.url + window.location.search;

  const getResultType = (resultData: any) => {
    let resultType;

    switch (resultData.inventory_type) {
      case 'Stock':
        resultType = 'display_homes';
        break;
      case 'MIR':
        resultType = 'move_in_ready_homes';
        break;
      default:
        resultType = 'our_homes';
    }

    return resultType;
  };

  /*
  const setFavoriteUrl = (data: ProductResultBaseProps) => {
    const assetPath = getResultType(data);
    const assetId = data.asset_id;
    const isFavorite = isFavoriteForUser(assetId);

    const websiteUrlVal = isMicroSite ? pageHref + mapped.retailer_microsite_url : data.url;

    const favoriteUrl = `${
      myAccountConfig?.favoriteBaseUrl
    }/${assetPath}/${assetId}/${!isFavorite}?websiteUrl=${websiteUrlVal}`;

    return favoriteUrl;
  };
  */

  const onVideoIconClick = () => {
    if (!!mapped.video_tour && handleMediaClick) {
      handleMediaClick({
        isVideo: true,
        mediaTitle: `${mapped.name} video tour`,
        mediaSrc: mapped.video_tour,
      });
    }
  };

  const onVirtualIconClick = () => {
    if (!!mapped.pana_tour && handleMediaClick) {
      handleMediaClick({
        isVideo: false,
        mediaTitle: `${mapped.name} pana tour`,
        mediaSrc: mapped.pana_tour,
      });
      return;
    }
    if (!!mapped.virtual_tour && handleMediaClick) {
      handleMediaClick({
        isVideo: false,
        mediaTitle: `${mapped.name} virtual tour`,
        mediaSrc: mapped.virtual_tour,
      });
    }
  };

  const ImageBlock = ({ photos = [], drawings = [] }: any) => {
    const image = photos[0] || drawings[0] || null;
    const hasCarousel = (photos && photos.length > 1) || (drawings && drawings.length > 1);

    return (
      <div className="floorplan-result__image-block">
        {hasCarousel ? (
          <ResolverLink
            target={linkTarget}
            href={mappedUrl}
            className="floorplan-result__single-image"
          >
            <ImageCarousel photoUrls={photos} lineDrawingUrl={drawings} />
          </ResolverLink>
        ) : (
          <ResolverLink
            target={linkTarget}
            href={mappedUrl}
            className="floorplan-result__single-image"
          >
            {image ? (
              <img className="single-image" alt={image?.imageAltText} src={image?.url} />
            ) : (
              <div className="card-no-photo">
                <ComingSoonCrossStitchPicture title="image of home coming soon" />
              </div>
            )}
          </ResolverLink>
        )}
      </div>
    );
  };

  const AvailabiltyPin = ({ availability }: { availability: string }) => {
    return <div className="floorplan-result__pin">{availability}</div>;
  };

  /** THESE ITEMS ARE PRETTY SIMILAR. AS WE BUILD OUT THE REST OF THEM, MAYBE WE CONSOLIDATE... */
  // presently only using floorplan type, remove move in ready if not needed

  const FloorPlanItem = ({
    data,
    isRetail,
  }: {
    data: ProductResultBaseProps;
    isRetail: boolean;
  }) => {
    const {
      video_tour,
      pana_tour,
      virtual_tour,
      photos,
      line_drawings,
      floorplan_availability,
      inventory_availability,
      sale_type,
      floorplan_retailers_in_stock,
    } = data;

    const isInStock = isRetailer
      ? floorplan_retailers_in_stock?.includes(retailerId)
      : floorplan_availability && floorplan_availability.includes('In Stock Now');
    const isSold = inventory_availability && inventory_availability.includes('Sold');
    const isNew = sale_type && sale_type.includes('New');
    const isPre = sale_type && sale_type.includes('Pre');
    // const favoriteUrl = setFavoriteUrl(data);
    const websiteUrl = isMicroSite ? pageHref + mapped.retailer_microsite_url : data.url;

    const showStock = isInStock || isSold;

    const MethodRow = () => {
      return (
        <>
          {isInStock && <span>In Stock |</span>}{' '}
          {data.building_method && <span>{data.building_method}</span>}{' '}
          {data.inventory_availability && !isSold && <span> | {data.inventory_availability}</span>}
        </>
      );
    };

    const PriceRow = () => {
      const convertStringToNum = (value: string | number) => {
        const isNumberString = (str: string) => /^\d+$/.test(str);

        if (typeof value === 'string' && isNumberString(value)) {
          return currencyFormatter.format(parseInt(value, 10));
        }
        if (typeof value === 'string' && !isNumberString(value)) {
          return value;
        }
        if (typeof value === 'number') {
          return currencyFormatter.format(value);
        }
      };

      const nowPrice = data?.now_price && convertStringToNum(data?.now_price);
      const wasPrice = data?.was_price && convertStringToNum(data?.was_price);
      const sellingPrice = data?.selling_price && convertStringToNum(data?.selling_price);
      const startingPrice = data?.starting_price && convertStringToNum(data?.starting_price);

      if (isSold) return <span className="now-price">Sold</span>;
      return (
        <div className="result-info result-info--price">
          {nowPrice &&
            (mapped.clearance ? (
              <>
                <span className="now-price">{nowPrice}</span>{' '}
                <span className="was-price">
                  {' '}
                  (was {wasPrice})<span className="price-asterisk">**</span>
                </span>
              </>
            ) : (
              <span className="selling-price">
                {sellingPrice}
                <span className="price-asterisk">**</span>
              </span>
            ))}
          {sellingPrice && !nowPrice && (
            <span className="selling-price">
              {sellingPrice}
              <span className="price-asterisk">**</span>
            </span>
          )}
          {startingPrice && (
            <span className="starting-price">
              Starts in the {startingPrice}s<span className="price-asterisk">**</span>
            </span>
          )}
        </div>
      );
    };

    const assetType = getResultType(data);

    return (
      <section className={`floorplan-result${isSold ? ' sold' : ''} ${isRetail}`}>
        {showStock && <AvailabiltyPin availability={isInStock ? 'IN STOCK' : 'SOLD'} />}
        {isNew && <AvailabiltyPin availability={isSold ? 'SOLD' : 'New Home'} />}
        {isPre && <AvailabiltyPin availability={isSold ? 'SOLD' : 'Pre-Owned'} />}

        <div className="floorplan-result__image">
          <FavoriteIcon
            classes="floorplan-result__favorite floorplan-save-favorites-icon"
            // setFavoriteUrl={favoriteUrl}
            type={assetType}
            baseUrl={myAccountConfig?.favoriteBaseUrl}
            handleClick={handleFavoriteClick}
            isCavcoHomesVariant
            loadFavoriteUrl={myAccountConfig?.cavcoFavoriteUrl}
            id={data.asset_id}
            myAccountConfig={myAccountConfig}
            websiteUrl={websiteUrl}
          />
          <ImageBlock photos={photos} drawings={line_drawings} />
        </div>
        <div className="floorplan-result__content">
          <header>
            <ResolverLink
              target={linkTarget}
              className="floorplan-result__content__heading-link"
              href={mappedUrl}
            >
              <h3>
                <span className="floorplan-result__model-name">{data.name} </span>
                <span className="floorplan-result__model-number">{data.model_number}</span>
              </h3>
            </ResolverLink>
          </header>
          <div className="result-info result-info--method">
            <MethodRow />
            {video_tour && video_tour.length > 0 && (
              <IconButton
                className="floorplan-result__media-button"
                onClick={() => onVideoIconClick()}
                aria-label="View video tour Button"
              >
                <VideoIcon />
              </IconButton>
            )}
            {((virtual_tour && virtual_tour.length) || (pana_tour && pana_tour.length)) && (
              <IconButton
                className="floorplan-result__media-button"
                onClick={() => onVirtualIconClick()}
                aria-label="View virtual tour button"
              >
                <VirtualIcon />
              </IconButton>
            )}
          </div>
          <div className="result-info result-info--price">
            <PriceRow />
          </div>
          <div className="result-info result-info--rooms">
            {data.number_of_bedrooms} bd | {data.number_of_bathrooms} ba | {data.square_foot} sqft
          </div>
        </div>
      </section>
    );
  };

  const MoveInReadyItem = ({ data }: { data: ProductResultMirProps }): any => {
    const { video_tour, nowPrice, wasPrice, photos, line_drawings, virtual_tour } = data;
    // const favoriteUrl = setFavoriteUrl(data);
    const websiteUrl = isMicroSite ? pageHref + mapped.retailer_microsite_url : data.url;
    const assetType = getResultType(data);

    return (
      <section className="floorplan-result">
        <div className="floorplan-result__image">
          <FavoriteIcon
            classes="floorplan-result__favorite floorplan-save-favorites-icon"
            // setFavoriteUrl={favoriteUrl}
            type={assetType}
            baseUrl={myAccountConfig?.favoriteBaseUrl}
            handleClick={handleFavoriteClick}
            isCavcoHomesVariant
            loadFavoriteUrl={myAccountConfig?.cavcoFavoriteUrl}
            id={data.asset_id}
            myAccountConfig={myAccountConfig}
            websiteUrl={websiteUrl}
          />
          <ImageBlock photos={photos} drawings={line_drawings} />
        </div>
        <div className="floorplan-result__content">
          <header>
            <ResolverLink
              target={linkTarget}
              className="floorplan-result__content__heading-link"
              href={mappedUrl}
            >
              <h3>
                {data.name} {data.model_number}
              </h3>
            </ResolverLink>
          </header>
          <div className="result-info result-info--method">
            {data.floorplan_availability} | {data.building_method}
            {video_tour && video_tour.length > 0 && (
              <IconButton
                className="floorplan-result__media-button"
                onClick={() => onVideoIconClick()}
              >
                <VideoIcon />
              </IconButton>
            )}
            {virtual_tour && virtual_tour.length > 0 && (
              <IconButton
                className="floorplan-result__media-button"
                onClick={() => onVirtualIconClick()}
              >
                <VirtualIcon />
              </IconButton>
            )}
          </div>
          {nowPrice && (
            <div className="result-info result-info--price">
              ${nowPrice}{' '}
              <span className="was-price">
                {' '}
                (was ${wasPrice})<span className="price-asterisk">**</span>
              </span>
            </div>
          )}
          <div className="result-info result-info--rooms">
            {data.number_of_bedrooms} bd | {data.number_of_bathrooms} ba | {data.square_foot} sqft
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="floorplan-result">
      {type === 'floorplan' && <FloorPlanItem data={mapped} isRetail={isRetailer} />}
      {type === 'move-in-ready' && <MoveInReadyItem data={mapped} />}
    </div>
  );
};
