import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './FavoriteIcon.scss';
import { ReactComponent as SaveFavoritesOutline } from 'assets/images/save-favorites-icon.svg';
// import { useStateWithLocalStorage } from 'components/hooks/useStateWithLocalStorage';
import { getCookie } from 'components/hooks/cookie';
// import { getCurrentSiteName } from 'components/utils';
import { SignInModals } from 'components/MyAccount/SignInModals';
import { CavcoSnackbar } from 'components/shared';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

import {
  initAllFavoritesForCurrentUser,
  isFavoriteForUser,
  getOriginalSubId,
} from 'components/utils';
import { LoginStatus, loginSubject$ } from 'components/MyAccount/LoginStatus';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FavoriteIcon = ({
  classes,
  type,
  subId = '',
  id,
  // isFavorite,
  baseUrl,
  loadFavoriteUrl,
  setFavoriteUrl = '',
  subIdType,
  myAccountConfig,
  handleClick = null,
  callback = null,
  overrideFavorite = false,
  isFavoriteOverride = false,
  isCavcoHomesVariant = false,
  websiteUrl = null,
}: any) => {
  //  const [, setCavcoUserFavorites] = useStateWithLocalStorage('cavcoUserFavorites');
  const isFavorite = overrideFavorite ? isFavoriteOverride : isFavoriteForUser(id);

  const [favorite, setFavorite] = useState(isFavorite);
  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [userId, setUserId] = useState(getCookie('cavcouserid'));

  useEffect(() => {
    const subscription = loginSubject$.subscribe((loginStatus: any) => {
      if (loginStatus) {
        setUserId(getCookie('cavcouserid'));
        if (loginStatus === LoginStatus.loggedOut) {
          setFavorite(false);
        } else if (loginStatus === LoginStatus.loggedIn) {
          setFavorite(isFavoriteForUser(id));
          setTimeout(() => {
            setFavorite(isFavoriteForUser(id));
          }, 2000);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  const initSnackBarMessage = {
    message: '',
    severity: 'success' as Color,
  };

  const MESSAGE_SEVERITY = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success',
  };
  const [snackBarMessage, setSnackBarMessage] = useState(initSnackBarMessage);

  useEffect(() => {
    if (overrideFavorite) {
      setFavorite(isFavoriteOverride);
    }
  }, [isFavoriteOverride, overrideFavorite]);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  function SnackBarComponent() {
    return !isCavcoHomesVariant ? (
      <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity={snackBarMessage.severity}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    ) : (
      <CavcoSnackbar
        snackbarProps={{
          open: openSnackBar,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000,
          onClose: handleCloseSnackBar,
        }}
        alertProps={{
          className: 'info-form-snackbar',
          severity: snackBarMessage.severity,
        }}
      >
        <span className="info-form-snackbar__text">{snackBarMessage.message}</span>
      </CavcoSnackbar>
    );
  }

  const handleSaveFavorite = async () => {
    const originalSubId = favorite ? getOriginalSubId(id) : subId;
    let favoriteUrl = setFavoriteUrl;
    if (!favoriteUrl) {
      favoriteUrl = originalSubId
        ? `${baseUrl}/${type}/${id}/${!favorite}?subId=${originalSubId}${
            subIdType ? `&subIdType=${subIdType}` : ''
          }`
        : `${baseUrl}/${type}/${id}/${!favorite}?${subIdType ? `&subIdType=${subIdType}` : ''}`;

      favoriteUrl = favoriteUrl.slice(-1) === '?' ? favoriteUrl.slice(0, -1) : favoriteUrl;

      if (websiteUrl && websiteUrl.length !== 0) {
        favoriteUrl = favoriteUrl.includes('?')
          ? favoriteUrl + '&websiteUrl=' + websiteUrl
          : favoriteUrl + '?websiteUrl=' + websiteUrl;
      }
    }
    if (!overrideFavorite) {
      setFavorite(!favorite);
    }
    fetch(favoriteUrl, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 400) {
          const severity = !isCavcoHomesVariant
            ? (MESSAGE_SEVERITY.WARNING as Color)
            : (MESSAGE_SEVERITY.ERROR as Color);
          if (!overrideFavorite) {
            setFavorite(favorite);
          }
          const snackBarMessageTemp = {
            message: data.message,
            severity,
          };
          setSnackBarMessage(snackBarMessageTemp);
          handleOpenSnackBar();
        } else {
          initAllFavoritesForCurrentUser(loadFavoriteUrl);
          if (callback) {
            callback(!favorite);
          }
          // postLogin();
        }
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
      });
  };

  const saveFavorite = !handleClick ? handleSaveFavorite : handleClick;

  const handleSetSuccessfulSignIn = () => {
    saveFavorite();
  };

  return (
    <>
      <SnackBarComponent />
      <SaveFavoritesOutline
        className={classNames(classes, `${favorite ? 'active' : ''}`)}
        onClick={userId ? saveFavorite : openLoginModal}
      />
      {loginModal && (
        <SignInModals
          loginModalClosed={closeLoginModal}
          setSuccessfulSignIn={handleSetSuccessfulSignIn}
          myAccountConfig={myAccountConfig}
        />
      )}
    </>
  );
};
