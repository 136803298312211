import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import '../shared/ProductList/ProductList.scss';

export function FloorPlanSearchContent(props: BrProps) {
  const { floorPlanDisclaimerLabels, retailerDisclaimerLabels } = props.component.getModels();

  const resourceBundle = floorPlanDisclaimerLabels || retailerDisclaimerLabels;

  let disclaimer = 'The disclaimer resource bundle is not connected.';
  let advertisingContent = disclaimer;
  let pricingDisclaimer = null;

  if (resourceBundle) {
    disclaimer = resourceBundle.disclaimer;
    advertisingContent = resourceBundle.advertisingContent;
    pricingDisclaimer = resourceBundle.pricingDisclaimer;
  }

  return (
    <div className="cvc-fp-search-content cvc-product-list-content">
      <div
        className="cvc-product-list-content-content"
        dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(advertisingContent) }}
      />
      <div className="cvc-product-list-content-disclaimer">
        <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(disclaimer) }} />
        {pricingDisclaimer ? (
          <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(pricingDisclaimer) }} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
