import React, { useState } from 'react';
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg';

import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/swiper.min.css';
import '../../../styles/swiper-custom.scss';

interface ImageCarouselProps {
  photoUrls: any;
  lineDrawingUrl: any;
  centered?: boolean;
}

export const ImageCarousel = (icprops: ImageCarouselProps) => {
  const { photoUrls, centered = false, lineDrawingUrl } = icprops;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const windowDimensions = useWindowDimensions();
  const allowTouchMoveCheck = !!(windowDimensions.isMobile || windowDimensions.isTablet);

  const goToNextSlide = () => {
    if (currentSlideIndex < photoUrls.length) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else if (currentSlideIndex === photoUrls.length) {
      setCurrentSlideIndex(1);
    }
  };

  const goToPrevSlide = () => {
    if (currentSlideIndex === 1) {
      setCurrentSlideIndex(photoUrls.length);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const displaySliders = (inputPhotosUrl: any, inputLineDrawingUrl: any) => {
    let items = null;
    if (inputPhotosUrl && inputPhotosUrl.length > 0) {
      items = inputPhotosUrl;
    } else if ((!inputPhotosUrl || inputPhotosUrl.length === 0) && inputLineDrawingUrl) {
      items = new Array(inputLineDrawingUrl);
    }
    if (!items) return null;

    return items.map((item: any, index: number) => {
      return (
        <SwiperSlide key={`slide-image-${index.toString()}`}>
          {allowTouchMoveCheck ? (
            <img className="swiper-lazy" alt={item.imageAltText} src={item.url} />
          ) : (
            <img className="swiper-lazy" alt={item.imageAltText} src={item.url} />
          )}
        </SwiperSlide>
      );
    });
  };

  return (
    <Swiper
      className="floorplan-card-image-carousel product-image-carousel"
      loop
      slidesPerView={1}
      allowTouchMove={allowTouchMoveCheck}
      centeredSlides={centered}
      fadeEffect={{ crossFade: true }}
      modules={[Pagination, Navigation]}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={
        photoUrls &&
        photoUrls.length > 1 && {
          type: 'fraction',
        }
      }
    >
      {displaySliders(photoUrls, lineDrawingUrl)}
      {photoUrls && photoUrls.length > 1 && (
        <>
          <ArrowRight className="swiper-button-next" onClick={() => goToNextSlide()} />
          <ArrowLeft className="swiper-button-prev" onClick={() => goToPrevSlide()} />
        </>
      )}
    </Swiper>
  );
};
