import React, { useState } from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import 'components/shared/ProductDetails/Product3DTours.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { display3DTour, getDisplayHomeDataModel } from '../utils';

function displayTitle(title: string) {
  if (!title) return '';

  return <h2 className="cvc-product-tours-title">{title}</h2>;
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  index: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="presentation"
      key={`${imageGalleryName}-tours-container-${index}`}
      className="cvc-product-tours-item-container"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
    >
      <div
        key={`${imageGalleryName}-tour-${index}`}
        className="cvc-product-tours-media-item"
        tabIndex={-1}
      >
        {item.matterportURL ? display3DTour(item) : ''}
      </div>
      <div
        key={`${imageGalleryName}-display-home-tours-item-link-${index}`}
        className="cvc-product-tours-item-link"
        tabIndex={-1}
      >
        <VideoIcon
          key={`${imageGalleryName}-display-home-tours-item-icon-${index}`}
          className="cvc-product-tours-item-icon"
        />
      </div>
    </div>
  );
}

function displayMediaAssets(
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.VIRTUAL_TOUR && mediaType !== ALBUM_MEDIA_TYPES.PANATOUR)
    return '';

  return mediaAssetItems.map((item: any, index) => {
    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      index,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function DisplayHome3DTours(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { displayHomeToursLabels, spinnerConfig } = props.component.getModels();

  const { displayHomeData: dhData } = getDisplayHomeDataModel(props.page);

  const imageGalleryName = props.component.getName();

  if (!dhData) {
    return null;
  }

  const { tourAlbum: matterportRef, panatourAlbum: panatourRef } = dhData;

  const matterportDocument = matterportRef;
  const panatourDocument = panatourRef;

  let albumId = '';
  let albumMediaType = '';
  let albumTitle = '';
  let mediaItems = null;

  if (matterportDocument) {
    albumId = matterportRef.id;
    albumTitle = matterportRef.title;
    albumMediaType = matterportRef.mediaType;
    mediaItems = matterportRef.mediaAssets;
    mediaItems = mediaItems.slice(0, 3) as [MediaAssetCompound];
    if (panatourRef && panatourRef.title) {
      albumTitle = albumTitle.concat(' & ', panatourRef.title);
    }
    if (panatourRef && panatourRef.mediaAssets) {
      mediaItems = mediaItems.concat(panatourRef.mediaAssets.slice(0, 1)) as [MediaAssetCompound];
    }
  } else if (!matterportDocument && panatourDocument) {
    albumId = panatourRef.id;
    albumTitle = panatourRef.title;
    albumMediaType = panatourRef.mediaType;
    mediaItems = panatourRef.mediaAssets;
    mediaItems = mediaItems.slice(0, 1) as [MediaAssetCompound];
  } else {
    return null;
  }

  const toursCompTitle =
    displayHomeToursLabels && displayHomeToursLabels.title
      ? displayHomeToursLabels.title
      : '3D Virtual Tours';

  const displayHomeToursClasses = classNames({
    'cvc-display-home-tours cvc-product-tours': true,
    container:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig,
  });

  const mediaAssetItems = mediaItems;
  const noTours = mediaItems.find((item) => item.matterportURL !== '') === undefined;

  if (noTours) {
    return null;
  }

  const oneItem = mediaAssetItems.length === 1;

  const displayItemsContainerClasses = classNames({
    'cvc-product-tours-items-container': true,
    'one-item': oneItem,
  });

  const spinnerColorInDarkBackground = spinnerConfig && spinnerConfig.spinner_color_dark_background;

  return (
    <div
      id={`${imageGalleryName}-${albumId}`}
      className={displayHomeToursClasses}
      key={`${imageGalleryName}-${albumId}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={albumMediaType}
        mediaAssetItems={mediaAssetItems}
        albumName={albumTitle}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerColorInDarkBackground}
      />
      {displayTitle(toursCompTitle)}
      <div className={displayItemsContainerClasses}>
        {displayMediaAssets(
          imageGalleryName,
          albumMediaType,
          mediaAssetItems,
          setIndexItemSelected,
          handleShowMediaViewer
        )}
      </div>
    </div>
  );
}
