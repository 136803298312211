import React, { FC } from 'react';
import useLocalStorage from 'use-local-storage';
import { FLOORPLAN_LOCALSTORAGE_KEY } from 'components/Header/constants';

export const LocalStorageContext = React.createContext<any>(null);

export const LocalStorageProvider: FC<React.ReactNode> = ({ children }) => {
  const localStorage = useLocalStorage<any>(FLOORPLAN_LOCALSTORAGE_KEY, '');
  return (
    <LocalStorageContext.Provider value={localStorage}>{children}</LocalStorageContext.Provider>
  );
};
