import React from 'react';

import { BrProps } from '@bloomreach/react-sdk';
import { RetailerContact } from '../RetailerContact/RetailerContact';
import { CustomerServiceForm } from '../CustomerServiceForm/CustomerServiceForm';

enum REQUEST_FORM_VARIANTS {
  RETAILER_REQUEST_FORM = 'retailerRequestForm',
  CUSTOMER_SERVICE_REQUEST_FORM = 'customerServiceRequestForm',
}

export function RequestForm(props: BrProps) {
  const { componentParameterMap } = props.component.getModels();
  const { variant } = componentParameterMap;

  switch (variant) {
    case REQUEST_FORM_VARIANTS.RETAILER_REQUEST_FORM:
      return <RetailerContact brProps={props} />;
    case REQUEST_FORM_VARIANTS.CUSTOMER_SERVICE_REQUEST_FORM:
      return <CustomerServiceForm brProps={props} />;
    default:
      return <RetailerContact brProps={props} />;
  }
}
