import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { ProductResultCard } from 'components/shared/ProductResultCard';
import './CavcoResultCardsList.scss';
import useFetch from 'components/hooks/FetchElasticSearchResults.hook';
import { findRetailerBasepath } from 'components/utils';
import {
  allPageSort,
  pageTypeDefaultSortConfigs,
} from 'components/FloorPlanResultsCH/FloorPlanResults.constants';
import { MediaModal } from 'components/MediaModal';
import { MediaModalState } from 'components/FloorPlanResultsCH/FloorPlanResultsCH';

export function CavcoResultCardsList(props: BrProps) {
  const maxCards = 6;
  const { esconfiguration, retailer, componentParameterMap } = props.component.getModels();

  const {
    title,
    listType,
    inventoryType,
  }: { title: string; listType: string; inventoryType: any } = componentParameterMap;
  let accountConfig: any;
  const esConfig: any = props?.page?.getContent(esconfiguration)?.getData();
  const esConfigMap: any = { apiKey: '', engineName: '', endpointBase: '' };
  const isRetailerPage = !!props.page.getComponent('retailer-header-content');
  const retailerBasePath = isRetailerPage && findRetailerBasepath(props?.page);
  const resultTypes = {
    MIR: 'move-in-ready-homes',
    Stock: 'display-homes',
  };

  if (isRetailerPage) {
    const { myAccountConfig } =
      props.page.getComponent('retailer-header-content', 'header-component')?.getModels() || {};
    accountConfig = myAccountConfig;
  }

  const resultType = resultTypes[inventoryType as keyof typeof resultTypes] || 'floorplans';

  const fillConfigData = (dest: any, source: any) => {
    source?.keys.forEach((element: any, index: number) => {
      dest[element] = source.messages[index];
    });
  };

  fillConfigData(esConfigMap, esConfig);

  const primaryPageType = isRetailerPage ? 'retailer' : 'main';
  const defaultSortConfig = pageTypeDefaultSortConfigs[primaryPageType][inventoryType] || [];
  const defaultSort = defaultSortConfig.length ? defaultSortConfig : allPageSort.sort.defaultValue;

  const esOptions = {
    filters: {
      all: [{ type: listType }, { retailer_ids: retailer.id }, { inventory_type: inventoryType }],
    },
    sort: defaultSort,
  };

  const { elasticSearchResponse, setElasticSearchRequest, elasticSearchLoading } =
    useFetch(esConfigMap);
  const esResult = useMemo(() => {
    return (
      elasticSearchResponse && {
        totalResults:
          elasticSearchResponse.rawInfo?.meta?.page?.total_results ||
          elasticSearchResponse.rawResults?.length,
        results: elasticSearchResponse.rawResults,
      }
    );
  }, [elasticSearchResponse]);

  useEffect(() => {
    setElasticSearchRequest(esOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useResults = esResult?.results;

  const totalResults = esResult && `(${esResult.totalResults})`;

  const [mediaModalState, setMediaModalState] = useState<MediaModalState>({
    isOpen: false,
    isVideo: false,
    mediaSrc: undefined,
    mediaTitle: undefined,
  });

  const handleDisplayMediaModal = ({ isVideo, mediaSrc, mediaTitle }: MediaModalState) => {
    setMediaModalState({
      isOpen: true,
      isVideo,
      mediaSrc,
      mediaTitle,
    });
  };

  const handleCloseMediaModal = () => {
    setMediaModalState({
      isOpen: false,
      isVideo: false,
      mediaSrc: '',
      mediaTitle: '',
    });
  };

  if (!useResults?.length) return <></>;
  return !elasticSearchLoading ? (
    <div className="cvc-results-card-list">
      <div className="section-title cavcohomes-title-component">
        <h4>
          {title} {totalResults}
        </h4>
      </div>

      <MediaModal
        show={mediaModalState.isOpen || false}
        isVideo={mediaModalState.isVideo}
        title={mediaModalState.mediaTitle || ''}
        mediaSrc={mediaModalState.mediaSrc}
        handleClose={handleCloseMediaModal}
      />

      <div className="card-list-container">
        {useResults?.length > 0 &&
          useResults.map(
            (result: any, idx: number) =>
              idx < maxCards && (
                <ProductResultCard
                  result={result}
                  handleMediaClick={handleDisplayMediaModal}
                  type="floorplan"
                  isMicroSite={isRetailerPage}
                  myAccountConfig={accountConfig}
                  key={`item_${result.id.raw}`}
                  basePath={retailerBasePath || null}
                  isRetailer={isRetailerPage}
                  retailerId={retailer?.id}
                />
              )
          )}
      </div>
      <Row>
        <a
          className="btn btn-punchout-large card-list-link"
          href={`${window.location.pathname.replace(/\/$/, '')}/${resultType}`}
        >
          View all {title}
        </a>
      </Row>
    </div>
  ) : (
    <></>
  );
}
