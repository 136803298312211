import React, { useState } from 'react';
import './SiteSearchCard.scss';
import { Link } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as LocationIcon } from '../../assets/images/cavcohomes/location.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/cavcohomes/phone-icon.svg';
import { ReactComponent as ReadMoreIcon } from '../../assets/images/cavcohomes/read-more-icon.svg';
import { formatPhoneNumber } from '../utils';

export interface SiteSearchCardProps {
  data: any;
  includeImages: boolean;
  titleLabels: any[];
}

export interface SiteSearchFloorplanCardProps {
  price: string;
  bedrooms: string;
  bathrooms: string;
  square: string;
  series: string;
  homeType: string;
  sections: string;
}

interface FloorplanAttProps {
  name: string;
  value: string;
}

export function FloorplanAttribute(props: FloorplanAttProps) {
  return (
    <div className="fp-attribute">
      <span className="att-title">{props.name}</span>
      <span>{props.value}</span>
    </div>
  );
}

export function SiteSearchFloorPlanCard(props: SiteSearchFloorplanCardProps) {
  const { price, bedrooms, bathrooms, square, series, homeType, sections } = props;
  return (
    <div className="floorplan-card-container">
      {price && <span className="start-price">{price}</span>}
      <div className="fp-facts">
        {bedrooms && <span>{bedrooms}</span>}
        {bathrooms && <span>{bathrooms}</span>}
        {square && <span>{square}</span>}
      </div>
      <div className="attributes-container">
        {series && <FloorplanAttribute name="Series:" value={series} />}
        {homeType && <FloorplanAttribute name="Home Type:" value={homeType} />}
        {sections && <FloorplanAttribute name="Sections:" value={sections} />}
      </div>
    </div>
  );
}

const getRawValue = (obj: any) => {
  return obj ? obj.raw : '';
};

export function SiteSearchCard(props: SiteSearchCardProps) {
  const [onOverClass, setOnOverClass] = useState(false);
  const { search_image, title, type, address, meta_description, url } = props.data;
  const { city, state, zip, phone } = props.data;
  const { number_of_bedrooms, number_of_bathrooms, square_foot, starting_price } = props.data;
  const { series, building_method, sections } = props.data;
  const imgValue = getRawValue(search_image);
  const titleValue = getRawValue(title);
  const typeValue = getRawValue(type);
  const addressValue = address ? `${getRawValue(address)},` : '';
  const cityValue = city ? `${getRawValue(city)},` : '';
  const stateValue = state ? `${getRawValue(state)},` : '';
  const zipValue = getRawValue(zip);
  const urlValue = getRawValue(url);
  const contentValue = getRawValue(meta_description);
  const phoneValue = formatPhoneNumber(getRawValue(phone));

  const numberOfBedrooms = getRawValue(number_of_bedrooms);
  const numberOfBathrooms = getRawValue(number_of_bathrooms);
  const squareValue = getRawValue(square_foot);
  const startingPrice = getRawValue(starting_price);
  const seriesValue = getRawValue(series);
  const buildingMethodValue = getRawValue(building_method);
  const sectionsValue = getRawValue(sections);
  const noImage = imgValue ? '' : 'noimage';
  const titleLabel = props.titleLabels[typeValue] ? props.titleLabels[typeValue] : typeValue;

  const showTitle = (docType: string) => {
    if (docType === 'floorplan' || docType === 'MIR' || docType === 'DisplayHome') {
      return false;
    }
    return true;
  };

  const handleOverSearchCard = (isHover: boolean) => {
    setOnOverClass(isHover);
  };

  return (
    <div className={classNames('site-search-container', onOverClass ? 'onHover' : '')}>
      {props.includeImages && imgValue && (
        <Link href={urlValue}>
          <div
            className="imagewrap"
            onMouseOver={() => handleOverSearchCard(true)}
            onMouseOut={() => handleOverSearchCard(false)}
            onFocus={() => handleOverSearchCard(true)}
            onBlur={() => handleOverSearchCard(false)}
          >
            <img src={imgValue} alt={titleValue} />
          </div>
        </Link>
      )}
      <div>
        {type && <h3 className={`type ${noImage}`}>{titleLabel}</h3>}
        <Link href={urlValue} className="title-link">
          <h2
            className="title"
            onMouseOver={() => handleOverSearchCard(true)}
            onMouseOut={() => handleOverSearchCard(false)}
            onFocus={() => handleOverSearchCard(true)}
            onBlur={() => handleOverSearchCard(false)}
          >
            {titleValue}
          </h2>
        </Link>
        {typeValue !== 'location' && showTitle(typeValue) && (
          <p className="content">{contentValue}</p>
        )}
        {typeValue === 'location' && typeValue && (
          <>
            <div className="address">
              <LocationIcon />
              <p className="content">{`${addressValue} ${cityValue} ${stateValue} ${zipValue}`}</p>
            </div>
            {phoneValue && (
              <div className="address">
                <PhoneIcon />
                <p className="content">{phoneValue}</p>
              </div>
            )}
          </>
        )}
        {(typeValue === 'floorplan' || typeValue === 'MIR' || typeValue === 'DisplayHome') && (
          <SiteSearchFloorPlanCard
            price={startingPrice}
            bedrooms={numberOfBedrooms}
            bathrooms={numberOfBathrooms}
            square={squareValue}
            series={seriesValue}
            homeType={buildingMethodValue}
            sections={sectionsValue}
          />
        )}
        <div className="read-more-container">
          <Link
            className="read-more"
            href={urlValue}
            onMouseOver={() => handleOverSearchCard(true)}
            onMouseOut={() => handleOverSearchCard(false)}
            onFocus={() => handleOverSearchCard(true)}
            onBlur={() => handleOverSearchCard(false)}
          >
            <u>
              Read more
              <ReadMoreIcon />
            </u>
          </Link>
        </div>
      </div>
    </div>
  );
}
