import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Element } from 'react-scroll';
import 'components/shared/ProductDetails/ProductFiles.scss';
import { ResolverLink } from 'components/shared';
import { getDisplayHomeDataModel } from 'components/utils';

function getFiles(pdfs: any) {
  return pdfs.map((pdf: any, indexPdf: number) => {
    const { id, uploadSize: fileSize, uploadFileName, fileName } = pdf;
    if (fileName === null || fileName.length === 0) return null;
    const fileSizeStr = fileSize < 1000 ? `${fileSize} KB` : `${(fileSize / 1024).toFixed(1)} MB`;

    return (
      <li key={`${id}-${indexPdf.toString()}`}>
        <ResolverLink target="_blank" href={uploadFileName}>
          {fileName}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function DownloadFiles(props: any) {
  return (
    <Element name="download" className="cvc-download-files">
      <h2>{props.title}</h2>
      <ul className="cvc-download-files-list">{getFiles(props.files)}</ul>
    </Element>
  );
}

function ValidateFileCompounds(fileCompounds: any) {
  for (let i = 0; fileCompounds && i < fileCompounds.length; i += 1) {
    const fileCompound = fileCompounds[i];
    const { fileName, uploadFileName } = fileCompound;
    if (fileName && fileName.length > 0 && uploadFileName && uploadFileName.length > 0) {
      return true;
    }
  }
  return false;
}

export function DisplayHomeFiles(props: BrProps) {
  const { floorPlanFilesLabels } = props.component.getModels();
  const { document: ref } = getDisplayHomeDataModel(props.page);
  const document = props.page.getContent(ref);
  const inventoryFiles = document && document.getData().inventoryFiles;

  let downloadableFiles;

  const { title } = floorPlanFilesLabels;

  const hasInventoryFiles = ValidateFileCompounds(inventoryFiles);

  if (!hasInventoryFiles) {
    const floorPlanRef = document && document.getData().floorPlan;
    const floorPlanDoc = props.page.getContent(floorPlanRef);
    const floorPlanFiles = floorPlanDoc && floorPlanDoc.getData().floorplanFiles;
    if (ValidateFileCompounds(floorPlanFiles)) {
      downloadableFiles = floorPlanFiles;
    } else {
      return null;
    }
  } else {
    downloadableFiles = inventoryFiles;
  }

  for (let i = 0; downloadableFiles && i < downloadableFiles.length; i += 1) {
    if (downloadableFiles[i].fileName != null && downloadableFiles[i].fileName.length > 0) {
      return <DownloadFiles title={title} files={downloadableFiles} />;
    }
  }
  return null;
}
