import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from 'components/shared';
import { KeyboardArrowDown } from '@material-ui/icons';
import Modal from 'react-bootstrap/Modal';
import './FloorPlanModalFacet.scss';
import { StyledButton } from '../../FloorPlanResultsStyledComponents';

export const FloorPlanModalFacet: React.FC<{
  label: string;
  name: string;
  show: boolean;
  handleShow: any;
}> = ({ label, name, children, show, handleShow }) => {
  // Offset Material-ui scroll lock
  // adds/removes 15px padding to fixed-position header
  const handleScrollBar = (open: boolean) => {
    const addRemove = open ? 'add' : 'remove';
    const el = document.querySelector('.cvc-header.is-fixed');
    if (el) {
      el.classList[addRemove]('scroll-lock-offset');
    }
  };

  const handleOpenFilter = () => {
    handleScrollBar(true);
    handleShow(true);
  };

  const handleCloseFilter = () => {
    handleShow(false);
    // slight hack to prevent header from jumping when material closes the modal
    setTimeout(() => {
      handleScrollBar(false);
    }, 200);
  };

  return (
    <div className="facet-item__container">
      <StyledButton
        className="cavcohomes-floorplan-list__filter-item facet-item__button"
        aria-controls={`floorplanMenu${name}`}
        aria-haspopup="true"
        variant="contained"
        disableElevation
        onClick={() => handleOpenFilter()}
        endIcon={<KeyboardArrowDown />}
      >
        {label}
      </StyledButton>
      <Modal
        show={show}
        onHide={() => handleCloseFilter()}
        className="floor-plan-facet-modal"
        dialogClassName="more-facets-panel"
      >
        <div className="floor-plan-facet-modal_close">
          <IconButton onClick={() => handleCloseFilter()}>
            <CloseIcon className="floor-plan-facet-modal_close-icon" />
          </IconButton>
        </div>
        <div>{children}</div>
      </Modal>
    </div>
  );
};
