import React from 'react';

import { BrProps } from '@bloomreach/react-sdk';
import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';

import useWindowDimensions from '../hooks/useWindowDimensions';

import { CavcoFloorPlanCard } from './ViewedHomeCards';
import './CavcoRecentlyViewedHomes.scss';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper.scss';

export function CavcoRecentlyViewedHomes(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  const { visitedHomes } = props.component.getModels();
  if (!visitedHomes?.length) return null;

  const mapFloorPlanToCard = (floorplan: any, index: number) => (
    <CavcoFloorPlanCard
      key={`viewed-home-card-${index.toString()}`}
      floorPlanDocumentData={floorplan}
      windowDimensions={windowDimensions}
      props={props}
    />
  );

  const mapFloorPlanToSlides = (floorplans = [], isThumbs = true) =>
    floorplans
      .map((item: any, index: number) => {
        const slideContainerClasses = classNames({
          'cvc-media-viewer__item': true,
          'swiper-slide-container': false,
          'swiper-slide-container-thumbs': isThumbs,
        });
        return (
          <SwiperSlide key={`slide-image-${item.url}`}>
            <div className={slideContainerClasses}>{mapFloorPlanToCard(item, index)}</div>
          </SwiperSlide>
        );
      })
      .reverse();

  const displayFloorPlanCards = (floorplans = []) => (
    <div>
      <Swiper
        className="mySwiper"
        loop={false}
        spaceBetween={floorplans.length > 1 ? 19 : 0}
        slidesPerView={windowDimensions?.isMobile ? 1.2 : 'auto'}
        centeredSlides={!!windowDimensions?.isMobile}
        slideToClickedSlide
        watchSlidesProgress
      >
        {mapFloorPlanToSlides(floorplans)}
      </Swiper>
    </div>
  );

  return (
    <div className="cavco-panel-container">
      <div className="cvc-recentlyviewhomes">
        <div className="title">
          <h2>Recently Viewed</h2>
        </div>
        {displayFloorPlanCards(visitedHomes)}
      </div>
    </div>
  );
}
