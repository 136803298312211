/*
 *
 * This component creates the metadata for the Retailer INDIVIDUAL Album page.
 * This is a Document-backed page.
 * ex url: https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/gallery/3d-virtual-tour-gallery/flood-homes-3d-gallery%5B2%5D
 *
 *
 * *********** NOT CURRENTLY DISPLAYING
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { getImageUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { getMatterportThumbImage, getYoutubeThumbImage, getRetailerData } from '../utils';
import { BuildCannonicalURL } from './CannonicalURL';

function getAlbEnums() {
  enum ALBUM_MEDIA_TYPES {
    IMAGE = 'Image',
    VIDEO = 'Video',
    VIRTUAL_TOUR = '3D',
  }
  return ALBUM_MEDIA_TYPES;
}
function getAlbumTypeString(data: AlbumData) {
  // Album Media Enums

  const ALBUM_MEDIA_TYPES = getAlbEnums();
  let albumEltType;
  if (data.mediaType === ALBUM_MEDIA_TYPES.IMAGE) {
    albumEltType = 'images';
  } else if (data.mediaType === ALBUM_MEDIA_TYPES.VIDEO) {
    albumEltType = 'videos';
  } else if (data.mediaType === ALBUM_MEDIA_TYPES.VIRTUAL_TOUR) {
    albumEltType = ' 3D tours';
  }
  return albumEltType;
}
// Album [title] displaying [mediaAsset.length] [images | videos | 3d tours]
function buildMetaDescription(data: AlbumData) {
  const metaDescription = `Album ${data.title} displaying ${
    data.mediaAsset.length
  } ${getAlbumTypeString(data)}`;

  return metaDescription;
}

// `Album, [images | videos | 3d tours]
function buildMetaKeywords(data: AlbumData) {
  return `Album, ${getAlbumTypeString(data)}`;
}

function buildMetaImage(props: BrProps, data: AlbumData) {
  const ALBUM_MEDIA_TYPES = getAlbEnums();

  if (data.mediaType === ALBUM_MEDIA_TYPES.IMAGE) {
    const imgContent: any = data.mediaAsset[0] && props.page.getContent(data.mediaAsset[0].image);
    return getImageUrl(data.mediaAsset[0], imgContent, 'large');
  }
  if (data.mediaType === ALBUM_MEDIA_TYPES.VIDEO) {
    return getYoutubeThumbImage(data.mediaAsset[0]);
  }
  if (data.mediaType === ALBUM_MEDIA_TYPES.VIRTUAL_TOUR) {
    return getMatterportThumbImage(data.mediaAsset[0]);
  }
}

function pagePropertiesForAlbData(
  props: BrProps,
  data: AlbumData,
  links: any,
  retailerData: RetailerData | null
) {
  const { metadata: cmsMetadata } = retailerData || {};

  const pageTitle = cmsMetadata?.pageTitle || `${data.title}`;

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links);
  const metaDescription = cmsMetadata?.metaDescription || buildMetaDescription(data);
  const metaKeywords = cmsMetadata?.metaKeywords || buildMetaKeywords(data);
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage = buildMetaImage(props, data);
  const imageAlt = cmsMetadata?.socialImage?.alt || '';
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata && !cmsMetadata.index ? 'noindex' : 'index';
  const followRobot = cmsMetadata && !cmsMetadata.follow ? 'nofollow' : 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;
  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}

export function PagePropertiesAlb(props: BrProps) {
  const retailerData = getRetailerData(props.page);
  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('content');
  const doc = form && form.getModels().document;
  const document = props.page.getContent(doc);
  const dm = document && document.getData<AlbumData>();
  if (!dm) {
    return null;
  }
  return pagePropertiesForAlbData(props, dm, document.getUrl(), retailerData);
}
