import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { Content } from '@bloomreach/spa-sdk';
import { ResolverLink } from 'components/shared';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES, IMAGE_SIZES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import './RetailerImageGallery.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';

function displayDescription(
  props: BrProps,
  description: string,
  galleryUrl: string,
  windowDimensions: any
) {
  if (!description) return '';

  const descriptionValue = description;
  // console.log('>>> displayDescription - description.value.length: ', description.value.length);

  let descriptionMaxLength = 299;

  if (windowDimensions.isTablet) {
    descriptionMaxLength = 265;
  }
  if (windowDimensions.isDesktop || windowDimensions.isDesktopBig) {
    descriptionMaxLength = 432;
  }
  if (windowDimensions.isDesktopExtraBig) {
    descriptionMaxLength = 650;
  }

  const readMore = () => {
    const text = document.getElementById('extra-description');
    const readMoreElements = document.getElementsByClassName('cvc-read-more');
    if (text) {
      text.style.display = 'inline';
      Array.prototype.forEach.call(readMoreElements, function hide(element) {
        element.style.display = 'none';
      });
    }
  };

  return (
    <div className="cvc-retailer-image-gallery-description">
      <span
        dangerouslySetInnerHTML={{
          __html: props.page.rewriteLinks(
            descriptionValue.length > descriptionMaxLength
              ? descriptionValue.substring(0, descriptionMaxLength)
              : descriptionValue
          ),
        }}
      />

      {descriptionValue.length > descriptionMaxLength && (
        <>
          <span
            id="extra-description"
            className="extra-description"
            dangerouslySetInnerHTML={{
              __html: props.page.rewriteLinks(descriptionValue.substring(descriptionMaxLength)),
            }}
          />
          <span className="cvc-read-more">... </span>
          <button
            type="button"
            className="cvc-retailer-image-gallery-read-more-link cvc-read-more d-inline"
            onClick={readMore}
          >
            Read More
          </button>
        </>
      )}
    </div>
  );
}

function getImage(imageGalleryName: string, item: any, imgContent: any) {
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';

  if (imgContent) {
    mediumImgPath =
      imgContent.model.medium && imgContent.model.medium._links.site.href
        ? imgContent.model.medium._links.site.href
        : null;
    largeImgPath =
      imgContent.model.large && imgContent.model.large._links.site.href
        ? imgContent.model.large._links.site.href
        : null;
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.ximage) {
    mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
  }

  return (
    <picture key={`${imageGalleryName}-${item.id}`}>
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={extraLargeImgPath} />
      <source
        media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
        srcSet={largeImgPath}
      />
      <source
        media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
        srcSet={mediumImgPath}
      />
      <img alt={item.imageAltText} src={mediumImgPath} />
    </picture>
  );
}

function displayMediaAssets(
  props: BrProps,
  isBackedPage: boolean,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  maxItems: number,
  retailerImageGalleryLabels: any,
  windowDimensions: any,
  urlToRedirect: string,
  handleShowMediaViewer = () => {},
  setIndexItemSelected: any
) {
  // if (mediaAssetItems.find((item) => item.image || item.ximage) === undefined) return '';
  let moreTextValue = 'more media in gallery';
  if (windowDimensions.isMobile) {
    if (retailerImageGalleryLabels && retailerImageGalleryLabels.more_mobile) {
      moreTextValue = retailerImageGalleryLabels.more_mobile;
    }
  }
  if (windowDimensions.isTablet) {
    if (retailerImageGalleryLabels && retailerImageGalleryLabels.more_tablet) {
      moreTextValue = retailerImageGalleryLabels.more_tablet;
    }
  }
  if (
    windowDimensions.isDesktop ||
    windowDimensions.isDesktopBig ||
    windowDimensions.isDesktopExtraBig
  ) {
    if (retailerImageGalleryLabels && retailerImageGalleryLabels.more_desktop) {
      moreTextValue = retailerImageGalleryLabels.more_desktop;
    }
  }

  const items = [...new Array(maxItems)].map(
    (item, index) =>
      mediaAssetItems[index] || {
        id: `${imageGalleryName}-empty-${index}`,
        key: `${imageGalleryName}-empty-${index}`,
      }
  );

  return (
    <>
      {items.map((item: any, index) => {
        const imgContent: any = item && props.page.getContent(item.image);

        let lastClass = '';
        if (!isBackedPage) {
          switch (index) {
            case 5:
              if (windowDimensions.isMobile && mediaAssetItems.length > 6) {
                lastClass = 'cvc-retailer-image-gallery-last-media-item-container--mobile';
              }
              if (windowDimensions.isTablet && mediaAssetItems.length > 6) {
                lastClass = 'cvc-retailer-image-gallery-last-media-item-container--tablet';
              }
              break;
            case 4:
              if (
                (windowDimensions.isDesktop ||
                  windowDimensions.isDesktopBig ||
                  windowDimensions.isDesktopExtraBig) &&
                mediaAssetItems.length > 5
              ) {
                lastClass = 'cvc-retailer-image-gallery-last-media-item-container--desktop';
              }
              break;
            default:
              break;
          }
        }

        const handleMediaItemClick = () => {
          setIndexItemSelected(Number(index));
          handleShowMediaViewer();
        };
        const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.keyCode === 32) {
            setIndexItemSelected(Number(index));
            handleShowMediaViewer();
          }
        };

        if (lastClass === '') {
          if (mediaType === ALBUM_MEDIA_TYPES.IMAGE) {
            return (
              <div
                role="presentation"
                key={`${imageGalleryName}-image-${item.id}-${index}`} //eslint-disable-line
                className="cvc-retailer-image-gallery-media-item"
                onClick={() => handleMediaItemClick()}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
                style={{ cursor: 'pointer' }}
              >
                {imgContent || item.ximage ? getImage(imageGalleryName, item, imgContent) : ''}
              </div>
            );
          }
          return <span>Missing Media Type for Album document</span>;
        }
        return (
          <div
            key={`${imageGalleryName}-album-last-media-item-container-${item.id}`}
            style={{ zIndex: items.length - index }}
            className={`cvc-retailer-image-gallery-last-media-item-container ${lastClass}`}
          >
            <div
              className="cvc-retailer-image-gallery-media-item"
              key={`${imageGalleryName}-album-last-media-item-${item.id}`}
            >
              {imgContent || item.ximage ? getImage(imageGalleryName, item, imgContent) : ''}
            </div>
            <ResolverLink
              className="cvc-retailer-image-gallery-last-media-item-text-container"
              href={urlToRedirect}
              key={`${imageGalleryName}-album-last-media-item-text-container-${item.id}`}
            >
              <div
                key={`${imageGalleryName}-album-last-media-item-text-${item.id}`}
                className="cvc-retailer-image-gallery-last-media-item-text text-capitalize"
              >
                {moreTextValue}
              </div>
            </ResolverLink>
          </div>
        );
      })}
    </>
  );
}

function showAlbumDoc(
  props: BrProps,
  document: Content,
  retailerImageGalleryDescription: any,
  retailerImageGalleryConfig: any,
  retailerImageGalleryLabels: any,
  handleCloseMediaViewer: any,
  handleShowMediaViewer: any,
  showMediaViewer: any,
  windowDimensions: any,
  indexItemSelected: any,
  setIndexItemSelected: any
) {
  const imageGalleryName = props.component.getName();
  const isBackedPage = imageGalleryName === 'content';

  const galleryUrl = window.location.pathname.endsWith('/')
    ? `${window.location.pathname}gallery`
    : `${window.location.pathname}/gallery`;

  const albumClasses = classNames({
    'cvc-retailer-image-gallery': true,
    'is-backed-page': isBackedPage,
    container: true,
  });

  const { id, title, mediaType, mediaAsset } = document.getData<AlbumData>();
  const defaultMaxItems = 6;
  const maxItemsFromResourceBundle =
    retailerImageGalleryConfig && retailerImageGalleryConfig.maxItems
      ? Number(retailerImageGalleryConfig.maxItems)
      : defaultMaxItems;

  const maxItems =
    isBackedPage && mediaAsset && mediaAsset.length > 0
      ? mediaAsset.length + 6
      : maxItemsFromResourceBundle;

  return (
    <div
      id={`${imageGalleryName}-${id}`}
      className={albumClasses}
      key={`${imageGalleryName}-${id}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={mediaAsset}
        albumName={title}
        albumLink={galleryUrl}
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
        defaultImageSize={IMAGE_SIZES.LARGE}
      />
      <Row className="cvc-retailer-image-gallery-details-container no-margin">
        <Col className="cvc-retailer-image-gallery-content-container-column no-padding">
          <div className="cvc-retailer-image-gallery-content-container">
            {displayDescription(
              props,
              retailerImageGalleryDescription,
              galleryUrl,
              windowDimensions
            )}
          </div>
        </Col>
        <Col className="cvc-retailer-image-gallery-items-container-column no-padding">
          <div className="cvc-retailer-image-gallery-items-container">
            {displayMediaAssets(
              props,
              isBackedPage,
              imageGalleryName,
              mediaType,
              mediaAsset,
              maxItems,
              retailerImageGalleryLabels,
              windowDimensions,
              galleryUrl,
              handleShowMediaViewer,
              setIndexItemSelected
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function RetailerImageGallery(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const {
    documents,
    // componentParameterMap,
    retailerImageGalleryConfig,
    retailerImageGalleryLabels,
  } = props.component.getModels();

  const document = documents && documents[0] && props.page.getContent(documents[0]);

  if (!document) {
    return null;
  }

  const { photos, featuredPhotos } = document.getData();
  if (!photos) {
    return null;
  }

  // default to the first photo album if there is not a featured photo album
  let photoAlbum = photos && props.page.getContent(photos[0]);
  if (featuredPhotos) {
    photoAlbum = featuredPhotos && props.page.getContent(featuredPhotos);
  }

  if (!photoAlbum) return null;

  const retailerDataComponent = props.page.getComponent('retailer-header-content', 'retailer-data');
  let retailerImageGalleryDescription = '';

  if (retailerDataComponent) {
    const { documents: retailerDataDocuments } = retailerDataComponent.getModels();
    const ref = retailerDataDocuments[0];
    const retailerContent: Content | undefined = ref && props.page.getContent(ref);

    if (retailerContent) {
      const { description } = retailerContent.getData<RetailerData>();

      if (description) {
        retailerImageGalleryDescription = description.value;
      }
    }
  }

  return showAlbumDoc(
    props,
    photoAlbum,
    retailerImageGalleryDescription,
    retailerImageGalleryConfig,
    retailerImageGalleryLabels,
    handleCloseMediaViewer,
    handleShowMediaViewer,
    showMediaViewer,
    windowDimensions,
    indexItemSelected,
    setIndexItemSelected
  );
}
