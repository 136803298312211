export enum HEADER_COMPONENTPARAMETERMAP_VARIANT {
  CAVCOHOMES = 'Cavcohomes Header',
  FAIRMONT = 'Fairmont Homes Header',
  PARK_MODEL = 'Park Model Header',
  RETAILER = 'Retailer Microsite Header',
  BUILDINGCENTER = 'Building Center Microsite Header',
  CAVCOHOMES_RETAILER = 'Cavcohomes Retailer Microsite Header',
  CAVCO_DOT_COM_HEADER = 'Cavcodotcom Header',
  CHARIOTEAGLE = 'Chariot Eagle Header',
  CHARIOTEAGLE_PARK = 'Chariot Eagle Park Header',
  CAVCO_COMMUNITIES = 'Cavco Communities Header',
}

export enum HEADER_CONTENT_TYPE {
  FAIRMONT = 'header-content',
  PARK_MODEL = 'parkmodel-header-content',
  RETAILER = 'retailer-header-content',
  CAVCOHOMES_RETAILER = 'retailer-header-content',
  CAVCO_DOT_COM = 'header-content',
}

export enum PAGE_VARIANT {
  CAVCOHOMES = 'is-cavcohomes',
  FAIRMONT = 'is-std',
  PARK_MODEL = 'is-park-model',
  RETAILER = 'is-retailer',
  BUILDINGCENTER = 'is-building-center',
  CAVCOHOMES_RETAILER = 'is-cavcohomes-retailer',
  CAVCO_DOT_COM = 'is-cavco-dot-com is-std',
}

export enum FOOTER_COMPONENTPARAMETERMAP_VARIANT {
  CAVCOHOMES = 'cavcohomes',
  FAIRMONT = 'standard',
  PARK_MODEL = 'parkModel',
  RETAILER = 'Retailer Microsite Footer',
  CAVCOHOMES_RETAILER = 'Cavcohomes Retailer Microsite Footer',
}

export enum ALBUM_MEDIA_TYPES {
  IMAGE = 'Image',
  VIDEO = 'Video',
  VIRTUAL_TOUR = '3D',
  LINE_DRAWING = 'LineDrawing',
  PANATOUR = 'Panatour',
}

export enum SCREEN_SIZES {
  MOBILE = 0,
  TABLET = 768,
  TABLET_MAX_WIDTH = 1023,
  DESKTOP = 1024,
  DESKTOP_MAX_WIDTH = 1365,
  DESKTOP_BIG = 1366,
  DESKTOP_BIG_MAX_WIDTH = 1919,
  DESKTOP_EXTRA_BIG = 1920,
}

export enum RETAILER_TYPE {
  STANDARD = 'retailerStandard',
  PARKMODEL = 'retailerParkModel',
}

export enum IMAGE_SIZES {
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extraLarge',
}

export enum OPERATIVE_SYSTEM {
  WINDOWS_OS = 'Windows OS',
  MAC_OS = 'MacOS',
  UNIX_OS = 'UNIX OS',
  LINUX_OS = 'Linux OS',
}

export enum ENVIRONMENT {
  LOCAL = 'http://localhost:3000',
  DEV = 'https://dev.cavco.bloomreach.cloud',
}

export enum PAGE_TYPES {
  STD = 'std',
  PM = 'pm',
  STD_RETAILER = 'std-retailer',
  PM_RETAILER = 'pm-retailer',
}

export enum BLOOMREACH_CHANNEL_NAMES {
  FAIRMONT_HOMES = 'fairmont',
  FRIENDSHIP_HOMES_MN = 'friendship',
  FLEETWOOD_HOMES = 'fleetwoodhomes',
  CAVCO_HOMES = 'cavcohomes',
  CAVCO_DOT_COM = 'cavcodotcom',
  PALM_HARBOR = 'palmharbor',
  CHARIOTEAGLE = 'charioteagle',
  SOLITAIRE_HOMES = 'solitairehomes',
  CAVCO_COMMUNITIES = 'cavcocommunities',
}

export enum SITE_NAMES {
  FAIRMONT_HOMES = 'fairmont',
  FRIENDSHIP_HOMES_MN = 'friendshiphomesmn',
  FLEETWOOD_HOMES = 'fleetwoodhomes',
  CAVCO_HOMES = 'cavcohomes',
  CAVCO_DOT_COM = 'cavcodotcom',
  PALM_HARBOR = 'palmharbor',
  CHARIOTEAGLE = 'charioteagle',
  SOLITAIRE_HOMES = 'solitairehomes',
  CAVCO_COMMUNITIES = 'cavcocommunities',
}

export enum BUILDING_METHOD_KEY {
  MANUFACTURED = '1.0000000131',
  MODULAR = '1.0000000132',
  PARK_MODEL = '2.0000001040',
}

export enum BUILDING_METHOD_VALUE {
  MANUFACTURED = 'Manufactured',
  MODULAR = 'Modular',
  PARK_MODEL = 'Park Model',
}

export enum BUILDING_METHOD_DISPLAY_VALUE {
  MANUFACTURED = 'Manufactured Homes',
  MODULAR = 'Modular Homes',
  PARK_MODEL = 'Park Models',
}

export enum GoogleMapPositions {
  BOTTOM = 11,
  BOTTOM_CENTER = 11,
  BOTTOM_LEFT = 10,
  BOTTOM_RIGHT = 12,
  CENTER = 13,
  LEFT = 5,
  LEFT_BOTTOM = 6,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  RIGHT = 7,
  RIGHT_BOTTOM = 9,
  RIGHT_CENTER = 8,
  RIGHT_TOP = 7,
  TOP = 2,
  TOP_CENTER = 2,
  TOP_LEFT = 1,
  TOP_RIGHT = 3,
}

export enum SEARCH_TYPES {
  FLOORPLAN = 'floorPlan',
  RETAILER = 'retailer',
  DEALERS = 'dealers',
  MIR = 'moveInReady',
  COMMUNITY = 'community',
}
