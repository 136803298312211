export function setCookie(cname: string, cvalue: string, exdays: any) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function deleteCookie(cname: string, cvalue: string) {
  const expires = `expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function deleteAllCookie() {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    document.cookie = `${ca[i]};expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  }
}

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
