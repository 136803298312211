import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { SearchAgainComp } from './SearchAgainComp';

export const FloorPlanSearch = ({
  locationNameSearched,
  totalFloorPlanCount,
  apiKey,
  internalLoc,
  searchButtonText = 'Search Again',
  isBuildingCenterOnly = false,
  searchedNameDisplay,
  isSearchPage,
  bcDocument,
  handleSearch,
}: any) => {
  const searched =
    bcDocument && isBuildingCenterOnly
      ? `${bcDocument.dba} - ${bcDocument.city}, ${bcDocument.state}`
      : searchedNameDisplay || locationNameSearched;
  let resultsPreposition = 'In';
  const searchParameters = useLocation().search;

  if (queryString.parse(searchParameters).latitude) {
    resultsPreposition = 'Near';
  }

  const preposition = isBuildingCenterOnly ? 'Built by' : resultsPreposition;
  const compoundLocationNameSearched = searched ? `${preposition} ${searched}` : '';
  const locationsAvailableText = `${totalFloorPlanCount} Floor Plans Available `;

  return (
    isSearchPage && (
      <div
        className={classNames(
          'cvc-floorplan-list-search-filters-container',
          'cvc-product-list-search-filters-container'
        )}
      >
        <h1 className="search-filter-locations-text-container">
          <span className="search-filter-locations-availables">{locationsAvailableText}</span>
          <span className="search-filter-location-searched">{compoundLocationNameSearched}</span>
        </h1>
        <SearchAgainComp
          {...{
            apiKey,
            locationNameSearched,
            internalLoc,
            searchButtonText,
            handleSearch,
          }}
        />
      </div>
    )
  );
};
