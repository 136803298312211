import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
} from 'react-share';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { getCurrentSiteName } from 'components/utils';
import { ReactComponent as CustomFacebookIcon } from '../../assets/images/facebook_icon.svg';
import { ReactComponent as CustomTwitterIcon } from '../../assets/images/twitter_icon.svg';
import { ReactComponent as CustomPinterestIcon } from '../../assets/images/pinterest_icon.svg';
import { ReactComponent as CustomEmailIcon } from '../../assets/images/email_icon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/share-this-page.svg';

import './SharePage.scss';

const currentSite = getCurrentSiteName();

function getIcon(customIcon: any, resourceIcon: any, name: string) {
  if (resourceIcon === undefined || resourceIcon.length === 0) {
    return customIcon;
  }
  return <img src={resourceIcon} alt={`${name} icon`} />;
}

export function SharePage(props: any) {
  const [shareModal, setShareModal] = useState(false);
  const closeShareModal = () => {
    setShareModal(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  const openShareModal = () => {
    setShareModal(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };

  const windowDimensions = useWindowDimensions();
  const { icons, textClass = '', classes = '', UseIcon, isCvh } = props;

  const link = window.location.href;
  return (
    <>
      <button
        className={`cvc-breadcrumb-page-cta-button ${classes}`}
        type="button"
        onClick={openShareModal}
        id="share-button"
      >
        {UseIcon ? (
          <UseIcon alt="share button" />
        ) : (
          <ShareIcon className="cvc-breadcrumb-page-cta-icon" alt="share button" />
        )}
        {!isCvh && (
          <div className={`text ${textClass ?? ''}`}>
            Share <span className="label-secondary">This {props.list ? 'List' : 'Page'}</span>
          </div>
        )}
      </button>

      <Modal
        className="cvc-share-page-modal"
        show={shareModal}
        onHide={closeShareModal}
        size="lg"
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body className="cvc-share-page-modal-content pb-5 text-center">
          <h2>Share this with your favorite network</h2>

          <div
            className={`cvc-share-page-icons d-flex align-items-center justify-content-center py-3 py-md-5  ${currentSite}`}
          >
            <div className="cvc-share-page-icon">
              <FacebookShareButton quote={props.ogDescription} url={link}>
                {getIcon(<CustomFacebookIcon />, icons && icons.facebook, 'Facebook')}
              </FacebookShareButton>
            </div>
            <div className="cvc-share-page-icon">
              <TwitterShareButton title={props.ogDescription} url={link}>
                {getIcon(<CustomTwitterIcon />, icons && icons.twitter, 'Twitter')}
              </TwitterShareButton>
            </div>
            <div className="cvc-share-page-icon">
              <PinterestShareButton
                media={props.ogImage}
                description={props.ogDescription}
                url={link}
              >
                {getIcon(<CustomPinterestIcon />, icons && icons.pinterest, 'Pinterest')}
              </PinterestShareButton>
            </div>
            <div className="cvc-share-page-icon">
              <EmailShareButton
                subject="Look what I found"
                body="Hello! I just found this and thought you might be interested."
                url={link}
              >
                {getIcon(<CustomEmailIcon />, icons && icons.email, 'Email')}
              </EmailShareButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
