import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoViewedHomeCards.scss';
import { ReactComponent as ComingSoonCrossStitchPicture } from '../../../assets/images/coming-soon-cross-stitch-picture.svg';

interface FloorPlanCardProps {
  floorPlanDocumentData: any;
  windowDimensions: any;
  props: BrProps;
  className?: string;
  hideHeart?: boolean;
}

export function CavcoFloorPlanCard(floorPlanCardProps: FloorPlanCardProps) {
  const {
    id,
    url,
    modelName,
    modelNumber,
    photoUrls,
    lineDrawingUrl: firstLineDrawingImage,
    numBedrooms,
    numBathrooms,
    squareFootage,
    startingPrice,
  } = floorPlanCardProps.floorPlanDocumentData;

  const floorPlanUrl = url;

  let imageDefault = null;
  let hasPhoto = false;

  if (photoUrls && photoUrls.length) {
    imageDefault = photoUrls[0]; // eslint-disable-line
    hasPhoto = true;
  }

  if (!hasPhoto && firstLineDrawingImage) {
    imageDefault = firstLineDrawingImage;
  }

  const floorPlanTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;
  return (
    <div id={id} className="recent-floorplan-container">
      <a href={floorPlanUrl}>
        {imageDefault ? (
          <img src={imageDefault?.url} alt={imageDefault?.alt} />
        ) : (
          <div className="card-no-photo">
            <ComingSoonCrossStitchPicture title="image of home coming soon" />
          </div>
        )}
      </a>
      <div className="card-info">
        <a href={floorPlanUrl}>
          <p className="price">{floorPlanTitle}</p>
        </a>
        <span>{startingPrice}</span>
        <div className="floorplan-detailinfo">
          {numBedrooms && <span className="floorplan-datavalue">{numBedrooms} bd</span>}
          {numBathrooms && <span className="floorplan-datavalue">{numBathrooms} ba</span>}
          {squareFootage && <span className="floorplan-datavalue">{squareFootage} sqft</span>}
        </div>
      </div>
    </div>
  );
}
