import { useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getCountryByGeocode } from '../utils';

export default function useGeocodeSearchDataHook() {
  const [geocodeResponse, setGeocodeResponse] = useState<any>(null);
  const [geocodeRequest, setGeocodeRequest] = useState<any>(null);

  const [geocodeError, setGeocodeError] = useState<any>(null);

  useEffect(() => {
    // eslint-disable-next-line func-names
    (async function () {
      try {
        const response = await geocodeByAddress(geocodeRequest);
        const res = response[0];
        const place: any = await geocodeByPlaceId(res.place_id);
        const { lat, lng }: any = await getLatLng(response[0]);
        const { formatted_address } = place[0];
        const data = {
          lat,
          lng,
          address: formatted_address,
          country: getCountryByGeocode(res),
        };

        setGeocodeResponse({
          isError: false,
          ...data,
        });
      } catch (err) {
        setGeocodeError({
          isError: true,
          error: err,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocodeRequest]);

  return {
    geocodeResponse,
    geocodeError,
    setGeocodeRequest,
  };
}
