import React, { useEffect, useState } from 'react';
import { getCookie } from 'components/hooks/cookie';
import { SignInModals } from 'components/MyAccount/SignInModals';
import SnackBar, {
  MESSAGE_SEVERITY,
  initSnackBarMessage,
  SnackBarMessage,
} from 'components/shared/SnackBar/SnackBar';
import { initAllFavoritesForCurrentUser, isFavoriteForUser } from 'components/utils';
import { LoginStatus, loginSubject$ } from 'components/MyAccount/LoginStatus';
import { ReactComponent as StarIcon } from '../../../../assets/images/save-favorites-star-icon.svg';
import './RetailerFavoriteIcon.scss';

export function RetailerFavoriteIcon({ id, baseUrl, loadFavoriteUrl, myAccountConfig }: any) {
  const [favorite, setFavorite] = useState(isFavoriteForUser(id));
  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);
  const [userId, setUserId] = useState(getCookie('cavcouserid'));

  useEffect(() => {
    const subscription = loginSubject$.subscribe((loginStatus: any) => {
      if (loginStatus) {
        setUserId(getCookie('cavcouserid'));
        if (loginStatus === LoginStatus.loggedOut) {
          setFavorite(false);
        } else if (loginStatus === LoginStatus.loggedIn) {
          setFavorite(isFavoriteForUser(id));
          setTimeout(() => {
            setFavorite(isFavoriteForUser(id));
          }, 2000);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  const [snackBarMessage, setSnackBarMessage] = useState(initSnackBarMessage);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const saveFavorite = async () => {
    const favoriteUrl = `${baseUrl}/our_retailers/${id}/${!favorite}`;
    setFavorite(!favorite);
    fetch(favoriteUrl, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 400 || data?.status === 500) {
          setFavorite(favorite);
          const snackBarMessageTemp: SnackBarMessage = {
            message: data.message,
            severity: MESSAGE_SEVERITY.WARNING,
          };
          setSnackBarMessage(snackBarMessageTemp);
          handleOpenSnackBar();
        } else {
          initAllFavoritesForCurrentUser(loadFavoriteUrl);
          // postLogin();
        }
      })
      .catch(() => {
        setFavorite(favorite);
        const snackBarMessageTemp: SnackBarMessage = {
          message:
            'There is no Location for this resourceId. Please pass a valid Location resourceId',
          severity: MESSAGE_SEVERITY.WARNING,
        };
        setSnackBarMessage(snackBarMessageTemp);
        handleOpenSnackBar();
      });
  };

  const handleSetSuccessfulSignIn = () => {
    saveFavorite();
  };

  return (
    <>
      <SnackBar
        openSnackBar={openSnackBar}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <button
        type="button"
        className="cvc-retailer-card-save-button d-flex"
        onClick={userId ? saveFavorite : openLoginModal}
      >
        <StarIcon className={`cvc-icon--save-star${favorite ? '-active' : ''}`} />
        <span className={`cvc-retailer-card-save-button-label label${favorite ? '-active' : ''}`}>
          {favorite ? 'My Favorite' : 'Add To My Favorites'}
        </span>
      </button>
      {loginModal && (
        <SignInModals
          loginModalClosed={closeLoginModal}
          setSuccessfulSignIn={handleSetSuccessfulSignIn}
          myAccountConfig={myAccountConfig}
        />
      )}
    </>
  );
}
