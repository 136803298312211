import React from 'react';
import { List, ListItem } from '@material-ui/core';
import cn from 'classnames';

export type CHAccountLinkName = 'settings' | 'favorites';

interface CHAccountNavProps {
  handleNavItemClick: (name: CHAccountLinkName) => void;
  activeLink?: CHAccountLinkName;
}

export const CHAccountNav: React.FC<CHAccountNavProps> = ({ handleNavItemClick, activeLink }) => {
  return (
    <div className="ch-my-account__nav">
      <List className="ch-my-account__nav-list">
        <ListItem
          button
          className={cn('ch-my-account__nav-list-item', { active: activeLink === 'favorites' })}
          onClick={() => handleNavItemClick('favorites')}
        >
          My Favorites
        </ListItem>
        <ListItem
          button
          className={cn('ch-my-account__nav-list-item', { active: activeLink === 'settings' })}
          onClick={() => handleNavItemClick('settings')}
        >
          My Settings
        </ListItem>
      </List>
    </div>
  );
};
