import React from 'react';
import { Container } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { Element } from 'react-scroll';
import { AlbumFiltered } from '../Album/Album';

export function RetailerGallery(props: BrProps) {
  const { documents } = props.component.getModels();

  return (
    <Element id="retailer-gallery" key="retailer-gallery" name="gallery">
      <Container id="retailer-gallery-container" key="retailer-gallery-container">
        {documents.map((album: any, index: number) => (
          <div id={`content-${index.toString()}`} key={`content-${index.toString()}`}>
            <AlbumFiltered key={`albumfiltered-${index.toString()}`} props={props} index={index} />
          </div>
        ))}
      </Container>
    </Element>
  );
}
