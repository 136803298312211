import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';
import { getRetailerData, buildCmsOgImage } from '../utils';

function buildMetaImage(props: BrProps, photos: any) {
  let ogImage = '';
  const photosDocument = photos && photos[0] && props.page.getContent(photos[0]);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, photos[0]);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function pagePropertiesForRtlData(props: BrProps, data: RetailerData, links: any) {
  const { metadata: cmsMetadata } = data;

  const pageTitle =
    cmsMetadata?.pageTitle ||
    `Pictures of Manufactured Homes in ${data.city}, ${data.state} | ${data.dba}, a Cavco Company`;

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links, true);
  const metaDescription =
    cmsMetadata?.metaDescription ||
    `Visit cavcohomes.com and gain inspiration from reviewing photos of some of our manufactured homes at ${data.dba} of ${data.city}, ${data.state}.`;
  const metaKeywords = cmsMetadata?.metaKeywords || '';
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage =
    buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data.photos);
  const imageAlt = cmsMetadata?.socialImage?.alt || '';
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata && !cmsMetadata.index ? 'noindex' : 'index';
  const followRobot = cmsMetadata && !cmsMetadata.follow ? 'nofollow' : 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
export function CavcoPagePropertiesRtlGallery(props: BrProps) {
  const dm = getRetailerData(props.page);

  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm.retailerPath);
}
