import React, { useEffect, useRef, useState } from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';

import './FloorPlanFacet.scss';
import { StyledButton, StyledMenu, StyledMenuItem } from '../../FloorPlanResultsStyledComponents';

export const FloorPlanFacet = ({ name, label, closeMenu, disabled, children }: any) => {
  const anchorEl = useRef<any>(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleOpenFilter = (event: any) => {
    setIsOpened(true);
    anchorEl.current = event.currentTarget;
  };

  const handleCloseFilter = () => {
    setIsOpened(false);
    anchorEl.current = null;
  };

  useEffect(() => {
    handleCloseFilter();
  }, [closeMenu]);

  // Offset Material-ui scroll lock
  // adds/removes 15px padding to fixed-position header
  useEffect(() => {
    const addRemove = isOpened ? 'add' : 'remove';
    const el = document.querySelector('.cvc-header.is-fixed');
    if (el) {
      el.classList[addRemove]('scroll-lock-offset');
    }
  }, [isOpened]);

  return (
    <div className="facet-item__container">
      <StyledButton
        className="cavcohomes-floorplan-list__filter-item facet-item__button"
        aria-controls={`floorplanMenu${name}`}
        aria-haspopup="true"
        variant="contained"
        disableElevation
        disabled={disabled}
        onClick={handleOpenFilter}
        endIcon={<KeyboardArrowDown />}
      >
        {label}
      </StyledButton>
      <StyledMenu
        className="facet-panel-container"
        id={`floorplanMenu${name}`}
        anchorEl={anchorEl.current}
        keepMounted
        open={isOpened}
        onClose={handleCloseFilter}
      >
        <StyledMenuItem className={`facet-panel facet-panel--${name}`} disableRipple>
          {children}
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};
