import React, { FunctionComponent } from 'react';
import { BrComponent } from '@bloomreach/react-sdk';
import './CavcohomesSingleColumn.scss';

const CavcohomesSingleColumn: FunctionComponent = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <BrComponent path="header-content" />

    <main
      className="cvc-main"
      style={{
        width: '100%',
        flex: '1 1 auto',
      }}
    >
      <section className="cvc-main__section cvc-main__section-main" id="main">
        <div className="drop-zone-1">
          <BrComponent path="drop-zone-1" />
          <BrComponent path="main" />
        </div>
        <div className="drop-zone-2">
          <BrComponent path="drop-zone-2" />
        </div>
        <div className="drop-zone-3">
          <BrComponent path="drop-zone-3" />
        </div>
      </section>
    </main>
    <BrComponent path="footer-content" />
  </div>
);

export default CavcohomesSingleColumn;
