import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Redirect, Route, Switch } from 'react-router-dom';

import { PageLoadScrollToTop } from 'components/shared';

import { App } from 'components/App/App';
import 'styles/styles.scss';
import 'components/App/App.scss';

import { createBrowserHistory } from 'history';
import { getCookie } from 'components/hooks/cookie';
import { TagManager } from 'components/utils';

const customHistory = createBrowserHistory();
// eslint-disable-next-line
// @ts-ignore
window.customHistory = customHistory;

const sites = process.env.REACT_APP_SITE_NAMES || '';
if (sites) {
  let site = '';
  const locationHref = window.location.href;

  if (locationHref.includes('www.cavco.com')) {
    site = 'cavcodotcom';
  } else {
    sites.split(',').forEach((d) => {
      if (locationHref.includes(d)) {
        site = d;
      }
    });
  }

  let gtmId = '';

  if (site) {
    gtmId = process.env[`REACT_APP_GTM_ID_${site.toLocaleUpperCase()}`] || '';
  } else if (
    process.env.REACT_APP_BLOOMREACH_SITE &&
    locationHref.includes(process.env.REACT_APP_BLOOMREACH_SITE)
  ) {
    gtmId = process.env.REACT_APP_GTM_ID || '';
  }

  if (gtmId) {
    const enabledDomains = process.env.REACT_APP_ENABLED_DOMAINS;
    if (enabledDomains) {
      const domains = enabledDomains.split(',');
      for (let i = 0; i < domains.length; i += 1) {
        if (window.location.origin.includes(domains[i])) {
          TagManager.initialize({ gtmId });
          break;
        }
      }
    }
  }
}

const pathName = customHistory.location.pathname.toLowerCase();
const isMyAccountPath = pathName.includes('/my-account');
if (isMyAccountPath) {
  const userId = getCookie('cavcouserid');
  if (!userId) {
    const basePath = pathName.substring(0, pathName.indexOf('my-account'));
    customHistory.push(basePath);
  }
}

ReactDOM.render(
  <Router history={customHistory}>
    <PageLoadScrollToTop />
    <Switch>
      <Route path="/(.*)" component={App} />
      <Redirect to="/" />
    </Switch>
  </Router>,
  document.getElementById('root')
);
