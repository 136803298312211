/*
 * Copyright 2019 Hippo B.V. (http://www.onehippo.com)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
// import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { Container } from 'react-bootstrap';
import { ResolverLink } from 'components/shared';
import { MenuFooter } from '../Menu/Menu';
import { FOOTER_COMPONENTPARAMETERMAP_VARIANT, PAGE_VARIANT } from '../enums';
import './Footer.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getRetailerData, getUrlFromImage } from '../utils';

function displayTermsAndConditions(props: BrProps, termsAndConditions: any) {
  if (!termsAndConditions) return '';

  return (
    <div
      className="cvc-footer-terms-and-conditions-link"
      dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(termsAndConditions.value) }}
    />
  );
}

function displayLogo(props: BrProps, logoImage: ImageLinkCompound) {
  const imgContent: any = logoImage && props.page.getContent(logoImage.image);
  if (!imgContent) return '';

  return (
    <ResolverLink
      key={logoImage.id}
      href={logoImage.destinationUrl}
      target={logoImage.newWindow ? '_blank' : '_self'}
    >
      <img
        className="cvc-footer-logo-image svg"
        src={getUrlFromImage(imgContent)}
        alt={logoImage.imageAltText}
      />
    </ResolverLink>
  );
}

function displaySocialMediaImages(props: BrProps, socialMediaImages: [ImageLinkCompound]) {
  if (!socialMediaImages) return '';

  if (socialMediaImages.length > 0) {
    return (
      <div
        key="cvc-footer-social-media-logos-container"
        className="cvc-footer-social-media-logos-container"
      >
        {socialMediaImages.map((mediaImage: any, indexMediaImage: number) => {
          const imgContent: any = mediaImage && props.page.getContent(mediaImage.image);
          if (!imgContent) return '';

          return (
            <a
              key={`media-image-link-${indexMediaImage.toString()}`}
              href={mediaImage.destinationUrl}
              target={mediaImage.newWindow ? '_blank' : '_self'}
              rel="noreferrer"
            >
              <img
                key={`media-image-img-${indexMediaImage.toString()}`}
                className="cvc-footer-social-media-image"
                src={getUrlFromImage(imgContent)}
                alt={mediaImage.imageAltText}
              />
            </a>
          );
        })}
      </div>
    );
  }
}

function getVariant(variant: string): string {
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.PARK_MODEL) {
    return PAGE_VARIANT.PARK_MODEL;
  }
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.RETAILER) {
    return PAGE_VARIANT.RETAILER;
  }
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES) {
    return PAGE_VARIANT.CAVCOHOMES;
  }

  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES_RETAILER) {
    return PAGE_VARIANT.CAVCOHOMES_RETAILER;
  }

  return PAGE_VARIANT.FAIRMONT;
}

function displayNavLinks(
  footerVariant: string,
  windowDimensions: any,
  extraMobileMenuItems: any[]
) {
  if (footerVariant === PAGE_VARIANT.PARK_MODEL) {
    return MenuFooter('parkmodel-footer-content', windowDimensions);
  }
  if (
    footerVariant === PAGE_VARIANT.RETAILER ||
    footerVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER
  ) {
    return MenuFooter('retailer-footer-content', windowDimensions, extraMobileMenuItems);
  }

  return MenuFooter('footer-content', windowDimensions);
}

export function Footer(props: BrProps) {
  // const location = useLocation();
  // const { pathname } = location;
  const {
    document: documentRef,
    componentParameterMap,
    footerLabels,
  } = props.component.getModels();
  const footerVariant = getVariant(componentParameterMap.variant);
  const document = documentRef ? props.page.getContent(documentRef) : undefined;
  const windowDimensions = useWindowDimensions();
  const isDetailPage = componentParameterMap?.template === 'cavco-floorplans-detail';

  if (!document) {
    return <div>{props.component.getModels().footer}No footer document</div>;
  }

  const {
    logoImage1,
    logoImage2,
    socialMedia,
    termsAndConditions1,
    termsAndConditions2,
    termsAndConditions3,
    adminLoginText,
    adminLoginLink,
    manufacturerName,
    tdhcaLogo,
  } = document.getData<FooterData>();

  let retailerData = null;
  if (footerVariant === PAGE_VARIANT.RETAILER || PAGE_VARIANT.CAVCOHOMES_RETAILER) {
    retailerData = getRetailerData(props.page);
  }

  const adminLoginTextVal = adminLoginText || 'Admin Login';
  const adminLoginLinkVal = adminLoginLink || window.location.href;
  const extraMobileMenuItems = [{ text: adminLoginTextVal, link: adminLoginLinkVal }];
  let retailerLabel = retailerData?.community
    ? 'is an official community of '
    : 'is an official retailer of ';
  if (footerLabels) {
    retailerLabel = retailerData?.community ? footerLabels.isCommunity : footerLabels.isRetailer;
  }

  const isTexasRetailer = retailerData && retailerData.state.toLocaleLowerCase() === 'tx';
  const tdhcaLogoImage: any = isTexasRetailer && tdhcaLogo && props.page.getContent(tdhcaLogo);

  const RetailerBrandsLabel = () => {
    return (
      <span>
        {' '}
        <span> {footerLabels?.retailer_of}</span>{' '}
        <a href={footerLabels?.retailer_linked_link}>
          <span className="brandLink"> {footerLabels?.retailer_linked}</span>
        </a>
      </span>
    );
  };

  return (
    <footer
      data-elastic-exclude
      className={classNames(
        'cvc-footer',
        `${footerVariant}`,
        `${isDetailPage ? 'detail-template' : ''}`
      )}
    >
      <Container>
        <div className="cvc-footer-row cvc-footer-logos-nav-container">
          <div className="cvc-footer-col cvc-footer-images-social-logos-container">
            <div className={`cvc-footer-logo-images-container ${logoImage2 && 'logo-2'}`}>
              {displayLogo(props, logoImage1)}
              {displayLogo(props, logoImage2)}
            </div>
            {displaySocialMediaImages(props, socialMedia)}
          </div>
          <div className="cvc-footer-col cvc-footer-nav-container">
            {footerVariant === PAGE_VARIANT.RETAILER && (
              <div className="cvc-footer-retailer-text-container">
                <span className="cvc-footer-retailer-name">
                  {retailerData?.name || retailerData?.displayName}
                </span>
                <span className="cvc-footer-retailer-description">
                  {' '}
                  {retailerLabel}
                  {manufacturerName && ` ${manufacturerName}`}
                </span>
              </div>
            )}
            {footerVariant === PAGE_VARIANT.CAVCOHOMES_RETAILER && (
              <div className="cvc-footer-retailer-text-container">
                <span className="cvc-footer-retailer-name">{retailerData?.displayName}</span>
                <span className="cvc-footer-retailer-description">
                  {' '}
                  {retailerLabel} <RetailerBrandsLabel />
                </span>
              </div>
            )}
            {displayNavLinks(footerVariant, windowDimensions, extraMobileMenuItems)}
          </div>
        </div>
        <div className="cvc-footer-bottom">
          <hr className="cvc-footer-horizontal-line" />
          <div className="cvc-footer-admin-and-terms-container">
            {footerVariant === PAGE_VARIANT.RETAILER ? (
              <div className="cvc-footer-retailer-admin-login">
                <ResolverLink href={adminLoginLinkVal}>{adminLoginTextVal}</ResolverLink>
              </div>
            ) : (
              ''
            )}
            <div className="cvc-footer-terms-and-conditions">
              {termsAndConditions1 && displayTermsAndConditions(props, termsAndConditions1)}
              {termsAndConditions2 && displayTermsAndConditions(props, termsAndConditions2)}
              {termsAndConditions3 && displayTermsAndConditions(props, termsAndConditions3)}
            </div>
          </div>
        </div>
        {isTexasRetailer && tdhcaLogoImage && (
          <div className="cvc-footer-tdhca-logo">
            <ResolverLink
              href="https://www.tdhca.texas.gov/mh"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="cvc-footer-logo-image svg"
                src={getUrlFromImage(tdhcaLogoImage)}
                alt="Licensed and regulated by the Texas Department of Housing and Community Affairs"
              />
            </ResolverLink>
          </div>
        )}
      </Container>
    </footer>
  );
}
