import React, { useState } from 'react';

import classNames from 'classnames';

import { Card } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { Content } from '@bloomreach/spa-sdk';

import { ResolverLink } from 'components/shared';

import { MediaViewer } from 'components/MediaViewer/MediaViewer';
import { ALBUM_MEDIA_TYPES } from 'components/enums';

import { FavoriteIcon, VirtualIcon, VideoIcon } from 'components/shared/Icons';
import SiteGlobalSwitches from 'components/utils';

import { ImageCarousel } from '../../shared/ImageCarousel/ImageCarousel';

interface DisplayHomeCardProps {
  key: string;
  displayHomeDocumentData: any;
  windowDimensions: any;
  props: BrProps;
}

function getAlbumPropertiesFromDocumentRef(documentRef: any, props: any, mediaAssetsArray: any) {
  const document: Content = documentRef && props.page.getContent(documentRef);

  if (!document) {
    if (documentRef && documentRef.mediaAssets && documentRef.title) {
      return {
        title: documentRef.title,
        mediaAsset: documentRef.mediaAssets,
        albumLink: '',
      };
    } else {
      return {
        title: '',
        mediaAsset: mediaAssetsArray,
        albumLink: '',
      };
    }
  }

  const { title, mediaAsset } = document.getData<AlbumData>();

  return {
    title,
    mediaAsset,
    albumLink: '',
  };
}

function formatDisplayHomeUrl(documentUrl: any) {
  const documentUrlSegment = documentUrl.slice(documentUrl.lastIndexOf('/'));
  const windowPathNameWithoutSlash = window.location.pathname.replace(/\/$/, '');

  const displayHomeSearchPage =
    windowPathNameWithoutSlash.slice(windowPathNameWithoutSlash.lastIndexOf('/')).toLowerCase() ===
    '/display-homes';

  const newDisplayHomeUrl = displayHomeSearchPage
    ? `${windowPathNameWithoutSlash}${documentUrlSegment}`
    : `${windowPathNameWithoutSlash}/display-homes${documentUrlSegment}`;

  return newDisplayHomeUrl;
}

export const DisplayHomeCard = (displayHomeCardProps: DisplayHomeCardProps) => {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const [mediaTypeVal, setMediaTypeVal] = useState('');
  const mediaAssetsArray: [MediaAssetCompound] = [
    {
      id: '',
      image: { $ref: '' },
      ximage: {
        id: '',
        xid: '',
        name: '',
        displayName: '',
        original: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        cavcoThumbnail: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        small: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        medium: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        large: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
        extraLarge: {
          id: '',
          name: '',
          displayName: '',
          url: '',
          width: 0,
          height: 0,
          length: 0,
        },
      },
      link: {
        destinationUrl: '',
        displayName: '',
        displayText: '',
        id: '',
        linkTitle: '',
        name: '',
        newWindow: false,
        alt: '',
      },
      imageAltText: '',
      matterportURL: '',
      videoURL: '',
      description: '',
      documentPicker: { $ref: '' },
      imageType: '',
    },
  ];
  const [mediaAssetsVal, setMediaAssetsVal] = useState(mediaAssetsArray);
  const [titleVal, setTitleVal] = useState('');
  const [albumLink, setAlbumLink] = useState('');

  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);

  const {
    id,
    assetID,
    modelName,
    modelNumber,
    photoUrls,
    lineDrawingUrl,
    videoAlbum: videosRef,
    tourAlbum: toursRef,
    panatourAlbum: panatourRef,
    numBedrooms,
    numBathrooms,
    squareFootage,
    buildingMethodValue,
    // isFavorite,
    availableStatus,
    markAsClearance,
    wasPrice,
    nowPrice,
    sellingPrice,
  } = displayHomeCardProps.displayHomeDocumentData;

  // Create our currency formatter.
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  let ref3dTour: any;
  let mediaType3dTour: any;
  if (toursRef !== null) {
    ref3dTour = toursRef;
    mediaType3dTour = ALBUM_MEDIA_TYPES.VIRTUAL_TOUR;
  } else if (toursRef === null && panatourRef !== null) {
    ref3dTour = panatourRef;
    mediaType3dTour = ALBUM_MEDIA_TYPES.PANATOUR;
  }

  const theDisplayHomeUrl = formatDisplayHomeUrl(displayHomeCardProps.displayHomeDocumentData.url);

  // My Account
  const compModels = displayHomeCardProps.props.component.getModels();
  const { retailerId } = compModels;
  const favoriteBaseUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.favoriteBaseUrl
    : null;
  const loadFavoriteUrl = compModels.myAccountConfig
    ? compModels.myAccountConfig.cavcoFavoriteUrl
    : null;

  let imageDefault = null;
  if (photoUrls && photoUrls[0]) {
    // first image should be shown
    imageDefault = photoUrls[0]; // eslint-disable-line
  }

  if (lineDrawingUrl) {
    if (!imageDefault || imageDefault.url === '') {
      imageDefault = lineDrawingUrl;
    }
  }

  const displayHomeTitle = `${modelName ?? ''} ${modelNumber ?? ''}`;

  const displayBedroomsBathroomsAndSquareFootage = () => {
    return (
      <div className="product-card-bed-bath-sqft">
        <span>{numBedrooms && `${numBedrooms} bd`}</span>
        {numBathrooms ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{numBathrooms && `${numBathrooms} ba`}</span>
        {squareFootage ? <span className="card-text-separator">{' | '} </span> : ''}
        <span>{squareFootage && `${squareFootage} sqft`}</span>
      </div>
    );
  };

  const displayCardBody = () => {
    const buildingMethodText = buildingMethodValue ? `${buildingMethodValue} Home` : '';
    const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
    const keyString = `display-home-card-favorite-${assetID}`;

    return displayHomeCardProps.windowDimensions.isMobile ? (
      <>
        <Card.Title>
          <ResolverLink className="card-link" href={theDisplayHomeUrl}>
            <span className="display-home-card-title">{displayHomeTitle}</span>
          </ResolverLink>
        </Card.Title>
        <div className="card-text">
          {displayBedroomsBathroomsAndSquareFootage()}
          {showFavorites && modelName ? (
            <FavoriteIcon
              key={keyString}
              classes="display-home-save-favorites-icon"
              type="display_homes"
              id={assetID}
              subId={retailerId}
              // isFavorite={isFavorite}
              baseUrl={favoriteBaseUrl}
              loadFavoriteUrl={loadFavoriteUrl}
              modelName={modelName}
              myAccountConfig={compModels.myAccountConfig}
            />
          ) : (
            ''
          )}
        </div>
      </>
    ) : (
      <>
        <div className="is-desktop-view card-text">
          <div className="display-home-favorite-building-container">
            {showFavorites && modelName ? (
              <FavoriteIcon
                key={keyString}
                classes="display-home-save-favorites-icon"
                type="display_homes"
                id={assetID}
                subId={retailerId}
                // isFavorite={isFavorite}
                baseUrl={favoriteBaseUrl}
                loadFavoriteUrl={loadFavoriteUrl}
                modelName={modelName}
                myAccountConfig={compModels.myAccountConfig}
              />
            ) : (
              ''
            )}
            {buildingMethodText ? (
              <span className="display-home-building-method-text">{buildingMethodText}</span>
            ) : (
              ''
            )}
            {availableStatus && (
              <span className="display-home-availability-text">{availableStatus}</span>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleIconMediaIconClick = (documentRef: string, mediaType: string) => {
    setMediaTypeVal(mediaType);

    const albumData = getAlbumPropertiesFromDocumentRef(
      documentRef,
      displayHomeCardProps.props,
      mediaAssetsArray
    );

    if (albumData) {
      setMediaAssetsVal(albumData!.mediaAsset);
      setTitleVal(albumData!.title);
      setAlbumLink(albumData!.albumLink);
    }
    handleShowMediaViewer();
  };

  const isSold = availableStatus.toLowerCase() === 'sold';
  const soldHomeClassName = isSold ? ' is-sold' : '';

  const handleCardPrice = () => {
    if (isSold) {
      // do all the different styling
      return (
        <span className="display-home-price-sold product-price-sold">
          Sold<span className="price-asterisk">**</span>
        </span>
      );
    }
    if (sellingPrice) {
      if (markAsClearance && nowPrice && wasPrice) {
        return displayHomeCardProps.windowDimensions.isMobile ? (
          <div className="display-home-price-clearance product-price-clearance">
            <span className="now-price">
              {currencyFormatter.format(nowPrice)}
              <span className="price-asterisk">**</span>
            </span>
          </div>
        ) : (
          // do the styling for sale price
          <div className="display-home-price-clearance product-price-clearance">
            <span className="now-price">
              <span className="currency-price-tag">{currencyFormatter.format(nowPrice)}</span>
              <span className="price-asterisk">**</span>
            </span>
            <span className="was-price">
              Was <span className="currency-price-tag">{currencyFormatter.format(wasPrice)}</span>
              <span className="price-asterisk">**</span>
            </span>
          </div>
        );
      }
      if (Number.isNaN(parseInt(sellingPrice, 10))) {
        // plain text
        return (
          <span className="textual-price">
            {sellingPrice}
            <span className="price-asterisk">**</span>
          </span>
        );
      }
      // format to money
      return (
        <span>
          <span className="currency-price-tag">{currencyFormatter.format(sellingPrice)}</span>
          <span className="price-asterisk">**</span>
        </span>
      );
    }
  };

  return (
    <Card id={id} className={imageDefault === lineDrawingUrl ? ' line-drawing' : ''}>
      <ResolverLink href={theDisplayHomeUrl}>
        <div className="display-home-price-container product-price-container">
          {handleCardPrice()}
        </div>
      </ResolverLink>

      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaTypeVal}
        mediaAssetItems={mediaAssetsVal}
        albumName={titleVal}
        albumLink={albumLink}
        hasParentPageFloorPlan={false}
        indexItemSelected={0}
      />
      {!imageDefault ? (
        <ResolverLink className={`card-link${soldHomeClassName}`} href={theDisplayHomeUrl}>
          <div className="card-no-photo" />
        </ResolverLink>
      ) : (
        <ResolverLink className={`card-link${soldHomeClassName}`} href={theDisplayHomeUrl}>
          <ImageCarousel photoUrls={photoUrls} lineDrawingUrl={lineDrawingUrl} />
        </ResolverLink>
      )}
      {!displayHomeCardProps.windowDimensions.isMobile && (
        <>
          <Card.Title className="is-desktop-view">
            <ResolverLink className="card-link" href={theDisplayHomeUrl}>
              <span className="display-home-card-title">{displayHomeTitle}</span>
            </ResolverLink>
            {displayBedroomsBathroomsAndSquareFootage()}
          </Card.Title>
          {ref3dTour ? (
            <div className="display-home-3d-virtual-icon cvc-product-list-card-icon">
              <VirtualIcon onClick={() => handleIconMediaIconClick(ref3dTour, mediaType3dTour)} />
            </div>
          ) : (
            ''
          )}
          {videosRef ? (
            <div
              className={classNames(
                'display-home-video-icon',
                'cvc-product-list-card-icon',
                ref3dTour ? 'show-after-virtual-icon' : ''
              )}
            >
              <VideoIcon
                onClick={() => handleIconMediaIconClick(videosRef, ALBUM_MEDIA_TYPES.VIDEO)}
              />
            </div>
          ) : (
            ''
          )}
          {lineDrawingUrl && photoUrls && photoUrls[0] ? (
            <div className="display-home-line-drawings-container">
              <ResolverLink className="card-link" href={theDisplayHomeUrl}>
                <img alt={lineDrawingUrl.alt} src={lineDrawingUrl.url} />
              </ResolverLink>
            </div>
          ) : (
            ''
          )}
        </>
      )}
      <Card.Body>{displayCardBody()}</Card.Body>
    </Card>
  );
};
