import { BrPageContext } from '@bloomreach/react-sdk';
import React from 'react';
import { Link } from 'react-router-dom';

const isExternalUrl = (url: string): boolean => /^https?:\/\//.test(url);

const forceToString = (href: any = ''): string => {
  if (typeof href === 'boolean') return '';
  return href.toString().replace(/\s/g, '+');
};

const containsAssetPath = (url: string): boolean => url.includes('/assets/');

const ExternalLink = ({ children, href, ...props }: any) => (
  <a href={href} {...props}>
    {children}
  </a>
);

const InternalLink = ({ children, to, ...props }: any) => (
  <Link to={to} {...props}>
    {children}
  </Link>
);

const resolveUrl = (href: string, page: any, spaBaseUrl: string): string => {
  const sanitizedUrl = forceToString(href).replace(
    /\.\//,
    `${window.location.pathname.replace(/\/$/, '')}/`
  );

  if (sanitizedUrl === '/' || sanitizedUrl === '') {
    return '';
  }

  if (!sanitizedUrl.startsWith('http')) {
    return spaBaseUrl ? `${spaBaseUrl}${page.getUrl(sanitizedUrl)}` : page.getUrl(sanitizedUrl);
  }

  // Return external or absolute URL as is
  return sanitizedUrl;
};

export const ResolverLink = ({ children, href = '', target = '', ...props }: any) => {
  const page = React.useContext(BrPageContext);
  const spaBaseUrl = window.spaBaseUrl || '';
  const cmsBaseUrl = window.cmsBaseUrl || '';

  const resolvedUrl = resolveUrl(href, page, spaBaseUrl);

  if (isExternalUrl(resolvedUrl)) {
    const externalUrl = new URL(resolvedUrl);
    const cmsUrl = new URL(cmsBaseUrl);

    // If the external URL belongs to the same domain and is not an asset, treat it as an internal link
    if (
      (externalUrl.origin === cmsUrl.origin || externalUrl.origin === window.location.origin) &&
      !containsAssetPath(resolvedUrl)
    ) {
      return (
        <InternalLink {...props} to={externalUrl.pathname + externalUrl.search}>
          {children}
        </InternalLink>
      );
    }

    return (
      <ExternalLink {...props} href={resolvedUrl} target={target}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <InternalLink {...props} to={resolvedUrl}>
      {children}
    </InternalLink>
  );
};

export const formatForHistory = (url: string) => {
  return url;
};
