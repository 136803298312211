import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { SCREEN_SIZES } from '../enums';

export default function useWindowDimensions(delay = 200) {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    const isMobile = hasWindow ? window.innerWidth < SCREEN_SIZES.TABLET : null;
    const isTablet = hasWindow
      ? window.innerWidth >= SCREEN_SIZES.TABLET && window.innerWidth < SCREEN_SIZES.DESKTOP
      : null;
    const isDesktop = hasWindow
      ? window.innerWidth >= SCREEN_SIZES.DESKTOP && window.innerWidth < SCREEN_SIZES.DESKTOP_BIG
      : null;
    const isDesktopBig = hasWindow
      ? window.innerWidth >= SCREEN_SIZES.DESKTOP_BIG &&
        window.innerWidth < SCREEN_SIZES.DESKTOP_EXTRA_BIG
      : null;
    const isDesktopExtraBig = hasWindow
      ? window.innerWidth >= SCREEN_SIZES.DESKTOP_EXTRA_BIG
      : null;
    return {
      width,
      height,
      isMobile,
      isTablet,
      isDesktop,
      isDesktopBig,
      isDesktopExtraBig,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isXpageEditor, setIsXPageEditor] = useState(false);

  const handleResize = useCallback(() => {
    // do not resize when viewing in channel manager or viewing video in full screen
    if (window.location.pathname.startsWith('/cms') || document.fullscreenElement) return;

    // do not resize if the screen width doesn't change
    // Mobile and tablet browser url bars dissapearing or shrinking when scrolling can cause resize events
    if (windowDimensions.width === getWindowDimensions().width) {
      return;
    }

    // do not resize when on input -- Android on-screen keyboard trigger a resize which dismisses keyboard
    if (
      !document ||
      !document.activeElement ||
      (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'input')
    ) {
      setWindowDimensions(getWindowDimensions());
    }
  }, [getWindowDimensions, windowDimensions]);

  useEffect(() => {
    const isXPageEditMode = !!document.querySelector('.hippo-overlay');
    setIsXPageEditor(isXPageEditMode);
  }, []);

  useEffect(() => {
    const debounced: any = debounce(handleResize, delay);
    if (hasWindow && !isXpageEditor) {
      window.addEventListener('resize', debounced);
    }
    return () => window.removeEventListener('resize', debounced);
  }, [delay, handleResize, hasWindow, isXpageEditor]);

  return windowDimensions;
}
