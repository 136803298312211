import React from 'react';
import cn from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { Container } from 'react-bootstrap';
import './CavcoRetailerBrands.scss';
import { getPageContentData, getRetailerData } from 'components/utils';

interface RetailerBrandImg {
  src: string;
  alt: string;
}

export const CavcoRetailerBrands: React.FC<BrProps> = ({ page, component }) => {
  const { showSeparator, retailerBrandLogos: cavcoBrands } = component.getModels();

  const retailerData = getRetailerData(page);

  const brands = retailerData?.series?.reduce<RetailerBrandImg[]>((arr, doc) => {
    const brand = getPageContentData<any>(doc, page)?.brand;
    if (brand && arr.findIndex((v) => v.alt === brand) === -1) {
      arr.push({
        alt: brand,
        src: cavcoBrands[brand],
      });
    }
    return arr;
  }, []);

  if (!brands?.length || !cavcoBrands) {
    return null;
  }

  return (
    <div className={cn('cvc-cavco-retailer-brands', { 'has-separator': showSeparator === 'on' })}>
      <Container>
        <p className="cvc-cavco-retailer-brands__header">{cavcoBrands?.title}</p>
        <div className="cvc-cavco-retailer-brands__logos-wrapper">
          {brands.map(({ src, alt }) => (
            <img
              key={`logo_${alt}`}
              alt={alt}
              className="cvc-cavco-retailer-brands__logo"
              src={src}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};
