import React, { useState, FC } from 'react';

interface ModalLaunchContextProps {
  globalModal: string | null;
  setGlobalModal: (modal: string) => void;
}

export const ModalLaunchContext = React.createContext<ModalLaunchContextProps | null>(null);

const ModalLaunchContextProvider: FC<React.ReactNode> = ({ children }) => {
  const [globalModal, setGlobalModal] = useState<string | null>(null);
  return (
    <ModalLaunchContext.Provider value={{ globalModal, setGlobalModal }}>
      {children}
    </ModalLaunchContext.Provider>
  );
};

export default ModalLaunchContextProvider;
