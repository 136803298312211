import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { ReactComponent as ChevronRightIcon } from 'assets/images/caret-right-teal.svg';
import './AccountMobileMenu.scss';

interface AccountLink {
  url: string;
  name: string;
}

interface AccountMobileMenuProps {
  mainLink: AccountLink;
  accountLinks?: AccountLink[];
}

export const AccountMobileMenu: React.FC<AccountMobileMenuProps> = ({
  accountLinks,
  children,
  mainLink,
}) => {
  return (
    <>
      <div className="cvc-header-comp-navbar-sidebar-content">
        <List>
          <ListItem className="account-mobile-menu_button main-link" button>
            <Nav.Link
              className="account-mobile-menu_link cvc-header-comp-navbar-nav-link nav-link"
              href={mainLink.url}
            >
              {mainLink.name}
            </Nav.Link>
            <ChevronRightIcon className="account-mobile-menu_link_chevron-icon" />
          </ListItem>
          {accountLinks && accountLinks?.length > 0 && (
            <>
              {accountLinks.map((link) => (
                <ListItem
                  className="account-mobile-menu_button"
                  button
                  key={`account-link${link.name}`}
                >
                  <Nav.Link
                    className="account-mobile-menu_link cvc-header-comp-navbar-nav-link nav-link"
                    href={link.url}
                  >
                    {link.name}
                  </Nav.Link>
                </ListItem>
              ))}
              {children}
            </>
          )}
        </List>
      </div>
    </>
  );
};
