import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Col, Row, Modal } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import classNames from 'classnames';
import { GlobalScrollToTopButton } from 'components/shared/ScrollToTop';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { useStateWithLocalStorage } from 'components/hooks/useStateWithLocalStorage';
import { SharePage } from '../SharePage/SharePage';
import { FloorPlanRequestInfoWithParams } from '../FloorPlanRequestInfo/FloorPlanRequestInfo';
import './BreadcrumbStack.scss';
import { ReactComponent as RequestMoreInfoIcon } from '../../assets/images/request-more-info.svg';
import { GoToPageUrl } from '../Autocomplete/Autocomplete';
import { PAGE_TYPES } from '../enums';

function titleCase(str: any) {
  if (!str) return null;

  return str
    .toLowerCase()
    .split('-')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ')
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ')
    .replace('3d ', '3D ');
}
interface PageTypes {
  stdPage: boolean;
  pmPage: boolean;
  pmRetailerPage: boolean;
  stdRetailerPage: boolean;
  stdLocationsPage: boolean;
  pmLocationsPage: boolean;
}
const getPageType = ({
  stdPage,
  pmPage,
  stdRetailerPage,
  pmRetailerPage,
  stdLocationsPage,
  pmLocationsPage,
}: PageTypes) => {
  if (stdLocationsPage) return PAGE_TYPES.STD;
  if (pmLocationsPage) return PAGE_TYPES.PM;
  if (stdPage && stdRetailerPage) return PAGE_TYPES.STD_RETAILER;
  if (pmPage && pmRetailerPage) return PAGE_TYPES.PM_RETAILER;
  if (stdRetailerPage) return PAGE_TYPES.STD_RETAILER;
  if (pmRetailerPage) return PAGE_TYPES.PM_RETAILER;
  if (stdPage) return PAGE_TYPES.STD;
  if (pmPage) return PAGE_TYPES.PM;

  return PAGE_TYPES.STD;
};

const getCustomMenuUrl = (
  page: any,
  urlInput: string,
  dataTerms: any,
  latitude: string,
  longitude: string
) => {
  const url = page.getUrl(urlInput);

  const menuItemURL = GoToPageUrl({
    url,
    terms: dataTerms,
    lat: latitude,
    lng: longitude,
  });

  return menuItemURL;
};

const getMenuUrlFromLocalStorage = (
  page: any,
  floorplanUrl: string,
  currentFloorPlanSearch: any
) => {
  return getCustomMenuUrl(
    page,
    floorplanUrl,
    currentFloorPlanSearch.dataTerms,
    currentFloorPlanSearch.latitude,
    currentFloorPlanSearch.longitude
  );
};
const getBreadcrumbInfo = (pageType: string, breadcrumbRB: any) => {
  const {
    stdPagesLink,
    stdPagesText,
    stdRetailerPagesLink,
    stdRetailerPagesText,
    pmPagesLink,
    pmPagesText,
    pmRetailerPagesLink,
    pmRetailerPagesText,
  } = breadcrumbRB;
  if (pageType === 'pm-retailer') {
    return { bcLabel: pmRetailerPagesText, bcUrl: pmRetailerPagesLink };
  }
  if (pageType === 'std-retailer') {
    return { bcLabel: stdRetailerPagesText, bcUrl: stdRetailerPagesLink };
  }
  if (pageType === 'pm') {
    return { bcLabel: pmPagesText, bcUrl: pmPagesLink };
  }
  if (pageType === 'std') {
    return { bcLabel: stdPagesText, bcUrl: stdPagesLink };
  }
};

function displayBreadcrumbNav(
  showBreadcrumb: boolean | undefined,
  breadcrumbValueList: any,
  globalBreadcrumbConfig: any,
  locationInfo: any,
  fpDetailBc?: any,
  customProps: any = {}
) {
  if (!breadcrumbValueList) return null;
  const breadcrumbList = breadcrumbValueList;
  if (fpDetailBc) {
    breadcrumbList.splice(1, 0, fpDetailBc);
  }
  const breadcrumbs =
    breadcrumbList &&
    breadcrumbList
      .slice(0, customProps.maxLength)
      .map((item: { key: string; value: string }, i: number, arr: any) => {
        try {
          const url = item.value;
          const overrideLabel = customProps.overrides?.find(
            (customItem: any) => customItem.index === i
          )?.label;
          const label = overrideLabel || titleCase(item.key);
          if (i === 0 && globalBreadcrumbConfig) {
            const pageType = getPageType(locationInfo);

            const breadcrumbInfo = getBreadcrumbInfo(pageType, globalBreadcrumbConfig);
            const { bcLabel = 'Home', bcUrl = 'https://www.fairmonthomes.com' } =
              breadcrumbInfo || {};

            return (
              <Breadcrumb.Item
                linkAs={ResolverLink}
                key={`${item.key}-${i.toString()}`}
                href={bcUrl}
              >
                {bcLabel}
              </Breadcrumb.Item>
            );
          }
          if (arr.length - 1 === i) {
            if (customProps.hideLast) return null;
            return (
              <Breadcrumb.Item
                linkAs={ResolverLink}
                key={`${item.key}-${i.toString()}`}
                href={url}
                active
              >
                {label}
              </Breadcrumb.Item>
            );
          }
          return (
            <Breadcrumb.Item linkAs={ResolverLink} key={`${item.key}-${i.toString()}`} href={url}>
              {label}
            </Breadcrumb.Item>
          );
        } catch (e) {
          return null;
        }
      });
  return showBreadcrumb ? <Breadcrumb>{breadcrumbs}</Breadcrumb> : '';
}

function displayRequestInfoButton(showRequestInfo: boolean | undefined, openModal: any) {
  return showRequestInfo ? (
    <button
      id="request-info-button"
      className="cvc-breadcrumb-page-cta-button"
      type="button"
      onClick={openModal}
    >
      <RequestMoreInfoIcon className="cvc-breadcrumb-page-cta-icon" />
      <div className="text">
        Request <span className="label-secondary">More Info</span>
      </div>
    </button>
  ) : (
    ''
  );
}

function displayRequestMoreInfo(icon: string, title: string, desc: string, openModal: any) {
  return (
    <button
      type="button"
      className="cvc-breadcrumb-page-cta-button d-inline-block mr-md-2 mr-lg-0"
      id="request-info-button"
      onClick={openModal}
    >
      <RequestMoreInfoIcon className="cvc-breadcrumb-page-cta-icon" />
      <div className="text">
        {title} <span className="label-secondary">{desc}</span>
      </div>
    </button>
  );
}

export function BreadcrumbStackWithParams(
  props: BrProps,
  parameters: any,
  isCustom = false,
  customProps: any
) {
  const windowDimensions = useWindowDimensions();
  const location = useLocation();
  const [requestInfoModal, setModal] = useState(false);
  const [currentFloorPlanSearch] = useStateWithLocalStorage('currentFloorPlanSearch');
  const [fpCustomBreadcrumb, setFpCustomBreadcrumb] = useState<
    { key: string; value: string } | undefined
  >(undefined);
  const closeModal = () => {
    setModal(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  const openModal = () => {
    setModal(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };
  const formId = 'retailer-breadcrumb-form';
  const { breadcrumbValueList, socialMediaIcons, globalBreadcrumbConfig, componentParameterMap } =
    props.component.getModels();
  const { displayScrollToTop = false } = customProps;

  const showBreadcrumbItems =
    componentParameterMap &&
    componentParameterMap.breadcrumb &&
    componentParameterMap.breadcrumb === 'on';
  const displayScrollFromProps = displayScrollToTop;
  const path = location.pathname;
  const pathNameSplit = path.split('/');
  const isParkModel = path.includes('our-park');
  const isRetailer = path.includes('retailer');
  const isOurHomesPage = path.includes('our-homes') || path.includes('our-park-models');
  const shouldDisplayScrollToTop =
    windowDimensions.isTablet &&
    pathNameSplit[pathNameSplit.length - 1] !== 'floorplans' &&
    displayScrollFromProps;

  const locationInfo = {
    stdPage: !isParkModel && !isRetailer,
    pmPage: (isParkModel && !isRetailer) || (isParkModel && location.search !== ''),
    stdLocationsPage:
      !isParkModel &&
      location.search !== '' &&
      !isOurHomesPage &&
      pathNameSplit[pathNameSplit.length - 1] !== 'floorplans',
    pmLocationsPage:
      isParkModel &&
      location.search !== '' &&
      !isOurHomesPage &&
      pathNameSplit[pathNameSplit.length - 1] !== 'floorplans',
    stdRetailerPage: isRetailer && !isParkModel,
    pmRetailerPage: isRetailer && isParkModel,
  };

  let bcvl: any[] = breadcrumbValueList;

  useEffect(() => {
    if (
      currentFloorPlanSearch &&
      bcvl.find((item: any) => item.key === 'Our Homes' || item.key === 'Our Park Models') ===
        undefined &&
      document.querySelector('.cvc-floorplan-detail-container') &&
      !pathNameSplit.includes('floorplans')
    ) {
      setFpCustomBreadcrumb({
        key: isParkModel ? 'Our Park Models' : 'Our Homes',
        value: getMenuUrlFromLocalStorage(
          props.page,
          isParkModel ? '/our-park-models' : '/our-homes',
          currentFloorPlanSearch
        ),
      });
    }
    return () => setFpCustomBreadcrumb({ key: '', value: '' });
    // eslint-disable-next-line
  }, [currentFloorPlanSearch]);

  if (!bcvl || bcvl.length === 0) {
    bcvl = [{ key: props.page.getTitle(), value: '' }];
  }
  const pageProps = props.page.getComponent('main')?.getComponent('pageProperties');
  const models = pageProps && pageProps?.getModels();
  const meta = models && models.componentParameterMap;

  let form = props.page.getComponent('main', 'request-info');

  if (!form) {
    const details = props.page.getComponent('main', 'detailcontainer');
    form = details && details.getComponent('request-info');
  }

  const isEditorialPage = !isCustom && !isOurHomesPage && !isRetailer;

  const breadcrumbClasses = classNames({
    'cvc-breadcrumb-container': true,
    'cvc-editorial': isEditorialPage,
    'no-breadcrumbs': !showBreadcrumbItems,
    'is-custom': isCustom,
  });

  return !isCustom ? (
    <div className={breadcrumbClasses}>
      {parameters.hideComponent ? (
        <></>
      ) : (
        <>
          <div className="cvc-breadcrumb-nav">
            {showBreadcrumbItems &&
              displayBreadcrumbNav(
                Boolean(parameters.breadcrumb),
                bcvl,
                globalBreadcrumbConfig,
                locationInfo,
                fpCustomBreadcrumb,
                customProps
              )}
          </div>
          <Row className={classNames(!showBreadcrumbItems ? 'py-3 py-md-5' : '')}>
            {Boolean(parameters.pageTitle) ||
              (Boolean(parameters.subTitle) && (
                <Col>
                  <span className="cvc-breadcrumb-header">
                    {parameters.pageTitle && (
                      <h1 className="cvc-breadcrumb-title">{parameters.pageTitle} </h1>
                    )}
                    {parameters.subTitle && (
                      <h3 className="cvc-breadcrumb-subtitle">{parameters.subTitle} </h3>
                    )}
                  </span>
                </Col>
              ))}

            {Boolean(parameters.requestInfo) && parameters.share && (
              <Col
                md="auto"
                className="d-none d-lg-block d-xl-block cvc-breadcrumb-cta-buttons-container"
              >
                <div style={{ display: 'flex' }}>
                  {displayRequestInfoButton(Boolean(parameters.requestInfo), openModal)}
                  {parameters.share && (
                    <SharePage
                      className="cvc-breadcrumb-share-modal-icon"
                      ogImage={meta && meta.ogImage ? meta.ogImage : null}
                      ogDescription={meta && meta.ogDescription ? meta.ogDescription : null}
                      icons={socialMediaIcons}
                    />
                  )}
                  {shouldDisplayScrollToTop && (
                    <GlobalScrollToTopButton className="scroll-to-top-breadcrumb" />
                  )}
                </div>
              </Col>
            )}
          </Row>

          <Modal
            className="cvc-request-info-modal-retailer"
            show={requestInfoModal}
            onHide={closeModal}
            backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
            keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body id="id-modal-body" className="px-0">
              {form && (
                <FloorPlanRequestInfoWithParams
                  page={props.page}
                  component={form}
                  displayInsideModal
                  formId={formId}
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  ) : (
    <div className={breadcrumbClasses}>
      <Row className="cvc-breadcrumb__nav-row">
        <Col className="cvc-breadcrumb-nav d-none d-sm-block no-horizontal-padding">
          {showBreadcrumbItems &&
            displayBreadcrumbNav(
              Boolean(parameters.breadcrumb),
              bcvl,
              globalBreadcrumbConfig,
              locationInfo,
              undefined
            )}
        </Col>
      </Row>
      <Row className={classNames(!showBreadcrumbItems ? 'py-3 py-md-5' : '')}>
        {(parameters.pageTitle || parameters.subTitle) && (
          <Col className="d-none d-sm-block">
            <span className="cvc-breadcrumb-header d-none d-sm-block">
              {/* TODO: When a Hero Banner is present, the Page Title will be removed. */}
              {/* TODO: When Banner image is present, Page Title will appear below the Banner image. */}
              {parameters.pageTitle && (
                <h1 className="cvc-breadcrumb-title">{parameters.pageTitle} </h1>
              )}
              {parameters.subTitle && (
                <h3 className="cvc-breadcrumb-subtitle">{parameters.subTitle} </h3>
              )}
            </span>
          </Col>
        )}
        {(parameters.requestInfo || parameters.share) && (
          <Col xs="auto" className="cvc-breadcrumb-panel-icons d-block">
            {parameters.requestInfo
              ? displayRequestMoreInfo('request', 'Request', 'More Info', openModal)
              : ''}
            {parameters.share && (
              <SharePage
                ogImage={meta && meta.ogImage ? meta.ogImage : null}
                ogDescription={meta && meta.ogDescription ? meta.ogDescription : null}
                icons={socialMediaIcons}
              />
            )}
            {shouldDisplayScrollToTop && (
              <GlobalScrollToTopButton className="scroll-to-top-breadcrumb" />
            )}
          </Col>
        )}
      </Row>

      <Modal
        className="cvc-request-info-modal-retailer"
        show={requestInfoModal}
        onHide={closeModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="id-modal-body" className="px-0">
          {form && (
            <FloorPlanRequestInfoWithParams
              page={props.page}
              component={form}
              displayInsideModal
              formId={formId}
              // onClose={closeModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function BreadcrumbStack({ page, component, ...props }: any & BrProps) {
  const parameters = component.getParameters();
  return BreadcrumbStackWithParams({ page, component }, parameters, false, props);
}

interface CustomBreadcrumbStackProps {
  isCustom?: boolean;
  props: BrProps;
  customProps?: {};
}

export const CustomBreadcrumbStack = ({
  isCustom = true,
  props,
  customProps = {},
}: CustomBreadcrumbStackProps) => {
  const parameters = props.component.getParameters();
  return BreadcrumbStackWithParams(props, parameters, isCustom, customProps);
};

export function BreadcrumbStackWithParamsRequestInfoShare(
  props: BrProps,
  parameters: any,
  isCustom = false,
  customProps: any
) {
  const windowDimensions = useWindowDimensions();
  const location = useLocation();
  const [requestInfoModal, setModal] = useState(false);
  const closeModal = () => {
    setModal(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  const openModal = () => {
    setModal(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };
  const formId = 'retailer-breadcrumb-form';
  const { breadcrumbValueList, socialMediaIcons, componentParameterMap } =
    props.component.getModels();
  const { displayScrollToTop = false } = customProps;

  const showBreadcrumbItems =
    componentParameterMap &&
    componentParameterMap.breadcrumb &&
    componentParameterMap.breadcrumb === 'on';
  const displayScrollFromProps = displayScrollToTop;
  const path = location.pathname;
  const pathNameSplit = path.split('/');
  const isRetailer = path.includes('retailer');
  const isOurHomesPage = path.includes('our-homes') || path.includes('our-park-models');
  const shouldDisplayScrollToTop =
    windowDimensions.isTablet &&
    pathNameSplit[pathNameSplit.length - 1] !== 'floorplans' &&
    displayScrollFromProps;

  let bcvl: any[] = breadcrumbValueList;

  if (!bcvl || bcvl.length === 0) {
    bcvl = [{ key: props.page.getTitle(), value: '' }];
  }
  const pageProps = props.page.getComponent('main')?.getComponent('pageProperties');
  const models = pageProps && pageProps?.getModels();
  const meta = models && models.componentParameterMap;

  let form = props.page.getComponent('main', 'request-info');

  if (!form) {
    const details = props.page.getComponent('main', 'detailcontainer');
    form = details && details.getComponent('request-info');
  }

  const isEditorialPage = !isCustom && !isOurHomesPage && !isRetailer;

  const breadcrumbClasses = classNames({
    'cvc-breadcrumb-container': true,
    'cvc-editorial': isEditorialPage,
    'no-breadcrumbs': !showBreadcrumbItems,
    'is-custom': isCustom,
  });

  return (
    <div className={breadcrumbClasses}>
      <Row className={classNames(!showBreadcrumbItems ? 'py-3 py-md-5' : '')}>
        {(parameters.requestInfo || parameters.share) && (
          <Col xs="auto" className="cvc-breadcrumb-panel-icons d-block">
            {parameters.requestInfo
              ? displayRequestMoreInfo('request', 'Request', 'More Info', openModal)
              : ''}
            {parameters.share && (
              <SharePage
                ogImage={meta && meta.ogImage ? meta.ogImage : null}
                ogDescription={meta && meta.ogDescription ? meta.ogDescription : null}
                icons={socialMediaIcons}
              />
            )}
            {shouldDisplayScrollToTop && (
              <GlobalScrollToTopButton className="scroll-to-top-breadcrumb" />
            )}
          </Col>
        )}
      </Row>

      <Modal
        className="cvc-request-info-modal-retailer"
        show={requestInfoModal}
        onHide={closeModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="id-modal-body" className="px-0">
          {form && (
            <FloorPlanRequestInfoWithParams
              page={props.page}
              component={form}
              displayInsideModal
              formId={formId}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export const CustomBreadcrumbStackWithRequestShare = ({
  isCustom = true,
  props,
  customProps = {},
}: CustomBreadcrumbStackProps) => {
  const parameters = props.component.getParameters();
  parameters.requestInfo = true;
  parameters.share = true;
  return BreadcrumbStackWithParamsRequestInfoShare(props, parameters, isCustom, customProps);
};

export function BreadcrumbStackWithParamsWithOutRequestInfoShared(
  props: BrProps,
  parameters: any,
  isCustom = false,
  customProps: any
) {
  const location = useLocation();

  const { breadcrumbValueList, globalBreadcrumbConfig, componentParameterMap } =
    props.component.getModels();

  const showBreadcrumbItems =
    componentParameterMap &&
    componentParameterMap.breadcrumb &&
    componentParameterMap.breadcrumb === 'on';
  const path = location.pathname;
  const pathNameSplit = path.split('/');
  const isParkModel = path.includes('park');
  const isRetailer = path.includes('retailer');
  const isOurHomesPage = path.includes('our-homes') || path.includes('our-park-models');

  const locationInfo = {
    stdPage: !isParkModel && !isRetailer,
    pmPage: (isParkModel && !isRetailer) || (isParkModel && location.search !== ''),
    stdLocationsPage:
      !isParkModel &&
      location.search !== '' &&
      !isOurHomesPage &&
      pathNameSplit[pathNameSplit.length - 1] !== 'floorplans',
    pmLocationsPage:
      isParkModel &&
      location.search !== '' &&
      !isOurHomesPage &&
      pathNameSplit[pathNameSplit.length - 1] !== 'floorplans',
    stdRetailerPage: isRetailer && !isParkModel,
    pmRetailerPage: isRetailer && isParkModel,
  };

  let bcvl: any[] = breadcrumbValueList;

  if (!bcvl || bcvl.length === 0) {
    bcvl = [{ key: props.page.getTitle(), value: '' }];
  }

  let form = props.page.getComponent('main', 'request-info');

  if (!form) {
    const details = props.page.getComponent('main', 'detailcontainer');
    form = details && details.getComponent('request-info');
  }

  const isEditorialPage = !isCustom && !isOurHomesPage && !isRetailer;

  const breadcrumbClasses = classNames({
    'cvc-breadcrumb-container': true,
    'cvc-editorial': isEditorialPage,
    'no-breadcrumbs': !showBreadcrumbItems,
    'is-custom': isCustom,
  });

  return (
    <div className={breadcrumbClasses}>
      <Row className="cvc-breadcrumb__nav-row">
        <Col className="cvc-breadcrumb-nav d-none d-sm-block no-horizontal-padding">
          {showBreadcrumbItems &&
            displayBreadcrumbNav(
              Boolean(parameters.breadcrumb),
              bcvl,
              globalBreadcrumbConfig,
              locationInfo,
              undefined,
              customProps
            )}
        </Col>
      </Row>
    </div>
  );
}

export const CustomBreadcrumbStackWithoutRequestInfoShare = ({
  isCustom = true,
  props,
}: CustomBreadcrumbStackProps) => {
  const parameters = props.component.getParameters();
  const customProps: any = {};
  //  customProps.overrides = [{ index: 2, label: 'Floor Plans' }];
  return BreadcrumbStackWithParamsWithOutRequestInfoShared(
    props,
    parameters,
    isCustom,
    customProps
  );
};
