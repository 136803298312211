// import { useEffect, useState } from 'react';
/* eslint-disable */

// declare global {
//     interface Window {
//         LassoAnalyticsAPI: number;
//         LassoAnalyticsObject: string;
//     }
// }
// interface LassoAnalytics {
//   (arg: any): any;
//   push(arg: any): void;
//   q?: any[];
//   guid: string;
// }

window.LassoAnalyticsAPI = 2; // If opting into new analytics API
(function (win, doc, scr, url, name, elem, parnt) {
  win.LassoAnalyticsObject = name;
  (win[name] =
    win[name] ||
    function () {
      (win[name].q = win[name].q || []).push(arguments);
      return win[name];
    }),
    (elem = doc.createElement(scr)),
    (elem.async = 1);
  elem.src = url;
  parnt = doc.getElementsByTagName(scr)[0];
  parnt.parentNode.insertBefore(elem, parnt);
})(window, document, 'script', 'https://platform.lassocrm.com/wt/analytics.js', 'LassoAnalytics');

export default function useLasso() {
  return {
    lasso: window.LassoAnalytics,
  };
}
