import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoDetailDisclaimer.scss';
import { getDisplayHomeDataModel } from 'components/utils';

export const CavcoDetailDisclaimer: React.FC<BrProps> = ({ page, component }) => {
  const { resourceBundles } = component.getModels();
  const disclaimers = component.getModels()[resourceBundles];
  const { retailerDisclaimer, floorplanDisclaimer, seoText } = disclaimers || {};
  let { pricingDisclaimer } = disclaimers || {};

  const inventoryData = getDisplayHomeDataModel(page);
  if (inventoryData) {
    const { document: ref } = inventoryData;
    const document = page.getContent(ref);

    if (document) {
      const { overrideClearancePricing, invCLDisclaimer } = document.getData<InventoryData>();
      pricingDisclaimer =
        overrideClearancePricing && invCLDisclaimer ? invCLDisclaimer : pricingDisclaimer;
    }
  }

  if (!retailerDisclaimer && !floorplanDisclaimer && !pricingDisclaimer && !seoText) {
    return null;
  }

  if (!pricingDisclaimer.startsWith('**')) {
    pricingDisclaimer = `**${pricingDisclaimer}`;
  }

  return (
    <div data-elastic-exclude className="cvc-cavco-retailer-fp-disclaimer">
      {seoText && <p className="cvc-cavco-retailer-fp-disclaimer__seo">{seoText}</p>}
      {retailerDisclaimer && (
        <p className="cvc-cavco-retailer-fp-disclaimer__disclaimer">{retailerDisclaimer}</p>
      )}
      {floorplanDisclaimer && (
        <p className="cvc-cavco-retailer-fp-disclaimer__disclaimer">{floorplanDisclaimer}</p>
      )}
      {pricingDisclaimer && (
        <p className="cvc-cavco-retailer-fp-disclaimer__disclaimer">{pricingDisclaimer}</p>
      )}
    </div>
  );
};
