import React, { useState, useEffect } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Container, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ResolverLink } from 'components/shared';
import { Spinner } from 'components/shared/Spinner';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { HeroBannerWithParams } from '../HeroBanner/HeroBanner';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import './SiteSearchResults.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { formatPhoneNumber } from '../utils';

const MAX_LENGTH_DESCRIPTION = 100;
const COMMUNITY_LABEL = 'Fairmont Homes, Inc Official Community';
let isSearching = false;

const boldQuery = (str: string, query: string) => {
  if (!str) return '';

  return str.replace(
    new RegExp(query, 'ig'),
    (word: string) => `<span class="query">${word}</span>`
  );
};

function truncateString(str: string, num: number) {
  if (str?.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
}

const Description = ({ value }: any) => {
  const text = truncateString(value, MAX_LENGTH_DESCRIPTION);
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
};

function showImage(imageUrl: any, imageAltText: any) {
  if (!imageUrl) return null;

  return (
    <img className="cvc-site-search-results__result-image" src={imageUrl} alt={imageAltText} />
  );
}

function displayFloorplanSellingFeatures(floorplanSellingFeatures: any) {
  if (floorplanSellingFeatures?.length < 1) return '';

  return (
    <div className="cvc-site-search-results__result-footer-text">
      {floorplanSellingFeatures && floorplanSellingFeatures[0] && floorplanSellingFeatures[0]}
      {floorplanSellingFeatures &&
        floorplanSellingFeatures![1] &&
        ` ${floorplanSellingFeatures[1]}`}
      {floorplanSellingFeatures &&
        floorplanSellingFeatures![2] &&
        ` ${floorplanSellingFeatures[2]}`}
      {floorplanSellingFeatures && ''}
    </div>
  );
}

function displayResultFooter(
  withPaddingLeft: boolean,
  url: string,
  floorplanSellingFeatures: any = null
) {
  const path = url;
  const adaptedPathname = window.location.pathname.startsWith('/site') ? '/site' : '';
  let displayUrl = '';
  let target = '';
  if (url) {
    if (url.startsWith('/')) {
      displayUrl = window.location.origin + adaptedPathname + url;
    } else {
      displayUrl = url;
      target = '_blank';
    }
  }

  return (
    <div
      className={classNames(
        'cvc-site-search-results__result-footer',
        withPaddingLeft ? 'padding-left' : ''
      )}
    >
      {floorplanSellingFeatures && displayFloorplanSellingFeatures(floorplanSellingFeatures)}
      <ResolverLink
        className="cvc-site-search-results__result-footer-link"
        href={path}
        target={target}
      >
        {displayUrl}
      </ResolverLink>
    </div>
  );
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

function showDisplayHomePrice(
  sellingPrice: any,
  availableStatus: string,
  markAsClearance: boolean,
  nowPrice: any,
  wasPrice: any
) {
  if (availableStatus.toLowerCase() === 'sold') {
    // do all the different styling
    return (
      <span className="display-home-price-sold product-price-sold">
        SOLD<span className="price-asterisk">**</span>
      </span>
    );
  }
  if (sellingPrice) {
    if (markAsClearance && nowPrice && wasPrice) {
      return (
        // do the styling for sale price
        <span className="display-home-price-clearance product-price-clearance">
          <span className="was-price">WAS {currencyFormatter.format(wasPrice)}</span>
          <span className="now-price">
            {' '}
            NOW {currencyFormatter.format(nowPrice)}
            <span className="price-asterisk">**</span>
          </span>
        </span>
      );
    }
    if (Number.isNaN(parseInt(sellingPrice, 10))) {
      // plain text
      return (
        <span>
          {sellingPrice}
          <span className="price-asterisk">**</span>
        </span>
      );
    }
    // format to money
    return (
      <>
        {currencyFormatter.format(sellingPrice)}
        <span className="price-asterisk">**</span>
      </>
    );
  }
}

function displayHomeSearchResult(
  indexDHItem: number,
  document: SiteSearchDisplayHomeDetail,
  siteSearchLabels: any,
  isDesktop: boolean,
  query: string
) {
  if (!document) {
    return null;
  }

  const {
    squareFootage,
    numBedrooms,
    numBathrooms,
    modelName,
    modelNumber,
    sellingPrice,
    sections,
    buildingMethodValue,
    url,
    imageUrl,
    imageAltText,
    availableStatus,
    specialFeatures,
    markAsClearance,
    wasPrice,
    nowPrice,
    series,
    retailerCity,
    retailerState,
  } = document;

  const {
    bedroomsLabel,
    displayHomeLabel,
    squareFeetLabel,
    bathroomsLabel,
    sectionsLabel,
    homeTypeLabel,
    displayHomePriceLabel,
  } = siteSearchLabels;

  const path = url;

  const detailsCharacterConcat = isDesktop ? ' | ' : '';
  const queriedSeriesName = boldQuery(series, query);
  const queriedModelName = boldQuery(modelName, query);
  const queriedRetailerCity = boldQuery(retailerCity, query);
  const queriedRetailerState = boldQuery(retailerState, query);
  const seriesText = queriedSeriesName ? `The ${queriedSeriesName} Series ` : '';
  const retailerLocationText =
    queriedRetailerCity && queriedRetailerState
      ? `, ${queriedRetailerCity} ${queriedRetailerState}`
      : '';
  const queriedDisplayHomeName = `${seriesText}${queriedModelName}${retailerLocationText}`;
  return (
    <Container className="cvc-site-search-results__result no-horizontal-padding no-horizontal-margin full-width">
      <div className="cvc-site-search-results__result-header">
        <span className="cvc-site-search-results__result-index result-left-margin">
          {indexDHItem}
        </span>
        <div className="cvc-site-search-results__result-header-label-link-container">
          <span className="cvc-site-search-results__result-label">{displayHomeLabel}: </span>
          <ResolverLink
            className="cvc-site-search-results__result-link"
            href={path}
            aria-label={`${modelName} ${modelNumber}`}
            dangerouslySetInnerHTML={{ __html: queriedDisplayHomeName }}
          />
        </div>
      </div>
      <div className={classNames('cvc-site-search-results__result-body')}>
        {imageUrl && <div>{showImage(imageUrl, imageAltText)}</div>}
        <div className="cvc-site-search-results__result-info cvc-site-search-results__result-features">
          <span className="cvc-site-search-results__result-display-home-price">
            {displayHomePriceLabel}:{' '}
            {showDisplayHomePrice(
              sellingPrice,
              availableStatus,
              markAsClearance,
              nowPrice,
              wasPrice
            )}
          </span>
          <br />
          <div className="cvc-site-search-results__result-info cvc-site-search-results__result-features">
            <div className="feature">
              <span className="feature-label">
                {numBedrooms}&nbsp;{bedroomsLabel}
                ,&nbsp;
                {numBathrooms}&nbsp;{bathroomsLabel}
                ,&nbsp;
                {squareFootage && squareFootage}
                &nbsp;{squareFootage && squareFeetLabel}
              </span>
              {(buildingMethodValue || sections) && <>{detailsCharacterConcat}&nbsp;</>}
            </div>
            {buildingMethodValue && (
              <div className="feature">
                <span className="feature-label">{homeTypeLabel}: </span> {buildingMethodValue}
                {sections && <>{detailsCharacterConcat}&nbsp;</>}
              </div>
            )}
            {sections && (
              <div className="feature">
                <span className="feature-label">{sectionsLabel}: </span> {sections}
              </div>
            )}
          </div>
          {isDesktop && displayResultFooter(false, url, specialFeatures)}
        </div>
      </div>
      {!isDesktop && displayResultFooter(true, url, specialFeatures)}
    </Container>
  );
}

function floorplanSearchResult(
  indexFPItem: number,
  document: SiteSearchFloorPlanDetail,
  siteSearchLabels: any,
  isDesktop: boolean,
  query: string
) {
  if (!document) {
    return null;
  }

  const {
    squareFootage,
    numBedrooms,
    numBathrooms,
    modelName,
    modelNumber,
    series,
    sections,
    buildingMethodValue,
    url,
    imageUrl,
    imageAltText,
    country,
    floorplanSellingFeatures,
  } = document;

  const {
    bedroomsLabel,
    floorplanLabel,
    seriesLabel,
    squareFeetLabel,
    bathroomsLabel,
    sectionsLabel,
    homeTypeLabel,
    countryLabel,
  } = siteSearchLabels;

  const path = url;

  const detailsCharacterConcat = isDesktop ? ' | ' : '';
  const queriedModelName = boldQuery(modelName, query);
  const queriedModelNumber = boldQuery(modelNumber, query);
  const queriedFloorPlanName = `${queriedModelName} ${queriedModelNumber}`;
  return (
    <Container className="cvc-site-search-results__result no-horizontal-padding no-horizontal-margin full-width">
      <div className="cvc-site-search-results__result-header">
        <span className="cvc-site-search-results__result-index result-left-margin">
          {indexFPItem}
        </span>
        <div className="cvc-site-search-results__result-header-label-link-container">
          <span className="cvc-site-search-results__result-label">{floorplanLabel}: </span>
          <ResolverLink
            className="cvc-site-search-results__result-link"
            href={path}
            aria-label={`${modelName} ${modelNumber}`}
            dangerouslySetInnerHTML={{ __html: queriedFloorPlanName }}
          />
        </div>
      </div>
      <div className={classNames('cvc-site-search-results__result-body')}>
        {imageUrl && <div>{showImage(imageUrl, imageAltText)}</div>}
        <div className="cvc-site-search-results__result-info cvc-site-search-results__result-features">
          <span className="cvc-site-search-results__result-beds-baths-sqft">
            {numBedrooms}&nbsp;{bedroomsLabel}
            ,&nbsp;
            {numBathrooms}&nbsp;{bathroomsLabel}
            ,&nbsp;
            {squareFootage && squareFootage}
            &nbsp;{squareFootage && squareFeetLabel}
          </span>
          <br />
          <div className="cvc-site-search-results__result-info cvc-site-search-results__result-features">
            {series && (
              <div className="feature">
                <span className="feature-label">{seriesLabel}: </span> {series}
                {buildingMethodValue && <>{detailsCharacterConcat}&nbsp;</>}
              </div>
            )}
            {buildingMethodValue && (
              <div className="feature">
                <span className="feature-label">{homeTypeLabel}: </span> {buildingMethodValue}
                {sections && <>{detailsCharacterConcat}&nbsp;</>}
              </div>
            )}
            {sections && (
              <div className="feature">
                <span className="feature-label">{sectionsLabel}: </span> {sections}
                {country && <>{detailsCharacterConcat}&nbsp;</>}
              </div>
            )}
            {country && (
              <div className="feature">
                <span className="feature-label">{countryLabel}: </span> {country}
              </div>
            )}
          </div>
          {isDesktop && displayResultFooter(false, url, floorplanSellingFeatures)}
        </div>
      </div>
      {!isDesktop && displayResultFooter(true, url, floorplanSellingFeatures)}
    </Container>
  );
}

function retailerSearchResult(
  indexRSItem: number,
  document: SiteSearchRetailerDetail,
  siteSearchLabels: any,
  isDesktop: boolean,
  query: string
) {
  if (!document) {
    return null;
  }

  const {
    locationName,
    community,
    address1,
    city,
    state,
    zip,
    phone,
    url,
    imageUrl,
    imageAltText,
    description,
  } = document;

  const queriedName = boldQuery(locationName, query);
  const queriedAddress1 = boldQuery(address1, query);
  const queriedCity = boldQuery(city, query);
  const queriedState = boldQuery(state, query);
  const queriedZip = boldQuery(zip, query);

  const RETAILER_LABEL = locationName;
  const { retailerLabel } = siteSearchLabels;
  const phoneFormatted = formatPhoneNumber(phone);

  const path = url;

  return (
    <Container className="cvc-site-search-results__result no-horizontal-padding no-horizontal-margin full-width">
      <div className="cvc-site-search-results__result-header">
        <span className="cvc-site-search-results__result-index result-left-margin">
          {indexRSItem}
        </span>
        <div className="cvc-site-search-results__result-header-label-link-container">
          <span className="cvc-site-search-results__result-label">{retailerLabel}:</span>
          <ResolverLink
            className="cvc-site-search-results__result-link"
            href={path}
            aria-label={locationName}
            dangerouslySetInnerHTML={{ __html: queriedName }}
            target={path.startsWith('/') ? '' : '_blank'}
          />
        </div>
      </div>
      <div className={classNames('cvc-site-search-results__result-body')}>
        <div>{showImage(imageUrl, imageAltText)}</div>
        <div className="cvc-site-search-results__result-info cvc-site-search-results__result-features is-retailer">
          <div className="feature">
            <span className="feature-label feature-title">
              {community ? COMMUNITY_LABEL : RETAILER_LABEL}
            </span>
          </div>
          <div className="feature">
            <span dangerouslySetInnerHTML={{ __html: queriedAddress1 }} /> ,&nbsp;
            <span dangerouslySetInnerHTML={{ __html: queriedCity }} />
            ,&nbsp;
            <span dangerouslySetInnerHTML={{ __html: queriedState }} /> &nbsp;
            <span dangerouslySetInnerHTML={{ __html: queriedZip }} />
            &nbsp;|&nbsp;{' '}
            <a href={`tel:${phone}`} className="feature-phone">
              {phoneFormatted}
            </a>
          </div>
          <div className="feature">
            {description?.value && <Description value={description?.value} />}
          </div>
          {isDesktop && displayResultFooter(false, url)}
        </div>
      </div>
      {!isDesktop && displayResultFooter(true, url)}
    </Container>
  );
}

const setIsSearching = (value: any) => {
  isSearching = value;
};

function DisplaySearchInput({ spinnerColorInLightBackground }: any) {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const searchParameters = useLocation().search;

  const redirectToSearchPage = async () => {
    await setIsSearching(true);

    if (searchText) {
      let newLoc = window.location.pathname;

      if (searchParameters) {
        const existing: any = queryString.parse(searchParameters).query;
        if (existing) {
          let newQuery = searchParameters.replace(
            `query=${existing}`,
            `query=${encodeURIComponent(searchText)}`
          );
          if (newQuery === searchParameters) {
            newQuery = searchParameters.replace(
              `query=${encodeURIComponent(existing).replace(/[!'()*]/g, escape)}`,
              `query=${encodeURIComponent(searchText)}`
            );
          }
          newLoc += newQuery;
        } else {
          newLoc += `${searchParameters}&query=${encodeURIComponent(searchText)}`;
        }
        await history.push(newLoc);
      } else {
        newLoc += `?query=${encodeURIComponent(searchText)}`;
        await history.push(newLoc);
      }
    } else {
      const siteSearchInputBox = document.getElementById(
        'cvc-site-search-results__search-input-box'
      );
      if (siteSearchInputBox) {
        (siteSearchInputBox as HTMLInputElement).focus();
      }
    }

    await setIsSearching(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      redirectToSearchPage();
    }
  };

  const displaySearchInputBox = () => {
    const handleHideSearchInput = () => {
      setSearchText('');
      const siteSearchInputBox = document.getElementById(
        'cvc-site-search-results__search-input-box'
      );
      if (siteSearchInputBox) {
        (siteSearchInputBox as HTMLInputElement).value = '';
        (siteSearchInputBox as HTMLInputElement).focus();
      }
    };

    let inputPlaceHolder = '';
    if (searchParameters) {
      inputPlaceHolder = queryString.parse(searchParameters).query
        ? queryString.parse(searchParameters).query!.toString()
        : '';
    }

    return (
      <div className="cvc-site-search-results__search-again-container">
        <div className="cvc-site-search-results__input-and-close-container">
          <input
            id="cvc-site-search-results__search-input-box"
            type="text"
            defaultValue={inputPlaceHolder}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
            className="cvc-site-search-results__input"
          />
          <CloseIcon
            className="cvc-site-search-results__input-close-icon"
            onClick={() => handleHideSearchInput()}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary d-inline-block"
          onClick={redirectToSearchPage}
          disabled={isSearching === true}
          tabIndex={isSearching ? -1 : 0}
        >
          Search Again
        </button>
        {isSearching ? (
          <Spinner
            color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
          />
        ) : (
          ''
        )}
        {/* <Spinner color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''} /> */}
      </div>
    );
  };

  return displaySearchInputBox();
}

export function SiteSearchResults(props: BrProps) {
  const searchParameters = useLocation().search;
  let currentPageIndexDefault = 1;
  if (searchParameters) {
    if (queryString.parse(searchParameters).page) {
      currentPageIndexDefault = Number(queryString.parse(searchParameters).page);
    }
  }
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPageIndexDefault);
  const windowDimensions = useWindowDimensions();
  // const history = useHistory();
  const {
    displayHomeDetailsListClearance,
    displayHomeDetailsListNonClearance,
    floorplanDetailsList,
    retailerDetailsList,
    siteSearchLabels,
    totalSize,
    query,
    spinnerConfig,
    // socialMediaIcons,
  } = props.component.getModels();
  const pageCount = Math.ceil(totalSize / siteSearchLabels.pageSize);
  const pages: Array<number> = [pageCount];
  for (let i = 0; i < pageCount; i += 1) {
    pages[i] = i + 1;
  }

  const details = props.page.getComponent('main', 'detailcontainer');
  const breadcrumb = details && details.getComponent('breadcrumb-stack');
  const heroBanner = details && details.getComponent('heroBanner');

  const displayHomeResults = displayHomeDetailsListClearance?.concat(
    displayHomeDetailsListNonClearance
  );

  const displayTitle = (numberResults: any, labelTitle: string, title: string) => {
    const titleValue = title ? ` ${title}` : '';

    return (
      <div className="cvc-site-search-results__title-container">
        <span className="cvc-site-search-results__label-title">
          {numberResults} {labelTitle}
        </span>
        <span className="cvc-site-search-results__title">{titleValue}</span>
      </div>
    );
  };

  const displayPaginationButtons = () => {
    const handlePageButtonClick = (pageNum: string) => {
      let newPageVal = currentPageIndex;
      if (pageNum === 'Previous') {
        if (currentPageIndex > 1) {
          newPageVal = currentPageIndex - 1;
        }
      } else if (pageNum === 'Next') {
        if (currentPageIndex < pages?.length) {
          newPageVal = currentPageIndex + 1;
        }
      } else {
        newPageVal = parseInt(pageNum, 10);
      }
      setCurrentPageIndex(newPageVal);

      let newLoc = window.location.pathname;
      const { search } = window.location;

      const params = new URLSearchParams(search);
      const pageVal = params.get('page');
      if (pageVal) {
        newLoc += search.replace(`page=${pageVal}`, `page=${newPageVal}`);
      } else if (search) {
        newLoc = `${newLoc + search}&page=${newPageVal}`;
      } else {
        newLoc = `${newLoc + search}?page=${newPageVal}`;
      }

      // history.push(newLoc);
      // (wrojas) - Temporal workaround so when the user click on pagination buttons the proper data is displayed
      window.location.replace(window.location.origin + newLoc);
    };

    const paginationButton = (customKey: string, customClasses: string, text: string) => {
      return (
        <button
          key={customKey}
          type="button"
          className={customClasses}
          onClick={() => handlePageButtonClick(text)}
          disabled={
            (text === 'Previous' && currentPageIndex === 1) ||
            (text === 'Next' && currentPageIndex === pages?.length)
          }
          value={text}
        >
          {text}
        </button>
      );
    };

    const displayNumericButtons = () => {
      return pages.map((item: any) => {
        return paginationButton(
          `button-${item}`,
          `cvc-site-search-results__pagination-button ${
            item === currentPageIndex ? 'with-border' : ''
          }`,
          item
        );
      });
    };

    return pages.length > 1 ? (
      <div className="cvc-site-search-results__pagination-buttons-container">
        {paginationButton(
          'pagination-previous-button',
          'cvc-site-search-results__pagination-button',
          'Previous'
        )}
        {displayNumericButtons()}
        {paginationButton(
          'pagination-next-button',
          'cvc-site-search-results__pagination-button',
          'Next'
        )}
      </div>
    ) : (
      ''
    );
  };
  const isDesktop = !windowDimensions.isMobile && !windowDimensions.isTablet;

  useEffect(() => {
    const generalBreadcrumb = document.body.querySelector(
      '.cvc-breadcrumb-container:not(.is-custom)'
    );
    if (generalBreadcrumb) {
      (generalBreadcrumb as HTMLDivElement).style.display = 'none';
    }

    const regularHeroBanner = document.body.querySelector(
      '.cvc-hero-banner:not(.with-custom-params)'
    );
    if (regularHeroBanner) {
      (regularHeroBanner as HTMLDivElement).style.display = 'none';
    }
  });

  let initialIndexValue = 1;
  if (currentPageIndexDefault > 1) {
    initialIndexValue = (currentPageIndexDefault - 1) * Number(siteSearchLabels.pageSize) + 1;
  }

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  return (
    <Container className="cvc-site-search-results">
      <div className="cvc-site-search-results__breadcrumb d-sm-block">
        {breadcrumb && (
          <CustomBreadcrumbStack props={{ page: props.page, component: breadcrumb }} />
        )}
      </div>
      {displayTitle(
        totalSize,
        query ? siteSearchLabels.resultsFor : siteSearchLabels.resultsNoFor,
        query
      )}
      <DisplaySearchInput spinnerColorInLightBackground={spinnerColorInLightBackground} />

      {displayHomeResults?.length > 0 ||
      floorplanDetailsList?.length > 0 ||
      retailerDetailsList?.length > 0 ? (
        <div className="cvc-site-search-results__pagination-and-results-container">
          {displayPaginationButtons()}

          {displayHomeResults.map((item: SiteSearchDisplayHomeDetail, indexDHItem: number) => (
            <div key={`${indexDHItem.toString()}-${item.modelName}`}>
              {displayHomeSearchResult(
                initialIndexValue + indexDHItem,
                item,
                siteSearchLabels,
                isDesktop,
                query
              )}
            </div>
          ))}

          {floorplanDetailsList.map((item: SiteSearchFloorPlanDetail, indexFPItem: number) => (
            <div key={`${indexFPItem.toString()}-${item.modelName}`}>
              {floorplanSearchResult(
                initialIndexValue + displayHomeResults?.length + indexFPItem,
                item,
                siteSearchLabels,
                isDesktop,
                query
              )}
            </div>
          ))}

          {retailerDetailsList.map((item: SiteSearchRetailerDetail, indexRDItem: number) => (
            <div key={`${indexRDItem.toString()}-${item.locationName}`}>
              {retailerSearchResult(
                initialIndexValue +
                  displayHomeResults?.length +
                  floorplanDetailsList?.length +
                  indexRDItem,
                item,
                siteSearchLabels,
                isDesktop,
                query
              )}
            </div>
          ))}

          {displayPaginationButtons()}
        </div>
      ) : (
        ''
      )}

      <Col className="cvc-site-search-results__hero-banner no-horizontal-padding">
        {heroBanner && (
          <HeroBannerWithParams
            page={props.page}
            component={heroBanner}
            customParams={{ displayBackground: false }}
          />
        )}
      </Col>
    </Container>
  );
}
