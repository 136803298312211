import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import { formatForHistory } from 'components/shared/ResolverLink';
import { RetailerFavoriteIcon } from 'components/shared/Icons';
import SiteGlobalSwitches from 'components/utils';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { formatPhoneNumber } from '../utils';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import './RetailerCards.scss';

function getLargeImageUrl(item: any, imgContent: any) {
  let largeImgPath = '';
  const alt = item.imageAltText;

  if (imgContent) {
    largeImgPath =
      imgContent.model.large && imgContent.model.large._links.site.href
        ? imgContent.model.large._links.site.href
        : null;
  } else if (item.ximage) {
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
  }

  return {
    largeImgPath,
    alt,
  };
}

function getFirstImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent: any = mediaAsset[0] && props.page.getContent(mediaAsset[0].image);
  return getLargeImageUrl(mediaAsset[0], imgContent);
}

function distanceSpan(distance: any, radiusUnit: any) {
  if (distance)
    return (
      <span className="cvc-retailer-card-details-distance ml-auto">
        {Math.round(distance + Number.EPSILON)} {radiusUnit}
      </span>
    );
}

export function RetailerCards(props: BrProps) {
  const history = useHistory();
  const {
    cards,
    retailerCardLabels: lbl,
    radiusUnit,
    myAccountConfig,
  } = props.component.getModels();
  const [idMarkerSelected, setIdMarkerSelected] = useState('');
  const { isMobile } = useWindowDimensions();

  // My Account
  const favoriteBaseUrl = myAccountConfig ? myAccountConfig.favoriteBaseUrl : null;
  const loadFavoriteUrl = myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : null;

  if (!cards) {
    return null;
  }

  const updateMarker = (id: string) => {
    const markerSelected = document.querySelector(`[title="marker-${id}"]`);

    if (markerSelected) {
      (markerSelected as HTMLDivElement).click();
    }

    setIdMarkerSelected(id);
  };

  const handleDetailsClick = (id: string, url: string) => {
    if (isMobile) {
      if (url.startsWith('/')) {
        history.push(formatForHistory(props.page.getUrl(url)));
      } else {
        window.open(url);
      }
    } else {
      updateMarker(id);
    }
  };

  return cards.map((item: any, index: any) => {
    const {
      locationName,
      locationID,
      address1,
      address2,
      city,
      state,
      zip,
      zip2,
      phone,
      distance,
      photos,
      community,
      imageUrl,
      imageAltText,
      url,
      locationTypeOverride,
    } = item;

    const theUrl = url;

    const name = locationName;

    const id = index + 1;
    const buttonLink = theUrl;
    let image;
    if (imageUrl) {
      image = { alt: imageAltText, largeImgPath: imageUrl };
    } else {
      image = getFirstImgUrl(props, photos);
    }
    const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
    const phoneFormatted = formatPhoneNumber(phone);
    const phoneLink = `tel:${phone}`;
    const butTextPark = lbl && lbl.buttonText_park ? lbl.buttonText_park : 'View model';
    const butText = lbl && lbl.buttonText ? lbl.buttonText : 'View homes';
    const isPark = window.location.href.includes('park');
    const buttonText = isPark ? butTextPark : butText;
    const favoriteKeyString = `retailer-card-favorite-${id}`;
    const linkTarget = url.startsWith('/') ? '' : '_blank';
    return (
      <Element
        key={id}
        id={`card-${id}`}
        name={`card-${id}`}
        className={`cvc-retailer-card${props.page.isPreview() ? ' has-edit-button' : ''}${
          id === idMarkerSelected ? ' active' : ''
        }`}
      >
        <Col className="cvc-retailer-card-image-container p-0">
          {showFavorites && (
            <RetailerFavoriteIcon
              key={favoriteKeyString}
              id={locationID}
              // isFavorite={isFavorite}
              baseUrl={favoriteBaseUrl}
              loadFavoriteUrl={loadFavoriteUrl}
              myAccountConfig={myAccountConfig}
            />
          )}

          <span onClick={() => handleDetailsClick(id, buttonLink)} aria-hidden="true">
            {image && (
              <div
                className={`cvc-retailer-card-image ${
                  props.page.isPreview() ? 'has-edit-button' : ''
                }`}
                role="img"
                aria-label={image.alt}
                style={{ backgroundImage: !image ? '' : `url(${image.largeImgPath})` }}
              />
            )}

            {locationTypeOverride ? (
              <div className={`cvc-retailer-card-tag ${locationTypeOverride.id}`}>
                {locationTypeOverride.label}
              </div>
            ) : (
              <div className={`cvc-retailer-card-tag${community ? ' is-community' : ''}`}>
                {community ? 'Community' : 'Retailer'}
              </div>
            )}
          </span>
        </Col>

        <Col
          className="cvc-retailer-card-details py-2"
          onClick={() => handleDetailsClick(id, buttonLink)}
        >
          <p className="cvc-retailer-card-details-name my-2">
            <ResolverLink href={theUrl} target={linkTarget}>{`${id}. ${name}`}</ResolverLink>
          </p>

          <div className="cvc-retailer-card-details-address d-flex">
            <p className="cvc-retailer-card-details-address-block">
              {address1}
              <br />
              {address2}
              {address2 && <br />}
              {city}, {state} {zip}
              {zip2 && `-${zip2}`} <br />
            </p>
            {distanceSpan(distance, radiusUnit)}
          </div>

          <div className="cvc-retailer-card-details-phone-button">
            <span className="cvc-retailer-card-details-phone">
              <PhoneIcon className="cvc-retailer-card-details-phone-icon" />
              <a href={phoneLink}>{phoneFormatted}</a>
            </span>
            <span className="cvc-retailer-card-button">
              <ResolverLink className="btn btn-primary" href={buttonLink} target={linkTarget}>
                {buttonText} <span className="icon cvc-icon--arrow-white-right sm-ml-2" />
              </ResolverLink>
            </span>
          </div>
        </Col>
      </Element>
    );
  });
}
