export function BuildCannonicalURL(urlParam: any, useWindowsHref = false) {
  if (useWindowsHref) {
    return window.location.href;
  }

  // just in case if url is undefined
  const url = !urlParam ? window.location.pathname : urlParam;
  let canon = url;
  if (!url?.startsWith('http')) {
    const locationArr = window.location.href.split('/');
    canon = `${locationArr[0]}//${locationArr[2]}${url}`;
  }

  const splits = canon?.split('/');
  if (splits[splits.length - 1] === splits[splits.length - 2]) {
    canon = canon.slice(0, canon.lastIndexOf('/'));
  }

  return canon;
}
