import React, { FunctionComponent, useState } from 'react';
import { BrPageContext, BrComponent } from '@bloomreach/react-sdk';
import { Button, Modal } from 'react-bootstrap';
import './RetailerDetails2.scss';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { getRetailerData } from 'components/utils';
import { FloorPlanRequestInfoWithParams } from 'components/FloorPlanRequestInfo/FloorPlanRequestInfo';

const RetailerDetails2: FunctionComponent = () => {
  const page = React.useContext(BrPageContext)!;
  const windowDimensions = useWindowDimensions();
  const arrayCompNames: string[] = [];
  const retailerData = getRetailerData(page);

  const address1Plus = retailerData?.address1.replace(/\s/g, '+');
  const address2Plus = retailerData?.address2
    ? `+${retailerData?.address2.replace(/\s/g, '+')}`
    : '';
  const cityPlus = retailerData?.city.replace(/\s/g, '+');
  const statePlus = retailerData?.state.replace(/\s/g, '+');

  const addressQuery = `${address1Plus}${address2Plus},${cityPlus}+${statePlus}+${retailerData?.zip}`;

  const displayComponentByName = (name: string) => {
    if (name === 'gallery') {
      return null;
    }

    return (
      <div className={`cvc-retailer-location-${name}`} id={name} key={`div-${name}`}>
        <BrComponent key={name} path={name} />
      </div>
    );
  };

  const [requestInfoModal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  const details = page.getComponent('main', 'detailcontainer');

  const form = details && details.getComponent('details');
  const apiKey = form?.getModels()?.googleMapConfig?.apiKey;

  const requestInfo = () => {
    const requestInfoDetails = details && details.getComponent('request-info');

    return (
      <div>
        <Button variant="primary" className=" m-0" onClick={openModal}>
          Request Info
        </Button>
        <Modal
          className="cvc-request-info-modal-retailer"
          show={requestInfoModal}
          onHide={closeModal}
          backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
          keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body id="id-modal-body" className="px-0">
            {form && (
              <FloorPlanRequestInfoWithParams
                page={page}
                component={requestInfoDetails}
                displayInsideModal
                formId="retailer-home-header-link-modal-form"
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const retailerManagerHeader = () => {
    if (!retailerData) {
      return null;
    }
    const {
      generalManagerName: managerName,
      generalManagerTitle,
      generalManagerPictureURL: imageURL,
    } = retailerData;

    if (!managerName) {
      return null;
    }
    const managerTitle = generalManagerTitle; // || lbl.gmTitle;
    return (
      <div className="cvc-retailer-manager">
        {imageURL && (
          <img className="cvc-retailer-manager-image" src={imageURL} alt={managerName} />
        )}
        <div className="cvc-retailer-manager-text">
          <p className="cvc-retailer-manager-name">{managerName}</p>
          <p className="cvc-retailer-manager-title">{managerTitle}</p>
        </div>
      </div>
    );
  };

  const getDescription = () => {
    const descriptionValue = retailerData?.description?.value || '';

    return (
      <div
        className="cvc-retailer-location-content-message-body"
        dangerouslySetInnerHTML={{
          __html: page.rewriteLinks(descriptionValue),
        }}
      />
    );
  };

  const getRetailerTitle = () => {
    return (
      <h2 className="cvc-retailer-location-content-message-head-title">
        Welcome to {retailerData?.name} in {retailerData?.city}, {retailerData?.state}!
      </h2>
    );
  };

  const showManagerHeader = retailerData && retailerData.generalManagerName;

  const getTitleRetailerManager = () => {
    return (
      <>
        <div className="cvc-retailer-location-content-message-head">
          {getRetailerTitle()}
          {showManagerHeader ? retailerManagerHeader() : <></>}
        </div>
        {getDescription()}
      </>
    );
  };

  const getDescriptionRequest = () => {
    return (
      <div className="cvc-retailer-location-content">
        <div className="cvc-retailer-location-content-message">{getTitleRetailerManager()}</div>
        <div className="cvc-retailer-location-content-form">
          {windowDimensions.isTablet || windowDimensions.isMobile ? (
            requestInfo()
          ) : (
            <BrComponent key="request-info" path="request-info" />
          )}
        </div>
      </div>
    );
  };

  const getGoogleMap = () => {
    return (
      <div className="cvc-retailer-location-map">
        <iframe
          className="cvc-retailer-location-map-iframe"
          title={retailerData?.name}
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${addressQuery}`}
          allowFullScreen
          aria-hidden="true"
        />
      </div>
    );
  };

  const displayComponentWithContainer = (components: any[], comp: any, containerName: string) => {
    const compsWithContainer =
      components &&
      components.some(
        (child: any) => child.getModels().componentParameterMap.container === containerName
      )
        ? components.filter(
            (child: any) => child.getModels().componentParameterMap.container === containerName
          )
        : [];

    if (comp.getModels().componentParameterMap.container === containerName) {
      arrayCompNames.push(comp.model.name);
    }

    if (arrayCompNames.length === compsWithContainer.length) {
      return (
        <>
          <BrComponent path="promos" />
          {getDescriptionRequest()}
          {getGoogleMap()}
        </>
      );
    }
  };

  const displayComponentWithoutContainer = (comp: any) => {
    return comp.model.name !== 'builder-exterior' && comp.model.name !== 'breadcrumb-stack'
      ? displayComponentByName(comp.model.name)
      : null;
  };

  const displayMainComponentsWrapper = (breadcrumb: any, int: any, ext: any) => (
    <div className="d-block interactive-builder-components" key="div-builder-promos">
      {
        // we don't need to display breadcrumb here when it's a retailer
        false && (
          <div id={breadcrumb.model.name}>
            <BrComponent key={breadcrumb.model.name} path={breadcrumb.model.name} />
          </div>
        )
      }
      <div className="builder-promos">
        <div className="promo" id={int.model.name}>
          <BrComponent key={int.model.name} path={int.model.name} />
        </div>
        <div className="promo" id={ext.model.name}>
          <BrComponent key={ext.model.name} path={ext.model.name} />
        </div>
      </div>
    </div>
  );

  const displayBrComponents = (pageModelName?: string) => {
    const pageName = pageModelName || '';
    const main =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'main');
    const retailerDetailContainer =
      main && main.getChildren().find((comp: any) => comp.getName() === 'detailcontainer');

    const components = retailerDetailContainer && retailerDetailContainer.getChildren();

    if (!components) {
      return null;
    }

    const breadcrumbStack = components.find((comp: any) => comp.model.name === 'breadcrumb-stack');
    const interiorPromo = components.find((comp: any) => comp.model.name === 'builder-interior');
    const exteriorPromo = components.find((comp: any) => comp.model.name === 'builder-exterior');

    return components.map((comp: any, index: number) => {
      const shouldDisplayMainComponentsWrapper: boolean =
        (pageName === 'interactive-builder' || pageName === 'interactive-builder-park') &&
        breadcrumbStack !== undefined &&
        interiorPromo !== undefined &&
        exteriorPromo !== undefined &&
        comp === interiorPromo &&
        (components[index + 1] as any) === exteriorPromo;
      const { componentParameterMap } = comp.getModels();
      const container: string =
        componentParameterMap && componentParameterMap.container
          ? componentParameterMap.container.toString()
          : undefined;
      if (container === undefined) {
        return shouldDisplayMainComponentsWrapper
          ? displayMainComponentsWrapper(breadcrumbStack, interiorPromo, exteriorPromo)
          : displayComponentWithoutContainer(comp);
      }
      return displayComponentWithContainer(components, components[index], container);
    });
  };

  const displayHeaderComponentsWithoutRetailerData = () => {
    const headerContent =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'retailer-header-content');

    const validHeaderComponents =
      headerContent &&
      headerContent.getChildren().filter((comp: any) => comp.getName() !== 'retailer-data');

    if (!validHeaderComponents) {
      return null;
    }
    return validHeaderComponents.map((comp: any) => {
      if (comp.model.name === 'header-component') {
        return <BrComponent key={comp.model.name} path={comp.model.name} />;
      }
      return '';
    });
  };

  const pageModelName = (page.getComponent() as any).model.name;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrComponent path="header-content" />
      <BrComponent path="parkmodel-header-content" />
      <BrComponent path="retailer-header-content">
        {displayHeaderComponentsWithoutRetailerData()}
      </BrComponent>
      <main
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-retailer">
          <BrComponent path="hero" />
          <BrComponent path="main">
            <BrComponent path="detailcontainer">
              <div className="no-horizontal-padding cvc-retailer-detail-container">
                <div className={`cvc-retailer-detail-component-container cvc-${pageModelName}`}>
                  {displayBrComponents(pageModelName)}
                </div>
              </div>
            </BrComponent>
          </BrComponent>
        </section>
      </main>
      <BrComponent path="footer-content" />
      <BrComponent path="parkmodel-footer-content" />
      <BrComponent path="retailer-footer-content" />
    </div>
  );
};

export default RetailerDetails2;
