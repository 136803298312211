import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Container, Col, Modal } from 'react-bootstrap';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { GlobalScrollToTopButton } from 'components/shared';
import SiteGlobalSwitches, {
  getCurrentSiteName,
  initAllFavoritesForCurrentUser,
  isFavoriteForUser,
  getOriginalSubId,
  isStringOnOrTrue,
} from 'components/utils';
import { getCookie } from 'components/hooks/cookie';
import { SignInModals } from 'components/MyAccount/SignInModals';
import SnackBar, {
  MESSAGE_SEVERITY,
  initSnackBarMessage,
  SnackBarMessage,
} from 'components/shared/SnackBar/SnackBar';
import { ComingSoonCrossStitchPictureDetail } from 'components/shared/Icons';
import { ImageCarousel } from '../shared/ImageCarousel/ImageCarousel';
import { ALBUM_MEDIA_TYPES, SITE_NAMES } from '../enums';
import { FloorPlanRequestInfoWithParams } from '../FloorPlanRequestInfo/FloorPlanRequestInfo';
import { BreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import { SharePage } from '../SharePage/SharePage';
import { getRetailerData } from '../utils';
import useWindowDimensions from '../hooks/useWindowDimensions';
import 'components/shared/ProductDetails/ProductBanner.scss';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-files-icon.svg';
import { ReactComponent as PrintIcon } from '../../assets/images/print-icon.svg';
import { ReactComponent as RequestIcon } from '../../assets/images/request-more-info.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/save-favorites-heart-icon.svg';

function FloorPlanBreadcrumb(props: any) {
  const hideLast = !!(props.hideLast && props.hideLast === 'true');

  return (
    <BreadcrumbStack
      page={props.page}
      component={props.component}
      maxLength={!props.retailer ? props.maxLength : undefined}
      hideLast={hideLast}
      displayScrollToTop
      overrides={[{ index: 2, label: 'Floor Plans' }]}
    />
  );
}

function FloorPlanIcon(props: any) {
  const requestSVG =
    props.icon === 'request' ? <RequestIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const printSVG =
    props.icon === 'print' ? <PrintIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const saveSVG =
    props.icon === 'save' ? <SaveIcon className="cvc-breadcrumb-page-cta-icon" /> : '';
  const saveActiveSVG =
    props.icon === 'save-active' ? (
      <SaveIcon className="cvc-breadcrumb-page-cta-icon heart-active" />
    ) : (
      ''
    );
  const button = props.jump ? (
    <Link
      to={props.link ?? 'download'}
      smooth
      offset={-150}
      duration={1000}
      className={props.classes ?? ''}
      id="download-button"
    >
      <DownloadIcon className="cvc-breadcrumb-page-cta-icon" />
      <p className="text floorplan-banner-icon__text">
        {props.title} <span className="label-secondary">{props.desc}</span>
      </p>
    </Link>
  ) : (
    <button
      type="button"
      className={`cvc-breadcrumb-page-cta-button ${` ${props.classes}` ?? ''}`}
      onClick={props.clickHandler}
      id={`${props.icon}-button`}
    >
      {requestSVG}
      {printSVG}
      {saveSVG}
      {saveActiveSVG}
      <p className="text floorplan-banner-icon__text">
        {props.title} <span className="label-secondary">{props.desc}</span>
      </p>
    </button>
  );

  return <>{button}</>;
}

function SaveFavorite(props: any) {
  const fpFavorite = isFavoriteForUser(props.assetId);
  const [favorite, setFavorite] = useState(fpFavorite);
  const userId = getCookie('cavcouserid');
  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);

  const [snackBarMessage, setSnackBarMessage] = useState(initSnackBarMessage);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  // eslint-disable-next-line
  // @ts-ignore
  const saveFavorite = async () => {
    const originalSubId = favorite ? getOriginalSubId(props.assetId) : props.subId;
    let favoriteUrl = originalSubId
      ? `${props.favoriteBaseUrl}/our_homes/${props.assetId}/${!favorite}?subId=${originalSubId}`
      : `${props.favoriteBaseUrl}/our_homes/${props.assetId}/${!favorite}`;
    if (props.subIdType) {
      favoriteUrl += favoriteUrl.includes('?')
        ? `&subIdType=${props.subIdType}`
        : `?subIdType=${props.subIdType}`;
    }

    setFavorite(!favorite);
    fetch(favoriteUrl, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === 400) {
          setFavorite(favorite);
          const snackBarMessageTemp: SnackBarMessage = {
            message: data.message,
            severity: MESSAGE_SEVERITY.WARNING,
          };
          setSnackBarMessage(snackBarMessageTemp);
          handleOpenSnackBar();
        } else {
          initAllFavoritesForCurrentUser(props.loadFavoriteUrl);
        }
        console.log('responseData:', data); // eslint-disable-line
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
      });
  };

  const handleSetSuccessfulSignIn = () => {
    saveFavorite();
  };

  const icon = props.icon === 'solid' ? 'save' : 'save-outline';
  return (
    <>
      <SnackBar
        openSnackBar={openSnackBar}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMessage={snackBarMessage}
      />
      <FloorPlanIcon
        classes="floorplan-banner-panel__button"
        title={favorite ? props.titleAdded : props.title}
        icon={favorite ? 'save-active' : icon}
        desc={favorite ? props.descriptionAdded : props.description}
        clickHandler={userId ? saveFavorite : openLoginModal}
      />
      {loginModal && (
        <SignInModals
          loginModalClosed={closeLoginModal}
          setSuccessfulSignIn={handleSetSuccessfulSignIn}
          myAccountConfig={props.myAccountConfig}
        />
      )}
    </>
  );
}

function RequestInfo(props: any) {
  const [request, setRequest] = useState(false);
  const closeModal = () => {
    setRequest(false);
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };
  const openModal = () => {
    setRequest(true);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
  };
  const windowDimensions = useWindowDimensions();

  const button =
    props.type === 'icon' ? (
      <FloorPlanIcon
        classes={`d-md-block ${props.classes ?? ''}`}
        icon="request"
        title="Request"
        desc="More Info"
        clickHandler={openModal}
      />
    ) : (
      <button
        type="button"
        className="btn btn-primary btn-primary-sm d-lg-none"
        onClick={openModal}
      >
        Request Info
      </button>
    );

  return (
    <>
      {button}
      <Modal
        className="floorplan-banner-modal cvc-request-info-modal"
        show={request}
        onHide={closeModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="id-modal-body">{props.modal}</Modal.Body>
      </Modal>
    </>
  );
}

function showPrice(startingString: any, price: any) {
  if (!price) return null;
  return (
    <Col className="floorplan-banner-starting-price-container d-none d-sm-flex d-md-none p-2">
      <Container>
        <div className="floorplan-banner-price-content d-flex align-items-center justify-content-end ml-auto ">
          <p className="floorplan-banner-price-title col-3 mr-2 text-right">{startingString}</p>
          <p className="floorplan-banner-price">{price}*</p>
        </div>
      </Container>
    </Col>
  );
}

export function getImageUrl(item: any, imgContent: any, size: string) {
  let imgPath = '';
  if (imgContent) {
    imgPath =
      imgContent.model[size] && imgContent.model[size].links.site.href
        ? imgContent.model[size].links.site.href
        : null;
  } else if (item.ximage) {
    imgPath = item.ximage[size] && item.ximage[size].url ? item.ximage[size].url : null;
  }

  return imgPath;
}

export function GetFirstImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent: any = mediaAsset[0] && props.page.getContent(mediaAsset[0].image);
  return getImageUrl(mediaAsset[0], imgContent, 'large');
}

export function GetFirstExteriorImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  let imgContent;
  let mediaAssetExterior;
  for (let i = 0; !mediaAssetExterior && i < mediaAsset.length; i += 1) {
    if (mediaAsset[i].imageType === 'Exterior Photo') {
      mediaAssetExterior = mediaAsset[i];
      imgContent = mediaAssetExterior && props.page.getContent(mediaAssetExterior.image);
    }
  }
  if (!mediaAssetExterior) return null;
  return getImageUrl(mediaAssetExterior, imgContent, 'large');
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  imgContent: any,
  index: any,
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {},
  mediaAssetItems: any[]
) {
  const imgLink = getImageUrl(item, imgContent, 'extraLarge');

  let displayMediaViewer = true;
  if (windowDimensions.isMobile || windowDimensions.isTablet) {
    displayMediaViewer = false;
    if (mediaAssetItems.length > 1) {
      const photoUrls = mediaAssetItems.map((assetItem: any) => {
        return {
          url: getImageUrl(assetItem, imgContent, windowDimensions.isTablet ? 'large' : 'medium'),
          alt: assetItem.imageAltText,
        };
      });
      return <ImageCarousel key="" photoUrls={photoUrls} lineDrawingUrl="" centered />;
    }
    return (
      <div
        key={index}
        className="floorplan-banner-image cvc-product-banner-image"
        role="img"
        style={{ backgroundImage: `url(${imgLink})` }}
      />
    );
  }

  const handleOnClick = () => {
    if (displayMediaViewer) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (displayMediaViewer) {
      if (e.keyCode === 32) {
        setIndexItemSelected(index);
        handleShowMediaViewer();
      }
    }
  };

  return (
    <div
      role="button"
      key={`${imageGalleryName}-image-${item.id}-${index}`}
      className="floorplan-banner-image cvc-product-banner-image"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
      style={displayMediaViewer ? { cursor: 'pointer', backgroundImage: `url(${imgLink})` } : {}}
      tabIndex={displayMediaViewer ? 0 : -1}
    >
      <span className="sr-only">View image gallery</span>
    </div>
  );
}

function displayMediaAssets(
  props: BrProps,
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  windowDimensions: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.IMAGE) return '';

  return mediaAssetItems.map((item: any, index) => {
    const imgContent: any = item && props.page.getContent(item.image);

    return (
      index === 0 &&
      displayAlbumMediaItem(
        imageGalleryName,
        item,
        imgContent,
        index,
        windowDimensions,
        setIndexItemSelected,
        handleShowMediaViewer,
        mediaAssetItems
      )
    );
  });
}

function FloorPlanBannerMain(
  props: BrProps,
  breadcrumb: any,
  modelName: any,
  modelNumber: any,
  numBathrooms: any,
  numBedrooms: any,
  squareFootage: any,
  photos: any,
  series: any,
  requestForm: any,
  image: any,
  socialMediaIcons: any,
  isRetailerPage: boolean,
  config: any,
  componentParameterMap: any
) {
  const maxLength =
    componentParameterMap && componentParameterMap.breadcrumbMaxLength
      ? componentParameterMap.breadcrumbMaxLength
      : 3;
  const isCommunity: boolean =
    componentParameterMap && componentParameterMap.isCommunity
      ? isStringOnOrTrue(componentParameterMap.isCommunity)
      : false;
  const hideLast: string | undefined =
    componentParameterMap && componentParameterMap.hideLast
      ? componentParameterMap.hideLast
      : undefined;
  const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
  const windowDimensions = useWindowDimensions();
  const displayBreadcrumbScrollToTop = windowDimensions.isTablet;

  const {
    documents,
    myAccountConfig,
    floorPlanBannerLabel: lbl,
    inStock,
    plantLocationUid,
  } = props.component.getModels();
  const fpDocument = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;
  let fpAssetId: any = null;
  let fpIsFavorite = false;
  let flrPlanWidthFeet = null;
  let flrPlanWidthInches = null;
  let flrPlanLengthFeet = null;
  let flrPlanLengthInches = null;
  if (fpDocument) {
    const fpData: FloorPlanData = fpDocument.getData<FloorPlanData>();
    fpAssetId = fpData.assetId;
    fpIsFavorite = fpData.isFavorite;
    flrPlanWidthFeet = fpData.flrPlanWidthFeet;
    flrPlanWidthInches = fpData.flrPlanWidthInches ? fpData.flrPlanWidthInches : '0';
    flrPlanLengthFeet = fpData.flrPlanLengthFeet;
    flrPlanLengthInches = fpData.flrPlanLengthInches ? fpData.flrPlanLengthInches : '0';
  }

  let subIdType = '';
  let locationID: any = null;
  if (isRetailerPage) {
    const retailerData = getRetailerData(props.page);

    if (retailerData && retailerData.locationID) {
      locationID = retailerData?.locationID;
      subIdType = 'subIdTypeRetailer';
    }
  } else if (plantLocationUid) {
    locationID = plantLocationUid;
    subIdType = 'subIdTypeBuildingCenter';
  }
  const detailContainer = props.page.getComponent('main', 'detailcontainer');
  const fpFiles = detailContainer && detailContainer.getComponent('fp-files');
  let showDownloadsButton = false;
  if (fpFiles) {
    const { hasDownloadableFiles } = fpFiles.getModels();
    showDownloadsButton = hasDownloadableFiles;
  }

  const keyString = locationID
    ? `floorplan-detail-favorite-${locationID}-${fpAssetId}`
    : `floorplan-detail-favorite-${fpAssetId}`;

  let pdfLink: any = null;
  if (fpAssetId) {
    pdfLink = `${config.pdfLinkUrl + fpAssetId}/`;
    if (isRetailerPage && locationID) {
      pdfLink = `${pdfLink + locationID}/`;
    }
    const currentLocation = window.location.href.split('#')[0].split('?')[0];
    pdfLink += `?${config.query}=${currentLocation}`;
  }

  const downloadPDF = () => {
    if (pdfLink) {
      window.location.href = pdfLink;
    }
  };

  const currentSite = getCurrentSiteName();

  const dimentionText =
    flrPlanWidthFeet && flrPlanLengthFeet
      ? `${flrPlanWidthFeet}'-${flrPlanWidthInches}" x ${flrPlanLengthFeet}'-${flrPlanLengthInches}", `
      : '';

  let floorplanSpec = '';
  if (currentSite === SITE_NAMES.CAVCO_DOT_COM) {
    floorplanSpec =
      (numBedrooms && `${numBedrooms} Bedrooms , `) +
      (numBathrooms && `${numBathrooms} Bathrooms , `) +
      (squareFootage && `${squareFootage} sq. ft.`);
  } else if (currentSite === SITE_NAMES.CAVCO_COMMUNITIES) {
    floorplanSpec =
      dimentionText +
      (numBedrooms && `${numBedrooms} bd. `) +
      (numBathrooms && `${numBathrooms} ba. `) +
      (squareFootage && `${squareFootage} sqft`);
  } else {
    floorplanSpec =
      (numBedrooms && `${numBedrooms} bd | `) +
      (numBathrooms && `${numBathrooms} ba |  `) +
      (squareFootage && `${squareFootage} sqft`);
  }

  return (
    <div className="floorplan-banner-content cvc-product-banner-content">
      <div className="floorplan-banner-content__container cvc-product-banner-content__container">
        <div className="cvc-product-banner-text">
          {breadcrumb && (
            <div className="cvc-product-banner-breadcrumb">
              <FloorPlanBreadcrumb
                page={props.page}
                component={breadcrumb}
                retailer={isRetailerPage}
                maxLength={maxLength}
                hideLast={hideLast}
              />
              <span className="cvc-product-banner-breadcrumb--last">
                Floor Plan: {`${modelName} ${modelNumber}`}
              </span>
            </div>
          )}

          <h1 className="cvc-product-banner-title">
            Floor Plan: <strong className="cvc-product-banner-title-lg">{modelName} </strong>
            <span className="cvc-product-banner-title-sm">{modelNumber}</span>
          </h1>
          <p className="floorplan-banner-details cvc-product-banner-details">{floorplanSpec}</p>

          <div className="cvc-product-banner-buttons">
            {!isRetailerPage && !isCommunity && (
              <Link
                className="btn btn-primary btn-primary-sm"
                to="availability"
                smooth
                offset={-150}
                duration={1000}
              >
                Where to Buy?
              </Link>
            )}

            {currentSite !== 'cavcodotcom' && !isCommunity && (
              <RequestInfo type="button" modal={requestForm} />
            )}

            {isRetailerPage && inStock && (
              <a className="btn btn-primary btn-primary-sm" href="../display-homes">
                In Stock
              </a>
            )}
          </div>
        </div>

        <div className="floorplan-banner-panel product-banner-panel floorplan-banner-panel__icons product-banner-panel__icons d-sm-flex flex-lg-row flex-md-column">
          {showFavorites && (
            <SaveFavorite
              key={keyString}
              icon="solid"
              classes="floorplan-banner-panel__button"
              favoriteBaseUrl={myAccountConfig ? myAccountConfig.favoriteBaseUrl : ''}
              assetId={fpAssetId}
              subId={locationID}
              isFavorite={fpIsFavorite}
              title={lbl.favoriteTitle}
              description={lbl.favoriteDescription}
              titleAdded={lbl.favoriteTitleAdded}
              descriptionAdded={lbl.favoriteDescriptionAdded}
              subIdType={subIdType}
              loadFavoriteUrl={myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : ''}
              myAccountConfig={myAccountConfig}
            />
          )}
          {requestForm && (
            <RequestInfo classes="floorplan-banner-panel__button" type="icon" modal={requestForm} />
          )}
          {showDownloadsButton && (
            <FloorPlanIcon
              title="Download"
              jump
              icon="download"
              desc="Files"
              classes="cvc-breadcrumb-page-cta-button floorplan-banner-panel__button"
            />
          )}
          <FloorPlanIcon
            classes=" floorplan-banner-panel__button"
            title="Print"
            icon="print"
            desc="Brochure"
            clickHandler={downloadPDF}
          />

          <SharePage
            classes=" floorplan-banner-panel__button"
            textClass=" floorplan-banner-icon__text"
            ogImage={image}
            icons={socialMediaIcons}
          />
          {displayBreadcrumbScrollToTop && (
            <GlobalScrollToTopButton className="scroll scroll-to-top-breadcrumb" />
          )}
        </div>
      </div>
    </div>
  );
}

// FloorPlanBanner - show banner with first exterior photo if one exists, or gray if there is none
export function FloorPlanBanner(props: BrProps) {
  const {
    documents,
    socialMediaIcons,
    retailerFloorPlanBannerConfig: config,
    componentParameterMap,
    contactSalesUrl,
  } = props.component.getModels();

  // const loadFavoriteUrl = myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : null;
  // initAllFavoritesForCurrentUser(loadFavoriteUrl);

  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;
  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('fp-request-form');

  const { hideBreadcrumb, showContactSales } = componentParameterMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const isRetailerPage = !!props.page.getComponent('retailer-header-content');

  if (!document) {
    return null;
  }

  const { modelName, modelNumber, numBathrooms, numBedrooms, squareFootage, photos, series } =
    document.getData<FloorPlanData>();

  const imageGalleryName = props.component.getName();
  const photosDocument = photos ? props.page.getContent(photos) : undefined;
  let mediaViewer;
  let mediaAssetDisplay;
  let imageGalleryId = imageGalleryName;
  const image = GetFirstImgUrl(props, photos);
  // if there is a banner image we set up linking to the gallery
  if (photosDocument && image) {
    const { id, title, mediaType, mediaAsset } = photosDocument.getData<AlbumData>();
    imageGalleryId += `-${id}`;
    mediaViewer = (
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={mediaAsset}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan
        indexItemSelected={indexItemSelected}
      />
    );
    mediaAssetDisplay = displayMediaAssets(
      props,
      imageGalleryName,
      mediaType,
      mediaAsset,
      windowDimensions,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  }

  const price = null;
  const startingString = 'This Floor Plan Starts From';
  const requestForm = form && (
    <FloorPlanRequestInfoWithParams
      page={props.page}
      component={form}
      displayInsideModal
      formId="floor-plan-banner-cta-form"
    />
  );
  let productPageType = '';
  if (!isRetailerPage) {
    productPageType = 'cvc-product-on-main';
  }

  return (
    <div>
      <div id={imageGalleryId} key={imageGalleryId}>
        {mediaViewer && mediaViewer}
        <div
          className={`floorplan-banner-container cvc-product-banner-container ${productPageType}`}
        >
          <div className={`floorplan-banner cvc-product-banner ${!image ? ' no-image' : ''}`}>
            <BrManageContentButton content={document} />
            <ComingSoonCrossStitchPictureDetail title="image of home coming soon" />
            {!image || !mediaAssetDisplay ? '' : mediaAssetDisplay}
            {FloorPlanBannerMain(
              props,
              breadcrumb,
              modelName,
              modelNumber,
              numBathrooms,
              numBedrooms,
              squareFootage,
              photos,
              series,
              requestForm,
              image,
              socialMediaIcons,
              isRetailerPage,
              config,
              componentParameterMap
            )}
          </div>
          {showPrice(startingString, price)}
        </div>
      </div>
      {contactSalesUrl && showContactSales && isStringOnOrTrue(showContactSales) && (
        <div>
          <div className="cvc-content-sticky">
            <div className="cvc-content-sticky__inner">
              <div className="cvc-content-sticky__rotated">
                <a href={contactSalesUrl} className="btn btn-primary btn-sticky">
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
