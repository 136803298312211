import React, { useEffect, FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import { BrPage, BrPageContext } from '@bloomreach/react-sdk';
import { GlobalScrollToTopButton } from 'components/shared';
import { LocalStorageProvider } from 'components/Header/LocalStorageContext';
import { PagePropertiesDesignCenter } from 'components/PageProperties/PagePropertiesDesignCenter';
import useWindowDimensions from '../hooks/useWindowDimensions';
import {
  Banner,
  Content,
  ContentCustomClick,
  Footer,
  NewsList,
  Header,
  MyAccountHeader,
  PageProperties,
  PanelAdsWithBrProps as PanelAds,
  BreadcrumbStack,
  BrRaceFix,
  Album,
  CarouselBanner,
  ContactSalesForm,
  HeroBanner,
  TileGrid,
  FloorPlanFacts,
  FloorPlanBanner,
  SingleColumn,
  FloorPlanFiles,
  FloorPlanDetails,
  FloorPlanPhotos,
  RetailerList,
  FloorPlan3DTours,
  FloorPlanVideos,
  FloorPlanLineDrawings,
  FloorPlanAvailability,
  FloorPlanDisclaimer,
  FloorPlanRequestInfo,
  FloorPlanPrice,
  RetailerDetails,
  RetailerLocationDetails,
  RetailerBanner,
  RetailerEvent,
  RetailerGallery,
  FloorPlanList,
  RequestForm,
  RetailerImageGallery,
  PageNotFound,
  RetailerPromoComponent,
  FeatureModels,
  FavoriteComponent,
  DisplayHomeList,
  BuildingCenterTileGrid,
  DisplayHomeDetails,
  DisplayHomeBanner,
  DisplayHomeFacts,
  DisplayHomeFiles,
  DisplayHomePhotos,
  DisplayHomeVideos,
  DisplayHomePresentedBy,
  DisplayHomeDisclaimer,
  DisplayHomeRequestInfo,
  DisplayHome3DTours,
  RecentlyViewedHomes,
  CavcohomesSingleColumn,
  SearchTabHero,
  BuildingCenterDisclaimers,
  BuildingCenterSeriesList,
  Quiz,
  SearchBanner,
  BuildingCenterBanner,
  BuildingCenterMarketingText,
  Accordion,
  CustomerSupportForm,
  RetailerSingleColumn,
  RetailerListMapper as RetailerListCH,
  CavcoRetailerFloorPlanDisclaimer,
  CavcoRetailerEvent,
  CavcoRetailerFloorPlanTitle,
  CavcoRetailerLocationDetails,
  CavcoRetailerBrands,
  FloorPlanResultsCMS,
  CavcoResultCardsList,
  CavcoRetailerPromoList,
  CavcoInteractiveBuilderPromo,
  CavcoDetailBanner,
  CavcoDetailFiles,
  CavcoDetailFacts,
  CavcoDetailVideos,
  CavcoGMinfoBanner,
  CavcoRetailerStickyBanner,
  CavcoInteractiveBuilder,
  CavcoDetailDisclaimer,
  AlertBar,
  FloorPlanListES,
} from '..';
import { SiteSearchResults } from '../SiteSearchResults/SiteSearchResults';
import { PagePropertiesFP } from '../PageProperties/PagePropertiesFP';
import { PagePropertiesRtl } from '../PageProperties/PagePropertiesRtl';
import { PagePropertiesBuildCenterHome } from '../PageProperties/PagePropertiesBuildCenterHome';
import { PagePropertiesBuildFloorPlans } from '../PageProperties/PagePropertiesBuildFloorplans';
import { PagePropertiesBuildSeriesPage } from '../PageProperties/PagePropertiesBuildSeriesPage';
import { PagePropertiesRtlDisplayHomeList } from '../PageProperties/PagePropertiesRtlDisplayHomeList';
import { PagePropertiesRtlDisplayHome } from '../PageProperties/PagePropertiesRtlDisplayHome';
import { PagePropertiesAlb } from '../PageProperties/PagePropertiesAlb';
import { PagePropertiesRtlGallery } from '../PageProperties/PagePropertiesRtlGallery';
import { PagePropertiesRtlFloorplans } from '../PageProperties/PagePropertiesRtlFloorplans';
import { PagePropertiesRtlBuilder } from '../PageProperties/PagePropertiesRtlBuilder';
import { PagePropertiesSearch } from '../PageProperties/PagePropertiesSearch';
import { FloorPlanSearchContent } from '../FloorPlanSearchContent/FloorPlanSearchContent';
import { InspirationGallery } from '../InspirationGallery/InspirationGallery';
import CavcoFloorPlanDetails from '../Templates/CavcoFloorPlanDetails/CavcoFloorPlanDetails';
import { FloorPlanAdditionalInfo } from '../FloorPlanAdditionalnfo/FloorPlanAdditionalInfo';
import { CavcoRecentlyViewedHomes } from '../CavcoRecentlyViewedHomes/CavcoRecentlyViewedHomes';
import { CavcoFloorPlanRequestInfo } from '../CavcoFloorPlanRequestInfo/CavcoFloorPlanRequestInfo';
import { CavcoFloorPlanAvailability } from '../FloorPlanAvailability/CavcoFloorPlanAvailability';

import { CavcoSearchComponent } from '../CavcoSearch/CavcoSearchComponent';
import { CavcoRetailerGallery } from '../RetailerGallery/CavcoRetailerGallery';
import ModalLaunchContextProvider from '../hooks/ModalLaunch.context';
import { CavcoPagePropertiesRtlInventoryList } from '../PageProperties/CavcoPagePropertiesRtlInventoryList';
import { CavcoPagePropertiesRtlFloorplans } from '../PageProperties/CavcoPagePropertiesRtlFloorplans';
import { CavcoPagePropertiesRtlGallery } from '../PageProperties/CavcoPagePropertiesRtlGallery';
import useLasso from '../../lasso';

declare let window: any;

function InvisibleComponent() {
  return null;
}

axios.interceptors.request.use((config) => ({ ...config, withCredentials: true }));

interface PageTemplateProps {
  page: any;
  setTheme: (theme: string) => void;
}

const templates: { [key: string]: FunctionComponent } = {
  base: SingleColumn,
  'floorplans-detail': FloorPlanDetails,
  'cavco-floorplans-detail': CavcoFloorPlanDetails,
  retailer: RetailerDetails,
  'page-not-found': PageNotFound,
  'display-home-detail': DisplayHomeDetails,
  'cavco-base': CavcohomesSingleColumn,
  'cavcohome-retailer': RetailerSingleColumn,
};

const getPageInfo10 = (page: any) => {
  const pageInfo = page.toJSON() as any;
  const { channel: { info: { props: { name = '' } = {} } = {} } = {} } = pageInfo || {};

  const { model: { meta: { params: { template = '' } = {} } = {} } = {} } =
    page.getComponent('header-content') ||
    page.getComponent('retailer-header-content') ||
    page.getComponent('parkmodel-header-content') ||
    {};

  const formattedThemeClass = name.toLowerCase().replace(/' '/g, '-');

  return { template: template as string, name: formattedThemeClass };
};

const PageTemplate10 = (props: PageTemplateProps) => {
  const { page, setTheme } = props;
  const pageInfo = getPageInfo10(page);
  const { name } = pageInfo;
  const templateName = pageInfo.template;

  const [template, setTemplate] = useState(templateName);

  useEffect(() => {
    setTemplate(templateName);
    setTheme(name);
  }, [name, templateName, setTheme]);

  const SelectedTemplate = templates[template || 'base'];

  return (
    <BrRaceFix>
      <LocalStorageProvider>
        <ModalLaunchContextProvider>
          <SelectedTemplate />
        </ModalLaunchContextProvider>
      </LocalStorageProvider>
    </BrRaceFix>
  );
};

export function App() {
  window.spaBaseUrl =
    window.spaBaseUrl !== undefined
      ? window.spaBaseUrl.replace(/\/$/, '')
      : process.env.REACT_APP_SPA_BASE_URL;

  window.cmsBaseUrl =
    (window.cmsBaseUrl && window.cmsBaseUrl.replace(/\/$/, '')) ||
    process.env.REACT_APP_CMS_BASE_URL;

  window.spaBaseUrl = window.spaBaseUrl.startsWith(window.location.origin)
    ? window.spaBaseUrl.replace(window.location.origin, '')
    : window.spaBaseUrl;

  // localhost configurations
  let configPath = `${window.location.pathname}${window.location.search}`;
  let configEndpoint = process.env.REACT_APP_CMS_BASE_URL + '/resourceapi';
  let configBaseUrl = window.location.origin;

  if (!window.location.origin.toLowerCase().includes('localhost')) {
    // this would work for the sole domain, ie tmp-cavcohomes, tmp-solitairehomes, etc
    configBaseUrl = '';
    configEndpoint = window.cmsBaseUrl;
    configPath = `/resourceapi${window.location.pathname}${window.location.search}`;

    // this is for fairmonthomes, fleetwoodhomes, etc. Those with tmp.cavco.bloomreach.cloud domain
    if (window.spaBaseUrl && window.spaBaseUrl.length > 1) {
      configPath = configPath.replace(window.spaBaseUrl, '');
    }
  }

  const configuration = {
    httpClient: axios as any,
    endpoint: configEndpoint,
    // endpoint: process.env.REACT_APP_BRXM_ENDPOINT,
    // REACT_APP_BRXM_ENDPOINT=http://localhost:8080/delivery/site/v1/channels/brxsaas/pages

    baseUrl: configBaseUrl,
    // cmsBaseUrl: window.cmsBaseUrl || process.env.REACT_APP_CMS_BASE_URL,
    // spaBaseUrl: window.spaBaseUrl,
    path: configPath,
    apiVersion: '1.0',
    // debug: true,
  };

  const mapping = {
    Banner,
    'Panel Ads': PanelAds,
    'Hero Banner': HeroBanner,
    'Inspiration Gallery': InspirationGallery,
    'Hero Banner Bar': InvisibleComponent,
    menu: Content,
    'Page Properties': PageProperties,
    'News List': NewsList,
    'Footer Component': Footer,
    'My Account Footer Component': Footer,
    'Header Component': Header,
    'Community Header Component': Header,
    'My Account Header Component': MyAccountHeader,
    'Breadcrumb Stack Component': BreadcrumbStack,
    'Hidden Breadcrumb Stack Component': InvisibleComponent,
    'Image Gallery': Album,
    'Album Detail': Album,
    'Carousel Banner': CarouselBanner,
    'Tile Grid': TileGrid,
    'Building Center State Tile Grid': TileGrid,
    'Community Building Center Tile Grid': TileGrid,
    'Floor Plan Facts': FloorPlanFacts,
    'Cavco Detail Facts': CavcoDetailFacts,
    'Floor Plan Photos': FloorPlanPhotos,
    'Floor Plan Banner': FloorPlanBanner,
    'Cavco Detail Banner': CavcoDetailBanner,
    'Floor Plan Files': FloorPlanFiles,
    'Cavco Detail Files': CavcoDetailFiles,
    'Retailer List': RetailerList,
    'Floor Plan Tours': FloorPlan3DTours,
    'Floor Plan Videos': FloorPlanVideos,
    'Floor Plan Line Drawings': FloorPlanLineDrawings,
    'Floor Plan Availability': FloorPlanAvailability,
    'Cavco Floor Plan Availability': CavcoFloorPlanAvailability,
    'Floor Plan Disclaimer': FloorPlanDisclaimer,
    'Floor Plan Form Request Info': FloorPlanRequestInfo,
    'Floor Plan Search Content': FloorPlanSearchContent,
    'Retailer Location Details': RetailerLocationDetails,
    'Floor Plan Price': FloorPlanPrice,
    'Retailer Banner': RetailerBanner,
    'Retailer Floor Plans Available': FloorPlanList,
    'Retailer Gallery': RetailerGallery,
    'Cavco Retailer Gallery': CavcoRetailerGallery,
    'Simple Content New Window': ContentCustomClick,
    'Retailer Event': RetailerEvent,
    'Floorplan List': FloorPlanList,
    'Request Form': RequestForm,
    'Contact Sales Form': ContactSalesForm,
    'Simple Content': Content,
    RichContent: Content,
    'Search Results': SiteSearchResults,
    'Floor Plan Metadata': PagePropertiesFP,
    'Retailer Metadata': PagePropertiesRtl,
    'Building Center Metadata': PagePropertiesBuildCenterHome,
    'Building Center Floorplans Metadata': PagePropertiesBuildFloorPlans,
    'Building Center Series Metadata': PagePropertiesBuildSeriesPage,
    'Retailer Gallery Metadata': PagePropertiesRtlGallery,
    'Retailer Display Home Metadata': PagePropertiesRtlDisplayHomeList,
    'Cavco Retailer Display Home Metadata': CavcoPagePropertiesRtlInventoryList,
    'Cavco Retailer Gallery Metadata': CavcoPagePropertiesRtlGallery,
    'Display Home Single Metadata': PagePropertiesRtlDisplayHome,
    'Retailer Floorplans Metadata': PagePropertiesRtlFloorplans,
    'Cavco Retailer Floorplans Metadata': CavcoPagePropertiesRtlFloorplans,
    'Retailer Builder Metadata': PagePropertiesRtlBuilder,
    'Album Metadata': PagePropertiesAlb,
    'Retailer Floor Plans': FloorPlanList,
    'Retailer Data': InvisibleComponent,
    'Building Center Data': InvisibleComponent,
    'Locations Request Info': InvisibleComponent,
    'Move in Ready Validator': InvisibleComponent,
    'Move in Ready Presented By': InvisibleComponent,
    'Move in Ready Photos': InvisibleComponent,
    'Move in Ready Tours': InvisibleComponent,
    'Detail Page Photos': InvisibleComponent,
    'Detail Page Tours': InvisibleComponent,
    'Retailer Disclaimer': FloorPlanDisclaimer,
    'Retailer Image Gallery': RetailerImageGallery,
    'Request Info Hidden': Content,
    'Site Search Metadata': PagePropertiesSearch,
    'Floorplan Search Metadata': PagePropertiesSearch,
    'Retailer Search Metadata': PagePropertiesSearch,
    'Floor Plan Validator': InvisibleComponent,
    'Retailer Validator': InvisibleComponent,
    'Display Home Validator': InvisibleComponent,
    'Retailer Promo Component': RetailerPromoComponent,
    'Feature Models': FeatureModels,
    'Floorplan Feature Models': FeatureModels,
    'My Favorite Lists': FavoriteComponent,
    'Retailer Homepage Display Homes List': DisplayHomeList,
    'Building Center Tile Grid': BuildingCenterTileGrid,
    'Retailer Display Homes': DisplayHomeList,
    'Display Home Banner': DisplayHomeBanner,
    'Display Home Facts': DisplayHomeFacts,
    'Display Home Files': DisplayHomeFiles,
    'Display Home Photos': DisplayHomePhotos,
    'Display Home Videos': DisplayHomeVideos,
    'Display Home Presented By': DisplayHomePresentedBy,
    'Display Home Disclaimer': DisplayHomeDisclaimer,
    'Display Home Form Request Info': DisplayHomeRequestInfo,
    'Display Home Tours': DisplayHome3DTours,
    'Recently Viewed Homes': RecentlyViewedHomes,
    'Floorplan Search Results': FloorPlanResultsCMS,
    'Building Center Disclaimers': BuildingCenterDisclaimers,
    SearchTabHero,
    'Building Center Series List': BuildingCenterSeriesList,
    'Building Center Floor Plans': FloorPlanList,
    'Floor Plan Additional Info': FloorPlanAdditionalInfo,
    'Cavco Detail Videos': CavcoDetailVideos,
    'Cavco Recently Viewed Homes': CavcoRecentlyViewedHomes,
    'Cavco Detail Form Request Info': CavcoFloorPlanRequestInfo,
    Quiz,
    SearchBanner,
    'Building Center Banner': BuildingCenterBanner,
    'Building Center Marketing Text': BuildingCenterMarketingText,
    Accordion,
    CustomerSupportForm,
    'Hero Banner Hidden': InvisibleComponent,
    'Retailer Search Results': RetailerListCH,
    'Site Search': CavcoSearchComponent,
    'Cavco Retailer Floor Plan Disclaimer': CavcoRetailerFloorPlanDisclaimer,
    'Cavco Retailer Event': CavcoRetailerEvent,
    'Cavco Retailer Floor Plan Title': CavcoRetailerFloorPlanTitle,
    'Cavco Retailer Location Details': CavcoRetailerLocationDetails,
    'Cavco MIR Brands': CavcoRetailerBrands,
    'Cavco Result Cards List': CavcoResultCardsList,
    'Retailer Promos': CavcoRetailerPromoList,
    'Retailer Interactive Builder Promo Component': CavcoInteractiveBuilderPromo,
    'Interactive Builder Check': InvisibleComponent,
    'Cavco GM Info Banner': CavcoGMinfoBanner,
    'Cavco Retailer Sticky Banner': CavcoRetailerStickyBanner,
    'Tabbed Interactive Builder': CavcoInteractiveBuilder,
    'Cavco Detail Disclaimer': CavcoDetailDisclaimer,
    'Alert Bar': AlertBar,
    'Floorplan List ES': FloorPlanListES,
    'Tile Grid Document Data': InvisibleComponent,
    'Design Center Metadata': PagePropertiesDesignCenter,
  };

  const [displayGlobalScrollToTop, setDisplayGlobalScrollToTop] = useState(true);
  const location = useLocation();
  const windowDimensions = useWindowDimensions();
  const { pathname } = location;
  const { isTablet } = windowDimensions;
  const pathnameSplit = pathname.split('/');
  const [siteThemeClass, setSiteThemeClass] = useState('');

  const retailerPage = pathname.includes('retailers');
  const floorPlanDetailPageFromSearch =
    pathname.includes('our-homes') || pathname.includes('our-park-models'); // will be true for Floor Plan Detail AND for Floor Plan List pages
  const floorPlanListPageFromSearch =
    floorPlanDetailPageFromSearch && location.search.includes('latitude'); // will only be true for floor plan list page
  const locationListFromSearch = retailerPage && location.search.includes('latitude'); // will only be true for Locations List page

  const retailerFloorPlanList =
    retailerPage && pathnameSplit[pathnameSplit.length - 1] === 'floorplans'; // will be true for retailer floor plan list only

  useEffect(() => {
    if (isTablet) {
      if (retailerFloorPlanList) setDisplayGlobalScrollToTop(false);
      if (retailerPage && !locationListFromSearch) setDisplayGlobalScrollToTop(false);
      if (floorPlanDetailPageFromSearch && !floorPlanListPageFromSearch)
        setDisplayGlobalScrollToTop(false);
    }
  }, [
    retailerPage,
    isTablet,
    retailerFloorPlanList,
    displayGlobalScrollToTop,
    location.search,
    floorPlanDetailPageFromSearch,
    floorPlanListPageFromSearch,
    locationListFromSearch,
  ]);

  useEffect(() => {
    const pool = new Map();

    async function processRequest(request: any) {
      let result = null;
      try {
        result = await window[request?.command](...request.payload);

        window.parent.postMessage(
          {
            result,
            id: request.id,
            state: 'fulfilled',
            type: 'brxm:response',
          },
          process.env.REACT_APP_SPA_BASE_URL!
        );
      } catch (error) {
        window.parent.postMessage(
          {
            result,
            id: request.id,
            state: 'rejected',
            type: 'brxm:response',
          },
          process.env.REACT_APP_SPA_BASE_URL!
        );
      }
    }

    function processResponse(poolInput: any, response: any) {
      if (!poolInput.has(response.id)) {
        return;
      }

      const [resolve, reject] = poolInput.get(response.id);
      poolInput.delete(response.id);

      if (response.state === 'rejected') {
        reject(response.result);

        return;
      }

      resolve(response.resolve);
    }

    window.addEventListener('message', async (event: any) => {
      if (event.origin !== process.env.REACT_APP_SPA_BASE_URL) {
        return;
      }

      // eslint-disable-next-line
      switch (event.data && event.data.type) {
        case 'brxm:request':
          return processRequest(event.data);
        case 'brxm:response':
          return processResponse(pool, event.data);
      }
    });
  });

  useEffect(() => {
    const rootEl = window.document.querySelector('html');
    const themeClass = siteThemeClass;
    if (!rootEl || themeClass === undefined || themeClass === '' || themeClass !== siteThemeClass) {
      return;
    }
    rootEl?.classList.add(themeClass);

    return () => rootEl?.classList.remove(themeClass);
  }, [siteThemeClass]);

  const setFavIcon = (name: string) => {
    if (name && name !== '') {
      const favIconName = `${name}-favicon.png`;
      const favicon: any = document.getElementById('favicon');
      if (favicon) {
        favicon.href = `${process.env.PUBLIC_URL}/static/${favIconName}`;
      }
    }
  };

  const handleThemeSetting = (name: string) => {
    setFavIcon(name);
    setSiteThemeClass(name);
  };

  const { lasso } = useLasso();

  const mainUrl = window.location.hostname;

  const urlToTrackingID: { [key: string]: string } = {
    'www.palmharbor.com': 'LAS-175372-01',
    'www.cavcohomes.com': 'LAS-175372-09',
    'www.cavco.com': 'LAS-175372-10',
    'www.fleetwoodhomes.com': 'LAS-175372-11',
    'www.fairmonthomes.com': 'LAS-175372-12',
    'www.friendshiphomesmn.com': 'LAS-175372-13',
    'www.solitairehomes.com': 'LAS-175372-14',
    'www.cavcomfg.com': 'LAS-175372-15',
    'dev.cavco.bloomreach.cloud': 'LAS-175372-16',
    'qa.cavco.bloomreach.cloud': 'LAS-175372-17',
    'prod.cavco.bloomreach.cloud': 'LAS-175372-18',
    'dev-cavcohomes.cavco.bloomreach.cloud': 'LAS-175372-19',
    'qa-cavcohomes.cavco.bloomreach.cloud': 'LAS-175372-20',
    'prod-cavcohomes.cavco.bloomreach.cloud': 'LAS-175372-21',
    'dev-solitairehomes.cavco.bloomreach.cloud': 'LAS-175372-22',
    'qa-solitairehomes.cavco.bloomreach.cloud': 'LAS-175372-23',
    'prod-solitairehomes.cavco.bloomreach.cloud': 'LAS-175372-24',
  };

  const lassoTrackingID = urlToTrackingID[mainUrl] || 'LAS-175372-00';

  try {
    lasso('setAccountId', lassoTrackingID)('track', {
      category: 'pageView',
      label: window.location.href,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const hasUtmId = urlParams.has('utm_id');
  const hasUtmCookie = document.cookie.includes('utm_id');
  const expirationDate = `max-age=31536000; path=/`; // expires in 1 year

  if (hasUtmId && !hasUtmCookie) {
    const nextYearDate = new Date();
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

    const utmParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term'];

    utmParams.forEach((param) => {
      const value = urlParams.get(param);
      if (value) {
        document.cookie = `${param}=${value}; ${expirationDate}`;
      }
    });
  }

  return (
    <div className="cvc-app">
      <a className="skip-to-content" href="#main">
        Skip to Content
      </a>
      <HelmetProvider>
        <BrPage configuration={configuration} mapping={mapping}>
          <BrPageContext.Consumer>
            {(page) => {
              return <PageTemplate10 page={page} setTheme={handleThemeSetting} />;
            }}
          </BrPageContext.Consumer>
        </BrPage>
      </HelmetProvider>
      {displayGlobalScrollToTop && <GlobalScrollToTopButton />}
    </div>
  );
}
