import React, { useState, useEffect } from 'react';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { useHistory } from 'react-router-dom';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { ReactComponent as SearchIcon } from '../../assets/images/search-bg-black.svg';
import './RetailerBanner.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getRetailerData, compareValues, isRetailerHomePage } from '../utils';
import { RetailerBanner2 } from './RetailerBanner2';

function getExtraLargeImageUrl(item: any, imgContent: any) {
  let extraLargeImgPath = '';
  if (imgContent) {
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.ximage) {
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
  }

  return extraLargeImgPath;
}

function getFirstImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos[0]) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent: any = mediaAsset[0] && props.page.getContent(mediaAsset[0].image);
  return getExtraLargeImageUrl(mediaAsset[0], imgContent);
}

function getFirstImgDescription(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos[0]) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  return mediaAsset[0].description;
}

enum DROPDOWN_ID {
  HOME_SIZE = 'dropdownHomesizeId',
  NUM_BEDROOM = 'dropdownNumBedroomId',
  NUM_BATHROOM = 'dropdownNumBathroomId',
}

let homeSizeItemSelected = '';
let numBedroomItemSelected = '';
let numBathroomItemSelected = '';
let homeSizeTitle = 'Home Size';
let numBedroomTitle = 'Bedrooms';
let numBathroomTitle = 'Baths';

const paramHomeSize = 'squareFootage';
let paramNumBathrooms = 'numBathrooms';
let paramNumBedrooms = 'numBedrooms';

let homeSizeFilterItems = [
  '999 sqft & under',
  '1000 - 1499 sqft',
  '1500 - 1799 sqft',
  '1800 - 1999 sqft',
  '2000+ sqft',
];
let homeSizeFilterBoundaries = [1000, 1500, 1800, 2000];

let floorPlanFinderUrlAppend = '/floorplans';

function setDropdownItemSelected(dropdownId: string, itemSelected: string) {
  if (dropdownId === DROPDOWN_ID.HOME_SIZE) {
    homeSizeItemSelected = itemSelected;
  } else if (dropdownId === DROPDOWN_ID.NUM_BEDROOM) {
    numBedroomItemSelected = itemSelected;
  } else if (dropdownId === DROPDOWN_ID.NUM_BATHROOM) {
    numBathroomItemSelected = itemSelected;
  }
}

function getHomeSizeItemSelected() {
  return homeSizeItemSelected;
}

function getNumBedroomItemSelected() {
  return numBedroomItemSelected;
}

function getNumBathroomItemSelected() {
  return numBathroomItemSelected;
}

function FilterDropdown({
  dropdownId,
  title,
  items,
  floorPlanFieldName = null,
  filtersFromFPResourceBundle = null,
}: {
  dropdownId: string;
  title: string;
  items: any;
  floorPlanFieldName?: any;
  filtersFromFPResourceBundle?: any;
}) {
  const [dropDownTitle, setDropDownTitle] = useState(title);

  useEffect(() => {
    let filtersAsArray = [];
    let filtersOrderAscending: any[] = [];

    if (title !== dropDownTitle) {
      if (filtersFromFPResourceBundle) {
        filtersAsArray =
          filtersFromFPResourceBundle.values && filtersFromFPResourceBundle.values.includes(',')
            ? filtersFromFPResourceBundle.values.split(',')
            : new Array(filtersFromFPResourceBundle.values);
        filtersOrderAscending = filtersAsArray.sort(compareValues(floorPlanFieldName));
      }

      if (filtersFromFPResourceBundle) {
        if (filtersOrderAscending.length > 0) {
          if (!filtersOrderAscending.some((filter: any) => filter === dropDownTitle)) {
            setDropdownItemSelected(
              dropdownId,
              filtersOrderAscending[filtersOrderAscending.length - 1]
            );
          } else {
            setDropdownItemSelected(dropdownId, dropDownTitle);
          }
        }
      } else {
        setDropdownItemSelected(dropdownId, dropDownTitle);
      }
    }
  });

  return (
    <>
      <DropdownButton
        alignRight
        id={dropdownId}
        className="cvc-find-a-floor-plan-filter"
        title={dropDownTitle}
      >
        <Dropdown.Item
          key={title}
          as="button"
          eventKey={title}
          onClick={() => setDropDownTitle(title)}
        >
          {title}
        </Dropdown.Item>
        {items.map((item: string) => (
          <Dropdown.Item
            key={item}
            as="button"
            eventKey={item}
            onClick={() => setDropDownTitle(item)}
          >
            {item}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
}

export function RetailerBanner(props: BrProps) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const {
    retailerFloorPlanFinderLabels: lbl,
    componentParameterMap: paramMap,
    fpBedroomsFiltersStd,
    fpBathsFiltersStd,
    // socialMediaIcons,
  } = props.component.getModels();

  const details = props.page.getComponent('main', 'detailcontainer');

  const { hideBreadcrumb } = paramMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const showFloorplanFinder = paramMap.floorplanFinder && paramMap.floorplanFinder === 'on';
  const showSmallerImage = !paramMap.tallerImage || paramMap.tallerImage === 'off';
  const bedroomsFiltersFromResourceBundle = fpBedroomsFiltersStd || null;
  const bathroomsFiltersFromResourceBundle = fpBathsFiltersStd || null;

  const retailerData = getRetailerData(props.page);
  const event = retailerData && retailerData.event;
  const photos = retailerData && retailerData.photos;
  const image = photos && getFirstImgUrl(props, photos);

  useEffect(() => {
    if (!windowDimensions.isMobile && !windowDimensions.isTablet && !image && event) {
      const eventElement = document.getElementById('event');
      const retailerEventWrapper =
        eventElement && eventElement.querySelector('.cvc-retailer-event-row-desktop-wrapper');
      if (retailerEventWrapper) {
        if (!windowDimensions.isMobile || !windowDimensions.isTablet) {
          (retailerEventWrapper as HTMLDivElement).classList.add('heroless');
        }
      }
    }
  });

  if (!retailerData) return null;

  if (isRetailerHomePage(props.page)) {
    // return RetailerBanner2(props);
    return <RetailerBanner2 {...props} />;
  }

  let { homeSizeList, numBathroomsSet, numBedroomsList } = retailerData;
  let findAFloorPlanTitle = 'FIND A FLOOR PLAN';

  if (lbl) {
    homeSizeTitle = lbl.homeSizeTitle;
    numBedroomTitle = lbl.numBedrooms;
    numBathroomTitle = lbl.numBathrooms;
    findAFloorPlanTitle = lbl.findAFloorPlan;
    homeSizeFilterBoundaries = lbl.homeSizeFilterBoundaries
      ? lbl.homeSizeFilterBoundaries.split(',')
      : homeSizeFilterBoundaries;
    homeSizeFilterItems = lbl.homeSizeFilterItems
      ? lbl.homeSizeFilterItems.split(',')
      : homeSizeFilterItems;
    paramNumBathrooms = lbl.paramNumBathrooms;
    paramNumBedrooms = lbl.paramNumBedrooms;
    floorPlanFinderUrlAppend = lbl.searchAppendUrl;
  }
  homeSizeItemSelected = homeSizeTitle;
  numBedroomItemSelected = numBedroomTitle;
  numBathroomItemSelected = numBathroomTitle;

  if (!homeSizeList) homeSizeList = [];
  if (!numBathroomsSet) numBathroomsSet = [];
  if (!numBedroomsList) numBedroomsList = [];

  const newListNumbers = [0];
  newListNumbers.length = 0;
  const homeSizeListNumbers = homeSizeList.map((sizeString: string) => Number(sizeString));
  for (
    let i = 0;
    i < homeSizeListNumbers.length;
    i++ // eslint-disable-line
  ) {
    for (
      let b = 0;
      b < homeSizeFilterBoundaries.length;
      b++ // eslint-disable-line
    ) {
      if (b === 0) {
        if (homeSizeListNumbers[i] < homeSizeFilterBoundaries[b]) {
          if (!newListNumbers.includes(b)) {
            newListNumbers.push(b);
          }
          continue; // eslint-disable-line
        }
      } else if (b === homeSizeFilterBoundaries.length - 1) {
        if (homeSizeFilterBoundaries[b] <= homeSizeListNumbers[i]) {
          if (!newListNumbers.includes(b + 1)) {
            newListNumbers.push(b + 1);
          }
          continue; // eslint-disable-line
        }
      }

      if (
        homeSizeFilterBoundaries[b] <= homeSizeListNumbers[i] &&
        homeSizeListNumbers[i] < homeSizeFilterBoundaries[b + 1]
      ) {
        if (!newListNumbers.includes(b + 1)) {
          newListNumbers.push(b + 1);
        }
      }
    }
  }

  const newHomeSizeFilterItems = [''];
  newHomeSizeFilterItems.length = 0;
  newListNumbers.sort().forEach((i) => {
    newHomeSizeFilterItems.push(homeSizeFilterItems[i]);
  });

  const findAFloorPlan = () => {
    let gotoParams = '';
    if (getHomeSizeItemSelected() !== homeSizeTitle) {
      const selectedHomeSize = getHomeSizeItemSelected();
      const bounds = selectedHomeSize.match(/^\d+|\d+\b|\d+(?=\w)/g);
      if (bounds) {
        const boundaries = bounds.map((v) => +v);
        if (boundaries.length === 1) {
          if (selectedHomeSize === homeSizeFilterItems[0]) {
            gotoParams += `${paramHomeSize}=-${boundaries[0]}`;
          } else {
            gotoParams += `${paramHomeSize}=${boundaries[0]}gt`;
          }
        } else if (boundaries.length === 2) {
          gotoParams += `${paramHomeSize}=${boundaries[0]}-${boundaries[1]}`;
        }
      }
    }
    if (getNumBedroomItemSelected() !== numBedroomTitle) {
      gotoParams +=
        gotoParams === ''
          ? `${paramNumBedrooms}=${getNumBedroomItemSelected().replace('+', 'gt')}`
          : `&${paramNumBedrooms}=${getNumBedroomItemSelected().replace('+', 'gt')}`;
    }
    if (getNumBathroomItemSelected() !== numBathroomTitle) {
      gotoParams +=
        gotoParams === ''
          ? `${paramNumBathrooms}=${getNumBathroomItemSelected().replace('+', 'gt')}`
          : `&${paramNumBathrooms}=${getNumBathroomItemSelected().replace('+', 'gt')}`;
    }

    let gotoUrl =
      window.location.pathname.slice(-1) === '/'
        ? window.location.pathname.slice(0, -1)
        : window.location.pathname;

    if (gotoUrl.indexOf(floorPlanFinderUrlAppend) < 0) {
      gotoUrl += floorPlanFinderUrlAppend;
    }

    gotoUrl += gotoParams === '' ? '' : `?${gotoParams}`;

    // (wrojas) - Temporal workaround which allows user to go from Retailer Home page to Retailer Floorplans
    // const { origin } = window.location;
    // window.location.replace(origin + gotoUrl);

    history.push(gotoUrl);
  };

  const panelClass = classNames(
    `cvc-retailer-banner${image ? '' : ' no-image'} ${
      props.page.isPreview() ? ' has-edit-button' : ''
    } ${event && !showSmallerImage ? ' has-event-below' : ''}`
  );

  const displayBreadcrumb = (breadcrumbInput: any) => {
    const breadcrumbBody = () => {
      return (
        <div>
          {breadcrumb && (
            <div className="cvc-retailer-banner-breadcrumb d-sm-block">
              <CustomBreadcrumbStack
                props={{ page: props.page, component: breadcrumbInput }}
                customProps={{ displayScrollToTop: true }}
              />
            </div>
          )}
        </div>
      );
    };

    return breadcrumbBody();
  };

  return (
    <div className={panelClass}>
      <Row className="cvc-retailer-banner-image-container d-block m-0">
        <div
          className={classNames(
            `cvc-retailer-banner-image${props.page.isPreview() ? 'has-edit-button' : ''}`,
            showSmallerImage ? 'is-smaller-image' : '',
            !image ? 'heroless' : ''
          )}
          role="img"
          style={{ backgroundImage: !image ? '' : `url(${image})` }}
          title={getFirstImgDescription(props, photos) ?? ''}
        />
      </Row>

      {isRetailerHomePage(props.page) && showFloorplanFinder && (
        <div className="cvc-retailer-banner-panel-container">
          <div className="cvc-retailer-banner-panel">
            {breadcrumb && displayBreadcrumb(breadcrumb)}
            <div className="cvc-find-a-floor-plan-container">
              <Row className="cvc-find-a-floor-plan-row">
                <Col className="cvc-find-a-floor-plan-title">{findAFloorPlanTitle}</Col>
                <Col className="cvc-find-a-floor-plan-filters-container">
                  <div className="cvc-find-a-floor-plan-div">
                    <FilterDropdown
                      dropdownId={DROPDOWN_ID.HOME_SIZE}
                      title={homeSizeTitle}
                      items={newHomeSizeFilterItems}
                    />
                  </div>
                  <div className="cvc-find-a-floor-plan-div">
                    <FilterDropdown
                      dropdownId={DROPDOWN_ID.NUM_BEDROOM}
                      title={numBedroomTitle}
                      items={numBedroomsList}
                      floorPlanFieldName={
                        bedroomsFiltersFromResourceBundle &&
                        bedroomsFiltersFromResourceBundle.floorPlanField
                      }
                      filtersFromFPResourceBundle={bedroomsFiltersFromResourceBundle}
                    />
                  </div>
                  <div className="cvc-find-a-floor-plan-div">
                    <FilterDropdown
                      dropdownId={DROPDOWN_ID.NUM_BATHROOM}
                      title={numBathroomTitle}
                      items={numBathroomsSet}
                      floorPlanFieldName={
                        bathroomsFiltersFromResourceBundle &&
                        bathroomsFiltersFromResourceBundle.floorPlanField
                      }
                      filtersFromFPResourceBundle={bathroomsFiltersFromResourceBundle}
                    />
                  </div>
                  <div className="cvc-find-a-floor-plan-search">
                    <div role="presentation" onClick={findAFloorPlan}>
                      <SearchIcon className="cvc-find-a-floor-plan-search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
