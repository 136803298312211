export const inventoryDoc = (data: any) => {
  const details = data.page.getComponent('main', 'detailcontainer');
  const mirData = details && details.getComponent('inventory-data');
  const docRef = mirData && mirData.getModels().document;
  return data.page.getContent(docRef);
};

export const inventoryToFloorplanMapper = (data: any) => {
  const { documents, nodeTypes } = data.component.getModels();
  let videoRefList;

  if (nodeTypes === 'cavco:Inventory') {
    const inventoryDocument = inventoryDoc(data);
    const { matterport3dTours, panatours, videoTours /* panatourUrls */ } =
      inventoryDocument.getData();
    videoRefList = { toursRef: matterport3dTours, panatoursRef: panatours, videosRef: videoTours };
  } else {
    const ref = documents && documents[0];
    const fpDocument = ref && data.page.getContent(ref);
    const { videos } = fpDocument.getData();
    const { tours, panatours } = fpDocument.getData();
    videoRefList = { toursRef: tours, panatoursRef: panatours, videosRef: videos };
  }
  return videoRefList;
};
