// eslint-disable-next-line
// @ts-ignore
import { Subject } from 'rxjs';

export const loginSubject$ = new Subject();

export const LoginStatus = {
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
};
