import React, { useState } from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { MediaViewer } from '../MediaViewer/MediaViewer';
import 'components/shared/ProductDetails/ProductVideos.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { displayYoutubeVideo, getDisplayHomeDataModel } from '../utils';

function displayTitle(title: string) {
  if (!title) return '';

  return <h2 className="cvc-product-videos-title">{title}</h2>;
}

function displayAlbumMediaItem(
  imageGalleryName: string,
  item: any,
  index: any,
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  const handleOnClick = () => {
    setIndexItemSelected(index);
    handleShowMediaViewer();
  };
  const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      setIndexItemSelected(index);
      handleShowMediaViewer();
    }
  };

  return (
    <div
      role="presentation"
      key={`${imageGalleryName}-videos-container-${index.toString()}`}
      className="cvc-product-videos-item-container"
      onClick={handleOnClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
    >
      <div
        key={`${imageGalleryName}-video-${index}`}
        className="cvc-product-videos-media-item"
        tabIndex={-1}
      >
        {item.videoURL ? displayYoutubeVideo(item) : ''}
      </div>
      <div
        key={`${imageGalleryName}-display-home-video-item-link-${item.id}`}
        className="cvc-product-videos-item-link"
        tabIndex={-1}
      >
        <VideoIcon
          key={`${imageGalleryName}-display-home-video-item-icon-${item.id}`}
          className="cvc-product-videos-item-icon"
        />
      </div>
    </div>
  );
}

function displayMediaAssets(
  imageGalleryName: string,
  mediaType: string,
  mediaAssetItems: [MediaAssetCompound],
  setIndexItemSelected: any,
  handleShowMediaViewer = () => {}
) {
  if (mediaType !== ALBUM_MEDIA_TYPES.VIDEO) return '';

  return mediaAssetItems.map((item: any, index) => {
    return displayAlbumMediaItem(
      imageGalleryName,
      item,
      index,
      setIndexItemSelected,
      handleShowMediaViewer
    );
  });
}

export function DisplayHomeVideos(props: BrProps) {
  const [showMediaViewer, setShowMediaViewer] = useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const windowDimensions = useWindowDimensions();

  const { displayHomeVideoLabels, spinnerConfig } = props.component.getModels();

  const imageGalleryName = props.component.getName();

  const { displayHomeData: dhData } = getDisplayHomeDataModel(props.page);
  const ref = dhData && dhData.videoAlbum;
  const videosDocument = ref ? props.page.getContent(ref) : undefined;

  if (!videosDocument) {
    return null;
  }

  const { id, title, mediaType, mediaAsset } = videosDocument.getData<AlbumData>();

  const videosTitle =
    displayHomeVideoLabels && displayHomeVideoLabels.title
      ? displayHomeVideoLabels.title
      : 'Videos';

  const displayHomePhotosClasses = classNames({
    'cvc-display-home-videos cvc-product-videos': true,
    container:
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig,
  });

  const mediaAssetItems = mediaAsset;
  const noVideos = mediaAsset.find((item: any) => item.videoURL !== '') === undefined;

  if (noVideos) {
    return null;
  }

  const oneVideo = mediaAssetItems.length === 1;

  let limitedMediaAssetItems = null;

  if (mediaAssetItems.length > 3) {
    limitedMediaAssetItems = mediaAssetItems.slice(0, 3) as [MediaAssetCompound];
  } else {
    limitedMediaAssetItems = mediaAssetItems;
  }

  const dhItemsContainerClasses = classNames({
    'cvc-product-videos-items-container': true,
    'one-item': oneVideo,
  });

  const spinnerColorInDarkBackground = spinnerConfig && spinnerConfig.spinner_color_dark_background;

  return (
    <div
      id={`${imageGalleryName}-${id}`}
      className={displayHomePhotosClasses}
      key={`${imageGalleryName}-${id}`}
    >
      <MediaViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={mediaType}
        mediaAssetItems={limitedMediaAssetItems}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerColorInDarkBackground}
      />
      {displayTitle(videosTitle)}
      <div className={dhItemsContainerClasses}>
        {displayMediaAssets(
          imageGalleryName,
          mediaType,
          limitedMediaAssetItems,
          setIndexItemSelected,
          handleShowMediaViewer
        )}
      </div>
    </div>
  );
}
