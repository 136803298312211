import { BrProps } from '@bloomreach/react-sdk';
import SiteGlobalSwitches, { currencyFormatter } from 'components/utils';
import React from 'react';
import './FloorPlanPrice.scss';

export function FloorPlanPrice(props: BrProps) {
  const { documents } = props.component.getModels();
  const ref = documents[0];
  const document = ref ? props.page.getContent(ref) : undefined;

  if (SiteGlobalSwitches.showFloorPlanPricing && document) {
    const { startingPrice } = document.getData<FloorPlanData>();
    if (startingPrice) {
      return (
        <div className="cvc-fp-price">
          <div>
            <span className="fp-price-starting-text">Starts in the </span>
            <span className="fp-price-amount">
              <strong>
                {currencyFormatter.format(startingPrice)}&apos;s
                <span className="price-asterisk">**</span>
              </strong>
            </span>
          </div>
        </div>
      );
    }
  }
  return null;
}
