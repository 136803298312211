import React, { useState } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Modal, Button } from 'react-bootstrap';
import SiteGlobalSwitches, {
  formatBytes,
  getCurrentBloomreachChannelName,
  getSeriesDecorImages,
  getUrlWithBasePath,
  isSinglePlantWebsite,
  validateFileCompounds,
  isStringOnOrTrue,
} from 'components/utils';
import 'components/shared/ProductDetails/ProductFacts.scss';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { BLOOMREACH_CHANNEL_NAMES } from 'components/enums';
import { ResolverLink } from 'components/shared';

const getSeries = (props: BrProps) => {
  const { series } = props.component.getModels();
  return series;
};

function listSeriesDecorImages(albumImages: any) {
  return albumImages.map((mediaAsset: any, index: number) => {
    const { description, ximage } = mediaAsset;
    const image = ximage && ximage.extraLarge;
    const url = image && image.url;
    const fileSize = image && image.size;

    if (url === null || url.length === 0 || description === null || description.length === 0) {
      return null;
    }
    const fileSizeStr = formatBytes(fileSize);
    return (
      <li key={`image-${index.toString()}`}>
        <ResolverLink className="decor-image" target="_self" rel="noopener noreferrer" href={url}>
          {description}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function listSeriesAssetDownloads(assets: any) {
  return assets.map((asset: any, indexPdf: number) => {
    const { id, uploadSize: fileSize, uploadFileName, fileName } = asset;
    if (
      fileName === null ||
      fileName.length === 0 ||
      uploadFileName === null ||
      uploadFileName.length === 0
    )
      return null;
    const fileSizeStr = formatBytes(fileSize);

    return (
      <li key={`asset-${id}-${indexPdf.toString()}`}>
        <ResolverLink target="_self" rel="noopener noreferrer" href={uploadFileName}>
          {fileName}
        </ResolverLink>{' '}
        ({fileSizeStr})
      </li>
    );
  });
}

function DownloadFiles(props: any) {
  const hasAssets = props.pdfs && validateFileCompounds(props.pdfs);
  const hasImages = props.decorImages && props.decorImages.length > 0;
  const hasFiles = hasAssets || hasImages;
  if (hasFiles) {
    return (
      <>
        <div className={props.isMobile ? 'h5' : 'h4'}>
          <strong>Download Files</strong>
        </div>
        <ul className="cvc-download-files-list pl-4">
          {hasAssets && listSeriesAssetDownloads(props.pdfs)}
          {hasImages && listSeriesDecorImages(props.decorImages)}
        </ul>
      </>
    );
  }
  return null;
}

const getSeriesFiles = (assets: any, floorplanFiles: any) => {
  let pdfs;
  if (!assets && !floorplanFiles) {
    return null;
  }
  if (assets && !floorplanFiles) {
    pdfs = assets.getData().assets;
  } else if (!assets && floorplanFiles) {
    pdfs = floorplanFiles.getData().floorplanFiles;
  } else if (assets && floorplanFiles) {
    const seriesFiles = assets.getData().assets;
    const planFiles = floorplanFiles.getData().floorplanFiles;
    pdfs = seriesFiles.concat(planFiles);
  }
  return pdfs;
};

const getSeriesDownloads = (props: BrProps, isMobile: boolean | null) => {
  const { series, floorplan } = props.component.getModels();
  const seriesDoc = series && series[0] && props.page.getContent(series[0]);
  const floorplanDoc = props.page.getContent(floorplan);
  const pdfs = getSeriesFiles(seriesDoc, floorplanDoc);
  const decorImages = getSeriesDecorImages(props, seriesDoc);

  return <DownloadFiles pdfs={pdfs} decorImages={decorImages} isMobile={isMobile} />;
};

export function FloorPlanFacts(props: BrProps) {
  const [seriesPopupModal, setSeriesPopupModal] = useState(false);
  const closeSeriesPopupModal = () => setSeriesPopupModal(false);
  const openSeriesPopupModal = () => setSeriesPopupModal(true);
  // const { documents /*, componentParameterMap */ } = props.component.getModels();
  //  const { documents, floorPlanFactsLabels } = props.component.getModels();
  //  const document = documents && documents[0] && props.page.getContent(documents[0]);
  const { floorplan, floorPlanFactsLabels, componentParameterMap } = props.component.getModels();
  const document = floorplan ? props.page.getContent(floorplan) : undefined;

  const isCommunity: boolean =
    componentParameterMap && componentParameterMap.isCommunity
      ? isStringOnOrTrue(componentParameterMap.isCommunity)
      : false;

  const modelsSeries = getSeries(props);
  const assets =
    modelsSeries && modelsSeries[0] && props.page.getContent(modelsSeries[0])?.getData();
  const buildingCenterId = assets?.buildingCenter;
  const buildingCenter = buildingCenterId && props?.page?.getContent(buildingCenterId)?.getData();
  const windowDimensions = useWindowDimensions();
  // const seriesFiles = getSeriesFiles(props, windowDimensions?.isMobile);
  const seriesDownloads = getSeriesDownloads(props, windowDimensions?.isMobile);
  const isSinglePlantSite = isSinglePlantWebsite();
  const isCavcoDotCom =
    getCurrentBloomreachChannelName() === BLOOMREACH_CHANNEL_NAMES.CAVCO_DOT_COM;
  const popUpLinkColor = !isCavcoDotCom ? '#00A9CE' : '#707070';
  const showEmptyMessage = !(
    !!assets?.descriptionNotUsed?.value ||
    !!assets?.standardFeatures?.value ||
    !!seriesDownloads
  );

  const builtBy = `${buildingCenter?.dba}, ${buildingCenter?.city}, ${buildingCenter?.state}`;
  const buildingCenterPath = buildingCenter?.buildingCenterPath;
  const builtByLink = getUrlWithBasePath(buildingCenterPath);

  if (!document) {
    return null;
  }

  /* name,
    displayName,
    modelNumber,
    buildingMethod,
  */

  const {
    squareFootage,
    dimensionalArea,
    content1,
    numBedrooms,
    numBathrooms,
    series,
    sections,
    floorplanSellingFeatures,
    buildingMethodValue,
    flrPlanWidthFeet,
    flrPlanWidthInches,
    flrPlanLengthFeet,
    flrPlanLengthInches,
  } = document.getData<FloorPlanData>();

  const widthInches = flrPlanWidthInches || '0';
  const lengthInches = flrPlanLengthInches || '0';

  const dimentionText =
    isCommunity && flrPlanWidthFeet && flrPlanLengthFeet
      ? `${flrPlanWidthFeet}'-${widthInches}" x ${flrPlanLengthFeet}'-${lengthInches}"`
      : dimensionalArea;

  let bedroomsLabel = 'Bedrooms:';
  let fafTitle = 'Facts and Features';
  let seriesLabel = 'Series:';
  let squareFeetLabel = 'Square Feet:';
  let bathroomsLabel = 'Bathrooms:';
  let dimentionalAreaLabel = 'Dimensional Area:';
  let sectionsLabel = 'Sections:';
  let homeTypeLabel = 'Home Type:';

  if (floorPlanFactsLabels) {
    bedroomsLabel = floorPlanFactsLabels.bedrooms;
    fafTitle = floorPlanFactsLabels.facts_and_features;
    seriesLabel = floorPlanFactsLabels.series;
    squareFeetLabel = floorPlanFactsLabels.square_feet;
    bathroomsLabel = floorPlanFactsLabels.bathrooms;
    dimentionalAreaLabel = floorPlanFactsLabels.dimentionalArea;
    sectionsLabel = floorPlanFactsLabels.sections;
    homeTypeLabel = floorPlanFactsLabels.homeType;
  }

  const elements: { val: string; title: string }[] = [
    { val: squareFootage, title: squareFeetLabel },
    { val: numBedrooms, title: bedroomsLabel },
    { val: numBathrooms, title: bathroomsLabel },
    { val: sections, title: sectionsLabel },
    { val: dimentionText, title: dimentionalAreaLabel },
    { val: series, title: seriesLabel },
    { val: buildingMethodValue, title: homeTypeLabel },
  ];

  const leftElements: { val: string; title: string }[] = [];
  const rightElements: { val: string; title: string }[] = [];
  for (let i = 0; i < elements.length; i += 1) {
    if (i < elements.length / 2) {
      leftElements.push(elements[i]);
    } else {
      rightElements.push(elements[i]);
    }
  }

  const { useElasticSearchFloorplans } = SiteGlobalSwitches;
  const seriesParam = useElasticSearchFloorplans
    ? `%5B"${series.split(' ').join('+')}"%5D`
    : series.toLowerCase().split(' ').join('');
  const buildingCenterFloorplansLink = getUrlWithBasePath(
    `/building-center${buildingCenterPath}/floorplans?series=${seriesParam}`
  );

  const contentVal = content1 && props.page.rewriteLinks(content1.value);
  return (
    <div className="cvc-product-facts-content">
      <h2 className="cvc-product-facts-content-h2">{fafTitle}</h2>
      <ul className="cvc-product-facts-content-facts">
        {leftElements.length > 0 && (
          <li>
            <strong>{leftElements[0].title} </strong> {leftElements[0].val}
          </li>
        )}
        {leftElements.length > 1 && (
          <li>
            <strong>{leftElements[1].title} </strong> {leftElements[1].val}
          </li>
        )}
        {leftElements.length > 2 && (
          <li>
            <strong>{leftElements[2].title} </strong> {leftElements[2].val}
          </li>
        )}
        {leftElements.length > 3 && (
          <li>
            <strong>{leftElements[3].title} </strong> {leftElements[3].val}
          </li>
        )}
        {leftElements.length > 4 && (
          <li>
            <strong>{leftElements[4].title} </strong> {leftElements[4].val}
          </li>
        )}
        {rightElements.length > 0 && (
          <li>
            <strong>{rightElements[0].title} </strong> {rightElements[0].val}
          </li>
        )}
        {rightElements.length > 1 && (
          <li>
            <strong>{rightElements[1].title} </strong>
            <span // eslint-disable-line
              className="cvc-text-link-alt"
              onClick={openSeriesPopupModal}
            >
              {rightElements[1].val}
            </span>
          </li>
        )}
        {rightElements.length > 2 && (
          <li>
            <strong>{rightElements[2].title} </strong> {rightElements[2].val}
          </li>
        )}
        {rightElements.length > 3 && (
          <li>
            <strong>{rightElements[3].title} </strong> {rightElements[3].val}
          </li>
        )}
        {rightElements.length > 4 && (
          <li>
            <strong>{rightElements[4].title} </strong> {rightElements[4].val}
          </li>
        )}
      </ul>
      <Modal
        show={seriesPopupModal}
        onHide={closeSeriesPopupModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        size="lg"
        scrollable
        centered
        className="cvc-product-facts-content-facts--series-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="px-4" style={{ minWidth: '100px' }}>
            <div className="text-center">
              <div className={`series-name ${windowDimensions.isMobile ? 'h4' : 'h1'}`}>
                <strong>{assets?.name}</strong>
              </div>
              <div className={`series-built-by ${windowDimensions.isMobile ? 'h6' : 'h5'}`}>
                <span>Built By </span>
                {!isSinglePlantSite ? (
                  <a
                    target="_self"
                    rel="noopener noreferrer"
                    href={builtByLink}
                    style={{ color: `${popUpLinkColor}`, cursor: 'pointer' }}
                  >
                    {builtBy}
                  </a>
                ) : (
                  <>{builtBy}</>
                )}
              </div>
            </div>
            <br />

            {assets && assets?.descriptionNotUsed?.value && (
              <div className="series-desc">
                <div className={windowDimensions.isMobile ? 'h5' : 'h4'}>
                  <strong>Description</strong>
                </div>
                <div
                  className="series-desc-body"
                  dangerouslySetInnerHTML={{
                    __html: props.page.rewriteLinks(assets?.descriptionNotUsed?.value),
                  }}
                />
              </div>
            )}

            {assets && assets?.standardFeatures?.value && (
              <div className="series-features">
                <div className={windowDimensions.isMobile ? 'h5' : 'h4'}>
                  <strong>Standard Features</strong>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.page.rewriteLinks(assets?.standardFeatures?.value),
                  }}
                />
              </div>
            )}

            {seriesDownloads}

            {showEmptyMessage && (
              <h4 className="text-center py-5">
                <strong>More Information Coming Soon</strong>
              </h4>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center" style={{ borderTop: '0' }}>
          {!isSinglePlantSite && (
            <Button target="_self" href={buildingCenterFloorplansLink}>
              View All Floor Plans in this Series
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <div dangerouslySetInnerHTML={{ __html: contentVal }} />
      <ul className="cvc-product-facts-content-features">
        {floorplanSellingFeatures?.feature.map((item) => {
          if (item !== '') return <li key={item}>{item}</li>;
        })}
      </ul>
    </div>
  );
}
