// copied from https://reactrouter.com/web/guides/scroll-restoration
import classNames from 'classnames';
import React, { useState } from 'react';
import debounce from 'lodash.debounce';
import { ReactComponent as BackToTopIcon } from '../../../assets/images/back-to-top.svg';
import './GlobalScrollToTopButton.scss';

interface GlobalScrollToTopProps {
  className?: string;
}

const GlobalScrollToTopButton = (props: GlobalScrollToTopProps) => {
  const { className = '' } = props;
  const [scrollClass, setScrollClass] = useState<string>('');

  const handleClickToScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const scrolling = () => {
    const isScrolling = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20;
    if (scrollClass === ' scroll' && isScrolling) return null;
    setScrollClass(isScrolling ? ' scroll' : '');
  };

  window.onscroll = debounce(() => {
    scrolling();
  }, 25);

  const rootClasses = classNames({
    'cvc-scroll-to-top': true,
    scroll: scrollClass,
    [className as string]: true,
  });

  return <BackToTopIcon onClick={() => handleClickToScrollToTop()} className={rootClasses} />;
};

export default GlobalScrollToTopButton;
