import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { CustomBreadcrumbStackWithRequestShare } from '../Breadcrumb/BreadcrumbStack';
import './RetailerBanner.scss';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getRetailerData } from '../utils';

function getExtraLargeImageUrl(item: any, imgContent: any) {
  let extraLargeImgPath = '';
  if (imgContent) {
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.ximage) {
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
  }

  return extraLargeImgPath;
}

function getFirstImgUrl(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos[0]) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent: any = mediaAsset[0] && props.page.getContent(mediaAsset[0].image);
  return getExtraLargeImageUrl(mediaAsset[0], imgContent);
}

function getFirstImgDescription(props: BrProps, photos: any) {
  const photoAlbum = photos ? props.page.getContent(photos[0]) : undefined;
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  return mediaAsset[0].description;
}

export function RetailerBanner2(props: BrProps) {
  const windowDimensions = useWindowDimensions();

  const { componentParameterMap: paramMap } = props.component.getModels();

  const details = props.page.getComponent('main', 'detailcontainer');
  const { hideBreadcrumb } = paramMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const showSmallerImage = !paramMap.tallerImage || paramMap.tallerImage === 'off';

  const retailerData = getRetailerData(props.page);
  const event = retailerData && retailerData.event;
  const photos = retailerData && retailerData.photos;
  const image = photos && getFirstImgUrl(props, photos);

  useEffect(() => {
    if (!windowDimensions.isMobile && !windowDimensions.isTablet && !image && event) {
      const eventElement = document.getElementById('event');
      const retailerEventWrapper =
        eventElement && eventElement.querySelector('.cvc-retailer-event-row-desktop-wrapper');
      if (retailerEventWrapper) {
        if (!windowDimensions.isMobile || !windowDimensions.isTablet) {
          (retailerEventWrapper as HTMLDivElement).classList.add('heroless');
        }
      }
    }
  });

  if (!retailerData) return null;

  let { homeSizeList, numBathroomsSet, numBedroomsList } = retailerData;

  if (!homeSizeList) homeSizeList = [];
  if (!numBathroomsSet) numBathroomsSet = [];
  if (!numBedroomsList) numBedroomsList = [];

  const panelClass = classNames(
    `cvc-retailer-banner${image ? '' : ' no-image'} ${
      props.page.isPreview() ? ' has-edit-button' : ''
    } ${event && !showSmallerImage ? ' has-event-below' : ''}`
  );

  return (
    <div className={panelClass}>
      <Row className="cvc-retailer-banner-image-container d-block m-0">
        <div
          className={classNames(
            `cvc-retailer-banner-image${props.page.isPreview() ? 'has-edit-button' : ''}`,
            showSmallerImage ? 'is-smaller-image' : '',
            !image ? 'heroless' : ''
          )}
          role="img"
          style={{ backgroundImage: !image ? '' : `url(${image})` }}
          title={getFirstImgDescription(props, photos) ?? ''}
        />
      </Row>
      {breadcrumb && (
        <div className="cvc-retailer-banner-panel-container">
          <div className="cvc-retailer-banner-panel">
            <div className="cvc-retailer-banner-breadcrumb d-sm-block">
              <CustomBreadcrumbStackWithRequestShare
                props={{ page: props.page, component: breadcrumb }}
                customProps={{ displayScrollToTop: true }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
