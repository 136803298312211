import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import './RetailerLocationDetails.scss';
import classNames from 'classnames';
import { ReactComponent as LocationIcon } from '../../assets/images/location-mini-black-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/clock-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { FloorPlanRequestInfoWithParams } from '../FloorPlanRequestInfo/FloorPlanRequestInfo';
import { formatPhoneNumber } from '../utils';

export function RetailerLocationDetails(props: BrProps) {
  const { documents, retailerLocationDetailsLabels, googleMapConfig } = props.component.getModels();
  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;
  const windowDimensions = useWindowDimensions();

  const retailerDetailsClass = classNames(
    `cvc-retailer-details-content pb-5 mb-5`,
    (windowDimensions.isMobile || windowDimensions.isTablet) && 'fullWidth'
  );

  const [requestInfoModal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('request-info');

  if (!document) {
    return null;
  }

  const {
    hoursOpMonday,
    hoursOpTuesday,
    hoursOpWednesday,
    hoursOpThursday,
    hoursOpFriday,
    hoursOpSaturday,
    hoursOpSunday,
    name,
    address1,
    address2,
    city,
    state,
    zip,
    phone,
  } = document.getData<RetailerData>();
  const phoneFormatted = formatPhoneNumber(phone);
  const phoneLink = `tel:${phone}`;
  const address1Plus = address1.replace(/\s/g, '+');
  const address2Plus = address2 ? `+${address2.replace(/\s/g, '+')}` : '';
  const cityPlus = city.replace(/\s/g, '+');
  const statePlus = state.replace(/\s/g, '+');

  const addressQuery = `${address1Plus}${address2Plus},${cityPlus}+${statePlus}+${zip}`;

  let titleKey = 'Location Details';
  let monKey = 'Mon';
  let tueKey = 'Tue';
  let wedKey = 'Wed';
  let thuKey = 'Thu';
  let friKey = 'Fri';
  let satKey = 'Sat';
  let sunKey = 'Sun';

  if (retailerLocationDetailsLabels) {
    titleKey = retailerLocationDetailsLabels.title;
    monKey = retailerLocationDetailsLabels.mon;
    tueKey = retailerLocationDetailsLabels.tue;
    wedKey = retailerLocationDetailsLabels.wed;
    thuKey = retailerLocationDetailsLabels.thu;
    friKey = retailerLocationDetailsLabels.fri;
    satKey = retailerLocationDetailsLabels.sat;
    sunKey = retailerLocationDetailsLabels.sun;
  }

  return (
    <>
      {(windowDimensions.isMobile || windowDimensions.isTablet) && (
        <Container className="cvc-location-details-request-info">
          <Button variant="primary" onClick={openModal}>
            Request Info
          </Button>
          <Modal
            className="cvc-request-info-modal-retailer retailer-location-details-modal"
            show={requestInfoModal}
            onHide={closeModal}
          >
            <Modal.Header closeButton />
            <Modal.Body id="id-modal-body" className="">
              {form && (
                <FloorPlanRequestInfoWithParams
                  page={props.page}
                  component={form}
                  displayInsideModal
                  formId="retailer-home-location-details-form"
                />
              )}
            </Modal.Body>
          </Modal>
        </Container>
      )}

      <Container className={retailerDetailsClass}>
        <Row className="cvc-retailer-details-content__location">
          <Col className="cvc-retailer-details-content__location__info">
            <h2 className="cvc-container-location-details" id="location-details">
              <span className="cvc-retailer-details-content-title">{titleKey}</span>
            </h2>

            <div className="cvc-container-location-details">
              <div className="cvc-container-icon">
                <LocationIcon />
              </div>
              <div className="cvc-container-content">
                <address>
                  <div>{name}</div>
                  <div>{address1}</div>
                  <div>{address2}</div>
                  <div>
                    {city}, {state} {zip}
                  </div>
                </address>
              </div>
            </div>

            <div className="cvc-container-location-details">
              <div className="cvc-container-icon">
                <PhoneIcon />
              </div>
              <div className="cvc-container-content">
                <a href={phoneLink}>{phoneFormatted}</a>
              </div>
            </div>

            <div className="cvc-container-location-details">
              <div className="cvc-container-icon">
                <ClockIcon />
              </div>
              <div className="cvc-container-content cvc-container-hours-content">
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{monKey}</span>
                  <span>{hoursOpMonday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{tueKey}</span>
                  <span>{hoursOpTuesday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{wedKey}</span>
                  <span>{hoursOpWednesday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{thuKey}</span>
                  <span>{hoursOpThursday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{friKey}</span>
                  <span>{hoursOpFriday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{satKey}</span>
                  <span>{hoursOpSaturday}</span>
                </div>
                <div className="cvc-container-hours-content-unit">
                  <span className="cvc-container-hours-content-day">{sunKey}</span>
                  <span>{hoursOpSunday}</span>
                </div>
              </div>
            </div>
          </Col>

          <Col className="cvc-retailer-details-content__location__map">
            <div className="cvc-container-location-details">
              <div className="cvc-map-container" id="location-details-map">
                <iframe
                  title={name}
                  width="95%"
                  height="95%"
                  frameBorder="1px"
                  style={{ border: `1px solid #707070`, padding: `1px` }}
                  src={`https://www.google.com/maps/embed/v1/place?key=${googleMapConfig.apiKey}
                &q=${addressQuery}`}
                  allowFullScreen
                  aria-hidden="true"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
