import { BrProps } from '@bloomreach/react-sdk';
import React, { useState } from 'react';
import './CavcoDetailVideos.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PlayCircleFilled } from '@material-ui/icons';
import { MediaModal } from 'components/MediaModal';
import { MediaModalState } from 'components/FloorPlanResultsCH/FloorPlanResultsCH';
import { display3DTour, displayYoutubeVideo } from '../utils';
import { ReactComponent as CaretDownIcon } from '../../assets/images/arrow-drop-down.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import { inventoryDoc, inventoryToFloorplanMapper } from './inventoryToFloorPlan.mapper';

export function CavcoDetailVideos(props: BrProps) {
  const [mediaModalState, setMediaModalState] = useState<MediaModalState>({
    isOpen: false,
    isVideo: false,
    mediaSrc: undefined,
    mediaTitle: undefined,
  });

  const { documents } = props.component.getModels();
  const inventoryDocument = inventoryDoc(props);

  const ref = documents && documents[0];
  const document = ref && props.page.getContent(ref);

  if (!document && !inventoryDocument) {
    return null;
  }

  const { videosRef, toursRef, panatoursRef } = inventoryToFloorplanMapper(props);

  const videosDocument = videosRef ? props.page.getContent(videosRef) : undefined;
  const toursDocument = toursRef ? props.page.getContent(toursRef) : undefined;
  const panatourDocument = panatoursRef ? props.page.getContent(panatoursRef) : undefined;

  let mediaAssetItemsVideo = null;

  if (videosDocument) {
    const { mediaAsset } = videosDocument.getData<AlbumData>();
    const noVideos = mediaAsset.find((item: any) => item.videoURL !== '') === undefined;
    if (!noVideos) {
      mediaAssetItemsVideo = mediaAsset;
    }
  }

  let mediaAssetItems3d = null;
  if (toursDocument) {
    const { mediaAsset } = toursDocument.getData<AlbumData>();
    const noTours = mediaAsset.find((item: any) => item.matterportURL !== '') === undefined;
    if (!noTours) {
      mediaAssetItems3d = mediaAsset;
    }
  }

  if (panatourDocument) {
    const { mediaAsset } = panatourDocument.getData<AlbumData>();
    const noTours = mediaAsset.find((item: any) => item.matterportURL !== '') === undefined;
    if (!noTours) {
      if (!mediaAssetItems3d) {
        mediaAssetItems3d = mediaAsset;
      } else {
        mediaAssetItems3d = mediaAssetItems3d.concat(mediaAsset);
      }
    }
  }

  if (!mediaAssetItemsVideo && !mediaAssetItems3d) {
    return null;
  }
  const slideLength = (a: number | undefined, b: number | undefined) => {
    if (a && b) return a + b;
    if (!b) return a;
    if (!a) return b;
  };
  let slideCountClass = '';
  if (slideLength(mediaAssetItemsVideo?.length, mediaAssetItems3d?.length) === 1) {
    slideCountClass = 'single';
  } else if (slideLength(mediaAssetItemsVideo?.length, mediaAssetItems3d?.length) === 2) {
    slideCountClass = 'double';
  } else if (slideLength(mediaAssetItemsVideo?.length, mediaAssetItems3d?.length) === 3) {
    slideCountClass = 'triple';
  }

  const handleCloseMediaModal = () => {
    setMediaModalState({
      isOpen: false,
      isVideo: false,
      mediaSrc: '',
      mediaTitle: '',
    });
  };

  const handleDisplayMediaModal = ({ isVideo, mediaSrc, mediaTitle }: MediaModalState) => {
    setMediaModalState({
      isOpen: true,
      isVideo,
      mediaSrc,
      mediaTitle,
    });
  };

  function displayMediaAssetsVideo(mediaAssetItems: MediaAssetCompound[]) {
    return mediaAssetItems.map((item: any, index: number) => {
      return (
        <SwiperSlide key={`slide-image-${index.toString()}`} className="video-slide">
          <button
            type="button"
            onClick={() =>
              handleDisplayMediaModal({
                isVideo: true,
                mediaSrc: item.videoURL,
                mediaTitle: item.name,
              })
            }
          >
            Video Tour
            <CaretDownIcon />
            <div className="cvc-product-videos-item">
              {item.videoURL ? displayYoutubeVideo(item, 'hq') : ''}
              <PlayCircleFilled
                key={`slide-image-${index.toString()}`}
                className="cvc-product-videos-item-icon"
              />
            </div>
          </button>
        </SwiperSlide>
      );
    });
  }

  function displayMediaAssets3d(mediaAssetItems: MediaAssetCompound[]) {
    return mediaAssetItems.map((item: any, index: number) => {
      return (
        <SwiperSlide key={`slide-image-${index.toString()}`}>
          <button
            type="button"
            onClick={() =>
              handleDisplayMediaModal({
                isVideo: false,
                mediaSrc: item.matterportURL,
                mediaTitle: item.name,
              })
            }
          >
            3D Virtual Tour
            <CaretDownIcon />
            <div className="cvc-product-videos-item">
              {item.matterportURL ? display3DTour(item) : ''}
              <PlayCircleFilled
                key={`slide-image-${index.toString()}`}
                className="cvc-product-videos-item-icon"
              />
            </div>
          </button>
        </SwiperSlide>
      );
    });
  }

  return (
    <div className="cavco-floorplanvideos-container">
      <h2 className="floorplan-videos-title">Explore</h2>

      <div className="carousel-container">
        <Swiper
          effect="coverflow"
          grabCursor
          slidesPerView="auto"
          className={`mySwiper ${slideCountClass}`}
          pagination={{
            el: '.swiper-pagination-videos',
            clickable: true,
          }}
          navigation={{
            nextEl: '.swiper-button-next-video',
            prevEl: '.swiper-button-prev-video',
          }}
        >
          {mediaAssetItemsVideo && displayMediaAssetsVideo(mediaAssetItemsVideo)}
          {mediaAssetItems3d && displayMediaAssets3d(mediaAssetItems3d)}
        </Swiper>
        <div className="swiper-pagination-videos" />
        <ArrowRight className="swiper-button-next-video" />
        <ArrowLeft className="swiper-button-prev-video" />
      </div>
      <MediaModal
        show={mediaModalState.isOpen || false}
        isVideo={mediaModalState.isVideo}
        title={mediaModalState.mediaTitle || ''}
        mediaSrc={mediaModalState.mediaSrc}
        handleClose={handleCloseMediaModal}
      />
    </div>
  );
}
