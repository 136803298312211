import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { BrProps } from '@bloomreach/react-sdk';
import { Col, Container, Row, Button } from 'react-bootstrap';
import * as Scroll from 'react-scroll';
import './RetailerList.scss';
import queryString from 'query-string';
import classNames from 'classnames';
import { Spinner } from 'components/shared/Spinner';
import { SITE_NAMES } from 'components/enums';
import AutoCompleteComp, { GoToPageUrl, GeocodeByAddress } from '../Autocomplete/Autocomplete';
import {
  ReactComponent as RetailerIcon,
  ReactComponent as CommunityIcon,
} from '../../assets/images/marker-icon.svg';

import { ReactComponent as AlertIcon } from '../../assets/images/alert-icon.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { RetailerCards } from '../RetailerCards/RetailerCards';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { checkForNessus, formatPhoneNumber, getCurrentSiteName } from '../utils';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';
import { ListComponent } from './RetailerList.interface';
// import { OPERATIVE_SYSTEM } from '../enums';

declare let window: any;
let zoomLevelValue = 0;
let placesValues: any[] = [];
let isSearching = false;

const { scroller } = Scroll;
const cardActiveClass = 'active';
const cardContainerId = 'containerElement';
const markerSize = 1.3;
const markerSizeSelected = 1.6;
const markerLabelX = 12;
const markerLabelY = 10;
const currentSiteName = getCurrentSiteName();
const markerCommunityColor = (currentSite: any) => {
  switch (currentSite) {
    case SITE_NAMES.CHARIOTEAGLE:
      return '#40659F';
    default:
      return '#2B3C3B';
  }
};
const markerRetailerColor = (currentSite: any) => {
  switch (currentSite) {
    case SITE_NAMES.CHARIOTEAGLE:
      return '#473E37';
    default:
      return '#56A803';
  }
};
const markerOverrideColor = (overrideId: any) => {
  switch (overrideId) {
    case 'palmharbor-model-center':
      return '#F9892D';
    case 'palmharbor-community':
      return '#F5CE35';
    case 'cavcodotcom-home-center':
      return '#FFC72C';
    default:
      return '#56A803';
  }
};
const labelCommunityColor = (currentSite: any) => {
  switch (currentSite) {
    default:
      return '#FFFFFF';
  }
};
const labelRetailerColor = (currentSite: any) => {
  switch (currentSite) {
    default:
      return '#FFFFFF';
  }
};
const labelOverrideColor = (overrideId: any) => {
  switch (overrideId) {
    case 'cavcodotcom-home-center':
      return '#000000';
    default:
      return '#FFFFFF';
  }
};
const getMarkerColor = (locationOverride: any, iscommunity: any, currentSite: any) => {
  if (locationOverride && locationOverride.id) {
    return markerOverrideColor(locationOverride.id);
  }
  return iscommunity ? markerCommunityColor(currentSite) : markerRetailerColor(currentSite);
};
const getLabelColor = (locationOverride: any, iscommunity: any, currentSite: any) => {
  if (locationOverride && locationOverride.id) {
    return labelOverrideColor(locationOverride.id);
  }
  return iscommunity ? labelCommunityColor(currentSite) : labelRetailerColor(currentSite);
};
const markerDefaultConfig = {
  fillOpacity: 1,
  scale: markerSize,
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  path: 'M12.5,23.8l-0.6-0.6l-6.1-6.1c-0.9-0.9-1.6-2-2.1-3.1C3.2,12.8,3,11.6,3,10.4S3.2,8,3.7,6.8s1.2-2.2,2.1-3.1 s2-1.6,3.1-2.1s2.3-0.7,3.6-0.7s2.4,0.2,3.6,0.7s2.2,1.2,3.1,2.1c0.9,0.9,1.6,2,2.1,3.1S22,9.1,22,10.4s-0.2,2.4-0.7,3.6 s-1.2,2.2-2.1,3.1l-6.1,6.1L12.5,23.8z',
};
let map: any;

enum GoogleMapPositions {
  BOTTOM = 11,
  BOTTOM_CENTER = 11,
  BOTTOM_LEFT = 10,
  BOTTOM_RIGHT = 12,
  CENTER = 13,
  LEFT = 5,
  LEFT_BOTTOM = 6,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  RIGHT = 7,
  RIGHT_BOTTOM = 9,
  RIGHT_CENTER = 8,
  RIGHT_TOP = 7,
  TOP = 2,
  TOP_CENTER = 2,
  TOP_LEFT = 1,
  TOP_RIGHT = 3,
}

const setMarkerIcon = (marker: any, config: object = {}) => {
  const { fillColor, labelOrigin } = marker.getIcon();

  marker.setIcon({
    ...markerDefaultConfig,
    fillColor,
    labelOrigin,
    ...config,
  });
};

const closeInfoWindow = () => {
  const { mapMarkers } = window.cavco;
  const prevCardSelected = document.querySelector(`#${cardContainerId} .${cardActiveClass}`);

  if (prevCardSelected) {
    const prevId = prevCardSelected.getAttribute('id')?.split('-')[1];
    const prevIndex = parseInt(prevId!, 10) - 1;
    const item = mapMarkers[prevIndex];

    prevCardSelected.classList.remove(cardActiveClass);
    item.infoWindow.close();
    item.marker.setZIndex(1);
    setMarkerIcon(item.marker, { scale: markerSize, anchor: new window.google.maps.Point(0, 0) });
  }
};

const handleMarkerClick = (e: any) => {
  const { mapMarkers } = window.cavco;
  const lat = e.latLng.lat();
  const lng = e.latLng.lng();
  let index = 0;

  closeInfoWindow();

  mapMarkers.forEach(({ marker: { position } }: any, i: number) => {
    if (lat === position.lat() && lng === position.lng()) {
      index = i;
    }
  });

  const cardId = `card-${index + 1}`;
  const cardSelected = document.getElementById(cardId);
  const item = mapMarkers[index];

  if (cardSelected) {
    cardSelected.classList.add(cardActiveClass);
  }

  item.infoWindow.open(map, mapMarkers[index].marker);
  item.marker.setZIndex(1075);
  setMarkerIcon(item.marker, {
    scale: markerSizeSelected,
    anchor: new window.google.maps.Point(2, 2),
  });
  map.panTo({ lat, lng });

  scroller.scrollTo(cardId, {
    smooth: true,
    duration: 1500,
    containerId: cardContainerId,
  });
};

const handleScriptLoaded = () => {
  if (window.google && window.cavco && window.cavco.mapConfig) {
    const { cavco, google } = window;
    const { mapConfig } = cavco;

    cavco.mapMarkers = [];

    map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: mapConfig.lat, lng: mapConfig.lng },
      disableDefaultUI: false,
      zoom: mapConfig.zoom,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
      zoomControlOptions: { position: GoogleMapPositions.RIGHT_TOP },
    });

    mapConfig.places.forEach((place: any, index: number) => {
      const {
        address,
        city,
        iscommunity,
        lat,
        lng,
        name,
        phone,
        state,
        website,
        zip,
        locationTypeOverride,
      } = place;
      const phoneFormatted = formatPhoneNumber(phone);
      const marker = new google.maps.Marker({
        title: `marker-${(index + 1).toString()}`,
        icon: {
          ...markerDefaultConfig,
          fillColor: getMarkerColor(locationTypeOverride, iscommunity, currentSiteName),
          labelOrigin: new google.maps.Point(markerLabelX, markerLabelY),
        },
        label: {
          text: (index + 1).toString(),
          color: getLabelColor(locationTypeOverride, iscommunity, currentSiteName),
          fontWeight: 'bold',
        },
        map,
        position: { lat, lng },
      });
      marker.set('type', 'point');
      marker.set('id', `marker-${(index + 1).toString()}`);

      const formattedUrl = website;
      const markerButtonOnClick = formattedUrl.startsWith('/')
        ? `window.customHistory.push('${formattedUrl}')`
        : `window.open('${formattedUrl}', '_blank')`;
      const infoWindow = new google.maps.InfoWindow({
        content: `
        <div class="cvc-info-window">
          <div class="cvc-info-window-title">${name}</div>
          <div class="cvc-info-window-detail">
            <div>${address}</div>
            <div>${city} ${state} ${zip}</div>
            <div>${phoneFormatted}</div>
          </div>
          <button class="cvc-info-window-button" onclick="${markerButtonOnClick}">
            VIEW HOMES
            <span class="cvc-info-window-right-arrow-icon" />
          </button>
        </div>`,
        position: { lat: place.lat, lng: place.lng },
      });

      infoWindow.addListener('closeclick', closeInfoWindow);
      marker.addListener('click', handleMarkerClick);

      cavco.mapMarkers.push({ infoWindow, marker });
    });

    cavco.mapConfig = null;
  }
};

window.onload = () => {
  document.head.addEventListener('load', handleScriptLoaded, true);
};

function buildMapPlaces(cards: any) {
  const places: {
    id: number;
    name: string;
    lat: number;
    lng: number;
    iscommunity: boolean;
    isretailer: boolean;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    website: any;
    locationTypeOverride: any;
  }[] = [];
  if (cards) {
    cards.map((card: any, documentIndex: number) => {
      const {
        locationName: name,
        latitude,
        longitude,
        community,
        parkModelRetailer,
        address1,
        city,
        state,
        zip,
        phone,
        url,
        locationTypeOverride,
      } = card;

      places.push({
        id: documentIndex,
        name,
        lat: latitude,
        lng: longitude,
        iscommunity: community,
        isretailer: parkModelRetailer,
        address: address1,
        city,
        state,
        zip,
        phone,
        website: url,
        locationTypeOverride,
      });
      return null;
    });
  }
  return places;
}

function buildPlaceCards(props: BrProps) {
  return (
    <div id="containerElement" className="cvc-retailer-cards">
      <RetailerCards page={props.page} component={props.component} />
    </div>
  );
}

function buildNoRetailersMessage(props: BrProps, noDocuments: any, maxRadiusString: any) {
  const document = props.page.getContent(noDocuments);
  if (document) {
    const contentVal = document.getData()!.content!.value;
    return (
      <Row className="cvc-retailer-list-alert-banner-container no-horizontal-margin">
        <Col className="cvc-retailer-list-alert-icon-container no-horizontal-padding">
          <AlertIcon className="cvc-retailer-list-alert-icon" />
        </Col>
        <Col className="cvc-retailer-list-alert-content-container no-horizontal-padding">
          <div className="cvc-retailer-list-alert-title">
            {`${document.getData()!.title} ${maxRadiusString}`}
          </div>
          <div className="cvc-retailer-list-alert-description">
            <div dangerouslySetInnerHTML={{ __html: contentVal }} />
          </div>
        </Col>
      </Row>
    );
  }

  return <div>No Retailers found nearby</div>;
}

function addResourceApiIntoNewLocationUrl(newLocation: string) {
  const newLocationSplitted: any = newLocation.split('/');

  let result = newLocation;
  if (newLocationSplitted.length > 2) {
    if (newLocationSplitted[2] === getCurrentSiteName()) {
      // for /site/friendshiphomesmn/our-retailers/us
      newLocationSplitted.splice(3, 0, 'resourceapi');
    } else {
      // for /site/our-retailers/us or /our-retailers/us
      newLocationSplitted.splice(newLocationSplitted[1] === 'site' ? 2 : 1, 0, 'resourceapi');
    }
    result = newLocationSplitted.join('/');
  }
  return result;
}

async function redirectToUrlWithNewZoomLevel(
  newLocation: string,
  history: any,
  setZoomLevelValue: any,
  setPlacesValues: any,
  setRenderMapWithMarkers: any,
  setIsSearching: any
) {
  setRenderMapWithMarkers(true);

  const requestOptions = {
    method: 'GET',
  };

  const cmsBaseUrl = window.cmsBaseUrl || process.env.REACT_APP_CMS_BASE_URL;
  const backendUrl = cmsBaseUrl + addResourceApiIntoNewLocationUrl(newLocation);

  await fetch(backendUrl, requestOptions)
    .then((res) => res.json())
    .then(
      async (response: any) => {
        const page = Object.values(response.page);
        const retailerList =
          page && (page as any).find((comp: ListComponent) => comp.name === 'retailer-list');

        if (retailerList) {
          const zoomLevel: number = retailerList.models?.zoomLevel || 0;
          setZoomLevelValue(zoomLevel);
          setPlacesValues(
            retailerList.models?.cards ? buildMapPlaces(retailerList.models.cards) : []
          );
          const updatedLocation = `${newLocation}&zoom=${zoomLevel}`;
          history.push(updatedLocation);
          setIsSearching(false);
        }
      },
      (/* error */) => {}
    );
}

const useZoomLevelValue = (value: any) => {
  zoomLevelValue = value;
};
const usePlacesValues = (value: any) => {
  placesValues = value;
};

const setIsSearching = (value: any) => {
  isSearching = value;
};

export function RetailerList(props: BrProps) {
  const history = useHistory();
  const [newLocation, setNewLocation] = useState('');
  const [displayInMobile, setDisplayInMobile] = useState('cards');

  // eslint-disable-next-line
  const [tempRetailerSearch, setTempRetailerSearch] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });
  // eslint-disable-next-line
  const [currentRetailerSearch, setCurrentRetailerSearch] =
    useStateWithLocalStorage('currentRetailerSearch');

  const [renderMapWithMarkers, setRenderMapWithMarkers] = useState(true);

  const {
    googleMapConfig,
    cards,
    zoomLevel,
    center,
    noDocuments,
    maxRadiusString,
    spinnerConfig,
    locationTypeOverrides,
  } = props.component.getModels();

  // const loadFavoriteUrl = myAccountConfig ? myAccountConfig.cavcoFavoriteUrl : null;
  // initAllFavoritesForCurrentUser(loadFavoriteUrl);

  const windowDimensions = useWindowDimensions();

  const apiKey = googleMapConfig && googleMapConfig.apiKey ? googleMapConfig.apiKey : '';
  const internalLoc =
    googleMapConfig && googleMapConfig.mapEndpoint ? googleMapConfig.mapEndpoint : '';

  const searchParameters = useLocation().search;
  let locationNameSearched = '';
  let latitudeVal = 0;
  let longitudeVal = 0;
  // zoomLevelValue = 0;
  let resultsPreposition = 'In';

  if (searchParameters) {
    locationNameSearched = queryString.parse(searchParameters).name
      ? queryString.parse(searchParameters).name!.toString()
      : '';
    // if (queryString.parse(searchParameters).zoom)
    // useZoomLevelValue(parseInt(queryString.parse(searchParameters).zoom!.toString(), 10));
    if (queryString.parse(searchParameters).latitude) {
      latitudeVal = parseFloat(queryString.parse(searchParameters).latitude!.toString());
      resultsPreposition = 'Near';
    }
    if (queryString.parse(searchParameters).longitude)
      longitudeVal = parseFloat(queryString.parse(searchParameters).longitude!.toString());
  }
  if (!latitudeVal && center) {
    latitudeVal = parseFloat(center.split(',')[0]);
  }
  if (!longitudeVal && center) {
    longitudeVal = parseFloat(center.split(',')[1]);
  }
  // if (zoomLevel) {
  // zoomLevelValue = parseInt(zoomLevel, 10);
  useZoomLevelValue(zoomLevelValue > 0 ? zoomLevelValue : parseInt(zoomLevel, 10));
  // }

  const details = props.page.getComponent('main', 'detailcontainer');
  const breadcrumb = details && details.getComponent('breadcrumb-stack');

  const compoundLocationNameSearched = `${resultsPreposition} ${locationNameSearched}`;
  const numLocationsAvailable = cards && cards.length ? cards.length : 0;
  const locationsAvailableText =
    numLocationsAvailable === 1
      ? `1 Location Available `
      : `${numLocationsAvailable} Locations Available `;

  usePlacesValues(placesValues.length > 0 ? placesValues : buildMapPlaces(cards));

  const displayLocationOverrideMarkers = () => {
    return Object.keys(locationTypeOverrides).map((key, index) => {
      const markerClass = `retailer-location-icon ${key}`;
      const keyIndex = `${key}-${index}`;
      return (
        <div className="retailer-location-legend" key={keyIndex}>
          <RetailerIcon className={markerClass} />
          <span className="retailer-location-icon-label">{locationTypeOverrides[key]}</span>
        </div>
      );
    });
  };

  const displayLegendIcons = () => {
    return (
      <div className="cvc-retailer-list-location-icons-container">
        {locationTypeOverrides && displayLocationOverrideMarkers()}
        <div className="retailer-location-legend">
          <RetailerIcon className="retailer-location-icon is-retailer" />
          <span className="retailer-location-icon-label">Retailer Location</span>
        </div>
        <div className="retailer-location-legend">
          <CommunityIcon className="retailer-location-icon is-community" />
          <span className="retailer-location-icon-label">Community Location</span>
        </div>
      </div>
    );
  };

  const searchFilterAutocompleteInputFocus = () => {
    const searchFilterAutocompleteInput = document.querySelector(
      '.search-filter-autocomplete-input'
    );
    if (searchFilterAutocompleteInput) {
      (searchFilterAutocompleteInput as HTMLInputElement).focus();
    }
  };

  const goToPage = (data: any) => {
    const urlVal = GoToPageUrl(data);
    setTempRetailerSearch(data);
    setNewLocation(urlVal);
  };

  const suggestionSelected = (data: any) => {
    let locationPathSplitted: any = window.location.pathname;
    let urlValue = locationPathSplitted;
    if (window.location.pathname && window.location.pathname.split('/').length > 1) {
      locationPathSplitted = window.location.pathname.split('/');
      locationPathSplitted.pop();
      // country abbreviation is to be added back later on
      if (
        locationPathSplitted[locationPathSplitted.length - 1] === 'us' ||
        locationPathSplitted[locationPathSplitted.length - 1] === 'ca'
      ) {
        locationPathSplitted.pop();
      }

      urlValue = locationPathSplitted.join('/');
    }

    const appendParamOnly = false;
    GeocodeByAddress(data.description).then(({ lat, lng }: any) =>
      goToPage({
        url: urlValue,
        terms: data.terms,
        lat,
        lng,
        appendParamOnly,
      })
    );
  };

  const handleSearchAgain = () => {
    setIsSearching(true);

    if (tempRetailerSearch.description !== '') {
      if (newLocation.includes('our-retailers') || newLocation.includes('park-retailers')) {
        if (tempRetailerSearch.lat && tempRetailerSearch.lng && tempRetailerSearch.terms) {
          setCurrentRetailerSearch({
            latitude: tempRetailerSearch.lat,
            longitude: tempRetailerSearch.lng,
            dataTerms: tempRetailerSearch.terms,
          });
        }
      }

      redirectToUrlWithNewZoomLevel(
        newLocation,
        history,
        useZoomLevelValue,
        usePlacesValues,
        setRenderMapWithMarkers,
        setIsSearching
      );
    } else {
      setIsSearching(false);
      searchFilterAutocompleteInputFocus();
    }
  };

  const displayMobileLinks = () => {
    return (
      <div className="retailer-mobile-links">
        <Button
          variant="link"
          className={`${displayInMobile === 'cards' ? 'active' : ''}`}
          onClick={() => setDisplayInMobile('cards')}
        >
          List View
        </Button>
        {' | '}
        <Button
          variant="link"
          className={`${displayInMobile === 'map' ? 'active' : ''}`}
          onClick={() => setDisplayInMobile('map')}
        >
          Map View
        </Button>
      </div>
    );
  };

  const retailersCardsContainerClasses = classNames({
    show:
      windowDimensions.isTablet ||
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      (windowDimensions.isMobile && displayInMobile === 'cards'),
    hide: windowDimensions.isMobile && displayInMobile !== 'cards',
    'retailers-cards-container': true,
    'no-horizontal-padding': true,
  });

  const retailersMapContainerClasses = classNames({
    show:
      windowDimensions.isTablet ||
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      (windowDimensions.isMobile && displayInMobile === 'map'),
    hide: windowDimensions.isMobile && displayInMobile !== 'map',
    'retailers-map-container': true,
    'no-horizontal-padding': true,
  });

  const displayMapWithMarkers = useCallback(() => {
    window.cavco = {
      mapConfig: {
        lat: latitudeVal,
        lng: longitudeVal,
        places: placesValues,
        zoom: zoomLevelValue,
      },
    };
    setRenderMapWithMarkers((current) => !current);
  }, [latitudeVal, longitudeVal]);

  const displayAutocomplete = () => {
    return (
      <AutoCompleteComp
        apiKey={checkForNessus(apiKey)}
        placeholder="Enter City/State or Province"
        onClick={suggestionSelected}
        inputClassName="search-filter-autocomplete-input"
        initialValue={locationNameSearched}
        geocodeEndpoint={internalLoc}
      />
    );

    // if (getOperatingSystem() === OPERATIVE_SYSTEM.WINDOWS_OS) {
    //   return (
    //     <AutoCompleteComp
    //       apiKey={apiKey}
    //       placeholder="Enter City/State or Province"
    //       onClick={suggestionSelected}
    //       inputClassName="search-filter-autocomplete-input"
    //       initialValue={locationNameSearched}
    //       geocodeEndpoint={internalLoc}
    //     />
    //   );
    // }

    // return mapWithMarkersRendered || noDocuments ? (
    //   <AutoCompleteComp
    //     apiKey={apiKey}
    //     placeholder="Enter City/State or Province"
    //     onClick={suggestionSelected}
    //     inputClassName="search-filter-autocomplete-input"
    //     initialValue={locationNameSearched}
    //     geocodeEndpoint={internalLoc}
    //   />
    // ) : (
    //   <div className="cvc-autocomplete-container">
    //     <div className="google-places-autocomplete">
    //       <input
    //         type="text"
    //         placeholder="Enter City/State or Province"
    //         className="search-filter-autocomplete-input"
    //         defaultValue={locationNameSearched}
    //       />
    //     </div>
    //   </div>
    // );
  };

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    const generalBreadcrumb = document.body.querySelector(
      '.cvc-breadcrumb-container:not(.is-custom)'
    );
    if (generalBreadcrumb) {
      (generalBreadcrumb as HTMLDivElement).style.display = 'none';
    }

    handleScriptLoaded();
  });

  const isCancelled = React.useRef(false);

  useEffect(() => {
    if (placesValues.length > 0 && cards) {
      displayMapWithMarkers();
    }
    return () => {
      isCancelled.current = true;
    };
  }, [displayMapWithMarkers, latitudeVal, longitudeVal, cards]);

  useEffect(() => {
    const searchInputBox: any = document.querySelector('.search-filter-autocomplete-input')!;
    if (searchInputBox) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          handleSearchAgain();
        }
      };

      searchInputBox.addEventListener('keydown', handleKeyDown);
      return () => searchInputBox.removeEventListener('keydown', handleKeyDown);
    }
  });

  return (
    <Container className="cvc-retailer-list">
      <Row className="no-horizontal-margin">
        <Col xs={12} className="cvc-retailer-list-search-filters-container no-horizontal-padding">
          <Row className="px-3">
            <div className="cvc-retailer-list-breadcrumb d-sm-block">
              {breadcrumb && (
                <CustomBreadcrumbStack props={{ page: props.page, component: breadcrumb }} />
              )}
            </div>
            <h1 className="search-filter-locations-text-container">
              <span className="search-filter-locations-availables">{locationsAvailableText}</span>
              <span className="search-filter-location-searched">
                {compoundLocationNameSearched}
              </span>
            </h1>
          </Row>

          {displayAutocomplete()}
          <button
            type="button"
            className="btn btn-primary d-inline-block"
            onClick={handleSearchAgain}
            disabled={isSearching === true}
            tabIndex={isSearching ? -1 : 0}
          >
            Search Again
          </button>
          {isSearching ? (
            <Spinner
              color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
            />
          ) : (
            ''
          )}
        </Col>
      </Row>

      {!cards && buildNoRetailersMessage(props, noDocuments, maxRadiusString)}
      {cards && (
        <Row className="no-horizontal-margin cvc-retailer-list-locations-container">
          {windowDimensions.isMobile ? displayMobileLinks() : ''}
          <Col xs={12} md={4} className={retailersCardsContainerClasses}>
            {buildPlaceCards(props)}
          </Col>
          <Col xs={12} md={8} className={retailersMapContainerClasses}>
            <div id="map" />
            {zoomLevelValue > 0 && renderMapWithMarkers ? displayMapWithMarkers() : ''}
            {windowDimensions.isMobile ? displayLegendIcons() : ''}
          </Col>
        </Row>
      )}
      {cards &&
      (windowDimensions.isTablet ||
        windowDimensions.isDesktop ||
        windowDimensions.isDesktopBig ||
        windowDimensions.isDesktopExtraBig)
        ? displayLegendIcons()
        : ''}
    </Container>
  );
}
