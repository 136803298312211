import React from 'react';
import './CavcoChips.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/cavcohomes/x-white.svg';

interface CavcoChipsProps {
  label: string;
  handleCloseEvent?: any;
  value: string;
}

export function CavcoChips(props: CavcoChipsProps) {
  const { label, value, handleCloseEvent } = props;

  const handleClose = () => {
    if (handleCloseEvent) {
      handleCloseEvent(value);
    }
  };

  return (
    <div className="cavcochip-container" role="button" tabIndex={0}>
      <span>{label}</span>
      <div onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
        <CloseIcon className="chips-close-icon" />
      </div>
    </div>
  );
}
