import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import { Formik, Form, Field } from 'formik';
import { scroller, Element } from 'react-scroll';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { ResolverLink } from 'components/shared';
import { Spinner } from 'components/shared/Spinner';
import { RETAILER_TYPE } from '../enums';
import { getBuildingCenterFromHeader, TagManager } from '../utils';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomerServiceForm.scss';

let isSearching = false;

function RetailerConfirmationModal(props: any) {
  const classnames = props.isError
    ? classNames('modal-error-title', 'mb-3')
    : classNames('modal-title', 'mb-3');

  return (
    <Modal className="cvc-fp-request-info-modal text-center" show={props.show} onHide={props.close}>
      <Modal.Header closeButton />
      <Modal.Body className="mt-4 px-5">
        <h2 className={classnames}>{props.title}</h2>
        <p>{props.content}</p>
        <button
          type="button"
          className="cvc-form-button btn btn-primary px-4"
          onClick={props.close}
        >
          {props.buttonText}
        </button>
      </Modal.Body>
    </Modal>
  );
}

const setIsSearching = (value: any) => {
  isSearching = value;
};

export function CustomerServiceForm({ brProps }: { brProps: BrProps }) {
  const location = useLocation();

  const {
    formModalLabel: modalLbl,
    customerRequestFormConfig: config,
    usStates,
    caProvinces,
    nameTitles,
    componentParameterMap,
    spinnerConfig,
    buildingcenterlist,
    documents,
    GoogleReCaptchaWebConfig: reCaptchaConfig,
  } = brProps.component.getModels();
  const [modal, setModal] = useState(false);

  const isReCaptchaEnabled =
    reCaptchaConfig &&
    reCaptchaConfig.serviceForm &&
    (reCaptchaConfig.serviceForm.toUpperCase() === 'ON' ||
      reCaptchaConfig.serviceForm.toUpperCase() === 'ENABLED' ||
      reCaptchaConfig.serviceForm.toUpperCase() === 'TRUE');

  // captcha
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState<any>('');

  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  // Validation
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [streetAddress, setStreetAddress] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [zipCode, setZipCode] = useState(false);
  const [, setComments] = useState(false);
  const [plantUidValidation, setPlantUidValidation] = useState(false);

  const [title, setTitle] = useState(false);
  const [email, setEmail] = useState(false);
  const [mainPhone, setMainPhone] = useState(false);

  const [modalContent, setModalContent] = useState({ title: '', text: '', isError: false });

  // Input values
  const [titleVal, setTitleVal] = useState('');
  const [stateVal, setStateVal] = useState('');

  const { page } = brProps;
  const bcDocument = getBuildingCenterFromHeader(page);
  const bcName = [];
  if (bcDocument) {
    bcName[0] = bcDocument?.name;
  }
  const retailerName = bcName.length
    ? bcName
    : location.pathname.split('/').filter((x: any) => Boolean(x));

  const document = brProps.page.getContent(documents[0]);
  let content: any = null;
  const contentData = document?.getData();
  if (contentData && contentData.content) {
    content = contentData.content;
  }
  const plantUidOptions = buildingcenterlist ? Object.entries(buildingcenterlist) : [];

  const value = retailerName.length
    ? plantUidOptions.filter((x: any) =>
        retailerName.find((y: string) =>
          x[1].toLowerCase().includes(y.toLowerCase().replace('-', ' '))
        )
      )
    : false;
  const [plantUidVal, setPlantUidVal] = useState(value && value.length > 0 ? value[0][0] : '');
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
  function handleTitleChange(event: any) {
    setTitleVal(event.target.value);
  }

  function handleStateChange(event: any) {
    setStateVal(event.target.value);
  }

  function handlePlantUidChange(event: any) {
    setPlantUidVal(event.target.value);
  }

  function handleDateChange(date: any, e: any) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    setPurchaseDate(date);
  }

  function validateFields() {
    setIsSearching(true);

    setTitle(false);
    setFirstName(false);
    setLastName(false);
    setStreetAddress(false);
    setCity(false);
    setState(false);
    setZipCode(false);
    setEmail(false);
    setMainPhone(false);
    setComments(false);

    scroller.scrollTo('CustomerServiceForm', {
      duration: 1000,
      smooth: true,
      offset: -150,
    });

    setIsSearching(false);
  }

  let { retailerFormLabels: lbl } = brProps.component.getModels();

  if (!lbl) {
    lbl = {};
  }
  // const {
  //   formModalLabel: modalLbl,
  //   customerRequestFormConfig: config,
  //   usStates,
  //   caProvinces,
  //   nameTitles,
  //   componentParameterMap,
  //   spinnerConfig,
  //   buildingcenterlist,
  // } = brProps.component.getModels();

  const getDefaultHelpText = (label: any, isParkVal: boolean) => {
    if (label) {
      if (isParkVal) {
        return label.helpDescription_park;
      }
      return label.helpDescription;
    }
    return '';
  };
  const formId = 'customer-service-form';
  const { variant2: retailerType } = componentParameterMap;
  const isParkVal = retailerType && retailerType === RETAILER_TYPE.PARKMODEL;
  const defaultHelpDescription = getDefaultHelpText(lbl, isParkVal);
  const { webType } = config;

  const titleOptions = nameTitles ? Object.entries(nameTitles) : [];
  titleOptions.sort();

  // const plantUidOptions = buildingcenterlist ? Object.entries(buildingcenterlist) : [];
  let stateOptions = { ...usStates, ...caProvinces };
  stateOptions = Object.entries(stateOptions);
  stateOptions.sort();

  // for both US and Canada
  const zipPattern = '[0-9]{5}(?:-[0-9]{4})?|[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]';

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;
  return (
    <div className="cvc-customer-service cvc-full-page-form">
      <Container>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            title: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            emailAddress: '',
            mainPhoneNo: '',
            mphone1: '',
            mphone2: '',
            mphone3: '',
            cellPhoneNo: '',
            cphone1: '',
            cphone2: '',
            cphone3: '',
            workPhoneNo: '',
            wphone1: '',
            wphone2: '',
            wphone3: '',
            purchasedDate: '',
            comments: defaultHelpDescription,
            serialNumber: '',
            webType: '',
            plantUid: '',
            captchaToken: '',
          }}
          onSubmit={async (values, { resetForm }) => {
            if (purchaseDate && purchaseDate > new Date()) {
              return;
            }
            await setIsSearching(true);

            values.mainPhoneNo = values.mphone1 + values.mphone2 + values.mphone3;
            values.cellPhoneNo = values.cphone1 + values.cphone2 + values.cphone3;
            values.workPhoneNo = values.wphone1 + values.wphone2 + values.wphone3;
            values.title = titleVal;
            values.state = stateVal;
            values.webType = webType;
            values.plantUid = plantUidVal as string;
            values.captchaToken = captchaToken;

            if (purchaseDate && purchaseDate !== null) {
              values.purchasedDate = purchaseDate.toLocaleDateString('en-US');
            }

            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
            };
            const formUrl =
              config.apiRoot +
              (isParkVal ? config.requestServiceParkUrl : config.requestServiceUrl);
            //          console.log("formUrl", formUrl);
            fetch(formUrl, requestOptions)
              .then((response) => response.json())
              .then(async (data) => {
                console.log('responseData:', data); // eslint-disable-line

                // check for error
                if (data.status !== 200) {
                  const textLength = modalLbl.modalTextLength
                    ? parseInt(modalLbl.modalTextLength, 10)
                    : 50;
                  const errorMessage =
                    data.payload.length > textLength
                      ? `${data.payload.substr(0, textLength)}...`
                      : data.payload;
                  setModalContent({
                    title: modalLbl.modalErrorTitle,
                    text: errorMessage,
                    isError: true,
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'formError',
                      errorMessage,
                      formId,
                      pageHref: location.pathname,
                    },
                  });

                  await setIsSearching(false);
                  await openModal();
                } else {
                  setModalContent({
                    title: modalLbl.modalTitle,
                    text: modalLbl.modalText,
                    isError: false,
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'formSubmission',
                      formId,
                      pageHref: location.pathname,
                    },
                  });

                  await setIsSearching(false);
                  await openModal();

                  setStateVal('');
                  setTitleVal('');
                  setPurchaseDate(null);
                  resetForm();
                  const captchaRefCurrent: any = captchaRef.current;
                  if (captchaRefCurrent) captchaRefCurrent.reset();
                  setCaptchaToken('');
                }
              })
              .catch(async (error) => {
                console.log('error from the server:', error.message); // eslint-disable-line
                const textLength = modalLbl.modalTextLength
                  ? parseInt(modalLbl.modalTextLength, 10)
                  : 50;
                const errorMessage =
                  error.message.length > textLength
                    ? `${error.message.substr(0, textLength)}...`
                    : error.message;
                setModalContent({
                  title: modalLbl.modalErrorTitle,
                  text: errorMessage,
                  isError: true,
                });
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'formError',
                    errorMessage,
                    formId,
                    pageHref: location.pathname,
                  },
                });

                await setIsSearching(false);
                await openModal();
              });

            console.log(values); // eslint-disable-line
          }}
        >
          {({ handleSubmit }) => {
            return (
              <Element name="CustomerServiceForm">
                <Form
                  id={formId}
                  className="cvc-customer-service-form cvc-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (purchaseDate && purchaseDate > new Date()) {
                      return;
                    }
                    handleSubmit(e);
                  }}
                >
                  <Row className="justify-content-between">
                    <Col className="cvc-customer-service-info" md={5}>
                      <h2 className="cvc-form-section-title">{lbl.contactTitleInfo}</h2>

                      <div className="form-row">
                        <Col className="cvc-form-group align-self-end" xs={3}>
                          <label htmlFor="title" className={title ? 'cvc-form-error' : ''}>
                            {lbl.title}
                          </label>
                          <Field
                            id="title"
                            as="select"
                            name="title"
                            aria-label="Title"
                            value={titleVal}
                            onChange={handleTitleChange}
                            onInvalid={() => setTitle(true)}
                          >
                            <option value="">{lbl.title}</option>
                            {titleOptions.map((option: any) => {
                              return (
                                <option key={option[1]} value={option[1]}>
                                  {option[0]}
                                </option>
                              );
                            })}
                          </Field>
                        </Col>

                        <Col className="cvc-form-group cvc-form-required">
                          <label htmlFor="firstName" className={firstName ? 'cvc-form-error' : ''}>
                            {lbl.firstName}
                          </label>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            inputMode="text"
                            autoComplete="given-name"
                            pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                            required
                            onInvalid={() => setFirstName(true)}
                          />
                        </Col>

                        <Col className="cvc-form-group cvc-form-required">
                          <label htmlFor="lasttName" className={lastName ? 'cvc-form-error' : ''}>
                            {lbl.lastName}
                          </label>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="text"
                            inputMode="text"
                            autoComplete="family-name"
                            pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                            required
                            onInvalid={() => setLastName(true)}
                          />
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group cvc-form-required">
                          <label
                            htmlFor="streetAddress"
                            className={streetAddress ? 'cvc-form-error' : ''}
                          >
                            {lbl.contactInfo}
                          </label>
                          <Field
                            id="streetAddress"
                            name="streetAddress"
                            autoComplete="street-address"
                            type="text"
                            inputMode="text"
                            placeholder={lbl.streetAddress}
                            pattern="[A-Za-z0-9_\-\s\.]+"
                            required
                            onInvalid={() => setStreetAddress(true)}
                          />
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group cvc-form-required" xs={5}>
                          <label htmlFor="city" className={city ? 'cvc-form-error' : 'city-label'}>
                            {lbl.city}
                          </label>
                          <Field
                            id="city"
                            name="city"
                            inputMode="text"
                            autoComplete="address-level2"
                            type="text"
                            placeholder={lbl.city}
                            pattern="[A-Za-z\-\s\.]+"
                            aria-label="Location city"
                            required
                            onInvalid={() => setCity(true)}
                          />
                        </Col>

                        <Col className="cvc-form-group cvc-form-required align-self-end" xs={4}>
                          <label
                            htmlFor="state"
                            className={state ? 'cvc-form-error' : 'state-label'}
                          >
                            {lbl.state}
                          </label>
                          <Field
                            id="state"
                            as="select"
                            name="state"
                            autoComplete="address-level1"
                            aria-label="Location state"
                            required
                            value={stateVal}
                            onChange={handleStateChange}
                            onInvalid={() => setState(true)}
                          >
                            <option value="" disabled>
                              {lbl.state}
                            </option>
                            {stateOptions.map((option: any) => {
                              return (
                                <option key={option[1]} value={option[1]}>
                                  {option[0]}
                                </option>
                              );
                            })}
                          </Field>
                        </Col>

                        <Col className="cvc-form-group cvc-form-required" xs={3}>
                          <label
                            htmlFor="zipCode"
                            className={zipCode ? 'cvc-form-error' : 'zipcode-label'}
                          >
                            {lbl.zip}
                          </label>
                          <Field
                            id="zipCode"
                            name="zipCode"
                            inputMode="decimal"
                            autoComplete="postal-code"
                            type="text"
                            placeholder={lbl.zip}
                            pattern={zipPattern}
                            required
                            onInvalid={() => setZipCode(true)}
                          />
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group cvc-form-required">
                          <label htmlFor="emailAddress" className={email ? 'cvc-form-error' : ''}>
                            {lbl.email}
                          </label>
                          <Field
                            id="emailAddress"
                            name="emailAddress"
                            inputMode="email"
                            autoComplete="email"
                            type="email"
                            required
                            onInvalid={() => setEmail(true)}
                          />
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group cvc-form-required">
                          <label htmlFor="mphone1" className={mainPhone ? 'cvc-form-error' : ''}>
                            {lbl.yourMainPhone}
                          </label>
                          <div className="d-flex align-items-center phone-number">
                            <Field
                              className="col-2"
                              name="mphone1"
                              inputMode="tel"
                              autoComplete="tel-area-code"
                              type="tel"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone area code"
                              required
                              onInvalid={() => setMainPhone(true)}
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-2"
                              name="mphone2"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-prefix"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone number"
                              required
                              onInvalid={() => setMainPhone(true)}
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-3"
                              name="mphone3"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-suffix"
                              maxLength="4"
                              pattern="[0-9]{4}"
                              aria-label="Phone number"
                              required
                              onInvalid={() => setMainPhone(true)}
                            />
                          </div>
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group">
                          <label htmlFor="cphone1">{lbl.yourCellPhone}</label>
                          <div className="d-flex align-items-center phone-number">
                            <Field
                              className="col-2"
                              name="cphone1"
                              inputMode="tel"
                              autoComplete="tel-area-code"
                              type="tel"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone area code"
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-2"
                              name="cphone2"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-prefix"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone number"
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-3"
                              name="cphone3"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-suffix"
                              maxLength="4"
                              pattern="[0-9]{4}"
                              aria-label="Phone number"
                            />
                          </div>
                        </Col>
                      </div>

                      <div className="form-row">
                        <Col className="cvc-form-group">
                          <label htmlFor="wphone1">{lbl.yourWorkPhone}</label>
                          <div className="d-flex align-items-center phone-number">
                            <Field
                              className="col-2"
                              name="wphone1"
                              inputMode="tel"
                              autoComplete="tel-area-code"
                              type="tel"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone area code"
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-2"
                              name="wphone2"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-prefix"
                              maxLength="3"
                              pattern="[0-9]{3}"
                              aria-label="Phone number"
                            />
                            &nbsp;-&nbsp;
                            <Field
                              className="col-3"
                              name="wphone3"
                              type="tel"
                              inputMode="tel"
                              autoComplete="tel-local-suffix"
                              maxLength="4"
                              pattern="[0-9]{4}"
                              aria-label="Phone number"
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                    {/* end your info */}

                    <Col className="cvc-customer-service-location" md={6}>
                      <h2 className="cvc-form-section-title">{lbl.titleHomeInfo}</h2>
                      <Col className="cvc-form-group">
                        {buildingcenterlist ? (
                          <label htmlFor="selectBuildingCenterCustomerService">
                            {lbl.selectBuildingCenterCustomerService}
                          </label>
                        ) : (
                          ''
                        )}
                      </Col>
                      {buildingcenterlist ? (
                        <div className="form-row flex-wrap">
                          <Col className="cvc-form-group cvc-form-required align-self-end">
                            <label
                              htmlFor="plantUid"
                              className={` col-12 ${plantUidValidation ? 'cvc-form-error' : ''}`}
                            >
                              {lbl.selectBuildingCenterBelow}
                            </label>
                            <Field
                              id="plantUid"
                              as="select"
                              name="plantUid"
                              aria-label="Plant ID"
                              required
                              value={plantUidVal}
                              onChange={handlePlantUidChange}
                              onInvalid={() => setPlantUidValidation(true)}
                            >
                              <option value="" disabled>
                                {lbl.selectBuildingCenter}
                              </option>
                              {plantUidOptions.map((option: any) => {
                                return (
                                  <option key={option[0]} value={option[0]}>
                                    {option[1]}
                                  </option>
                                );
                              })}
                            </Field>
                          </Col>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="form-row flex-wrap">
                        <Col className="cvc-form-group cvc-form-required align-self-end">
                          {content && content.value ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content.value,
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="cvc-form-group" xs={6}>
                          <label htmlFor="serialNumber">{lbl.serialNumber}</label>
                          <Field
                            name="serialNumber"
                            type="text"
                            pattern="[A-Za-z0-9_/-]+"
                            aria-label={lbl.serialNumber}
                          />
                        </Col>
                        <Col className="cvc-form-group align-self-end" xs={6}>
                          <label htmlFor="purchasedDate">{lbl.dateOfPurchase}</label>
                          <div className="form-row">
                            <Col className="position-relative">
                              <label // eslint-disable-line
                                htmlFor="purchasedDate"
                              >
                                <DatePicker
                                  className="cvc-form-field-date"
                                  id="purchasedDate"
                                  name="purchasedDate"
                                  selected={purchaseDate}
                                  placeholderText={lbl.pickDate}
                                  aria-label={lbl.dateOfPurchase}
                                  onChange={handleDateChange}
                                />
                                <span className="icon cvc-icon--calendar-mini-blue" />
                              </label>
                              {purchaseDate && purchaseDate > new Date() && (
                                <span className="errorMessage">
                                  Purchase Date is not valid. The date cannot be greater than
                                  today’s date.
                                </span>
                              )}
                            </Col>
                          </div>
                        </Col>
                      </div>

                      <h2 className="cvc-form-section-title help-title">{lbl.helpTitle} *</h2>

                      <div className="form-row">
                        <Col className="cvc-form-group cvc-form-required">
                          <Field
                            name="comments"
                            className="p-2"
                            component="textarea"
                            type="text"
                            aria-label={lbl.helpTitle}
                            rows={6}
                            required
                            onInvalid={() => setComments(true)}
                          />
                        </Col>
                      </div>
                    </Col>
                    {/* end your home info */}
                  </Row>

                  <Row className="mt-4">
                    <Col className="text-center">
                      {isReCaptchaEnabled ? (
                        <div className="cvc-form-captcha">
                          <ReCAPTCHA
                            sitekey={reCaptchaConfig.siteKey}
                            ref={captchaRef}
                            onChange={(token) => setCaptchaToken(token)}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <p className="cvc-form-text disclaimer">{lbl.required}</p>
                      <p className="cvc-form-text disclaimer">
                        {lbl.privacy}{' '}
                        <ResolverLink href={lbl.privacyUrl}>{lbl.privacyLinkText}</ResolverLink>.
                      </p>
                      <button
                        className="cvc-form-button mt-3 btn btn-primary"
                        type="submit"
                        onClick={validateFields}
                        disabled={isSearching === true || (isReCaptchaEnabled && !captchaToken)}
                        tabIndex={isSearching ? -1 : 0}
                      >
                        {lbl.submit}
                      </button>
                      {isSearching ? (
                        <Spinner
                          color={
                            spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''
                          }
                        />
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Form>

                <RetailerConfirmationModal
                  title={modalContent.title}
                  content={modalContent.text}
                  buttonText={modalLbl && modalLbl.modalButtonText}
                  show={modal}
                  close={closeModal}
                  isError={modalContent.isError}
                />
              </Element>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
}
