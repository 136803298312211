import { BrProps } from '@bloomreach/react-sdk';
import useFetch from 'components/hooks/FetchElasticSearchResults.hook';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CaretDownIcon,
  FiltersIcon,
  GlobalScrollToTopButton,
  ResolverLink,
  Spinner,
} from 'components/shared';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { Button, Container } from 'react-bootstrap';
import SiteGlobalSwitches, {
  getBuildingCenterFromHeader,
  getTileGridDocumentsFromComponent,
} from 'components/utils';
import classNames from 'classnames';
import { FloorPlanPagination } from 'components/FloorPlanResultsCH/components/FloorPlanPagination/FloorPlanPagination';
import { NoResultsTileGrid } from 'components/TileGrid/TileGrid';
import {
  allFacets,
  allPageSort,
  filterConfiguration,
  pageTypeActiveSortConfigs,
  pageTypeDefaultSortConfigs,
  parkModelFilter,
  squareFootRanges,
} from './FloorPlanListES.constants';
import {
  allFacetsMapper,
  checkIsDefaultValue,
  facetMapper,
  getFacets,
  getLocalizationData,
  sortFacetsByOrder,
} from './services/facets.service';
import {
  buildBaseFiltersArray,
  buildESRequest,
  buildLocationFacet,
  buildQueryString,
  buildUiLocationFilters,
  getQueryStringValues,
  processLocationFiltersForEs,
} from './services/url.service';
import { CustomBreadcrumbStack } from '../Breadcrumb/BreadcrumbStack';
import { FloorPlanCard } from './FloorPlanCard';
import { FloorPlanSearch } from './FloorPlanSearch';
import { NoFloorPlansMessage } from './NoFloorPlansMessage';
import { MyFiltersBar } from './MyFiltersBar/MyFiltersBar';
import { DynamicFiltersPanel } from './DynamicFiltersPanel/DynamicFiltersPanel';
import { FloorPlanSort } from './FloorPlanSort/FloorPlanSort';

function displayFloorPlanCards(
  props: BrProps,
  floorplanResults: any,
  windowDimensions: any,
  isSearchPage: boolean,
  isBuildingCenter: boolean
) {
  if (!floorplanResults || floorplanResults === undefined || floorplanResults.length === 0) {
    return (
      <p className="cvc-floorplan-no-results-found-with-filters">
        No Floor Plans were found with the filters selected.
      </p>
    );
  }

  if (
    floorplanResults !== undefined &&
    floorplanResults.length > 0 &&
    floorplanResults[0].id !== ''
  ) {
    const displayFPCards = () => {
      return (
        <div
          className={`floorplans-cards-container cvc-product-list-cards${
            isSearchPage ? '' : ' cvc-floorplan-list-availability'
          }`}
        >
          {floorplanResults.map((fpResult: any, index: number) => {
            const keyString = `floorplan-card-${index.toString()}`;
            return (
              <FloorPlanCard
                key={keyString}
                floorPlanResult={fpResult}
                windowDimensions={windowDimensions}
                props={props}
                isBuildingCenter={isBuildingCenter}
              />
            );
          })}
        </div>
      );
    };
    return displayFPCards();
  }
}

enum PAGE_URL {
  FLOORPLAN_PAGE_ROOT = 'our-homes',
  PARK_MODEL_PAGE_ROOT = 'our-park-models',
  BUILDING_CENTER = 'building-center',
}

export function FloorPlanESResults({
  props,
  localization,
  configData,
  uiFacetConfig,
  esFacetConfig,
  allFilters,
  elasticSearch,
  filterConfig,
  hasLocationFilter,
  initialQueryStringValues,
  brandName,
  bcDocument,
  isBuildingCenterOnly,
  isParkModel,
  useParkModelFilter,
  isSearchPage,
}: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const windowDimensions = useWindowDimensions();
  const { isMobile, isTablet } = windowDimensions;

  const compModels = props.component.getModels();

  const [primed, setPrimed] = useState(true);

  const { elasticSearchResponse, setElasticSearchRequest, elasticSearchLoading } = elasticSearch;

  const { listType, listSubType, baseLocationFacet, facetConfigInstance, facetBarConfig } =
    configData;

  const { hasSearch, primary_count } = filterConfig;

  const [queryStringItems, setQueryStringItems] = useState<any>(initialQueryStringValues);
  const [selectedSortType, setSortType] = useState<any>(queryStringItems.general.sortBy);

  const [displayInMobile, setDisplayInMobile] = useState('cards');
  const [accordionFiltersMobileOpen, setAccordionFiltersMobileOpen] = useState(false);
  const [sortOptionsMobileOpen, setSortOptionsMobileOpen] = useState(false);
  const [showListGroupSortByOptions, setShowListGroupSortByOptions] = useState(false);

  const [locationNameSearched, setLocationNameSearched] = useState(
    initialQueryStringValues?.general.name
  );

  const tileGridDocumentData = getTileGridDocumentsFromComponent(props.page);
  const { document1, document2, document3, document4, tileGridTitle } = tileGridDocumentData || {};

  // QUEUES
  // data retuned from a facet chance needs to be set manually by the user.
  // store it in its queue until the user confirms it. queue used useRef hook to avoid triggering a re-render
  const facetsQueue = useRef<any>(null);
  const resultsQueue = useRef<any>([]);
  const metaDataQueue = useRef<any>(null);

  // STATE
  // set manually by the user
  // will cause the UI to re-render

  const paginationState = useRef<any>(allPageSort.page.defaultValue);
  const [facetState, setFacetState] = useState<any>();
  const [currentMeta, setCurrentMeta] = useState<any>({});

  const currentFiltersRequestItems = useRef<any>({});
  const currentGeneralRequestItems = useRef<any>({});

  // {size: 12, current: 1}
  // {square_foot: 'desc'}
  const currentSortRequest = useRef<any>({});

  const currentElasticSearchRequest = useRef<any>([]);

  const initialSelected = useRef<any>({});

  const resetPagination = () => {
    paginationState.current = {
      ...paginationState.current,
      current: allPageSort.page.defaultValue.current,
      size: paginationState.current.size,
    };
  };

  const setResults = () => {
    setPrimed(false);
    setFacetState((state: any) => {
      return {
        ...state,
        results: resultsQueue.current,
        facets: sortFacetsByOrder(facetsQueue.current),
      };
    });

    setCurrentMeta((previous: any) => ({
      ...previous,
      ...metaDataQueue.current,
    }));
  };

  const setData = (response: any) => {
    const { rawResults, rawInfo } = response || {
      rawResults: [],
      rawInfo: { facets: [], meta: { page: { total_pages: 0, total_results: 0 } } },
    };

    if (response) {
      metaDataQueue.current = {
        count: rawInfo.meta?.page?.total_results,
        pages: rawInfo.meta?.page?.total_pages,
        current: rawInfo.meta?.page?.current,
      };
      resultsQueue.current = rawResults;

      // Create a deduplicated array of all the properties of the returned results...
      const allProperties: string[] = rawResults.reduce((memo: any, item: any) => {
        const keys = Object.keys(item);
        return [...new Set([...memo, ...keys])];
      }, []);

      facetsQueue.current = allFilters
        ? facetMapper(allFilters, rawInfo.facets, uiFacetConfig, primary_count, allProperties)
        : [];

      setCurrentMeta((previous: any) => ({
        ...previous,
        count: rawInfo.meta?.page?.total_results,
      }));

      setFacetState((state: any) => {
        return {
          ...state,
          facets: sortFacetsByOrder(facetsQueue.current),
        };
      });

      if (primed) {
        setResults();
      }
    }
  };

  useEffect(() => {
    if (elasticSearchResponse) {
      setData(elasticSearchResponse);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elasticSearchResponse]);

  const setQueryString = (url: any) => {
    window.history.replaceState({}, '', url);
  };

  const processCurrentRequestForElasticSearch = (currentPage?: number) => {
    const currentRequest = currentFiltersRequestItems.current;

    let filter3dTour = false;

    const mergedLocation = processLocationFiltersForEs(currentRequest);
    const forRequest = Object.entries(mergedLocation)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter((item: [string, any]) => {
        return item[0] !== 'square_foot';
      })
      .filter((item: [string, any]) => {
        return Array.isArray(item[1]) ? item[1].length : true;
      })
      .map(([k, v]: [string, any]) => {
        if (Array.isArray(v) && v.includes('3d Tours')) {
          filter3dTour = true;
          const updatedV = v.filter((filter) => filter !== '3d Tours');
          if (updatedV.length) {
            return { [k]: updatedV };
          }
          return null;
        }
        return { [k]: v };
      });

    if (localization.countryCode) {
      forRequest.push({ country_code: localization.countryCode });
    }

    if (bcDocument) {
      forRequest.push({ plant_location_id: bcDocument.plantLocationUid });
    }

    if (isParkModel && useParkModelFilter) {
      forRequest.push(parkModelFilter);
    }

    const sizeFilter = currentRequest?.square_foot;

    const anyFilter: any = [];
    sizeFilter?.forEach((value: any) => {
      const optionRange = squareFootRanges.find((range) => range.to === value);
      if (optionRange) {
        anyFilter.push({ square_foot: { from: optionRange.from, to: optionRange.to } });
      }
    });

    if (filter3dTour) {
      anyFilter.push({ media: ['3d Tours', 'Pana Tour'] });
    }

    const noneFilter = [];
    if (!isParkModel && useParkModelFilter) {
      noneFilter.push(parkModelFilter);
    }

    setQueryString(
      buildQueryString(
        {
          ...currentFiltersRequestItems.current,
          ...currentGeneralRequestItems.current,
          page: currentPage ?? paginationState.current.current,
          sortBy: selectedSortType || '',
        },
        facetConfigInstance
      )
    );

    setQueryStringItems(getQueryStringValues(window.location.search));

    const subTypeFilter = listSubType ? [{ inventory_type: listSubType }] : [];
    currentElasticSearchRequest.current = {
      all: [{ type: listType }, { brand_name: brandName }, ...subTypeFilter, ...forRequest],
      any: anyFilter,
      none: noneFilter,
    };

    setElasticSearchRequest({
      facets: { ...esFacetConfig, ...baseLocationFacet },
      page: paginationState.current,
      sort: currentSortRequest.current,
      filters: currentElasticSearchRequest.current,
      query: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // HANDLE SEARCH CHANGES //
  const handleSearchChange = (data: any) => {
    const { lat, lng, address, country } = data;

    const current = currentFiltersRequestItems.current?.search;
    const value = { ...facetConfigInstance.search.mapper({ lat, lng, unit: localization.unit }) };

    const item = current
      ? {
          ...current,
          ...value,
        }
      : value;

    currentFiltersRequestItems.current = {
      ...currentFiltersRequestItems.current,
      search: item,
    };

    currentGeneralRequestItems.current = {
      ...currentGeneralRequestItems.current,
      name: address,
    };

    setLocationNameSearched(address);
    processCurrentRequestForElasticSearch();

    if (isBuildingCenterOnly) {
      window.location.pathname = window.location.pathname.replace(
        '/floorplans',
        `/floorplans/search/${country}`
      );
    } else {
      window.location.pathname = window.location.pathname.replace(/\/[^/]*$/, `/${country}`);
    }
  };

  const detectFacetChange = (facets: any) => {
    if (!facets) return false;
    const params = new URL(window.location.href).searchParams;
    let hasChanged = false;
    const check = (newFacets: any, queryFacets: any) => {
      newFacets.forEach((f: any) => {
        if (!queryFacets.get(f) || queryFacets.get(f) !== `[${facets[f]}]`) {
          hasChanged = true;
        }
      });
      return hasChanged;
    };
    return check(Object.keys(facets), params);
  };

  const handleSelectedFacetChange = (updatedFacets: any) => {
    // pull out the current search filter...
    const { search } = currentFiltersRequestItems.current;
    const searchFilter = search ? { search } : {};
    if (detectFacetChange(updatedFacets)) resetPagination();

    // if no distance values are set, insert default..
    const adjustedForLocations =
      hasLocationFilter && !updatedFacets.hasOwnProperty('locations')
        ? {
            ...updatedFacets,
            locations: [facetConfigInstance.locations.ui.defaultValue],
          }
        : { ...updatedFacets };

    // update the current filter object...
    currentFiltersRequestItems.current = Object.entries(adjustedForLocations).reduce(
      (memo: any, [k, v]: [string, any]) => {
        // apply the mapper to the current value...
        const value: any = facetConfigInstance[k]?.mapper(v.flat());
        const isDefault = k !== 'locations' && checkIsDefaultValue(k, value);
        const newItem = isDefault ? {} : { [k]: value };
        // return it with the update value and add the search back in...
        return {
          ...memo,
          ...newItem,
          ...searchFilter,
        };
      },
      {}
    );
    processCurrentRequestForElasticSearch();
  };

  const getActiveSortConfig = (sortData: any) => {
    const sortKey = Object.keys(sortData)?.[0];
    const sortVal = sortData[sortKey];
    const sortConfig =
      pageTypeActiveSortConfigs[facetBarConfig.primaryType][facetBarConfig.secondaryType] ||
      undefined;
    if (!sortConfig || !sortConfig?.[sortKey]?.[sortVal]) {
      return sortData;
    }

    return sortConfig?.[sortKey]?.[sortVal];
  };

  const processInitialData = () => {
    const { latitude, longitude, locations, ...filters } = queryStringItems.facets;
    const { sortBy } = queryStringItems.general;
    const newUrl = new URL(window.location.href);
    const sessionPage = newUrl.searchParams.get('page');
    const usePage = sessionPage != null ? +sessionPage : 1;

    paginationState.current = {
      ...paginationState.current,
      current: usePage || 1,
    };

    const defaultSortConfig =
      pageTypeDefaultSortConfigs[facetBarConfig.primaryType][facetBarConfig.secondaryType] || [];
    const defaultSort = defaultSortConfig.length
      ? defaultSortConfig
      : allPageSort.sort.defaultValue;

    const sortObject = sortBy && allPageSort.sort.values.find((o: any) => o.name === sortBy);
    setSortType(sortBy);

    const locationFilter = hasLocationFilter
      ? buildUiLocationFilters({
          type: listType,
          lat: latitude || null,
          lng: longitude || null,
          unit: localization.unit || 'mi',
          id: null,
          distance: facetConfigInstance.locations.ui.defaultValue,
        })
      : {};

    currentFiltersRequestItems.current = locationFilter;

    currentGeneralRequestItems.current = {
      ...queryStringItems.general,
    };

    currentSortRequest.current = sortObject?.value
      ? getActiveSortConfig(sortObject.value)
      : defaultSort;

    initialSelected.current = {
      ...filters,
      ...(hasLocationFilter
        ? {
            locations: locations || facetConfigInstance.locations.ui.defaultValue,
          }
        : {}),
    };

    handleSelectedFacetChange(initialSelected.current);
  };

  useEffect(() => {
    processInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (data: any) => {
    setSortOptionsMobileOpen(false);
    setShowListGroupSortByOptions(false);
    const keys = Object.keys(data);
    const sortString = `${keys[0]}_${data[keys[0]]}`;
    setSortType(sortString);
    resetPagination();
    currentSortRequest.current = getActiveSortConfig(data);
  };

  useEffect(() => {
    setPrimed(true);
    setShowListGroupSortByOptions(false);
    processCurrentRequestForElasticSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortType]);

  const scrollToAnchor = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const handleChangePage = (event: any, value: number) => {
    paginationState.current = {
      ...paginationState.current,
      current: value,
    };

    setPrimed(true);
    processCurrentRequestForElasticSearch();
    scrollToAnchor();
  };

  const handleChangeRowsPerPage = (value: number) => {
    paginationState.current = {
      ...paginationState.current,
      size: value,
    };

    setPrimed(true);
    processCurrentRequestForElasticSearch();
    scrollToAnchor();
  };

  // FloorPlanList Constants
  const { buildingCenterName, siteSearchLabels, componentParameterMap } =
    props.component.getModels();

  const details = props.page.getComponent('main', 'detailcontainer');
  const { hideBreadcrumb } = componentParameterMap;
  const breadcrumb =
    hideBreadcrumb && hideBreadcrumb === 'true'
      ? null
      : details && details.getComponent('breadcrumb-stack');

  const googleMapConfigModelVal = compModels.googleMapConfig ? compModels.googleMapConfig : null;

  const { noDocuments } = compModels;
  const noDocumentsModelVal = compModels.noDocuments ? compModels.noDocuments : null;
  const maxRadiusUnit = localization.unit === 'mi' ? 'miles' : 'KM';
  const currentSearchDistance = initialSelected.current.locations?.[0];
  const maxRadiusStringModelVal = `${currentSearchDistance} ${maxRadiusUnit}`;

  const floorplanDocuments = elasticSearchResponse?.results;
  const totalFloorPlanCount = currentMeta && currentMeta.count ? currentMeta.count : 0;

  const apiKey =
    googleMapConfigModelVal && googleMapConfigModelVal.apiKey ? googleMapConfigModelVal.apiKey : '';
  const internalLoc =
    googleMapConfigModelVal && googleMapConfigModelVal.mapEndpoint
      ? googleMapConfigModelVal.mapEndpoint
      : '';

  const displayTileGrids = () => {
    if (!isParkModel) {
      return (
        <>
          {document1 && (
            <NoResultsTileGrid
              props={props}
              documentRef={document1}
              gridTitleText={tileGridTitle}
            />
          )}
          {document2 && (
            <NoResultsTileGrid props={props} documentRef={document2} gridTitleText={null} />
          )}
        </>
      );
    }
    return (
      <>
        {document3 && (
          <NoResultsTileGrid props={props} documentRef={document3} gridTitleText={tileGridTitle} />
        )}
        {document4 && (
          <NoResultsTileGrid props={props} documentRef={document4} gridTitleText={null} />
        )}
      </>
    );
  };

  const displayBreadcrumb = (breadcrumbInput: any, isSearch: boolean) => {
    return breadcrumbInput && isSearch ? (
      <div className="cvc-floorplan-list-breadcrumb cvc-product-list-breadcrumb d-sm-block">
        {breadcrumb && (
          <CustomBreadcrumbStack props={{ page: props.page, component: breadcrumb }} />
        )}
      </div>
    ) : (
      ''
    );
  };

  const displayMobileLinks = () => {
    const handleMobileLinkSelected = (linkSelected: string) => {
      setDisplayInMobile(linkSelected);

      if (linkSelected === 'cards') {
        setAccordionFiltersMobileOpen(false);

        if (!sortOptionsMobileOpen) {
          setSortOptionsMobileOpen(true);
          setShowListGroupSortByOptions(true);
        } else {
          setSortOptionsMobileOpen(false);
          setShowListGroupSortByOptions(false);
        }
      } else if (linkSelected === 'filters') {
        setSortOptionsMobileOpen(false);
        setShowListGroupSortByOptions(false);

        if (!accordionFiltersMobileOpen) {
          setAccordionFiltersMobileOpen(true);
        } else {
          setAccordionFiltersMobileOpen(false);
        }
      }
    };

    return (
      <div className="floorplan-mobile-links product-list-mobile-links">
        <Container>
          <Button
            variant="link"
            className={`${displayInMobile === 'cards' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('cards')}
          >
            Sort
            <CaretDownIcon
              className={classNames(
                'product-mobile-link-caret-down-icon',
                showListGroupSortByOptions ? 'rotate-icon' : ''
              )}
            />
          </Button>
          <span className="product-mobile-separator">{' | '}</span>
          <Button
            variant="link"
            className={`${displayInMobile === 'filters' ? 'active' : ''}`}
            onClick={() => handleMobileLinkSelected('filters')}
          >
            <FiltersIcon className="product-mobile-filters-icon" />
            Filter Results
          </Button>
        </Container>
        {showListGroupSortByOptions ? (
          <FloorPlanSort
            config={allPageSort.sort}
            sortBy={selectedSortType}
            changeSort={handleSort}
            windowDimensions={windowDimensions}
            count={totalFloorPlanCount}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    if (initialSelected.current) {
      // eslint-disable-next-line
      Object.entries(initialSelected.current).map((filter: any) => {
        const key = filter[0];
        const values = filter[1];
        // eslint-disable-next-line
        values.map((value: any) => {
          const id = `formCheck-${key}-${value}`;
          const inputFilter = document.getElementById(id);
          if (inputFilter) {
            (inputFilter as HTMLInputElement).checked = true;
            inputFilter.classList.toggle('is-selected', true);
          }
        });
      });
    }
  });

  const noFloorplansDocument = props.page.getContent(noDocumentsModelVal);
  const noFloorplansTitle = noFloorplansDocument?.getData()?.title;
  const noFloorplansContent = noFloorplansDocument?.getData()?.content?.value;

  const searchButtonText =
    siteSearchLabels && siteSearchLabels.searchButtonText
      ? siteSearchLabels.searchButtonText
      : 'Search Again';
  let searchedNameDisplay = locationNameSearched;
  if (searchedNameDisplay === '' && isBuildingCenterOnly) {
    searchedNameDisplay = siteSearchLabels.companyName
      ? `${siteSearchLabels.companyName} ${buildingCenterName}`
      : buildingCenterName;
  }

  const floorPlansSortContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'cards'),
    'floorplans-sort-container': true,
    'cvc-product-list-sort': true,
  });

  const floorPlansFiltersContainerClasses = classNames({
    'show-container':
      windowDimensions.isDesktop ||
      windowDimensions.isDesktopBig ||
      windowDimensions.isDesktopExtraBig ||
      ((windowDimensions.isMobile || windowDimensions.isTablet) && displayInMobile === 'filters'),
    'floorplans-filters-container': true,
    'cvc-product-list-main': true,
  });

  const floorPlanListClasses = classNames({
    'cvc-floorplan-list': true,
    'cvc-product-list': true,
    'cvc-location-section': !isSearchPage,
    'cvc-bc-floorplans': !isSearchPage,
  });

  if (!isSearchPage && totalFloorPlanCount === 0) {
    return <></>;
  }

  const displayButton = (searchPage: boolean) => {
    if (!searchPage && bcDocument) {
      return (
        <ResolverLink
          className="cvc-product-view-all-button"
          href={`/building-center${bcDocument.buildingCenterPath}/floorplans`}
        >
          <Button>View All {totalFloorPlanCount} Available Floor Plans</Button>
        </ResolverLink>
      );
    }
  };

  if (primed) {
    return <></>;
  }

  return (
    <div className={floorPlanListClasses}>
      {!isSearchPage && (
        <h2 className="cvc-heading-featured">
          <span className="cvc-heading-featured-inner">Floor Plans For Order</span>
        </h2>
      )}
      {(isMobile || isTablet) && (
        <>
          {breadcrumb && displayBreadcrumb(breadcrumb, isSearchPage)}
          <FloorPlanSearch
            {...{
              locationNameSearched,
              totalFloorPlanCount,
              apiKey,
              internalLoc,
              searchButtonText,
              isBuildingCenterOnly,
              searchedNameDisplay,
              isSearchPage,
              bcDocument,
            }}
            handleSearch={(search: any) => handleSearchChange(search)}
          />
        </>
      )}
      <div className="cvc-floorplan-list-locations-container cvc-product-list-container">
        {(isMobile || isTablet) && isSearchPage && displayMobileLinks()}
        <>
          {(isSearchPage || isMobile || isTablet) && (
            <div className={floorPlansSortContainerClasses}>
              {isMobile || isTablet ? (
                <>
                  {isSearchPage && !!facetState?.facets && (
                    <MyFiltersBar
                      currentAddress={queryStringItems.general?.name}
                      facets={facetState?.facets}
                      selected={initialSelected.current}
                      filterChange={(filters: any) => handleSelectedFacetChange(filters)}
                      hasSearchZip={hasSearch}
                      handleSearch={(search: any) => handleSearchChange(search)}
                      handleSetFacets={() => setResults()}
                      configData={configData}
                      isMobile={isMobile || isTablet}
                    />
                  )}
                  {!elasticSearchLoading && noDocuments && totalFloorPlanCount === 0 ? (
                    <>
                      <NoFloorPlansMessage
                        {...{
                          title: noFloorplansTitle,
                          contentVal: noFloorplansContent,
                          maxRadiusString: maxRadiusStringModelVal,
                        }}
                      />
                      {totalFloorPlanCount === 0 && displayTileGrids()}
                    </>
                  ) : (
                    displayFloorPlanCards(
                      props,
                      floorplanDocuments,
                      windowDimensions,
                      isSearchPage,
                      bcDocument
                    )
                  )}
                  {isSearchPage &&
                    DynamicFiltersPanel(
                      true,
                      facetState,
                      initialSelected.current,
                      windowDimensions,
                      allPageSort,
                      handleSort,
                      totalFloorPlanCount,
                      handleSelectedFacetChange,
                      selectedSortType,
                      accordionFiltersMobileOpen
                    )}
                </>
              ) : (
                <>
                  {isSearchPage &&
                    DynamicFiltersPanel(
                      false,
                      facetState,
                      initialSelected.current,
                      windowDimensions,
                      allPageSort,
                      handleSort,
                      totalFloorPlanCount,
                      handleSelectedFacetChange,
                      selectedSortType,
                      accordionFiltersMobileOpen
                    )}
                  {isSearchPage && !!facetState?.facets && <></>}
                </>
              )}
            </div>
          )}
          <div className={floorPlansFiltersContainerClasses}>
            {!isMobile && !isTablet && (
              <>
                <>
                  {breadcrumb && isSearchPage && displayBreadcrumb(breadcrumb, isSearchPage)}
                  <FloorPlanSearch
                    {...{
                      locationNameSearched,
                      totalFloorPlanCount,
                      apiKey,
                      internalLoc,
                      searchButtonText,
                      isBuildingCenterOnly,
                      searchedNameDisplay,
                      isSearchPage,
                      bcDocument,
                    }}
                    handleSearch={(search: any) => handleSearchChange(search)}
                  />
                </>
                {isSearchPage && !!facetState?.facets && (
                  <MyFiltersBar
                    currentAddress={queryStringItems.general?.name}
                    facets={facetState?.facets}
                    selected={initialSelected.current}
                    filterChange={(filters: any) => handleSelectedFacetChange(filters)}
                    hasSearchZip={hasSearch}
                    handleSearch={(search: any) => handleSearchChange(search)}
                    handleSetFacets={() => setResults()}
                    configData={configData}
                    isMobile={isMobile || isTablet}
                  />
                )}
                {!elasticSearchLoading && noDocuments && totalFloorPlanCount === 0 ? (
                  <>
                    <NoFloorPlansMessage
                      {...{
                        title: noFloorplansTitle,
                        contentVal: noFloorplansContent,
                        maxRadiusString: maxRadiusStringModelVal,
                      }}
                    />
                  </>
                ) : (
                  displayFloorPlanCards(
                    props,
                    floorplanDocuments,
                    windowDimensions,
                    isSearchPage,
                    bcDocument
                  )
                )}
                {totalFloorPlanCount === 0 && displayTileGrids()}
              </>
            )}
          </div>
        </>
      </div>
      {/* {!isSearchPage && (
        <div className={footerButtonClasses}>
            <ResolverLink className="cvc-product-view-all-button" href="./floorplans">
            <Button>View All Floor Plans</Button>
          </ResolverLink>
        </div>
      )} */}
      {displayButton(isSearchPage)}
      <div>
        {!elasticSearchLoading && facetState?.results?.length > 0 && (
          <FloorPlanPagination
            config={allPageSort.page}
            totalPages={currentMeta?.pages || 0}
            currentPage={currentMeta?.current || 0}
            rowsPerPage={paginationState.current.size}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      <GlobalScrollToTopButton />
    </div>
  );
}

export function FloorPlanListES(props: BrProps) {
  const { elasticSearchConfig, componentParameterMap } = props.component.getModels();

  const { brandName: brandNameList, separateParkModels } = componentParameterMap;
  const brandName = brandNameList ? brandNameList.split(',') : '';

  const useParkModelFilter = separateParkModels && separateParkModels === 'true';

  const { page } = props;
  const bcDocument = getBuildingCenterFromHeader(page);
  const plantLocationUid = bcDocument ? bcDocument.plantLocationUid : null;

  const isBuildingCenterOnly =
    window.location.pathname.includes(PAGE_URL.BUILDING_CENTER) &&
    !window.location.pathname.includes('search');

  const isParkModel = window.location.pathname.includes(PAGE_URL.PARK_MODEL_PAGE_ROOT);

  const initialQueryStringValues = getQueryStringValues(useLocation().search);
  const { facets }: any = initialQueryStringValues;
  const hasCenter = !!facets?.latitude;

  const primaryType = 'main';
  const secondaryType = 'floorplan';

  const localization = getLocalizationData(
    useLocation().pathname,
    'searchData',
    isBuildingCenterOnly
  );

  const { isSearchPage } = props.component.getParameters();

  const { countryCode } = localization;

  // we don't want a location filter if no lat/lng info is provided
  const hasLocationFilter: boolean = hasCenter;

  const defaultValueOverrides: any = {
    locations: hasLocationFilter ? [250] : allFacets.locations.ui?.defaultValue,
  };

  const facetConfigInstance: any = Object.entries(allFacets).reduce((memo: any, [k, v]) => {
    const value = {
      ...v,
      ui: {
        ...v.ui,
        suffix: v.ui?.suffix === 'mi' ? localization.unit : v.ui?.suffix,
        defaultValue: defaultValueOverrides[k] || v.ui?.defaultValue,
      },
    };
    return {
      ...memo,
      [k]: value,
    };
  }, {});

  const uiBaseFacetConfig = getFacets(
    primaryType,
    secondaryType,
    'ui',
    facetConfigInstance,
    !hasLocationFilter
  );

  // basic elastic search facet data based on the facets requested by this facet bar...
  // is sent with the API request to ES
  // tells ES what facets we need returned in the response
  // the last param is an override to hide the location filter. static config will display it for non-retailer searches
  // in the event that no lat/long info is available, however, we need to remove it.
  const esBaseFacetConfig = getFacets(
    primaryType,
    secondaryType,
    'elasticSearch',
    facetConfigInstance,
    !hasLocationFilter
  );

  const coordinateFacets = {
    lat: facets?.latitude,
    lng: facets?.longitude,
  };

  const locationFacet = hasLocationFilter
    ? buildLocationFacet({
        ...coordinateFacets,
        base: esBaseFacetConfig?.locations[0],
        unit: localization.unit,
      })
    : {};

  // the last param is an override to hide the location filter. static config will display it for non-retailer searches
  // in the event that no lat/long info is available, however, we need to remove it.
  const allEsFiltersArray = buildBaseFiltersArray({
    ...coordinateFacets,
    type: 'floorplan',
    subtype: null,
    unit: localization.unit,
    id: null,
    brandName,
    plantLocationUid,
    countryCode,
    useParkModelFilter: useParkModelFilter && isParkModel,
    facetConfig: facetConfigInstance,
  });

  const noneEsFiltersArray = [];
  if (useParkModelFilter && !isParkModel) {
    noneEsFiltersArray.push(parkModelFilter);
  }

  const esInitialBaseFacets: any = buildESRequest({
    facets: {
      ...esBaseFacetConfig,
      ...locationFacet,
    },
    page: { size: 5, current: 1 },
    sort: allPageSort.sort.defaultValue,
    allFilters: allEsFiltersArray,
    noneFilters: noneEsFiltersArray,
  });

  // elastic search object for the faceted search...
  const elasticSearch = useFetch(elasticSearchConfig);

  const configData = {
    uiBaseFacetConfig,
    esBaseFacetConfig,
    initialQueryStringValues,
    listType: 'floorplan',
    listSubType: null,
    facetBarConfig: {
      primaryType,
      secondaryType,
    },
    baseLocationFacet: locationFacet,
    facetConfigInstance,
  };

  // elastic search object for the initial request for all facets. This has to be separate from the main search
  const { elasticSearchResponse, setElasticSearchRequest, elasticSearchLoading } =
    useFetch(elasticSearchConfig);

  // fetch all the initial facets and pass to the facet bar
  const allFilters = useMemo(() => {
    return elasticSearchResponse ? allFacetsMapper(elasticSearchResponse.rawInfo.facets) : {};
  }, [elasticSearchResponse]);

  useEffect(() => {
    setElasticSearchRequest(esInitialBaseFacets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compModels = props.component.getModels();

  const spinnerColorInLightBackground =
    compModels &&
    compModels.spinnerConfig &&
    compModels.spinnerConfig.spinner_color_light_background;

  const filterConfig = filterConfiguration[primaryType][secondaryType];

  if (isSearchPage && !SiteGlobalSwitches.useElasticSearchFloorplans) {
    return <></>;
  }

  return !elasticSearchLoading && Object.keys(allFilters).length ? (
    <FloorPlanESResults
      props={props}
      localization={localization}
      configData={configData}
      uiFacetConfig={uiBaseFacetConfig}
      esFacetConfig={esBaseFacetConfig}
      allFilters={allFilters}
      elasticSearch={elasticSearch}
      filterConfig={filterConfig}
      hasLocationFilter={hasLocationFilter}
      initialQueryStringValues={initialQueryStringValues}
      brandName={brandName}
      bcDocument={bcDocument}
      isBuildingCenterOnly={isBuildingCenterOnly}
      isParkModel={isParkModel}
      useParkModelFilter={useParkModelFilter}
      isSearchPage={isSearchPage}
    />
  ) : (
    <div className="cavcohomes-floorplan-list__spinner">
      <Spinner color={spinnerColorInLightBackground} />
    </div>
  );
}
