import React from 'react';

export function ArrowDrowDown() {
  return (
    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63681 7.744L15.0699 1.51467C15.4212 1.152 15.4212 0.597333 15.0699 0.256C14.7186 -0.0853333 14.1258 -0.0853333 13.7745 0.256L8.00008 5.86667L2.22563 0.256C1.85238 -0.0853333 1.28152 -0.0853333 0.930221 0.256C0.578924 0.597333 0.578924 1.152 0.930221 1.51467L7.3414 7.744C7.71465 8.08533 8.28551 8.08533 8.63681 7.744Z"
        fill="#33687E"
      />
    </svg>
  );
}
