import { SEARCH_TYPES } from 'components/enums';

// KEY: the localstorage item
// NAME: the label sent from Bloomreach.
// If the name is changed in the CMS, it will need to be updated here to match
export const HEADER_LINKS: any = {
  [SEARCH_TYPES.FLOORPLAN]: {
    KEY: SEARCH_TYPES.FLOORPLAN,
    NAME: 'Find Floor Plans',
  },
  [SEARCH_TYPES.RETAILER]: {
    KEY: SEARCH_TYPES.RETAILER,
    NAME: 'Find Retailers',
  },
  [SEARCH_TYPES.DEALERS]: {
    KEY: SEARCH_TYPES.RETAILER,
    NAME: 'Find Dealers',
  },
  [SEARCH_TYPES.MIR]: {
    KEY: SEARCH_TYPES.MIR,
    NAME: 'Find Move-In Ready Homes',
  },
  [SEARCH_TYPES.COMMUNITY]: {
    KEY: SEARCH_TYPES.COMMUNITY,
    NAME: 'Find Communities',
  },
};

// The localstorage key
export const FLOORPLAN_LOCALSTORAGE_KEY = 'floorPlanSearch';
