import React, { useState } from 'react';
import './sliderFacet.scss';
import { StyledSlider } from '../../FloorPlanResultsStyledComponents';

const sliderConfig = {
  handle: 34,
  dot: 20,
  track: 20,
};

export const SliderFacet = ({
  facet,
  selectedFacets,
  handleSelectedFacet,
  configData,
  disabled,
}: any) => {
  const { key, label, prefix, suffix, options } = facet;
  const defaultValue = configData?.facetConfigInstance[key].ui?.defaultValue || [];
  const [values, setValues] = useState<number[]>(selectedFacets[key] || defaultValue);
  const handleChangeSlider = (data: any) => {
    setValues(data);
  };
  const handleSetValues = (data: any) => {
    handleSelectedFacet(key, data);
  };

  const Labels = ({ labels, type }: any) => {
    return type === 'square_foot' ? (
      <span className="slider-facet__label">
        {`${prefix || ''}${labels[0]} to ${prefix || ''}${labels[1]} ${suffix || ''}`}
      </span>
    ) : (
      labels.map((item: number, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item}-${index}`}>
          {`${prefix || ''}${item.toLocaleString('en-US')}`} <span>{suffix || ''}</span>
        </div>
      ))
    );
  };

  const marks =
    key === 'square_foot'
      ? [
          { label: 500, value: 500 },
          { label: 1000, value: 1000 },
          { label: 1500, value: 1500 },
          { label: 2000, value: 2000 },
          { label: 2500, value: 2500 },
          { label: 3000, value: 3000 },
        ]
      : false;

  return (
    <section className="slider-facet">
      <div className="slider-facet__values">{values && <Labels labels={values} type={key} />}</div>
      <StyledSlider
        styleprops={sliderConfig}
        key="facet-slider"
        value={values}
        disabled={disabled}
        className="cavcohomes-floorplan-list__filters--slider"
        getAriaLabel={(index: number) => (index === 0 ? `Minimum ${label}` : `Maximum ${label}`)}
        onChange={(e: any, newSqFt: any) => handleChangeSlider(newSqFt)}
        onChangeCommitted={(e: any, newSqFt: any) => handleSetValues(newSqFt)}
        valueLabelDisplay="off"
        min={options[0].from}
        max={options[0].to}
        marks={marks}
      />
    </section>
  );
};
