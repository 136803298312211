import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BrManageContentButton } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import './PanelAds.scss';
import { getCurrentSiteName } from 'components/utils';
import { SITE_NAMES } from 'components/enums';

export const CIRCLE_NO_LINK_VARIANT = 'circleNoLink';
export const CIRCLE_TEXT_LINK_VARIANT = 'circleTextLink';
export const CIRCLE_BUTTON_LINK_VARIANT = 'circleButtonLink';
export const SQUARE_VARIANT = 'square';
export const SQUARE_TEXT_BUTTON_VARIANT = 'squareTextButton';
export type PanelAdsVariant =
  | typeof CIRCLE_NO_LINK_VARIANT
  | typeof CIRCLE_TEXT_LINK_VARIANT
  | typeof CIRCLE_BUTTON_LINK_VARIANT
  | typeof SQUARE_VARIANT
  | typeof SQUARE_TEXT_BUTTON_VARIANT;
export type PanelAdsPanel = {
  name: string;
  displayName: string;
  content: string;
  link: any;
  image: any;
  title: string;
};

export interface PanelAdsProps {
  variant: PanelAdsVariant;
  panels: PanelAdsPanel[];
  groupTitle?: string;
}

const isExternalUrl = (url: string) => {
  const externalUrlRegex = new RegExp(/^https?:\/\//);
  return externalUrlRegex.test(url);
};

const isClickable = (variant: string) => variant === SQUARE_TEXT_BUTTON_VARIANT;

/**
 * Wrap component within a Router Link
 */
const withLink =
  (Component: FunctionComponent<any & { link: any; tabIndex: number }>) => (linkProps: any) => {
    const { link, tabIndex, ...componentProps } = linkProps;
    const { destinationUrl, linkTitle, newWindow } = link;
    isExternalUrl(destinationUrl);

    return isExternalUrl(destinationUrl) ? (
      <a
        href={destinationUrl}
        title={linkTitle}
        target={newWindow ? '_blank' : undefined}
        rel={newWindow ? 'noreferrer' : undefined}
        tabIndex={tabIndex}
        className="cvc-panel-ad__panel-title-link"
      >
        <Component {...componentProps} />
      </a>
    ) : (
      <Link
        to={destinationUrl}
        title={linkTitle}
        target={newWindow ? '_blank' : undefined}
        tabIndex={tabIndex}
        className="cvc-panel-ad__panel-title-link"
      >
        <Component {...componentProps} />
      </Link>
    );
  };

const PanelAds: FunctionComponent<PropsWithChildren<PanelAdsProps & { isPreview: boolean }>> = (
  props
) => {
  const { children, variant, panels, isPreview, groupTitle } = props;
  const shouldDisplayLink = variant !== CIRCLE_NO_LINK_VARIANT;
  const isSquare = variant === SQUARE_VARIANT || variant === SQUARE_TEXT_BUTTON_VARIANT;
  const isCircle = !isSquare;
  const currentSite = getCurrentSiteName();
  const isCavcoDotCom = currentSite === SITE_NAMES.CAVCO_DOT_COM;
  /**
   * Panel Image
   */
  const PanelImage: FunctionComponent<any> = (imageProps) => {
    const { image, link } = imageProps;
    const { alt, imageRef } = image;
    const shouldDisplayImageWithLink = shouldDisplayLink && link.destinationUrl && isCircle;

    if (!imageRef) {
      return null;
    }

    const Picture = (pictureProps: any) => {
      const { medium, large, altText, displayName } = pictureProps;
      const mediumSrc =
        typeof medium.getLink === 'function' ? medium.getLink().href : medium.links.site.href;
      const largeSrc =
        typeof large.getLink === 'function' ? large.getLink().href : large.links.site.href;
      return (
        <div className="cvc-panel-ad__panel-image">
          <picture>
            <source media="(min-width: 768px)" srcSet={mediumSrc} />
            <source media="(min-width: 1920px)" srcSet={largeSrc} />
            <img src={mediumSrc} alt={altText || displayName} />
          </picture>
        </div>
      );
    };

    const Image = shouldDisplayImageWithLink ? withLink(Picture) : Picture;

    return (
      <div className="cvc-panel-ad__panel-image-wrapper">
        <Image {...imageRef} link={link} altText={alt} tabIndex={-1} />
      </div>
    );
  };

  /**
   * Panel Title
   */
  const PanelTitle: FunctionComponent<any> = (titleProps) => {
    const { title } = titleProps;

    if (!title) {
      return null;
    }

    return <h2 className="cvc-panel-ad__panel-title">{title}</h2>;
  };

  /**
   * Panel Description
   */
  const PanelDescription: FunctionComponent<any> = (contentProps) => {
    const { content } = contentProps;

    if (!content) {
      return null;
    }

    return (
      <div
        className="cvc-panel-ad__panel-description"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  };

  /**
   * Panel Link
   */
  const PanelLink: FunctionComponent<any> = (linkProps) => {
    const {
      link: { destinationUrl, displayText, linkTitle, newWindow },
      variant: linkVariant,
    } = linkProps;

    const isExternal = isExternalUrl(destinationUrl) ? Link : undefined;

    if (linkVariant === SQUARE_TEXT_BUTTON_VARIANT) {
      return (
        <Button
          className="cvc-panel-ad__panel-link"
          variant="primary"
          title={`${linkTitle} button`}
        >
          {displayText}
        </Button>
      );
    }

    if (!destinationUrl) {
      return null;
    }

    return (
      <Button
        className="cvc-panel-ad__panel-link"
        variant={linkVariant}
        as={!isExternal ? Link : undefined}
        to={!isExternal ? destinationUrl : undefined}
        href={isExternal ? destinationUrl : undefined}
        title={`${linkTitle} button`}
        target={newWindow ? '_blank' : undefined}
      >
        {displayText}
      </Button>
    );
  };

  /**
   * Panel Content
   */
  const PanelContent: FunctionComponent<any> = (contentProps) => {
    const { title, link, content } = contentProps;
    let linkVariant;
    if (variant === CIRCLE_BUTTON_LINK_VARIANT) {
      linkVariant = 'primary';
    } else if (variant === SQUARE_TEXT_BUTTON_VARIANT) {
      linkVariant = 'primary';
    } else if (variant === SQUARE_VARIANT) {
      linkVariant = 'primary';
    } else {
      linkVariant = 'link';
    }
    const shouldDisplayTitleWithLink = shouldDisplayLink && link.destinationUrl && isCircle;
    const Title = shouldDisplayTitleWithLink ? withLink(PanelTitle) : PanelTitle;

    return (
      <div className="cvc-panel-ad__panel-content">
        <Title title={title} link={shouldDisplayTitleWithLink && link} tabIndex={-1} />
        <PanelDescription content={content} />
        {shouldDisplayLink && <PanelLink link={link} variant={linkVariant} />}
      </div>
    );
  };

  /**
   * Panel
   */
  const Panel: FunctionComponent<any> = (panelProps) => {
    const { documentRef, image, ...content } = panelProps;
    const panelClass = classNames(
      'cvc-panel-ad__panel',
      isPreview && `has-edit-button`,
      isCavcoDotCom && 'px-lg-2'
    );

    if (isClickable(variant)) {
      const { destinationUrl, linkTitle, newWindow } = content.link || {};

      return (
        <ResolverLink
          className={panelClass}
          href={content.link?.documentLink || destinationUrl}
          title={linkTitle}
          target={newWindow ? '_blank' : undefined}
        >
          {documentRef && <BrManageContentButton content={documentRef} />}
          <PanelImage image={image} link={content.link} />
          <PanelContent {...content} />
        </ResolverLink>
      );
    }

    return (
      <div className={panelClass}>
        {documentRef && <BrManageContentButton content={documentRef} />}
        <PanelImage image={image} link={content.link} />
        <PanelContent {...content} />
      </div>
    );
  };

  const colClass = classNames(
    isCircle && !isCavcoDotCom && 'px-2',
    isCircle && !isCavcoDotCom && 'px-sm-3',
    isCavcoDotCom && isCircle && 'px-2 px-xl-5'
  );
  const keys = ['one', 'two', 'three'];

  const getPanels = () => {
    // only cavhomes gets 2-col in tablet
    const isCavHomes = document.documentElement.classList.contains('cavcohomes');
    return panels.map((panel, index) => (
      <Col
        xs={isSquare ? 12 : 4}
        md={isCavHomes ? 6 : 4}
        lg={4}
        className={colClass}
        key={`${panel.title}_${keys[index]}`}
      >
        <Panel {...panel} />
      </Col>
    ));
  };

  let variantSufix = 'circle';
  if (isSquare) {
    variantSufix = variant === SQUARE_TEXT_BUTTON_VARIANT ? 'square-text-button' : 'square';
  }

  const rootClass = classNames('cvc-panel-ad', `cvc-panel-ad--${variantSufix}`);

  return (
    <div className={rootClass}>
      {children}
      {groupTitle && <header className="cvc-panel-ad--group-title">{groupTitle}</header>}
      <Container>
        <Row>{panels && getPanels()}</Row>
      </Container>
    </div>
  );
};

export default PanelAds;
