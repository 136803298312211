import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

import { MenuItem } from '@material-ui/core';
import { buildArrayFromNumber } from '../../services/facets.service';
import './floorplanPagination.scss';
import { StyledSelect } from '../../FloorPlanResultsStyledComponents';

export const FloorPlanPagination = ({
  config,
  totalPages,
  currentPage,
  changePage,
  changeRowsPerPage,
  rowsPerPage,
}: {
  config: any;
  totalPages: number;
  currentPage: number;
  changePage: any;
  changeRowsPerPage: any;
  rowsPerPage: number;
}) => {
  const [allPages, setAllPages] = useState<any>(buildArrayFromNumber(totalPages));
  const [page, setPage] = useState<any>(currentPage);
  const [rows] = useState<number>(rowsPerPage || config.values[0]);
  // removing showFullPagination as an option but keeping for validation in case of updates to desgin
  const [showFullPagination, setShowFullPagination] = useState<boolean>(false);
  const [showPrevPagination, setShowPrevPagination] = useState<boolean>(false);
  const [showNextPagination, setShowNextPagination] = useState<boolean>(false);

  const handleChangeRowsPerPage = (e: any) => {
    changeRowsPerPage(e);
  };

  const handleChangePage = (e: any, p: number) => {
    setShowFullPagination(!showFullPagination && p > 5);
    changePage(e, p);
  };

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setAllPages(buildArrayFromNumber(totalPages));
  }, [totalPages]);

  useEffect(() => {
    setShowFullPagination(totalPages < 6);
  }, [totalPages]);

  const EllipsisLast = ({ showLast }: { showLast: boolean }) => {
    const showPagination = () =>
      showLast === true
        ? setShowNextPagination(!showNextPagination)
        : setShowPrevPagination(!showPrevPagination);
    return (
      <>
        <Pagination.Ellipsis onClick={() => showPagination()} />
        {showLast === true || (showLast === false && showFullPagination === true) ? (
          <Pagination.Item
            active={page === allPages.length}
            key={`page-${allPages.length}`}
            onClick={(e: any) => handleChangePage(e, allPages.length)}
          >
            {allPages.length}
          </Pagination.Item>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderPagination = (idx: number) => {
    const item = idx + 1;
    if (
      (showPrevPagination === true && item <= page - 1) ||
      (showNextPagination === true && item >= page + 5)
    ) {
      return (
        <Pagination.Item
          active={page === item}
          key={`page-${item + 1}`}
          onClick={(e: any) => handleChangePage(e, item)}
        >
          {item}
        </Pagination.Item>
      );
    }
  };

  return (
    <div className="pagination-bar cavcohomes-floorplan-pagination">
      <section className="pagination-bar__rows-per-page">
        <div id="pagination-bar-label" className="pagination-bar__label">
          <span>{config?.label}</span>
        </div>
        <div className="pagination-bar__select">
          <StyledSelect
            variant="outlined"
            defaultValue={rows}
            onChange={(e: any) => handleChangeRowsPerPage(e.target?.value)}
            labelId="pagination-bar-label"
            label="pagination-bar-label"
          >
            {config?.values?.map((value: number) => {
              return (
                <MenuItem
                  key={`${config.label}-${value}`}
                  value={value}
                  aria-labelledby="pagination-bar-label"
                >
                  {value}
                </MenuItem>
              );
            })}
          </StyledSelect>
        </div>
      </section>
      <div className="pagination-bar__navigation">
        {allPages.length && (
          <Pagination>
            <Pagination.Prev
              className="pagination-prev"
              disabled={page === 1}
              onClick={(e: any) => handleChangePage(e, page - 1)}
            />
            <>
              {showPrevPagination && (
                <section className="pagination--show-more">
                  {allPages.map(renderPagination)}
                </section>
              )}
              <Pagination.Item
                active={page === 1}
                key="page-1"
                onClick={(e: any) => handleChangePage(e, 1)}
              >
                1
              </Pagination.Item>
              {!showFullPagination && page > 2 && <EllipsisLast showLast={false} />}
              {allPages.map((pageItem: any) => {
                const len = showFullPagination ? allPages.length + 5 : 5;
                return (
                  pageItem > 1 &&
                  pageItem <= page + (len - 1) &&
                  pageItem >= page && (
                    <Pagination.Item
                      active={page === pageItem}
                      key={`page-${pageItem + 1}`}
                      onClick={(e: any) => handleChangePage(e, pageItem)}
                    >
                      {pageItem}
                    </Pagination.Item>
                  )
                );
              })}
              {showNextPagination && (
                <section className="pagination--show-more">
                  {allPages.map(renderPagination)}
                </section>
              )}
              {!showFullPagination && page <= allPages.length - 1 && <EllipsisLast showLast />}
            </>
            <Pagination.Next
              className="pagination-next"
              disabled={page === totalPages}
              onClick={(e: any) => handleChangePage(e, page + 1)}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};
