import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { BrProps } from '@bloomreach/react-sdk';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { ReactComponent as UsFlagIcon } from '../../assets/images/us-icon.svg';
import { ReactComponent as CanadaFlagIcon } from '../../assets/images/canada-icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import './TileGrid.scss';

function CountryIcon(countryPicker: any) {
  let country;

  if (countryPicker === 'US') {
    country = (
      <div className="cvc-tile-icon cvc-tile-icon-us">
        <UsFlagIcon />
      </div>
    );
  } else if (countryPicker === 'Canada') {
    country = (
      <div className="cvc-tile-icon cvc-tile-icon-ca">
        <CanadaFlagIcon />
      </div>
    );
  }

  return country;
}

function Tile(
  cellTitle: string,
  cellTitlePark: string,
  cellTitleMobile: string,
  countryPicker: any,
  linkRoot: string,
  linkRootPark: string,
  regionDetails: any[],
  windowDimensions: any,
  isParkModel = false,
  toAppendRegion = true,
  showCountry: string,
  hasCommunitySeries = false
) {
  const finalDesktopCellTitle = isParkModel && cellTitlePark !== '' ? cellTitlePark : cellTitle;

  const finalLinkRoot = isParkModel ? linkRootPark : linkRoot;
  return regionDetails.map((region, regionIndex) => {
    const { id, name: bcname, regionName: name, regionAbbreviation: abbreviation } = region;
    const country = countryPicker === 'US' ? 'USA' : 'CAN';
    const nameParams = hasCommunitySeries ? '' : `?name=${name}%2C+${country}`;
    let tileLink;
    if (hasCommunitySeries && bcname) {
      tileLink = bcname.toLowerCase();
    } else {
      tileLink = toAppendRegion
        ? (
            finalLinkRoot +
            ((finalLinkRoot as string).endsWith('/') ? '' : '/') +
            abbreviation
          ).toLowerCase()
        : finalLinkRoot.toLowerCase();
    }

    const isMobileOrTablet = windowDimensions.isMobile || windowDimensions.isTablet;

    const adaptedLink = window.location.pathname.startsWith('/site')
      ? tileLink
      : tileLink.replace('/site', '');

    return (
      <Col
        key={id || `region-${regionIndex.toString()}`}
        className="cvc-tile cvc-tile-wrapper d-flex"
        xs={6}
        md={4}
      >
        <a className="cvc-tile-link" href={adaptedLink + nameParams}>
          <div className="cvc-tile-content d-flex align-items-center">
            {showCountry === 'false' ? (
              <div className="cvc-tile-icon">
                <LocationIcon className="cvc-tile-icon-pin" />
              </div>
            ) : (
              CountryIcon(countryPicker)
            )}
            <div className="cvc-tile-text">
              {cellTitle && !isMobileOrTablet ? (
                <p>{finalDesktopCellTitle}</p>
              ) : (
                <p>{cellTitleMobile}</p>
              )}
              {name && <h3>{name}</h3>}
            </div>
          </div>
        </a>
      </Col>
    );
  });
}

function DisplayTileGrid(
  documentObj: any,
  title: any,
  showTitle: any,
  panelClass: any,
  valueListLabels: any = undefined,
  showCountry: any
) {
  const {
    countryPicker,
    gridDescription: desc,
    regionDetails,
    cellTitle = '',
    cellTitlePark = '',
    cellTitleMobile = '',
    linkRoot,
    linkRootPark,
    toAppendRegion = false,
    hasCommunitySeries = false,
  } = documentObj;

  const windowDimensions = useWindowDimensions();
  const isParkModel = useLocation().pathname.includes('park');

  const standardCellTitle = cellTitle || 'Fairmont Homes Locations In';
  const parkCellTitle = cellTitlePark || standardCellTitle;
  const mobileCellTitle = cellTitleMobile || (isParkModel ? parkCellTitle : standardCellTitle);

  const standardLinkRoot = linkRoot || `/our-retailers/${countryPicker}`;
  const parkLinkRoot = linkRootPark || `/our-park-retailers/${countryPicker}`;

  return (
    <Container className={`${panelClass}${valueListLabels ? ' building-center' : ''}`}>
      {
        // documentRef && <BrManageContentButton content={document} />
      }
      <Row>
        {title && (
          <Col className="cvc-tile-grid-title mb-3" xs={12}>
            <h2>{title}</h2>
          </Col>
        )}
        {valueListLabels && valueListLabels.displayName && showTitle !== 'false' && (
          <Col className="cvc-tile-grid-description mb-3" xs={12}>
            <h2 className="cvc-heading-featured">
              <span className="cvc-heading-featured-inner">{valueListLabels.displayName}</span>
            </h2>
          </Col>
        )}
        {desc && (
          <Col
            className={`cvc-tile-grid-description mb-3 ${valueListLabels ? 'bc-desc' : ''}`}
            xs={12}
          >
            <p>{desc}</p>
          </Col>
        )}

        <div className="cvc-tile-grid__tiles d-flex">
          {Tile(
            standardCellTitle,
            parkCellTitle,
            mobileCellTitle,
            countryPicker,
            standardLinkRoot,
            parkLinkRoot,
            regionDetails,
            windowDimensions,
            isParkModel,
            toAppendRegion,
            showCountry,
            hasCommunitySeries
          )}
        </div>
      </Row>
    </Container>
  );
}

export function TileGrid(props: BrProps) {
  const {
    document: documentRef,
    componentParameterMap,
    buildingCenterTileGridLabels,
    retailerTileGridLabels,
    showCountry,
  } = props.component.getModels();
  let document = documentRef ? props.page.getContent(documentRef) : undefined;
  const panelClass = classNames(
    `cvc-tile-grid mt-2 mb-5${props.page.isPreview() ? ' has-edit-button' : ''}`
  );

  let documentObj: any;
  if (!document) {
    document = documentRef;
    documentObj = document;
  } else {
    documentObj = document.getData<BannerData>();
  }
  if (!documentObj) {
    return null;
  }

  const valueListLabels = buildingCenterTileGridLabels || retailerTileGridLabels;

  const { gridTitleText: title, showTitle } = componentParameterMap;

  return DisplayTileGrid(
    documentObj,
    title,
    showTitle,
    panelClass,
    valueListLabels,
    showCountry || componentParameterMap.showCountry
  );
}

export const NoResultsTileGrid: FunctionComponent<any> = (tileGridProps: any) => {
  const { props, documentRef, gridTitleText } = tileGridProps;
  let document = documentRef && props.page.getContent(documentRef);
  const panelClass = classNames(
    `cvc-tile-grid mt-2 mb-5${props.page.isPreview() ? ' has-edit-button' : ''}`
  );
  let documentObj: any;
  if (!document) {
    document = documentRef;
    documentObj = document;
  } else {
    documentObj = document.getData();
  }
  if (!documentObj) {
    return null;
  }
  return <>{DisplayTileGrid(documentObj, gridTitleText, true, panelClass, null, null)}</>;
};
