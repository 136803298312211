import {
  AllFacetsProps,
  DistanceRangesProps,
  SquareFootRangesProps,
} from './FloorPlanListES.interface';

// FACETS
const bathrooms = 'number_of_bathrooms';
const bedrooms = 'number_of_bedrooms';
const sqft = 'square_foot';
const media = 'media';
const sections = 'sections';
const buildingmethod = 'building_method';
const floorplanavailability = 'floorplan_availability';
const locations = 'locations';
const latitude = 'latitude';
const longitude = 'longitude';
const series = 'series';

// If any resource bundle data is provided for a specific facet, map it here:
// key: the facet key
// value: the resource bundle name
export const resourceBundleMapper = {
  [buildingmethod]: 'buildingMethodDesc',
};

// FACET BAR CONFIG
export const filterConfiguration: any = {
  main: {
    floorplan: {
      facet_bar: [
        locations,
        bedrooms,
        bathrooms,
        sqft,
        sections,
        buildingmethod,
        series,
        media,
        floorplanavailability,
      ],
      primary_count: 5,
      hasSearch: true,
    },
  },
};

export const queryStringFacetItems = [
  latitude,
  longitude,
  locations,
  buildingmethod,
  bathrooms,
  bedrooms,
  sqft,
  floorplanavailability,
  sections,
  media,
  series,
];

export const defaultOptions: any = {
  query: '',
  facets: {
    locations: [],
    building_method: { type: 'value', sort: { value: 'asc' } },
    square_foot: { type: 'range', ranges: [{ from: 500, to: 3000 }] },
    sections: { type: 'value', sort: { value: 'asc' } },
    number_of_bathrooms: { type: 'value', sort: { value: 'asc' } },
    number_of_bedrooms: { type: 'value', sort: { value: 'asc' } },
    floorplan_availability: { type: 'value', sort: { value: 'asc' } },
    media: { type: 'value', sort: { value: 'asc' } },
  },
};

export const distanceRanges: DistanceRangesProps[] = [
  {
    from: 0,
    to: 25,
    name: '25',
  },
  {
    from: 0,
    to: 50,
    name: '50',
  },
  {
    from: 0,
    to: 75,
    name: '75',
  },
  {
    from: 0,
    to: 100,
    name: '100',
  },
  {
    from: 0,
    to: 150,
    name: '150',
  },
  {
    from: 0,
    to: 200,
    name: '200',
  },
  {
    from: 0,
    to: 250,
    name: '250',
  },
];

export const squareFootRanges: SquareFootRangesProps[] = [
  {
    from: 0,
    to: 999,
    name: '0 - 999',
  },
  {
    from: 1000,
    to: 1499,
    name: '1000 - 1499',
  },
  {
    from: 1500,
    to: 1799,
    name: '1500 - 1799',
  },
  {
    from: 1800,
    to: 1999,
    name: '1800 - 1999',
  },
  {
    from: 2000,
    to: 3000,
    name: '2000+',
  },
];

const facetMapperBase = (val: string | number) => (Array.isArray(val) ? val : [val]);
const urlMapperBase = (key: string, val: string | number) => ({
  [key]: Array.isArray(val) ? val : [val],
});

export const pageTypeDefaultSortConfigs: any = {
  main: {
    floorplan: [
      {
        square_foot: 'desc',
      },
      {
        number_of_bedrooms: 'desc',
      },
      {
        name: 'asc',
      },
      {
        model_number: 'asc',
      },
    ],
  },
};

export const pageTypeActiveSortConfigs: any = {
  main: {
    floorplan: {
      square_foot: {
        desc: [
          {
            square_foot: 'desc',
          },
          {
            number_of_bedrooms: 'desc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
        asc: [
          {
            square_foot: 'asc',
          },
          {
            number_of_bedrooms: 'asc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
      },
      number_of_bedrooms: {
        desc: [
          {
            number_of_bedrooms: 'desc',
          },
          {
            square_foot: 'desc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
        asc: [
          {
            number_of_bedrooms: 'asc',
          },
          {
            square_foot: 'asc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
      },
      number_of_bathrooms: {
        desc: [
          {
            number_of_bathrooms: 'desc',
          },
          {
            square_foot: 'desc',
          },
          {
            number_of_bedrooms: 'desc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
        asc: [
          {
            number_of_bathrooms: 'asc',
          },
          {
            square_foot: 'asc',
          },
          {
            number_of_bedrooms: 'asc',
          },
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
      },
      name: {
        desc: [
          {
            name: 'desc',
          },
          {
            model_number: 'asc',
          },
        ],
        asc: [
          {
            name: 'asc',
          },
          {
            model_number: 'asc',
          },
        ],
      },
    },
  },
};

export const allPageSort: any = {
  page: {
    label: 'Show per page',
    values: [12, 24, 36, 48],
    defaultValue: { size: 12, current: 1 },
  },
  sort: {
    label: 'Sort By',
    values: [
      {
        label: 'SqFt Low to High',
        field: 'square_foot',
        order: 'asc',
        name: 'square_foot_asc',
        value: { square_foot: 'asc' },
      },
      {
        label: 'SqFt High to Low',
        field: 'square_foot',
        order: 'desc',
        name: 'square_foot_desc',
        value: { square_foot: 'desc' },
      },
      {
        label: 'Bedrooms Low to High',
        field: 'number_of_bedrooms',
        order: 'asc',
        name: 'number_of_bedrooms_asc',
        value: { number_of_bedrooms: 'asc' },
      },
      {
        label: 'Bedrooms High to Low',
        field: 'number_of_bedrooms',
        order: 'desc',
        name: 'number_of_bedrooms_desc',
        value: { number_of_bedrooms: 'desc' },
      },
      {
        label: 'Bathrooms Low to High',
        field: 'number_of_bathrooms',
        order: 'asc',
        name: 'number_of_bathrooms_asc',
        value: { number_of_bathrooms: 'asc' },
      },
      {
        label: 'Bathrooms High to Low',
        field: 'number_of_bathrooms',
        order: 'desc',
        name: 'number_of_bathrooms_desc',
        value: { number_of_bathrooms: 'desc' },
      },
      {
        label: 'Model Name A-Z',
        field: 'name',
        order: 'asc',
        name: 'name_asc',
        value: { name: 'asc' },
      },
      {
        label: 'Model Name Z-A',
        field: 'name',
        order: 'desc',
        name: 'name_desc',
        value: { name: 'desc' },
      },
    ],
    defaultValue: { square_foot: 'desc' },
  },
};

/** AllFacetsProps
 *
 * ui:
 *  key: string - the key for the property. should match what is returned from ES
 *  label: string - label to be displayed
 *  prefix: string - symbol or text preceding the label,
 *  panel: string - panel title,
 *  type: range | button | checkbox - type of facet
 *  defaultValue: default valye for facet
 *  disableWhenEmpty: boolean - disable the facet if no results contain the property
 *  filterEmpty: boolean - filter out any options where count = 0
 */

export const allFacets: AllFacetsProps = {
  search: {
    mapper: ({ lat, lng, unit }: any) => ({ center: `${lat},${lng}`, unit }),
    urlMapper: (key: string, val: any) => {
      const lat_lng = val.center.split(',');
      return {
        latitude: Number(lat_lng[0]),
        longitude: Number(lat_lng[1]),
      };
    },
  },
  series: {
    ui: {
      key: 'series',
      label: 'Series',
      panel: 'Series',
      type: 'checkbox',
      defaultValue: null,
      filterEmpty: false,
    },
    elasticSearch: { type: 'value', size: 250, sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  locations: {
    ui: {
      key: 'locations',
      label: 'Distance Within',
      prefix: '<',
      suffix: 'mi',
      panel: 'Distance Within',
      type: 'radio',
      defaultValue: [250],
    },
    elasticSearch: [
      {
        center: null,
        ranges: distanceRanges,
        type: 'range',
        unit: null,
      },
    ],
    mapper: (val: number[]) => ({ from: 0, to: Math.max(...val) }),
    urlMapper: (key: string, val: any) => ({ [key]: [val.to] }),
  },
  building_method: {
    ui: {
      key: 'building_method',
      label: 'Building Method',
      panel: 'Building Method',
      type: 'checkbox',
      defaultValue: null,
    },
    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  number_of_bedrooms: {
    ui: {
      key: 'number_of_bedrooms',
      label: 'Bedrooms',
      suffix: 'bd',
      panel: 'Bedrooms',
      type: 'checkbox',
      defaultValue: null,
    },
    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  number_of_bathrooms: {
    ui: {
      key: 'number_of_bathrooms',
      label: 'Baths',
      suffix: 'ba',
      panel: 'Baths',
      type: 'checkbox',
      defaultValue: null,
    },

    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  square_foot: {
    ui: {
      key: 'square_foot',
      label: 'SqFt',
      suffix: 'sqft',
      panel: 'Floor Plan Size',
      type: 'checkbox',
      defaultValue: [0, 3000],
    },

    elasticSearch: { type: 'range', ranges: squareFootRanges },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  sections: {
    ui: {
      key: 'sections',
      label: 'Sections',
      panel: 'Sections',
      type: 'checkbox',
      defaultValue: null,
    },

    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  floorplan_availability: {
    ui: {
      key: 'floorplan_availability',
      label: 'Availability',
      panel: 'Availability',
      type: 'checkbox',
      defaultValue: null,
    },

    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
  media: {
    ui: {
      key: 'media',
      label: 'Media',
      panel: 'Media',
      type: 'checkbox',
    },
    elasticSearch: { type: 'value', sort: { value: 'asc' } },
    mapper: facetMapperBase,
    urlMapper: urlMapperBase,
  },
};

export const parkModelFilter = { building_method: 'Park Model' };
