import React from 'react';
import classNames from 'classnames';

export const DisplayHomeSearch = ({ numLocationsAvailable }: any) => {
  const locationsAvailableText = `${numLocationsAvailable} Display Homes Available `;
  return (
    <div
      className={classNames(
        'cvc-display-home-list-search-filters-container',
        'cvc-product-list-search-filters-container'
      )}
    >
      <h1 className="search-filter-locations-text-container">
        <span className="search-filter-locations-availables">{locationsAvailableText}</span>
      </h1>
    </div>
  );
};
