import React, { useRef } from 'react';
import { Button, Checkbox, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-blue-down.svg';
import './CavcoMultiSelectField.scss';

interface CavcoMultiSelectProps {
  label: string;
  isOpen: boolean;
  data?: any[];
  totalResults?: number;
  handleSelectChange: any;
  values: any[];
  selectLabels?: any;
}

export function CavcoMultiSelectField(props: CavcoMultiSelectProps) {
  const myRef = useRef();

  const handleSelectChanges = (event: any) => {
    if (props.handleSelectChange) {
      props.handleSelectChange(event);
    }
  };

  return (
    <div className="selectContainer">
      <InputLabel id="filter-label">All</InputLabel>
      <Select
        ref={myRef}
        open={props.isOpen}
        className="cavcohomes-multiselect"
        IconComponent={(prop: any) => (
          <CaretDownIcon {...prop} className="MuiSvgIcon-root MuiSelect-icon select-arrow" />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 82,
            horizontal: 0,
          },
          transformOrigin: {
            vertical: 0,
            horizontal: 0,
          },
          getContentAnchorEl: null,
          variant: 'menu',
          autoFocus: false,
          disableAutoFocusItem: true,
          className: `cvh-sitesearch-list`,
        }}
        labelId="All"
        key="search-document-type"
        multiple
        name="search-document-type"
        variant="filled"
        value={props.values}
        onChange={handleSelectChanges}
        renderValue={() => ``}
      >
        <h3 className="mobileFilterLabel">Filter</h3>
        {props.data?.map((f: any, index: number) => {
          const key = `item-${index}`;
          return (
            <MenuItem className="menuitem" disableRipple key={key} value={f.value}>
              <Checkbox
                key={`cb-${f.value}`}
                value={f.value}
                checked={props.values.indexOf(f.value) > -1}
                name={f.value}
              />
              <ListItemText
                primary={
                  props.selectLabels && props.selectLabels[f.value]
                    ? props.selectLabels[f.value]
                    : f.value
                }
              />
            </MenuItem>
          );
        })}
        <hr />
        <Button
          role="button"
          variant="contained"
          key="see-results"
          value="see-results"
          className="search-result"
        >
          See {props.totalResults} Results
        </Button>
      </Select>
    </div>
  );
}
