import React from 'react';

import { Card } from 'react-bootstrap';

import { ResolverLink } from 'components/shared';

export const CardBody = ({
  title = '',
  url = '',
  startingPrice = '',
  specialFloorPlan = 'Special Floor Plan Pricing',
  offeredBy = 'Offered by',
  dba = '',
  city = '',
  state = '',
}) => {
  return (
    <Card.Body className="fp-feature-model-card-body">
      <Card.Title className="fp-feature-model-card-body-title">
        <span className="fp-feature-model-card-body-title-line line1">{specialFloorPlan} </span>
        <span className="fp-feature-model-card-body-title-line line2">
          {offeredBy} {dba} - {city}, {state}{' '}
        </span>
      </Card.Title>
      <div className="fp-feature-model-card-body-button">
        <ResolverLink className="fp-feature-model-card-body-button-link" href={url}>
          <span className="fp-feature-model-card-body-button-label">{title}</span>
        </ResolverLink>
      </div>
      <ResolverLink className="fp-feature-model-card-body-price" href={url}>
        <span className="fp-feature-model-card-body-price-prefix">Starting From: </span>
        <strong className="fp-feature-model-card-body-price-amount">
          {startingPrice}
          <span className="price-asterisk">**</span>
        </strong>
      </ResolverLink>
    </Card.Body>
  );
};
