import React from 'react';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import './RetailerPromoComponent2.scss';

import { ResolverLink } from 'components/shared';

export function RetailerPromoComponent2(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef ? props.page.getContent(documentRef) : undefined;

  if (!document) {
    return null;
  }

  const { content, content2, link } = document.getData<DocumentData>();
  const theContent = content || content2;
  const destination = link.destinationUrl;

  return (
    <div className={`cvc-retailer-promo-banner${props.page.isPreview() ? ' has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      {theContent && (
        <ResolverLink href={destination}>
          <div dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(theContent.value) }} />
        </ResolverLink>
      )}
    </div>
  );
}
