import { BrProps } from '@bloomreach/react-sdk';
import React, { useEffect, useRef, useState } from 'react';
import './CavcoFloorPlanAvailability.scss';
import { Element } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import { mapMIRtoFpRetailers } from './mapMIRtoFpRetailers';
import { ReactComponent as CoordinateIcon } from '../../assets/images/cavcohomes/coordinate.svg';
import {
  formatPhoneNumber,
  getDistanceFromLatLonInKm,
  getDistanceUnitByCountry,
  oneKmInMiles,
} from '../utils';
import { ReactComponent as CaretDownIcon } from '../../assets/images/caret-down.svg';

export function CavcoFloorPlanAvailability(props: BrProps) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const countryCode = useLocation().pathname.split('/')[2];
  const locationLat = useQuery().get('latitude');
  const locationLng = useQuery().get('longitude');
  const locations = useQuery().get('locations')?.slice(1, -1);
  const [checked, setChecked] = React.useState(false);
  const isRetailerPage = !!props.page.getComponent('retailer-header-content');

  const { retailers } = props.component.getModels();
  const { floorPlanAvailabilityLabels } = props.component.getModels();

  const MIRretailers = !retailers && mapMIRtoFpRetailers(props);
  let useRetailers = retailers || MIRretailers;

  if (!useRetailers || !useRetailers.length || isRetailerPage) {
    return <></>;
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const distanceUnit = getDistanceUnitByCountry(countryCode);

  if (locationLat && locationLng && locations) {
    useRetailers = useRetailers.map((item: any) => {
      const distanceRetailer = getDistanceFromLatLonInKm(
        +locationLat,
        +locationLng,
        item.latitude,
        item.longitude
      );

      if (distanceUnit === 'miles') {
        item.distance = Math.round(+distanceRetailer * oneKmInMiles * 10) / 10;
      } else {
        item.distance = Math.round(+distanceRetailer * 10) / 10;
      }
      return item;
    });
  }

  // eslint-disable-next-line no-shadow
  const sortByDistance = (retailers: any) =>
    retailers.sort(
      (
        a: { distance: number | string; name: string },
        b: { distance: number | string; name: string }
      ) => {
        if (!a.distance || !b.distance) {
          return a.name.localeCompare(b.name);
        }
        return a.distance < b.distance ? -1 : 0;
      }
    );

  let states: any = [];

  useRetailers.forEach((retailer: any) => {
    states.push(retailer.state);
  });

  states = Array.from(new Set(states)).sort();
  function ListStates(propsState: any) {
    const [showCollapseButton, setShowCollapseButton] = React.useState(false);
    const [divHeight, setDivHeight] = React.useState(0);
    const [windowDimension, detectHW] = useState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });

    const detectSize = () => {
      detectHW({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    };
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      window.addEventListener('resize', detectSize);
      const height = ref.current?.clientHeight || 0;
      if (height <= 370 || useRetailers?.length <= 7) {
        setChecked(true);
        setShowCollapseButton(false);
      } else {
        // setChecked(false);
        setShowCollapseButton(true);
      }
      setDivHeight(height || 0);
      return () => {
        window.removeEventListener('resize', detectSize);
      };
    }, [windowDimension, divHeight]);
    const retailersInStock = sortByDistance(
      propsState.retailers.filter((r: { inStock: boolean }) => r.inStock)
    );
    const retailersNotInStock = sortByDistance(
      propsState.retailers.filter((r: { inStock: boolean }) => !r.inStock)
    );

    const getCollapseSize = () => {
      if (divHeight < 350) {
        return 200;
      }
      return 390;
    };

    return (
      <>
        <Collapse in={checked} collapsedSize={getCollapseSize()}>
          <div key="cavco-state-list" ref={ref} className="floorplanavailability-retailers">
            {retailersInStock.map((item: any, indexState: number) => (
              <div className="retailers-card" key={`cvc-state-detail-${indexState.toString()}`}>
                <div className="retailer-name-container">
                  <a href={item.url}>{item.name}</a>
                </div>
                <span className="retailers-info">{item.address}</span>
                <span className="retailers-info">
                  {item.city}, {item.stateAbbr} {item.zipCode}
                </span>
                <span className="retailers-info">{formatPhoneNumber(item.phone)}</span>
                <div className="retailers-location">
                  {item.distance && (
                    <span>
                      <CoordinateIcon /> {item.distance} {distanceUnit}
                    </span>
                  )}
                </div>
                {item.inStock && <div className="availability-retailer-in-stock">In Stock</div>}
              </div>
            ))}
            {retailersNotInStock.map((item: any, indexState: number) => (
              <div className="retailers-card" key={`cvc-state-detail-${indexState.toString()}`}>
                <div className="retailer-name-container">
                  <a href={item.url}>{item.name}</a>
                </div>
                <span className="retailers-info">{item.address}</span>
                <span className="retailers-info">
                  {item.city}, {item.stateAbbr} {item.zipCode}
                </span>
                <span className="retailers-info">{formatPhoneNumber(item.phone)}</span>
                <span className="retailers-location">
                  {item.distance && (
                    <>
                      <CoordinateIcon /> {item.distance} {distanceUnit}
                    </>
                  )}
                </span>
                {item.inStock && <div className="availability-retailer-in-stock">In Stock</div>}
              </div>
            ))}
          </div>
        </Collapse>
        {showCollapseButton && (
          <button
            type="button"
            className={`collapse-control ${showCollapseButton} more-${checked}`}
            onClick={handleChange}
            aria-label="toggle show more show less"
          >
            {checked ? 'Show Less' : 'Show More'}
            <CaretDownIcon />
          </button>
        )}
      </>
    );
  }

  const title = floorPlanAvailabilityLabels?.whereToBuy_title || 'Where To Buy';
  return (
    <>
      <Element key="cvc-fp-availability-element" name="availability">
        <div className="cavco-availability-panel-container">
          <div className="cvc-floorplanavailability-container">
            <div className="floorplanavailability-title">
              <h2>{title}</h2>
            </div>
            <ListStates states={states} retailers={useRetailers} />
          </div>
        </div>
      </Element>
    </>
  );
}
