import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import AutoCompleteComp, { GoToPageUrl } from 'components/Autocomplete/Autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useStateWithLocalStorage } from 'components/hooks/useStateWithLocalStorage';
import { Spinner } from 'components/shared/Spinner';
import { checkForNessus } from 'components/utils';

let isSearching = false;

const setIsSearching = async (value: any) => {
  isSearching = value;
};

export const SearchAgainComp = ({
  apiKey,
  locationNameSearched,
  internalLoc,
  spinnerColorInLightBackground,
  searchButtonText = 'Search Again',
}: any) => {
  const history = useHistory();
  const [, setCurrentFloorPlanSearch] = useStateWithLocalStorage('currentFloorPlanSearch');
  const [newLocation, setNewLocation] = useState('');
  const [tempFloorPlanSearch, setTempFloorPlanSearch] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });

  const searchFilterAutocompleteInputFocus = () => {
    const searchFilterAutocompleteInput = document.querySelector(
      '.search-filter-autocomplete-input'
    );
    if (searchFilterAutocompleteInput) {
      (searchFilterAutocompleteInput as HTMLInputElement).focus();
    }
  };

  const goToPage = (data: any) => {
    const urlVal = GoToPageUrl(data);
    setTempFloorPlanSearch(data);
    setNewLocation(urlVal);
  };

  const handleSearchAgain = async () => {
    await setIsSearching(true);

    if (tempFloorPlanSearch.description !== '') {
      if (newLocation.includes('our-homes') || newLocation.includes('park-models')) {
        if (tempFloorPlanSearch.lat && tempFloorPlanSearch.lng && tempFloorPlanSearch.terms) {
          await setCurrentFloorPlanSearch({
            latitude: tempFloorPlanSearch.lat,
            longitude: tempFloorPlanSearch.lng,
            dataTerms: tempFloorPlanSearch.terms,
          });
        }
      }

      if (newLocation) {
        await history.push(newLocation);
      }
    } else {
      searchFilterAutocompleteInputFocus();
    }

    await setIsSearching(false);
  };

  const suggestionSelected = (data: any) => {
    let urlValue: any = window.location.pathname;
    let appendParamOnly = false;
    if (urlValue.includes('building-center')) {
      appendParamOnly = true;
      if (!urlValue.endsWith('search') && !urlValue.endsWith('search/')) {
        urlValue = urlValue.endsWith('/') ? `${urlValue}search` : `${urlValue}/search`;
      }
    }
    if (
      !appendParamOnly &&
      window.location.pathname &&
      window.location.pathname.split('/').length > 1
    ) {
      const locationPathSplitted = window.location.pathname.split('/');
      locationPathSplitted.pop();
      urlValue = locationPathSplitted.join('/');
    }

    geocodeByAddress(data.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        goToPage({
          url: urlValue,
          terms: data.terms,
          lat,
          lng,
          appendParamOnly,
        })
      );
  };

  useEffect(() => {
    const searchInputBox: any = document.querySelector('.search-filter-autocomplete-input')!;
    if (searchInputBox) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
          handleSearchAgain();
        }
      };

      searchInputBox.addEventListener('keydown', handleKeyDown);
      return () => searchInputBox.removeEventListener('keydown', handleKeyDown);
    }
  });

  return (
    <>
      <AutoCompleteComp
        apiKey={checkForNessus(apiKey)}
        placeholder="Enter City/State or Province"
        onClick={suggestionSelected}
        inputClassName="search-filter-autocomplete-input"
        initialValue={locationNameSearched}
        geocodeEndpoint={internalLoc}
      />
      <button
        type="button"
        className="btn btn-primary d-inline-block"
        onClick={handleSearchAgain}
        disabled={isSearching === true}
        tabIndex={isSearching ? -1 : 0}
      >
        {searchButtonText}
      </button>
      {isSearching ? (
        <Spinner color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''} />
      ) : (
        ''
      )}
    </>
  );
};
