/*
 *
 * This component creates the metadata for the Floor Plan Detail pages
 * This is a Document-backed page
 * ex url: https://qa.cavco.bloomreach.cloud/site/our-homes/us/blackthorn
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';
import { findBuildingCenterFromPage, getRetailerData, buildCmsOgImage } from '../utils';

const pluralizer = (val: string | number, desc: string) =>
  val === '1' || val === 1 ? desc : `${desc}s`;

function buildMetaDescription(
  data: FloorPlanData,
  isPark: boolean,
  brand: string,
  retailerData: RetailerData | null,
  buildingMethod: string
) {
  //"[Model Name] [Model Number] manufactured home from [Brand] features [x] bedrooms, [x] baths and [number square footage] square feet of living space."
  const metaDescription = retailerData
    ? `${data.modelName} ${
        data.modelNumber
      } ${buildingMethod?.toLowerCase()} from ${brand} features ${data.numBedrooms} ${pluralizer(
        data.numBedrooms,
        'bedroom'
      )}, ${data.numBathrooms} ${pluralizer(data.numBathrooms, 'bath')} and ${
        data.squareFootage
      } square feet of living space. Learn more about this floor plan at cavcohomes.com.`
    : `${data.modelName} ${
        data.modelNumber
      } ${buildingMethod?.toLowerCase()} from ${brand} features ${data.numBedrooms} ${pluralizer(
        data.numBedrooms,
        'bedroom'
      )}, ${data.numBathrooms} ${pluralizer(data.numBathrooms, 'bath')} and ${
        data.squareFootage
      } square feet of living space.`;

  /*
  let metaDescription = `The ${data.modelName} ${data.modelNumber} is a `;
  metaDescription += data.numBedrooms ? `${data.numBedrooms} bedroom, ` : '';
  metaDescription += data.numBathrooms ? `${data.numBathrooms} bathroom, ` : '';
  metaDescription += data.squareFootage ? `${data.squareFootage} square foot ` : '';
  metaDescription += `${data.sections} ${data.buildingMethodValue} ${
    isPark ? 'park model' : 'home'
  }`.toLowerCase();
  if (data.floorplanSellingFeatures && data.floorplanSellingFeatures.feature) {
    // max length is 160, so once we hit 145 stop
    for (
      let i = 0;
      i < 8 && data.floorplanSellingFeatures.feature.length > i && metaDescription.length < 145;
      i += 1
    ) {
      metaDescription = `${metaDescription} ${data.floorplanSellingFeatures.feature[i]}`;
    }
  }
*/
  return metaDescription;
}

function buildMetaKeywords(data: FloorPlanData) {
  let metaKeywords = `${
    (data.buildingMethodValue ? `${data.buildingMethodValue}, ` : '') +
    (data.dimensionalArea ? `${data.dimensionalArea}, ` : '') +
    data.modelName
  }, ${data.modelNumber}, ${data.numBedrooms} ${pluralizer(data.numBedrooms, 'bedroom')}, ${
    data.numBathrooms
  } ${pluralizer(data.numBathrooms, 'bathroom')}, `;
  metaKeywords += data.squareFootage ? `${data.squareFootage.toString()} square foot, ` : '';
  metaKeywords = `${metaKeywords + data.series}, ${data.sections ? data.sections : ''}`;
  return metaKeywords;
}

function buildMetaImage(props: BrProps, data: FloorPlanData) {
  let ogImage = '';
  const photosDocument = data.photos && props.page.getContent(data.photos);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, data.photos);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function pagePropertiesForFPData(props: BrProps, data: FloorPlanData, links: any) {
  /*    // we could build dynamically as below
        const kwMapStr = ['The %modelName %modelNumber ',
        'is a ',
        '%numBedrooms bedroom, ',
        '%numBathroom bathroom, ',
        '%squareFootage square foot ',
        '%sections ',
        'home with the following features %feature1',
        ', %feature2',
        ', %feature3',
        ', %feature4',
    ]
*/
  const { metadata: cmsMetadata } = data;
  const page = props?.page;
  const buildingCenterData = findBuildingCenterFromPage(page);
  // building center's DBA is the brand
  const brand = buildingCenterData ? buildingCenterData?.dba : '';
  const parkFind: boolean = data.buildingMethodValue
    ? data.buildingMethodValue.toString().toLowerCase().includes('park')
    : false;

  const retailerData = getRetailerData(page);

  const buildingMethod = parkFind ? data.buildingMethodValue : `${data.buildingMethodValue} Home`;

  const titleTemp = retailerData
    ? `${data.modelName} ${data.modelNumber} ${buildingMethod} from ${brand}, ${retailerData?.city}, ${retailerData?.state} | ${retailerData?.dba}, a Cavco Company`
    : `${data.modelName} ${data.modelNumber} ${buildingMethod} from ${brand}, a Cavco Company`;
  const pageTitle = cmsMetadata?.pageTitle || titleTemp;

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links);
  const metaDescription =
    cmsMetadata?.metaDescription ||
    buildMetaDescription(data, parkFind, brand, retailerData, buildingMethod);
  const metaKeywords = cmsMetadata?.metaKeywords || buildMetaKeywords(data);
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage = buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data);

  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata && !cmsMetadata.index ? 'noindex' : 'index';
  const followRobot = cmsMetadata && !cmsMetadata.follow ? 'nofollow' : 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta className="elastic" name="search_image" content={`${ogImage}`} />}
        <meta className="elastic" name="type" content="floorplan" />
        <meta className="elastic" name="sort_group" content="1" />
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
export function PagePropertiesFP(props: BrProps) {
  const details = props.page.getComponent('main', 'detailcontainer');
  let form = details && details.getComponent('fp-banner');
  if (!form || !form.getModels().documents) {
    form = details && details.getComponent('fp-facts');
  }
  const doc = form && form.getModels().documents[0];
  const document = props.page.getContent(doc);
  const dm = document && document.getData<FloorPlanData>();

  if (!dm) {
    return null;
  }
  return pagePropertiesForFPData(props, dm, document.getUrl());
}
