import React from 'react';
import { Image } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoGMinfoBanner.scss';
import { getRetailerData } from 'components/utils';

export function CavcoGMinfoBanner(props: BrProps) {
  const retailerData = getRetailerData(props.page);

  const GM_name = retailerData && retailerData?.generalManagerName;
  const GM_title = retailerData && retailerData?.generalManagerTitle;
  const GM_image = retailerData && retailerData?.generalManagerPictureURL;
  const description = retailerData && retailerData?.description;
  const retailer_name = retailerData && retailerData?.name;
  const retailer_city = retailerData && retailerData?.city;
  const retailer_state = retailerData && retailerData?.state;

  const showWelcome = retailer_city && retailer_state && retailer_name;

  return (
    <div className="cvc-retailer-gm-info-container">
      <section>
        <header className="cvc-retailer-gm-info-header">
          {showWelcome && (
            <h3>
              Welcome to {retailer_name} in {retailer_city}, {retailer_state}
            </h3>
          )}
          {(GM_image || GM_name || GM_title) && (
            <div className={`gm-image-block ${!GM_image && 'no-img'}`}>
              {GM_image && <Image src={GM_image} alt={GM_name || 'General Manager'} />}
              <div className="gm-info">
                {GM_name && <p className="gm-name">{GM_name}</p>}
                {GM_title && <p className="gm-title">{GM_title}</p>}
              </div>
            </div>
          )}
        </header>
        <main className="cvc-retailer-gm-content">
          {description && <div dangerouslySetInnerHTML={{ __html: description.value }} />}
        </main>
      </section>
    </div>
  );
}
