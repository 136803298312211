import { BrProps } from '@bloomreach/react-sdk';
import React from 'react';
import './FloorPlanAdditionalInfo.scss';
import { ReactComponent as DownArrowIcon } from '../../assets/images/cavcohomes/down-arrow.svg';
import { ReactComponent as FloorPlanIcon } from '../../assets/images/cavcohomes/floorplan-icon.svg';
import { ResolverLink } from '../shared';

export function FloorPlanAdditionalInfo(props: BrProps) {
  const details = props.page.getComponent('main', 'detailcontainer');
  const mirData = details && details.getComponent('inventory-data');

  // Getting Inventory Files
  const invDocRef = mirData && mirData.getModels().document;
  const invPdfs = props.page.getContent(invDocRef)?.getData().inventoryFiles;

  // Getting Floor Plan Files
  let pdfs;

  if (props.component.getName() === 'inventory-data') {
    const fpDocRef = mirData && mirData.getModels().inventoryFloorplan;
    pdfs = props.page.getContent(fpDocRef)?.getData().floorplanFiles;
  } else {
    const { floorplan } = props.component.getModels();
    const floorplanFiles = props.page.getContent(floorplan);
    pdfs = floorplanFiles?.getData().floorplanFiles;
  }

  function validateArray(array: any) {
    return array && array.length > 0;
  }

  function transformArray(array: any) {
    return array
      ? array.filter((item: any) => {
          return item.uploadFileName;
        })
      : array;
  }

  let validPdfs;
  const transInvPdfs = transformArray(invPdfs);
  const transPdfs = transformArray(pdfs);

  if (props.component.getName() === 'inventory-data') {
    validPdfs = validateArray(transInvPdfs) ? transInvPdfs : transPdfs;
  } else {
    validPdfs = validateArray(transPdfs) ? transPdfs : transInvPdfs;
  }

  if (!validateArray(validPdfs)) {
    return <></>;
  }

  function getFiles(fileName: string, uploadFileName: string) {
    return (
      <ResolverLink target="_blank" href={uploadFileName}>
        <div className="download-icon">
          <DownArrowIcon alt="download" />
        </div>
      </ResolverLink>
    );
  }

  return (
    <div className="csv-floorplaninfo-container">
      <h2 className="csv-floorplaninfo-container-title">Additional Information</h2>
      <div className={`additionalinfo-files-container ${validPdfs?.length <= 1 && 'centered'}`}>
        {validPdfs.map((item: any, indexState: number) => {
          const fileId = `file-${indexState}`;
          return (
            <div className="file-item" key={fileId} id={fileId}>
              <FloorPlanIcon />
              <div className="file-description">
                <span>{item.fileName}</span>
              </div>
              {getFiles(item.fileName, item.uploadFileName)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
