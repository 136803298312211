export const REQUEST_INFO_FORM_DEFAULT = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  appointmentDate: '',
  appointmentTime: '',
  ownLand: '',
  phoneNo: '',
  phone1: '',
  phone2: '',
  phone3: '',
  locationToBuildZip: '',
  locationToBuildCity: '',
  locationToBuildState: '',
  version: '',
  locationUid: '',
  webType: '',
  comments: '',
};

export const FORM_TYPE_VALUES = { SIDEBAR: 31, FULL_SCREEN: 32, INLINE: 33 };
export const ZIPCODE_REGEX = /^[0-9]{5}$(?:-[0-9]{4})?|^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/;
export const EMAIL_REGEX = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const PHONE_REGEX = /^[0-9]{3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/gm;

export const SNACKBAR_DURATIONS = {
  error: 6000,
  success: 3000,
};
