/*
 *
 * This component creates the Metadata BR component.
 * This is able to be added to any editorial page created.
 *
 *
 * ******** Will update metadata, but will get overwritten if a specific page's metadata is set elsewhere.
 * ex: The metadata is pulling from here for the search page, but from
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { getBuildingCenterFromHeader } from 'components/utils';
import { BuildCannonicalURL } from './CannonicalURL';

function getMetaTag(attribute: string, metaValueList: any, data: any, bcNodeName: string) {
  let metaTag = '';
  try {
    metaTag = metaValueList[bcNodeName + attribute] || metaValueList[`default${attribute}`];
  } catch (error) {
    try {
      metaTag = metaValueList[`default${attribute}`];
    } catch (error2) {
      // now we do nothing
    }
  }
  return metaTag ? metaTag.replace(/{city}/g, data.city).replace(/{state}/g, data.state) : '';
}

export function pagePropertiesForBuildingCenterData(props: BrProps, metaValueList: any) {
  const { page } = props;
  const data = getBuildingCenterFromHeader(page);

  if (!data) {
    return null;
  }

  const { url } = data;

  let bcNodeName = data?.buildingCenterPath as string;
  bcNodeName = bcNodeName ? bcNodeName.replace('/', '') : data?.name;

  const { metadata: cmsMetadata } = data;
  const { componentParameterMap } = props.component.getModels();

  const pageTitle = cmsMetadata?.pageTitle || getMetaTag('.title', metaValueList, data, bcNodeName);

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(url);
  const metaDescription =
    cmsMetadata?.metaDescription || getMetaTag('.description', metaValueList, data, bcNodeName);
  const metaKeywords =
    cmsMetadata?.metaKeywords || getMetaTag('.keywords', metaValueList, data, bcNodeName);
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;

  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = componentParameterMap.indexRobot || 'index';
  const followRobot = componentParameterMap.followRobot || 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}

export function PagePropertiesBuildCenterHome(props: BrProps) {
  const { metadatabuildingcenterhome: metaValueList } = props.component.getModels();
  return pagePropertiesForBuildingCenterData(props, metaValueList);
}
