import { BrProps } from '@bloomreach/react-sdk';
import { Content, ImageSet } from '@bloomreach/spa-sdk';
import React from 'react';
import './CavcoBanner.scss';
import { Image } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { CavcoLink } from '../FormComponents/CavcoLink';
import { getYoutubeId, getUrlFromImage } from '../utils';
import { FullWidthVideoBanner } from './FullWidthVideoBanner';

export function CavcoBanner(props: BrProps) {
  const { document: documentRef, componentParameterMap } = props.component.getModels();
  const document: Content | undefined = documentRef && props.page.getContent(documentRef);

  if (!document) {
    return null;
  }

  const {
    title,
    subtitle,
    content1,
    content2,
    image: imageRef,
    link: linkRef,
  } = document.getData<BannerData>();

  const { videoCompound } = document.getData();
  const { theme } = componentParameterMap;
  let containerClassName = 'default';

  let vidClass = '';
  if (theme === 'video') {
    vidClass = 'banner-video';
  } else if (theme === 'videoLarge') {
    vidClass = 'large-video';
  }
  const videoSizeClass = vidClass;

  if (theme === 'imageleft') {
    containerClassName = 'imageLeft';
  } else if (theme === 'imageright') {
    containerClassName = 'imageRight';
  } else if (theme === 'video' || theme === 'videoLarge') {
    containerClassName = 'video';
  } else if (theme === 'skinny') {
    containerClassName = 'skinny';
  } else {
    return null;
  }
  const image = imageRef && props.page.getContent(imageRef.imagelink);
  const contentVal = content1 && props.page.rewriteLinks(content1.value);
  const content2Val = content2 && props.page.rewriteLinks(content2.value);

  function mapFullWidthVideoBannerProps() {
    const { fallbackImage, videoId, mobileVideoId } = videoCompound;
    const posterImage = fallbackImage?.imagelink
      ? getUrlFromImage(props.page.getContent<ImageSet>(fallbackImage.imagelink))
      : '';

    return {
      posterImage,
      videoId,
      mobileVideoId,
      content2Val,
      videoSizeClass,
    };
  }

  function Video(videoCompoundProp: any) {
    const { videoURL } = videoCompoundProp;
    const { fallbackImage } = videoCompoundProp;

    const fallBackImageSrc = imageRef && props.page.getContent(fallbackImage.imagelink);

    const videoRequest = {
      videoURL,
    };
    const videoId = getYoutubeId(videoRequest);

    return (
      <>
        {videoId && (
          <YouTube
            className="cavco-banner-video"
            videoId={videoId}
            opts={{
              width: '100%',
              height: '300px',
              playerVars: {
                controls: 1,
                start: 0,
                disablekb: 1,
                enablejsapi: 1,
                iv_load_policy: 3,
                modestbranding: 1,
                showinfo: 0,
              },
            }}
          />
        )}
        {!videoId && fallBackImageSrc && (
          <Image
            className="cavco-banner-video"
            src={getUrlFromImage(fallBackImageSrc)}
            alt={fallbackImage.alt}
          />
        )}
      </>
    );
  }

  return containerClassName === 'video' ? (
    <FullWidthVideoBanner {...mapFullWidthVideoBannerProps()} />
  ) : (
    <div className={`cavco-banner-container ${containerClassName}`}>
      <div className="image-container">
        {image && <Image src={getUrlFromImage(image)} alt={imageRef.alt} />}
        {!image && videoCompound && Video(videoCompound)}
      </div>
      <div className="banner-body-container">
        <div className="cavco-banner-title">
          <p>{title}</p>
        </div>
        {theme !== 'skinny' && (
          <div className="cavco-banner-content" dangerouslySetInnerHTML={{ __html: contentVal }} />
        )}
        {theme === 'skinny' && (
          <div className="cavco-banner-content">
            <p>{subtitle}</p>
          </div>
        )}
        {linkRef && (
          <div className="cta-container">
            <CavcoLink
              label={linkRef.displayText}
              link={linkRef.destinationUrl}
              newWindow
              variant="white"
            />
          </div>
        )}
      </div>
    </div>
  );
}
