import React, { ReactNode, useEffect } from 'react';
import { BrPageContext } from '@bloomreach/react-sdk';

/*
 * This is a temporary solution to a race condition in the Bloomreach SDK.
 * Sometimes, the Bloomreach SDK will trigger the synchronization with the channel manager edit mode
 * before the page is ready. We can't currently prevent that from happening, but we can detect when there is a
 * problem and force it to resynchronize once things are ready.
 */
let interval: NodeJS.Timeout | null = null;

const findComment = (el: HTMLElement | ChildNode, depth: number) => {
  const children = el.childNodes;
  for (let i = 0; i < children.length; i += 1) {
    const child = children.item(i);
    // Search for the page metadata comment, if it exists then we are good to go
    if (child.nodeType === child.COMMENT_NODE && child.nodeValue !== null) {
      try {
        const obj = JSON.parse(child.nodeValue);
        if (obj['HST-Type'] === 'PAGE-META-DATA') return true;
      } catch (e) {
        // We don't really care if it fails
      }
    }
  }

  // We're at maximum depth, stop here
  if (depth === 0) return false;

  // Traverse into the child nodes up to the specified depth
  for (let i = 0; i < children.length; i += 1) {
    const child = children.item(i);
    if (child.COMMENT_NODE && child.hasChildNodes() && findComment(child, depth - 1)) return true;
  }

  return false;
};

export function BrRaceFix(props: { children: ReactNode }) {
  const pageContext = React.useContext(BrPageContext)!;

  useEffect(() => {
    if (!pageContext?.isPreview() || interval) return;
    const execute = () => {
      if (!findComment(document.body, 4)) return;
      pageContext.sync();
      if (interval) clearInterval(interval);
    };
    interval = setInterval(execute, 500);
  }, [pageContext]);
  return <>{props.children}</>;
}
