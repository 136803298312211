import React, { FunctionComponent } from 'react';
import { BrPageContext, BrComponent } from '@bloomreach/react-sdk';
import { Row, Col } from 'react-bootstrap';
import './RetailerDetails.scss';
import { isRetailerHomePage } from 'components/utils';
import RetailerDetails2 from './RetailerDetails2';

const RetailerDetails: FunctionComponent = () => {
  const page = React.useContext(BrPageContext)!;
  if (isRetailerHomePage(page)) {
    // eslint-disable-next-line
    // @ts-ignore
    return RetailerDetails2();
  }

  const arrayCompNames: string[] = [];

  const displayComponentByName = (name: string) => {
    return (
      <div className="d-block" id={name} key={`div-${name}`}>
        <BrComponent key={name} path={name} />
      </div>
    );
  };

  const displayComponentWithContainer = (components: any[], comp: any, containerName: string) => {
    const compsWithContainer =
      components &&
      components.some(
        (child: any) => child.getModels().componentParameterMap.container === containerName
      )
        ? components.filter(
            (child: any) => child.getModels().componentParameterMap.container === containerName
          )
        : [];

    if (comp.getModels().componentParameterMap.container === containerName) {
      arrayCompNames.push(comp.model.name);
    }

    if (arrayCompNames.length === compsWithContainer.length) {
      const sizeForMd = 12 / arrayCompNames.length;
      const sizeForLg = 12 / arrayCompNames.length;

      return (
        <div className={`cvc-retailer-${containerName}-container`}>
          <Row key={`row-${containerName}`}>
            {arrayCompNames.map((compName: any) => {
              return (
                <Col
                  xs={12}
                  md={containerName === 'location-request-promos' ? 12 : sizeForMd}
                  lg={sizeForLg}
                  key={`col-${compName}`}
                >
                  {displayComponentByName(compName)}
                </Col>
              );
            })}
          </Row>
        </div>
      );
    }
  };

  const displayComponentWithoutContainer = (comp: any) => {
    return comp.model.name !== 'builder-exterior' && comp.model.name !== 'breadcrumb-stack'
      ? displayComponentByName(comp.model.name)
      : null;
  };

  const displayMainComponentsWrapper = (breadcrumb: any, int: any, ext: any) => (
    <div className="d-block interactive-builder-components" key="div-builder-promos">
      {
        // we don't need to display breadcrumb here when it's a retailer
        false && (
          <div id={breadcrumb.model.name}>
            <BrComponent key={breadcrumb.model.name} path={breadcrumb.model.name} />
          </div>
        )
      }
      <h1>Start Designing Your Own Manufactured Home and Make Your Dream a Reality. </h1>
      <h3>
        Use our Digital Design Center to see your dream manufactured mobile home become a reality
        before your eyes.
      </h3>
      <div className="builder-promos">
        <div className="promo" id={int.model.name}>
          <BrComponent key={int.model.name} path={int.model.name} />
        </div>
        <div className="promo" id={ext.model.name}>
          <BrComponent key={ext.model.name} path={ext.model.name} />
        </div>
      </div>
      <h2>You Design It. We Build It.</h2>
      <p>
        Explore your options for the interior and exterior of your new manufactured or modular home
        with our Digital Design Center - adding your own unique style with customized furnishings
        materials.{' '}
      </p>
      <p>
        With just a few clicks from the comfort of your couch, you can choose your cabinets,
        flooring, siding, front doors, and more, and see it right in front of you!{' '}
      </p>
      <p>
        If you decide you don&rsquo;t like it, just start over. Mix and match until you find the
        perfect combination for your new manufactured or modular home.{' '}
      </p>
    </div>
  );

  const displayBrComponents = (pageModelName?: string) => {
    const pageName = pageModelName || '';
    const main =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'main');
    const retailerDetailContainer =
      main && main.getChildren().find((comp: any) => comp.getName() === 'detailcontainer');

    const components = retailerDetailContainer && retailerDetailContainer.getChildren();

    if (!components) {
      return null;
    }

    const breadcrumbStack = components.find((comp: any) => comp.model.name === 'breadcrumb-stack');
    // const breadcrumbStack = undefined;
    const interiorPromo = components.find((comp: any) => comp.model.name === 'builder-interior');
    const exteriorPromo = components.find((comp: any) => comp.model.name === 'builder-exterior');

    return components.map((comp: any, index: number) => {
      const shouldDisplayMainComponentsWrapper: boolean =
        (pageName === 'interactive-builder' || pageName === 'interactive-builder-park') &&
        breadcrumbStack !== undefined &&
        interiorPromo !== undefined &&
        exteriorPromo !== undefined &&
        comp === interiorPromo &&
        (components[index + 1] as any) === exteriorPromo;
      const { componentParameterMap } = comp.getModels();
      const container: string =
        componentParameterMap && componentParameterMap.container
          ? componentParameterMap.container.toString()
          : undefined;

      if (container === undefined) {
        return shouldDisplayMainComponentsWrapper
          ? displayMainComponentsWrapper(breadcrumbStack, interiorPromo, exteriorPromo)
          : displayComponentWithoutContainer(comp);
      }
      return displayComponentWithContainer(components, components[index], container);
    });
  };

  const displayHeaderComponentsWithoutRetailerData = () => {
    const headerContent =
      page &&
      page
        .getComponent()
        .getChildren()
        .find((comp: any) => comp.getName() === 'retailer-header-content');

    const validHeaderComponents =
      headerContent &&
      headerContent.getChildren().filter((comp: any) => comp.getName() !== 'retailer-data');

    if (!validHeaderComponents) {
      return null;
    }
    return validHeaderComponents.map((comp: any) => {
      if (comp.model.name === 'header-component') {
        return <BrComponent key={comp.model.name} path={comp.model.name} />;
      }
      return '';
    });
  };

  const pageModelName = (page.getComponent() as any).model.name;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrComponent path="header-content" />
      <BrComponent path="parkmodel-header-content" />
      <BrComponent path="retailer-header-content">
        {displayHeaderComponentsWithoutRetailerData()}
      </BrComponent>
      <main
        style={{
          width: '100%',
          flex: '1 1 auto',
        }}
      >
        <section className="cvc-main__section cvc-main__section-retailer">
          <BrComponent path="hero" />
          <BrComponent path="main">
            <BrComponent path="detailcontainer">
              <div className="cvc-retailer-detail-container">
                <div className={`cvc-retailer-detail-component-container cvc-${pageModelName}`}>
                  {displayBrComponents(pageModelName)}
                </div>
              </div>
            </BrComponent>
          </BrComponent>
        </section>
      </main>
      <BrComponent path="footer-content" />
      <BrComponent path="parkmodel-footer-content" />
      <BrComponent path="retailer-footer-content" />
    </div>
  );
};

export default RetailerDetails;
