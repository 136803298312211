import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getBunnyNetVideoSource } from 'components/utils';
import './FullWidthVideoBanner.scss';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import { ReactComponent as PlayIcon } from 'assets/images/cavcohomes/play-icon.svg';
import { ReactComponent as PauseIcon } from 'assets/images/video-pause-btn.svg';
import { IconButton } from '@material-ui/core';

const videoMobileProps = {
  width: 375,
  height: 295,
  controls: false,
  playsInline: true,
  muted: true,
};

const videoDesktopProps = {
  width: 1512,
  height: 400,
  controls: false,
  playsInline: true,
  muted: true,
  loop: true,
  autoPlay: true,
};
interface FullWidthVideoBannerProps {
  posterImage?: string;
  videoId: string;
  mobileVideoId?: string;
  content2Val?: any;
  videoSizeClass: string;
}

export const FullWidthVideoBanner: React.FC<FullWidthVideoBannerProps> = ({
  videoId,
  mobileVideoId = null,
  posterImage,
  content2Val,
  videoSizeClass,
}) => {
  const windowDimensions = useWindowDimensions();
  const { isMobile } = windowDimensions;

  const videoSrc = useMemo(() => {
    return isMobile && !!mobileVideoId
      ? getBunnyNetVideoSource(mobileVideoId)
      : getBunnyNetVideoSource(videoId);
  }, [isMobile, mobileVideoId, videoId]);
  const videoEl = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(true);

  const videoProps = isMobile
    ? { ...videoMobileProps, poster: posterImage }
    : { ...videoDesktopProps, poster: posterImage };

  useEffect(() => {
    if (videoEl.current) {
      setIsPaused(videoEl.current.paused);
    }
  }, []);

  useEffect(() => {
    if (videoEl.current && videoSrc) {
      videoEl.current.load();
    }
  }, [videoSrc]);

  const handlePlayPause = useCallback(() => {
    if (!videoEl.current) return;

    if (videoEl.current.paused) {
      setIsPaused(false);
      videoEl.current.play();
    } else {
      setIsPaused(true);
      videoEl.current.pause();
    }
  }, []);

  return (
    <div className={`full-width-video-banner ${videoSizeClass}`}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoEl} {...videoProps}>
        <source src={videoSrc} />
      </video>
      {content2Val && (
        <>
          <div
            className="full-width-video-banner_content"
            dangerouslySetInnerHTML={{ __html: content2Val }}
          />
          <div className="full-width-video-banner_overlay" />
        </>
      )}
      {isMobile && (
        <div className="full-width-video-banner_control-button">
          <IconButton onClick={handlePlayPause}>
            {isPaused ? (
              <PlayIcon className="full-width-video-banner_control-icon" />
            ) : (
              <PauseIcon className="full-width-video-banner_control-icon" />
            )}
          </IconButton>
        </div>
      )}
    </div>
  );
};
